import React, { useContext, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import * as XLSX from 'xlsx'

import Button from '@components/atoms/button'
import CustomCheckbox from '@components/atoms/checkbox'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { downloadS3File } from '@helpers/file-downloader'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { StatementService } from '@services/api-analytics/financials-statement'

const FinancialExports = () => {
  const { get_access } = useUserAccessFeature()
  const FEATURE = `analytics_financials_statements`
  const can_export_pnl = get_access(`${FEATURE}_profit-&-loss`, 'export')
  const can_export_bs = get_access(`${FEATURE}_balance-sheet`, 'export')

  const { appliedFilters, company, optionFilters } = useContext(AuthContext)
  const { dateStart, dateEnd, financialEntity, currency } = appliedFilters
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isOg, setIsOg] = useState(false)
  const [isBalanceSheet, setIsBalanceSheet] = useState(false)
  const [isPnL, setIsPnL] = useState(false)

  const filters = {
    date_from: moment(dateStart).format('YYYY-MM-DD'),
    date_to: moment(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    entity_id: financialEntity,
  }

  const {
    error: balanceSheetError,
    data: balanceSheetData,
    isFetching: isBalanceSheetFetching,
  } = useQuery(
    ['balanceSheet', filters],
    () => StatementService.getBalanceSheet(filters),
    getStaleMins()
  )

  const {
    error: pnlError,
    data: pnlData,
    isFetching: pnlFetching,
  } = useQuery(
    ['pnl', filters],
    () => StatementService.getPnL(filters),
    getStaleMins()
  )

  const generateExcelDataArray = (data: any) => {
    const arrayToSheet = [
      [`item`, ...data.cols],
      ...data.data.flatMap((d: any) => [
        [d.item, ...d.values],
        ...d.info.flatMap((c: any) => [
          [c.item, ...c.values],
          ...c.info.map((p: any) => [p.item, ...p.values]),
        ]),
      ]),
      [],
      [],
      [`CURRENCY : ${currency}`],
      [],
    ]

    return arrayToSheet
  }

  const downloadXlsx = async () => {
    setIsDialogOpen(!isDialogOpen)

    toast.info('Your download should begin automatically', { autoClose: 5000 })

    const entity = optionFilters.financialEntities.find(
      (entity: any) => entity.entity_id === financialEntity
    )
    const entityName = entity?.name ?? ''
    const sheets = []

    try {
      if (isBalanceSheet && balanceSheetData) {
        const balanceSheetArray = generateExcelDataArray(balanceSheetData)
        balanceSheetArray.push(
          [
            '1 : Asset portfolio includes loan/collateral portfolio net of write-offs/provisions',
          ],
          [
            '2,3 : Long-term debt includes debt deals form investors like asset-backed lending facilities, notes, loans etc',
          ]
        )
        sheets.push({ name: 'Balance Sheet', array: balanceSheetArray })
      }
      if (isPnL && pnlData) {
        const pnlArray = generateExcelDataArray(pnlData)
        pnlArray.push(
          [
            '1 : For lending companies, revenue includes all interest and fee income earned from the asset portfolio.',
          ],
          [
            '2 : For lending companies, CoGS includes interest expense from debt investoris for capital that is used to lend, and write-offs/provisions',
          ],
          [
            '3: Non-operating income includes currency impact, investment income/loss, or ther items not related to the core business.',
          ]
        )
        sheets.push({ name: 'Profit & Loss', array: pnlArray })
      }
      if (sheets.length > 0) {
        const workbook = XLSX.utils.book_new()
        sheets.forEach(sheet => {
          const worksheet = XLSX.utils.aoa_to_sheet(sheet.array)
          XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name)
        })

        const filename = `${entityName} - Financial Statements - ${moment().format(
          'YYYY-MM-DD HH:mm:ss'
        )}.xlsx`
        await XLSX.writeFile(workbook, filename)
      }
      if (!isOg && balanceSheetData?.download_url) {
        downloadS3File(
          balanceSheetData.download_url,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
      }
    } catch (error) {
      toast.error('Export failed', { autoClose: false })
      console.error('Error generating or downloading Excel file:', error)
    } finally {
      setIsOg(false)
      setIsBalanceSheet(false)
      setIsPnL(false)
    }
  }

  return (
    <>
      {(balanceSheetData || pnlData) && (
        <Button
          onClick={() => setIsDialogOpen(!isDialogOpen)}
          disabled={
            !!balanceSheetError ||
            isBalanceSheetFetching ||
            !!pnlError ||
            pnlFetching
          }
        >
          <ArrowDownTrayIcon className="w-5 mr-2" />
          Export
        </Button>
      )}
      <Dialog
        open={isDialogOpen}
        handler={() => setIsDialogOpen(!isDialogOpen)}
      >
        <DialogHeader className="text-xl">
          Select from available exports
        </DialogHeader>
        <DialogBody className="flex flex-col" divider>
          {can_export_bs && (
            <div
              onClick={() => setIsBalanceSheet(!isBalanceSheet)}
              className="flex items-center w-full justify-between rounded-lg px-4 py-1 cursor-pointer hover:bg-primary-surface-1"
            >
              <span className="text-sm">Balance sheet (as displayed)</span>
              <CustomCheckbox checked={isBalanceSheet} />
            </div>
          )}
          {can_export_bs && balanceSheetData?.download_url && (
            <div
              onClick={() => setIsOg(!isOg)}
              className="flex items-center w-full justify-between rounded-lg px-4 py-1 cursor-pointer hover:bg-primary-surface-1"
            >
              <span className="text-sm">
                Latest uploaded financial statements
              </span>
              <CustomCheckbox checked={isOg} />
            </div>
          )}
          {can_export_pnl && (
            <div
              onClick={() => setIsPnL(!isPnL)}
              className="flex items-center w-full justify-between rounded-lg px-4 py-1 cursor-pointer hover:bg-primary-surface-1"
            >
              <span className="text-sm">
                Profit & loss Statement (as displayed)
              </span>
              <CustomCheckbox checked={isPnL} />
            </div>
          )}
        </DialogBody>
        <DialogFooter>
          <Button
            disabled={!(isOg || isBalanceSheet || isPnL)}
            color="primary"
            onClick={downloadXlsx}
          >
            Download
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default FinancialExports
