/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useAuth0, User } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import { ACCESS_TOKEN_STORAGE_KEY } from '@constants/app'
import AuthContext from '@contexts/auth'
import { setMetadata } from '@contexts/auth'
import { getProviderName } from '@helpers/auth-provider'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import {
  LinkAccountRequest,
  VerifyAccountRequest,
} from '@interfaces/admin-settings-social-login'
import { Avatar } from '@material-tailwind/react'
import { SocialLoginService } from '@services/api-admin/settings-social-login'

const AuthHandler = () => {
  const navigate = useNavigate()
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user,
    getAccessTokenSilently,
    logout,
  } = useAuth0()
  const { userMetadata, setUserMetadata } = useContext(AuthContext)

  const provider: string | undefined = user?.sub?.split('|')?.[0]
  const companies = userMetadata?.companies

  const email: string = user?.email ?? ''
  const [authready, setAuthReady] = useState<boolean>(false)

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (isAuthenticated) {
      getAccessTokenSilently().then(token => {
        localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token)
        if (!userMetadata) {
          setMetadata(user, setUserMetadata)
          return
        }
        setAuthReady(true)
      })
      if (provider === 'auth0' || (companies ?? []).length > 0) {
        navigate('/')
      }
    } else {
      loginWithRedirect()
    }
  }, [isAuthenticated, isLoading, user, userMetadata])

  const { data = [], isFetching } = useQuery(
    ['searchInviteduser', email],
    () => SocialLoginService.searchInvitedUser(),
    {
      ...getStaleMins(),
      enabled: !!email && authready,
    }
  )

  const { mutate: verifyAccount, isLoading: isVerifying } = useMutation(
    (param: VerifyAccountRequest) => {
      return SocialLoginService.verify(param)
    },
    {
      onSuccess: () => {
        window.location.href = '/'
      },
    }
  )

  const { mutate: linkAccount, isLoading: isLinking } = useMutation(
    (param: LinkAccountRequest) => {
      return SocialLoginService.link(param)
    },
    {
      onSuccess: () => {
        window.location.href = '/'
      },
    }
  )

  return (
    <div className="bg-primary-surface-1 w-screen h-[calc(100dvh)] flex flex-col justify-center p-8 overflow-y-auto">
      <img
        src="/svgs/cascade-logo-long.svg"
        alt="Cascade"
        data-testid="cascade-logo"
        className="mt-6 mb-8 px-4 h-[40px]"
      />
      <div className="flex flex-1 flex-col items-center">
        {isFetching ? (
          <ArrowPathIcon className="w-8 h-8 m-8 text-primary-main animate-spin" />
        ) : (
          <>
            <span className="text-[64px] md:text-[128px] font-bold text-transparent bg-clip-text bg-gradient-to-r from-primary-main to-secondary-main opacity-30">
              Welcome!!
            </span>

            <div className="p-8 rounded-lg bg-neutral-white flex gap-4 shadow items-center">
              <Avatar src={user?.picture} alt="avatar" variant="circular" />
              <div>
                <Typography className="font-semibold">{user?.name}</Typography>
                <Typography className="text-sm">{user?.email}</Typography>
                <Typography className="text-sm">
                  {getProviderName(provider)}
                </Typography>
              </div>
            </div>
            {data?.length === 0 ? (
              <>
                <Typography className="mt-16">
                  Unfortunately we can not find any user associated with this
                  account.
                </Typography>
                <Typography className="mb-8 font-semibold">
                  What can I do from here?
                </Typography>
                <div className="gap-4 flex">
                  <div className="flex items-center justify-center shadow rounded-lg p-8 w-[300px] bg-neutral-white group cursor-pointer hover:bg-gradient-to-r hover:from-primary-main hover:to-secondary-main">
                    <Typography className="text-center text-sm font-medium text-neutral-border-3 group-hover:hidden">
                      My Company already registered to Cascade
                    </Typography>
                    <div className="hidden group-hover:block">
                      <Typography className="text-center text-sm text-white font-semibold">
                        Please contact your administrator to invite you into
                        company&apos;s account.
                      </Typography>
                    </div>
                  </div>
                  <div className="flex items-center justify-center shadow rounded-lg p-8 w-[300px] bg-neutral-white group cursor-pointer hover:bg-gradient-to-r hover:from-primary-main hover:to-secondary-main">
                    <Typography className="text-center text-sm font-medium text-neutral-border-3 group-hover:hidden ">
                      This is my first time to Cascade
                    </Typography>
                    <div className="hidden group-hover:flex flex-col gap-4 justify-center">
                      <Typography className="text-center text-sm text-white font-semibold">
                        Join Cascade waiting list
                      </Typography>
                      <Button
                        onClick={() =>
                          (window.location.href =
                            'https://calendly.com/rossiam/cascade-debt-meeting')
                        }
                      >
                        Schedule a Demo
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Typography className="mt-16 max-w-[90vw] md:max-w-[35vw] text-center">
                  A possible match has been identified for the user. Please
                  verify and link the account.
                </Typography>
                {data?.map((d: User) => {
                  const isVerified =
                    !!d.last_login ||
                    !!d.last_password_reset ||
                    d.email_verified
                  return (
                    <div
                      key={d.user_id}
                      className={`
                      p-8 rounded-lg bg-neutral-white flex flex-col md:flex-row gap-6 shadow mt-6 
                      [&>div]:flex [&>div]:items-start [&>div]:gap-4 [&>div]:max-w-[300px]
                      [&>div>span]:flex [&>div>span]:justify-center [&>div>span]:items-center [&>div>span]:h-[24px] [&>div>span]:min-w-[24px] [&>div>span]:rounded-full [&>div>span]:border [&>div>span]:border-neutral-black 
                      [&>div>span]:text-sm [&>div>span]:text-neutral-black
                    `}
                    >
                      <div className="border-r border-dashed pr-4 flex !items-center">
                        <div className="flex gap-4 items-center">
                          <Avatar
                            src={d?.picture}
                            alt="avatar"
                            variant="circular"
                          />
                          <div>
                            <Typography className="font-semibold">
                              {d?.name}
                            </Typography>
                            <Typography className="text-sm">
                              {d?.email}
                            </Typography>
                            <Typography className="text-sm capitalize">
                              {d?.user_metadata?.isExternal
                                ? 'External User'
                                : d?.roles?.map((r: any) => r.name).join(', ')}
                            </Typography>
                            <Typography className="text-sm capitalize">
                              {Array.isArray(d?.user_metadata?.slugName)
                                ? d?.user_metadata?.slugName?.join(', ')
                                : d?.user_metadata?.slugName}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div>
                        <span>1</span>
                        <div className="flex flex-col gap-4">
                          <Typography className="text-sm font-semibold">
                            Verify this account
                          </Typography>
                          <Typography className="text-sm">
                            This step is to verify that you are the owner of
                            this account
                          </Typography>
                          {isVerified ? (
                            <Typography className="text-sm text-primary-main">
                              Account is verified
                            </Typography>
                          ) : (
                            <Button
                              color="primary"
                              onClick={() =>
                                verifyAccount({ user_id: d.user_id })
                              }
                              disabled={isVerifying}
                            >
                              {isVerifying && (
                                <ArrowPathIcon className="animate-spin text-primary-main w-4 mr-2" />
                              )}
                              Send verification email
                            </Button>
                          )}
                        </div>
                      </div>
                      <div>
                        <span>2</span>
                        <div className="flex flex-col gap-4">
                          <Typography className="text-sm font-semibold">
                            Link both accounts together
                          </Typography>
                          <Typography className="text-sm">
                            This step will enable user to access all settings
                            and preferences of this account
                          </Typography>
                          {isVerified ? (
                            <Button
                              color="primary"
                              onClick={() =>
                                linkAccount({
                                  user_id: d.user_id,
                                })
                              }
                              disabled={isLinking}
                            >
                              {isLinking && (
                                <ArrowPathIcon className="animate-spin text-primary-main w-4 mr-2" />
                              )}
                              Link both accounts
                            </Button>
                          ) : (
                            <Typography className="text-sm text-neutral-border-3">
                              Verify account first
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            )}
            <Button
              className="mt-16"
              variant="text"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Logout
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default AuthHandler
