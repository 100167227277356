import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import VintageAnalysisL5 from './vintage-analysis'

const VintageAnalysisL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const tabs = [
    {
      label: '',
      desc: `The percentage of the portfolio that is beyond a certain DPD cut-off tracked against month-on-books. Can either be by outstanding balance or by loan number.`,
      component: <VintageAnalysisL5 exportable={exportable} />,
    },
  ]

  return (
    <L4TabsLayout
      tabs={tabs}
      additionalIndicator={
        showHistoricalRateIndicator ? <ExchangeRateBadge /> : undefined
      }
    />
  )
}

export default VintageAnalysisL4
