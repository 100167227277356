import moment from 'moment'

import { SeriesData } from '@components/chart'

export const formatUnixSeries = (data: SeriesData[]) => {
  const convertedData = data.map(datum => {
    return { ...datum, x: moment(datum.x).format('DD-MM-YYYY') }
  })

  return convertedData
}
