import React from 'react'

import { Typography } from '@material-tailwind/react'
const Description = () => (
  <div className="m-5">
    <Typography className="text-md font-bold text-left">Important:</Typography>
    <div className="mt-2">
      <div className="flex mt-2">
        <Typography className="text-sm font-light text-left">1.</Typography>
        <Typography className="text-sm font-light text-left pl-1">
          Data is being displayed at a monthly basis.
        </Typography>
      </div>
      <div className="flex mt-2">
        <Typography className="text-sm font-light text-left">2.</Typography>
        <Typography className="text-sm font-light text-left pl-1">
          <span className="font-medium">Updating cannot be undone.</span> If
          required cancel the validation and start process again.
        </Typography>
      </div>
      <div className="flex mt-2">
        <Typography className="text-sm font-light text-left">3.</Typography>
        <Typography className="text-sm font-light text-left pl-1">
          Update current record on the database with incoming statement data.
        </Typography>
      </div>
      <div className="flex mt-2">
        <Typography className="text-sm font-light text-left">4.</Typography>
        <Typography className="text-sm font-light text-left pl-1">
          <strong>Data on record:</strong> current statement data on the
          database (monthly basis).
          <br />
          <strong>Incoming statement:</strong> incoming statement from current
          upload.
        </Typography>
      </div>
    </div>
  </div>
)

export default Description
