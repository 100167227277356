import React from 'react'

import L4TabsLayout from '@components/layouts/l4-tabs-layout'

import NetYieldL5 from './net-yield'

const NetYieldL4 = ({ exportable }: { exportable: boolean }) => {
  const tabs = [
    {
      label: 'Net Yield',
      component: <NetYieldL5 exportable={exportable} />,
    },
  ]

  return <L4TabsLayout tabs={tabs} />
}

export default NetYieldL4
