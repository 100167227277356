import React, { memo, useLayoutEffect, useRef } from 'react'
import moment from 'moment'
import ReactDOM from 'react-dom'

import * as am5 from '@amcharts/amcharts5'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive'
import * as am5xy from '@amcharts/amcharts5/xy'
import { ILineSeriesAxisRange } from '@amcharts/amcharts5/xy'
import { formatUnixSeries } from '@helpers/unix-converter'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { Alert } from '@material-tailwind/react'

/**
 * @todo
 * extract from tailwind theme
 */
export const chartColors = [
  '#0e9eda',
  '#e6318a',
  '#5856d6',
  '#af52de',
  '#007aff',
  '#34c759',
  '#ff9500',
  '#ffa19c',
  '#ffcc00',
  '#2f4f4f',
  '#556b2f',
  '#8b4513',
]

export interface SeriesData {
  /**
   * x value
   */
  x: number | string
  /**
   * series value
   */
  [key: string]: number | string | undefined
}

interface BaseChartProps {
  /**
   * chart data. should include field from all series
   */
  data: SeriesData[]
  /**
   * yAxis Label
   */
  yLabel: string
  /**
   * yAxis format
   */
  yFormat?: string
  /**
   * optional yAxis Setting
   */
  ySetting?: {
    [key: string]: any
  }
  /**
   * xAxis Label
   */
  xLabel?: string
  /**
   * xAxis Type
   */
  xAxisType?: 'DateAxis' | 'CategoryAxis'
  /**
   * optional xAxis Setting
   */
  xSetting?: {
    [key: string]: any
  }
  /**
   * series list
   */
  series: {
    /**
     * series label. used as legend and tooltip
     */
    label: string
    /**
     * series label. used to override tooltip
     */
    tooltipLabel?: string
    /**
     * series tooltip format
     */
    tooltipValueFormat?: string
    /**
     * series type
     */
    type: 'ColumnSeries' | 'LineSeries' | 'SmoothedXLineSeries'
    /**
     * whether series is stack or not. only applicable for ColumnSeries type.
     */
    isStack?: boolean
    /**
     * Only applicable for Stack ColumnSeries type.
     * whether stack is aggregated or not
     * True by default
     */
    isTotal?: boolean
    /**
     * Only applicable for Total Stack ColumnSeries type.
     * set true when the value is not in percentage
     */
    isTransform?: boolean
    /**
     * series color.
     * will applied to stack fill color for column / stacked series,
     * or stroke color for line series
     * format should be in hexadecimal eg 0xff0000
     * or hex string #FF0000
     */
    color?: number | string
    /**
     * enables gradient from the given fill/stroke colour
     * */
    gradient?: boolean
    /**
     * field key name
     */
    field: string
    /**
     * whether series has bullet or not. only applicable for SmoothedXLineSeries
     */
    hasBullet?: boolean
    /**
     * bullet shape
     */
    bulletType?: 'circle' | 'line'

    /**
     * function to set each item color dynamicaly
     * passing dataitem
     * return color hex or string
     */
    setColor?: (field: string, data: any) => number | string
  }[]
  /**
   * use as element distinguisher
   */
  id: string
  /**
   * chart height
   */
  height?: number // can use tailwind in parent component?
  /**
   * legend setting
   */
  legendSetting?: {
    show?: boolean
    position?: string
    config?: {
      [key: string]: any
    }
  }
  /**
   * scrollbar setting
   */
  scroll?: {
    y?: boolean
    x?: boolean
    xStart?: number
    xEnd?: number
  }
  /**
   * range setting
   */
  range?: {
    from: number
    to: number
    label?: string
    color?: string | number
  }[]
  /**
   * tooltip position
   */
  tooltipPosition?: 'top' | 'bottom'
  /**
   * tooltip subtitle
   * currently used to show historical fx rate
   */
  tooltipSubtitle?: {
    field: string
    title: string
  }[]
  /**
   * whether show export button
   */
  exportable?: boolean
}

const BaseChart = (props: BaseChartProps) => {
  const {
    data,
    yLabel,
    yFormat = '#.00a',
    ySetting,
    xLabel = 'Monthly',
    xAxisType = 'DateAxis',
    xSetting,
    series: seriesProps,
    id,
    legendSetting = {
      show: true,
      position: 'top',
    },
    scroll = {
      x: true,
      y: true,
    },
    range,
    tooltipPosition = 'top',
    tooltipSubtitle,
    exportable = true,
  } = props

  const dateFormats: { [key: string]: string } = {
    day: 'dd MMM',
    week: 'dd MMM yyyy',
    month: 'MMM-yy',
    year: 'yyyy',
  }

  if (data.length == 0) {
    return (
      <div
        style={props.height ? { minHeight: props.height } : {}}
        className={`flex flex-col w-full min-h-[400px] justify-center items-center`}
      >
        <Alert className="w-1/2 text-neutral-subtitle-3 border border-primary-border text-center">
          No available data to display
        </Alert>
      </div>
    )
  }
  const series = seriesProps.map(x => ({
    ...x,
    label: (x.label ?? '-').replace(/[\[\]']+/g, ''),
    tooltipLabel: x.tooltipLabel?.replace(/[\[\]']+/g, ''),
  }))
  const seriesRefs = useRef<
    Array<am5xy.ColumnSeries | am5xy.LineSeries | am5xy.SmoothedXLineSeries>
  >([])
  const xAxisRef = useRef<
    | am5xy.DateAxis<am5xy.AxisRenderer>
    | am5xy.CategoryAxis<am5xy.AxisRenderer>
    | null
  >(null)

  function replaceNodeWithReactComponent(child?: HTMLElement) {
    if (child) {
      const parent = document.createElement('div')
      const reactComponent = (
        <div className="flex flex-row w-full px-3 my-1">
          <ArrowDownTrayIcon className="w-7 h-7 pr-2" />{' '}
          <span className="flex text-sm font-medium items-center">Export</span>
        </div>
      )
      ReactDOM.render(reactComponent, parent, () => {
        child.replaceWith(...Array.from(parent.childNodes))
      })
    }
  }

  useLayoutEffect(() => {
    const root = am5.Root.new(`chart-${id}`)

    root.numberFormatter.setAll({
      bigNumberPrefixes: [
        { number: 1e3, suffix: 'K' },
        { number: 1e6, suffix: 'M' },
        { number: 1e9, suffix: 'B' },
        { number: 1e12, suffix: 'T' },
        { number: 1e15, suffix: 'Q' },
      ],
      smallNumberPrefixes: [],
    })

    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Responsive.new(root),
    ])
    root.container._settings.paddingLeft = -24
    root.container._settings.paddingBottom = scroll.x ? -12 : -24

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        layout: root.verticalLayout,
        maxTooltipDistance: -1,
      })
    )

    // cursor
    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'zoomX',
      })
    )

    // Create Y-axis
    const yAxisRenderer = am5xy.AxisRendererY.new(root, {})
    yAxisRenderer.labels.template.setAll({
      fontSize: ySetting?.fontSize ?? 12,
      fill: am5.color(0x757575),
      paddingBottom: 10,
      lineHeight: 1.5,
      paddingRight: 10,
    })

    const isTotalStackChart =
      series.filter(
        ({ isStack, type, isTotal = true }) =>
          isStack && type === 'ColumnSeries' && isTotal
      ).length === series.length

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: isTotalStackChart ? 0 : undefined,
        max: isTotalStackChart ? 100 : undefined,
        strictMinMax: isTotalStackChart,
        calculateTotals: isTotalStackChart,
        renderer: yAxisRenderer,
        numberFormat: yFormat,
        ...ySetting,
      })
    )

    chart.leftAxesContainer.children.unshift(
      am5.Label.new(root, {
        text: yLabel,
        fontSize: ySetting?.fontSize ?? 12,
        fontWeight: '400',
        fontFamily: 'Inter',
        rotation: -90,
        textAlign: 'center',
        centerY: am5.p50,
        y: am5.p50,
        fill: am5.color(0x757575),
      })
    )

    // Create X-Axis
    const xAxisRenderer = am5xy.AxisRendererX.new(root, {
      ...xSetting?.renderer,
    })
    xAxisRenderer.labels.template.setAll({
      fontSize: xSetting?.fontSize ?? 12,
      fill: am5.color(0x757575),
      paddingTop: 22,
      paddingBottom: 16,
      background: am5.Graphics.new(root, {
        fill: am5.color(0xff0000),
      }),
    })

    const axisTooltip =
      tooltipPosition === 'bottom'
        ? {
            tooltip: am5.Tooltip.new(root, {}),
          }
        : {}
    const xAxis =
      xAxisType === 'DateAxis'
        ? chart.xAxes.push(
            am5xy.DateAxis.new(root, {
              baseInterval: { timeUnit: 'month', count: 1 },
              groupData: false,
              renderer: xAxisRenderer,
              dateFormats,
              periodChangeDateFormats: dateFormats,
              ...xSetting?.dateAxis,
              ...axisTooltip,
            })
          )
        : chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
              renderer: xAxisRenderer,
              categoryField: 'x',
              ...axisTooltip,
            })
          )

    xAxis.children.push(
      am5.Label.new(root, {
        text: xLabel,
        fontSize: xSetting?.fontSize ?? 12,
        centerX: am5.p50,
        x: am5.p50,
        fill: am5.color(0x757575),
        fontWeight: '400',
        fontFamily: 'Inter',
      })
    )

    if (tooltipPosition === 'bottom') {
      ;(xAxis as any).get('tooltip')?.label.adapters.add('text', () => {
        let text = ''
        chart.series.each((s, idx) => {
          const dataItem = s.get('tooltipDataItem')
          const _s = series[idx]
          if (dataItem) {
            if (idx === 0) {
              text += `[bold]${
                xAxisType === 'DateAxis'
                  ? moment
                      .utc(dataItem.get('valueX') ?? 0)
                      .format(
                        dateFormats[
                          xSetting?.dateAxis?.baseInterval?.timeUnit ?? 'month'
                        ].toUpperCase()
                      )
                  : dataItem.get('valueX')
              }[/]\n`
            }
            text += `[#98A2B3]${_s.tooltipLabel ?? _s.label}[/] : [${
              _s.setColor ? '#98A2B3' : chartColors[idx] ?? _s.color
            }]${root.numberFormatter.format(
              dataItem.get('valueY') ?? 0,
              _s.tooltipValueFormat || '#.0a'
            )}[/]\n`
          }
        })
        return text
      })
      ;(xAxis as any).get('tooltip')?.label.setAll({
        fontSize: 12,
        fill: am5.color(0x0e9eda),
      })
      ;(xAxis as any)
        .get('tooltip')
        .get('background')
        ?.setAll({
          fill: am5.color(0xffffff),
          stroke: am5.color(0x98a2b3),
        })
    }

    xAxisRef.current = xAxis

    // Create Series
    const tooltipColumn = Math.ceil(series.length / 20)
    series.forEach((s, i) => {
      let tooltip
      if (tooltipPosition === 'top') {
        let labelHTML = ''
        series.forEach((_s, _i) => {
          labelHTML += `<div class="flex text-xs gap-1"><span>${
            _s.tooltipLabel ?? _s.label
          }:</span><span class="font-bold" style="color:${
            _s.setColor ? '#98A2B3' : chartColors[_i] ?? _s.color
          }">{${_s.field}.formatNumber("${
            s.tooltipValueFormat || '#.0a'
          }")}</span></div>`
        })

        tooltip = am5.Tooltip.new(root, {
          autoTextColor: false,
          getFillFromSprite: false,
          labelHTML: `<div class="flex flex-col gap-2"><span class="text-xs font-bold">{${
            xAxisType === 'DateAxis'
              ? `x.formatDate("${
                  dateFormats[
                    xSetting?.dateAxis?.baseInterval?.timeUnit ?? 'month'
                  ]
                }")`
              : 'x'
          }}</span>${
            tooltipSubtitle
              ? tooltipSubtitle
                  .map(
                    t =>
                      `<span class="text-xs">${t.title}: <span class="font-bold">{${t.field}}</span></span>`
                  )
                  .join('')
              : ''
          }<div class="grid gap-1" style="grid-template-columns: repeat(${tooltipColumn}, minmax(0, 1fr))">${labelHTML}</div><div>`,
        })

        tooltip.label.setAll({
          fontSize: 12,
          fill: am5.color(0x0e9eda),
        })

        tooltip.get('background')?.setAll({
          fill: am5.color(0xffffff),
          stroke: am5.color(0x98a2b3),
        })
      }

      const isTransformTotal =
        series.filter(
          ({ isStack, type, isTotal = true, isTransform = false }) =>
            isStack && type === 'ColumnSeries' && isTotal && isTransform
        ).length === series.length
      const chartSeries = chart.series.push(
        am5xy[s.type].new(root, {
          name: s.label,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: s.field,
          ...(isTransformTotal ? { valueYShow: 'valueYTotalPercent' } : {}),
          valueXField: 'x',
          categoryXField: 'x',
          stacked: s.type === 'ColumnSeries' && s.isStack,
          fill: am5.color(s.setColor ? '#98A2B3' : chartColors[i] ?? s.color),
          stroke: am5.color(s.setColor ? '#98A2B3' : chartColors[i] ?? s.color),
          tooltip,
        })
      )
      if (s.gradient) {
        ;(chartSeries as am5xy.LineSeries).fills.template.set(
          'fillGradient',
          am5.LinearGradient.new(root, {
            stops: [
              {
                opacity: 1,
              },
              {
                opacity: 0.5,
              },
            ],
            rotation: 90,
          })
        )
        ;(chartSeries as am5xy.LineSeries).fills.template.setAll({
          visible: true,
          fillOpacity: 1,
        })
      }

      if (['LineSeries', 'SmoothedXLineSeries'].includes(s.type)) {
        ;(chartSeries as am5xy.LineSeries).strokes.template.setAll({
          strokeWidth: 2,
        })
      }
      const hasBullet = s.hasBullet ?? true
      if (s.type === 'SmoothedXLineSeries' && hasBullet) {
        ;(chartSeries as am5xy.SmoothedXLineSeries).bullets.push(() => {
          const sprite =
            s.bulletType === 'line'
              ? am5.Rectangle.new(root, {
                  height: 1,
                  width: am5.percent(5),
                  fill: am5.color(chartColors[i] ?? s.color),
                  stroke: am5.color(chartColors[i] ?? s.color),
                })
              : am5.Circle.new(root, {
                  radius: 4,
                  stroke: am5.color(chartColors[i] ?? s.color),
                  strokeWidth: 2,
                  fill: am5.color(0xffffff),
                })
          return am5.Bullet.new(root, {
            locationX: 0.5,
            sprite,
          })
        })
      }

      if (s.setColor) {
        ;(chartSeries as any).columns?.template.adapters.add(
          'fill',
          (fill: any, target: any) => {
            return s.setColor?.(s.field, target.dataItem.dataContext)
          }
        )
        ;(chartSeries as any).columns?.template.adapters.add(
          'stroke',
          (fill: any, target: any) => {
            return s.setColor?.(s.field, target.dataItem.dataContext)
          }
        )
      }

      seriesRefs.current[i] = chartSeries

      // Create axis ranges
      if (range && range.length > 0) {
        range.forEach(r => {
          const seriesRangeDataItem = yAxis.makeDataItem({
            value: r.from,
            endValue: r.to,
          })
          const seriesRange = chartSeries.createAxisRange(seriesRangeDataItem)
          ;(seriesRange as ILineSeriesAxisRange).fills?.template.setAll({
            visible: true,
          })
          ;(seriesRange as ILineSeriesAxisRange).fills?.template.set(
            'fill',
            am5.color(r.color ?? 0xff9500)
          )
          ;(seriesRange as ILineSeriesAxisRange).strokes?.template.set(
            'stroke',
            am5.color(r.color ?? 0xff9500)
          )

          seriesRangeDataItem.get('grid')?.setAll({
            strokeOpacity: 1,
            visible: true,
            stroke: am5.color(r.color ?? 0xff9500),
            // strokeDasharray: [10, 10],
            strokeWidth: 2,
          })

          if (r.label) {
            seriesRangeDataItem.get('label')?.setAll({
              location: 0,
              visible: true,
              text: r.label,
              inside: true,
              centerX: 0,
              centerY: am5.p100,
            })
          }
        })
      }
    })

    // Legend
    if (series.length > 1 && legendSetting.show) {
      const legendItem = am5.Legend.new(root, {
        ...(['left', 'right'].includes(legendSetting?.position ?? 'top')
          ? {
              layout: root.gridLayout,
              x: am5.percent(50),
              centerX: am5.percent(50),
              height: am5.percent(100),
              verticalScrollbar: am5.Scrollbar.new(root, {
                orientation: 'vertical',
              }),
            }
          : {
              layout: root.gridLayout,
              y: am5.percent(50),
              centerY: am5.percent(50),
              paddingBottom: 24,
            }),
        ...legendSetting.config,
      })
      let legend
      if (legendSetting?.position === 'right') {
        legend = chart.rightAxesContainer.children.push(legendItem)
        root.container._settings.paddingRight = -24
      } else if (legendSetting?.position === 'bottom') {
        legend = chart.bottomAxesContainer.children.push(legendItem)
      } else if (legendSetting?.position === 'left') {
        legend = chart.leftAxesContainer.children.unshift(legendItem)
      } else {
        legend = chart.topAxesContainer.children.push(legendItem)
      }
      legend?.markers.template.setAll({
        width: 12,
        height: 12,
      })
      legend?.labels.template.setAll({
        fontSize: 12,
        fill: am5.color(0x757575),
      })
      legend?.data.setAll(chart.series.values)
    }

    //scrollbar
    if (scroll.x) {
      const scrollbarX = am5.Scrollbar.new(root, {
        orientation: 'horizontal',
        ...(scroll.xStart ? { start: scroll.xStart } : {}),
        ...(scroll.xEnd ? { end: scroll.xEnd } : {}),
      })
      scrollbarX.thumb.setAll({
        height: 8,
        fill: am5.color(0xe4e7ec),
      })
      scrollbarX.startGrip.setAll({
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color(0xffffff),
          stroke: am5.color(0xe4e7ec),
        }),
      })
      scrollbarX.endGrip.setAll({
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color(0xffffff),
          stroke: am5.color(0xe4e7ec),
        }),
      })

      chart.set('scrollbarX', scrollbarX)
      chart.bottomAxesContainer.children.push(scrollbarX)
    }

    if (scroll.y) {
      const scrollbarY = am5.Scrollbar.new(root, {
        orientation: 'vertical',
      })
      scrollbarY.thumb.setAll({
        height: 8,
        fill: am5.color(0xe4e7ec),
      })
      scrollbarY.startGrip.setAll({
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color(0xffffff),
          stroke: am5.color(0xe4e7ec),
        }),
      })
      scrollbarY.endGrip.setAll({
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color(0xffffff),
          stroke: am5.color(0xe4e7ec),
        }),
      })
      chart.set('scrollbarY', scrollbarY)
      chart.rightAxesContainer.children.push(scrollbarY)
      root.container._settings.paddingRight = -24
    }
    if (data[0]) {
      const dataFieldsArray = Object.keys(data[0])
        .filter(key => key.includes('type_'))
        .reduce((acc: { [key: string]: string }, current: string) => {
          acc[current] = series.filter(si => si.field == current)[0]?.label
          return acc
        }, {})

      if (exportable) {
        am5plugins_exporting.Exporting.new(root, {
          menu: am5plugins_exporting.ExportingMenu.new(root, {
            useDefaultCSS: false,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            container: document.getElementById('exportdiv')!,
          }),
          filePrefix: `${id}_${moment().format()}`,
          dataSource: xAxisType === 'DateAxis' ? formatUnixSeries(data) : data,
          dataFields:
            Object.entries(dataFieldsArray).length > 1
              ? xAxisType === 'DateAxis'
                ? { x: 'Date', ...dataFieldsArray }
                : { x: 'x', ...dataFieldsArray }
              : undefined,
        })
      }
    }

    root?._logo?.dispose()

    return () => {
      root?.dispose()

      if (exportable) {
        replaceNodeWithReactComponent(
          document.getElementsByClassName('am5exporting-icon')[0]
            ?.children[0] as HTMLElement
        )
      }
    }
  }, [])

  useLayoutEffect(() => {
    if (exportable) {
      replaceNodeWithReactComponent(
        document.getElementsByClassName('am5exporting-icon')[0]
          ?.children[0] as HTMLElement
      )
    }
  }, [])

  useLayoutEffect(() => {
    const sorted_data = data.sort((a, b) =>
      typeof a.x === 'number' ? (Number(a.x) > Number(b.x) ? 1 : -1) : 0
    )
    xAxisRef.current?.data.setAll(sorted_data)
    series.forEach((s, i) => {
      seriesRefs?.current?.[i]?.data.setAll(sorted_data)
    })
  }, [data])

  const minHeight =
    props.height ??
    450 +
      (['top', 'bottom'].includes(legendSetting?.position ?? 'top')
        ? 80 * Math.ceil(series.length / 10)
        : 0)

  return (
    <div
      id={`chart-${props.id}`}
      style={{ minHeight }}
      className={`w-full`}
    ></div>
  )
}

interface ChartProps extends BaseChartProps {
  /**
   * loading state
   */
  loading: boolean
  /**
   * error
   */
  error?: {
    message: string
  }
}
const Chart = (props: ChartProps) => {
  const { loading, error, ...rest } = props
  if (loading || error) {
    return (
      <div
        style={props.height ? { minHeight: props.height } : {}}
        className={`flex flex-col w-full min-h-[400px] justify-center items-center`}
      >
        {loading ? (
          <ArrowPathIcon className="animate-spin text-primary-main w-8" />
        ) : (
          <Alert className="w-1/2 text-danger-main border border-danger-main text-center">
            {error?.message}
          </Alert>
        )}
      </div>
    )
  }
  return <BaseChart {...rest} />
}

export default memo(
  Chart,
  /**
   * return true will prevent rerender
   */
  (prevProps, nextProps) => {
    return (
      prevProps.loading == nextProps.loading &&
      prevProps.error == nextProps.error
    )
  }
)
