import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { useForm } from 'react-hook-form'

import Button from '@components/atoms/button'
import {
  CloudArrowUpIcon,
  PencilSquareIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
} from '@material-tailwind/react'

interface ManualAccountDialogProps {
  open: boolean
  statementUploadOnly?: boolean
  handleOpen: () => void
  handleSubmitManualInfo: (data: any, uploadedStatement: File) => void
}

const ManualAccountDialog = ({
  open,
  handleOpen,
  handleSubmitManualInfo,
  statementUploadOnly,
}: ManualAccountDialogProps) => {
  const [uploadedStatement, setUploadedStatement] = useState<File>()
  const [fileError, setFileError] = useState<string>('')
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data: any) => {
    !uploadedStatement && setFileError('No file found')
    fileError == '' &&
      uploadedStatement &&
      handleSubmitManualInfo(data, uploadedStatement)
  }

  const fileUpload = (acceptedFile: any) => {
    setFileError('')
    setUploadedStatement(acceptedFile[0])
  }

  return (
    <>
      <Dialog open={open} handler={handleOpen} className="overflow-auto z-40">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader>
            <div className="flex justify-between w-full">
              <span>
                {statementUploadOnly
                  ? `Enter Statement Information`
                  : `Enter Account Information`}
              </span>
              <XMarkIcon
                onClick={handleOpen}
                className="w-8 h-8 cursor-pointer hover:opacity-50"
              />
            </div>
          </DialogHeader>
          <DialogBody divider>
            <div className="flex flex-col gap-6 w-full mt-5">
              {!statementUploadOnly && (
                <>
                  <div>
                    <Input
                      {...register('bankName', {
                        pattern: {
                          value: /^[A-Za-z\s]+$/,
                          message: 'Invalid characters',
                        },
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                      })}
                      variant="static"
                      label="Bank Name"
                      placeholder="Enter the name of your bank"
                      success={true}
                    />
                    {errors.bankName && (
                      <p className="text-red text-xs">
                        {errors.bankName.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <Input
                      {...register('accNo', {
                        pattern: {
                          value: /^[0-9\s]+$/,
                          message: 'Invalid characters',
                        },
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                      })}
                      variant="static"
                      label="Account Number"
                      placeholder="Enter Account Number"
                      success={true}
                    />
                    {errors.accNo && (
                      <p className="text-red text-xs">{errors.accNo.message}</p>
                    )}
                  </div>
                  <div>
                    <Input
                      {...register('accName', {
                        pattern: {
                          value: /^[A-Za-z0-9\s]+$/,
                          message: 'Invalid characters',
                        },
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                      })}
                      variant="static"
                      label="Account Name"
                      placeholder="Enter the Name of the Account"
                    />
                    {errors.accName && (
                      <p className="text-red text-xs">
                        {errors.accName.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <Input
                      {...register('accType', {
                        pattern: {
                          value: /^[A-Za-z\s]+$/,
                          message: 'Invalid characters',
                        },
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                      })}
                      variant="static"
                      label="Account Type"
                      placeholder="Enter the Type of the Account (i.e Checking)"
                    />
                    {errors.accType && (
                      <p className="text-red text-xs">
                        {errors.accType.message}
                      </p>
                    )}
                  </div>
                </>
              )}

              <div className="flex flex-row gap-4">
                <div>
                  <Input
                    label="Statement Date"
                    type="date"
                    placeholder="Statement Date"
                    {...register('statementDate', {
                      required: {
                        value: true,
                        message: 'This is required',
                      },
                    })}
                  />

                  {errors.statementDate && (
                    <p className="text-red text-xs">
                      {errors.statementDate.message}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    {...register('closingBalance', {
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/,
                        message: 'Invalid characters',
                      },
                      required: {
                        value: true,
                        message: 'This is required',
                      },
                    })}
                    variant="static"
                    label="Closing Balance"
                    placeholder="Enter closing balance"
                  />
                  {errors.closingBalance && (
                    <p className="text-red text-xs">
                      {errors.closingBalance.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="p-10 border-dashed border-2 border-neutral-border-1 rounded-lg cursor-pointer">
                <Dropzone
                  maxFiles={1}
                  accept={{
                    'application/pdf': ['.pdf'],
                  }}
                  onDrop={acceptedFiles => fileUpload(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="flex text-xs items-center gap-4">
                          {uploadedStatement == null ? (
                            <>
                              <CloudArrowUpIcon className="w-6 h-6" />
                              <span className="text-xs">
                                Choose or drop files here
                              </span>
                            </>
                          ) : (
                            <section className="flex justify-between w-full">
                              <span className="text-xs">
                                {uploadedStatement.name}
                              </span>
                              <PencilSquareIcon className="w-5 h-5" />
                            </section>
                          )}
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              <span className="text-xs">
                1 file maximum. Only PDF files allowed
              </span>
              {fileError != '' && (
                <p className="text-red text-xs">{fileError}</p>
              )}
            </div>
          </DialogBody>
          <DialogFooter>
            <div>
              <Button
                type="submit"
                className="rounded-lg flex-1"
                disabled={Object.keys(errors).length != 0 || fileError != ''}
                color={'primary'}
              >
                Upload & Save
              </Button>
            </div>
          </DialogFooter>
        </form>
      </Dialog>
    </>
  )
}
export default ManualAccountDialog
