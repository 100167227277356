const GTR_MAX_RED = 255
const GTR_MIN_RED = 52
const GTR_MAX_GREEN = 199
const GTR_MIN_GREEN = 59

/**
 *
 * @param {number} percentage
 * @param {boolean} toHEX
 */
export const greenToRed = (percentage: number, toHEX: boolean) => {
  percentage = Math.min(Math.max(0, percentage), 100)
  const red =
    GTR_MIN_RED +
    ((percentage > 30 ? 100 : percentage) / 100) * (GTR_MAX_RED - GTR_MIN_RED)
  const green =
    GTR_MIN_GREEN + ((100 - percentage) / 100) * (GTR_MAX_GREEN - GTR_MIN_GREEN)
  const blue = 0

  const rgb = new Uint8Array([red, green, blue])

  if (toHEX) {
    return `#${intToHex(rgb[0])}${intToHex(rgb[1])}00`
  }

  return rgb
}

const intToHex = (number: number) => {
  const hex = number.toString(16)
  return hex.length == 1 ? '0' + hex : hex
}
