import React, { Fragment } from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'

import { SelectedTagsProps } from './interfaces'

const SelectedTags = ({
  selectedTags,
  onTagRemoved,
  className,
  iconClassName,
}: SelectedTagsProps) => {
  return (
    <Fragment>
      <div className="flex no-wrap">
        {(selectedTags ?? []).map((tag, tagIdx) => (
          <span
            key={`${tagIdx}-${tag}`}
            className={`${
              className ??
              `border rounded-full border-primary-main text-primary-main bg-primary-surface-2 text-xs px-2 py-1 flex flex-inline items-center justify-center mr-1 h-[22px] whitespace-nowrap overscroll-none scrollbar-thin`
            }`}
          >
            {tag}
            <XMarkIcon
              className={`${iconClassName ?? `w-3 h-3 ml-1`}`}
              onClick={() => onTagRemoved(tagIdx)}
            />
          </span>
        ))}
      </div>
    </Fragment>
  )
}

export default SelectedTags
