import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'

import FilterCurrency from '@components/filters/filter-currency'
import FilterDateRange from '@components/filters/filter-date-range'
import FilterDebtDeal from '@components/filters/filter-debt-deal'
import FilterDocCentre from '@components/filters/filter-doc-centre'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'

import AdvanceRequest from './advance-request'
import DocumentCentre from './document-centre'
import Waterfall from './waterfall'
// import Document from './document'
// import Reporting from './reporting'
// import TaskList from './task-list'
// import Waiver from './waiver'

const ActionsL3 = () => {
  const {
    company,
    appliedFilters,
    setAppliedFilters,
    activeFilters,
    optionFilters,
  } = useContext(AuthContext)
  const { debtDealOptions } = optionFilters
  const { activeDebtDeal, docCategory, docStatus, docKeyword } = activeFilters
  const { activeDebtDeal: appliedActiveDebtDeal = 0 } = appliedFilters
  const activeFacility = debtDealOptions?.[appliedActiveDebtDeal]
  const facility = activeFacility?.facility

  const { filter_access, get_access } = useUserAccessFeature()
  const FEATURE = `${facility}_manage_monitor_actions`

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (
      !filterReady &&
      activeDebtDeal !== undefined &&
      docCategory &&
      docStatus &&
      docKeyword == ''
    ) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters, activeDebtDeal])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset = false
  const [searchParams] = useSearchParams()

  const tab = searchParams.get('tab')

  const tabs = [
    { label: 'Document Centre', component: DocumentCentre },
    {
      label: 'Waterfall',
      component: Waterfall,
      feature: 'has_waterfall_report',
    },
    {
      label: 'Advance Request',
      component: AdvanceRequest,
      feature: 'has_advance_request',
    },
  ]

    .filter(
      t =>
        filter_access(
          `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`
        ) &&
        (t.feature && !!activeFacility
          ? activeFacility?.[t.feature] == 'true'
          : true)
    )
    .map(t => {
      const can_sign = get_access(
        `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`,
        'sign'
      )
      const can_create = get_access(
        `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`,
        'create'
      )
      return {
        ...t,
        component: <t.component can_sign={can_sign} can_create={can_create} />,
      }
    })

  return (
    <L3TabsLayout
      title="Cashflows"
      filters={{
        left:
          tab == 'document-centre' ? (
            <FilterDocCentre />
          ) : (
            <FilterCurrency hideSelect />
          ),
        middle: (
          <FilterDateRange
            forcedRange={{
              fDateStart: debtDealOptions?.[activeDebtDeal ?? 0]?.agreement_date
                ? moment(debtDealOptions[activeDebtDeal ?? 0]?.agreement_date)
                : moment(company?.date_start),
              fDateEnd: moment().add(1, 'month'),
            }}
          />
        ),
        right: <FilterDebtDeal />,
      }}
      disableReset={disableReset}
      tabs={tabs}
    />
  )
}
export default ActionsL3
