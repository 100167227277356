import React from 'react'

const Icon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="85"
      height="64"
      viewBox="0 0 85 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.666748"
        y="53.7144"
        width="4"
        height="10.2857"
        className={className}
      />
      <rect
        x="8.66675"
        y="50.2856"
        width="4"
        height="13.7143"
        className={className}
      />
      <rect
        x="16.6667"
        y="61.7144"
        width="4"
        height="2.28571"
        className={className}
      />
      <rect x="24.6667" y="24" width="4" height="40" className={className} />
      <rect
        x="32.6667"
        y="28.5713"
        width="4"
        height="35.4286"
        className={className}
      />
      <rect
        x="40.6667"
        y="50.2856"
        width="4"
        height="13.7143"
        className={className}
      />
      <rect x="48.6667" y="24" width="4" height="40" className={className} />
      <rect x="56.6667" width="4" height="64" className={className} />
      <rect x="64.6667" y="8" width="4" height="56" className={className} />
      <rect
        x="72.6667"
        y="46.8569"
        width="4"
        height="17.1429"
        className={className}
      />
      <rect
        x="80.6667"
        y="52.5713"
        width="4"
        height="11.4286"
        className={className}
      />
    </svg>
  )
}

export default Icon
