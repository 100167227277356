import React from 'react'

import L4TabsLayout from '@components/layouts/l4-tabs-layout'

import OutstandingDelinqL5 from './outstanding-delinq'

const OutstandingDelinqL4 = ({ exportable }: { exportable: boolean }) => {
  const tabs = [
    {
      label: 'Outstanding by Delinquency',
      component: <OutstandingDelinqL5 exportable={exportable} />,
    },
  ]

  return <L4TabsLayout tabs={tabs} />
}

export default OutstandingDelinqL4
