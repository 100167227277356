import React, { useEffect, useState } from 'react'
import moment from 'moment'

import Chart, { chartColors } from '@components/chart'
import { REMOUNT_MS } from '@constants/config'
import { greenToRed } from '@helpers/green-to-red'
import { CovenantListResponse, CovenantType } from '@interfaces/covenant'

import { getLabel, getLabelFormat, useCovenantDetail } from '../helper'
import CovenantL4SummarySide from '../summary/side'

interface DefaultCovenantChartProps {
  covenants: CovenantListResponse[]
  type: CovenantType
}

const getRandomColor = () =>
  '#00' +
  Math.floor(Math.random() * 16777215)
    .toString(16)
    .slice(2, -2) +
  'ff'

const DefaultCovenantChart = ({
  covenants,
  type,
}: DefaultCovenantChartProps) => {
  const conv = covenants?.[0]
  const isInverted = Number(conv?.invert_colors) === 1

  const {
    chartData,
    breachCounter,
    isFetching,
    covenantsQueries,
    maxTrigger,
    maxData,
    minTrigger,
    minData,
  } = useCovenantDetail(covenants, type)

  const hasTrigger =
    !!chartData?.[0]?.[
      `${covenants?.[0]?.[`${type}_covenant`]
        .toLowerCase()
        .split(' ')
        .join('_')}_trigger_1`
    ]

  const triggers = hasTrigger
    ? Array(Number(covenants?.[0]?.triggers))
        .fill('')
        .map((_, i: number) => {
          return {
            label: `${
              covenants?.length > 1 ? covenants?.[0]?.[`${type}_covenant`] : ''
            } Trigger ${i + 1}`,
            tooltipValueFormat: getLabelFormat(conv, type),
            type: 'SmoothedXLineSeries',
            field: `${covenants?.[0]?.[`${type}_covenant`]
              .toLowerCase()
              .split(' ')
              .join('_')}_trigger_${i + 1}`,
            hasBullet: false,
            color: getRandomColor(),
          }
        })
    : []

  const TRIAL_PERIOD_DAYS = parseFloat((conv?.test_day ?? 60).toString())
  const chartLastDate = moment.utc().subtract(TRIAL_PERIOD_DAYS, 'days')
  const eligibleData = chartData?.filter(x =>
    moment.utc(x.cohort).isSameOrBefore(moment.utc(chartLastDate))
  )
  const lastData =
    eligibleData[eligibleData.length - 1] ??
    chartData?.[chartData.length - 1] ??
    {}

  const setColor = (field: string, data: any) => {
    if (data.x > lastData.x) {
      return '#757575'
    } else if (!hasTrigger) {
      return greenToRed(0, true).toString()
    }

    const triggerKeys = Object.keys(data).filter(k =>
      k.includes(
        `${conv?.[`${type}_covenant`]
          .toLowerCase()
          .split(' ')
          .join('_')}_trigger_`
      )
    )
    const numberOfTriggers = Number(conv?.triggers) ?? 0
    const segments = numberOfTriggers
    const triggerValues = triggerKeys
      .map(k => data[k])
      .sort((a, b) => (isInverted ? b - a : a - b))

    const pctgs = covenants.map(c => {
      let pctg = 0
      const key = c?.[`${type}_covenant`].toLowerCase().split(' ').join('_')
      for (let i = 0; i <= segments; i++) {
        pctg = i
        if (isInverted && data[key] >= triggerValues[i]) {
          break
        }
        if (!data[key] || (!isInverted && data[key] <= triggerValues[i])) {
          break
        }
      }
      return pctg
    })
    const minPctg = isInverted ? Math.max(...pctgs) : Math.min(...pctgs)

    const percent = (minPctg / segments) * 100
    return greenToRed(percent, true).toString()
  }

  const series = [
    ...covenants.map(c => ({
      label: c?.[`${type}_covenant`],
      tooltipValueFormat: getLabelFormat(conv, type),
      type: 'ColumnSeries',
      field: c?.[`${type}_covenant`].toLowerCase().split(' ').join('_'),
      setColor,
    })),
    ...triggers,
  ]

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [covenants])
  /** end */

  const cohort_label = conv?.cohort_pool || 'month'
  const xLabel = cohort_label[0].toUpperCase() + cohort_label.slice(1)

  return (
    <div className="flex gap-6">
      <div className="flex-1">
        <div className="my-6 grid grid-cols-4 gap-4 px-8">
          {[
            { color: '#34c700', label: 'No Trigger Breach' },
            ...triggers.map((t, i) => ({
              color: greenToRed(
                ((i + 1) / triggers.length) * 100,
                true
              ) as string,
              label: `Trigger ${i + 1} Breached`,
            })),
            { color: '#757575', label: 'Not in Test Period' },
          ].map((t, i) => {
            return (
              <div key={i} className="text-xs flex items-center gap-1">
                <span
                  style={{
                    display: 'inline-block',
                    height: 14,
                    width: 14,
                    backgroundColor: t.color,
                    borderRadius: 4,
                  }}
                />
                {t.label}
              </div>
            )
          })}
          {triggers.map((t, i) => {
            return (
              <div key={i} className="text-xs flex items-center gap-1">
                <span
                  style={{
                    display: 'inline-block',
                    height: 2,
                    width: 14,
                    backgroundColor:
                      chartColors[i + covenants.length] ?? t.color,
                  }}
                />
                {t.label}
              </div>
            )
          })}
        </div>
        <Chart
          loading={isProcessing || (isFetching as unknown as boolean)}
          id={covenants.map(x => x?.[`${type}_covenant`]).join('|')}
          yLabel={getLabel(conv, type)}
          yFormat={getLabelFormat(conv, type)}
          ySetting={{
            max: hasTrigger
              ? maxTrigger > maxData
                ? maxTrigger * 1.1
                : maxData > maxTrigger * 2
                ? maxTrigger * 2
                : maxData
              : undefined,
            min: hasTrigger
              ? minTrigger < minData
                ? minTrigger * 1.1
                : minData < minTrigger * 2
                ? minData
                : minTrigger * 2
              : undefined,
          }}
          xSetting={{
            renderer: {
              cellStartLocation: 0.1,
              cellEndLocation: 0.9,
            },
            dateAxis: {
              baseInterval: {
                timeUnit: conv?.cohort_pool ?? 'month',
                count: 1,
              },
            },
          }}
          xAxisType={
            covenantsQueries.find(cq => (cq.data ?? []).length > 0)
              ? 'DateAxis'
              : 'CategoryAxis'
          }
          xLabel={xLabel}
          data={chartData as any}
          series={series as any}
          tooltipPosition="bottom"
          legendSetting={{ show: false }}
          exportable={false}
        />
      </div>
      <CovenantL4SummarySide
        covenants={covenants}
        type={type}
        chartData={chartData}
        breachCounter={breachCounter}
      />
    </div>
  )
}

export default DefaultCovenantChart
