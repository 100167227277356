import React, { useContext, useEffect } from 'react'
import moment from 'moment'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { LayoutFilter } from '@interfaces/analytics-risk-filter-type-key'
import {
  Popover,
  PopoverContent,
  PopoverHandler,
} from '@material-tailwind/react'

const FORMAT = 'YYYY-MM-DD'

const FilterDateRangeV2 = ({ isCohort = false }: { isCohort?: boolean }) => {
  const { activeFilters, setActiveFilters, company } = useContext(AuthContext)

  const {
    dateStart,
    dateEnd = moment.utc(),
    dateStartCohort,
    dateEndCohort = moment.utc(),
    range,
  } = activeFilters

  const company_end = moment.utc(company?.date_end)
  const end = company_end.isAfter(moment.utc())
    ? moment.utc(dateEnd)
    : company_end

  const start =
    range <= 0
      ? moment.utc(company?.date_start)
      : end.clone().subtract(range, 'years')

  const end_cohort = moment.utc(company?.date_end_cohort)

  const start_cohort =
    range <= 0
      ? moment.utc(company?.date_start)
      : end_cohort.clone().subtract(range, 'years')

  useEffect(() => {
    if (company) {
      setActiveFilters((prev: LayoutFilter) => {
        return {
          ...prev,
          range: range ?? 0,
          dateEnd: end.format(),
          dateStart: start.format(),
          dateEndCohort: end_cohort.format(),
          dateStartCohort: start_cohort.format(),
        }
      })
    }
  }, [company])

  useEffect(() => {
    if (range !== -1) {
      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        dateEnd: end.format(),
        dateStart: start.format(),
        dateEndCohort: end_cohort.format(),
        dateStartCohort: start_cohort.format(),
      }))
    }
  }, [range])

  return (
    <div className="filter__date-range  flex flex-col gap-1">
      <Typography className="text-neutral-body-2 text-sm">
        Date Range
      </Typography>
      <Popover placement="bottom">
        <PopoverHandler>
          <button className="flex justify-between border border-neutral-border-2 !bg-neutral-white shadow-lg rounded-lg max-w-full min-h-[40px] gap-4 !outline-none flex flex-col sm:flex-row items-center px-4">
            <Typography className="text-sm !text-neutral-black font-medium">
              {moment
                .utc(isCohort ? dateStartCohort : dateStart)
                .format(FORMAT)}
            </Typography>
            <Typography className="text-sm !text-neutral-black">to</Typography>
            <Typography className="text-sm !text-neutral-black font-medium">
              {moment.utc(isCohort ? dateEndCohort : dateEnd).format(FORMAT)}
            </Typography>
            <ChevronDownIcon className="h-3 w-3" />
          </button>
        </PopoverHandler>
        <PopoverContent className="z-[9999] border-t border-neutral-border-2 rounded-lg flex flex-col shadow-lg">
          <Typography className="text-sm !text-neutral-black">From</Typography>
          <input
            type="date"
            className="border border-neutral-border-2 rounded-lg px-4 py-2 !outline-none"
            value={moment
              .utc(isCohort ? dateStartCohort : dateStart)
              .format(FORMAT)}
            min={moment.utc(isCohort ? start_cohort : start).format(FORMAT)}
            max={moment.utc(isCohort ? dateEndCohort : dateEnd).format(FORMAT)}
            onChange={e => {
              const key = isCohort ? 'dateStartCohort' : 'dateStart'
              setActiveFilters((prev: LayoutFilter) => ({
                ...prev,
                [key]: e.target.value,
                range: -1,
              }))
            }}
          />
          <Typography className="text-sm !text-neutral-black mt-4">
            To
          </Typography>
          <input
            type="date"
            className="border border-neutral-border-2 rounded-lg px-4 py-2 !outline-none"
            value={moment
              .utc(isCohort ? dateEndCohort : dateEnd)
              .format(FORMAT)}
            min={moment.utc(isCohort ? start_cohort : start).format(FORMAT)}
            max={moment.utc(isCohort ? dateEndCohort : dateEnd).format(FORMAT)}
            onChange={e => {
              const key = isCohort ? 'dateEndCohort' : 'dateEnd'
              setActiveFilters((prev: LayoutFilter) => ({
                ...prev,
                [key]: e.target.value,
                range: -1,
              }))
            }}
          />
          <Typography className="text-sm !text-neutral-black mt-4">
            Last
          </Typography>
          <div className="flex w-full">
            {Array(3)
              .fill('')
              .map((x, i) => (
                <Button
                  key={i}
                  color={range === i + 1 ? 'primary' : 'default'}
                  onClick={() => {
                    setActiveFilters((prev: LayoutFilter) => ({
                      ...prev,
                      range: i + 1,
                    }))
                  }}
                  className={`flex-1 h-[40px] !outline-none rounded-lg ${
                    i === 0 ? 'rounded-r-none' : 'rounded-none'
                  }`}
                >{`${i + 1}Y`}</Button>
              ))}
            <Button
              className="rounded-lg rounded-l-none flex-1 !outline-none"
              color={range === 0 ? 'primary' : 'default'}
              onClick={() => {
                setActiveFilters((prev: LayoutFilter) => ({
                  ...prev,
                  range: 0,
                }))
              }}
            >
              All
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
export default FilterDateRangeV2
