import BalanceEntry from '@interfaces/balance-entry'
import BankAccount from '@interfaces/bank-account'

import adminApiClient from './client'

class BanksService {
  static async addBankTable(slugName: string | undefined) {
    return adminApiClient.post(`bank-account/create-table`, {
      slug_name: slugName,
    })
  }

  static async addBankAccount(bankInfo: BankAccount) {
    return adminApiClient.post(`bank-account/create`, {
      bankInfo,
    })
  }

  static async editBankAccount(bankInfo: BankAccount) {
    return adminApiClient.patch(`bank-account/edit`, {
      bankInfo,
    })
  }

  static async addBalance(balanceInfo: BalanceEntry) {
    return adminApiClient.post(`bank-account/add-balance-entry`, {
      balanceInfo,
    })
  }

  static async addBankSchema(slugName: string | undefined) {
    return adminApiClient.post(`bank-account/create-schema`, {
      slug_name: slugName,
    })
  }

  static async uploadStatement(
    statement: File,
    slugName: string,
    fileName: string
  ) {
    const formData = new FormData()
    formData.append('file', statement, fileName)
    formData.append('slug_name', slugName)
    formData.append('file_key', fileName)

    return adminApiClient.post(`bank-account/upload-statement`, formData)
  }

  static async downloadStatement(slugName: string, fileName: string) {
    return adminApiClient.post(`bank-account/download-statement`, {
      file_key: fileName,
      slug_name: slugName,
    })
  }

  static async getAllActiveAccounts(slugName: string | undefined) {
    return adminApiClient.post(`bank-account/pull-active-accounts`, {
      slug_name: slugName,
    })
  }

  static async getStatementBalances(
    slugName?: string,
    schemaNo?: number | null
  ) {
    return adminApiClient.post(`bank-account/pull-statement-balances`, {
      slug_name: slugName,
      schema_no: schemaNo,
    })
  }

  static async getPlaidLinkToken(params: {
    slug_name: string
    access_token?: string
  }) {
    const res = await adminApiClient.post(
      `bank-account/plaid-link-token`,
      params
    )

    return res?.data
  }

  static async setPlaidAccessToken(payload: {
    slug_name: string
    public_token: string
    metadata: Record<string, any>
    entity_ownership: string
    account_use: string
  }) {
    return await adminApiClient.post(`bank-account/plaid-access-token`, {
      ...payload,
    })
  }

  static async updatePlaidAccessToken(params: {
    slug_name: string
    access_token: string
  }) {
    return await adminApiClient.post(
      `bank-account/update-plaid-access-token`,
      params
    )
  }
}

export default BanksService
