import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { getStaleMins } from '@helpers/stale-timer'
import { CashflowService } from '@services/api-manage/monitor-cashflow'

export const useFSVNCustomData = (facility: string): [boolean, any] => {
  const [result, setResult] = useState<[boolean, any]>([true, {}])

  const filters = {
    slug_name: 'fsvn',
    facility,
  }

  const { data, isFetching } = useQuery(
    ['paymentScheduleStatistic', filters],
    () => CashflowService.getPaymentScheduleStatistic(filters),
    {
      ...getStaleMins(),
    }
  )

  useEffect(() => {
    !isFetching && setResult([false, data])
  }, [isFetching])

  return result
}
