import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { Filter, FilterItem } from '@interfaces/filter'
import { RisksFilters } from '@interfaces/risk'
import { CollectionService } from '@services/api-analytics/risk-collection'

const CashCollectedL5 = ({ exportable }: { exportable: boolean }) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes = [],
    activeType,
  } = appliedFilters
  const { categoryTypeOptions } = optionFilters
  const isAggregate = activeType === 'All'
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const filters: RisksFilters = {
    date_from: moment.utc(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEndCohort).format('YYYY-MM-DD'),
    slug_name: company?.slug_name,
    filters: [],
  }

  const {
    error: errorAggregated,
    data: fetchedDataAggregated,
    isFetching: isFetchingAggregated,
  } = useQuery(
    ['cashCollected', filters, true],
    () =>
      CollectionService.getCashCollected({
        ...filters,
        is_aggregate: true,
        filters: categoryTypeOptions?.reduce(
          (prev: string[], cur: Filter) => [
            ...prev,
            ...cur.type_val.map((x: FilterItem) => x.id),
          ],
          []
        ),
      }),
    getStaleMins()
  )

  const {
    error: errorNonAggregated,
    data: fetchedDataNonAggregated,
    isFetching: isFetchingNonAggregated,
  } = useQuery(
    ['cashCollected', filters, false, categoryTypes],
    () =>
      CollectionService.getCashCollected({
        ...filters,
        is_aggregate: false,
        filters: categoryTypes,
      }),
    {
      ...getStaleMins(),
      enabled: isAggregate === false,
    }
  )

  const error = isAggregate ? errorAggregated : errorNonAggregated
  const isFetching = isAggregate
    ? isFetchingAggregated
    : isFetchingNonAggregated

  const { data } =
    (isAggregate ? fetchedDataAggregated : fetchedDataNonAggregated) ?? {}

  const cohort = (data || []).filter(
    (v, i, a) => a.findIndex(v2 => v2.cohort === v.cohort) === i
  )

  let maxVal = 0

  const chartData = cohort.map(c => {
    const result: { x: number; [key: string]: any } = {
      x: moment(c.cohort).valueOf(),
    }
    const cohortData = (data || []).filter(d => d.cohort === c.cohort)
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
      if (result.type_all > maxVal) {
        maxVal = result.type_all
      }
    } else {
      ;[0, ...categoryTypes].forEach((ct: number) => {
        const cohortCategory =
          ct === 0
            ? fetchedDataAggregated?.data?.find(
                x => x.cohort === c.cohort && x.id === ct
              )
            : cohortData.find(cd => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
        if (result[`type_${ct}`] > maxVal) {
          maxVal = result[`type_${ct}`]
        }
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  const cashCollectedSeries = isAggregate
    ? [
        {
          label: 'All',
          tooltipValueFormat: '#.0a%',
          type: 'ColumnSeries',
          field: 'type_all',
        },
      ]
    : [0, ...categoryTypes].map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: `${typeVal ? typeVal.type : 'All'}`,
          tooltipValueFormat: '#.0a%',
          field: `type_${ct}`,
          color,
          type: 'ColumnSeries',
        }
      })

  return (
    <L5ChartstatLayout
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`riskCollectionCashCollected_by_${activeType}`}
          yLabel="Percentage"
          ySetting={maxVal < 5 ? { max: 5, maxPrecision: 1 } : {}}
          yFormat="#.0a%"
          data={chartData}
          series={cashCollectedSeries as any}
          exportable={exportable}
          error={error as { message: string }}
        />
      }
    />
  )
}

export default CashCollectedL5
