import React from 'react'

import L4TabsLayout from '@components/layouts/l4-tabs-layout'

import BalanceL5 from './balance'

const BalanceL4 = () => {
  const tabs = [
    {
      label: '',
      component: <BalanceL5 />,
    },
  ]

  return <L4TabsLayout tabs={tabs} />
}

export default BalanceL4
