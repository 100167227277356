import React from 'react'
import { PDFDocument } from 'pdf-lib'

import { pdf } from '@react-pdf/renderer'
import { AuditService } from '@services/api-manage/audit'

import CashAuditReportStartTemplate from '../templates/cash-start-template'
import ExhibitTemplate from '../templates/exhibit-template'
import CashAuditReportEndTemplate from '../templates/report-end-template'
import UnderwritingAuditReportStartTemplate from '../templates/underwriting-start-template'

export const generateReport = async (
  reportData: any,
  auditType: 'Payment' | 'Underwriting',
  slug?: string,
  facility?: string
) => {
  const data = reportData.item_data
  // Create a new PDF document to merge the pages into
  const mergedPDFDoc = await PDFDocument.create()
  const reportStartBlob = await pdf(
    auditType == 'Payment' ? (
      <CashAuditReportStartTemplate data={reportData} />
    ) : (
      <UnderwritingAuditReportStartTemplate data={reportData} />
    )
  ).toBlob()

  const reportStartDoc = await PDFDocument.load(
    await reportStartBlob.arrayBuffer()
  )

  // Add pages from the binary PDF to the merged PDF
  const reportStartPages = reportStartDoc.getPages()
  const indices = reportStartDoc.getPageIndices()

  for (let k = 1; k <= reportStartPages.length; k++) {
    const [copiedPage] = await mergedPDFDoc.copyPages(reportStartDoc, indices)
    mergedPDFDoc.addPage(copiedPage)
    indices.shift()
  }

  //attach user uploaded pdfs to the new pdf report
  for (let j = 0; j < data.length; j++) {
    const item = data[j]

    // Load the exhibit PDF from the blob - cascade generated
    const exhibitBlob = await pdf(
      <ExhibitTemplate data={item.info} index={j + 1} />
    ).toBlob()

    const exhibitPDFDoc = await PDFDocument.load(
      await exhibitBlob.arrayBuffer()
    )

    // Add pages from the binary PDF to the merged PDF
    const exhibitPdfPages = exhibitPDFDoc.getPages()
    const indices = exhibitPDFDoc.getPageIndices()

    for (let i = 1; i <= exhibitPdfPages.length; i++) {
      const [copiedPage] = await mergedPDFDoc.copyPages(exhibitPDFDoc, indices)
      mergedPDFDoc.addPage(copiedPage)
      indices.shift()
    }
    if (!!item.keys && item.keys.length > 0) {
      //handle merging user uploaded pdfs to the above generated pdf
      const attachmentPromises = item.keys.map(async (key: string) => {
        const data = await AuditService.getAttachmentByKey({
          slug_name: slug,
          key: key,
          facility: facility,
        })

        if (!!data) {
          try {
            // Create a Uint8Array from the streamed data - user uploaded documents
            const binaryData = new Uint8Array(data)

            // Load the binary PDF from binary data
            const binaryPDFDoc = await PDFDocument.load(binaryData)

            // Add pages from the binary PDF to the merged PDF
            const binaryPages = binaryPDFDoc.getPages()
            const indices = binaryPDFDoc.getPageIndices()

            for (let i = 1; i <= binaryPages.length; i++) {
              const [copiedPageX] = await mergedPDFDoc.copyPages(
                binaryPDFDoc,
                indices
              )
              mergedPDFDoc.addPage(copiedPageX)
              indices.shift()
            }
          } catch (error) {
            return error
          }
        }
      })
      await Promise.all(attachmentPromises)
    }
  }

  const reportEndBlob = await pdf(<CashAuditReportEndTemplate />).toBlob()

  const reportEndDoc = await PDFDocument.load(await reportEndBlob.arrayBuffer())

  // Add pages from the binary PDF to the merged PDF
  const reportEndPages = reportEndDoc.getPages()
  const indicesEnd = reportEndDoc.getPageIndices()

  for (let k = 1; k <= reportEndPages.length; k++) {
    const [copiedPage] = await mergedPDFDoc.copyPages(reportEndDoc, indicesEnd)
    mergedPDFDoc.addPage(copiedPage)
    indicesEnd.shift()
  }
  // Serialize the merged PDF to bytes
  const mergedPDFBytes = await mergedPDFDoc.save()

  // Create a Blob from the merged PDF bytes
  const mergedBlob = new Blob([mergedPDFBytes], {
    type: 'application/pdf',
  })

  // Create a URL for the merged PDF
  const docUrl = URL.createObjectURL(mergedBlob)

  return docUrl
}
