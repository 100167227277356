import { Filter } from '@interfaces/filter'
import { EntityInfo } from '@interfaces/financial'

import apiClient from './client'

class FilterService {
  static async getFilters(slug_name: string) {
    return apiClient.post<Filter[]>(`risk-traction-filters/retrieve`, {
      slug_name,
    })
  }

  static async getEntities(slug_name: string) {
    return apiClient.post<EntityInfo[]>(
      `risk-traction-filters/retrieve-entities-per-company`,
      {
        slug_name,
      }
    )
  }

  static async getAvgTerm(params: {
    slug_name?: string
    filters: any[]
    date_from: string
    date_to: string
  }) {
    const { data } = await apiClient.post<string | number>(
      `risk-traction-filters/avg-term`,
      params
    )

    return data
  }
}

export default FilterService
