import React, { FC } from 'react'

interface L5ChartStatProps {
  /**
   * chart content
   */
  chart: JSX.Element
  /**
   * L5 stat content
   */
  stat?: JSX.Element
}

/**
 * Base layout for L5 that has chart and stat
 * @param {L5ChartStatProps} props Component properties
 * @returns JSX
 */
const L5ChartstatLayout: FC<L5ChartStatProps> = ({ chart, stat }) => {
  return (
    <div className="flex flex-col lg:flex-row ">
      <div className="flex-1 w-full">{chart}</div>
      {stat && (
        <div className="border-t border-t-neutral-border-2 mt-10 lg:mt-0 lg:border-0 lg:ml-4 lg:w-[162px] lg:-translate-y-12">
          {stat}
        </div>
      )}
    </div>
  )
}

export default L5ChartstatLayout
