import React, { useContext, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import Button from '@components/atoms/button'
import FormInput from '@components/form/form-input'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { CovenantType } from '@interfaces/covenant'
import {
  SignatureRequest,
  TemplateDialogProps,
} from '@interfaces/manage-signature'
import { Dialog, DialogBody } from '@material-tailwind/react'
import { pdf } from '@react-pdf/renderer'
import { PDFViewer } from '@react-pdf/renderer'
import { CovenantService } from '@services/api-manage/covenant'
import { FacilityDetailsService } from '@services/api-manage/facility-details'

import { CustomFields, SignerInput } from '../components'
import { getCustomData } from '../custom-data'

import { renderTemplate } from './templates'

interface Props extends TemplateDialogProps {
  covenantNames: string[]
}

const FinancialCovenantCertAction = ({
  form,
  user,
  createEmbeddedSignRequest,
  uploadDocument,
  handler,
  dialogOpen,
  covenantNames,
  category,
  docData,
}: Props) => {
  const { company, activeFilters, optionFilters } = useContext(AuthContext)
  const { activeDebtDeal } = activeFilters
  const { debtDealOptions = [] } = optionFilters
  const [agent, setAgent] = useState<string>('')
  const [borrower, setBorrower] = useState<string>('')
  const [guarantors, setGuarantors] = useState<string>('')
  const [effectiveDate, setEffectiveDate] = useState<Moment>(
    moment(docData?.effective_date)
  )
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = form

  const signers = category?.signers ? JSON.parse(category.signers) : undefined

  const [isLoadingCustomData, customData] = getCustomData(category)

  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility ?? '',
    covenant_name: 'Max First Time Borrowers',
    covenant_type: 'financial' as CovenantType,
    date: moment(effectiveDate).format('YYYY-MM-DD'),
  }

  const { mutate: getPartyDetails, isLoading: isLoadingPartyDetails } =
    useMutation(
      (params: { facility: string; slug_name: string }) => {
        return FacilityDetailsService.getDealPartyDetailsRequest(params)
      },
      {
        onSuccess: data => {
          const guarantorsString = data
            .filter(entry => entry.type === 'Guarantor')
            .map(entry => entry.legal_name)
            .join(' and ')
          setGuarantors(guarantorsString)
          const borrowerValue = data.filter(
            entry => entry.type === 'Borrower'
          )[0].legal_name
          setBorrower(borrowerValue)
          const agentValue = data.filter(entry => entry.type === 'Agent')[0]
            .legal_name
          setAgent(agentValue)
        },
      }
    )

  const covenantQueries = covenantNames.map(covenantName =>
    useQuery(
      ['covenant-raw', covenantName, filters],
      () =>
        CovenantService.getRawData({
          ...filters,
          covenant_name: covenantName,
        }),
      {
        ...getStaleMins(),
        enabled: !!covenantNames.length,
      }
    )
  )

  const allTableData =
    covenantNames?.length &&
    covenantQueries.length &&
    covenantQueries.every(query => !query.isLoading)
      ? covenantNames.map((covenant: string) => {
          const query = covenantQueries.find(
            (query, index) => covenantNames[index] === covenant
          )

          const queryData = query?.data

          const firstData = (queryData ?? [])?.[0] ?? {}

          const columns: any = Object.keys(firstData)
            .filter(x => firstData[x] !== null)
            .map(x => ({
              field: x,
              title: x.split('_').join(' '),
              align: 'right',
            }))

          const data = queryData
            ?.sort((a: any, b: any) => (a.cohort < b.cohort ? -1 : 1))
            .filter((x: any) => {
              // show trigger breached
              return x[columns[columns.length - 1].field] !== 0 ? 'Yes' : 'No'
            })
          return { [covenant]: { data, columns } }
        })
      : []

  const onSubmit = (cd: any) => {
    if (!!signers?.signers && !signers.signers.length) {
      uploadDoc(cd)
    } else {
      createSignableDoc(cd)
    }
  }

  const uploadDoc = async (cd: any) => {
    const formData = new FormData()
    if (activeFacility?.slug_name || company?.slug_name) {
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name
      )
    }
    formData.append(
      'effective_date',
      moment(effectiveDate).format('DD-MM-YYYY')
    )
    formData.append('category', 'Financial Covenant Certificate')
    formData.append(
      'title',
      `Form of Compliance Certificate – Financial Covenants - ${moment().format(
        'DD-MMM-YYYY'
      )}`
    )
    formData.append('facility', debtDealOptions?.[activeDebtDeal]?.facility)
    user?.email && formData.append('author_email', `${user.email}`)
    user?.name && formData.append('author_name', `${user.name}`)
    signers?.saveCalculatedData &&
      formData.append('calculated_data', JSON.stringify(customData))
    formData.append('form_values', JSON.stringify(cd))
    formData.append(
      'files',
      await pdf(
        renderTemplate(category, {
          agent,
          borrower,
          guarantors,
          agreementDate: activeFacility?.agreement_date
            ? moment.utc(activeFacility.agreement_date).format('DD-MM-YYYY')
            : '',
          effectiveDate: moment(effectiveDate).format('DD-MM-YYYY'),
          tableData: allTableData,
          covenantNames: covenantNames?.length ? covenantNames : [],
          customData,
          formData: cd,
        })
      ).toBlob()
    )
    uploadDocument(formData)
    handler()
    toast.loading('Request in progress', { autoClose: false })
    reset()
  }

  const createSignableDoc = async (cd: any) => {
    const formData = new FormData()
    user?.email && formData.append('requester_email', `${user.email}`)
    user?.name && formData.append('requester_name', `${user.name}`)
    if (activeFacility?.slug_name || company?.slug_name) {
      formData.append(
        'slug_name',
        activeFacility.slug_name ?? company?.slug_name
      )
    }
    activeFacility?.facility &&
      formData.append('facility', activeFacility.facility)
    docData.id && formData.append('id', docData.id)
    formData.append('category', 'Financial Covenant Certificate')
    formData.append(
      'title',
      `Form of Compliance Certificate – Financial Covenants - ${moment().format(
        'DD-MMM-YYYY'
      )}`
    )
    formData.append(
      'effective_date',
      moment(effectiveDate).format('DD-MM-YYYY')
    )
    formData.append(
      'files',
      await pdf(
        renderTemplate(category, {
          agent,
          borrower,
          guarantors,
          agreementDate: activeFacility?.agreement_date
            ? moment.utc(activeFacility.agreement_date).format('DD-MM-YYYY')
            : '',
          effectiveDate: moment(effectiveDate).format('DD-MM-YYYY'),
          tableData: allTableData,
          covenantNames: covenantNames?.length ? covenantNames : [],
          customData,
          formData: cd,
        })
      ).toBlob()
    )
    formData.append('subject', `Form of Compliance Certificate`)
    formData.append(
      'message',
      `${user?.name} has requested your signature via Cascade Debt`
    )
    formData.append(
      'signers',
      JSON.stringify(
        cd.signers.map(
          (s: { name: string; email: string; title?: string }, i: number) => ({
            name: s.name,
            order: i,
            emailAddress: s.email,
          })
        )
      )
    )
    signers?.saveCalculatedData &&
      formData.append('calculated_data', JSON.stringify(customData))
    formData.append('form_values', JSON.stringify(cd))
    formData.append('tags', 'true')
    createEmbeddedSignRequest(formData as SignatureRequest)
    handler()
    toast.loading('Request in progress', { autoClose: false })
    reset()
  }

  useEffect(() => {
    dialogOpen &&
      !!activeFacility.facility &&
      company?.slug_name &&
      getPartyDetails({
        facility: activeFacility.facility,
        slug_name: activeFacility?.slug_name ?? company.slug_name,
      })
  }, [dialogOpen])

  const erroredCovenants: string[] = covenantQueries
    .map((q, index) => (q.isError ? covenantNames[index] : null))
    .filter(x => x !== null) as string[]

  const isLoading =
    (covenantQueries.length &&
      covenantQueries.some(query => query.isLoading)) ||
    isLoadingPartyDetails ||
    isLoadingCustomData

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogBody className="flex flex-row h-[80vh] overflow-hidden relative">
        <XMarkIcon
          className="absolute right-3 w-7 h-7 cursor-pointer"
          onClick={handler}
        />
        {isLoading ? (
          <div className="w-[100%] h-[100%] flex items-center justify-center">
            <ArrowPathIcon className="w-10 h-10 mx-20 text-primary-main animate-spin" />
          </div>
        ) : !covenantNames?.length || erroredCovenants?.length ? (
          <div className="flex flex-col justify-center items-center w-full">
            {!covenantNames?.length
              ? 'No covenants available to display'
              : 'No data available for the following covenants:'}
            <ul>
              {erroredCovenants.map(c => (
                <li key={c}>{c}</li>
              ))}
            </ul>
          </div>
        ) : (
          <PDFViewer showToolbar={false} className="h-full w-full">
            {renderTemplate(category, {
              agent,
              borrower,
              guarantors,
              agreementDate: activeFacility?.agreement_date
                ? moment.utc(activeFacility.agreement_date).format('DD-MM-YYYY')
                : '',
              effectiveDate: moment(effectiveDate).format('DD-MM-YYYY'),
              tableData: allTableData,
              covenantNames: covenantNames?.length ? covenantNames : [],
              customData,
              formData: getValues(),
            })}
          </PDFViewer>
        )}
        <>
          <div className="m-5 w-1/3 overflow-auto">
            <div className="flex flex-col content-between">
              <div className="font-bold text-lg pb-5">
                Fill in below fields to generate document
              </div>
              <FormInput
                type="date"
                label={{ start: 'As of Date' }}
                value={effectiveDate}
                onSelected={val => setEffectiveDate(val)}
                error={errors?.date?.message as string}
              />
              <CustomFields category={category} form={form} />
              <SignerInput form={form} user={user} signers={signers} />
              {!signers?.hideDisclosure && (
                <FormInput
                  type="textarea"
                  label={{ start: 'Disclosure Notice' }}
                  value={getValues('disclosure')}
                  {...register('disclosure', {
                    disabled: isSubmitting,
                    onChange: (e: any) => {
                      setValue('disclosure', e.target.value, {
                        shouldValidate: true,
                      })
                    },
                  })}
                />
              )}
              <div className="flex flex-row w-full justify-end my-3">
                <Button
                  disabled={
                    isLoading ||
                    !isValid ||
                    !covenantNames?.length ||
                    covenantQueries.every(query => query.isError)
                  }
                  type="submit"
                  color="primary"
                  className="w-full"
                >
                  {isLoading ? (
                    <ArrowPathIcon className="w-4 h-4 mx-20 text-primary-main animate-spin" />
                  ) : (
                    `Prepare Signature Request`
                  )}
                </Button>
              </div>
            </div>
          </div>
        </>
      </DialogBody>
    </form>
  )
}

const ActionWrapper = (props: TemplateDialogProps) => {
  const { company, activeFilters, optionFilters } = useContext(AuthContext)
  const { activeDebtDeal } = activeFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const params = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility,
    covenant_type: 'financial' as CovenantType,
    covenant_name: '',
  }

  const { data: covenantNames = [], isFetching: isFetchingCovenantNames } =
    useQuery<string[]>(
      ['covenant-names', params],
      () => CovenantService.getCovenantNames(params),
      {
        ...getStaleMins(),
        enabled: !!activeFacility?.facility,
      }
    )

  return (
    <Dialog
      open={props.dialogOpen}
      size={'xl'}
      dismiss={{ enabled: false }}
      handler={props.handler}
    >
      {isFetchingCovenantNames ? (
        <div className="w-[100%] h-[70vh] flex items-center justify-center">
          <ArrowPathIcon className="w-10 h-10 mx-20 text-primary-main animate-spin" />
        </div>
      ) : (
        <FinancialCovenantCertAction {...props} covenantNames={covenantNames} />
      )}
    </Dialog>
  )
}

export default ActionWrapper
