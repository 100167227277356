import React, { useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import moment from 'moment'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import { ACCESS_TOKEN_STORAGE_KEY } from '@constants/app'
import { AUTH_TOS_TIMESTAMP } from '@constants/config'
import AuthContext from '@contexts/auth'
import { setMetadata } from '@contexts/auth'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { Alert, Card, CardBody, CardFooter } from '@material-tailwind/react'
import { UserService } from '@services/api-admin/settings-user'

// Auth0 required a valid route to redirect after the login
// This pages shows a loading while checking if the user is authenticated
// If so it redirects to the application, if not redirect to auth0 to login

/**
 * @summary
 * This pages have 2 ways to access
 * 1. Redirection after login (no token stored in frontend)
 * 2. Redirection validation for loggedin user with valid token but havent accept TOS (token stored)
 *
 * Thus we need to get token and store it in frontend at this page as well
 */

const AuthCallback = () => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user,
    getAccessTokenSilently,
  } = useAuth0()
  const { userMetadata, setUserMetadata } = useContext(AuthContext)
  const [getAuthResponse, setAuthResponse] = useState('')
  const navigate = useNavigate()

  const page_origin = window.location.origin
  const TOSAccepted: boolean | undefined = userMetadata?.tosAccepted
  const TOSTimestamp: number | undefined = userMetadata?.tosTimestamp
  const user_origin: string | undefined = userMetadata?.origin
  const companies = userMetadata?.companies
  const company_slugs: string[] | undefined = companies
    ? Object.keys(companies)
    : undefined

  const acceptedTOS =
    TOSAccepted &&
    TOSTimestamp &&
    moment(TOSTimestamp).unix() >= Number(AUTH_TOS_TIMESTAMP)

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (isAuthenticated) {
      getAccessTokenSilently().then(token => {
        localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token)
        if (!userMetadata) {
          setMetadata(user, setUserMetadata, setAuthResponse)
          return
        }
      })
      if (acceptedTOS) {
        navigate('/')
      }
    } else {
      loginWithRedirect()
    }
  }, [isAuthenticated, isLoading, TOSAccepted, TOSTimestamp, userMetadata])

  useEffect(() => {
    if (user_origin && user_origin !== page_origin) {
      window.location.href = user_origin
    }
  }, [user])

  const [reachBottom, setReactBottom] = useState<boolean>(false)
  const _handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100
    setReactBottom(bottom)
  }

  const {
    mutate: acceptTOS,
    isLoading: isLoadingAccept,
    error: errorAccept,
  } = useMutation(
    () => {
      return UserService.acceptTOS(company_slugs)
    },
    {
      onSuccess: () => navigate(0),
    }
  )

  if (!isLoading && getAuthResponse.length > 0) {
    return (
      <div className="flex flex-col h-[calc(100dvh)] px-[25%] bg-primary-surface-2 justify-center items-center">
        <Alert className="bg-danger-main text-center">{getAuthResponse}</Alert>
      </div>
    )
  }

  return (
    <div className="flex flex-col w-screen h-[calc(100dvh)] bg-primary-surface-2 justify-center items-center">
      {!acceptedTOS && user && userMetadata ? (
        <>
          <Card>
            <CardBody
              className="max-w-[75vw] max-h-[75vh] overflow-y-auto"
              onScroll={_handleScroll}
            >
              {errorAccept && (
                <Alert className="bg-danger-main mb-6">
                  {((errorAccept as AxiosError).response?.data as any)?.error ??
                    (errorAccept as AxiosError).message}
                </Alert>
              )}
              <Typography variant="h4" className="text-center">
                Terms of Service
              </Typography>
              <Typography variant="b2" className="text-center">
                {`Last updated: ${moment
                  .unix(Number(AUTH_TOS_TIMESTAMP))
                  .format('MMMM DD YYYY')}`}
              </Typography>
              <Typography variant="b2" className="mt-8">
                These Cascade End User Terms of Service (“Terms”) are designed
                to govern your (“you” or “your”) use of the platform and related
                products (collectively the “Cascade Platform”) provided by
                Cascade Debt Corporation, a Delaware corporation (“Cascade,”
                “us,” “we,” or “our”) as an authorized user of a Cascade
                customer (collectively “Customers”), or as a third-party
                investor or authorized individual in connection with such
                investor that is utilizing available aspects of the Cascade
                Platform to review information of Customers (“Investors”).
                Individual users of the Cascade Platform related Customers and
                Investors are collectively referred to collectively as “Users”.
                Obligations and responsibilities provided in these Terms are in
                addition to any obligations and responsibilities that are agreed
                to under any separate binding agreement entered into between the
                parties, including any Cascade Master Services Agreement or
                separate Investor Partnership Agreement.
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                1. Acceptance
              </Typography>
              <Typography variant="b2">
                BY CLICKING THE “I ACCEPT” BUTTON OR SIMILAR ATTESTATION WHEN
                SUCH OPTION IS MADE AVAILABLE TO YOU, OR OTHERWISE USING THE
                CASCADE PLATFORM, YOU ACCEPT AND AGREE TO BE BOUND BY THESE
                TERMS EFFECTIVE AS OF THE DATE OF SUCH ACTION. YOU EXPRESSLY
                ACKNOWLEDGE AND REPRESENT THAT YOU HAVE CAREFULLY REVIEWED THESE
                TERMS AND FULLY UNDERSTAND THE RISKS, COSTS AND BENEFITS RELATED
                TO THE USE OF THE CASCADE PLATFORM. IF YOU DO NOT AGREE WITH
                THESE TERMS OR THE PRIVACY POLICY, OR IF YOU DO NOT HAVE SUCH
                AUTHORITY TO AGREE TO THESE TERMS, YOU MUST NOT ACCEPT THESE
                TERMS AND MAY NOT ACCESS OR USE THE CASCADE PLATFORM.
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                2. Changes to These Terms
              </Typography>
              <Typography variant="b2">
                In Cascade’s sole discretion, Cascade has the right to add to,
                remove, modify or otherwise change any part these terms, in
                whole or in part, at any time. If Cascade exercises this right,
                it will notify you or otherwise indicate such update through the
                Cascade Platform or contacting you through the contact
                information provided when registering for an Account. Changes
                will be effective as of the date the changes of these Terms are
                made available on the Cascade Platform or otherwise provided to
                you as a User.
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                3. Proper Use
              </Typography>
              <Typography variant="b2">
                You warrant that you will use the Cascade Platform only for
                lawful purposes and will comply with all applicable laws. You
                further warrant that you will not use the Cascade Platform for
                any purpose that is unlawful, malicious, harmful to any other
                Users of the Cascade Platform, or otherwise prohibited by these
                Terms.
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                4. Privacy
              </Typography>
              <Typography variant="b2">
                We will process personal information you provide to us in a
                reasonable manner and in compliance with applicable laws,
                however, you should refrain from providing any personal
                information to the Cascade Platform unless otherwise required by
                the Cascade Platform or requested by Cascade. Where personal
                information is provided to us as part of Customer Data, we will
                process such information in accordance with our privacy policy
                found at the Privacy page.
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                5. Accounts, Passwords and Security
              </Typography>
              <Typography variant="b2">
                When you access the Cascade Platform, you will be required to
                create an account (“Account”), including providing an email
                address and creating a password. You are entirely responsible
                for maintaining current information on the Account, as well as
                the confidentiality of your Account credentials, including your
                password, and for any and all activity that occurs under your
                Account as a result of your failing to keep this information
                secure and confidential. You agree to notify Cascade immediately
                of any unauthorized use of your Account or password, or any
                other breach of security. You may not use anyone else’s account
                information. Cascade cannot and will not be liable for any loss
                or damage arising from your failure to comply with these
                obligations.
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                6. Interactive Services
              </Typography>
              <Typography variant="b2">
                The Cascade Platform may contain interactive services including
                without limitation the ability to post comments, notes, or other
                communications to other Users through the Cascade Platform
                (“Interactions”). Cascade reserve the right to review any
                Interactions, however are under no obligation to oversee,
                monitor, or moderate any Interactions, and Cascade expressly
                disclaims any and all liability for any loss or damage arising
                from any Interactions with Users.
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                7. Prohibitions
              </Typography>
              <Typography variant="b2">
                You agree not to use the Cascade Platform to
                <ol className="list-[lower-alpha] pl-6">
                  <li>
                    Violate any law, regulation, or governmental policy in the
                    US or internationally;
                  </li>
                  <li>
                    Infringe upon or violate intellectual property rights or any
                    other rights of anyone else (including Cascade);
                  </li>
                  <li>
                    Behave in a harmful, fraudulent, deceptive, threatening,
                    harassing, defamatory, obscene, or otherwise objectionable
                    manner (including to exploit or harm minors in any way by
                    exposing them to inappropriate content or other material);
                  </li>
                  <li>
                    Jeopardize the security of your Account or anyone else’s
                    (such as allowing someone else to log into the Service as
                    you);
                  </li>
                  <li>
                    Transmit, or procure the sending of, any advertising or
                    promotional material, including any “junk mail,” “chain
                    letter,” “spam,” or any other similar solicitation;
                  </li>
                  <li>
                    Violate the security of any computer network, or crack any
                    password or security encryption code;
                  </li>
                  <li>
                    Impersonate or attempt to impersonate another individual,
                    entity, Cascade employee, agent, or another User;
                  </li>
                  <li>
                    Infringe, in any way, on the rights of others or engage in
                    behavior or activity that is threatening, harmful,
                    discriminatory, or fraudulent;
                  </li>
                  <li>
                    Copy or store any Cascade Platform source code or a
                    significant portion of our intellectual property;
                  </li>
                  <li>
                    Decompile, reverse engineer, or otherwise attempt to obtain
                    source code or underlying ideas or information of or
                    relating to the Cascade Platform we provide;
                  </li>
                  <li>
                    Attempt to gain unauthorized access to, interfere with,
                    damage, or disrupt any parts of the Cascade Platform, the
                    server on which any part of the Cascade Platform requires,
                    or any other computer or database connected to the Platform;
                  </li>
                  <li>
                    Circumvent, remove, alter, deactivate, degrade, or thwart
                    any technological measure or content protections of the
                    Platform;
                  </li>
                  <li>
                    Attack the Cascade Platform via a denial-of-service attack
                    or distributed denial-of-service attack;
                  </li>
                  <li>
                    Use any device, software, bot, or routine that interferes
                    with the proper working of the Cascade Platform;
                  </li>
                  <li>
                    Use any manual or automated process to monitor or copy any
                    of the material on the Cascade Platform or for any other
                    unauthorized purpose, including, without limitation, using
                    any automated or non-automated systems to scape, copy, or
                    distribute content without our prior written consent;
                  </li>
                  <li>
                    Damage, overburden, disable, or impair the Cascade Platform;
                  </li>
                  <li>
                    Introduce any viruses, trojan horses, worms, logic bombs, or
                    other material that is malicious or technologically harmful;
                  </li>
                  <li>
                    Engage in any other conduct that restricts or inhibits
                    anyone’s use or enjoyment of the Cascade Platform, or which,
                    as determined by us, may harm or offend Cascade or its
                    Users, or otherwise expose them to any liability; or
                  </li>
                  <li>
                    Otherwise attempt to interfere with the proper working of
                    the Cascade Platform.
                  </li>
                </ol>
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                8. No Competition
              </Typography>
              <Typography variant="b2">
                You cannot use the Cascade Platform, or any content or materials
                provided through the Cascade Platform, to compete with the
                Cascade Platform, whether for pecuniary gain or not. This
                provision includes without limitation making content or
                materials gained from the Cascade Platform available to others.
                Except as expressly authorized by us, you agree not to, in whole
                or in part, modify, copy, frame, rent, lease, loan, sell,
                distribute, publicly display or perform, reproduce, replicate,
                duplicate, print or create any derivative works based on the
                Cascade Platform or any Cascade intellectual property that may
                be available through the Cascade Platform.
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                9. Termination and Suspension Rights
              </Typography>
              <Typography variant="b2">
                Cascade may suspend, terminate, or otherwise deny your access to
                or use of all or any part of the Cascade Platform without
                incurring any resulting obligation or liability, if Cascade
                believes, in its good faith and reasonable discretion, that you
                <ol className="list-[lower-roman] pl-6">
                  <li>
                    Failed to comply with any of these Terms or express
                    instructions of Cascade,
                  </li>
                  <li>
                    Have been, or are likely to be involved in any fraudulent,
                    misleading, or unlawful activities; or
                  </li>
                  <li>
                    The subscription fee related to your access rights to the
                    Cascade Platform has not been paid by the respective
                    Customer or Investor. This Section 7 does not limit any of
                    Cascade’s other rights or remedies, whether at law, in
                    equity, or under these Terms.
                  </li>
                </ol>
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                10. Disclaimers (a)
              </Typography>
              <Typography variant="b2">
                NO UNDERWRITING OR LOAN EVALUATION SERVICES. CASCADE IS A
                TECHNOLOGY PROVIDER ONLY WITH SERVICES TO HELP ORGANIZE AND
                DISPLAY RELEVANT INFORMATION OF CUSTOMER, INCLUDING LOAN
                INFORMATION RELATED TO LOANS, DEBT AND CASH FLOW, AS WELL AS
                RELEVANT DOCUMENTS AND AGREEMENTS OF CUSTOMER THAT MAY BE
                UPLOADED BY CUSTOMER TO THE CASCADE PLATFORM. IN NO WAY DOES
                CASCADE PROVIDE ANY DIRECT LOANS, INVESTMENT, UNDERWRITING,
                LEGAL, OR TAX PLANNING SERVICES THROUGH THE ANY OF THE SERVICES
                AND TO THE EXTENT INFORMATION IS USED BY AUTHORIZED USERS OF
                CUSTOMER, INCLUDING LENDERS AND UNDERWRITERS, SUCH INFORMATION
                IS USED INDEPENDENTLY BY SUCH THIRD-PARTIES FOR THEIR OWN
                PURPOSES AND CASCADE SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY
                SUCH USES BY SUCH AUTHORIZED USERS.
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                10. Disclaimers (b)
              </Typography>
              <Typography variant="b2">
                DISCLAIMER OF WARRANTIES. EXCEPT AS EXPRESSLY PROVIDED IN THESE
                TERMS, ALL SERVICES ARE PROVIDED “AS IS.” EXCEPT AS EXPRESSLY
                PROVIDED IN THESE TERMS, CASCADE SPECIFICALLY DISCLAIMS ALL
                OTHER WARRANTIES, EXPRESS AND IMPLIED, INCLUDING ALL IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM
                COURSE OF DEALING, USAGE, OR TRADE PRACTICE. CASCADE MAKES NO
                REPRESENTATION, WARRANTY, OR GUARANTEE AS TO THE RELIABILITY,
                TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY, ACCURACY, OR
                COMPLETENESS OF THE CASCADE PLATFORM OR ANY CASCADE IP. CASCADE
                DOES NOT REPRESENT OR WARRANT THAT
                <ol className="list-[upper-alpha] pl-6">
                  <li>
                    THE CASCADE PLATFORM WILL BE AVAILABLE, SECURE,
                    UNINTERRUPTED, OR ERROR-FREE OR OPERATE IN COMBINATION WITH
                    ANY OTHER APPLICATION, SOFTWARE, HARDWARE, SYSTEM, OR DATA,
                  </li>
                  <li>
                    THE CASCADE PLATFORM WILL MEET YOUR REQUIREMENTS OR
                    EXPECTATIONS,
                  </li>
                  <li>
                    ANY DATA STORED USING THE CASCADE PLATFORM WILL BE ACCURATE,
                    RELIABLE, OR SECURE,
                  </li>
                  <li>
                    ERRORS OR DEFECTS IN THE CASCADE PLATFORM WILL BE CORRECTED,
                    OR
                  </li>
                  <li>
                    THE CASCADE PLATFORM IS FREE OF VIRUSES OR OTHER HARMFUL
                    COMPONENTS.
                  </li>
                </ol>
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                11. Limitation of Liability
              </Typography>
              <Typography variant="b2">
                <ol className="list-[lower-alpha] pl-6">
                  <li>
                    IN NO EVENT WILL CASCADE BE LIABLE TO YOU OR TO ANY THIRD
                    PARTY FOR ANY CONSEQUENTIAL, INDIRECT, EXEMPLARY, SPECIAL OR
                    PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT,
                    TORT (INCLUDING NEGLIGENCE) OR OTHERWISE UNDER THESE TERMS
                    (INCLUDING ANY APPLICABLE ADDITIONAL TERMS), REGARDLESS OF
                    WHETHER SUCH DAMAGE WAS FORESEEABLE AND WHETHER OR NOT SUCH
                    PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  </li>
                  <li>
                    IN NO EVENT CASCADE’S LIABILITY ARISING OUT OF OR RELATED TO
                    THESE TERMS, WHETHER ARISING OUT OF OR RELATED TO BREACH OF
                    CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EXCEED
                    THE GREATER OF ONE ($1) DOLLAR OR THE LEGAL MINIMUM THAT
                    LIABILITY MAY BE LIMITED TO UNDER APPLICABLE LAW.
                  </li>
                </ol>
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                12. Indemnification
              </Typography>
              <Typography variant="b2">
                To the maximum extent permitted by law, you shall release,
                defend, indemnify, and hold harmless Cascade and its officers,
                directors, shareholders, employees, and agents and their
                respective successors and assigns from any third-party claim,
                suit, proceeding, or government enforcement actions arising out
                of, related to, or alleging an injury or loss caused by
                <ol className="list-[lower-roman] pl-6">
                  <li>
                    Your access and use of the Cascade Platform in violation of
                    these Terms, and
                  </li>
                  <li>
                    Any actions by you constituting gross negligence, willful
                    misconduct, or fraud.
                  </li>
                </ol>
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                13. Notice
              </Typography>
              <Typography variant="b2">
                All notices required or permitted to be given by either party to
                the other under these Terms shall be sent as follows:
                <ol className="list-[lower-alpha] pl-6">
                  <li>
                    If to you, Cascade shall send notice to the email address
                    provided in your registration for the Services; and
                  </li>
                  <li>
                    If to Cascade, you must send notice to
                    admin@cascadedebt.com.
                  </li>
                </ol>
              </Typography>
              <Typography variant="b2" className="mt-8 font-bold">
                14. Miscellaneous
              </Typography>
              <Typography variant="b2">
                These Terms constitutes the sole and entire agreement of the
                Parties with respect to the subject matter contained herein, and
                supersedes all prior and contemporaneous understandings,
                agreements, representations, and warranties, both written and
                oral, with respect to such subject matter. These Terms shall be
                governed by and construed in accordance with the internal laws
                of the State of New York, without giving effect to any choice or
                conflict of law provision or rule. Nothing herein shall confer
                upon any person or entity other than the Parties and their
                successors or permitted assigns any rights or remedies under or
                by reason of these Terms. Except as expressly set forth herein,
                each Party will be responsible for its own costs and expenses
                associated with performance of its obligations under these
                Terms. Neither these Terms, nor any rights or obligations
                hereunder may be assigned, delegated or conveyed by you without
                the prior written consent of Cascade. Any provision which by its
                nature is intended to survive the termination or expiration of
                these Terms shall survive such termination or expiration.
              </Typography>
            </CardBody>
            <CardFooter className="flex justify-center">
              <Button
                color="primary"
                size="lg"
                disabled={!reachBottom || isLoadingAccept}
                onClick={() => acceptTOS()}
              >
                {isLoadingAccept && (
                  <ArrowPathIcon className="w-4 h-4 mr-2 text-primary-main animate-spin" />
                )}
                Accept
              </Button>
            </CardFooter>
          </Card>
        </>
      ) : (
        <ArrowPathIcon className="animate-spin text-primary-main w-8" />
      )}
    </div>
  )
}

export default AuthCallback
