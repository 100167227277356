import React from 'react'

import {
  Footer,
  Table,
} from '@pages/manage/monitor/actions/document-centre/templates/components'
import { Document, Page, Text } from '@react-pdf/renderer'

import { styles } from './style'

interface ExhibitTemplateProps {
  data: any
  index: number
}

const ExhibitTemplate = ({ data, index }: ExhibitTemplateProps) => {
  const itemData = Object.keys(data).map((key: string) => [
    {
      width: '50%',
      content: key.replaceAll('_', ' ').toUpperCase(),
      position: 'left' as const,
    },
    {
      width: '50%',
      content: key.includes('amount') ? data[key].toFixed(2) : data[key],
      position: 'left' as const,
    },
  ])

  const auditCoverData = {
    title: '',
    tableWidth: '100%',
    data: itemData,
  }

  return (
    <Document>
      {index > 1 && (
        <Page style={styles.body}>
          <Text style={styles.sectionCover}>
            {`~ END OF USER-PROVIDED EVIDENCE FOR EXHIBIT #${index - 1} ~`}
          </Text>
          <Footer />
        </Page>
      )}
      <Page style={styles.body}>
        <Text style={styles.sectionTitle}>Exhibit #{index}</Text>
        <Table data={auditCoverData} />
        <Footer />
      </Page>
      {data.number_of_evidence_uploads > 0 ? (
        <Page style={styles.body}>
          <Text style={styles.sectionCover}>
            {`~ START OF USER-PROVIDED EVIDENCE FOR EXHIBIT #${index} ~`}
          </Text>
          <Footer />
        </Page>
      ) : (
        <Page style={styles.body}>
          <Text style={styles.sectionCover}>
            {`~ NO EVIDENCE PROVIDED BY THE USER FOR EXHIBIT #${index} ~`}
          </Text>
          <Footer />
        </Page>
      )}
    </Document>
  )
}

export default ExhibitTemplate
