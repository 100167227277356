import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import { AvailableFeatures } from '@components/app-routes/routes'
import BasicDialog from '@components/basic-dialog/basic-dialog'
import FilterCurrency from '@components/filters/filter-currency'
import FilterFinancialDisplay from '@components/filters/filter-financial-display'
import OverviewL2 from '@components/layouts/l2-overview-layout'
import AuthContext from '@contexts/auth'
import { FinancialKeyIndicatorService } from '@services/api-analytics/financials-key-indicators'
import { StatementService } from '@services/api-analytics/financials-statement'

const FinancialOverviewL3 = () => {
  const {
    userMetadata,
    company,
    activeFilters,
    appliedFilters,
    optionFilters,
    setAppliedFilters,
  } = useContext(AuthContext)

  const active_company = userMetadata?.companies?.[company?.slug_name ?? '']
  const active_access = Object.keys(active_company?.access ?? {})
  const features = AvailableFeatures(active_access)

  const { financialEntity, financialEntityCurrency, currency, rate } =
    appliedFilters
  const [featureUnavailDialogOpen, setFeatureUnavailDialogOpen] =
    useState<boolean>(false)

  useEffect(() => {
    Object.keys(appliedFilters).length == 0 &&
      activeFilters.financialEntity &&
      setAppliedFilters({
        ...activeFilters,
        dateStart: moment.utc(company?.date_start).format('YYYY-MM-DD'),
        dateEnd: moment.utc(company?.date_end).format('YYYY-MM-DD'),
      })
  }, [activeFilters.financialEntity])

  useEffect(() => {
    optionFilters.financialEntities?.length == 0 &&
      setFeatureUnavailDialogOpen(true)
  }, [optionFilters])

  const handleDialogOpen = () => {
    setFeatureUnavailDialogOpen(false)
  }

  const currencyUtil = {
    default: financialEntityCurrency,
    code: currency,
    ratePrimary:
      financialEntityCurrency == company?.currencies_available?.[0]?.to_currency
        ? { code: currency, rate: rate }
        : optionFilters.rates?.find(
            (r: { code: string; rate: number }) =>
              r.code == financialEntityCurrency
          ),
    rateSecondary:
      financialEntityCurrency == company?.currencies_available?.[0]?.to_currency
        ? optionFilters.rates?.find(
            (r: { code: string; rate: number }) =>
              r.code == financialEntityCurrency
          )
        : optionFilters.rates?.find(
            (r: { code: string; rate: number }) => r.code == currency
          ),
  }
  const convertCurrency = (primaryAmount: number) => {
    return !!currencyUtil.ratePrimary
      ? !currencyUtil.rateSecondary && currencyUtil.default == 'USD'
        ? primaryAmount * currencyUtil.ratePrimary?.rate ?? 1
        : currencyUtil.rateSecondary && currencyUtil.default != 'USD'
        ? primaryAmount * currencyUtil.rateSecondary?.rate ?? 1
        : !currencyUtil.rateSecondary && currencyUtil.default != 'USD'
        ? primaryAmount / currencyUtil.ratePrimary?.rate ?? 1
        : primaryAmount
      : 0
  }

  const data = [
    //total assets
    {
      feature: 'analytics_financials_statements',
      cardTitle: 'Balance Sheet',
      cardLink: '/analytics/financials/statements?tab=balance-sheet',
      fetcher: StatementService.getOverview,
      fetchOption: {
        slug_name: company?.slug_name,
        date_from: moment.utc(company?.date_start).format('YYYY-MM-DD'),
        entity_id: financialEntity,
        date_to: moment.utc(company?.date_end_cohort).format('YYYY-MM-DD'),
      },
      icon: 'bar',
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
        }).format(convertCurrency(val) ?? 0)} ${currency ?? ''}`,
    },
    //cash balance
    {
      feature: 'analytics_financials_key-indicators',
      cardTitle: 'KPI',
      cardLink: '/analytics/financials/key-indicators?tab=cash',
      fetcher: FinancialKeyIndicatorService.getOverview,
      icon: 'bar',
      fetchOption: {
        slug_name: company?.slug_name,
        entity_id: financialEntity,
        date_from: moment.utc(company?.date_start).format('YYYY-MM-DD'),
        date_to: moment.utc(company?.date_end_cohort).format('YYYY-MM-DD'),
        key: 'cash',
      },
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
        }).format(convertCurrency(val) ?? 0)} ${currency ?? ''}`,
    },
    //cash runway
    {
      feature: 'analytics_financials_key-indicators',
      cardTitle: 'KPI',
      cardLink:
        '/analytics/financials/key-indicators?tab=runway:gross-burn-rate',
      fetcher: FinancialKeyIndicatorService.getOverview,
      icon: 'line',
      fetchOption: {
        slug_name: company?.slug_name,
        entity_id: financialEntity,
        date_from: moment.utc(company?.date_start).format('YYYY-MM-DD'),
        date_to: moment.utc(company?.date_end_cohort).format('YYYY-MM-DD'),
        key: 'runway',
        metric: 'previous',
      },
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          maximumFractionDigits: 1,
          style: 'decimal',
          notation: 'compact',
        }).format(val ?? 0)}`,
      indicatorClass: {
        up: 'bg-green',
        down: 'bg-red',
      },
    },
    //pNL - net income
    {
      feature: 'analytics_financials_statements',
      cardTitle: 'Profit & Loss',
      cardLink: `/analytics/financials/statements?tab=${encodeURIComponent(
        'profit-&-loss'
      )}`,
      fetcher: FinancialKeyIndicatorService.getOverview,
      fetchOption: {
        slug_name: company?.slug_name,
        date_from: moment.utc(company?.date_start).format('YYYY-MM-DD'),
        entity_id: financialEntity,
        date_to: moment.utc(company?.date_end).format('YYYY-MM-DD'),
        key: 'net-income',
      },
      icon: 'line',
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
        }).format(convertCurrency(val) ?? 0)} ${currency ?? ''}`,

      indicatorClass: {
        up: 'bg-green',
        down: 'bg-red',
      },
    },
    //debt:equity
    {
      feature: 'analytics_financials_key-indicators',
      cardTitle: 'KPI',
      cardLink: '/analytics/financials/key-indicators?tab=debt:equity',
      fetcher: FinancialKeyIndicatorService.getOverview,
      fetchOption: {
        slug_name: company?.slug_name,
        date_from: moment.utc(company?.date_start).format('YYYY-MM-DD'),
        entity_id: financialEntity,
        date_to: moment.utc(company?.date_end).format('YYYY-MM-DD'),
        key: 'debt-equity',
      },
      icon: 'line',
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
          maximumFractionDigits: 2,
        }).format(val ?? 0)}`,
      indicatorClass: {
        up: 'bg-red',
        down: 'bg-green',
      },
    },
    //tangible net worth
    {
      feature: 'analytics_financials_key-indicators',
      cardTitle: 'KPI',
      cardLink: '/analytics/financials/key-indicators?tab=net-worth',
      fetcher: FinancialKeyIndicatorService.getOverview,
      fetchOption: {
        slug_name: company?.slug_name,
        date_from: moment.utc(company?.date_start).format('YYYY-MM-DD'),
        entity_id: financialEntity,
        date_to: moment.utc(company?.date_end).format('YYYY-MM-DD'),
        key: 'net-worth',
      },
      icon: 'line',
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
        }).format(convertCurrency(val) ?? 0)} ${currency ?? ''}`,
    },
  ].filter(d => features.includes(d.feature))

  return (
    <>
      <OverviewL2
        title="Financials Overview"
        filters={{
          left: <FilterCurrency />,
          right: <FilterFinancialDisplay />,
        }}
        data={data}
      />
      <BasicDialog
        open={featureUnavailDialogOpen}
        handleOpen={handleDialogOpen}
        headerText={'No Financial Information Uploaded'}
        description={
          "Financial dashboards require accounting integration or statement uploads by the organization's administrators."
        }
      />
    </>
  )
}
export default FinancialOverviewL3
