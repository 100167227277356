import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import RadarChart from '@components/chart/radar'
import AuthContext from '@contexts/auth'
import { greenToRed } from '@helpers/green-to-red'
import { getStaleMins } from '@helpers/stale-timer'
import { Facility } from '@interfaces/facility'
import { BorrowingBaseService } from '@services/api-manage/borrowing-base'

const SurplusChart = ({ facility }: { facility?: Facility }) => {
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)

  const { activeDebtDeal = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const filters = {
    slug_name:
      facility?.slug_name ??
      activeFacility?.slug_name ??
      company?.slug_name ??
      '',
    facility: facility?.facility ?? activeFacility?.facility ?? '',
    numberOfLenders: facility?.lender ?? activeFacility?.lender ?? 0,
  }

  const { data } = useQuery(
    ['surplus', filters],
    () => BorrowingBaseService.getSurplus(filters),
    {
      ...getStaleMins(),
      enabled: !!activeFacility?.facility && !!company?.slug_name,
    }
  )
  const percent = data?.percentage ?? 0
  const graphPercent = percent / 2
  const color = greenToRed(
    data?.percentage
      ? 100 - graphPercent - (graphPercent >= 50 ? 20 : 0)
      : (data?.surplus ?? 0) > 0
      ? 0
      : 100,
    true
  )
  return (
    <>
      <Typography className="text-xl font-medium mb-4 text-center">
        Borrowing Base
      </Typography>
      <RadarChart
        value={`${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
        }).format(Math.round(data?.surplus ?? 0))}`}
        color={color.toString()}
        subtitle={
          data?.percentage
            ? Intl.NumberFormat(undefined, {
                style: 'percent',
                notation: 'compact',
                minimumFractionDigits: 2,
              }).format(percent / 100)
            : '-'
        }
        percent={graphPercent}
        id={`radar-chart${facility ? `-${facility?.facility}` : ''}`}
      />
      <div className="flex flex-col mt-6">
        <Typography className="text-xs font-medium text-neutral-body-2">
          Days Deficient
        </Typography>
        <div
          className="flex justify-center mt-2 w-full border border-neutral-border-2 relative"
          style={{ backgroundColor: `${color}40` }}
        >
          {data?.daysDeficient ?? 0}
        </div>
      </div>
    </>
  )
}

export default SurplusChart
