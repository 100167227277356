import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  footerContainer: {
    position: 'absolute',
    bottom: 30,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },

  footerText: {
    borderTop: '4px solid #feef00',
    width: '100%',
    fontSize: 8,
    fontFamily: 'Times-Roman',
    color: '#000000',
    paddingTop: '20px',
  },
})
