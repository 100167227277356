import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import CollectionRateL5 from './collection-rate'

const CollectionRateL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const tabs = [
    {
      label: 'Collection Rate',
      desc: {
        description:
          'The amount collected on loans compared to how much was expected according to the schedule.',
        formula:
          'Total Payment (payments table) / Total Payment (schedules table) as long as the date_due or date_paid matches the specific month.',
      },
      component: <CollectionRateL5 exportable={exportable} />,
    },
  ]

  return (
    <L4TabsLayout
      tabs={tabs}
      additionalIndicator={
        showHistoricalRateIndicator ? <ExchangeRateBadge /> : undefined
      }
    />
  )
}

export default CollectionRateL4
