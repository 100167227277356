import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
// import StatisticL5 from '@components/layouts/l5-stat'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { FinancialsFilters } from '@interfaces/financial'
import { Tooltip } from '@material-tailwind/react'
import { FinancialKeyIndicatorService } from '@services/api-analytics/financials-key-indicators'

const RunwayL5 = ({ exportable }: { exportable: boolean }) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const { dateStartCohort, dateEndCohort, financialEntity } = appliedFilters

  const [label, setLabel] = useState<string>('all')
  const [is_gross, setIsGross] = useState<boolean>(true)

  const filters: FinancialsFilters = {
    date_from: moment.utc(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEndCohort).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    entity_id: financialEntity,
    metric: label,
    is_gross,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['runway', filters],
    () => FinancialKeyIndicatorService.getRunway(filters),
    getStaleMins()
  )

  const {
    graphData,
    //...growthRatios
  } = fetchedData ?? {}

  const allLabels = [
    { label: 'Previous 3 months', metric: 'previous_3' },
    {
      label: 'Previous 6 months',
      metric: 'previous_6',
    },
    {
      label: 'Previous month',
      metric: 'previous_month',
    },
  ]

  const chartData = (graphData || []).map((c: any) => {
    const result: { x: number; [key: string]: any } = {
      x: moment(c.date).valueOf(),
    }
    if (label != 'all') {
      result.type_all = parseFloat(c.value.toString())
    } else {
      allLabels.map((l: { label: string; metric: string }) => {
        result[`type_${l.metric}`] = c[l.metric]
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [graphData])

  const series: any =
    label != 'all'
      ? [
          {
            label: 'All',
            field: 'type_all',
            tooltipLabel: `Runway`,
            hasBullet: true,
            type: 'SmoothedXLineSeries',
          },
        ]
      : ['previous_3', 'previous_6', 'previous_month'].map((ct: string) => {
          const typeVal = allLabels.find(
            (av: { label: string; metric: string }) => av.metric === ct
          )
          const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
          return {
            label: `${typeVal ? typeVal.label : 'All'}`,
            field: `type_${ct}`,
            color,
            type: 'SmoothedXLineSeries',
            hasBullet: true,
          }
        })

  return (
    <div className="[&>div>div:nth-child(2)]:mt-10">
      <L5ChartstatLayout
        chart={
          <>
            <div className="flex mb-8 justify-between">
              <div className="flex justify-center items-center">
                <Tooltip
                  content={
                    <span>
                      Description:
                      <br />
                      <br />
                      Gross burn rate measures the total monthly cash outflow,
                      whereas net burn rate measures the total monthly cash
                      loss. In other words, gross burn only includes expenses,
                      but net burn also considers income.
                    </span>
                  }
                  placement="right"
                >
                  <InformationCircleIcon className="w-8 text-primary-main cursor-help" />
                </Tooltip>
              </div>
              <div className="gap-4 flex flex-col 2xl:flex-row">
                {[
                  {
                    options: [
                      {
                        title: 'Gross',
                        active: is_gross,
                        action: () => setIsGross(true),
                      },
                      {
                        title: 'Net',
                        active: !is_gross,
                        action: () => setIsGross(false),
                      },
                    ],
                  },
                  {
                    options: [
                      {
                        title: 'All',
                        active: label === 'all',
                        action: () => setLabel('all'),
                      },
                      {
                        title: 'Previous Month',
                        active: label === 'previous',
                        action: () => setLabel('previous'),
                      },
                      {
                        title: '3m Average',
                        active: label === '3m',
                        action: () => setLabel('3m'),
                      },
                      {
                        title: '6m Average',
                        active: label === '6m',
                        action: () => setLabel('6m'),
                      },
                    ],
                  },
                ].map((group, i) => (
                  <div key={i} className={`flex `}>
                    <div className="bg-neutral-border-1 rounded-md p-1 flex">
                      {group.options.map((g, j) => (
                        <button
                          key={j}
                          className={`block text-sm rounded-md px-8 py-0.5 font-semibold ${
                            g.active ? 'bg-secondary-main text-white' : ''
                          }`}
                          onClick={g.action}
                        >
                          {g.title}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Chart
              loading={isFetching || isProcessing}
              data={chartData}
              id={`runway${label}${is_gross}`}
              yLabel={'Months'}
              series={series}
              exportable={exportable}
              error={error as { message: string }}
            />
          </>
        }
        //stat={<StatisticL5 {...growthRatios} />}
      />
    </div>
  )
}

export default RunwayL5
