import React from 'react'

const MaintenancePage = () => {
  return (
    <div className="bg-primary-surface-1 w-screen h-[calc(100dvh)] flex flex-col justify-center p-8">
      <img
        src="/svgs/cascade-logo-long.svg"
        alt="Cascade"
        data-testid="cascade-logo"
        className="mt-6 mb-8 px-4 h-[40px]"
      />
      <div className="flex flex-1 flex-col items-center">
        <span className="text-[256px] font-bold text-transparent bg-clip-text bg-gradient-to-r from-primary-main to-secondary-main opacity-30">
          Oops!!
        </span>
        <span className="font-semibold text-4xl text-neutral-subtitle-2">
          Under Maintenance
        </span>
        <p className="text-neutral-body-2 text-base">
          We will be back soon!! Sorry for the inconvenience. We are performing
          some maintenance at the moment. - Cascade Team
        </p>
      </div>
    </div>
  )
}
export default MaintenancePage
