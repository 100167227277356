import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
// if (process.env.NODE_ENV !== 'local' && process.env.DSN_SENTRY)
if (process.env.REACT_APP_DSN_SENTRY !== 'null') {
  Sentry.init({
    dsn: process.env.REACT_APP_DSN_SENTRY,
    integrations: [new BrowserTracing()],
    debug: false,
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
  })
}
Sentry.setTag('environment', process.env.REACT_APP_ENVIRONMENT)
export default Sentry
