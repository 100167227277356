import {
  InterestRateDataResponse,
  OverviewDataResponse,
  RiskDataResponse,
  RisksFilters,
} from '@interfaces/risk'

import apiClient from './client'

export class CharacteristicService {
  static async getInterestRate(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<InterestRateDataResponse>(
        '/characteristics/interest-rate',
        params
      )

      return data
    }
  }
  static async getTerms(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<InterestRateDataResponse>(
        '/characteristics/terms',
        params
      )

      return data
    }
  }
  static async getValues(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<InterestRateDataResponse>(
        '/characteristics/values',
        params
      )

      return data
    }
  }

  static async getOverview(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<OverviewDataResponse>
      >('/indicators/overview-chars', params)

      return data
    }
  }
}
