import React, { useContext } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { ComponentResponse } from '@interfaces/borrowing-base'
import { BorrowingBaseService } from '@services/api-manage/borrowing-base'

interface DetailTableProps {
  component: ComponentResponse
}
const DetailTable = ({ component }: DetailTableProps) => {
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)

  const { activeDebtDeal = 0, calculationDate } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const filters = {
    facility: activeFacility?.facility ?? '',
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    date: calculationDate,
  }

  const API = (): Promise<any[]> => {
    switch (component.component) {
      case 'Cash':
        return BorrowingBaseService.getCash({
          ...filters,
          cashNumber: component.subcomponent,
        })
      case 'Eligible Pool Balance':
        return BorrowingBaseService.getEligiblePool({
          ...filters,
          eligiblePool: component.subcomponent,
        })
      case 'Borrowing Base Interest Reserve':
        return BorrowingBaseService.getInterestReserve(filters)
      case 'Excess Concentration':
        return BorrowingBaseService.getExcessConcentration({
          ...filters,
          defined_term: component.subcomponent,
        })
      default:
        return new Promise(resolve => resolve([]))
    }
  }

  const { data, isFetching } = useQuery([component, filters], () => API(), {
    ...getStaleMins(),
    enabled:
      !!activeFacility?.facility && !!company?.slug_name && !!calculationDate,
  })

  const renderDecimal = (value?: any) =>
    Intl.NumberFormat(undefined, {
      style: 'decimal',
    }).format(value)

  const columns = () => {
    switch (component.component) {
      case 'Cash':
        return [
          { title: 'Bank Name', field: 'bank_name', align: 'center' },
          { title: 'Account Number', field: 'account_number', align: 'center' },
          { title: 'Account Name', field: 'account_name' },
          { title: 'Account Type', field: 'account_type', align: 'center' },
          {
            title: 'Statement Date',
            field: 'balance_as_of',
            align: 'center',
            render: (record: any) =>
              record.balance_as_of
                ? moment.utc(record.balance_as_of).format('DD-MM-YYYY')
                : '',
          },
          {
            title: 'Closing Balance',
            field: 'balance_value',
            align: 'right',
            render: (record: any) =>
              renderDecimal(record.closing_balance ?? record.balance_value),
            head: {
              align: 'center',
            },
          },
          { title: 'Currency', field: 'currency', align: 'center' },
        ]
      case 'Eligible Pool Balance':
        return [
          { title: 'Days Past Due ', field: 'dpd_bucket', align: 'center' },
          {
            title: `Outstanding Principal (USD)`,
            field: 'outstanding_principal_balance',
            align: 'right',
            render: (record: any) =>
              renderDecimal(record.outstanding_principal_balance),
            head: {
              align: 'center',
            },
          },
          {
            title: 'Outstanding Principal Balance multiplied by: ',
            field: 'discount',
            align: 'right',
            head: {
              align: 'center',
            },
            render: (record: any) =>
              record.discount
                ? `${Number(record.discount * 100).toFixed(2)}%`
                : '0%',
          },
          {
            title: `Discounted Outstanding Principal Balance (USD)`,
            field: 'discounted_outstanding_principal_balance',
            align: 'right',
            render: (record: any) =>
              renderDecimal(record.discounted_outstanding_principal_balance),
            head: {
              align: 'center',
            },
          },
        ]
      case 'Borrowing Base Interest Reserve':
        return [
          {
            title: 'Interest Period ',
            field: 'interest_period',
            align: 'center',
            render: (record: any) =>
              record.interest_period
                ? moment.utc(record.interest_period).format('MMM-YY')
                : 'Total',
          },
          {
            title: `Interest Payment (USD)`,
            field: 'interest_payment',
            align: 'right',
            render: (record: any) => renderDecimal(record.interest_payment),
            head: {
              align: 'center',
            },
          },
        ]
      case 'Excess Concentration':
        return [
          {
            title: 'Calculation Date',
            field: 'calculation_date',
            align: 'center',
            render: (record: any) =>
              record.calculation_date
                ? moment.utc(record.calculation_date).format('YYYY-MM-DD')
                : '',
          },
          {
            title: `Amount (USD)`,
            field: 'amount',
            align: 'right',
            render: (record: any) => renderDecimal(record.amount),
            head: {
              align: 'center',
            },
          },
        ]
      default:
        return []
    }
  }

  const preparedTableData = (data ?? []).filter(
    x => !['fx_rate'].includes(x.defined_term)
  )

  const tableData = () => {
    switch (component.component) {
      case 'Cash':
        const totalCash = preparedTableData?.reduce((prev, cur) => {
          return {
            bank_name: 'Total',
            balance_value: (prev?.balance_value ?? 0) + cur.balance_value,
          }
        }, {})
        return [
          ...preparedTableData,
          ...(preparedTableData.length > 0 ? [totalCash] : []),
        ]
      case 'Eligible Pool Balance':
        const totalEligibleData = preparedTableData?.reduce((prev, cur) => {
          return {
            dpd_bucket: 'Total',
            outstanding_principal_balance:
              (prev?.outstanding_principal_balance ?? 0) +
              cur.outstanding_principal_balance,
            discounted_outstanding_principal_balance:
              (prev?.discounted_outstanding_principal_balance ?? 0) +
              cur.discounted_outstanding_principal_balance,
          }
        }, {})

        totalEligibleData.discount =
          totalEligibleData.outstanding_principal_balance
            ? (
                totalEligibleData.discounted_outstanding_principal_balance /
                totalEligibleData.outstanding_principal_balance
              ).toFixed(2)
            : 0

        return [
          ...preparedTableData,
          ...(preparedTableData.length > 0 ? [totalEligibleData] : []),
        ]
      case 'Borrowing Base Interest Reserve':
        const totalBaseData = preparedTableData?.reduce((prev, cur) => {
          return {
            interest_payment:
              (prev?.interest_payment ?? 0) + cur.interest_payment,
          }
        }, {})
        return [
          ...preparedTableData.sort((a, b) =>
            moment(a.interest_period) < moment(b.interest_period) ? -1 : 1
          ),
          ...(preparedTableData.length > 0 ? [totalBaseData] : []),
        ]
      default:
        return preparedTableData
    }
  }

  return (
    <Table
      containerClass={
        preparedTableData.length > 0
          ? '[&>table>tbody>tr:last-child>td]:border-t-2 [&>table>tbody>tr:last-child>td]:font-semibold'
          : ''
      }
      columns={columns() as any}
      data={tableData() as any}
      loading={isFetching || !calculationDate}
    />
  )
}

export default DetailTable
