import React, { useContext, useEffect, useState } from 'react'

import FilterCurrency from '@components/filters/filter-currency'
import FilterDateRange from '@components/filters/filter-date-range'
import FilterTypeKey from '@components/filters/filter-type-key'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'

import AggregatedRollRatesL4 from './aggregated-roll-rates'
import CashCollectedL4 from './cash-collected'
import CollectionByStatusL4 from './collection-by-status'
import CollectionRateL4 from './collection-rate'
import NetYieldL4 from './net-yield'
import PRRRL4 from './prrr'

const CollectionL3 = () => {
  const { filter_access, get_access } = useUserAccessFeature()
  const FEATURE = `analytics_risk_collection`
  const { activeFilters, appliedFilters, company, setAppliedFilters } =
    useContext(AuthContext)

  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes = [],
    activeType,
    range,
    currency,
    rate,
  } = activeFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (
      !filterReady &&
      dateStartCohort &&
      dateEndCohort &&
      categoryTypes.length > 0 &&
      rate
    ) {
      //carries filters over unless no previously applied filters
      JSON.stringify(appliedFilters) !== JSON.stringify({ activeFilters }) &&
        setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    range === 0 &&
    activeType === 'All' &&
    currency === company?.currencies_available?.[0]?.to_currency

  const tabs = [
    {
      label: 'Collection Rate',
      component: CollectionRateL4,
      headerClassName: 'text-sm',
    },
    {
      label: 'Prepayment Rate by Cohort',
      component: PRRRL4,
      headerClassName: 'text-sm',
    },
    {
      label: 'Collection by Status',
      component: CollectionByStatusL4,
      headerClassName: 'text-sm',
    },
    {
      label: 'Net Yield',
      component: NetYieldL4,
      headerClassName: 'text-sm',
    },
    {
      label: 'Aggregated Roll Rates',
      component: AggregatedRollRatesL4,
      headerClassName: 'text-sm',
    },
    {
      label: 'Cash Collected by Cohort',
      component: CashCollectedL4,
      headerClassName: 'text-xs lg:text-sm',
    },
  ]
    .filter(t =>
      filter_access(`${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`)
    )
    .map(t => {
      const exportable = get_access(
        `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`,
        'export'
      )
      return {
        ...t,
        component: <t.component exportable={exportable} />,
      }
    })

  return (
    <L3TabsLayout
      title="Collection"
      filters={{
        left: <FilterCurrency />,
        middle: <FilterDateRange isCohort={true} />,
        right: <FilterTypeKey />,
      }}
      disableReset={disableReset}
      tabs={tabs}
    />
  )
}
export default CollectionL3
