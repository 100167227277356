import React, { useState } from 'react'
import moment from 'moment'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { CovenantListResponse, CovenantType } from '@interfaces/covenant'

import { getUnit } from '../helper'
import PopupAudit from '../popup-audit'

interface CovenantL4SummarySideProps {
  covenants: CovenantListResponse[]
  type: CovenantType
  chartData: any[]
  breachCounter: { [key: number]: number[] }
}
const CovenantL4SummarySide = ({
  covenants,
  type,
  chartData,
  breachCounter,
}: CovenantL4SummarySideProps) => {
  const dateFormats: { [key: string]: string } = {
    day: 'DD MMM YY',
    week: 'DD MMM YY',
    month: 'MMM-YY',
    year: 'YYYY',
  }

  const conv = covenants?.[0]
  const isInverted = Number(conv?.invert_colors) === 1

  const [showPopup, setShowPopup] = useState<boolean>(false)

  const hasTrigger =
    !!chartData?.[0]?.[
      `${covenants?.[0]?.[`${type}_covenant`]
        .toLowerCase()
        .split(' ')
        .join('_')}_trigger_1`
    ]

  const TRIAL_PERIOD_DAYS = parseFloat((conv?.test_day ?? 60).toString())
  const chartLastDate = moment.utc().subtract(TRIAL_PERIOD_DAYS, 'days')
  const eligibleData = chartData?.filter(x =>
    moment.utc(x.cohort).isSameOrBefore(moment.utc(chartLastDate))
  )
  const lastData =
    eligibleData[eligibleData.length - 1] ??
    chartData?.[chartData.length - 1] ??
    {}

  return (
    <>
      <PopupAudit
        open={showPopup}
        handler={() => setShowPopup(false)}
        covenant={covenants?.[0]}
        type={type}
      />
      <div className="flex flex-col gap-6 w-[170px]">
        <div className="border border-neutral-border-2 rounded p-3">
          <Typography className="font-medium text-center">
            {parseFloat(conv?.cohort_based.toString()) === 1
              ? 'Current Cohort'
              : 'Current Rate'}
          </Typography>
          <Typography className="text-sm text-center pb-6">
            {moment
              .utc(lastData?.x)
              .format(dateFormats[conv?.cohort_pool ?? 'month'])}
          </Typography>

          {covenants.map((c, i) => {
            return (
              <React.Fragment key={i}>
                {covenants.length > 1 ? (
                  <Typography className="text-sm text-center">
                    {c?.[`${type}_covenant`]}
                  </Typography>
                ) : (
                  ''
                )}
                <Typography className="text-2xl font-semibold text-center pb-6">
                  {lastData?.[lastData?.[`${type}_covenant`]]
                    ? Intl.NumberFormat(undefined, {
                        notation: 'compact',
                        maximumFractionDigits: 2,
                      }).format(
                        lastData?.[
                          c?.[`${type}_covenant`]
                            ?.toLowerCase()
                            ?.split(' ')
                            .join('_')
                        ] ?? 0
                      )
                    : 0}
                  {getUnit(conv, type)}
                </Typography>
              </React.Fragment>
            )
          })}
          {hasTrigger &&
            Object.keys(lastData).map((k, i) => {
              return k?.includes('trigger') ? (
                <div key={i} className="flex justify-between items-center mb-3">
                  <Typography className="text-sm ">
                    <span className="font-semibold capitalize">{`Trigger ${k
                      .split('trigger')[1]
                      .replaceAll('_', '')}:`}</span>
                    {` ${Intl.NumberFormat(undefined, {
                      notation: 'compact',
                      maximumFractionDigits: 2,
                    }).format(lastData?.[k])}${getUnit(conv, type)}`}
                  </Typography>
                  {(isInverted &&
                    covenants.some(
                      c =>
                        lastData?.[
                          c?.[`${type}_covenant`]
                            ?.toLowerCase()
                            ?.split(' ')
                            .join('_')
                        ] >= lastData?.[k]
                    )) ||
                  (!isInverted &&
                    covenants.some(
                      c =>
                        lastData?.[
                          c?.[`${type}_covenant`]
                            ?.toLowerCase()
                            ?.split(' ')
                            .join('_')
                        ] <= lastData?.[k]
                    )) ? (
                    <CheckCircleIcon className="text-success-main w-6 h-6" />
                  ) : (
                    <XCircleIcon className="text-danger-main w-6 h-6" />
                  )}
                </div>
              ) : (
                <></>
              )
            })}
        </div>
        {parseFloat(conv?.cohort_based.toString()) === 1 && (
          <div className="border border-neutral-border-2 rounded p-3">
            <Typography className="font-medium mb-6 text-center">
              Number of Cohort Breaches
            </Typography>
            {Object.keys(lastData).map((k, i) => {
              const counter = k.split('trigger')?.[1]?.replaceAll('_', '')

              return k?.includes('trigger') ? (
                <div key={i} className="flex justify-between items-center mb-3">
                  <Typography className="text-sm capitalize gap-2 flex">
                    <span className="font-semibold">{`Trigger ${counter}:`}</span>
                    {Intl.NumberFormat(undefined, {
                      style: 'decimal',
                    }).format(
                      breachCounter[Number(counter)]?.filter(
                        x => x <= lastData?.x
                      )?.length ?? 0
                    )}
                  </Typography>
                </div>
              ) : (
                <></>
              )
            })}
          </div>
        )}

        <Button onClick={() => setShowPopup(true)}>Audit</Button>
      </div>
    </>
  )
}

export default CovenantL4SummarySide
