import apiClient from './client'

class BigDataLoantapeService {
  static async downloadLoantape(
    isAbbr: boolean,
    startDateExport: string,
    endDateExport: string,
    email?: string,
    slugName?: string
  ) {
    return apiClient.post(`loantape/download`, {
      slug_name: slugName,
      notif_email: email,
      is_abbreviated: isAbbr,
      date_start: startDateExport,
      date_end: endDateExport,
    })
  }

  static async getLoantableSize(schemaName?: string) {
    return apiClient.post(`loantape/get-size`, {
      slug_name: schemaName,
    })
  }
}

export default BigDataLoantapeService
