import React from 'react'

import Button from '@components/atoms/button'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

interface PaginationProps<T> {
  currentIndex: number
  setCurrentIndex: (index: number) => void
  currentData: T
}
const Pagination = ({
  currentIndex,
  setCurrentIndex,
  currentData,
}: PaginationProps<Record<string, any>[]>) => {
  const hasItemsLeft = (): boolean => {
    return currentData.length - currentIndex > 1
  }

  return (
    <div className="bg-white flex justify-evenly items-center p-2">
      <Button
        className="bg-transparent disabled:bg-gray-200 disabled:cursor-not-allowed"
        disabled={currentIndex === 0}
        onClick={() => setCurrentIndex(currentIndex - 1)}
        size="sm"
      >
        <ChevronLeftIcon className="h-4 w-4 m-0.5" color="black" />
      </Button>
      <span className="mx-2"> {currentIndex + 1}</span>
      <Button
        className="bg-transparent disabled:bg-gray-200 disabled:cursor-not-allowed"
        disabled={currentIndex === currentData.length - 1}
        onClick={() => setCurrentIndex(currentIndex + 1)}
        size="sm"
      >
        <ChevronRightIcon className="h-4 w-4 m-0.5" color="black" />
      </Button>
      {hasItemsLeft() && (
        <div className="mr-3 ml-3 text-black-300 text-sm p-2">
          records left:
          <span className="text-sm font-normal ml-2">
            {currentData.length - currentIndex}
          </span>
        </div>
      )}
    </div>
  )
}

export default Pagination
