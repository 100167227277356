import {
  AuditItem,
  Prevalance,
  UnderwritingAuditItem,
} from '../../../../../../types/audit'

export const formatAuditCoverData = (data: any) => {
  return {
    title: '',
    tableWidth: '100%',
    data: Object.keys(data).map(key => {
      return [
        {
          width: '50%',
          content: key.replaceAll('_', ' ').toUpperCase(),
          position: 'left' as const,
        },
        {
          width: '50%',
          content: data[key],
          position: 'left' as const,
        },
      ]
    }),
  }
}
export const formatCashExecSummaryData = (itemData: AuditItem[]) => {
  const execSummaryHeader = [
    {
      width: '20%',
      content: 'Payment ID',
      position: 'center' as const,
      bold: true,
    },
    {
      width: '10%',
      content: 'Amount',
      position: 'center' as const,
      bold: true,
    },
    {
      width: '10%',
      content: 'Payment Date',
      position: 'center' as const,
      bold: true,
    },
    {
      width: '5%',
      content: 'Tier',
      position: 'center' as const,
      bold: true,
    },
    {
      width: '20%',
      content: 'Uploaded By',
      position: 'center' as const,
      bold: true,
    },
    {
      width: '20%',
      content: 'Reviewed By',
      position: 'center' as const,
      bold: true,
    },
    {
      width: '15%',
      content: 'Status',
      position: 'center' as const,
      bold: true,
    },
  ]
  const execSummaryData = itemData.map((item: AuditItem) => {
    const info = item.info
    return [
      {
        width: '20%',
        content: info.payment_id,
        position: 'center' as const,
      },
      {
        width: '10%',
        content: parseFloat(info.payment_amount).toFixed(2),
        position: 'center' as const,
      },
      {
        width: '10%',
        content: info.payment_date,
        position: 'center' as const,
      },
      {
        width: '5%',
        content: info.tier,
        position: 'center' as const,
      },
      {
        width: '20%',
        content: info.evidence_uploaded_by,
        position: 'center' as const,
      },
      {
        width: '20%',
        content: info.reviewed_by,
        position: 'center' as const,
      },
      {
        width: '15%',
        content: info.status,
        position: 'center' as const,
      },
    ]
  })
  return {
    title: '',
    tableWidth: '100%',
    data: [execSummaryHeader, ...execSummaryData],
  }
}

export const formatUnderwritingExecSummaryData = (
  itemData: UnderwritingAuditItem[]
) => {
  const execSummaryHeader = [
    {
      width: '20%',
      content: 'Loan ID',
      position: 'center' as const,
      bold: true,
    },
    {
      width: '10%',
      content: 'Disbursed Amount',
      position: 'center' as const,
      bold: true,
    },
    {
      width: '10%',
      content: 'Disbursed Date',
      position: 'center' as const,
      bold: true,
    },
    {
      width: '5%',
      content: 'Tier',
      position: 'center' as const,
      bold: true,
    },
    {
      width: '20%',
      content: 'Uploaded By',
      position: 'center' as const,
      bold: true,
    },
    {
      width: '20%',
      content: 'Reviewed By',
      position: 'center' as const,
      bold: true,
    },
    {
      width: '15%',
      content: 'Status',
      position: 'center' as const,
      bold: true,
    },
  ]
  const execSummaryData = itemData.map((item: UnderwritingAuditItem) => {
    const info = item.info
    return [
      {
        width: '20%',
        content: info.loan_id,
        position: 'center' as const,
      },
      {
        width: '10%',
        content: parseFloat(info.disbursed_amount).toFixed(2),
        position: 'center' as const,
      },
      {
        width: '10%',
        content: info.disbursed_date,
        position: 'center' as const,
      },
      {
        width: '5%',
        content: info.tier,
        position: 'center' as const,
      },
      {
        width: '20%',
        content: info.evidence_uploaded_by,
        position: 'center' as const,
      },
      {
        width: '20%',
        content: info.reviewed_by,
        position: 'center' as const,
      },
      {
        width: '15%',
        content: info.status,
        position: 'center' as const,
      },
    ]
  })
  return {
    title: '',
    tableWidth: '100%',
    data: [execSummaryHeader, ...execSummaryData],
  }
}

export const formatCashPrevalanceData = (prevalanceData: Prevalance[]) => {
  return {
    title: '',
    tableWidth: '100%',
    data: [
      [
        {
          width: '40%',
          content: 'Payment Flag',
          position: 'center' as const,
          bold: true,
        },
        {
          width: '30%',
          content: 'Percentage of Payments',
          position: 'center' as const,
          bold: true,
        },
        {
          width: '30%',
          content: 'Percentage of Loans',
          position: 'center' as const,
          bold: true,
        },
      ],
      ...prevalanceData.map((p: any) => {
        return [
          {
            width: '40%',
            content: p.payment_flag,
            position: 'center' as const,
            bold: false,
          },
          {
            width: '30%',
            content: `${p.percentage_of_payments.toFixed(2)}%`,
            position: 'center' as const,
            bold: false,
          },
          {
            width: '30%',
            content: `${p.percentage_of_loans.toFixed(2)}%`,
            position: 'center' as const,
            bold: false,
          },
        ]
      }),
    ],
  }
}

export const formatUnderwritingPrevalanceData = (
  prevalanceData: Prevalance[]
) => {
  return {
    title: '',
    tableWidth: '100%',
    data: [
      [
        {
          width: '50%',
          content: 'Loan Flag',
          position: 'center' as const,
          bold: true,
        },

        {
          width: '50%',
          content: 'Percentage of Loans',
          position: 'center' as const,
          bold: true,
        },
      ],
      ...prevalanceData.map((p: any) => {
        return [
          {
            width: '50%',
            content: p.loan_flag,
            position: 'center' as const,
            bold: false,
          },

          {
            width: '50%',
            content: `${p.percentage_of_loans.toFixed(2)}%`,
            position: 'center' as const,
            bold: false,
          },
        ]
      }),
    ],
  }
}
