import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'
import { NavLink } from 'react-router-dom'

import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { Facility } from '@interfaces/facility'
import { Card, CardBody } from '@material-tailwind/react'
import { CashflowService } from '@services/api-manage/monitor-cashflow'

import SurplusChart from '../borrowing-base/borrowing-base/surplus-chart'

import ManageOverviewL3Covenant from './covenant'

const Overview = ({ facility }: { facility?: Facility }) => {
  const {
    company,
    activeFilters,
    setAppliedFilters,
    appliedFilters,
    optionFilters,
  } = useContext(AuthContext)

  const { activeDebtDeal: appliedDebtDeal } = appliedFilters
  const { activeDebtDeal } = activeFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (
      !filterReady &&
      appliedDebtDeal === undefined &&
      activeDebtDeal !== undefined &&
      !facility
    ) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */

  const { activeDebtDeal: appliedActiveDebt = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[appliedActiveDebt]

  const filters = {
    slug_name:
      facility?.slug_name ??
      activeFacility?.slug_name ??
      company?.slug_name ??
      '',
    facility: facility?.facility ?? activeFacility?.facility ?? '',
  }

  const { data: statisticData } = useQuery(
    ['paymentScheduleStatistic', filters],
    () => CashflowService.getPaymentScheduleStatistic(filters),
    {
      ...getStaleMins(),
      enabled: !!activeFacility?.facility && !!company?.slug_name,
    }
  )

  const cashCoverage =
    (statisticData?.cash_available ?? 0) -
    (statisticData?.next_payment_amount ?? 0)

  const committedAmount = Array(activeFacility?.lender ?? 0)
    .fill('')
    .reduce((prev: number, cur: number, idx: number) => {
      return (
        prev + (activeFacility?.[`lender_${idx + 1}_committed_amount`] ?? 0)
      )
    }, 0)

  const amountAvailable =
    (committedAmount ?? 0) - (statisticData?.outstanding_principal ?? 0)

  return (
    <>
      <div className="flex gap-6 justify-center">
        {(facility?.features?.includes('borrowing-base') ||
          activeFacility?.features?.includes('borrowing-base')) && (
          <NavLink
            to={`/manage/monitor/borrowing-base`}
            className={`flex-1 ${facility ? 'max-w-[33%]' : ''}`}
          >
            <Card className="h-full">
              <CardBody>
                <SurplusChart facility={facility} />
              </CardBody>
            </Card>
          </NavLink>
        )}
        {activeFacility?.features?.includes('cashflows') && !facility && (
          <NavLink to={`/manage/monitor/cashflows`} className="flex-1">
            <Card className="h-full">
              <CardBody>
                <Table
                  columns={[
                    {
                      title: 'Payment Information',
                      field: 'payment_info',
                      head: {
                        colSpan: 2,
                        className: 'text-center',
                      },
                    },
                    {
                      field: 'payment_value',
                      head: {
                        colSpan: 0,
                      },
                    },
                  ]}
                  data={[
                    {
                      payment_info: 'Next Payment Date',
                      payment_value: statisticData?.next_payment_date
                        ? `${moment
                            .utc(statisticData.next_payment_date)
                            .format('YYYY-MM-DD')} <br />${moment
                            .utc(statisticData.next_payment_date)
                            .fromNow()}`
                        : '-',
                    },
                    {
                      payment_info: 'Next Payment value',
                      payment_value: `${Intl.NumberFormat(undefined, {
                        style: 'decimal',
                        maximumFractionDigits: 2,
                      }).format(statisticData?.next_payment_amount ?? 0)} USD`,
                    },
                    {
                      payment_info: 'Cash Available',
                      payment_value: `${Intl.NumberFormat(undefined, {
                        style: 'decimal',
                        maximumFractionDigits: 2,
                      }).format(statisticData?.cash_available ?? 0)} USD`,
                    },
                    {
                      payment_info: 'Cash Coverage',
                      payment_value: `<span class="${
                        cashCoverage < 0
                          ? 'text-danger-main'
                          : 'text-success-main'
                      }">${cashCoverage < 0 ? '(' : ''}${Intl.NumberFormat(
                        undefined,
                        {
                          style: 'decimal',
                          maximumFractionDigits: 2,
                        }
                      ).format(Math.abs(cashCoverage ?? 0))} USD${
                        cashCoverage < 0 ? ')' : ''
                      }</span>`,
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </NavLink>
        )}
        {activeFacility?.features?.includes('actions') && !facility && (
          <NavLink to={`/manage/monitor/actions`} className="flex-1">
            <Card className="h-full">
              <CardBody>
                <Table
                  columns={[
                    {
                      title: 'Funds Available',
                      field: 'fund_info',
                      head: {
                        colSpan: 2,
                        className: 'text-center',
                      },
                    },
                    { field: 'fund_value', colSpan: 0 },
                  ]}
                  data={[
                    {
                      fund_info: 'Drawdown Period End',
                      fund_value: activeFacility?.draw_period_end_date
                        ? `${moment
                            .utc(activeFacility.draw_period_end_date)
                            .format('YYYY-MM-DD')} <br />${moment
                            .utc(activeFacility.draw_period_end_date)
                            .fromNow()}`
                        : '-',
                    },
                    {
                      fund_info: 'Committed Amount',
                      fund_value: `${Intl.NumberFormat(undefined, {
                        style: 'decimal',
                        maximumFractionDigits: 2,
                      }).format(committedAmount ?? 0)} USD`,
                    },
                    {
                      fund_info: 'Outstanding Principal',
                      fund_value: `${Intl.NumberFormat(undefined, {
                        style: 'decimal',
                        maximumFractionDigits: 2,
                      }).format(
                        statisticData?.outstanding_principal ?? 0
                      )} USD`,
                    },
                    {
                      fund_info: 'Amount Available',
                      fund_value: `<span class="${
                        amountAvailable < 0
                          ? 'text-danger-main'
                          : 'text-success-main'
                      }">${amountAvailable < 0 ? '(' : ''}${Intl.NumberFormat(
                        undefined,
                        {
                          style: 'decimal',
                          maximumFractionDigits: 2,
                        }
                      ).format(Math.abs(amountAvailable ?? 0))} USD${
                        amountAvailable < 0 ? ')' : ''
                      }</span>`,
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </NavLink>
        )}
      </div>
      {(facility?.features?.includes('portfolio-covenants') ||
        activeFacility?.features?.includes('portfolio-covenants')) && (
        <div className="mt-6">
          <Card>
            <CardBody>
              <ManageOverviewL3Covenant type="portfolio" facility={facility} />
            </CardBody>
          </Card>
        </div>
      )}
      {(facility?.features?.includes('financial-covenants') ||
        activeFacility?.features?.includes('financial-covenants')) && (
        <div className="mt-6">
          <Card>
            <CardBody>
              <ManageOverviewL3Covenant type="financial" facility={facility} />
            </CardBody>
          </Card>
        </div>
      )}
    </>
  )
}
export default Overview
