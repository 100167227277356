const columns = [
  {
    title: 'Defined Term',
    field: 'defined_term',
  },
  {
    title: 'Definition',
    field: 'definition',
  },
  {
    title: 'Facility Variable',
    field: 'facility_variable',
  },
]

export const PORTFOLIO_COVENANT_MOCK = [
  {
    title: 'Maximum Cohort Default Ratio',
    columns,
    data: [
      {
        defined_term: 'Default Cohort Ratio Test',
        definition: `<div className="flex flex-col">
        means the Default Cohort Ratio for any Cohort Pool shall be no greater than the Maximum Default Cohort Ratio
        <div class="flex flex-col items-center mt-4">
          Default Cohort Ratio < Maximum Default Cohort Ratio
        </div>
      </div>`,
      },
      {
        defined_term: 'Default Cohort Ratio',
        definition: `<div className="flex flex-col">
        means with respect to all Eligible Assets of a Common Age within a Cohort Pool, the ratio calculated as of the end of each calendar month which shall be equal to the quotient of (a) an aggregate amount equal to the Defaulted Outstanding Principal Balance in such Cohort Pool and (b) an aggregate amount equal to the Original Principal Balance in such Cohort Pool.
        <div class="flex flex-col items-center mt-4">
          <span class="border-b border-neutral-body-2">Σ Defaulted Outstanding Principle Balance</span>
          <hr />
          <span>Σ Original Principal Balance</span>
        </div>
      </div>`,
        facility_variable: 'Borrower',
      },
      {
        defined_term: 'Defaulted Outstanding Principal Balance',
        definition:
          'means any outstanding principal balance which have any portion of the scheduled payment greater than the Bad Definition of delinquent which is expected to be repaid by the obligor relating to an Asset as of the date of calculation. ',
        facility_variable: 'Opco Account',
      },
      {
        defined_term: 'Bad Definition',
        definition: 'Means days past due equal or greater to [•] ',
        facility_variable: '30',
      },
      {
        defined_term: 'Original Principal Balance',
        definition:
          'means the original principal balance, which is expected to be repaid by the obligor relating to an Asset at the time of disbursement.  ',
      },
      {
        defined_term: 'Asset',
        definition:
          'means the loan originated by the originator to customers (the underlying loans)',
      },
      {
        defined_term: 'Eligible Assets',
        definition:
          'means all Assets as described by these characteristics [•]',
      },
      {
        defined_term: 'Cohort Pool',
        definition:
          'means all Eligible Assets that were originated in the same [•].',
      },
      {
        defined_term: 'Common Age',
        definition:
          'means, for any Eligible Asset, with respect to any Cohort Pool, the highest month on book reached by all the Eligible Asset included in such Cohort Pool.',
      },
      {
        defined_term: 'Maximum Default Cohort Ratio',
        definition: {
          content:
            'means the product of (a) Maximum Default Tolerance and (b) as indicated in the table below for each specific trigger:',
          table: {
            columns: [
              {
                title: 'Trigger',
                field: 'trigger',
              },
              {
                title: 'Maximum Default Tolerance multiplied by:',
                field: 'multiplied_by',
              },
            ],
            data: [
              {
                trigger: 'Trigger 1',
                multiplied_by: '1,5',
              },
              {
                trigger: 'Trigger 2',
                multiplied_by: '1',
              },
              {
                trigger: 'Trigger 3',
                multiplied_by: 'NA',
              },
            ],
          },
        },
      },
      {
        defined_term: 'Maximum Default Tolerance',
        definition: `
          <div className="flex flex-col">
            <p>means an amount calculated as of the end of each calendar month in respect of the relevant Cohort Pool equal to the quotient of:</p>
            <ol class="list-[lower-roman] pl-6">
              <li>
                1 plus the Percent NonPrincipal Payments, less the sum of:
                <ol class="list-[lower-alpha] pl-6">
                  <li>the Advance Rate; and</li>
                  <li>the Cohort Funding Costs; and</li>
                </ol>
              </li>
              <li> 1 plus the Percent NonPrincipal Payments.</li>
            <ol>
            <div class="flex flex-col items-center mt-4">
              <span class="border-b border-neutral-body-2">1 + Percent Non Principal Payments - (Advance Rate + Cohort Funding Cost)</span>
              <hr />
              <span>1 + Percent Non Principal Payments</span>
            </div>
          </div>
        `,
      },
      {
        defined_term: 'Percent NonPrincipal Payments',
        definition: `<div className="flex flex-col">
          means with respect to a Cohort Pool the quotient of (a) the aggregate of the NonPrincipal Payments and (b) the aggregate of the Original Principal Balance.
          <div class="flex flex-col items-center mt-4">
            <span class="border-b border-neutral-body-2">Σ Original NonPrincipal Payments</span>
            <hr />
            <span>Σ Original Principal Balance</span>
          </div>
        </div>`,
      },
      {
        defined_term: 'Original NonPrincipal Payments',
        definition:
          'means the aggregate of all original expected non-principal payments minus any Expected Rebates, excluding VAT or other forms of tax, expected to be received relating to an Asset at the time of disbursement',
      },
      {
        defined_term: 'Expected Rebates',
        definition:
          'means the aggregate of all expected negative payments the originator has agreed to credit the obligor relating to an Asset as of the date of disbursement.',
      },
      {
        defined_term: 'Cohort Funding Cost',
        definition: `<div className="flex flex-col">
            means the quotient of (a) Annual Funding Cost and (b) number of
            Cohort Pools in a calendar year (unless a rolling Cohort Pool is
            used in which case the rolling period divided by 12)
            <div class="flex flex-col items-center mt-4"><span class="border-b border-neutral-body-2">Annual Funding Cost</span><hr /><span># of Cohort Pools</span></div>
          </div>`,
      },
      {
        defined_term: 'Annual Funding Cost',
        definition:
          'means the annual cost associated with funding the debt facility (interest payments, backup service provider fees, data agent fees, hedging fees or any other fees associated with the facility) expressed as [•]%',
        facility_variable: '30',
      },
    ],
  },
  {
    title: 'Minimum Cash Collection Cohort Ratio',
    columns,
    data: [],
  },
  {
    title: 'Minimum Cohort Excess spread',
    columns,
    data: [],
  },
  {
    title: 'Minimum Cohort Net Annualized Return',
    columns,
    data: [],
  },
  {
    title: 'Max First Time Borrowers',
    columns,
    data: [],
  },
  {
    title: 'Max First Time Borrowers Default Base',
    columns,
    data: [],
  },
]
