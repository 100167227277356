import {
  CollateralDataResponse,
  CollateralFilterResponse,
  GetCollateralFilterRequest,
  GetCollateralListRequest,
} from '@interfaces/collateral'
import { PaginationResponse } from '@interfaces/pagination'

import dataValidationApiClient from './client'

export class CollateralService {
  static async getFilter(params: GetCollateralFilterRequest) {
    const { data } = await dataValidationApiClient.post<
      CollateralFilterResponse[]
    >(`/collateral/filter`, params)

    return data
  }

  static async getList(params: GetCollateralListRequest) {
    const { data } = await dataValidationApiClient.post<
      PaginationResponse<CollateralDataResponse>
    >(`/collateral/list`, params)
    return data
  }
}
