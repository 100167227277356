import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import Pagination from '@components/pagination'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { Facility } from '@interfaces/facility'
import { PaymentScheduleResponse } from '@interfaces/manage-monitor-cashflow'
// import {
//   ArchiveBoxIcon,
//   DocumentTextIcon,
//   EllipsisHorizontalIcon,
//   LockClosedIcon,
// } from '@heroicons/react/24/outline'
import {
  // Menu,
  // MenuHandler,
  // MenuItem,
  // MenuList,
  Option,
  Select,
} from '@material-tailwind/react'
import { CashflowService } from '@services/api-manage/monitor-cashflow'

interface PaymentScheduleProps {
  facility?: Facility
}

const PaymentSchedule = ({ facility }: PaymentScheduleProps) => {
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)

  const { activeDebtDeal = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const PER_PAGE = 15
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([])

  const [selectedDisbursement, setSelectedDisbursement] = useState<
    string | undefined
  >()

  // If this is for a single facility
  const filters = {
    slug_name:
      facility?.slug_name ??
      activeFacility?.slug_name ??
      company?.slug_name ??
      '',
    facility: facility?.facility ?? activeFacility?.facility ?? '',
  }

  const { data: disbursementList } = useQuery(
    ['disbursementList', filters],
    () => CashflowService.getPaymentScheduleDisbursementDates(filters),
    {
      ...getStaleMins(),
      enabled: !!activeFacility?.facility && !!company?.slug_name,
    }
  )

  const { data: statisticData } = useQuery(
    ['paymentScheduleStatistic', filters],
    () => CashflowService.getPaymentScheduleStatistic(filters),
    {
      ...getStaleMins(),
      enabled: !!activeFacility?.facility && !!company?.slug_name,
    }
  )

  const { data: feeList } = useQuery(
    ['feeList', filters],
    () => CashflowService.getPaymentScheduleFee(filters),
    {
      ...getStaleMins(),
      enabled: !!activeFacility?.facility && !!company?.slug_name,
    }
  )

  const listFilters = {
    ...filters,
    page: page + 1,
    per_page: perPage,
    disbursement_date: selectedDisbursement ?? '',
  }

  const { data, isFetching } = useQuery(
    ['paymentScheduleList', listFilters],
    () => CashflowService.getPaymentScheduleList(listFilters),
    {
      ...getStaleMins(),
      enabled: !!activeFacility?.facility && !!company?.slug_name,
    }
  )

  const columns = (feeList ?? []).reduce((prev: any[], cur) => {
    const field = `${cur}_total`
    return [
      ...prev,
      {
        title: cur,
        field,
        className: 'min-w-[100px]',
        align: 'right',
        head: { align: 'center' },
        render: (record: PaymentScheduleResponse) =>
          new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
          }).format(record[field] ?? 0),
      },
    ]
  }, [])

  const tableData = data?.data ?? []

  const expandedRowRender = (record: any) => {
    const mergedDetails = Object.keys(record.details).reduce(
      (p: { [key: string]: any }, c) => {
        const disbursementDate = c.replace('disburse-', '')
        const disbursementMoment = moment.utc(disbursementDate).add(1, 'day')
        const isPreviousDisbursement = disbursementMoment.isBefore(
          moment.utc(record.st_date)
        )
        if (isPreviousDisbursement) {
          p.previous = [...(p.previous ?? []), ...record.details[c]]
        } else {
          p[`Disbursement ${disbursementMoment.format('YYYY-MM-DD')}`] =
            record.details[c]
        }
        return p
      },
      {}
    )
    const mergedKeys = Object.keys(mergedDetails)
    return (
      <div className="[&>div]:!mb-0 [&>div>div]:!rounded-none [&>div:first-child>div]:!rounded-t-lg [&>div:last-child>div]:!rounded-b-lg">
        {mergedKeys.map(d => {
          const expandedColumns: any[] = [
            {
              title: d,
              field: 'investor',
              align: 'center',
              head: {
                colSpan: 7 + (feeList ?? []).length,
              },
              className: 'min-w-[150px]',
            },
            {
              field: 'st_date',
              align: 'center',
              head: { colSpan: 0 },
              className: 'min-w-[150px]',
            },
            {
              field: 'ed_date',
              align: 'center',
              head: { colSpan: 0 },
              className: 'min-w-[150px]',
            },
            {
              field: 'no_of_days',
              align: 'center',
              head: { colSpan: 0 },
              className: 'min-w-[150px]',
            },
            {
              field: 'investment_amount',
              align: 'right',
              head: { align: 'center', colSpan: 0 },
              className: 'min-w-[150px]',
              render: (record: any) =>
                !isNaN(record.investment_amount)
                  ? Intl.NumberFormat(undefined, {
                      maximumFractionDigits: 2,
                    }).format(record.investment_amount)
                  : 'Disbursement',
            },
            {
              field: 'outstanding_principal',
              align: 'right',
              head: { align: 'center', colSpan: 0 },
              className: 'min-w-[150px]',
              render: (record: any) =>
                !isNaN(record.outstanding_principal)
                  ? Intl.NumberFormat(undefined, {
                      maximumFractionDigits: 2,
                    }).format(record.outstanding_principal)
                  : 'Outstanding Principal',
            },
            ...(feeList ?? []).map(f => ({
              field: f,
              align: 'right',
              head: { align: 'center', colSpan: 0 },
              className: 'min-w-[150px]',
              render: (record: any) =>
                !isNaN(record[f])
                  ? Intl.NumberFormat(undefined, {
                      maximumFractionDigits: 2,
                    }).format(record[f])
                  : record[f]
                  ? f
                  : 0,
            })),
            {
              field: 'percentage_interest',
              align: 'right',
              head: { align: 'center', colSpan: 0 },
              className: 'min-w-[150px]',
              render: (record: any) =>
                !isNaN(record.percentage_interest)
                  ? Intl.NumberFormat(undefined, {
                      maximumFractionDigits: 2,
                      style: 'percent',
                    }).format(record.percentage_interest)
                  : 'Interest Rate',
            },
          ]

          const expandedData: any[] = mergedDetails[d].reduce(
            (p: any[], c: any) => {
              const investorIndex = p.findIndex(
                (x: any) => x.investor === c.investor
              )

              if (investorIndex >= 0) {
                if (d === 'previous') {
                  p[investorIndex].investment_amount =
                    parseFloat(p[investorIndex].investment_amount) +
                    parseFloat(c.investment_amount)
                  p[investorIndex][c.fee] =
                    parseFloat(p[investorIndex][c.fee]) + parseFloat(c.inflow)
                } else {
                  p[investorIndex][c.fee] = c.inflow
                  p[investorIndex].ed_date = c.ed_date
                  p[investorIndex].no_of_days = c.no_of_days
                }
                return p
              }

              return [
                ...p,
                {
                  ...c,
                  [c.fee]: c.inflow,
                },
              ]
            },
            []
          )
          return expandedData.length > 0 ? (
            <Table
              key={d}
              containerClass={`[&>table>thead>tr>td]:font-semibold [&>table>thead>tr>td]:capitalize [&>table>thead>tr>th]:capitalize [&>table>tbody>tr>td:first-child]:capitalize`}
              columns={expandedColumns as any}
              data={expandedData}
              headerData={[
                {
                  investor: 'Lender',
                  st_date: 'Period Start Date',
                  ed_date: 'Period End Date',
                  no_of_days: 'No of Days',
                  ...feeList?.reduce((p, c) => ({ ...p, [c]: c }), {}),
                },
              ]}
            />
          ) : null
        })}
      </div>
    )
  }

  const onExpand = (index: number) => {
    const indexes = expandedIndexes.includes(index)
      ? expandedIndexes.filter(i => i !== index)
      : [...expandedIndexes, index]

    setExpandedIndexes(indexes)
  }

  useEffect(() => {
    setPage(0)
  }, [selectedDisbursement, perPage])

  useEffect(() => {
    setExpandedIndexes([])
  }, [page, selectedDisbursement])

  return (
    <div className="flex flex-col p-4">
      <div className="flex justify-end mb-6 gap-6">
        <div className="max-w-[300px] [&>div]:!min-w-[200px]">
          <Select
            className="[&~ul]:max-h-[200px]"
            onChange={(val: any) => {
              setSelectedDisbursement(val)
            }}
            selected={() => {
              return <span>{selectedDisbursement || 'Summary'}</span>
            }}
          >
            {['', ...(disbursementList ?? [])].map((d: string) => (
              <Option key={d} value={d}>
                {d || 'Summary'}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="flex justify-end mb-6 grid grid-cols-4 gap-6">
        {Object.keys(statisticData ?? {}).map(k => {
          return (
            <div
              key={k}
              className="rounded-lg border border-neutral-border-2 bg-neutral-surface-1 p-3"
            >
              <Typography className="capitalize text-sm text-right">
                {k.split('_').join(' ')}
              </Typography>
              <Typography className="text-xl font-semibold text-right">
                {isNaN(statisticData[k])
                  ? statisticData[k]
                  : new Intl.NumberFormat(undefined, {
                      maximumFractionDigits: 2,
                    }).format(statisticData[k])}
              </Typography>
            </div>
          )
        })}
      </div>
      <Table
        containerClass="[&>table>thead>tr>th]:capitalize [&>table>thead>tr>th]:border-b-4 [&>table>thead>tr:last-child>td]:border-b-4 [&>table>thead>tr>td]:font-semibold"
        columns={[
          {
            title: 'No.',
            field: 'id',
            align: 'center',
            className: 'min-w-[50px] left-0 shadow-inner',
            render: (r: PaymentScheduleResponse, i: number) =>
              r.st_date ? page * perPage + (i + 1) : '',
          },
          {
            title: 'Period Start Date',
            field: 'st_date',
            className: 'min-w-[150px]',
            align: 'center',
          },
          {
            title: 'Period End Date',
            field: 'ed_date',
            className: 'min-w-[150px]',
            align: 'center',
          },
          {
            title: '# Disbursements in Period',
            field: 'id',
            align: 'center',
            className: 'max-w-[100px]',
            render: (r: PaymentScheduleResponse) => {
              return Object.keys(r.details).filter(x => {
                const disbursementDate = x.replace('disburse-', '')
                const disbursementMoment = moment
                  .utc(disbursementDate)
                  .add(1, 'day')
                const isPreviousDisbursement = disbursementMoment.isBefore(
                  moment.utc(r.st_date)
                )
                return !isPreviousDisbursement
              }).length
            },
          },
          {
            title: 'No of Days in Period',
            field: 'no_of_days',
            align: 'center',
            className: 'max-w-[100px]',
          },
          ...columns,
          // {
          //   title: '',
          //   field: 'id',
          //   width: '50px',
          //   className: 'sticky right-0 border-l shadow-inner',
          //   render: (record: any) => {
          //     return record.id ? (
          //       <Menu placement="left-end">
          //         <MenuHandler>
          //           <button className="p-2 text-sm cursor-pointer rounded hover:bg-primary-surface-2 hover:text-primary-main">
          //             <EllipsisHorizontalIcon className="w-4 h-4" />
          //           </button>
          //         </MenuHandler>
          //         <MenuList>
          //           <MenuItem className="!text-primary-main flex hover:bg-primary-surface-2">
          //             <DocumentTextIcon className="mr-2 w-4 h-4" />
          //             Invoice Request
          //           </MenuItem>
          //           <MenuItem className="flex">
          //             <LockClosedIcon className="mr-2 w-4 h-4" />
          //             View Proof
          //           </MenuItem>
          //           <MenuItem className="flex">
          //             <ArchiveBoxIcon className="mr-2 w-4 h-4" />
          //             Upload Proof
          //           </MenuItem>
          //         </MenuList>
          //       </Menu>
          //     ) : null
          //   },
          // },
        ]}
        loading={isFetching}
        data={tableData}
        rowClass={(r: PaymentScheduleResponse) => {
          return moment(r.st_date).format('YYYY-MM') ===
            moment.utc().format('YYYY-MM')
            ? '[&>td]:!bg-primary-surface-1'
            : ''
        }}
        expandable={{
          expandedIndexes,
          expandedRowRender,
          onExpand,
        }}
      />
      <Pagination
        id="payment-schedule-table-pagination"
        total={data?.total ?? 0}
        page={page}
        onChangePage={p => setPage(p)}
        limit={perPage}
        limits={Array(3)
          .fill('')
          .map((_, i) => PER_PAGE * (i + 1))}
        onChangeLimit={l => setPerPage(l)}
      />
    </div>
  )
}

export default PaymentSchedule
