import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import DelinqByCohortL5 from './delinq-by-cohort'

const DelinqByCohortL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const tabs = [
    {
      label: 'Borrower Concentration',
      desc: {
        description:
          'The proportion of the outstanding balance by value in each delinquency bucket, for a (monthly) cohort, tracked over time.',
        formula:
          'Same as outstanding by delinquency but for each monthly cohort rather than the whole portfolio, and only at the latest date.',
      },
      component: <DelinqByCohortL5 exportable={exportable} />,
    },
  ]

  return (
    <L4TabsLayout
      tabs={tabs}
      additionalIndicator={
        showHistoricalRateIndicator ? (
          <ExchangeRateBadge isHistorical={false} />
        ) : undefined
      }
    />
  )
}

export default DelinqByCohortL4
