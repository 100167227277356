import React from 'react'

import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useUserAccessFeature } from '@helpers/auth-provider'

import BankDistributionL5 from './bank-distribution'
import CashL5 from './cash'

const CashL4 = () => {
  const { filter_access, get_access } = useUserAccessFeature()
  const FEATURE = `analytics_financials_key-indicators_cash`
  const tabs = [
    {
      label: 'Cash',
      desc: 'Either Cash and Cash Equivalents taken from the Balance Sheet or if a direct banking integration is complete with all of the entities banking accounts is pulled direct from the bank accounts and is the aggregate of all accounts',
      component: CashL5,
    },
    {
      label: 'Bank Account Distribution',
      desc: 'Feature unavailable as available data may be insufficient. Please contact us for more information',
      component: BankDistributionL5,
    },
  ]
    .filter(t =>
      filter_access(`${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`)
    )
    .map(t => {
      const exportable = get_access(
        `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`,
        'export'
      )
      return { ...t, component: <t.component exportable={exportable} /> }
    })

  return <L4TabsLayout tabs={tabs} />
}

export default CashL4
