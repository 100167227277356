import { AccountingInfo } from '@interfaces/accounting'

import { ACTIONS } from '../constants'
export interface ModalProps {
  open: boolean
  accountData?: AccountingInfo
  title: string
  action?: ACTIONS
}

export enum AccountTypeNames {
  name = 'entityName',
  slugName = 'entitySlugName',
}
