export const parseValue = (value: string, type: 'number' | 'percent') => {
  if (type === 'number') {
    return parseFloat((value ?? '0').replaceAll(',', ''))
  } else {
    return parseFloat((value ?? '0').replaceAll('%', ''))
  }
}

export const formatMoney = (value: number) => {
  let result =
    typeof value === 'number' && !isNaN(value)
      ? `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(value ?? 0)}`
      : '[*]'
  if (result.includes('-')) {
    result = `(${result.replace('-', '')})`
  }
  return result
}

export const formatPercent = (value: number) => {
  return typeof value === 'number' && !isNaN(value)
    ? `${Intl.NumberFormat(undefined, {
        style: 'decimal',
        maximumFractionDigits: 2,
        notation: 'compact',
      }).format(value ?? 0)}%`
    : '[*]%'
}

export const formatDecimal = (value: number) =>
  typeof value === 'number' && !isNaN(value)
    ? `${Intl.NumberFormat(undefined, {
        style: 'decimal',
        maximumFractionDigits: 1,
        minimumFractionDigits: 0,
      }).format(value ?? 0)}`
    : '[*]'
