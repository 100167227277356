import React, { useContext, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import {
  ArrowPathIcon,
  CheckCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'

const ExportDialog = ({ data = [] as any }: { data: DashboardResponse[] }) => {
  const { company } = useContext(AuthContext)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }

  const _download = async () => {
    setIsDownloading(true)

    const workbook = XLSX.utils.book_new()

    data.forEach(d => {
      const { data } = d
      const content = data ?? []
      if ((content ?? []).length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(content ?? [])
        const title = d.title || ''
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          title.length > 30
            ? title
                .match(/\b([A-Za-z0-9])/g)
                ?.join('')
                .toUpperCase()
            : d.title || ''
        )
      }
    })

    await XLSX.writeFile(
      workbook,
      `${company?.legal_name} Manage Monitor Dashboard.xlsx`
    )

    setIsDownloading(false)
  }

  useEffect(() => {
    setIsDownloading(false)
  }, [open])

  return (
    <>
      <Button
        className="mr-0.25!shadow-none border border-primary-main text-primary-main py-2 px-6 hover:bg-primary-main hover:text-neutral-white"
        onClick={handleOpen}
      >
        <Typography className="capitalize font-medium text-sm">
          Export
        </Typography>
      </Button>
      <Dialog open={open} handler={() => undefined}>
        <DialogHeader className="justify-between">
          <Typography className="capitalize font-medium text-xl">
            Export Manage Monitor Dashboard
          </Typography>
          <XMarkIcon
            onClick={handleOpen}
            className="w-6 h-6 cursor-pointer hover:opacity-50"
          />
        </DialogHeader>
        <DialogBody divider className="flex flex-col p-6">
          <Typography className="text-sm text-center font-bold">
            Please DO NOT close this dialog or the browser tab until exporting
            is complete
          </Typography>
          <div className="mt-8 flex flex-col gap-4 max-h-[50vh] overflow-y-auto">
            {data.map(c => {
              const { data = [] } = c
              return (
                <div
                  key={c.title}
                  className="rounded-lg bg-neutral-border-1 px-4 py-2 flex gap-4 justify-between items-center"
                >
                  <Typography className="capitalize text-sm flex-1">
                    {c.title}
                  </Typography>

                  {data.length > 0 && (
                    <CheckCircleIcon className="text-primary-main w-6 h-6" />
                  )}
                </div>
              )
            })}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={_download}
            disabled={isDownloading}
            className="!shadow-none border py-2 px-6 border-primary-main text-primary-main hover:bg-primary-main hover:text-neutral-white disabled:border-neutral-border-3 disabled:bg-neutral-border-2 disabled:text-neutral-border-3"
          >
            {isDownloading && (
              <ArrowPathIcon className="w-4 h-4 mr-4 text-primary-main animate-spin" />
            )}
            Download
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default ExportDialog
