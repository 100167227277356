import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import FilterCollateralData from '@components/filters/filter-collateral-data'
import FilterCurrency from '@components/filters/filter-currency'
import BaseLayout from '@components/layouts/base-layout'
import Pagination from '@components/pagination'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { getStaleMins } from '@helpers/stale-timer'
import { GetCollateralListRequest } from '@interfaces/collateral'
import { Alert, Card, CardBody } from '@material-tailwind/react'
import { CollateralService } from '@services/api-data-validation/data-quarantine-collateral'

import ExportCollateralL3 from './export'

const CollateralL3 = () => {
  const {
    company,
    activeFilters,
    appliedFilters,
    setAppliedFilters,
    optionFilters,
  } = useContext(AuthContext)

  const { get_access } = useUserAccessFeature()
  const feature = `data-validation_data-quarantine_collateral-data`
  const can_export = get_access(feature, 'export')

  const { rate } = activeFilters
  const { activeDebtDeal, collateral } = appliedFilters
  const { id, table_name, column_name, error_type, status } = collateral ?? {}

  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (!filterReady && rate) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.schema_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset = Object.keys(collateral ?? {}).length === 0

  const LIMIT = 15
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(LIMIT)

  const filters: GetCollateralListRequest = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    id,
    table_name,
    column_name,
    error_type,
    status,
    start: page * limit,
    limit,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['collateral', filters],
    () => CollateralService.getList(filters),
    getStaleMins()
  )

  const { data, total } = fetchedData ?? {}

  const columns = [
    { title: 'ID', field: 'id', align: 'center' },
    { title: 'Error Type', field: 'error_type', align: 'center' },
    { title: 'Error Message', field: 'error_message' },
    {
      title: 'Status',
      field: 'to_quarantine',
      align: 'center',
      render: (record: { to_quarantine: any }) =>
        record.to_quarantine ? 'Quarantined' : 'Flagged',
    },
  ]

  return (
    <BaseLayout
      title="Collateral Data"
      filters={{
        left: <FilterCurrency hideSelect />,
        middle: <FilterCollateralData />,
      }}
      disableReset={disableReset}
      rightFooterContent={
        total && can_export ? <ExportCollateralL3 /> : undefined
      }
    >
      <Card>
        <CardBody>
          {error && (
            <Alert className="bg-danger-main text-center mb-6 p-2 text-sm">
              {(error as any).message}
            </Alert>
          )}
          <Table columns={columns as any} data={data} loading={isFetching} />
          <Pagination
            id="collateral-data-pagination"
            total={total ?? 0}
            page={page}
            onChangePage={p => setPage(p)}
            limit={limit}
            limits={Array(3)
              .fill('')
              .map((_, i) => LIMIT * (i + 1))}
            onChangeLimit={l => setLimit(l)}
          />
        </CardBody>
      </Card>
    </BaseLayout>
  )
}
export default CollateralL3
