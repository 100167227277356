import React, { useContext } from 'react'
import moment from 'moment'
import { useQueries, useQuery } from 'react-query'

import { Part } from '@components/table/type'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { CovenantListResponse, CovenantType } from '@interfaces/covenant'
import { CovenantService } from '@services/api-manage/covenant'

import CovenantL4 from './covenant'

export const getMultiplier = (covenant: CovenantListResponse, type: string) => {
  if (covenant?.multiplier) {
    return Number(covenant.multiplier)
  }
  const covenantName = covenant?.[`${type}_covenant`]?.toLowerCase()
  switch (covenantName) {
    case 'minimum cash balance':
    case 'minimum cash runway':
      return 1
    default:
      return 100
  }
}

export const getLabel = (covenant: CovenantListResponse, type: string) => {
  if (covenant?.label) {
    return covenant.label
  }
  const covenantName = covenant?.[`${type}_covenant`]?.toLowerCase()
  switch (covenantName) {
    case 'minimum cash balance':
      return 'USD'
    case 'minimum cash runway':
      return 'Month'
    default:
      return 'Percentage'
  }
}

export const getLabelFormat = (
  covenant: CovenantListResponse,
  type: string
) => {
  if (covenant?.format) {
    return covenant.format
  }
  const covenantName = covenant?.[`${type}_covenant`]?.toLowerCase()
  switch (covenantName) {
    case 'minimum cash balance':
      return '#.00a'
    case 'minimum cash runway':
      return '#.00a month'
    default:
      return '#.00a%'
  }
}

export const getUnit = (covenant: CovenantListResponse, type: string) => {
  if (covenant?.unit) {
    return ' ' + covenant.unit
  }
  const covenantName = (
    covenant?.[`${type}_covenant`] || covenant?.[`covenant_name`]
  )?.toLowerCase()

  switch (covenantName) {
    case 'minimum cash balance':
      return ' USD'
    case 'minimum cash runway':
      return 'm'
    default:
      return '%'
  }
}

export const useCovenantList = (type: CovenantType) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)

  const { activeDebtDeal = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]
  const facility = activeFacility?.facility
  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility,
    covenant_type: type,
  }

  const { data, isFetching } = useQuery(
    ['covenant-list', filters],
    () => CovenantService.getList(filters),
    {
      ...getStaleMins(),
      enabled:
        !!debtDealOptions?.[activeDebtDeal]?.facility &&
        !!(activeFacility?.slug_name ?? company?.slug_name),
    }
  )

  const tabs = (data ?? [])
    .map(d => {
      const covenants = [d]
      if (d[`${type}_covenant`].includes('Default Base')) {
        const maxFirstKey = d[`${type}_covenant`]
          ?.split(' Default Base')
          .join('')
          .trim()
        const maxFirst = data?.find(x => x[`${type}_covenant`] === maxFirstKey)
        if (maxFirst) {
          covenants.push(maxFirst)
        }
      }
      return {
        label: d[`${type}_covenant`],
        component: <CovenantL4 covenants={covenants} type={type} />,
        headerClassName: 'text-sm',
      }
    })
    .sort((a, b) => (a.label < b.label ? -1 : 1))

  return { data, isFetching, tabs, facility }
}

export const useCovenantDetail = (
  covenants: CovenantListResponse[],
  type: CovenantType
) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeDebtDeal = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]
  const conv = covenants?.[0]
  const isInverted = Number(conv?.invert_colors) === 1

  const covenantsQueries = useQueries(
    covenants.map(c => {
      const filters = {
        slug_name:
          c?.slug_name ?? activeFacility?.slug_name ?? company?.slug_name ?? '',
        covenant_name: c?.[`${type}_covenant`],
        facility: c.facility,
        covenant_type: type,
      }
      return {
        queryKey: ['covenant-detail-list', filters],
        queryFn: () => CovenantService.getDetail(filters),
        ...getStaleMins(),
      }
    })
  )

  let maxTrigger = 0
  let maxData = 0
  let minTrigger = 0
  let minData = 0
  const breachCounter: { [key: number]: number[] } = {}
  const isFetching = covenantsQueries.find(x => x.isLoading)
  const multiplier = getMultiplier(conv, type)
  const fetchedData = covenantsQueries.reduce(
    (prev: { [key: number]: any }, cur, i) => {
      const next: { [key: number]: any } = (cur?.data ?? []).reduce(
        (prevX, curX) => {
          const _x = moment(curX.cohort, 'YYYY-MM-DD').valueOf()
          const key = covenants[i]?.[`${type}_covenant`]
            .toLowerCase()
            .split(' ')
            .join('_')

          const exist = {
            ...prev[_x],
            x: _x,
            cohort: curX.cohort,
            [`${type}_covenant`]: key,
            [key]: curX.covenant_value_1 * multiplier,
            ...(i === 0
              ? Array(Number(covenants[i].triggers))
                  .fill(' ')
                  .reduce((pt, ct, it) => {
                    if (curX[`trigger_${it + 1}`] * multiplier > maxTrigger) {
                      maxTrigger = curX[`trigger_${it + 1}`] * multiplier
                    }
                    if (curX[`trigger_${it + 1}`] * multiplier < minTrigger) {
                      minTrigger = curX[`trigger_${it + 1}`] * multiplier
                    }
                    if (
                      (isInverted &&
                        curX.covenant_value_1 < curX[`trigger_${it + 1}`]) ||
                      (!isInverted &&
                        curX.covenant_value_1 > curX[`trigger_${it + 1}`])
                    ) {
                      breachCounter[it + 1] = [
                        ...(breachCounter[it + 1] ?? []),
                        _x,
                      ]
                    }
                    return {
                      ...pt,
                      [`${key}_trigger_${it + 1}`]:
                        curX[`trigger_${it + 1}`] * multiplier,
                    }
                  }, {})
              : {}),
          }

          if (curX.covenant_value_1 * multiplier > maxData) {
            maxData = curX.covenant_value_1 * multiplier
          }
          if (curX.covenant_value_1 * multiplier < minData) {
            minData = curX.covenant_value_1 * multiplier
          }

          return {
            ...prevX,
            [_x]: exist,
          }
        },
        {}
      )

      return {
        ...prev,
        ...next,
      }
    },
    {}
  )

  const chartData = Object.values(fetchedData).sort(
    (a, b) => (a as any).x - (b as any).x
  )

  return {
    chartData,
    breachCounter,
    isFetching,
    covenantsQueries,
    maxTrigger,
    maxData,
    minTrigger,
    minData,
  }
}

export const useVintageCovenantDetail = (
  covenants: CovenantListResponse[],
  type: CovenantType
) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeDebtDeal = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]
  const conv = covenants?.[0]

  const covenantsQueries = useQueries(
    covenants.map(c => {
      const filters = {
        slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
        covenant_name: c?.[`${type}_covenant`],
        facility: c.facility,
        covenant_type: type,
      }
      return {
        queryKey: ['covenant-detail-list', filters],
        queryFn: () => CovenantService.getDetail(filters),
        ...getStaleMins(),
      }
    })
  )

  const isFetching = covenantsQueries.find(x => x.isLoading)

  const unprep_columns = covenantsQueries.reduce((p: any[], c) => {
    const new_columns = (c.data ?? []).map(x => x.mob)
    return [...p, ...new_columns]
  }, [])
  const prepped_columns = unprep_columns
    .filter((c, i) => unprep_columns.indexOf(c) === i)
    .map(x => ({
      title: x,
      field: `value_${x}`,
      align: 'center',
      className: 'min-w-[100px]',
      render: (r: any, i: number, part: Part) => {
        return (part === 'head' && Number(r[`value_${x}`])) ||
          (part === 'body' && typeof r[`value_${x}`] !== 'undefined')
          ? Intl.NumberFormat(undefined, {
              style: 'percent',
              notation: 'compact',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(r[`value_${x}`] / 100)
          : ''
      },
      props: (r: any, i: number, part: Part) => {
        return {
          className: `${
            (part === 'head' && Number(r[`value_${x}`])) ||
            (part === 'body' && typeof r[`value_${x}`] !== 'undefined')
              ? part === 'body'
                ? r[`trigger_${x}`] === 1
                  ? '!bg-danger-surface !text-danger-main'
                  : '!bg-success-surface !text-success-main'
                : ''
              : '!bg-neutral-border-2'
          }`,
        }
      },
    }))
    .sort((a, b) => (a.title > b.title ? 1 : -1))

  const table_columns = [
    {
      title: 'Cohort',
      field: 'cohort',
      align: 'center',
      className: 'min-w-[100px] sticky left-0',
      render: (r: any, i: number, part: Part) => {
        return part === 'body'
          ? moment.utc(r.cohort).format('MMM-YY')
          : r.cohort
      },
    },
    ...prepped_columns,
  ]

  const table_data = covenantsQueries
    .reduce((p: any[], c) => {
      return [...p, ...(c.data ?? [])]
    }, [])
    .reduce((p, c) => {
      const key = `value_${c.mob}`
      const trigger_key = `trigger_${c.mob}`
      const existingIndex = p.findIndex((x: any) => x.cohort === c.cohort)
      if (existingIndex >= 0) {
        p[existingIndex] = {
          ...p[existingIndex],
          [key]: c.ratio * getMultiplier(conv, type),
          [trigger_key]: c.trigger,
        }
        return p
      } else {
        return [
          ...p,
          {
            cohort: c.cohort,
            [key]: c.ratio * getMultiplier(conv, type),
            [trigger_key]: c.trigger,
          },
        ]
      }
    }, [])
    .sort((a: any, b: any) => (a.cohort > b.cohort ? 1 : -1))

  const unpreped_data = covenantsQueries
    .reduce((p: any[], c) => {
      return [...p, ...(c.data ?? [])]
    }, [])
    .sort((a, b) => (a.cohort > b.cohort ? 1 : -1))

  const unprepped_triggers = unpreped_data
    .sort((a, b) => (a.mob > b.mob ? 1 : -1))
    .reduce((p, c) => {
      if (!Number(c.trigger_1)) {
        return p
      }
      const existingIndex = p.findIndex((x: any) => x.trigger_1 === c.trigger_1)
      if (existingIndex >= 0) {
        if (!p[existingIndex].mobs.includes(c.mob)) {
          p[existingIndex].mobs.push(c.mob)
        }
        return p
      } else {
        return [
          ...p,
          {
            mobs: [c.mob],
            ...Array(Number(conv?.triggers))
              .fill('')
              .reduce((_p, _, _i) => {
                return {
                  ..._p,
                  [`trigger_${_i + 1}`]: c[`trigger_${_i + 1}`],
                }
              }, {}),
          },
        ]
      }
    }, [])
    .sort((a: any, b: any) =>
      Number(Math.min(...a.mobs)) < Number(Math.min(...b.mobs)) ? -1 : 1
    )

  const triggers = unprepped_triggers.map((x: any) => ({
    ...x,
    mob: Math.max(...x.mobs),
  }))

  const table_header_data = Array(Number(conv?.triggers))
    .fill('')
    .map((_, i) => {
      return {
        cohort: `Trigger ${i + 1}`,
        ...triggers.reduce((p: any, c: any) => {
          const value_mob = c.mobs.reduce((_p: any, _c: any) => {
            return {
              ..._p,
              [`value_${_c}`]:
                c[`trigger_${i + 1}`] * getMultiplier(conv, type),
            }
          }, {})
          return { ...p, ...value_mob }
        }, {}),
      }
    })

  const chart_data: any[] = unpreped_data
    .reduce((p, c) => {
      const key = c.cohort
      const existingIndex = p.findIndex((x: any) => x.mob === c.mob)
      if (existingIndex >= 0) {
        p[existingIndex] = {
          ...p[existingIndex],
          [key]: c.ratio * getMultiplier(conv, type),
        }
        return p
      } else {
        return [
          ...p,
          {
            x: c.mob,
            mob: c.mob,
            [key]: c.ratio * getMultiplier(conv, type),
            ...triggers.reduce((_p: any, _c: any) => {
              return _c.mobs.includes(c.mob)
                ? {
                    ..._p,
                    ...Array(Number(conv?.triggers))
                      .fill('')
                      .reduce((__p, __, __i) => {
                        return {
                          ...__p,
                          [`trigger_${__i + 1}_${_c.mob}`]:
                            _c[`trigger_${__i + 1}`] *
                            getMultiplier(conv, type),
                        }
                      }, {}),
                  }
                : _p
            }, {}),
          },
        ]
      }
    }, [])
    .sort((a: any, b: any) => (a.mob > b.mob ? 1 : -1))

  const overview_columns = [
    {
      title: 'Trigger',
      field: 'trigger',
      align: 'center',
    },
    { title: 'Breach', field: 'breach', align: 'center' },
  ]

  const overview_data = triggers.reduce((px: any[], cx: any) => {
    const mob_trigger = Array(Number(conv?.triggers))
      .fill('')
      .map((_, _i) => {
        return {
          ...cx,
          trigger: `MoB ${cx.mobs?.[0]}${
            cx.mobs.length > 1 ? `-${cx.mobs?.[cx.mobs?.length - 1]}` : ``
          } Trigger ${_i + 1}`,
          breach: unpreped_data?.reduce((p, c) => {
            const trigger = Number(c.trigger)
            return cx.mobs.includes(c.mob) ? p + trigger : p
          }, 0),
        }
      })
    return [...px, ...mob_trigger]
  }, [])

  const default_series = {
    tooltipValueFormat: getLabelFormat(conv, type),
    type: 'SmoothedXLineSeries',
    hasBullet: false,
  }

  const chart_series: any[] = [
    ...table_data.map((x: any) => ({
      ...default_series,
      label: moment.utc(x.cohort).format('MMM-YY'),
      field: x.cohort,
      color: '#' + Math.floor(Math.random() * 16777215).toString(16),
    })),
    ...triggers.reduce((p: any[], c: any) => {
      const mob_triggers = Array(Number(conv?.triggers))
        .fill('')
        .map((_, _i) => {
          return {
            ...default_series,
            field: `trigger_${_i + 1}_${c.mob}`,
            label: `MoB ${c.mobs?.[0]}${
              c.mobs.length > 1 ? `-${c.mobs?.[c.mobs?.length - 1]}` : ``
            } Trigger ${_i + 1}`,
            color: '#' + Math.floor(Math.random() * 16777215).toString(16),
            hasBullet: true,
            bulletType: 'line',
          }
        })
      return [...p, ...mob_triggers]
    }, []),
  ]

  return {
    isFetching,
    covenantsQueries,
    table_columns,
    table_data,
    table_header_data,
    chart_series,
    chart_data,
    overview_columns,
    overview_data,
  }
}
