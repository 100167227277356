import React, { useContext, useEffect, useState } from 'react'

import FilterCurrency from '@components/filters/filter-currency'
import FilterDateRange from '@components/filters/filter-date-range'
import FilterFinancialDisplay from '@components/filters/filter-financial-display'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'

import BalanceSheetL4 from './balance-sheet'
import FinancialExports from './exports'
import ProfitAndLossL4 from './profit-and-loss'

const StatementsL3 = () => {
  const { filter_access } = useUserAccessFeature()
  const FEATURE = `analytics_financials_statements`
  const { activeFilters, appliedFilters, company, setAppliedFilters } =
    useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    range,
    financialEntity,
    financialEntityCurrency,
    currency,
    rate,
  } = activeFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (
      !filterReady &&
      dateStart &&
      dateEnd &&
      financialEntity &&
      range !== undefined &&
      rate
    ) {
      //carries filters over unless no previously applied filters
      JSON.stringify(appliedFilters) !== JSON.stringify({ activeFilters }) &&
        setAppliedFilters(activeFilters)

      // update activeFilters.currency with financialEntityCurrency if it exists
      if (financialEntityCurrency) {
        activeFilters.currency = financialEntityCurrency
      }
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    range === 0 && currency != company?.currencies_available?.[0]?.to_currency

  const tabs = [
    { label: 'Balance Sheet', component: <BalanceSheetL4 /> },
    { label: 'Profit & Loss', component: <ProfitAndLossL4 /> },
  ].filter(t =>
    filter_access(`${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`)
  )

  return (
    <L3TabsLayout
      title="Statements"
      filters={{
        left: <FilterCurrency />,
        middle: <FilterDateRange />,
        right: <FilterFinancialDisplay />,
      }}
      disableReset={disableReset}
      tabs={tabs}
      rightFooterContent={<FinancialExports />}
    />
  )
}
export default StatementsL3
