import React from 'react'

import Table from '@components/table'

import { REPORTING_MOCK } from './mock'

const ReportingL4 = () => {
  return (
    <div className="flex flex-col p-4">
      <Table
        columns={[
          { title: 'Document', field: 'document' },
          { title: 'Description', field: 'description' },
          { title: 'Target', field: 'target' },
          { title: 'Inputs', field: 'inputs' },
          { title: 'Frequency', field: 'frequency' },
          { title: 'Grace Period', field: 'grace_period' },
        ]}
        data={REPORTING_MOCK}
      />
    </div>
  )
}

export default ReportingL4
