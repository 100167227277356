import React, { useContext, useState } from 'react'
import moment from 'moment'

import AuthContext from '@contexts/auth'
import { CloudArrowDownIcon, TrashIcon } from '@heroicons/react/24/outline'
import { DeleteFileParams, DownloadFileParams } from '@interfaces/accounting'
import { Button } from '@material-tailwind/react'

import { SUCCESS_DELETE_FILE } from '../constants'
import AlertPopUp from '../pop-ups/alert-popup'
import { downloadFile } from '../service.runners.hooks'
import { useDeleteFile } from '../service.runners.hooks'

interface AccountListProps {
  data: Array<any>
}

const SheetsAccordionBody = ({ data }: AccountListProps) => {
  const [selectedItem, setSelectedItem] = useState<number | undefined>(
    undefined
  )
  const alertContent = 'Are you sure you want to delete this item?'
  const { company } = useContext(AuthContext)
  const { deleteFile, isSuccess } = useDeleteFile()

  const deleteFileHandler = async (data: DeleteFileParams) => {
    const params = {
      ...data,
      slug_name: company?.slug_name,
    }
    deleteFile(params)
    setSelectedItem(undefined)
  }

  const downloadStatement = async (params: DownloadFileParams) => {
    const url = await downloadFile(params)
    //triggers download with the url
    window.open(url, '_blank')
  }

  return (
    <>
      <div className="grid grid-cols-5 gap-4 w-full py-3 pl-10 font-medium text-sm text-neutral-subtitle-3 border-b-2  mx-9 w-11/12">
        <span>Statement Name</span>
        <span className="text-center">Date Upload</span>
      </div>
      {data
        .filter(
          //filter out all the null values
          item => item.uploadedDate !== null && item.file_name !== null
        )
        .map((statement: any) => (
          <>
            {!statement.uploadedDate && !statement.file_name ? (
              <div className="text-sm font-medium mt-4 ml-10 pb-2.5">
                No files uploaded
              </div>
            ) : (
              <div
                className="grid grid-cols-5 gap-4 w-full pl-10 p-5 border-b  md:text-sm font-light text-left relative"
                key={statement.id}
              >
                <span className="pl-10 text-black/[0.8]">
                  {statement.file_name.split('.xlsx').join('')}
                </span>
                <span className="text-center text-black/[0.8]">
                  {moment(statement.uploadedDate).format('YYYY-MM-DD')}{' '}
                </span>
                <div className="flex justify-end gap-8 item-end absolute right-10 top-0 bottom-0">
                  <CloudArrowDownIcon
                    className="stroke-blue w-6 hover:opacity-50 cursor-pointer"
                    onClick={() =>
                      downloadStatement({
                        bucketName: statement.bucket_name,
                        fileName: statement.file_name,
                        folderName: statement.folder_name,
                        slug_name: company?.slug_name,
                      })
                    }
                  />
                  <TrashIcon
                    className="stroke-red w-6 hover:opacity-50 cursor-pointer"
                    onClick={() => {
                      setSelectedItem(statement.id)
                    }}
                  />
                </div>

                {selectedItem === statement.id && (
                  <AlertPopUp
                    message={alertContent}
                    handler={() => setSelectedItem(undefined)}
                    open={selectedItem === statement.id}
                    header={`${
                      isSuccess ? SUCCESS_DELETE_FILE : 'Delete Statement'
                    }`}
                    isSuccess={isSuccess}
                  >
                    <Button
                      className="rounded-lg flex-1"
                      disabled={isSuccess ? true : false}
                      onClick={() => setSelectedItem(undefined)}
                    >
                      <span>Cancel</span>
                    </Button>

                    <Button
                      variant="filled"
                      className="rounded-lg flex-1 bg-[#ff3b31] :hover[#ff3b31]/[.55] text-white"
                      disabled={isSuccess ? true : false}
                      onClick={() =>
                        deleteFileHandler({
                          sheetId: statement.id,
                          bucketName: statement.bucket_name,
                          fileName: statement.file_name,
                          folderName: statement.folder_name,
                        })
                      }
                    >
                      <span>Confirm</span>
                    </Button>
                  </AlertPopUp>
                )}
              </div>
            )}
            <div></div>
          </>
        ))}
    </>
  )
}
export default SheetsAccordionBody
