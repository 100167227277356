import React, { useContext, useEffect, useState } from 'react'

import FilterCurrency from '@components/filters/filter-currency'
import FilterDateRange from '@components/filters/filter-date-range'
import FilterTypeKey from '@components/filters/filter-type-key'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'

import InterestRateL4 from './interest-rate'
import TermL4 from './term'
import ValueL4 from './value'

const CharacteristicL3 = () => {
  const { filter_access } = useUserAccessFeature()
  const FEATURE = `analytics_risk_characteristics`
  const { activeFilters, appliedFilters, company, setAppliedFilters } =
    useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes = [],
    activeType,
    range,
    currency,
    rate,
  } = activeFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (
      !filterReady &&
      dateStartCohort &&
      dateEndCohort &&
      categoryTypes.length > 0 &&
      rate
    ) {
      //carries filters over unless no previously applied filters
      JSON.stringify(appliedFilters) !== JSON.stringify({ activeFilters }) &&
        setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    range === 0 &&
    activeType === 'All' &&
    currency === company?.currencies_available?.[0]?.to_currency

  const tabs = [
    { label: 'Interest Rate', component: <InterestRateL4 /> },
    { label: 'Term', component: <TermL4 /> },
    { label: 'Value', component: <ValueL4 /> },
  ].filter(t =>
    filter_access(`${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`)
  )

  return (
    <L3TabsLayout
      title="Characteristics"
      filters={{
        left: <FilterCurrency />,
        middle: <FilterDateRange isCohort={true} />,
        right: <FilterTypeKey />,
      }}
      disableReset={disableReset}
      tabs={tabs}
    />
  )
}
export default CharacteristicL3
