import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import BorrowerConcentrationL5 from './borrower-concentration'

const BorrowerConcentrationL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const tabs = [
    {
      label: 'Borrower Concentration',
      desc: {
        description:
          'The outstanding balance of a borrower / client as a percentage of the total outstanding portfolio.',
        formula:
          'For each individual borrower / client: outstanding balance of that borrower / total outstanding balance.',
      },
      component: <BorrowerConcentrationL5 exportable={exportable} />,
    },
  ]

  return (
    <L4TabsLayout
      tabs={tabs}
      additionalIndicator={
        showHistoricalRateIndicator ? <ExchangeRateBadge /> : undefined
      }
    />
  )
}

export default BorrowerConcentrationL4
