import React from 'react'

import Table from '@components/table'

import {
  AGENTS_MOCK,
  BORROWERS_MOCK,
  LENDERS_MOCK,
  SERVICE_PROVIDERS_MOCK,
} from './mock'

const PartiesL4 = () => {
  return (
    <div className="flex flex-col p-4">
      <Table
        title="Lender Details"
        columns={[
          { title: 'Legal Name', field: 'legal_name' },
          { title: 'Nickname', field: 'nickname' },
          { title: 'Class', field: 'class' },
          { title: 'Commitment', field: 'commitment' },
          { title: 'Cascade Name', field: 'cascade_name' },
        ]}
        data={LENDERS_MOCK}
      />
      <Table
        title="Borrower Details"
        columns={[
          { title: 'Roles', field: 'roles' },
          { title: 'Legal Name', field: 'legal_name' },
          { title: 'Nickname', field: 'nickname' },
          { title: 'Country', field: 'country' },
        ]}
        data={BORROWERS_MOCK}
      />
      <Table
        title="Agent Details"
        columns={[
          { title: 'Legal Name', field: 'legal_name' },
          { title: 'Nickname', field: 'nickname' },
          { title: 'Title', field: 'title' },
        ]}
        data={AGENTS_MOCK}
      />
      <Table
        title="Service Providers Details"
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Roles', field: 'roles' },
          { title: 'Access to Cascade', field: 'cascade_access' },
        ]}
        data={SERVICE_PROVIDERS_MOCK}
      />
    </div>
  )
}

export default PartiesL4
