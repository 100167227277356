import apiClient from './client'

class LoantapeService {
  static async downloadLoantape(
    is_abbreviated: boolean,
    date_start: string,
    date_end: string,
    notif_email?: string,
    slug_name?: string
  ) {
    return apiClient.post(`loantape/download`, {
      slug_name,
      notif_email,
      is_abbreviated,
      date_start,
      date_end,
    })
  }

  static async getLoantableSize(slug_name?: string) {
    return apiClient.post(`loantape/get-size`, {
      slug_name,
    })
  }

  static async getLoantapePresignedUrl(slugName: string, hierarchy1: string) {
    return apiClient.post(`loantape/download-url`, {
      slug_name: slugName,
      hierarchy1: hierarchy1,
    })
  }
}

export default LoantapeService
