import {
  DashboardRequest,
  DashboardResponse,
} from '@interfaces/manage-monitor-dashboard'

import manageApiClient from './client'

export class DashboardService {
  static async getComponent(params: DashboardRequest) {
    const { data } = await manageApiClient.post<DashboardResponse[]>(
      `/dashboard`,
      params
    )
    return data
  }
}
