export enum Role {
  superadmin = 'superadmin',
  admin = 'admin',
  staff = 'staff',
  custom = 'custom',
}

export const Controls = [
  {
    value: Role.admin,
    description:
      'Can see all pages, have ability to upload and edit pages/documents (full access)',
  },
  {
    value: Role.staff,
    description:
      'Have limited access to certain pages based on permission, cannot edit or upload documents',
  },
  {
    value: Role.custom,
    description: 'Have customizable access to every page',
  },
]
