import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { Facility } from '@interfaces/facility'
import { CovenantService } from '@services/api-manage/covenant'

import CovenantL4 from '../covenant/covenant'

interface ManageOverviewL3CovenantProps {
  type: 'portfolio' | 'financial'
  facility?: Facility
}

const ManageOverviewL3Covenant = ({
  type,
  facility,
}: ManageOverviewL3CovenantProps) => {
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)

  const { activeDebtDeal } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const filters = {
    slug_name:
      facility?.slug_name ??
      activeFacility?.slug_name ??
      company?.slug_name ??
      '',
    facility: facility?.facility ?? activeFacility?.facility,
    covenant_type: type,
  }

  const { data, isFetching } = useQuery(
    ['covenant-list', filters],
    () => CovenantService.getList(filters),
    {
      ...getStaleMins(),
      enabled:
        !!debtDealOptions?.[activeDebtDeal]?.facility && !!company?.slug_name,
    }
  )

  const list = (data ?? [])
    .map(d => {
      const covenants = [d]
      if (d[`${type}_covenant`].includes('Default Base')) {
        const maxFirstKey = d[`${type}_covenant`]
          ?.split(' Default Base')
          .join('')
          .trim()
        const maxFirst = data?.find(x => x[`${type}_covenant`] === maxFirstKey)
        if (maxFirst) {
          covenants.push(maxFirst)
        }
      }
      return {
        label: d[`${type}_covenant`],
        covenants,
      }
    })
    .sort((a, b) => (a.label < b.label ? -1 : 1))

  return (
    <div className="flex flex-col">
      <Typography className="text-xl font-medium text-center capitalize">
        {`${type} covenants`}
      </Typography>
      <div
        className={`gap-6 mt-6 grid ${
          isFetching || list.length === 0
            ? 'grid-cols-1'
            : 'lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4'
        }`}
      >
        {isFetching ? (
          <div className="flex justify-center">
            <ArrowPathIcon className="animate-spin text-primary-main w-6" />
          </div>
        ) : (
          <>
            {list.length === 0 && (
              <Typography className="text-center">
                No {type} covenant found for {company?.legal_name}
              </Typography>
            )}
            {list.map(l => (
              <CovenantL4
                key={l.label}
                covenants={l.covenants}
                type={type}
                hideChart
                link={
                  facility
                    ? `/manage/monitor/${type}-covenants?pid=${company?.slug_name}&facility=${facility.facility}&`
                    : undefined
                }
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default ManageOverviewL3Covenant
