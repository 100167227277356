import React, { useContext, useEffect, useState } from 'react'
import HelloSign from 'hellosign-embedded'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import Pagination from '@components/pagination'
import Table from '@components/table'
import { SortOrder } from '@components/table/type'
import { DROPBOX_SIGN_CLIENT_ID } from '@constants/config'
import AuthContext from '@contexts/auth'
import { pdfDownloader } from '@helpers/file-downloader'
import { getStaleMins } from '@helpers/stale-timer'
import {
  ArrowPathIcon,
  Bars3CenterLeftIcon,
  CloudArrowUpIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import {
  CategoriesResult,
  ListCategoriesFilters,
} from '@interfaces/manage-reporting'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from '@material-tailwind/react'
import SignatureHistoryDialog from '@pages/manage/monitor/actions/document-centre/dialogs/audit-dialog'
import DocumentService from '@services/api-manage/docs'
import { ActionService } from '@services/api-manage/monitor-action'
import SignatureService from '@services/api-manage/signature'

import ARStatusLabel from '../advance-request/status-label'

import NotesDialog from './dialogs/notes-dialog'
import UploadDocumentDialog from './dialogs/upload-dialog'
import DocumentMenu from './reporting/document-action'
import GenerateDocProvider from './reporting/generate-doc-provider'
import StatusLabel from './reporting/status-label'

interface UploadRedirects {
  'Advance Request': string
  'Capital Advance': string
}

const DocumentCentre = ({
  can_sign,
  can_create,
}: {
  can_sign: boolean
  can_create: boolean
}) => {
  const { appliedFilters, company, optionFilters, setLoadingData } =
    useContext(AuthContext)
  const { debtDealOptions } = optionFilters
  const [generateDocType, setGenerateDocType] = useState({
    category: '',
    subcategory: '',
    facility: '',
    action: 'create' as CategoriesResult['action'],
  })
  const [uploadDocType, setUploadDocType] = useState({
    category: '',
    subcategory: '',
    facility: '',
    action: 'upload' as CategoriesResult['action'],
  })
  const [activeDocData, setActiveDocData] = useState<any>('')
  const [isNotesOpen, setIsNotesOpen] = useState<boolean>(false)

  const [action, setAction] = useState('')
  const { user } = useAuth0()
  const [page, setPage] = useState<number>(0)
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc')
  const [sortField, setSortField] = useState<string>('status')

  const PER_PAGE = 15
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  // Upload redirect logic
  const UPLOAD_REDIRECTS: UploadRedirects = {
    'Advance Request': `/manage/monitor/actions?tab=advance-request&dCAction=upload&pid=${company?.slug_name}`,
    'Capital Advance': `/manage/monitor/actions?tab=advance-request&dCAction=upload&pid=${company?.slug_name}`,
  }
  const redirectUser = (path: string) => {
    setLoadingData([
      {
        state: 'loading',
        text: `Redirecting...`,
      },
    ])

    setTimeout(() => {
      setLoadingData({})
      navigate(path)
    }, 1000)
  }
  useEffect(() => {
    if (UPLOAD_REDIRECTS[uploadDocType?.category as keyof UploadRedirects]) {
      redirectUser(
        UPLOAD_REDIRECTS[uploadDocType.category as keyof UploadRedirects]
      )
    }
  }, [uploadDocType])

  const {
    dateStart,
    dateEnd,
    activeDebtDeal,
    docCategory,
    docKeyword,
    docStatus,
  } = appliedFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const filters = {
    date_start: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_end: moment.utc(dateEnd).format('YYYY-MM-DD'),
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility ?? '',
    page: page + 1,
    per_page: perPage,
    search_keyword: docKeyword,
    order_by: sortField,
    sort_order: sortOrder,
    category:
      docCategory && docCategory.category != 'All'
        ? docCategory.category
        : docCategory?.subcategory ?? '',
    status: docStatus?.status ?? '',
  }

  const { data, isFetching, refetch } = useQuery(
    ['reporting-docs', filters],
    () => DocumentService.getOrderedReporting(filters),
    getStaleMins()
  )

  const {
    data: advanceRequestData,
    isFetching: isFetchingAdvanceRequest,
    refetch: refetchAdvanceRequests,
  } = useQuery(
    [
      'advance_request',
      { slug_name: filters.slug_name, facility: filters.facility },
    ],
    () =>
      ActionService.getAdvanceRequest({
        slug_name: filters.slug_name,
        facility: filters.facility,
        page: 1,
        per_page: 100,
      }),
    { ...getStaleMins(), enabled: !!activeFacility }
  )
  const { data: advanceRequests = [] } = advanceRequestData ?? {}

  const categoriesParams: ListCategoriesFilters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility ?? '',
  }
  const { data: categoriesResult = [], isFetching: isFetchingCategories } =
    useQuery(
      ['reporting-categories', categoriesParams],
      () => DocumentService.listCategories(categoriesParams),
      {
        ...getStaleMins(),
        enabled: !!activeFacility?.facility,
      }
    )
  const createCategories = categoriesResult.filter(
    cat => cat.action === 'create'
  )
  const uploadCategories = categoriesResult.filter(
    cat => cat.action === 'upload'
  )

  const { mutate: sign } = useMutation(
    (signees: any) => {
      const signee = JSON.parse(signees).find(
        (item: { signer_email_address: string | undefined }) =>
          item.signer_email_address === user?.email
      )
      setLoadingData([
        { state: 'loading', text: 'Preparing document for e-Signature' },
      ])
      return SignatureService.getEmbeddedSignatureURL(signee.signature_id)
    },
    {
      onSuccess: res => {
        setLoadingData([
          { state: 'done', text: 'Preparing document for e-Signature' },
        ])
        setTimeout(() => {
          setLoadingData([])
          const client = new HelloSign()
          client.open(res.data?.signUrl, {
            clientId: DROPBOX_SIGN_CLIENT_ID,
            skipDomainVerification: true,
          })
        }, 800)
      },
    }
  )

  const { mutate: downloadFile } = useMutation(
    async (docData: any) => {
      toast.loading('Preparing Your Download...')
      if (docData.dropbox_api_supp != 's3 upload') {
        return SignatureService.getdataUri(docData.id, company?.slug_name)
      } else {
        return DocumentService.getPresignedUrl(
          JSON.parse(docData.supplementary_data),
          activeFacility?.slug_name ?? company?.slug_name
        )
      }
    },
    {
      onSuccess: (res, docData) => {
        if (docData.dropbox_api_supp != 's3 upload') {
          toast.dismiss()
          toast.success('Your Download Should begin automatically', {
            autoClose: 5000,
            hideProgressBar: true,
          })
          pdfDownloader(res.data.data_uri, docData.name)
        } else {
          toast.dismiss()
          window.open(String(res.data), '_blank')
        }
      },
      onError: () => {
        toast.dismiss()
        toast.error('An error occurred while downloading')
      },
    }
  )

  const { mutate: voidDoc } = useMutation(
    () => {
      setAction('')
      toast.loading('Request in Progress')
      return DocumentService.voidDoc(
        activeDocData.id,
        activeFacility?.slug_name ?? company?.slug_name
      )
    },
    {
      onSuccess: () => {
        setActiveDocData('')
        toast.dismiss()
        toast.success('Document voided', {
          autoClose: 5000,
          hideProgressBar: true,
        })
        refetch()
      },
      onError: () => {
        toast.dismiss()
        toast.error('An error occurred')
      },
    }
  )

  const { mutate: resendSignatureRequest } = useMutation(
    (doc: any) => {
      toast.loading('Request in progress')
      return SignatureService.resendSignatureRequest({
        id: doc.id,
        slug_name: company?.slug_name || '',
      })
    },
    {
      onSuccess: () => {
        toast.dismiss()
        toast.success('Signature request resent', {
          autoClose: 5000,
          hideProgressBar: true,
        })
      },
      onError: (error: any) => {
        toast.dismiss()
        toast.error(error.message || 'Unable to send to all signees')
      },
    }
  )

  // Handle external download links (from email or documents)
  useEffect(() => {
    const decodedFilename = searchParams.get('fileName')
    if (company?.slug_name && decodedFilename) {
      const params = {
        dropbox_api_supp: 's3 upload',
        supplementary_data: JSON.stringify({
          Bucket: `${company.slug_name}-cascade`,
          Key: `/Manage/Document Centre/${decodedFilename}`,
        }),
      }
      downloadFile(params)
    }
  }, [])

  useEffect(() => {
    setPage(0)
  }, [docKeyword, docCategory, docStatus])

  const handleCompleted = (doc: any, action: string) => {
    setAction(action)
    setActiveDocData(doc)
  }
  const notesHandler = (doc: any) => {
    setActiveDocData(doc)
    setIsNotesOpen(!isNotesOpen)
  }
  const uploadHandler = (doc: any) => {
    const category = uploadCategories.find(cat => cat.category === doc.category)
    if (!!category?.category) {
      setActiveDocData(doc)
      setUploadDocType(category)
    }
  }

  const signHandler = (doc: any) => {
    setActiveDocData(doc)
    // custom logic for capital advance request sign handling
    if (
      doc.category == 'Capital Advance' ||
      doc.category == 'Advance Request'
    ) {
      navigate(
        `/manage/monitor/actions?tab=advance-request&dCAction=sign&dcId=${doc.id}&pid=${company?.slug_name}`
      )
      return
    }
    sign(doc.signees)
  }

  const placeholderGenerateHandler = (doc: any, category: CategoriesResult) => {
    setActiveDocData(doc)
    setGenerateDocType(category)
  }

  useEffect(() => {
    setActiveDocData('')
  }, [data])

  const isLoading =
    isFetching || isFetchingCategories || isFetchingAdvanceRequest

  return (
    <div className="flex flex-col p-4">
      <div className="flex justify-end items-center gap-4 mb-6">
        {can_create && (
          <>
            {!!createCategories.length && (
              <Menu placement="bottom">
                <MenuHandler>
                  <button className="flex justify-center items-center w-28 py-2 -mt-2 text-sm font-medium cursor-pointer rounded-lg bg-primary-main hover:bg-primary-hover text-white transition ease-in-out delay-50 duration-300">
                    <PlusIcon className="w-4 h-4 mr-2 -ml-2" />
                    Create
                  </button>
                </MenuHandler>
                <MenuList className="max-h-[49vh] overflow-auto">
                  {createCategories.map(
                    (category: CategoriesResult, index: number) => (
                      <MenuItem
                        key={index}
                        className="text-primary flex hover:bg-primary-surface-2"
                        onClick={() => setGenerateDocType(category)}
                      >
                        {category.category}
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </Menu>
            )}
            <Menu placement="bottom">
              <MenuHandler>
                <button className="flex justify-center items-center w-28 py-2 -mt-2 text-sm font-medium cursor-pointer rounded-lg bg-primary-main hover:bg-primary-hover text-white transition ease-in-out delay-50 duration-300">
                  <CloudArrowUpIcon className="w-4 h-4 mr-2" />
                  Upload
                </button>
              </MenuHandler>
              <MenuList className="max-h-[49vh] overflow-auto">
                {uploadCategories.map(
                  (category: CategoriesResult, index: number) => (
                    <MenuItem
                      key={index}
                      className="text-primary flex hover:bg-primary-surface-2"
                      onClick={() => setUploadDocType(category)}
                    >
                      {category.category}
                    </MenuItem>
                  )
                )}
              </MenuList>
            </Menu>
          </>
        )}
        {/* <FormInput
          value={keyword}
          {...{
            placeholder: 'Search for a document..',
            onChange: (e: any) => {
              setKeyword(e.target.value)
            },
          }}
        /> */}
        <Button
          color="default"
          className="mb-2 "
          onClick={() => {
            refetchAdvanceRequests()
            refetch()
          }}
        >
          <ArrowPathIcon
            className={`${isLoading ? 'animate-spin' : ''} w-4 h-4 `}
          />
        </Button>
      </div>
      {/*commented until design reviewed*}
           {/*<DocumentSection
            title="OVERDUE"
            documents={data.overdue}
            iconSize="24"
            handler={dueDoc => setGenerateDocType(dueDoc.category)}
          />
          <DocumentSection
            title="Awaiting Your Signature"
            documents={data.awaiting_signature}
            iconSize="24"
            handler={dueDoc => sign(dueDoc.signees)}
          />
          <DocumentSection
            title="Due now"
            documents={data.due}
            iconSize="24"
            handler={dueDoc => setGenerateDocType(dueDoc.category)}
          />
          <DocumentSection
            title="Upcoming"
            documents={data.upcoming}
            iconSize="24"
            handler={dueDoc => setGenerateDocType(dueDoc.category)}
          /> 
           <span className="text-xl font-bold pb-5 mt-5">Document Archive</span> */}
      <Table
        loading={isLoading}
        columns={[
          { title: 'Document', field: 'name', sortable: true },
          { title: 'Document Type', field: 'category', sortable: true },
          {
            title: 'Event Date',
            field: 'effective_date',
            sortable: true,
            render: i => moment.utc(i.effective_date).format('DD-MM-YYYY'),
          },
          {
            title: 'Status',
            field: 'status',
            width: '200px',
            sortable: true,
            render: doc => {
              const ar =
                doc.category === 'Advance Request'
                  ? advanceRequests.find(
                      (ar: any) => ar.signature_request?.id === doc.id
                    )
                  : null
              return ar ? (
                <ARStatusLabel request={ar} />
              ) : (
                <StatusLabel
                  statusText={doc.status}
                  effectiveDate={doc.effective_date}
                  isComplete={
                    doc.dropbox_api_supp && doc.dropbox_api_supp != 's3 upload'
                      ? JSON.parse(doc.dropbox_api_supp).is_complete
                      : true
                  }
                />
              )
            },
          },
          {
            title: 'Notes',
            field: 'notes',
            width: '50px',
            render: (record: any) => {
              return (
                <div
                  className="flex justify-center rounded-lg hover:border hover:border-primary-border hover:text-primary-hover"
                  onClick={() => {
                    setActiveDocData(record)
                    setIsNotesOpen(!isNotesOpen)
                  }}
                >
                  <Bars3CenterLeftIcon className="w-6 opacity-60 " />
                </div>
              )
            },
          },
          {
            title: 'Actions',
            field: 'id',
            width: '50px',
            render: doc => {
              return can_sign ? (
                <DocumentMenu
                  doc={doc}
                  setGenerateDocType={placeholderGenerateHandler}
                  sign={signHandler}
                  handleCompleted={handleCompleted}
                  downloadFile={downloadFile}
                  handleUpload={uploadHandler}
                  handleNotes={notesHandler}
                  handleResendSignRequest={resendSignatureRequest}
                  createCategories={createCategories}
                  can_create={can_create}
                  can_sign={can_sign}
                />
              ) : (
                <></>
              )
            },
          },
        ]}
        data={data}
        onSort={(field: string, order: SortOrder) => {
          setSortField(order != '' ? field : 'status')
          setSortOrder(order != '' ? order : 'asc')
        }}
        sortField={sortField}
        sortOrder={sortOrder}
      />
      {data?.[0]?.total > perPage && (
        <Pagination
          id="user-table-pagination"
          total={data?.[0]?.total}
          page={page}
          onChangePage={p => setPage(p)}
          limit={perPage}
          limits={Array(2)
            .fill('')
            .map((_, i) => PER_PAGE * (i + 1))}
          onChangeLimit={l => setPerPage(l)}
        />
      )}
      {generateDocType.category != '' && (
        <GenerateDocProvider
          category={generateDocType}
          handler={() =>
            setGenerateDocType({
              category: '',
              subcategory: '',
              facility: '',
              action: 'create' as CategoriesResult['action'],
            })
          }
          refetchHandler={() => refetch()}
          docData={activeDocData}
          can_sign={can_sign}
        />
      )}
      {action === 'view' && (
        <SignatureHistoryDialog
          docData={activeDocData}
          open={action === 'view' && activeDocData !== ''}
          handler={() => {
            setAction('')
            setActiveDocData('')
            refetch()
          }}
        />
      )}
      <UploadDocumentDialog
        docData={activeDocData}
        open={
          !!uploadDocType.category &&
          !UPLOAD_REDIRECTS[uploadDocType?.category as keyof UploadRedirects]
        }
        category={uploadDocType}
        uploadCategories={uploadCategories}
        handler={() => {
          setUploadDocType({
            category: '',
            subcategory: '',
            facility: '',
            action: 'upload' as CategoriesResult['action'],
          })
          setActiveDocData('')
          refetch()
        }}
      />
      <NotesDialog
        open={isNotesOpen}
        docData={activeDocData}
        handler={() => {
          setIsNotesOpen(!isNotesOpen)
          setActiveDocData('')
          refetch()
        }}
      />
      <Dialog
        open={action === 'void'}
        handler={() => setAction('')}
        className="overflow-auto z-40"
      >
        <DialogHeader>
          <div className="flex justify-between w-full">
            <span>Are you sure?</span>
          </div>
        </DialogHeader>
        <DialogBody divider>
          <div className="flex flex-col gap-6 w-full mt-5">
            <p>
              You are about to void {activeDocData.name}. This action is
              irreversible!
            </p>
            <p> Do you want to continue?</p>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            className="rounded-lg flex-1 max-w-[100px]"
            color="primary"
            onClick={() => setAction('')}
          >
            No
          </Button>
          <Button
            className="rounded-lg flex-1 bg-danger-main hover:bg-danger-hover text-white max-w-[100px] ml-10"
            onClick={() => voidDoc()}
          >
            Yes
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  )
}

export default DocumentCentre
