import React, { ReactNode } from 'react'

interface StepProps {
  children: ReactNode
  onClick: (index: number) => void
  isActive: boolean
  title: string
}

const Step = ({ children }: StepProps) => {
  return <div className="w-full h-full">{children}</div>
}

export default Step
