import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import * as XLSX from 'xlsx'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import {
  ArrowPathIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { CovenantType } from '@interfaces/covenant'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { CovenantService } from '@services/api-manage/covenant'

import { useCovenantList } from './helper'

const ExportDialogComponent = ({
  type,
  list,
}: {
  type: CovenantType
  list: { label: string }[]
}) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeDebtDeal } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const query = (c?: { label: string }) => {
    const filters = {
      slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
      covenant_name: c?.label ?? '',
      facility: activeFacility?.facility,
      covenant_type: type,
    }
    return useMutation({
      mutationKey: ['covenant-raw', filters, c?.label],
      mutationFn: () =>
        c
          ? CovenantService.getRawData(filters)
          : new Promise(resolve => resolve([])),
    })
  }

  /**
   * this is to prevent different number of hook mapping
   * by fix array number
   */
  const prelist = [...new Array(100)].map((_, i) => list[i])

  const parts = prelist.map(l => ({ ...l, ...query(l) }))

  const currentPart: any = parts.find((p: any) => p.isIdle)

  const prepareExport = () => {
    if (open && currentPart) {
      currentPart?.mutate()
    }
  }

  useEffect(() => {
    if (open) {
      prepareExport()
    } else {
      setIsDownloading(false)
    }
  }, [open, currentPart])

  const isDownloadReady = !currentPart
  const _download = async () => {
    setIsDownloading(true)

    const workbook = XLSX.utils.book_new()

    parts.forEach(p => {
      const content = ((p.data as any[]) ?? []).map((d: any) => {
        return Object.keys(d).reduce((prev, cur) => {
          return {
            ...prev,
            [cur.toUpperCase().split('_').join(' ')]: d[cur],
          }
        }, {})
      })

      if ((content ?? []).length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(content ?? [])
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          p.label.length > 31
            ? p.label
                .split(' ')
                .map(x => x.charAt(0))
                .join('')
            : p.label
        )
      }
    })

    await XLSX.writeFile(
      workbook,
      `${company?.legal_name} ${type} Covenants.xlsx`
    )

    setIsDownloading(false)
  }

  return (
    <>
      <Button
        className="mr-0.25!shadow-none border border-primary-main text-primary-main py-2 px-6 hover:bg-primary-main hover:text-neutral-white"
        onClick={handleOpen}
      >
        <Typography className="capitalize font-medium text-sm">
          Export
        </Typography>
      </Button>
      <Dialog open={open} handler={() => undefined}>
        <DialogHeader className="justify-between">
          <Typography className="capitalize font-medium text-xl">
            {`Export ${type} Covenants`}
          </Typography>
          <XMarkIcon
            onClick={handleOpen}
            className="w-6 h-6 cursor-pointer hover:opacity-50"
          />
        </DialogHeader>
        <DialogBody divider className="flex flex-col p-6">
          <Typography className="text-sm text-center font-bold">
            Please DO NOT close this dialog or the browser tab until exporting
            is complete
          </Typography>
          <div className="mt-8 flex flex-col gap-4 max-h-[50vh] overflow-y-auto">
            {parts
              .filter(x => x.label)
              .map((p: any) => {
                return (
                  <div
                    key={p.label}
                    className="rounded-lg bg-neutral-border-1 px-4 py-2 flex gap-4 justify-between items-center"
                  >
                    <Typography className="capitalize text-sm flex-1">
                      {p.label}
                    </Typography>
                    {p.isLoading && (
                      <ArrowPathIcon className="animate-spin text-primary-main w-6 h-6" />
                    )}
                    {!p.isLoading && p.isSuccess && (
                      <CheckCircleIcon className="text-primary-main w-6 h-6" />
                    )}
                    {!p.isLoading && !p.isSuccess && (
                      <ExclamationTriangleIcon className="text-primary-main w-6 h-6" />
                    )}
                  </div>
                )
              })}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={_download}
            disabled={!isDownloadReady || isDownloading}
            className="!shadow-none border py-2 px-6 border-primary-main text-primary-main hover:bg-primary-main hover:text-neutral-white disabled:border-neutral-border-3 disabled:bg-neutral-border-2 disabled:text-neutral-border-3"
          >
            {isDownloading && (
              <ArrowPathIcon className="w-4 h-4 mr-4 text-primary-main animate-spin" />
            )}
            Download
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

const ExportDialog = ({ type }: { type: CovenantType }) => {
  const { isFetching, tabs } = useCovenantList(type)

  const [t, setT] = useState<CovenantType | undefined>()

  useEffect(() => {
    setT(undefined)
    setTimeout(() => {
      setT(type)
    }, 1000)
  }, [type])

  return isFetching || tabs.length === 0 || t !== type ? (
    <></>
  ) : (
    <ExportDialogComponent type={t} list={t ? tabs : []} />
  )
}

export default ExportDialog
