export const BORROWING_BASE_MOCK = [
  {
    advance_rate: 'Sum of:',
    multiplied_by: 'Cash 1',
    divided_by_fx_rate: 'N',
    included: 'Y',
  },
  {
    multiplied_by: 'Cash 2',
    divided_by_fx_rate: 'Y',
    included: 'Y',
  },
  {
    multiplied_by: 'Eligible Pool Balance 1',
    divided_by_fx_rate: 'Y',
    included: 'Y',
  },
  {
    multiplied_by: 'Eligible Pool Balance 2',
    divided_by_fx_rate: 'Y',
    included: 'Y',
  },
  {
    advance_rate: 'Minus',
    multiplied_by: 'Excess Concentration Amount',
    divided_by_fx_rate: 'N',
    included: 'N',
  },
  {
    multiplied_by: 'Accrued Lender`s Interest',
    divided_by_fx_rate: 'N',
    included: 'N',
  },
  {
    multiplied_by: 'Minimum Borrowing Base Account Balance',
    divided_by_fx_rate: 'N',
    included: 'N',
  },
  {
    multiplied_by: 'Borrowing Base Interest Reserve Requirement',
    divided_by_fx_rate: 'Y',
    included: 'Y',
  },
]

const columns = [
  {
    title: 'Defined Term',
    field: 'defined_term',
    width: '200px',
  },
  {
    title: 'Definition',
    field: 'definition',
  },
  {
    title: 'Facility Variable',
    field: 'facility_variable',
    width: '200px',
  },
]

export const BORROWING_BASE_DETAILS_MOCK = [
  {
    title: 'Key Information',
    columns,
    data: [
      {
        defined_term: 'Calculation Date',
        definition:
          'means [•].  If the Calculation Date is a non-business day, the calculation will occur on the next business day.',
        facility_variable: 'Every Day',
      },
      {
        defined_term: 'Borrowing Base Deficiency',
        definition:
          'means when the Borrowing Base is less than the Lender’s Principal Loan Value.',
      },
      {
        defined_term: 'Borrowing Base Deficiency Days',
        definition:
          'means the number of days a Borrowing Base Deficiency has existed since it was last cured.',
      },
      {
        defined_term: 'Borrowing Base Deficiency Days Triggers',
        definition: {
          content: 'means',
          table: {
            columns: [
              {
                title: 'Trigger',
                field: 'trigger',
              },
              {
                title: 'Dynamic Advance Rate Table',
                field: 'dynamic_advance_rate_table',
              },
            ],
            data: [
              {
                trigger: 'Trigger 1',
                dynamic_advance_rate_table: '15',
              },
              {
                trigger: 'Trigger 2',
              },
              {
                trigger: 'Trigger 3',
              },
            ],
          },
        },
      },
    ],
  },
  {
    title: 'Dynamic Advance Rate Table',
    columns,
    data: [
      {
        defined_term: 'Dynamic Advance Rate',
        definition:
          'means the Advance Rate shall be set based on the Advance Rate Table, which shall be calculated as of [•]',
        facility_variable: '1st of each month',
      },
      {
        defined_term: 'Advance Rate Table',
        definition: {
          content:
            'means the table below which shall be calculated based on [•] covenant(s).  If any trigger is breached within the calculated covenant(s) the Advance Rate will be reduced by the corresponding amount in the table.  The Advance Rate will be reduced for each trigger that is breached on the calculation date. Note: Trigger 2 breach will only reduce the advance rate by the corresponding amount and not the sum of Trigger 1 and Trigger 2.',
          table: {
            columns: [
              {
                title: 'Trigger',
                field: 'trigger',
                width: '150px',
              },
              {
                title: 'Advance Rate',
                field: 'advance_rate',
                width: '150px',
              },
            ],
            data: [
              {
                trigger: 'Advance Rate',
                advance_rate: '90%',
              },
              {
                trigger: 'Trigger 1',
                advance_rate: '80%',
              },
              {
                trigger: 'Trigger 2',
                advance_rate: '70%',
              },
              {
                trigger: 'Trigger 3',
                advance_rate: '0%',
              },
              {
                trigger: 'Trigger 4',
                advance_rate: 'N/A',
              },
            ],
          },
        },
      },
      {
        defined_term: 'Advance Rate',
        definition: 'means the number in the Advance Rate Table.',
      },
    ],
  },
  {
    title: 'Cash',
    columns,
    data: [
      {
        defined_term: 'Cash 1',
        definition:
          'Cash means the sum of all balances held within the following accounts [•]',
        facility_variable: 'Trust USD Funding Account',
      },
      {
        defined_term: 'Cash 2',
        definition: 'means the number in the Advance Rate Table.',
        facility_variable: 'Trust MXN Funding Account',
      },
    ],
  },
  {
    title: 'Eligible Pool Balance 1',
    columns,
    data: [
      {
        defined_term: 'Eligible Pool Balance 1',
        definition:
          'Means as of any Borrowing Base Calculation Date the sum of the Outstanding Principal Balance of all Eligible Assets multiplied by the Eligible Pool Balance Table given the individual assets days past due.',
      },
      {
        defined_term: 'Outstanding Principal Balance',
        definition:
          'means the outstanding principal balance, which is expected to be repaid by the obligor relating to an Asset as of the date of calculation.',
      },
      {
        defined_term: 'Asset',
        definition:
          'means the loan originated by the originator to customers (the underlying loans)',
      },
      {
        defined_term: 'Eligible Assets',
        definition:
          'means all Assets as described by these characteristics [•]',
        facility_variable: 'Eligible Asset Pool 1',
      },
      {
        defined_term: 'Eligible Pool Balance Table',
        definition: {
          content: 'means the table below:',
          table: {
            columns: [
              {
                title: 'Day Past Due',
                field: 'dpd',
                width: '150px',
              },
              {
                title: 'Outstanding Principal Balance multiplied by:',
                field: 'multiplied_by',
                width: '150px',
              },
            ],
            data: [
              {
                dpd: '0',
                multiplied_by: '90%',
              },
              {
                dpd: '1-3',
                multiplied_by: '80%',
              },
              {
                dpd: '4-7',
                multiplied_by: '70%',
              },
              {
                dpd: '8 or greater',
                multiplied_by: '0%',
              },
            ],
          },
        },
      },
    ],
  },
  {
    title: 'Eligible Pool Balance 2',
    columns,
    data: [
      {
        defined_term: 'Eligible Pool Balance 2',
        definition:
          'Means as of any Borrowing Base Calculation Date the sum of the Outstanding Principal Balance of all Eligible Assets multiplied by the Eligible Pool Balance Table given the individual assets days past due.',
      },
      {
        defined_term: 'Outstanding Principal Balance',
        definition:
          'means the outstanding principal balance, which is expected to be repaid by the obligor relating to an Asset as of the date of calculation.',
      },
      {
        defined_term: 'Asset',
        definition:
          'means the loan originated by the originator to customers (the underlying loans)',
      },
      {
        defined_term: 'Eligible Assets',
        definition:
          'means all Assets as described by these characteristics [•]',
        facility_variable: 'Eligible Asset Pool 2',
      },
      {
        defined_term: 'Eligible Pool Balance Table',
        definition: {
          content: 'means the table below:',
          table: {
            columns: [
              {
                title: 'Day Past Due',
                field: 'dpd',
                width: '150px',
              },
              {
                title: 'Outstanding Principal Balance multiplied by:',
                field: 'multiplied_by',
                width: '150px',
              },
            ],
            data: [
              {
                dpd: '0',
                multiplied_by: '90%',
              },
              {
                dpd: '1 or greater',
                multiplied_by: '0%',
              },
            ],
          },
        },
      },
    ],
  },
  {
    title: 'Interest Reserve',
    columns,
    data: [
      {
        defined_term: 'Interest Reserve',
        definition:
          'means the sum of the expected non-principal payments the Borrower will pay the Lender for the next [•] payments.',
        facility_variable: '3',
      },
    ],
  },
  {
    title: 'FX Rate',
    columns,
    data: [
      {
        defined_term: 'FX Rate',
        definition:
          'means the exchange rate as of [•] or the previous business day, as reported by [x].',
        facility_variable: 'End of previous day',
      },
    ],
  },
]
