import React from 'react'

import { Image, Text, View } from '@react-pdf/renderer'

import { styles } from './style'

export const Footer = () => {
  return (
    <View style={styles.pageFooter}>
      <Text style={styles.footerImage}>
        <Image src="/logo192.png" />
      </Text>

      <Text style={styles.footerText}> Cascade Debt </Text>
      <Text style={styles.footerText}> www.cascadedebt.com </Text>
    </View>
  )
}
