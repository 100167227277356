import React from 'react'

import { AdvanceRequestTemplateProps } from '@interfaces/manage-signature'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { Footer } from '../../../document-centre/templates/components'
import { styles } from '../template-style'

import { autochekStyles } from './style'

const AdvanceRequestTemplate = ({
  requestDate,
  advanceDate,
  formData,
  bankInfo,
}: AdvanceRequestTemplateProps) => {
  const formatCurrency = (amount: number) => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount)
  }

  return (
    <Document>
      <Page style={styles.body}>
        <Text
          style={{ ...styles.title, fontSize: 12, fontFamily: 'Times-Bold' }}
        >
          SENIOR ADVANCE REQUEST
        </Text>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Text style={styles.text}>{requestDate || '[date]'}</Text>
        </View>
        <View
          style={{
            ...autochekStyles.textContainerWithMargin,
            flexDirection: 'column',
            marginLeft: 44,
          }}
        >
          <Text style={autochekStyles.textNoMarginBig}>
            Cordiant Capital, Inc.
          </Text>
          <Text style={autochekStyles.textNoMarginBig}>Suite 2800</Text>
          <Text style={autochekStyles.textNoMarginBig}>
            1002 Sherbrooke Street West
          </Text>
          <Text style={autochekStyles.textNoMarginBig}>Montreal, QC</Text>
          <Text style={autochekStyles.textNoMarginBig}>Canada H3A 3L6</Text>
          <Text style={autochekStyles.textNoMarginBig}>
            (as the &quot;
            <Text style={autochekStyles.textBigBoldUnderline}>
              Facility Agent
            </Text>
            &quot;)
          </Text>
          <Text style={{ ...styles.text, marginHorizontal: 0 }}>and</Text>
          <Text style={autochekStyles.textNoMarginBig}>
            Cordiant Global Credit Opportunities S.C.S.
          </Text>
          <Text style={autochekStyles.textNoMarginBig}>
            287-289, route d’Arion
          </Text>
          <Text style={autochekStyles.textNoMarginBig}>L-1150 Luxembourg</Text>
          <Text style={autochekStyles.textNoMarginBig}>
            Grand Duchy of Luxembourg
          </Text>
          <Text style={autochekStyles.textNoMarginBig}>
            (acting through its manager Cordiant Luxembourg S.A.)
          </Text>
          <Text style={autochekStyles.textNoMarginBig}>
            (as the &quot;
            <Text style={autochekStyles.textBigBoldUnderline}>
              Original Senior Lender
            </Text>
            &quot;)
          </Text>
        </View>
        <Text style={autochekStyles.textBig}>
          We refer to the Senior Loan Agreement dated as of 10 July 2023 (as
          amended, restated, supplemented or otherwise modified from time to
          time, the &quot;
          <Text style={autochekStyles.textBigBoldUnderline}>
            Senior Loan Agreement
          </Text>
          &quot;), among AFS Capital SPC, for the account of and on behalf of
          its segregated portfolio, SP1 (the &quot;
          <Text style={autochekStyles.textBigBoldUnderline}>Borrower</Text>
          &quot;), Vee8 International Holdings, Inc, (the &quot;
          <Text style={autochekStyles.textBigBoldUnderline}>Sponsor</Text>
          &quot;), the several financial institutions or entities from time to
          time party hereto (collectively, the &quot;
          <Text style={autochekStyles.textBigBoldUnderline}>
            Senior Lenders
          </Text>
          &quot;) and Cordiant Capital Inc. (the &quot;
          <Text style={autochekStyles.textBigBoldUnderline}>
            Facility Agent
          </Text>
          &quot; and the &quot;
          <Text style={autochekStyles.textBigBoldUnderline}>
            Security Trustee
          </Text>
          &quot;). Capitalised terms used but not defined herein have the
          meanings specified in the Senior Loan Agreement.
        </Text>
        <Text style={{ ...autochekStyles.textBig, textIndent: 30 }}>
          {'1.        '}
          The Borrower hereby requests on {advanceDate || '[request date]'} (the
          &quot;
          <Text style={autochekStyles.textBigBoldUnderline}>
            Senior Advance Date
          </Text>
          &quot;) the funding of the following Senior Loan (the &quot;
          <Text style={autochekStyles.textBigBoldUnderline}>
            Requested Amount
          </Text>
          &quot;){' '}
          {formData?.amount
            ? formatCurrency(formData?.amount)
            : '[USD Requested Amount]'}
        </Text>
        <Text style={{ ...autochekStyles.textBig, textIndent: 30 }}>
          {'2.        '}
          The Borrower hereby gives notice of its request for the Requested
          Amount to the Facility Agent (who shall forward such request to the
          Senior Lenders) pursuant to Clause 4.3 (Additional Requirements with
          respect to each Senior Loan) of the Senior Loan Agreement and requests
          that the Senior Lenders remit, or cause to be remitted, their
          respective pro rata portions of such Requested Amount to the Nigeria
          Liquidity Reserve Account (USD) as follows:
        </Text>
        <View
          wrap={false}
          style={{ ...styles.tableWrapper, paddingVertical: 8 }}
        >
          <View style={{ ...styles.table, width: '75%', borderWidth: 0 }}>
            <View style={styles.tableBody}>
              <View style={{ ...styles.tableRow, borderWidth: 0 }}>
                <View
                  style={{ ...styles.tableCol, width: '30%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    Account Name:
                  </Text>
                </View>
                <View
                  style={{ ...styles.tableCol, width: '70%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    {bankInfo.recipientName}
                  </Text>
                </View>
              </View>
              <View style={{ ...styles.tableRow, borderWidth: 0 }}>
                <View
                  style={{ ...styles.tableCol, width: '30%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    Customer Number:
                  </Text>
                </View>
                <View
                  style={{ ...styles.tableCol, width: '70%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    {bankInfo.wireRoutingNumber}
                  </Text>
                </View>
              </View>
              <View style={{ ...styles.tableRow, borderWidth: 0 }}>
                <View
                  style={{ ...styles.tableCol, width: '30%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    Account Number:
                  </Text>
                </View>
                <View
                  style={{ ...styles.tableCol, width: '70%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    {bankInfo.recipientBankAccountNumber}
                  </Text>
                </View>
              </View>
              <View style={{ ...styles.tableRow, borderWidth: 0 }}>
                <View
                  style={{ ...styles.tableCol, width: '30%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    IBAN:
                  </Text>
                </View>
                <View
                  style={{ ...styles.tableCol, width: '70%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    {bankInfo.aac}
                  </Text>
                </View>
              </View>
              <View style={{ ...styles.tableRow, borderWidth: 0 }}>
                <View
                  style={{ ...styles.tableCol, width: '30%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    Bank:
                  </Text>
                </View>
                <View
                  style={{ ...styles.tableCol, width: '70%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    {bankInfo.recipientBankName}
                  </Text>
                </View>
              </View>
              <View style={{ ...styles.tableRow, borderWidth: 0 }}>
                <View
                  style={{ ...styles.tableCol, width: '30%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    Address:
                  </Text>
                </View>
                <View
                  style={{ ...styles.tableCol, width: '70%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    {bankInfo.recipientBankAddress}
                  </Text>
                </View>
              </View>
              <View style={{ ...styles.tableRow, borderWidth: 0 }}>
                <View
                  style={{ ...styles.tableCol, width: '30%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    Sort Code:
                  </Text>
                </View>
                <View
                  style={{ ...styles.tableCol, width: '70%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    {bankInfo.achRoutingNumber}
                  </Text>
                </View>
              </View>
              <View style={{ ...styles.tableRow, borderWidth: 0 }}>
                <View
                  style={{ ...styles.tableCol, width: '30%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    SWIFT Code:
                  </Text>
                </View>
                <View
                  style={{ ...styles.tableCol, width: '70%', borderWidth: 0 }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 12 }}>
                    {bankInfo.recipientSwiftNumber}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Text style={{ ...autochekStyles.textBig, textIndent: 30 }}>
          {'3.        '}
          The Borrower hereby represents that the Requested Amount will not, on
          the Senior Advance Date, exceed the Available Facility Amount.
        </Text>
        <Text style={{ ...autochekStyles.textBig, textIndent: 30 }}>
          {'4.        '}
          The Borrower certifies that on the date of this Senior Advance Request
          and on the Senior Advance Date each of the applicable conditions
          precedent set forth in Clause 4.3 of the Senior Loan Agreement are
          satisfied.
        </Text>
        <Text style={{ ...autochekStyles.textBig, textIndent: 30 }}>
          {'5.        '}
          The Borrower certifies that on the Senior Advance Date, the Liquidity
          Reserve Requirement will be satisfied.
        </Text>
        <Text style={{ ...autochekStyles.textBig, textIndent: 30 }}>
          {'6.        '}
          The Borrower confirms that the Original Senior Lender has, on or
          around the date of the Senior {'Loan '} Agreement, received evidence
          of satisfaction of the Eligibility Criteria in the form of a report
          prepared by the Segregated Portfolio Administrator (with support from
          the Data Agent) carried out on a sample of auto loans selected by the
          Original Senior Lender.
        </Text>
        <Text style={{ ...autochekStyles.textBig, textIndent: 30 }}>
          {'7.        '}
          This notice is irrevocable.
        </Text>
        <Text style={{ ...autochekStyles.textBig, textIndent: 30 }}>
          {'8.        '}
          The proceeds of this drawdown should be credited to the Nigeria
          Liquidity Reserve Account (USD).
        </Text>
        <Text style={{ ...autochekStyles.textBig, textIndent: 30 }}>
          {'9.        '}
          The Borrower confirms that on the date of this Senior Advance Request
          and on the Senior Advance Date, the representations set out in Clause
          4.3(b) (Deemed Representations) of the Senior Loan Agreement are true
          and correct, and the Borrower shall be deemed to have certified that,
          after giving effect to the Requested Senior Advance, each of the
          representations set out in Clause 4.3(b) (Deemed Representations) of
          the Senior Loan Agreement are true.
        </Text>
        <View wrap={false} style={{ marginBottom: 10 }}>
          <Text style={{ ...autochekStyles.textBig, textIndent: 30 }}>
            {'10.        '}
            We confirm that on the date of the Senior Advance Request and on the
            proposed Senior Advance Date:
          </Text>
          <View style={{ ...autochekStyles.textContainer, paddingLeft: 68 }}>
            <Text style={autochekStyles.textBig}>{'(i)        '}</Text>
            <Text style={autochekStyles.textBig}>
              no Default or Event of Default is continuing;
            </Text>
          </View>
          <View style={{ ...autochekStyles.textContainer, paddingLeft: 68 }}>
            <Text style={autochekStyles.textBig}>{'(ii)       '}</Text>
            <Text style={autochekStyles.textBig}>
              no Early Amortisation Event is continuing;
            </Text>
          </View>
          <View style={{ ...autochekStyles.textContainer, paddingLeft: 68 }}>
            <Text style={autochekStyles.textBig}>{'(iii)      '}</Text>
            <Text style={autochekStyles.textBig}>
              there is no Borrowing Base Deficiency;
            </Text>
          </View>
          <View style={{ ...autochekStyles.textContainer, paddingLeft: 68 }}>
            <Text style={autochekStyles.textBig}>{'(iv)       '}</Text>
            <Text style={autochekStyles.textBig}>
              the Repeating Representations to be made by each of the Obligors,
              as relevant, are true in all material respects;
            </Text>
          </View>
          <View style={{ ...autochekStyles.textContainer, paddingLeft: 68 }}>
            <Text style={autochekStyles.textBig}>{'(v)        '}</Text>
            <Text style={autochekStyles.textBig}>
              no Default, Event of Default or Early Amortisation Event would
              result from the proposed Senior Loan; and
            </Text>
          </View>
          <View style={{ ...autochekStyles.textContainer, paddingLeft: 68 }}>
            <Text style={autochekStyles.textBig}>{'(vi)       '}</Text>
            <Text style={autochekStyles.textBig}>
              the Senior Loan complies with the Senior Loan Agreement.
            </Text>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.text}>[Signature Page to Follow]</Text>
        </View>
      </Page>
      <Page style={styles.body}>
        <Text style={autochekStyles.textNoMarginBig}>
          This Senior Advance Request is made this {requestDate || '[date]'}.
        </Text>
        <View
          wrap={false}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          <Text
            style={{
              ...autochekStyles.textNoMarginBig,
              maxWidth: '50%',
              paddingRight: 32,
            }}
          >
            <Text style={autochekStyles.textBigBold}>EXECUTED</Text> for and on
            behalf of{' '}
            <Text style={autochekStyles.textBigBold}>
              AFS CAPITAL SPC, FOR THE ACCOUNT AND ON BEHALF OF ITS SEGREGATED
              PORTFOLIO, SP1
            </Text>{' '}
            by:
          </Text>
          <View style={{ width: '50%', borderLeftWidth: 1, paddingLeft: 16 }}>
            <Text style={styles.text}>By:</Text>
            <Text style={autochekStyles.signTags}>
              [sig|req|signer1|signature]
            </Text>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderTopWidth: 1,
              }}
            >
              <Text style={styles.text}>
                Name: {formData?.signers?.[0]?.name}
              </Text>
            </View>
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}

export default AdvanceRequestTemplate
