import { useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import { getStaleMins } from '@helpers/stale-timer'
import { CovenantType } from '@interfaces/covenant'
import { BorrowingBaseService } from '@services/api-manage/borrowing-base'
import { CovenantService } from '@services/api-manage/covenant'

export const useLendableComplianceCertData = (effectiveDate: string) => {
  const [data, setData] = useState<any>([true, {}])

  const filters = {
    slug_name: 'autochek',
    facility: 'Lendable-UG',
    covenant_type: 'portfolio' as CovenantType,
  }

  const covenantQueries = [
    'PAR 60 UG CI',
    'PAR 90 UG CI',
    'Writeoff UG CI',
    'Post Recovery UG CI',
    'Concentration Rate 1 UG CI',
  ].map(covenantName =>
    useQuery(
      ['covenant-raw', covenantName, filters],
      () =>
        CovenantService.getRawData({
          ...filters,
          covenant_name: covenantName,
        }),
      {
        ...getStaleMins(),
      }
    )
  )

  const covenantData = covenantQueries.every(query => query.isSuccess)
    ? covenantQueries.map(query => {
        const data = query?.data
          ?.filter(
            entry =>
              entry.calculated_on <= moment(effectiveDate).format('YYYY-MM-DD')
          )
          .sort((a, b) => a.calculated_on - b.calculated_on)

        const closestCalcDate = data?.[data?.length - 1]?.calculated_on

        const result = data?.filter(
          entry => entry.calculated_on === closestCalcDate
        )

        return result
      })
    : []

  const { data: customData, isFetching: isFetchingCustomData } = useQuery(
    ['autochekCustomData', filters, effectiveDate],
    () =>
      BorrowingBaseService.getAutochekCustomData({
        ...filters,
        date: moment(effectiveDate).format('YYYY-MM-DD'),
      }),
    { ...getStaleMins() }
  )

  const tempData = {
    ...customData,
    par60: parseFloat(covenantData[0]?.[0]?.par60),
    par90: parseFloat(covenantData[1]?.[0]?.par90),
    writeoff: parseFloat(covenantData[2]?.[0]?.writeoff),
    expectedLosses: covenantData[3]?.reduce(
      (acc, curr) => acc + parseFloat(curr.expected_losses.replaceAll(',', '')),
      0
    ),
    outstandingGBV: covenantData[3]?.reduce(
      (acc, curr) => acc + parseFloat(curr.outstanding_gbv.replaceAll(',', '')),
      0
    ),
    concentrationRateUG:
      (covenantData[4]?.find(item => item.facility === 'Lendable-UG')
        ?.disburse_principal /
        covenantData[4]?.reduce(
          (acc, curr) => acc + curr.disburse_principal,
          0
        )) *
      100,
    concentrationRateCI:
      (covenantData[4]?.find(item => item.facility === 'Lendable-CI')
        ?.disburse_principal /
        covenantData[4]?.reduce(
          (acc, curr) => acc + curr.disburse_principal,
          0
        )) *
      100,
  }

  const isLoading =
    isFetchingCustomData || covenantQueries.some(query => query.isLoading)

  useEffect(() => {
    setData([
      isFetchingCustomData || covenantQueries.some(query => query.isLoading),
      tempData,
    ])
  }, [isLoading])

  return data
}
