import React from 'react'

import { Checkbox, CheckboxProps } from '@material-tailwind/react'

interface Props extends Omit<CheckboxProps, 'color'> {
  color?: 'primary' | 'secondary'
}

export default function CustomCheckbox(props: Props) {
  return <Checkbox {...(props as any)} />
}
