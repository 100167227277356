import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  footerImage: {
    paddingTop: 5,
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
  },

  footerText: {
    fontSize: 7,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
  },

  pageFooter: {
    width: '100%',
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
})
