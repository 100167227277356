import React, { useContext, useEffect, useState } from 'react'
import HelloSign from 'hellosign-embedded'
import moment, { Moment } from 'moment'
import Dropzone from 'react-dropzone'
import { useMutation, useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import FormInput from '@components/form/form-input'
import Table from '@components/table'
import { Part } from '@components/table/type'
import { DROPBOX_SIGN_CLIENT_ID } from '@constants/config'
import AuthContext from '@contexts/auth'
import {
  ArrowPathIcon,
  DocumentCheckIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline'
import {
  CalculationDateResponse,
  WaterfallDetailResponse,
} from '@interfaces/manage-monitor-waterfall'
import { Option, Select } from '@material-tailwind/react'
import { WaterfallService } from '@services/api-manage/monitor-waterfall'
import SignatureService from '@services/api-manage/signature'

import { FX_DEFINED_TERM } from './constant'
import ReportDialog from './report-dialog'

const Waterfall = ({
  can_sign,
  can_create,
}: {
  can_sign: boolean
  can_create: boolean
}) => {
  const { user } = useAuth0()
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const docCentreAction = searchParams.get('dCAction')
  const dcId = searchParams.get('dcId')
  const currency = 'USD'

  const newItem = {
    item: '',
    currency,
    amount: 0,
    proof_of_transaction: undefined,
  }

  const { activeDebtDeal = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility ?? '',
  }

  const {
    data: waterfallList,
    isFetching: isWaterfallListFetching,
    refetch: refetchList,
  } = useQuery(
    ['waterfal-list', filters],
    () => WaterfallService.getList(filters),
    {
      enabled: !!activeFacility?.facility && !!company?.slug_name,
    }
  )

  const [openReportDialog, setOpenReportDialog] = useState<boolean>(false)
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([])
  const onExpand = (index: number) => {
    const indexes = expandedIndexes.includes(index)
      ? expandedIndexes.filter(i => i !== index)
      : [...expandedIndexes, index]

    setExpandedIndexes(indexes)
  }

  const [selectedDate, setSelectedDate] = useState<Moment>(moment())
  const [selectedWF, setSelectedWF] = useState<number>(0)

  const waterfall = (waterfallList ?? [])[selectedWF]
  const originalDateList = (waterfall?.calculation_date ?? []).sort((a, b) =>
    a.calculation_date < b.calculation_date ? 1 : -1
  )
  // const today = moment()
  // const latestDate = moment(originalDateList?.[0]?.calculation_date)
  // const monthDiff = Math.ceil(today.diff(latestDate, 'months', true))
  // const additionalDateList = today.isAfter(latestDate)
  //   ? Array(monthDiff)
  //       .fill('')
  //       .map((_, i) => ({
  //         calculation_date: moment(latestDate)
  //           .add(monthDiff - i, 'month')
  //           .endOf('month')
  //           .format('YYYY-MM-DD'),
  //       }))
  //   : []
  const dateList: CalculationDateResponse[] = [
    // ...additionalDateList,
    ...originalDateList,
  ]
  const selectedWaterfallDate = dateList?.find(
    x =>
      moment.utc(x.calculation_date).format('YYYY-MM-DD') ===
      moment.utc(selectedDate).format('YYYY-MM-DD')
  )

  const isProfitShare = waterfall?.waterfall
    ?.toLowerCase()
    .includes('profit share')
  const isOriginalDate = originalDateList.find(
    d => d.calculation_date === selectedWaterfallDate?.calculation_date
  )
  const isEditable =
    !selectedWaterfallDate?.status ||
    selectedWaterfallDate?.status === 'tentative'

  useEffect(() => {
    if (originalDateList.length > 0) {
      const prevDate = dateList?.find(
        x =>
          moment.utc(x.calculation_date).format('YYYY-MM-DD') ===
          moment.utc(selectedDate).format('YYYY-MM-DD')
      )
      setSelectedDate(moment.utc((prevDate ?? dateList[0])?.calculation_date))
    }
  }, [selectedWF, originalDateList])

  const detailFilters = {
    ...filters,
    calculation_date: moment(
      isOriginalDate ? selectedDate : originalDateList?.[0]?.calculation_date
    ).format('YYYY-MM-DD'),
    waterfall: waterfall?.waterfall,
  }

  const {
    data: waterfallDetail,
    isFetching: isWaterfallDetailFetching,
    refetch: refetchDetails,
  } = useQuery(
    ['waterfal-detail', detailFilters],
    () => WaterfallService.getDetail(detailFilters),
    {
      enabled:
        !!activeFacility?.facility &&
        !!company?.slug_name &&
        !!selectedDate &&
        !!waterfall,
    }
  )

  const [currentDetailData, setCurrentDetailData] = useState<
    WaterfallDetailResponse[] | undefined
  >([])

  useEffect(() => {
    const nextDetailData = waterfallDetail?.map(wd => {
      return {
        ...wd,
        ...(isOriginalDate
          ? {}
          : {
              actual_value: undefined,
              input_value: undefined,
              component: [],
            }),
      }
    })

    setCurrentDetailData(nextDetailData)
  }, [waterfallDetail, isOriginalDate])

  const fxItems = (currentDetailData ?? []).filter(
    (x: WaterfallDetailResponse) =>
      x.defined_term.toLowerCase().includes(FX_DEFINED_TERM)
  )

  const _onAddFx = (fx: string) => {
    if (fx === currency) {
      return
    }
    const current = [...(currentDetailData ?? [])]
    const idx = current.findIndex(
      x =>
        x.defined_term.toLocaleLowerCase().includes(FX_DEFINED_TERM) &&
        x.currency === fx
    )
    if (idx < 0) {
      current.push({
        description: `FX rate used ${fx}`,
        defined_term: `${FX_DEFINED_TERM}_${fx}`,
        action: 'none',
        order: 0,
        currency: fx,
      })
    }

    setCurrentDetailData(current)
  }

  const _onRemoveFx = (fx: string) => {
    if (fx === currency) {
      return
    }
    const current = [...(currentDetailData ?? [])].filter(
      x => x.currency !== fx
    )

    setCurrentDetailData(current)
  }

  const fxDefaultItem = fxItems.find(
    (x: WaterfallDetailResponse) =>
      x.defined_term.toLowerCase() === FX_DEFINED_TERM
  )
  const fxDefault =
    fxDefaultItem?.input_value ?? fxDefaultItem?.actual_value ?? 1

  let rest = 0
  const detailData = currentDetailData
    ?.filter(x => !x.defined_term.toLowerCase().includes(FX_DEFINED_TERM))
    ?.map(x => {
      const actualValue = x.actual_value ?? 0
      const originalValue = x.action.includes('div')
        ? actualValue / fxDefault
        : actualValue

      const component =
        x.component?.length === 0
          ? [{ ...newItem, amount: Number(Number(originalValue).toFixed(2)) }]
          : x.component
      x.input_value =
        (x.component ?? []).length > 0
          ? (component ?? []).reduce((p, c) => {
              const fxUsedItem = fxItems.find(x => x.currency === c.currency)
              const fxUsed =
                fxUsedItem?.input_value ?? fxUsedItem?.actual_value ?? 1
              const calculatedAmount = c.amount / fxUsed
              return p + calculatedAmount
            }, 0)
          : x.input_value

      const value = Number(x.input_value ?? originalValue)
      const max = ['add', 'none'].some(y => x.action.includes(y))
        ? undefined
        : rest
      const is_error_profit =
        !x.actual_value &&
        x.defined_term?.toLowerCase().includes('profit_share')
      const is_error = is_error_profit

      const result = {
        ...x,
        component,
        value: value.toFixed(2),
        max,
        is_error,
        is_error_profit,
      }
      rest =
        rest +
        value *
          (x.action.includes('subtract')
            ? -1
            : x.action.includes('none')
            ? 0
            : 1)

      return result
    })
    .sort((a, b) => a.order - b.order)

  const multiplier = detailData?.[0]?.multiplier ?? 0
  const footerDetailData = (
    isProfitShare
      ? [
          {
            description: `Final Profit Share (${Intl.NumberFormat(undefined, {
              style: 'percent',
              notation: 'compact',
            }).format(multiplier)})`,
            value: rest * multiplier,
          },
        ]
      : []
  ) as WaterfallDetailResponse[]
  const isValid = detailData?.filter(x => x.is_error).length === 0

  const _onChange = (
    defined_term: string,
    value: any,
    field = 'input_value'
  ) => {
    const current = [...(currentDetailData ?? [])]
    const idx = current.findIndex(x => x.defined_term === defined_term)
    current[idx] = {
      ...current[idx],
      [field]: value,
    }

    setCurrentDetailData(current)
  }

  const _onAddItem = (defined_term: string, item: any) => {
    const current = [...(currentDetailData ?? [])]
    const idx = current.findIndex(x => x.defined_term === defined_term)
    if (current[idx].component?.length === 0) {
      ;(current[idx].component ?? []).push(item)
    }
    ;(current[idx].component ?? []).push(newItem)

    setCurrentDetailData(current)
  }

  const _onRemoveItem = (defined_term: string, itemIndex: number) => {
    const current = [...(currentDetailData ?? [])]
    const idx = current.findIndex(x => x.defined_term === defined_term)
    current[idx].component = (current[idx].component ?? []).filter(
      (_, i) => i !== itemIndex
    )

    setCurrentDetailData(current)
  }

  const _onChangeItem = (
    defined_term: string,
    itemIndex: number,
    field: string,
    value: any,
    item: any
  ) => {
    if (isEditable) {
      const current = [...(currentDetailData ?? [])]
      const idx = current.findIndex(x => x.defined_term === defined_term)
      if (current[idx].component?.length === 0) {
        current[idx].component = [item]
      }
      ;(current[idx]?.component ?? [])[itemIndex] = {
        ...(current[idx]?.component?.[itemIndex] ?? {}),
        [field]: value,
      } as any
      setCurrentDetailData(current)
    } else {
      const formData = new FormData()
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name ?? ''
      )
      formData.append('facility', activeFacility?.facility ?? '')
      formData.append('waterfall', waterfall?.waterfall)
      formData.append(
        'calculation_date',
        moment(selectedDate).format('YYYY-MM-DD')
      )
      formData.append('defined_term', defined_term)
      formData.append('itemIndex', itemIndex.toString())
      formData.append('files', value)
      uploadFile(formData)
    }
  }

  const { mutate: download } = useMutation(
    (params: { slug_name: string; file: string }) => {
      return WaterfallService.download(params)
    },
    {
      onSuccess: res => {
        window.open(String(res), '_blank')
      },
    }
  )

  const { mutate: uploadFile, isLoading: isLoadingUploadFile } = useMutation(
    (formData: FormData) => {
      return WaterfallService.uploadProofOfTransaction(formData)
    },
    {
      onSuccess: () => {
        toast.success('File uploaded successfully')
        refetchDetails()
      },
      onError: () => {
        toast.error('Failed to upload file')
      },
    }
  )

  const client = new HelloSign()
  client.on('sign', () => {
    sign()
  })

  const currentSigner = selectedWaterfallDate?.signature?.signatures?.find(
    (s: any) =>
      s.status_code !== 'signed' && s.signer_email_address === user?.email
  )

  const { mutate: sign } = useMutation(
    () => {
      return WaterfallService.sign({
        ...detailFilters,
        signature_id: currentSigner?.signature_id,
        requester_name: user?.name ?? '',
      })
    },
    {
      onSuccess: () => {
        refetchList()
      },
    }
  )

  const { mutate: getSignatureURL, isLoading: isLoadingSignature } =
    useMutation(
      () => {
        return SignatureService.getEmbeddedSignatureURL(
          currentSigner.signature_id
        )
      },
      {
        onSuccess: res => {
          client.open(res.data?.signUrl, {
            clientId: DROPBOX_SIGN_CLIENT_ID,
            skipDomainVerification: true,
          })
        },
      }
    )

  const _handler = async (needUpdate?: boolean) => {
    setOpenReportDialog(false)
    if (needUpdate) {
      await refetchList()
      getSignatureURL()
    }
  }

  const { mutate: submitWaterfall, isLoading: isLoadingSave } = useMutation(
    (formData: FormData) => {
      return WaterfallService.submit(formData)
    },
    {
      onSuccess: () => {
        toast.success('Waterfall saved successfully')
      },
      onError: () => {
        toast.error('Failed to save waterfall')
      },
    }
  )

  const saveWaterfall = () => {
    const formData = new FormData()
    formData.append(
      'slug_name',
      activeFacility?.slug_name ?? company?.slug_name ?? ''
    )
    formData.append('facility', activeFacility?.facility ?? '')
    formData.append('waterfall', waterfall?.waterfall)
    formData.append(
      'calculation_date',
      moment(selectedDate).format('YYYY-MM-DD')
    )
    formData.append('requester_name', user?.name ?? '')
    formData.append('status', 'tentative')
    ;(footerDetailData ?? []).forEach(fd => {
      formData.append(
        'final_value',
        Number(fd.value ?? fd.input_value ?? fd.actual_value ?? 0).toFixed(2)
      )
    })
    ;[...fxItems, ...(detailData ?? []), ...(footerDetailData ?? [])]?.forEach(
      (d: any, i: number) => {
        formData.append(`detail[${i}][defined_term]`, d.defined_term)
        formData.append(
          `detail[${i}][value]`,
          d.input_value ?? d.value ?? d.actual_value ?? 0
        )
        d.component?.forEach((c: any, j: number) => {
          formData.append(`detail[${i}][component][${j}][item]`, c.item ?? '')
          formData.append(`detail[${i}][component][${j}][currency]`, c.currency)
          formData.append(
            `detail[${i}][component][${j}][amount]`,
            c.amount ?? 0
          )
          c.proof_of_transaction &&
            formData.append(
              `detail[${i}][component][${j}][proof_of_transaction]`,
              c.proof_of_transaction ?? ''
            )
        })
      }
    )

    submitWaterfall(formData)
  }

  const isLoading = isLoadingSave || isLoadingSignature

  const expandedRowRender = (record: any) => {
    const expandedData: any[] = record.component
    const expandedColumns: any[] = [
      {
        title: 'Item',
        field: 'item',
        render: (r: any, i: number) => {
          return isEditable ? (
            <div className={`flex flex-col [&>div]:mb-0 hide-arrow`}>
              <FormInput
                value={r.item}
                {...{
                  placeholder: 'Item description',
                  onChange: (e: any) => {
                    _onChangeItem(
                      record.defined_term,
                      i,
                      'item',
                      e.target.value,
                      r
                    )
                  },
                }}
              />
            </div>
          ) : (
            r.item
          )
        },
      },
      {
        title: 'Currency',
        field: 'currency',
        width: 100,
        align: 'center',
        render: (r: any, i: number) => {
          return isEditable ? (
            <div
              className={`flex flex-col [&>div]:mb-0 hide-arrow [&>div>input]:max-w-[100px] [&>div>input]:text-center ${
                record.is_error_max ? '[&>div>input]:!border-danger-main' : ''
              }`}
            >
              <FormInput
                value={r.currency}
                {...{
                  onChange: (e: any) => {
                    _onChangeItem(
                      record.defined_term,
                      i,
                      'currency',
                      e.target.value,
                      r
                    )
                  },
                  onBlur: (e: any) => {
                    const value = e.target.value
                    _onAddFx(value)
                  },
                }}
              />
            </div>
          ) : (
            r.currency
          )
        },
      },
      {
        title: 'Amount',
        field: 'amount',
        align: 'right',
        head: { align: 'center' },
        render: (r: any, i: number) => {
          return isEditable ? (
            <div
              className={`flex flex-col [&>div]:mb-0 hide-arrow ${
                record.is_error_max ? '[&>div>input]:!border-danger-main' : ''
              }`}
            >
              <FormInput
                type="number"
                value={Number(r.amount)}
                {...{
                  style: { textAlign: 'right' },
                  step: '0.01',
                  min: 0,
                  max: r.max,
                  onChange: (e: any) => {
                    _onChangeItem(
                      record.defined_term,
                      i,
                      'amount',
                      Number(e.target.value),
                      r
                    )
                  },
                }}
              />
            </div>
          ) : (
            Intl.NumberFormat(undefined, {
              style: 'decimal',
              maximumFractionDigits: 2,
            }).format(r?.amount ?? 0)
          )
        },
      },
      {
        title: 'Amount (USD)',
        field: 'amount',
        align: 'right',

        head: { align: 'center' },
        render: (r: any) => {
          const fxItemCurrent = fxItems.find(
            x =>
              x.defined_term.toLowerCase().includes(FX_DEFINED_TERM) &&
              x.currency === r.currency
          )
          const fxCurrentDefault =
            fxItemCurrent?.input_value ?? fxItemCurrent?.actual_value ?? 1

          return Intl.NumberFormat(undefined, {
            style: 'decimal',
            maximumFractionDigits: 2,
          }).format((r?.amount ?? 0) / fxCurrentDefault)
        },
      },
      {
        title: 'Proof of Transaction',
        field: 'proof_of_transaction',
        align: 'center',
        width: 200,
        render: (r: any, i: number) => {
          return (
            <div>
              {r.proof_of_transaction ? (
                typeof r.proof_of_transaction === 'string' ? (
                  <Typography
                    onClick={() =>
                      download({
                        slug_name:
                          activeFacility?.slug_name ?? company?.slug_name ?? '',
                        file: r.proof_of_transaction,
                      })
                    }
                    className="text-primary-main cursor-pointer text-sm mb-2"
                  >
                    {r.proof_of_transaction}
                  </Typography>
                ) : (
                  <Typography className="text-primary-main text-sm mb-2">
                    {r.proof_of_transaction.name}
                  </Typography>
                )
              ) : isLoadingUploadFile ? (
                <div className="flex justify-center p-4">
                  <ArrowPathIcon className="w-6 h-6 text-primary-main animate-spin" />
                </div>
              ) : (
                <Dropzone
                  accept={{
                    'application/pdf': ['.pdf'],
                  }}
                  onDrop={acceptedFiles => {
                    _onChangeItem(
                      record.defined_term,
                      i,
                      'proof_of_transaction',
                      acceptedFiles[0],
                      r
                    )
                  }}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="focus-within:border-primary-main cursor-pointer border border-neutral-border-2 rounded-md p-2 border-dashed bg-neutral-white justify-center text-center max-w-[175px]">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="flex justify-center items-center gap-2">
                          <DocumentCheckIcon className="w-4 h-4 text-primary-main" />
                          <p className="truncate text-sm max-w-[120px]">
                            {r.proof_of_transaction?.path ??
                              (r.proof_of_transaction
                                ? 'Select another file'
                                : 'Click to select file')}
                          </p>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}
            </div>
          )
        },
      },
      ...(isEditable
        ? [
            {
              title: '',
              field: 'id',
              align: 'center',
              render: (r: any, i: number) => {
                return isEditable ? (
                  i === expandedData.length - 1 ? (
                    <Button
                      className="border-primary-main !outline-0"
                      onClick={() => _onAddItem(record.defined_term, r)}
                    >
                      <PlusCircleIcon className="w-5 h-5 text-primary-main" />
                    </Button>
                  ) : (
                    <Button
                      className="!border-danger-main !outline-0 hover:bg-danger-surface"
                      onClick={() => _onRemoveItem(record.defined_term, i)}
                    >
                      <MinusCircleIcon className="w-5 h-5 !text-danger-main" />
                    </Button>
                  )
                ) : (
                  <></>
                )
              },
            },
          ]
        : []),
    ]

    return (
      <div className="[&>div]:!mb-0">
        <Table columns={expandedColumns} data={expandedData} />
      </div>
    )
  }

  useEffect(() => {
    docCentreAction == 'create' && setOpenReportDialog(true)
    !!dcId && searchParams.delete('dcId')
    !!docCentreAction && searchParams.delete('dCAction')
    setSearchParams(searchParams)
  }, [])

  return isWaterfallListFetching ? (
    <div className="flex justify-center p-4">
      <ArrowPathIcon className="w-6 h-6 text-primary-main animate-spin" />
    </div>
  ) : (waterfallList ?? []).length === 0 ? (
    <div className="flex justify-center p-4">
      <Typography className="text-center">{`No Waterfall found for ${company?.legal_name}`}</Typography>
    </div>
  ) : (
    <>
      {can_create && (
        <ReportDialog
          open={openReportDialog}
          data={[...fxItems, ...(detailData ?? [])]}
          footerData={footerDetailData}
          date={selectedDate}
          waterfall={waterfall}
          handler={_handler}
          currency={currency}
        />
      )}
      <div className="flex flex-col p-4">
        <div className="flex justify-between">
          <div className=" max-w-[250px]">
            <Select
              className="[&~ul]:max-h-[100px]"
              onChange={val => {
                setSelectedDate(moment.utc(val as string))
              }}
              selected={() => {
                return <span>{selectedDate?.format('YYYY-MM-DD')}</span>
              }}
            >
              {dateList.map((c: CalculationDateResponse) => (
                <Option key={c.calculation_date} value={c.calculation_date}>
                  {c.calculation_date}
                </Option>
              ))}
            </Select>
          </div>

          <div className="flex bg-neutral-border-2 p-1 rounded-lg">
            {(waterfallList ?? []).map((x: any, i: number) => (
              <button
                onClick={() => setSelectedWF(i)}
                key={i}
                className={`rounded-lg px-8 text-sm ${
                  i === selectedWF
                    ? 'bg-secondary-main text-neutral-white'
                    : 'text-neutral-body-2'
                }`}
              >
                {x.waterfall}
              </button>
            ))}
          </div>
        </div>
        <Typography className="font-medium my-6 text-neutral-black text-xl text-center">{`${
          waterfall?.waterfall
        }${isProfitShare ? '' : `- ${waterfall?.period}`}`}</Typography>
        <Table
          containerClass="max-w-[35%]"
          data={fxItems}
          columns={[
            {
              field: 'currency',
              title: 'FX Rate',
              align: 'center',
              render: (r: any) => `${currency}:${r?.currency}`,
            },
            {
              field: 'actual_value',
              title: 'Default',
              width: 100,
              align: 'right',
              head: { align: 'center' },
              render: (r: any) => {
                return r?.actual_value
                  ? Intl.NumberFormat(undefined, {
                      style: 'decimal',
                      maximumFractionDigits: 2,
                    }).format(r?.actual_value)
                  : '-'
              },
            },
            {
              field: 'input_value',
              title: 'Value',
              width: 100,
              render: (r: any) => {
                const fxCurrent = r?.input_value ?? r?.actual_value ?? 1
                return (
                  <div className="[&>div]:!mb-0 [&>div>input]:max-w-[75px] hide-arrow">
                    <FormInput
                      type="number"
                      value={Number(fxCurrent)}
                      {...{
                        disabled: !isEditable,
                        style: { textAlign: 'right' },
                        step: '0.01',
                        min: 1,
                        onChange: (e: any) => {
                          _onChange(
                            r?.defined_term ?? FX_DEFINED_TERM,
                            Number(e.target.value)
                          )
                        },
                      }}
                    />
                  </div>
                )
              },
            },
            ...(fxItems.length > 1
              ? [
                  {
                    field: 'defined_term',
                    render: (r: any, i: number) => {
                      return (
                        r.currency !== currency &&
                        i > 0 && (
                          <MinusCircleIcon
                            className="w-5 h-5 !text-danger-main !border-none ml-2 cursor-pointer"
                            onClick={() => _onRemoveFx(r.currency)}
                          />
                        )
                      )
                    },
                  },
                ]
              : []),
          ]}
        />

        <Table
          columns={[
            {
              title: 'Priority',
              field: 'order',
              align: 'center',
              render: (r: any, i: number, part?: Part) => {
                return part === 'body' ? i : ''
              },
            },
            {
              title: 'Description',
              field: 'description',
              render: (r: any, i: number, part?: Part) => {
                return (
                  <div className="flex flex-col">
                    <Typography
                      className={`text-sm ${
                        part === 'foot' ? 'font-semibold' : ''
                      }`}
                    >
                      {r.description}
                    </Typography>
                    {r.is_error_profit && (
                      <Typography className="text-xs text-danger-main mt-1">
                        {`Please finalize ${waterfall?.waterfall
                          ?.toLowerCase()
                          .replace('waterfall', 'profit share')} first`}
                      </Typography>
                    )}
                  </div>
                )
              },
            },
            {
              title: 'Cash Available',
              field: 'max',
              align: 'right',
              head: { align: 'center' },
              width: 200,
              render: (r: any, i: number, part?: Part) => {
                return part === 'body'
                  ? r.max
                    ? Intl.NumberFormat(undefined, {
                        style: 'decimal',
                        maximumFractionDigits: 2,
                      }).format(r.max) + ` ${currency}`
                    : '-'
                  : ''
              },
            },
            {
              title: 'Cascade Calculation',
              field: 'actual_value',
              align: isOriginalDate ? 'right' : 'center',
              head: { align: 'center' },
              width: 200,
              render: (r: any, i: number, part?: Part) => {
                const og_v = r.actual_value ?? 0
                const v = r.action?.includes('div') ? og_v / fxDefault : og_v
                return part === 'body'
                  ? isOriginalDate
                    ? Intl.NumberFormat(undefined, {
                        style: 'decimal',
                        maximumFractionDigits: 2,
                      }).format(v) + ` ${currency}`
                    : 'TBD'
                  : ''
              },
            },
            {
              title: 'Final Amount',
              field: 'value',
              align: 'right',
              head: { align: 'center' },
              width: 200,
              render: (r: any) => {
                const v = r.value ?? 0
                return (
                  Intl.NumberFormat(undefined, {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                  }).format(v) + ` ${currency}`
                )
              },
            },
          ]}
          data={detailData}
          footerData={footerDetailData}
          loading={isWaterfallDetailFetching}
          rowClass={(r: any, i: number) => {
            const og_v = r.actual_value ?? 0
            const v = r.action?.includes('div') ? og_v / fxDefault : og_v
            const is_same = Number(r.value).toFixed(2) === Number(v).toFixed(2)
            const last_child_class = is_same
              ? ``
              : `[&>td:nth-child(6)]:!bg-primary-surface-2`
            return r.is_error
              ? `[&>td]:!bg-danger-surface`
              : i % 2 === 0
              ? `[&>td]:!bg-neutral-surface-2 ${last_child_class}`
              : `${last_child_class}`
          }}
          expandable={{
            expandedIndexes,
            expandedRowRender,
            onExpand,
          }}
        />
        <div className="gap-2 flex justify-center">
          {isEditable && can_create && (
            <>
              <Button
                color="primary"
                onClick={() => {
                  saveWaterfall()
                }}
                disabled={!isValid}
              >
                Save for later
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  setOpenReportDialog(true)
                }}
                disabled={!isValid || !isOriginalDate}
              >
                Generate Report
              </Button>
            </>
          )}
          {!isEditable && currentSigner && can_sign && (
            <Button
              color="primary"
              onClick={() => {
                getSignatureURL()
              }}
              disabled={isLoading}
            >
              {isLoading && (
                <ArrowPathIcon className="animate-spin text-primary-main w-4 h-4 mr-2" />
              )}
              Sign Report
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default Waterfall
