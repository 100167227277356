import React from 'react'

import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useUserAccessFeature } from '@helpers/auth-provider'

import ClientL5 from './client'

const ClientL4 = () => {
  const { filter_access, get_access } = useUserAccessFeature()
  const FEATURE = `analytics_risk_traction_clients`
  const tabs = [
    {
      label: 'Monthly Active Clients',
      desc: {
        description: 'The number of active clients in that calendar month. ',
        formula:
          'Count of Active Clients at the end of each month that were active at some point in that month. ',
      },
      isTotal: false,
    },
    {
      label: 'Total Unique Clients',
      desc: {
        description:
          'The number of unique clients the company has had since the beginning of operations ',
        formula:
          'Total count of unique customer_id from beginning to a given date',
      },
      isTotal: true,
    },
  ]
    .filter(t =>
      filter_access(`${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`)
    )
    .map(t => {
      const exportable = get_access(
        `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`,
        'export'
      )
      return {
        ...t,
        component: <ClientL5 isTotal={t.isTotal} exportable={exportable} />,
      }
    })

  return (
    <L4TabsLayout tabs={tabs} headerClassName={`test-class [&>nav>ul]:!flex`} />
  )
}

export default ClientL4
