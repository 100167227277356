import React from 'react'

import { ActivityBox } from './activity-box'

interface ActivityBoxProps {
  data: any
}

export const Activity = ({ data }: ActivityBoxProps) => {
  return (
    <div className="h-[380px] overflow-y-auto">
      {!!data.length && data.length > 0 ? (
        data.map((activity: any) => {
          return (
            <div key={activity.id}>
              <ActivityBox data={activity} />
            </div>
          )
        })
      ) : (
        <div className="flex justify-center">No data to display </div>
      )}
    </div>
  )
}

export default Activity
