import { STALE_MINS } from '@constants/config'

/**
  TODO: use last sync timestamps to determine the stale timer
 if its due for a resync set stale time to 0
 currently no suitable data present on the db
*/
export const getStaleMins = () => {
  return { staleTime: STALE_MINS * 1000 * 60 }
}
