import {
  CohortRollRatesDataResponse,
  OverviewDataResponse,
  RiskDataResponse,
  RisksFilters,
  RollRatesDataResponse,
  VintageDataResponse,
} from '@interfaces/risk'

import apiClient from './client'

class CohortService {
  static async getVintageAnalysis(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<VintageDataResponse>(
        '/cohort/vintage-analysis',
        params
      )

      return data
    }
  }

  static async getRollRates(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<RollRatesDataResponse[]>(
        '/cohort/roll-rates',
        params
      )

      return data
    }
  }

  static async getCohortRollRates(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<CohortRollRatesDataResponse>(
        '/cohort/cohort-roll-rates',
        params
      )

      return data
    }
  }

  static async getOverview(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<OverviewDataResponse>
      >('/indicators/overview-cohort', params)

      return data
    }
  }
}

export default CohortService
