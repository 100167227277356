import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import CollectionByStatusL5 from './collection-by-status'

const CollectionByStatusL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const tabs = [
    {
      label: 'Collection by Status',
      desc: {
        description: 'Payments designated as prepaid, on-time, or late.',
        formula: (
          <span>
            Prepaid = Prepayment. <br />
            On-time = 0 DPD when a payment is made. <br /> Late &ge; 0 DPD when
            a payment is made.
          </span>
        ),
      },
      component: <CollectionByStatusL5 exportable={exportable} />,
    },
  ]

  return (
    <L4TabsLayout
      tabs={tabs}
      additionalIndicator={
        showHistoricalRateIndicator ? <ExchangeRateBadge /> : undefined
      }
    />
  )
}

export default CollectionByStatusL4
