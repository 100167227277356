import React from 'react'
import moment from 'moment'

import { TemplateProps } from '@interfaces/manage-reporting'
import { Document, Page, View } from '@react-pdf/renderer'

import { AutochekHeader, Footer, Table, Text } from '../../../components'
import { styles } from '../../../template_style'

import { generateTableData } from './helpers'

const textOtherStyles = {
  marginVertical: 5,
}

export const LendableComplianceTemplate = ({
  effectiveDate,
  customData,
  formData,
}: TemplateProps) => {
  const tableData = generateTableData(formData, customData)

  return (
    <Document>
      <Page>
        <AutochekHeader fixed={true} />
        <View style={{ ...styles.body, paddingHorizontal: 58 }}>
          <Text size="small" otherStyles={textOtherStyles}>
            To:{'                  '}
            <Text size="small" otherStyles={textOtherStyles}>
              Lendable Asset Management LLC, as the Facility Agent
            </Text>
          </Text>
          <Text size="small" otherStyles={textOtherStyles}>
            Attention:{'       '}
            <Text size="small" otherStyles={textOtherStyles} indent={32}>
              Chief Operating Officer and Head of Legal
            </Text>
          </Text>
          <Text
            otherStyles={{
              ...styles.title,
              fontSize: 10,
              borderBottom: 'none',
              fontFamily: 'Times-Bold',
              marginTop: 10,
            }}
          >
            Compliance Certificate
          </Text>
          <Text size="small" otherStyles={textOtherStyles}>
            Financial Covenant calculation as of:{' '}
            {formData?.['financial-covenant-as-of-date']
              ? moment(formData['financial-covenant-as-of-date']).format(
                  'DD-MM-YYYY'
                )
              : '[date as of]'}
          </Text>
          <Text size="small" otherStyles={textOtherStyles}>
            Porfolio Covenant calculation as of:{' '}
            {effectiveDate || '[date as of]'}
          </Text>
          {tableData.map((table, index) => (
            <Table
              key={`table-${index}`}
              data={table}
              size="small"
              index={`COVENANT ${index + 1}`}
            />
          ))}
          <Text size="small" otherStyles={textOtherStyles}>
            As of the end of the Relevant Period, there have been no changes to:
          </Text>
          <Text size="small">
            •{'    '}the registered address provided to the Facility Agent for
            each Obligor;
          </Text>
          <Text size="small">
            •{'    '}the list of appointed directors provided to the Facility
            Agent for each Obligor;
          </Text>
          <Text size="small">
            •{'    '}the list of Ultimate Beneficial Owners holding {'>'} ten
            per cent. (10%) ownership provided to the Facility Agent for each
            Obligor; or
          </Text>
          <Text size="small">
            the articles of association or memorandum of association of each
            Obligor
          </Text>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}
