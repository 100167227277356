import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'

import Button from '@components/atoms/button'
import { XMarkIcon } from '@heroicons/react/24/solid'

import { remappedHeaders } from '../../../constants'
import { SheetType, Status } from '../../../interfaces/validation.interface'

import Pagination from './pagination-controls'

interface ValidationTableProps {
  data: Record<string, any>
  onClose: () => void
  handleStatus: (s: Status) => void
}

const BalanceSheetValidationTable = ({
  data,
  onClose,
  handleStatus,
}: ValidationTableProps) => {
  const headers = remappedHeaders[SheetType.BALANCE_SHEET]
  const footNote = useRef<HTMLDivElement>(null)
  const [currentData, setCurrentData] = useState<Record<string, any>[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  useEffect(() => setCurrentData(data?.incomingData), [data])

  const handleIgnore = () => {
    handleStatus(Status.PASSED)
    onClose()
  }

  useEffect(() => {
    if (footNote.current) {
      footNote.current.scrollTop = footNote.current.scrollHeight
    }
  }, [footNote])

  return (
    <div className="fixed z-[999] top-0 left-0 h-[calc(100dvh)] w-full overflow-hidden bg-gray-600 bg-opacity-75 flex items-center justify-center">
      <div
        ref={footNote}
        className="p-4 w-full md:w-[75%] max-w-5xl h-[80%] max-h-5xl mx-4 md:mx-0 bg-white rounded-lg shadow-lg overflow-auto"
      >
        <div className="flex justify-between items-center sticky top-0 bg-white rounded-t-lg shadow-md">
          <div className="flex items-center">
            <h2 className="text-2xl font-bold py-4 px-4 ">Balance Sheet</h2>
          </div>
          <XMarkIcon
            onClick={onClose}
            className="h-8 w-8 flex-shrink-0 mr-2 opacity-75 cursor-pointer"
          />
        </div>
        <div className="overflow-auto shadow-md max-h-[70%] mt-4 p-4">
          {currentData.length > 0 && (
            <table className="w-full border-collapse border border-gray-300">
              <thead className="text-sm text-left py-2 bg-gray-100 text-black-500 font-medium">
                <tr>
                  <th className="px-2 py-2 border border-gray-300 ">Names</th>
                  <th className="px-2 py-2 border border-gray-300">Values</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(headers).map(
                  (headerKey: string, index: number) => {
                    const current =
                      headerKey === 'Statement close date'
                        ? moment(
                            currentData[currentIndex][headers[headerKey]]
                          ).format('YYYY-MM-DD')
                        : currentData[currentIndex][headers[headerKey]]
                    const isTotal =
                      headerKey === 'TOTAL LIABILITIES AND EQUITY' ||
                      headerKey === 'TOTAL ASSETS'
                    return (
                      <tr
                        key={index}
                        className={`${
                          isTotal ? 'bg-yellow' : ''
                        } border-b border-gray-300 `}
                      >
                        <td
                          className={` px-2 text-sm font-medium text-black-400 border border-gray-300 w-1/4`}
                        >
                          {headerKey}
                        </td>
                        <td
                          className={`p-2 text-sm font-normal border border-gray-300 w-1/4`}
                        >
                          {current}
                        </td>
                      </tr>
                    )
                  }
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className="flex items-center justify-between px-4 py-2 bg-gray-100 rounded-b-lg shadow-md mt-4 ">
          <>
            <Pagination
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              currentData={currentData}
            />
            <div className="flex gap-2 justify-between">
              <Button
                onClick={handleIgnore}
                size="sm"
                className="text-sm text-white"
                color="primary"
              >
                Leave as is
              </Button>
              <Button
                onClick={onClose}
                size="sm"
                className="text-sm text-black-300 px-4 py-2 rounded-md disabled:bg-gray-500 disabled:cursor-not-allowed"
              >
                Re-upload
              </Button>
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default BalanceSheetValidationTable
