import React from 'react'

import BaseLayout from '@components/layouts/base-layout'

import UserTable from './table'

const UsersPage = () => {
  return (
    <BaseLayout title="User">
      <UserTable isExternal={false} />
      <UserTable isExternal={true} />
    </BaseLayout>
  )
}
export default UsersPage
