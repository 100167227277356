import React, { useContext } from 'react'
import { useMutation } from 'react-query'

import Button from '@components/atoms/button'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { AdvanceRequestResponse } from '@interfaces/manage-monitor-action'
import {
  Alert,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { ActionService } from '@services/api-manage/monitor-action'

interface DialogArchiveAdvanceRequestProps {
  open?: boolean
  handler: (needUpdate?: boolean) => void
  data?: AdvanceRequestResponse
}

const DialogArchiveAdvanceRequest = ({
  open = false,
  handler,
  data,
}: DialogArchiveAdvanceRequestProps) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeDebtDeal = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]
  const _successHandler = () => {
    handler?.(true)
  }

  const {
    mutate: archiveAdvanceRequest,
    isLoading,
    error,
  } = useMutation(
    () => {
      return ActionService.archiveAdvanceRequest(data?.id ?? 0, {
        slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
      })
    },
    {
      onSuccess: _successHandler,
    }
  )

  return (
    <Dialog
      open={open}
      handler={() => handler()}
      className="px-4 py-2"
      size="xs"
    >
      <DialogHeader className="text-xl">Nullify Advance Request</DialogHeader>
      <DialogBody className="text-sm text-black flex flex-col">
        {error && (
          <Alert className="bg-danger-main mb-6">
            {(error as any).message}
          </Alert>
        )}
        {`Are you sure you want to nullify the selected advance request? This cannot be undone!`}
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => handler()} className="mr-2" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={() => archiveAdvanceRequest()}
          className={`bg-danger-hover border border-danger-hover  hover:bg-danger-pressed ${
            isLoading ? '!text-danger-main' : '!text-white'
          }`}
          disabled={isLoading}
        >
          {isLoading && (
            <ArrowPathIcon className="w-4 h-4 mr-2 text-danger-main animate-spin" />
          )}
          Yes, Nullify it
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default DialogArchiveAdvanceRequest
