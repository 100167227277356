import axios from 'axios'

import { ACCESS_TOKEN_STORAGE_KEY } from '@constants/app'

import Sentry from '../sentry/sentry.config'

const CreateClient = (baseURL?: string) => {
  const apiClient = axios.create({
    baseURL,
  })

  apiClient.interceptors.request.use(req => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)

    if (!accessToken) {
      return req
    }

    return {
      ...req,
      headers: {
        ...req.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    }
  })

  apiClient.interceptors.response.use(
    response => response,

    error => {
      const { config } = error

      //Need to nullify Sentry environment temporarily.
      if (process.env.REACT_APP_DSN_SENTRY !== 'null') {
        Sentry.withScope(scope => {
          scope.setExtra('route', error?.response?.data?.route)
          scope.setExtra('url', config?.request?.url)
          scope.setExtra('method', config?.request?.method)
          Sentry.captureException(error)
        })
      }
      return Promise.reject(error)
    }
  )

  return apiClient
}
export default CreateClient
