import React, { useContext, useEffect } from 'react'
import { useState } from 'react'

import FilterCurrency from '@components/filters/filter-currency'
import FilterDebtDeal from '@components/filters/filter-debt-deal'
import FilterDebtStat from '@components/filters/filter-debt-stat'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import AuthContext from '@contexts/auth'

import AccountsL4 from './accounts'
import BorrowingBaseL4 from './borrowing-base'
import EconomicsL4 from './economics'
import FinancialCovenantL4 from './financial-covenant'
import PartiesL4 from './parties'
import PortfolioCovenantL4 from './portfolio-covenant'
import ReportingL4 from './reporting'

const MonitorFacilityDetailsL3 = () => {
  const { company, activeFilters, setAppliedFilters } = useContext(AuthContext)

  const { activeDebtDeal, currency, rate } = activeFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (!filterReady && activeDebtDeal !== undefined && rate) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    activeDebtDeal === 0 &&
    currency === company?.currencies_available?.[0]?.to_currency

  const tabs = [
    { label: 'Parties', component: <PartiesL4 /> },
    { label: 'Economics', component: <EconomicsL4 /> },
    { label: 'Accounts', component: <AccountsL4 /> },
    { label: 'Borrowing Base', component: <BorrowingBaseL4 /> },
    { label: 'Portfolio Covenants', component: <PortfolioCovenantL4 /> },
    { label: 'Financial Covenants', component: <FinancialCovenantL4 /> },
    { label: 'Reporting', component: <ReportingL4 /> },
  ]

  return (
    <L3TabsLayout
      title="Facility Details"
      filters={{
        left: <FilterCurrency />,
        middle: <FilterDebtDeal />,
        right: <FilterDebtStat />,
      }}
      disableReset={disableReset}
      tabs={tabs}
    />
  )
}
export default MonitorFacilityDetailsL3
