import React, { useContext, useState } from 'react'
import { useMutation } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { AuditService } from '@services/api-manage/audit'
import DocumentService from '@services/api-manage/docs'

import ConfirmDialog from '../dialogs/finalise/finalise-dialog'
import GenerateReportDialog from '../dialogs/report-generate/generate-report-dialog'
import { mfpa001 } from '../reports/custom/mfs/mf_pa001'

interface SummaryTableProps {
  auditSummary: any
  auditData: any
  refetch: () => any
  access: string
  auditType: 'Payment' | 'Underwriting'
}

export const SummaryTableWithActions = ({
  auditSummary,
  auditData,
  refetch,
  access,
  auditType,
}: SummaryTableProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] =
    useState<boolean>(false)
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)
  const { activeDebtDeal } = appliedFilters
  const { debtDealOptions } = optionFilters

  const activeFacility = debtDealOptions?.[activeDebtDeal]
  const { user } = useAuth0()
  const filters = {
    slug_name: company?.slug_name,
    type: auditType.toLowerCase(),
    user_id: user?.sub,
    id: auditSummary.audit_id,
    facility: activeFacility?.facility,
  }
  //finalise audit
  const { mutate: finaliseAudit, isLoading: isFinalising } = useMutation(
    () => {
      setIsConfirmDialogOpen(false)
      return AuditService.finaliseAudit(filters)
    },
    {
      onSuccess: () => {
        refetch()
        toast.success('Audit Finalised')
      },
      onError: () => {
        toast.error('An unexpected error occured')
      },
    }
  )

  const { mutate: downloadFile } = useMutation(
    async (docData: any) => {
      toast.loading('Downloading data...')
      return DocumentService.getPresignedUrl(
        JSON.parse(docData.supplementary_data),
        activeFacility?.slug_name ?? company?.slug_name
      )
    },
    {
      onSuccess: res => {
        toast.dismiss()
        window.open(String(res.data), '_blank')
      },
      onError: () => {
        toast.dismiss()
        toast.error('An error occurred while downloading')
      },
    }
  )

  const columns =
    auditType == 'Payment'
      ? [
          { title: 'Audit Item ID', field: 'item_display_id', align: 'center' },
          {
            title: company?.slug_name == 'mfs' ? 'ID' : 'Payment ID',
            field: company?.slug_name == 'mfs' ? 'id' : 'payment_id',
            align: 'center',
          },
          {
            title: company?.slug_name == 'mfs' ? 'Account Number' : 'Loan ID',
            align: company?.slug_name == 'mfs' ? 'account_number' : 'center',
            field: 'loan_id',
          },
          company?.slug_name == 'mfs' && {
            title: 'Currency',
            align: 'center',
            field: 'currency',
          },
          {
            title: company?.slug_name == 'mfs' ? 'Date' : 'Date of Payment',
            field: company?.slug_name == 'mfs' ? 'date' : 'payment_date',
            align: 'center',
          },
          {
            title: company?.slug_name == 'mfs' ? 'Balance' : 'Total Payment',
            align: 'center',
            field: 'payment_total',
            render: (record: any) => {
              return <div>{record.payment_total.toFixed(2)}</div>
            },
          },
          ,
          {
            title: 'Status',
            align: 'center',
            field: 'status',
          },
          {
            title: 'Actions',
            field: '',
            align: 'center',
            render: (record: any) => (
              <Button
                onClick={() => {
                  const newSearchParams = new URLSearchParams(searchParams)
                  newSearchParams.set('itemId', record.item_id)
                  setSearchParams(newSearchParams.toString())
                }}
              >
                {auditSummary.audit_status.toUpperCase() == 'FINALISED'
                  ? 'View'
                  : 'Review'}
              </Button>
            ),
          },
        ]
      : [
          { title: 'Audit Item ID', field: 'item_display_id', align: 'center' },
          {
            title: 'Loan ID',
            align: 'center',
            field: 'loan_id',
          },
          {
            title: 'Date of Disbursed',
            field: 'disbursed_date',
            align: 'center',
          },
          {
            title: 'Disbursed Amount',
            align: 'center',
            field: 'disbursed_principal',
            render: (record: any) => {
              return <div>{record.disbursed_principal.toFixed(2)}</div>
            },
          },
          ,
          {
            title: 'Status',
            align: 'center',
            field: 'status',
          },
          {
            title: 'Actions',
            field: '',
            align: 'center',
            render: (record: any) => (
              <Button
                onClick={() => {
                  const newSearchParams = new URLSearchParams(searchParams)
                  newSearchParams.set('itemId', record.item_id)
                  setSearchParams(newSearchParams.toString())
                }}
              >
                {auditSummary.audit_status.toUpperCase() == 'FINALISED'
                  ? 'View'
                  : 'Review'}
              </Button>
            ),
          },
        ]
  return (
    <div>
      <div className="flex justify-end py-3">
        {!!auditSummary.audit_id && (
          <Button className="mx-2" onClick={() => refetch()}>
            <ArrowPathIcon className={'w-7'} />
          </Button>
        )}
        {access.includes('approve/reject') &&
          auditSummary.audit_status_control == 'FINALISATION REQUIRED' &&
          auditSummary.audit_status != 'FINALISED' &&
          !!auditData.length && (
            <Button
              disabled={isFinalising}
              color="secondary"
              className="mx-2"
              onClick={() => setIsConfirmDialogOpen(true)}
            >
              {isFinalising ? (
                <ArrowPathIcon className="w-5" />
              ) : (
                'Finalise Audit'
              )}
            </Button>
          )}

        {auditSummary.audit_status == 'FINALISED' && access.includes('export') && (
          <Button
            className="mx-2"
            onClick={() =>
              auditSummary.audit_id != mfpa001.audit_id
                ? setIsDownloadDialogOpen(true)
                : downloadFile(mfpa001)
            }
          >
            {auditSummary.audit_id != mfpa001.audit_id
              ? 'Download Report'
              : 'View Report'}
          </Button>
        )}
      </div>
      <div>
        {!!auditData.length ? (
          <>
            <div className="m-5 flex">
              <Typography className="text-sm font-medium my-1 text-left">
                Following {auditType == 'Payment' ? 'payments' : 'loans'} has
                been flagged for this audit and requires reviewing.
              </Typography>
            </div>
            <Table columns={columns as any} data={auditData} loading={false} />
          </>
        ) : (
          <div className="w-full flex justify-center font-light text-sm">
            No data to display
          </div>
        )}
        {isConfirmDialogOpen && (
          <ConfirmDialog
            open={isConfirmDialogOpen}
            handler={() => setIsConfirmDialogOpen(!isConfirmDialogOpen)}
            confirmHandler={finaliseAudit}
          />
        )}
        {isDownloadDialogOpen && auditData && (
          <GenerateReportDialog
            open={isDownloadDialogOpen}
            handler={() => setIsDownloadDialogOpen(!isDownloadDialogOpen)}
            auditDisplayId={auditSummary.audit_display_id}
            auditId={auditSummary.audit_id}
            auditType={auditType}
          />
        )}
      </div>
    </div>
  )
}

export default SummaryTableWithActions
