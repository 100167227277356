import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import { SUPPORT_EMAIL } from '@constants/config'
import AuthContext from '@contexts/auth'
import { pdfDownloader } from '@helpers/file-downloader'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  Alert,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { UserService } from '@services/api-admin/settings-user'
import { AuditService } from '@services/api-manage/audit'

import { generateReport } from '../../reports/helpers/provider'

interface GenerateReportDialogProps {
  auditDisplayId: string
  auditId: string
  auditType: 'Payment' | 'Underwriting'
  open: boolean
  handler: () => void
}

export const GenerateReportDialog = ({
  auditDisplayId,
  auditId,
  auditType,
  open,
  handler,
}: GenerateReportDialogProps) => {
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)
  const { activeDebtDeal } = appliedFilters
  const { debtDealOptions } = optionFilters

  const activeFacility = debtDealOptions?.[activeDebtDeal]
  const { user } = useAuth0()
  const [url, setUrl] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isRetrievingUsers, setIsRetrievingUsers] = useState<boolean>(true)
  const [auditData, setAuditData] = useState<any>(null)
  const [reportDataError, setReportDataError] = useState<any>(null)
  const filters = {
    slug_name: company?.slug_name,
    id: auditId,
    user_id: user?.sub,
    type: auditType.toLowerCase(),
    facility: activeFacility?.facility,
  }

  const getUserName = async (userId: string) => {
    const userName = await UserService.getUserById({ user_id: userId })
    return userName
  }
  //fetch audit summary info
  const { isFetching: isLoadingReportData } = useQuery(
    ['reportData', filters],
    async () => {
      try {
        setIsRetrievingUsers(true)
        const reportDataRes = await AuditService.getReportData(filters)
        const allPromises = []
        if (
          reportDataRes.audit_summary.audit_finalised_on &&
          reportDataRes.audit_summary.report_generated_on
        ) {
          reportDataRes.audit_summary.audit_finalised_on = moment(
            reportDataRes.audit_summary.audit_finalised_on
          ).format('DD-MM-YYYY')
          reportDataRes.audit_summary.report_generated_on = moment(
            reportDataRes.audit_summary.report_generated_on
          ).format('DD-MM-YYYY')
        }
        if (reportDataRes.audit_summary.audit_finalised_by) {
          const auditorPromise = getUserName(
            reportDataRes.audit_summary.audit_finalised_by
          ).then(auditor => {
            reportDataRes.audit_summary.audit_finalised_by = auditor.name
          })
          allPromises.push(auditorPromise)
        }

        const itemPromises = reportDataRes.item_data.map(async (item: any) => {
          const uploadersPromises = item.info.evidence_uploaded_by.map(
            async (userId: string) => {
              const user = await getUserName(userId)
              return user.name
            }
          )

          const reviewersPromises = item.info.reviewed_by.map(
            async (userId: string) => {
              const user = await getUserName(userId)
              return user.name
            }
          )

          const [uploaders, reviewers] = await Promise.all([
            Promise.all(uploadersPromises),
            Promise.all(reviewersPromises),
          ])

          item.info.evidence_uploaded_by = uploaders.join(', ')
          item.info.reviewed_by = reviewers.join(', ')

          return item
        })

        allPromises.push(...itemPromises)

        await Promise.all(allPromises)

        setIsRetrievingUsers(false)
        setAuditData(reportDataRes)

        return reportDataRes
      } catch (error) {
        setReportDataError(error)
        return error
      }
    }
  )

  useEffect(() => {
    if (!isRetrievingUsers && !!auditData) {
      generateReport(
        auditData,
        auditType,
        company?.slug_name,
        activeFacility?.facility
      ).then(url => {
        setIsLoading(false)
        setUrl(url)
      })
    }
  }, [auditData, isRetrievingUsers])

  const isProcessing = isLoading || isLoadingReportData || isRetrievingUsers

  return (
    <Dialog open={open} handler={handler} size="xl" className="h-[90%]">
      <DialogHeader>
        Generate Report for {auditDisplayId}
        <div onClick={() => handler()} className="absolute right-10">
          <XMarkIcon className="w-7 cursor-pointer" onClick={handler} />
        </div>
      </DialogHeader>
      <DialogBody className="flex flex-col h-[80%]">
        <div className="flex h-full w-full">
          {!!reportDataError ? (
            <div className="w-full h-full flex justify-center items-center">
              <Alert className="flex text-center border border-rounded-lg border-danger-main text-danger-main  p-4">
                An Error Occured while generating report. If this error
                persists, please contact us via {SUPPORT_EMAIL}
              </Alert>
            </div>
          ) : isProcessing ? (
            <div className="flex flex-col w-full h-full justify-center items-center">
              <ArrowPathIcon className="animate-spin w-7" />
              <div className="text-xs my-2">Generating report...</div>
            </div>
          ) : (
            <iframe src={url} className="h-full w-full" title="pdf viewer" />
          )}
        </div>
      </DialogBody>
      <DialogFooter className="w-full flex justify-center">
        <Button
          disabled={isLoading}
          color="primary"
          onClick={() =>
            pdfDownloader(
              url,
              `${
                company?.legal_name
              } - ${auditDisplayId} - Audit Report ${moment().format(
                'DD-MM-YYYY'
              )}`
            )
          }
        >
          Download
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default GenerateReportDialog
