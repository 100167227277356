import { useContext } from 'react'

import AuthContext from '@contexts/auth'

export const get_child_routes = (c: any, level: number, path: string) => {
  const current_item = {
    title: c.title,
    level,
    path,
    access: c.access,
    access_external: c.access_external,
  }
  if (!c.routes) {
    return [current_item]
  } else {
    const child = c.routes.reduce(
      (_p: any[], _c: any) => [
        ..._p,
        ...get_child_routes(_c, level + 1, `${path}_${_c.path}`),
      ],
      []
    )
    return [current_item, ...child]
  }
}

export const get_routes_counter = (
  c: any,
  level: number,
  path: string,
  is_external: boolean,
  checked_access: any
) => {
  const data = get_child_routes(c, level, path)

  const is_manage = c.path.includes('manage')
  const { activeFilters, optionFilters } = useContext(AuthContext)
  const { activeDebtDeal = 0 } = activeFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const { total, checked } = data?.reduce(
    ({ total: _total, checked: _checked }, c) => {
      const access = is_external ? c.access_external ?? c.access : c.access
      const access_key = `${is_manage ? `${activeFacility?.facility}_` : ``}${
        c.path
      }`

      return {
        total: _total + (access ?? []).length,
        checked:
          _checked +
          (checked_access?.[access_key]
            ? checked_access?.[access_key].split(',').length
            : 0),
      }
    },
    { total: 0, checked: 0 }
  )

  return { total, checked, data }
}
