import React, { useContext, useEffect, useState } from 'react'
import colormap from 'colormap'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/risk'
import CohortService from '@services/api-analytics/risk-cohort'

const VintageAnalysisL5 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    categoryTypes,
    currency = 'USD',
    activeType,
    historicalRate = {},
  } = appliedFilters
  const { display_rates = [] } = optionFilters

  const [isQuarter, setIsQuarter] = useState<boolean>(false)
  const [dpd, setDPD] = useState<string>('30')
  const [isNumberOfLoan, setIsNumberOfLoan] = useState<boolean>(false)

  const filters: RisksFilters = {
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name,
    filters: categoryTypes,
    is_percentage: true,
    is_quarter: isQuarter,
    val_dpd: dpd,
    is_loans_by_number: isNumberOfLoan,
  }

  const { error, data, isFetching } = useQuery(
    ['riskCohortVintage', filters],
    () => CohortService.getVintageAnalysis(filters),
    getStaleMins()
  )

  const cohort = (data?.data ?? []).reduce(
    (prev: { cohort: string; color: string; cutoff_age: number }[], cur) => {
      const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
      return prev.findIndex(
        (x: { cohort: string }) => x.cohort === cur.cohort
      ) < 0
        ? [...prev, { cohort: cur.cohort, color, cutoff_age: cur.cutoff_age }]
        : prev
    },
    []
  )

  const mob =
    (data?.data ?? []).length > 0
      ? Math.max(...(data?.data ?? []).map(x => x.cutoff_age))
      : 0

  let maxVal = 0
  const chartData = Array(mob)
    .fill('')
    .map((x, i) => {
      const currentMob = (data?.data ?? []).filter(x => x.mob === i + 1)
      const result: { x: number; [key: string]: number | undefined } = {
        x: i + 1,
      }
      cohort.forEach((y: { cohort: string; cutoff_age: number }) => {
        const currentVal = currentMob.find(x => x.cohort === y.cohort)
        const val = currentVal?.outstanding_loans
          ? parseFloat(currentVal?.outstanding_loans.toString())
          : 0

        if (val > maxVal) {
          maxVal = val
        }

        result[moment(y.cohort).format('MMM-YY')] =
          i + 1 <= y.cutoff_age ? parseFloat(val.toString()) : undefined
      })
      return result
    })

  /**
   * https://teams.microsoft.com/l/message/19:59ff850cd40d4120b750654e6c03aab7@thread.tacv2/1663052277011?tenantId=e78b5724-0eb7-42f6-ac40-43b5db198e3b&groupId=df080243-683b-496c-9ba2-e30c892be6c8&parentMessageId=1663052277011&teamName=Cascade%20Team&channelName=Data%20ETL&createdTime=1663052277011&allowXTenantAccess=false
   */
  useEffect(() => {
    const filterDateRange = document?.querySelector('.filter__date-range div')
    filterDateRange?.classList.add('hidden')
    return () => {
      filterDateRange?.classList.remove('hidden')
    }
  }, [])

  const heatmapColors = colormap({
    colormap: 'portland',
    nshades: 101,
    format: 'hex',
    alpha: 1,
  })

  /**
   * zoom
   */
  const xStart = 0
  const xSpan = Math.floor(data?.avgTermInMonth ?? 0) + 5
  const xEnd =
    chartData?.length === 0
      ? 0
      : xSpan >= chartData?.length
      ? chartData?.[chartData?.length - 1]?.x
      : chartData?.[xSpan - (xSpan > 0 ? 1 : 0)].x /
        chartData?.[chartData?.length - 1]?.x

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  return (
    <L5ChartstatLayout
      chart={
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 mb-4 gap-8">
            {[
              {
                title: 'Cohort Size',
                options: [
                  {
                    title: 'Quarter',
                    active: isQuarter,
                    action: () => setIsQuarter(true),
                  },
                  {
                    title: 'Monthly',
                    active: !isQuarter,
                    action: () => setIsQuarter(false),
                  },
                ],
                class: 'invisible',
              },
              {
                title: 'DPD Definition',
                options: [
                  {
                    title: '30DPD',
                    active: dpd === '30',
                    action: () => setDPD('30'),
                  },
                  {
                    title: '90DPD',
                    active: dpd === '90',
                    action: () => setDPD('90'),
                  },
                ],
              },
              {
                title: 'Measurement',
                options: [
                  {
                    title: '# of Loans',
                    active: isNumberOfLoan,
                    action: () => setIsNumberOfLoan(true),
                  },
                  {
                    title: 'Volume',
                    active: !isNumberOfLoan,
                    action: () => setIsNumberOfLoan(false),
                  },
                ],
              },
            ].map((group, i) => (
              <div key={i} className={`flex flex-col ${group.class}`}>
                <span className="mb-2 text-sm font-semibold">
                  {group.title}
                </span>
                <div className="bg-neutral-border-1 rounded-md p-1 flex">
                  {group.options.map((g, j) => (
                    <button
                      key={j}
                      className={`flex-1 text-sm rounded-md px-3 py-0.5 font-semibold ${
                        g.active ? 'bg-secondary-main text-white' : ''
                      }`}
                      onClick={g.action}
                    >
                      {g.title}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <Chart
            loading={isFetching || isProcessing}
            id={`riskCohortVintage_by_${activeType}`}
            yLabel={isNumberOfLoan ? 'Number of Loans' : 'Volume'}
            yFormat="#.00a%"
            xLabel="Month on Books (MoB)"
            xAxisType="CategoryAxis"
            data={chartData}
            series={cohort.map((c: { cohort: string; color: string }) => {
              const label = `${isQuarter ? 'Q' : ''}${moment(c.cohort).format(
                isQuarter ? 'Q YY' : 'MMM YY'
              )}`
              return {
                label,
                tooltipValueFormat: '#.00a%',
                type: 'SmoothedXLineSeries',
                field: moment(c.cohort).format('MMM-YY'),
                color: c.color,
                hasBullet: false,
              }
            })}
            legendSetting={{
              show: true,
              position: 'right',
              config: { width: 124, paddingLeft: 24 },
            }}
            exportable={exportable}
            scroll={{ y: true, x: true, xStart, xEnd }}
            error={error as { message: string }}
          />
          <div
            className={`mt-4 overflow-auto rounded-lg border border-neutral-border-1 max-h-[calc(100vh-100px)]`}
          >
            <table className="border-separate border-spacing-0">
              <thead className="sticky top-0 z-20">
                <tr>
                  <th className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[100px] sticky left-0 bg-neutral-white">
                    Cohort
                  </th>
                  {showHistoricalRateIndicator &&
                    !isNumberOfLoan &&
                    display_rates.map((r: any) => (
                      <th
                        key={r.code}
                        className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[100px] bg-neutral-white"
                      >
                        USD:{r.code}
                      </th>
                    ))}
                  <th className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[100px] sticky left-[100px] bg-neutral-white">
                    {`${isNumberOfLoan ? 'Number of Loans' : 'Volume'}`}
                  </th>
                  {Array(mob)
                    .fill('')
                    .map((_, i) => (
                      <th
                        key={i}
                        className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1"
                      >
                        {i + 1}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {cohort.map((c: { cohort: string; color: string }) => {
                  const rowData = (data?.data ?? []).filter(
                    x => x.cohort === c.cohort
                  )
                  const fx =
                    historicalRate?.[
                      moment.utc(c.cohort).format('YYYY-MM-DD')
                    ] ?? 1
                  return (
                    <tr key={c.cohort}>
                      <td className="p-3 border-r border-b border-neutral-border-1 sticky left-0 bg-neutral-white text-sm text-center bg-neutral-white z-10">
                        {`${isQuarter ? 'Q' : ''}${moment(c.cohort).format(
                          isQuarter ? 'Q YY' : 'MMM YY'
                        )}`}
                      </td>
                      {showHistoricalRateIndicator &&
                        !isNumberOfLoan &&
                        display_rates.map((r: any) => (
                          <td
                            key={r.code}
                            className="p-3 border-r border-b border-neutral-border-1 bg-neutral-white text-sm text-right bg-neutral-white z-10"
                          >
                            {Intl.NumberFormat(undefined, {
                              style: 'decimal',
                              maximumFractionDigits: 2,
                            }).format(
                              r.display_rates?.[
                                moment
                                  .utc(c.cohort)
                                  .endOf('month')
                                  .format('YYYY-MM-DD')
                              ] ?? 1
                            )}
                          </td>
                        ))}
                      <td className="p-3 border-r border-b border-neutral-border-1 sticky left-[100px] bg-neutral-white text-sm text-right bg-neutral-white z-10">
                        {`${Intl.NumberFormat(undefined, {
                          style: 'decimal',
                          maximumFractionDigits: 1,
                          currency,
                          notation: 'compact',
                        }).format(
                          parseFloat(
                            (
                              rowData?.[0]?.[
                                isNumberOfLoan
                                  ? isQuarter
                                    ? 'new_loans_quarterly'
                                    : 'new_loans'
                                  : 'new_loans_amount'
                              ] ?? 0
                            ).toString()
                          ) * (isNumberOfLoan ? 1 : fx)
                        )} ${isNumberOfLoan ? '' : currency}`}
                      </td>
                      {Array(mob)
                        .fill('')
                        .map((_, i) => {
                          const dt = rowData.find(x => x.mob === i + 1)
                          if (i + 1 > (rowData[0]?.cutoff_age ?? 0)) {
                            return (
                              <td key={i} className="bg-neutral-border-1" />
                            )
                          }
                          const val = dt?.outstanding_loans
                            ? parseFloat(dt.outstanding_loans.toString())
                            : 0
                          const valRange =
                            maxVal > 0 ? Math.ceil((val / maxVal) * 100) : 0
                          const backgroundColor = heatmapColors[valRange]

                          return (
                            <td
                              key={i}
                              className={`p-3 border-r border-b border-neutral-border-1 text-sm text-right relative text-white`}
                              style={{ backgroundColor }}
                            >
                              {Intl.NumberFormat(undefined, {
                                style: 'percent',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(
                                parseFloat(
                                  (dt?.outstanding_loans ?? 0).toString()
                                ) / 100
                              )}
                            </td>
                          )
                        })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      }
    />
  )
}

export default VintageAnalysisL5
