import React, { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Typography from '@components/atoms/typography'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
} from '@material-tailwind/react'

interface L4BaseLayoutProps {
  /**
   * L5 tabs for this L4
   */
  tabs: {
    /**
     * Tab label
     */
    label: string
    /**
     * Tab description
     */
    desc?:
      | string
      | {
          description: string | React.ReactNode
          formula: string | React.ReactNode
        }
    /**
     * Tab body component
     */
    component: JSX.Element
  }[]
  /**
   * class applied to tab header
   */
  headerClassName?: string
  /**
   * class applied to tab header
   */
  additionalIndicator?: JSX.Element
}

/**
 * Base layout for L4 that use tab as main layout of its L5s
 * @param {L4BaseLayoutProps} props Component properties
 * @returns JSX
 */
const L4TabsLayout: FC<L4BaseLayoutProps> = ({
  tabs,
  headerClassName,
  additionalIndicator,
}) => {
  const [activeTab, setActiveTab] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const tab2 = searchParams.get('tab2')
  const tabIndex = tabs.findIndex(
    t => t.label.toLowerCase().replaceAll(' ', '-') === tab2
  )

  useEffect(() => {
    const nextActiveTab =
      tabIndex < 0 || tabIndex > tabs.length - 1 ? 0 : tabIndex
    setActiveTab(nextActiveTab)
    // force click
    setTimeout(() => {
      const tabElem = document.querySelector(
        `.l4-tabs-layout [data-value="${tabs[nextActiveTab]?.label}"]`
      ) as HTMLElement

      if (tabElem) {
        tabElem.click()
      }
    }, 250)
  }, [JSON.stringify(tabs.map(x => x.label)), tab2])

  const handleClick = (idx: number, label: string) => {
    setActiveTab(idx)
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set('tab2', label.toLowerCase().replaceAll(' ', '-'))

    setSearchParams(newSearchParams.toString())
  }

  const infoContent = tabs[activeTab]?.desc ? (
    typeof tabs[activeTab]?.desc === 'string' ? (
      tabs[activeTab].desc
    ) : (
      <span>
        Description:
        <br />
        {(tabs[activeTab]?.desc as any).description}
        <br />
        <br />
        Formula:
        <br />
        {(tabs[activeTab]?.desc as any).formula}
      </span>
    )
  ) : undefined
  return (
    <Tabs
      value={tabs[activeTab]?.label}
      className="flex-1 [&>nav]:flex [&>nav]:justify-end l4-tabs-layout"
    >
      <div
        className={`ml-20 lg:ml-15 lg:mr-[188px] flex justify-between  items-center ${headerClassName}`}
      >
        <span className="z-10 flex items-center">
          {tabs[activeTab]?.desc && (
            <Tooltip content={infoContent} placement="right">
              <InformationCircleIcon className="w-8 text-primary-main -ml-10 cursor-help" />
            </Tooltip>
          )}
          {additionalIndicator && (
            <div className="ml-2">{additionalIndicator}</div>
          )}
        </span>
        <TabsHeader className={` ${tabs.length > 1 ? 'flex' : 'hidden'}`}>
          {tabs.map(({ label }, idx) => (
            <Tab
              key={label}
              value={label}
              onClick={() => {
                handleClick(idx, label)
              }}
              className={`${
                activeTab === idx
                  ? '[&>div:nth-child(2)]:!bg-secondary-main [&>div:nth-child(2)]:!opacity-100 text-neutral-white '
                  : 'hover:bg-primary-surface-2'
              } py-0.5 px-8 w-auto min-w-[150px]`}
            >
              <Typography className="text-sm font-medium">{label}</Typography>
            </Tab>
          ))}
        </TabsHeader>
      </div>
      <TabsBody className="mt-4 overflow-visible">
        {tabs.map(({ label, component }) => (
          <TabPanel key={label} value={label} className="p-0">
            {component}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  )
}

export default L4TabsLayout
