import React, { useContext } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { BarChartIcon, LineChartIcon } from '@components/atoms/icons'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline'
import { Filter, FilterItem } from '@interfaces/filter'
import { RisksFilters } from '@interfaces/risk'
import { Card, CardBody } from '@material-tailwind/react'

import BaseLayout, { BaseLayoutProps } from './base-layout'

interface OverviewItem {
  feature: string
  cardTitle: string
  cardLink: string
  icon: string | 'line' | 'bar'
  fetcher?: (params: any) => void
  fetchOption?: {
    [key: string]: any
  }
  formatter?: (val: number) => void
  secondaryFormatter?: (val: number) => void
  indicatorClass?: {
    up: string
    down: string
  }
}

interface OverviewLayoutProps extends BaseLayoutProps {
  data: OverviewItem[]
}

const OverviewItem = ({
  feature,
  cardTitle,
  cardLink,
  icon,
  fetcher,
  fetchOption,
  formatter,
  secondaryFormatter,
  indicatorClass = {
    up: 'bg-green',
    down: 'bg-red',
  },
}: OverviewItem) => {
  const { company, optionFilters } = useContext(AuthContext)

  const endDate = moment.utc(company?.date_end).isAfter(moment())
    ? moment.utc()
    : moment.utc(company?.date_end)
  const { categoryTypeOptions } = optionFilters
  const filters: RisksFilters = {
    date_from: endDate.clone().subtract(1, 'year').format('YYYY-MM-DD'),
    date_to: moment.utc(endDate).format('YYYY-MM-DD'),
    schema_name: company?.schema_name,
    slug_name: company?.slug_name,
    filters: categoryTypeOptions
      ?.find((x: Filter) => x.type_key === 'All')
      ?.type_val?.map((x: FilterItem) => x.id),
    ...fetchOption,
  }

  const { data } = useQuery(
    [feature, filters],
    () => fetcher?.(filters),
    getStaleMins()
  )

  const {
    primaryStat,
    primaryStatLabel,
    secondaryStat,
    secondaryStatChange,
    secondaryStatLabel,
  } = data ?? {
    primaryStat: 0,
    primaryStatLabel: '',
    secondaryStat: 0,
    secondaryStatChange: 0,
    secondaryStatLabel: '',
  }

  const Icon = secondaryStatChange < 0 ? ArrowDownIcon : ArrowUpIcon
  const ChartIcon = icon === 'line' ? LineChartIcon : BarChartIcon
  return (
    <Link to={cardLink}>
      <Card className="hover:bg-gradient-to-r hover:from-primary-main/75 hover:to-secondary-main/75 group">
        <CardBody>
          <Typography className="text-primary-main group-hover:text-neutral-white">
            {cardTitle}
          </Typography>
          <Typography
            variant="h4"
            className="font-semibold mt-8 text-center text-neutral-subtitle-2 group-hover:text-neutral-white"
          >
            {formatter?.(primaryStat) ?? primaryStat}
          </Typography>
          <Typography className="font-medium text-center text-neutral-body-2 group-hover:text-neutral-white">
            {primaryStatLabel || '-'}
          </Typography>
          <div className="flex justify-between items-center mt-6 mx-8">
            <div className="flex flex-col items-start">
              <Typography className="text-xs text-neutral-body-2 group-hover:text-neutral-white">
                {secondaryStatLabel || '-'}
              </Typography>
              <Typography
                variant="h6"
                className="font-bold text-neutral-subtitle-2 group-hover:text-neutral-white"
              >
                {secondaryFormatter
                  ? secondaryFormatter(secondaryStat)
                  : formatter
                  ? formatter(secondaryStat)
                  : secondaryStat}
              </Typography>
              <Typography
                className={`py-1 px-3 flex items-center text-xs font-medium rounded-xl text-neutral-white group-hover:text-neutral-body-2 group-hover:bg-neutral-white ${
                  (secondaryStatChange ?? 0) === 0
                    ? 'bg-neutral-body-1'
                    : (secondaryStatChange ?? 0) < 0
                    ? indicatorClass.down
                    : indicatorClass.up
                }`}
              >
                {(secondaryStatChange ?? 0) !== 0 && (
                  <Icon className="w-3 h-3 mr-1" />
                )}
                {`${Intl.NumberFormat(undefined, {
                  style: 'percent',
                  notation: 'compact',
                }).format((secondaryStatChange ?? 0) / 100)}`}
              </Typography>
            </div>
            <div className="invisible">
              <ChartIcon className="fill-blue group-hover:fill-neutral-white" />
            </div>
          </div>
        </CardBody>
      </Card>
    </Link>
  )
}

const OverviewL2 = ({ data, ...rest }: OverviewLayoutProps) => {
  return (
    <BaseLayout {...rest}>
      <div className="grid gird-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {data.map(d => (
          <OverviewItem key={d.cardLink} {...d} />
        ))}
      </div>
    </BaseLayout>
  )
}

export default OverviewL2
