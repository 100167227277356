export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'dev'
export const API_ANALYTICS_URL = process.env.REACT_APP_API_ANALYTICS_URL
export const API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL
export const API_MANAGE_URL = process.env.REACT_APP_API_MANAGE_URL
export const API_SECONDARY_URL = process.env.REACT_APP_API_SECONDARY_URL
export const API_DATA_VALIDATION_URL =
  process.env.REACT_APP_API_DATA_VALIDATION_URL
export const API_BELVO_FIVETRAN_URL =
  process.env.REACT_APP_API_BELVO_FIVETRAN_URL

// Auth0 configuration
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN
export const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID
export const AUTH_REDIRECT_URI = process.env.REACT_APP_AUTH_REDIRECT_URI
export const AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE
export const AUTH_TOS_TIMESTAMP = process.env.REACT_APP_AUTH_TOS_TIMESTAMP

// Hotjar configuration
export const HJID = process.env.REACT_APP_HJID as unknown as number
export const HJSV = process.env.REACT_APP_HJSV as unknown as number

export const STALE_MINS = process.env.REACT_APP_STALE_MINS as unknown as number
export const REMOUNT_MS = process.env.REACT_APP_REMOUNT_MS as unknown as number

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL
export const MAX_LOANTABLE_SIZE = Number(
  process.env.REACT_APP_MAX_LOANTABLE_SIZE
)

// MAINTENANCE PAGE
export const IS_MAINTENANCE = process.env.REACT_APP_IS_MAINTENANCE

export const DROPBOX_SIGN_CLIENT_ID =
  process.env.REACT_APP_PORTAL_DROPBOX_SIGN_CLIENT_ID
