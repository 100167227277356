import { useRef } from 'react'
import { useMutation } from 'react-query'
import { toast, ToastOptions } from 'react-toastify'

import {
  AccountingRequest,
  CancelValidationParams,
  DeleteEntityRecord,
  DeleteFileParams,
  DownloadFileParams,
  GetQuarantineDataParams,
  SaveRecordParams,
  UpdateCompanyInfo,
  UpdateQuarantineStatusParams,
  UpdateStatementsRecordsParams,
  UploadFileParams,
  ValidateParams,
} from '@interfaces/accounting'
import AccountingService from '@services/api-admin/data-source-accounting'

import { ACTIONS_TYPES } from './shared/actions'
import { useCustomContext } from './shared/local-context'
import {
  CLOSE_TOASTY,
  ERROR_UPLOAD,
  FAILED_CREATE_ENTITY,
  FAILED_DELETE_ENTITY,
  FAILED_DELETE_FILE,
  FAILED_UPDATE_ENTITY,
  SUCCESS_CREATE_ENTITY,
  SUCCESS_DELETE_ENTITY,
  SUCCESS_DELETE_FILE,
} from './constants'

const toastParams: ToastOptions = {
  position: 'top-right',
  autoClose: CLOSE_TOASTY,
  className: 'font-semibold text-sm whitespace-pre-line',
}

const payload = (message: string, type: string, data?: []) => {
  const payload = {
    reload: false,
    message: message,
    isOpenAlert: true,
    data: data,
  }
  return { type: type, payload: payload }
}

const setType = (hasFiles: boolean) => {
  return hasFiles
    ? ACTIONS_TYPES.OPEN_VALIDATION_MODAL
    : ACTIONS_TYPES.FETCH_SUCCESS
}

export const deleteFile = async (params: DeleteFileParams) => {
  try {
    const res = await AccountingService.deleteFile(params)
    if (res) {
      toast.success(res?.data?.message || SUCCESS_DELETE_FILE, toastParams)
    }
  } catch (error) {
    return error && toast.error(FAILED_DELETE_FILE, toastParams)
  }
}

export const downloadFile = async (params: DownloadFileParams) => {
  try {
    const res = await AccountingService.downloadFile(params)
    return res
  } catch (error) {
    return FAILED_DELETE_ENTITY
  }
}

export const useDeleteFile = () => {
  const { dispatch } = useCustomContext()
  const { mutate: deleteFile, isSuccess } = useMutation(
    (params: DeleteFileParams) => {
      return AccountingService.deleteFile(params)
    },
    {
      onSuccess: (res: any) => {
        dispatch(
          payload(
            res?.message || SUCCESS_DELETE_FILE,
            ACTIONS_TYPES.FETCH_SUCCESS
          )
        )
        return res && toast.success(res || SUCCESS_DELETE_FILE, toastParams)
      },

      onError: (error: any) => {
        dispatch(payload(FAILED_DELETE_FILE, ACTIONS_TYPES.FETCH_ERROR))
        return (
          error &&
          toast.error(
            error?.response?.data.error || FAILED_DELETE_FILE,
            toastParams
          )
        )
      },
    }
  )
  return { deleteFile, isSuccess }
}

export const useAddNewCompany = () => {
  const { dispatch } = useCustomContext()
  const { emailStatement } = useEmailStatement()
  // define params outside of useMutation hook
  const paramsRef = useRef<AccountingRequest>()
  const { mutate: addNewCompany, isLoading: isNewCompanyLoading } = useMutation(
    (params: AccountingRequest) => {
      paramsRef.current = params || undefined
      return AccountingService.addNewCompany(params)
    },
    {
      onSuccess: (res: any) => {
        toast['success'](
          res?.data?.message || SUCCESS_CREATE_ENTITY,
          toastParams
        )
        const hasFiles = (paramsRef?.current?.files ?? []).length > 0
        hasFiles &&
          emailStatement({
            slug_name: paramsRef.current?.slug_name ?? '',
            files: paramsRef?.current?.files ?? [],
            entityName: paramsRef?.current?.entityName ?? '',
          })
        return (
          res &&
          dispatch({
            type: setType(hasFiles),
            payload: {
              validation: {
                entityId: paramsRef.current?.entityId,
                entitySlugName: paramsRef.current?.entitySlugName,
              },
              openValidationModal: hasFiles,
            },
          })
        )
      },
      onError: (error: any) => {
        dispatch(
          payload(
            error?.response?.data.error || FAILED_CREATE_ENTITY,
            ACTIONS_TYPES.FETCH_ERROR
          )
        )
        return error && toast.error(FAILED_CREATE_ENTITY, toastParams)
      },
    }
  )
  return { addNewCompany, isNewCompanyLoading }
}

export const useEditCompany = () => {
  const { dispatch } = useCustomContext()
  const paramsRef = useRef<UpdateCompanyInfo>()
  const { emailStatement } = useEmailStatement()
  const { mutate: editCompany, isLoading: isUpdatingLoading } = useMutation(
    (params: UpdateCompanyInfo) => {
      paramsRef.current = params || ''
      return AccountingService.editCompany(params)
    },
    {
      onSuccess: (res: any) => {
        const hasFiles = (paramsRef?.current?.files ?? []).length > 0

        toast['success']('Updated Entity Details')
        hasFiles &&
          emailStatement({
            slug_name: paramsRef.current?.slug_name ?? '',
            files: paramsRef?.current?.files ?? [],
            entityName: paramsRef?.current?.entityName ?? '',
          })
        return (
          res &&
          dispatch({
            type: setType(hasFiles),
            payload: {
              validation: {
                entityId: paramsRef.current?.entityId,
                entitySlugName: paramsRef.current?.entitySlugName,
              },
              openValidationModal: true,
              reload: hasFiles ? true : false,
              modalPayload: {
                isOpenModal: false,
              },
            },
          })
        )
      },
      onError: (error: any) => {
        dispatch(payload(FAILED_UPDATE_ENTITY, ACTIONS_TYPES.FETCH_ERROR))
        return error && toast.error(FAILED_UPDATE_ENTITY, toastParams)
      },
    }
  )
  return { editCompany, isUpdatingLoading }
}

export const useDeleteEntityRecords = () => {
  const { dispatch } = useCustomContext()
  const { mutate: deleteEntityRecords } = useMutation(
    (params: DeleteEntityRecord) => {
      return AccountingService.deleteEntityRecords(params)
    },
    {
      onSuccess: (res: any) => {
        dispatch(
          payload(res || SUCCESS_DELETE_ENTITY, ACTIONS_TYPES.FETCH_SUCCESS)
        )
        return res && toast.success(res || SUCCESS_DELETE_ENTITY, toastParams)
      },
      onError: (error: any) => {
        dispatch(payload(FAILED_DELETE_ENTITY, ACTIONS_TYPES.FETCH_ERROR))
        return error && toast.error(FAILED_DELETE_ENTITY, toastParams)
      },
    }
  )
  return { deleteEntityRecords }
}

export const useUploadStatement = () => {
  const { dispatch } = useCustomContext()
  const paramsRef = useRef<UploadFileParams>()
  const { emailStatement } = useEmailStatement()
  const { mutate: uploadOnlyStatement, isLoading } = useMutation(
    (params: UploadFileParams) => {
      paramsRef.current = params
      return AccountingService.uploadOnlyStatement(params)
    },
    {
      onSuccess: () => {
        emailStatement({
          slug_name: paramsRef.current?.slug_name ?? '',
          files: paramsRef?.current?.files ?? [],
          entityName: paramsRef?.current?.entityName ?? '',
        })
        return dispatch({
          type: ACTIONS_TYPES.OPEN_VALIDATION_MODAL,
          payload: {
            validation: {
              entityId: paramsRef.current?.id,
              entitySlugName: paramsRef.current?.entitySlugName,
            },
            openValidationModal: true,
            openDropZone: false,
            modalPayload: {
              isOpenModal: false,
            },
          },
        })
      },
      onError: (error: any) => {
        dispatch(payload(ERROR_UPLOAD, ACTIONS_TYPES.FETCH_ERROR))
        return error && toast.error(ERROR_UPLOAD, toastParams)
      },
    }
  )
  return { uploadOnlyStatement, isLoading }
}

export const useValidate = () => {
  const { dispatch } = useCustomContext()
  const { mutate, isLoading } = useMutation(
    (params: ValidateParams) => {
      return AccountingService.validate(params)
    },
    {
      onSuccess: (res: any) => {
        return res
      },
      onError: (error: any) => {
        dispatch(payload('Failed to validate', ACTIONS_TYPES.FETCH_ERROR))
        return error && toast.error('Failed to validate', toastParams)
      },
    }
  )
  const validate = (params: ValidateParams): Promise<any> => {
    return new Promise(resolve => {
      mutate(params, {
        onSuccess: data => {
          resolve(data)
        },
      })
    })
  }

  return { validate, isLoading }
}

export const useCancelValidation = () => {
  const { dispatch } = useCustomContext()
  const { mutate: cancelValidation, isLoading } = useMutation(
    (params: CancelValidationParams) => {
      return AccountingService.cancelValidation(params)
    },
    {
      onSuccess: (res: any) => {
        dispatch({
          type: ACTIONS_TYPES.OPEN_VALIDATION_MODAL,
          payload: {
            openValidationModal: false,
            reload: true,
          },
        })

        return res && toast['success'](res)
      },
      onError: (error: any) => {
        dispatch(
          payload(error?.response?.data.error, ACTIONS_TYPES.FETCH_ERROR)
        )
        return error && toast.error('Error canceling validation')
      },
    }
  )
  return { cancelValidation, isLoading }
}

export const useGetCurrentQuarantineData = () => {
  const { dispatch } = useCustomContext()
  const { mutate } = useMutation(
    async (params: GetQuarantineDataParams) => {
      return await AccountingService.getCurrentQuarantineData(params)
    },
    {
      onSuccess: (res: any) => {
        return res
      },
      onError: (error: any) => {
        dispatch(
          payload(error?.response?.data.error, ACTIONS_TYPES.FETCH_ERROR)
        )
        return error && toast.error('Error canceling validation')
      },
    }
  )
  const getCurrentQuarantineData = (
    params: GetQuarantineDataParams
  ): Promise<any> => {
    return new Promise(resolve => {
      mutate(params, {
        onSuccess: data => {
          resolve(data)
        },
      })
    })
  }
  return { getCurrentQuarantineData }
}

export const useUpdateStatementsRecords = () => {
  const { dispatch } = useCustomContext()
  const { mutate: updateStatementsRecords } = useMutation(
    (params: UpdateStatementsRecordsParams) => {
      return AccountingService.updateStatementRecord(params)
    },
    {
      onSuccess: (res: any) => {
        return res && toast.success(res || 'Statement updated successfully')
      },
      onError: (error: any) => {
        dispatch(payload(FAILED_UPDATE_ENTITY, ACTIONS_TYPES.FETCH_ERROR))
        return error && toast.error(FAILED_UPDATE_ENTITY, toastParams)
      },
    }
  )
  return { updateStatementsRecords }
}

export const useUpdateQuarantineStatus = () => {
  const { dispatch } = useCustomContext()
  const { mutate } = useMutation(
    (params: UpdateQuarantineStatusParams) => {
      return AccountingService.updateQuarantineStatus(params)
    },
    {
      onSuccess: (res: any) => {
        return res
      },
      onError: (error: any) => {
        dispatch(payload(FAILED_UPDATE_ENTITY, ACTIONS_TYPES.FETCH_ERROR))
        return error && toast.error(FAILED_UPDATE_ENTITY, toastParams)
      },
    }
  )
  const updateQuarantineStatus = (
    params: UpdateQuarantineStatusParams
  ): Promise<any> => {
    return new Promise(resolve => {
      mutate(params, {
        onSuccess: data => {
          resolve(data)
        },
      })
    })
  }

  return { updateQuarantineStatus }
}

export const useSaveStatement = () => {
  const { dispatch } = useCustomContext()
  const { mutate } = useMutation(
    (params: SaveRecordParams) => {
      return AccountingService.saveStatementRecords(params)
    },
    {
      onSuccess: (res: any) => {
        return (
          res && toast['success'](res || 'Statement record saved successfully')
        )
      },
      onError: (error: any) => {
        dispatch(
          payload('Error saving statement records', ACTIONS_TYPES.FETCH_ERROR)
        )
        return error && toast.error('Error saving statement records')
      },
    }
  )
  const saveStatementRecords = (params: SaveRecordParams): Promise<any> => {
    return new Promise(resolve => {
      mutate(params, {
        onSuccess: data => {
          resolve(data)
        },
      })
    })
  }
  return { saveStatementRecords }
}

export const useUploadSourceFile = () => {
  const { dispatch } = useCustomContext()
  const { mutate: uploadSourceFile } = useMutation(
    (params: UploadFileParams) => {
      return AccountingService.uploadSourceFile(params)
    },
    {
      onSuccess: (res: any) => {
        dispatch({
          type: ACTIONS_TYPES.FETCH_SUCCESS,
          payload: {
            reload: true,
          },
        })
        return res && toast['success'](res || 'File uploaded successfully')
      },
      onError: (error: any) => {
        dispatch({
          type: ACTIONS_TYPES.FETCH_ERROR,
        })
        return (
          error &&
          toast['error']('Failed to upload source file, please try again.')
        )
      },
    }
  )
  return { uploadSourceFile }
}

export const useEmailStatement = () => {
  //This hook is used to send email statement only when the user uploads a file;
  const { mutate: emailStatement } = useMutation(
    (params: { slug_name: string; files: Blob[]; entityName: string }) => {
      return AccountingService.emailStatementRecords(params)
    },
    {
      onSuccess: (res: any) => {
        return res
      },
      onError: (error: any) => {
        return error && "Couldn't send email statement"
      },
    }
  )

  return { emailStatement }
}
