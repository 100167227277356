import React, { useContext } from 'react'
import moment from 'moment'
import { useQueries, useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import { Part } from '@components/table/type'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { CovenantType } from '@interfaces/covenant'
import { CovenantService } from '@services/api-manage/covenant'

import { getMultiplier, getUnit } from '../../covenant/helper'

export const useCovenantDashboard = (type: CovenantType, enabled = true) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)

  const { activeDebtDeal } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility,
    covenant_type: type,
  }

  const { data, isFetching } = useQuery(
    ['covenant-list', filters],
    () => CovenantService.getList(filters),
    {
      ...getStaleMins(),
      enabled:
        !!debtDealOptions?.[activeDebtDeal]?.facility &&
        !!company?.slug_name &&
        enabled,
    }
  )

  const covenantsQueries = useQueries(
    (data ?? []).map(c => {
      const filters = {
        slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
        covenant_name: c?.[`${type}_covenant`],
        facility: c.facility,
        covenant_type: type,
      }
      return {
        queryKey: ['covenant-detail-list', filters],
        queryFn: () => CovenantService.getDetail(filters),
        ...getStaleMins(),
        enabled: (data ?? []).length > 0,
      }
    })
  )
  const isFetchingDetail = covenantsQueries.find(x => x.isFetching)
  const [minCohort, maxCohort] = covenantsQueries.reduce(
    (p: (string | undefined)[], c) => {
      const { data: detailData } = c
      let [min, max] = p
      if (
        detailData?.[0]?.cohort &&
        (!min || moment(detailData?.[0]?.cohort).isBefore(moment(min), 'month'))
      ) {
        min = detailData?.[0]?.cohort
      }

      if (
        detailData?.[detailData?.length - 1]?.cohort &&
        (!max ||
          moment(detailData?.[detailData?.length - 1]?.cohort).isAfter(
            moment(max)
          ))
      ) {
        max = detailData?.[detailData?.length - 1]?.cohort
      }

      return [min, max]
    },
    [undefined, undefined]
  )
  const min =
    activeFacility?.agreement_date &&
    moment(activeFacility?.agreement_date).isAfter(moment(minCohort))
      ? moment(activeFacility?.agreement_date).add(1, 'month').endOf('month')
      : moment(minCohort)
  const numberOfCohort = moment(maxCohort).diff(min, 'months') + 1

  const cohortColumns = Array(numberOfCohort < 0 ? 0 : numberOfCohort)
    .fill('')
    .map((_, i) => {
      const cohort = moment(min).add(i, 'month').endOf('month')
      const field = cohort.format('YYYY-MM-DD')

      return {
        title: cohort.format('MMM-YY'),
        field,
        align: 'right',
        head: { align: 'center' },
        className: 'min-w-[100px]',
        render: (r: any, ir: number, part: Part) => {
          return typeof r[field] !== 'undefined'
            ? part === 'body'
              ? Intl.NumberFormat(undefined, {
                  notation: 'compact',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(r[field] * getMultiplier(r.covenants?.[0], type)) +
                getUnit(r.covenants?.[0], type)
              : r[field]
            : 'N/A'
        },
        renderCSV: (r: any) => {
          return typeof r[field] !== 'undefined'
            ? typeof r[field] === 'number'
              ? Intl.NumberFormat(undefined, {
                  notation: 'compact',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(r[field] * getMultiplier(r.covenants?.[0], type)) +
                getUnit(r.covenants?.[0], type)
              : r[field]
            : 'N/A'
        },
        props: (r: any) => {
          const TRIAL_PERIOD_DAYS = parseFloat(
            (r.covenants?.[0]?.test_day ?? 60).toString()
          )
          const chartLastDate = moment.utc().subtract(TRIAL_PERIOD_DAYS, 'days')

          return {
            className: `${cohort} ${chartLastDate} ${
              typeof r[field] !== 'undefined'
                ? cohort.isAfter(chartLastDate)
                  ? '!bg-neutral-border-1'
                  : r[`${field}_error`]
                  ? '!bg-danger-surface !text-danger-main'
                  : '!bg-success-surface !text-success-main'
                : ''
            }`,
          }
        },
      }
    })

  let maxTriggers = 0
  const list = (data ?? [])
    .reduce((p: any[], c, idx) => {
      const { data: detailData } = covenantsQueries[idx]
      const covenants = [c]
      if (c[`${type}_covenant`].includes('Default Base')) {
        const maxFirstKey = c[`${type}_covenant`]
          ?.split(' Default Base')
          .join('')
          .trim()
        const maxFirst = data?.find(x => x[`${type}_covenant`] === maxFirstKey)
        if (maxFirst) {
          covenants.push(maxFirst)
        }
      }

      if (Number(c.triggers) > maxTriggers) {
        maxTriggers = Number(c.triggers)
      }

      const isInverted = Number(covenants?.[0]?.invert_colors ?? '0') === 1

      const triggersData: { [key: string]: any }[] =
        Number(c.triggers) > 0
          ? Array(Number(c.triggers))
              .fill('')
              .map((_, i) => ({
                title: `Trigger ${i + 1}`,
                ...cohortColumns.reduce((p, c) => {
                  const detail = detailData?.find(
                    x => moment(x.cohort).format('YYYY-MM-DD') === c.field
                  )
                  return {
                    ...p,
                    [c.field]: detail?.[`trigger_${i + 1}`],
                    [`${c.field}_error`]: isInverted
                      ? (detail?.covenant_value_1 ?? 0) <
                        detail?.[`trigger_${i + 1}`]
                      : (detail?.covenant_value_1 ?? 0) >
                        detail?.[`trigger_${i + 1}`],
                  }
                }, {}),
              }))
          : []

      const newData = {
        title: c[`${type}_covenant`],
        covenants,
        triggersData,
        detailData,
        whole_title: `${c[`${type}_covenant`]} .`,
        ...cohortColumns.reduce((p, c) => {
          const detail = detailData?.find(
            x => moment(x.cohort).format('YYYY-MM-DD') === c.field
          )
          const isTriggerBreached =
            triggersData.filter(td => td[`${c.field}_error`])?.length > 0

          return {
            ...p,
            [c.field]: detail?.covenant_value_1,
            [`${c.field}_error`]: isTriggerBreached,
          }
        }, {}),
      }

      return [...p, newData]
    }, [])
    .sort((a, b) => (a.whole_title < b.whole_title ? -1 : 1))

  const triggerColumns = Array(Number(maxTriggers))
    .fill('')
    .map((_, i) => ({
      title: `Trigger ${i + 1}`,
      field: `trigger_${i + 1}`,
      className: 'min-w-[100px]',
      align: 'right',
      head: { align: 'center' },
      render: (r: any) => {
        const triggerData = r.triggersData?.[i]
        const key = Object.keys(triggerData ?? {}).reduce(
          (_p: any, _c) =>
            moment(_c).isValid() &&
            (!_p || moment(_c).isAfter(moment(_p))) &&
            triggerData[_c]
              ? _c
              : _p,
          undefined
        )

        const value = r.triggersData?.[i]?.[key]
        return (
          <Typography className={`text-sm`}>
            {value
              ? Intl.NumberFormat(undefined, {
                  notation: 'compact',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(value * getMultiplier(r.covenants?.[0], type)) +
                getUnit(r.covenants?.[0], type)
              : 'N/A'}
          </Typography>
        )
      },
      renderCSV: (r: any) => {
        const value =
          r.triggersData?.[i]?.[cohortColumns[cohortColumns.length - 1]?.field]
        return value
          ? Intl.NumberFormat(undefined, {
              notation: 'compact',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(value * getMultiplier(r.covenants?.[0], type)) +
              getUnit(r.covenants?.[0], type)
          : 'N/A'
      },
      props: (r: any) => {
        const triggerData = r.triggersData?.[i]
        const isBreached = !!Object.keys(triggerData ?? {}).find(
          x => x.includes('error') && triggerData[x] === true
        )

        return {
          className: `${
            isBreached
              ? '!bg-danger-surface !text-danger-main'
              : triggerData
              ? '!bg-success-surface !text-success-main'
              : ''
          }`,
        }
      },
    }))

  const columns = [
    {
      title: `${type} Covenant`,
      field: 'title',
      className: 'min-w-[250px] max-w-[250px] sticky left-0',
      render: (r: any) => (
        <Typography
          className={`text-sm ${
            r.title.toLowerCase().includes('trigger') ? 'pl-8' : ''
          }`}
        >
          {r.title}
        </Typography>
      ),
      renderCSV: (r: any) =>
        `${r.title.toLowerCase().includes('trigger') ? '    ' : ''}${r.title}`,
    },
    ...triggerColumns,
    ...cohortColumns,
  ]

  const footerData: { [key: string]: any } = {
    title: 'Compliant',
    covenants: [{ test_day: 0 }],
    ...cohortColumns.reduce((p, c) => {
      const isUndefined = list.find(l => typeof l[c.field] === 'undefined')
      const isError = list.find(l => l[`${c.field}_error`])
      return {
        ...p,
        [c.field]: !!isUndefined ? undefined : !!isError ? 'No' : 'Yes',
        [`${c.field}_error`]: isError,
      }
    }, {}),
  }

  return { isFetching, isFetchingDetail, columns, list, footerData }
}
