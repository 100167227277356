import React, { Fragment } from 'react'
import moment from 'moment'

import { useUserAccessFeature } from '@helpers/auth-provider'
import { CloudArrowUpIcon, PencilIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { AccountingInfo } from '@interfaces/accounting'

import { getMetaData } from '../helper'

import AccountAccordionBody from './table-level-two'

interface TableStatementsProps {
  data: AccountingInfo[]
  openModalEdit: (params: AccountingInfo) => void
  expandRow: string
  handleOpen: (s: string) => void
  company: Record<string, any>
  openFileUpload: (companyInfo: AccountingInfo, bucketName: string) => void
}

const TableStatements: React.FC<TableStatementsProps> = ({
  data,
  openModalEdit,
  expandRow,
  handleOpen,
  company,
  openFileUpload,
}) => {
  const { get_access } = useUserAccessFeature()
  const feature = `administration_sources_accounting`
  const can_edit = get_access(feature, 'edit')
  return (
    <>
      <div className="h-max relative">
        <div className="pl-12 text-sm p-3 md:text-sm grid grid-cols-5 font-semibold text-neutral-subtitle-3 py-5 items-left bg-neutral-surface-1 border-b border-neutral-border-2 rounded-t-md  w-full h-auto">
          <div>Entity Legal Name</div>
          <div>Entity Nickname</div>
          <div>Data Source</div>
          <div>Status</div>
          {can_edit && <div> </div>}
        </div>
        {data?.map((dataRow: AccountingInfo, idx: number) => {
          const isActive =
            moment().diff(moment(company?.date_sync, 'YYYY-MM-DD'), 'days') < 30
          return (
            <Fragment key={`${dataRow.entityId}-${idx}`}>
              <div
                className={`${
                  expandRow === dataRow.entityId
                    ? 'bg-secondary-surface cursor-default'
                    : ' cursor-default'
                }
                   text-base  md:text-md font-light grid grid-cols-5 pl-12 text-left w-full p-2 py-4  border-neutral-border-2 transition-all ease-in duration-300 overflow-hidden items-center`}
              >
                <ChevronRightIcon
                  className={`${
                    expandRow === dataRow.entityId ? 'rotate-90 stroke-red' : ''
                  } absolute left-5 h-4 w-4 transition-transform cursor-pointer`}
                  onClick={() => handleOpen(dataRow.entityId)}
                />

                <div className="leading-none text-sm md:text-sm ">
                  {dataRow.entityName}
                </div>
                <div className="leading-none text-sm md:text-sm ">
                  {dataRow.entitySlugName}
                </div>
                <div>{dataRow.source}</div>
                <div>
                  {
                    <span
                      className={`border rounded-full px-2 py-1 text-xs ${
                        isActive
                          ? 'border-success-main text-success-main bg-primary-success-hover'
                          : 'border-primary-main text-primary-main bg-primary-surface-2'
                      }`}
                    >
                      {isActive ? 'Active' : 'Inactive'}
                    </span>
                  }
                </div>
                {can_edit && (
                  <div className="flex justify-end gap-8 item-end pr-4">
                    <PencilIcon
                      className="stroke-blue hover:opacity-50 w-5"
                      onClick={() => {
                        openModalEdit({
                          ...dataRow,
                          updatedAt: moment().format('YYYY-MM-DD'),
                          consolidated: true,
                          bucketName:
                            getMetaData(dataRow, 'bucket_name') ||
                            `${company?.slug_name}-cascade`,

                          slug_name: company?.slug_name,
                        })
                      }}
                    />
                    <CloudArrowUpIcon
                      className="stroke-blue hover:opacity-50 w-6 "
                      onClick={() => {
                        const bucketName =
                          getMetaData(dataRow, 'bucket_name') ||
                          `${company?.slug_name}-cascade`
                        openFileUpload(dataRow, bucketName)
                      }}
                    />
                  </div>
                )}
              </div>
              <div
                className={`bg-[#fef6fa] px-6 
                     
                       border-t-2 border-b-0
                       rounded-b-md
                            font-semibold text-neutral-subtitle-3
                            overflow-hidden
                            transform origin-top
                            transition-all ease-out 
                            ${
                              expandRow === dataRow.entityId
                                ? 'scale-y-100'
                                : 'scale-y-0'
                            }
                             `}
              >
                {expandRow === dataRow.entityId && (
                  <AccountAccordionBody
                    data={dataRow.statements}
                    key={dataRow.entityId}
                    isExpanded={expandRow === dataRow.entityId}
                  />
                )}
              </div>
            </Fragment>
          )
        })}
      </div>
    </>
  )
}

export default TableStatements
