import React from 'react'

import { AdvanceRequestTemplateProps } from '@interfaces/manage-signature'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { Footer } from '../../document-centre/templates/components'

import { styles } from './template-style'

const AdvanceRequestTemplate = ({
  percentOfAvailable,
  agent,
  borrower,
  guarantors,
  requestDate,
  advanceDate,
  loanAgreementDate,
  formData,
  bankInfo,
  lenderRatios = [],
  originationFee,
}: AdvanceRequestTemplateProps) => {
  const formatCurrency = (amount: number) => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount)
  }

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>Advance Request</Text>
        <Text style={styles.text}>{requestDate || '[Date of Request]'}</Text>
        <Text style={styles.text}>{agent || '[Agent]'}</Text>
        <Text style={styles.text}>Reference is made to:</Text>
        <Text style={styles.text}>
          Loan and Security Agreement, dated:{' '}
          {loanAgreementDate || '[Date of Agreement]'} (as amended, restated,
          supplemented or otherwise modified from time to time), the “Loan
          Agreement”
        </Text>
        <Text style={styles.text}>Among :</Text>
        <Text style={styles.text}>
          the Borrower: {borrower || '[Borrower]'}
        </Text>
        <Text style={styles.text}>
          the Guarantor:{' '}
          {guarantors && guarantors.length
            ? guarantors.join(' and ')
            : `[Intentionally left blank]`}
        </Text>
        <Text style={styles.text}>
          the Lenders: the financial institutions or entities from time to time
          defined therein
        </Text>
        <Text style={styles.text}>the Agent: {agent || `[Agent]`}</Text>
        <Text style={styles.text}>
          Capitalized terms used but not defined herein have the meanings
          specified in the Loan Agreement.
        </Text>
        <Text style={{ ...styles.text, textIndent: 32 }}>
          The undersigned is an officer of the Borrower, knowledgeable of all of
          the Obligors’ financial matters, is authorized to provide this
          request, and on behalf of, the Borrower, and hereby certifies in such
          capacity, that:
        </Text>
        <Text style={styles.textNumbered}>
          1. The Borrower hereby request that on {advanceDate || `[date]`} (the
          “Advance Date”) the funding of the following Advance (the “Requested
          Amount”){' '}
          {formData?.amount ? formatCurrency(formData?.amount) : `[value]`}.
        </Text>
        <Text style={styles.textNumbered}>
          2. The Requested Amount represents{' '}
          {percentOfAvailable ? `${percentOfAvailable} ` : `[x%] `}
          of the outstanding commitment from the Lender(s).
        </Text>
        <Text style={styles.textNumbered}>
          3. The Borrower hereby gives notice of the Requested Amount to the
          Agent (who shall forward such request to the Lenders) pursuant to the
          Loan Agreement and requests that the Lenders remit, or cause to be
          remitted, their respective pro rata portions of such Requested Amounts
          to the following account:
        </Text>
        <View style={styles.tableWrapper}>
          <View wrap={false} style={styles.tableView}>
            <View style={styles.table}>
              <View style={styles.tableBody}>
                {bankInfo.intermediaryBank && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Intermediary Bank</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.intermediaryBank}
                      </Text>
                    </View>
                  </View>
                )}
                {bankInfo.intermediaryBankAddress && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Bank Address</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.intermediaryBankAddress}
                      </Text>
                    </View>
                  </View>
                )}
                {bankInfo.intermediarySwift && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>SWIFT</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.intermediarySwift}
                      </Text>
                    </View>
                  </View>
                )}
                {(bankInfo.intermediaryBank ||
                  bankInfo.intermediaryBankAddress ||
                  bankInfo.intermediarySwift) && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}> </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell} />
                    </View>
                  </View>
                )}
                {bankInfo.recipientName && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Recipient Name</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.recipientName}
                      </Text>
                    </View>
                  </View>
                )}
                {bankInfo.recipientAddress && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Recipient Address</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.recipientAddress}
                      </Text>
                    </View>
                  </View>
                )}
                {bankInfo.recipientBankName && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Recipient Bank Name</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.recipientBankName}
                      </Text>
                    </View>
                  </View>
                )}
                {bankInfo.recipientBankAccountNumber && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        Recipient Bank Account Number
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.recipientBankAccountNumber}
                      </Text>
                    </View>
                  </View>
                )}
                {bankInfo.recipientSwiftNumber && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        Recipient SWIFT Number
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.recipientSwiftNumber}
                      </Text>
                    </View>
                  </View>
                )}
                {bankInfo.recipientBankAddress && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        Recipient Bank Address
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.recipientBankAddress}
                      </Text>
                    </View>
                  </View>
                )}
                {bankInfo.beneficiaryBankName && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        Beneficiary Bank Name
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.beneficiaryBankName}
                      </Text>
                    </View>
                  </View>
                )}
                {bankInfo.beneficiaryBankAddress && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        Beneficiary Bank Address
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.beneficiaryBankAddress}
                      </Text>
                    </View>
                  </View>
                )}
                {bankInfo.beneficiarySwift && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        Beneficiary SWIFT Number
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.beneficiarySwift}
                      </Text>
                    </View>
                  </View>
                )}
                {bankInfo.aac && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        Beneficiary Bank Account Number
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{bankInfo.aac}</Text>
                    </View>
                  </View>
                )}
                {bankInfo.aba && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>ABA Routing Number</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{bankInfo.aba}</Text>
                    </View>
                  </View>
                )}
                {bankInfo.wireRoutingNumber && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Wire Routing Number</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.wireRoutingNumber}
                      </Text>
                    </View>
                  </View>
                )}
                {bankInfo.achRoutingNumber && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>ACH Routing Number</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {bankInfo.achRoutingNumber}
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.textNumbered}>
          4. As of the date hereof, the Borrower certifies that immediately
          prior to and after giving effect to the proposed Advance on the
          Advance Date each of the applicable conditions’ precedent set forth in
          the Loan Agreement are satisfied; and
        </Text>
        <View style={styles.tableWrapper}>
          <View wrap={false} style={styles.tableView}>
            <View style={styles.table}>
              <View style={styles.tableBody}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Advance Request Amount</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellRightAligned}>
                      {formData?.amount
                        ? formatCurrency(formData?.amount)
                        : '[X]'}
                    </Text>
                  </View>
                </View>
                {lenderRatios.map((ratio, index) => (
                  <View key={`lender-ratio-${index}`} style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {`Lender ${index + 1} Pro Rata Amount`}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCellRightAligned}>
                        {formData?.amount && ratio
                          ? formatCurrency(formData?.amount * ratio)
                          : '[X]*[Lender 1 %]'}
                      </Text>
                    </View>
                  </View>
                ))}
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      Borrower Equity Contribution
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellRightAligned}>
                      {typeof originationFee === 'number' &&
                      !isNaN(originationFee)
                        ? formatCurrency(originationFee)
                        : '([X]/Advance Rate)-[X]'}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View wrap={false}>
          <Text style={styles.textNumbered}>
            5. The Borrower certifies that:
          </Text>
          <Text style={styles.textLettered}>
            {`(a)      no Default has occurred and is continuing;`}
          </Text>
          <Text style={styles.textLettered}>
            {`(b)      no Default will result from the making of the Advance;`}
          </Text>
          <Text style={styles.textLettered}>
            {`(c)      the representations contained in this Advance Request are true,
            complete and accurate in all respects and are not misleading in any
            material matter on the date of this Advance Request and on the
            proposed Advance Date; and`}
          </Text>
        </View>
        <Text style={styles.textNumbered}>
          6. The Borrower confirms the Advance Request is irrevocable.
        </Text>
        <View wrap={false}>
          <Text style={styles.text}>Sincerely,</Text>
          <Text style={styles.text}>{borrower || `[Borrower]`}</Text>
          <Text style={styles.text}>
            {`Authorized Officer Signature:      `}
            <Text style={styles.signTags}>[sig|req|signer1|signature]</Text>
          </Text>
          <Text style={styles.text}>
            Authorized Officer Name:{'      '}
            {formData?.signers?.[0]?.name || `[Authorized Officer Name]`}
          </Text>
          <Text style={styles.text}>
            Authorized Officer Title:{'        '}
            {formData?.signers?.[0]?.title || `[Authorized Officer Title]`}
          </Text>
          <Text style={styles.text}>
            Date Signed:{`                           `}
            <Text style={styles.signTags}>
              [date|req|signer1|signature_date]
            </Text>
          </Text>
        </View>
        <View>
          <Text style={styles.disclosure}>Disclosure Notice</Text>
          <Text style={styles.text}>
            {formData?.disclosure ?? '[Intentionally left blank]'}
          </Text>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}

export default AdvanceRequestTemplate
