import { CategoriesResult } from '@interfaces/manage-reporting'

import { useLendableComplianceCertData } from './autochek/useLendableComplianceCertData'
import { useFsHsbcComplianceCertData } from './fssec/useFsHsbcComplianceCertData'
import { useFSVNCustomData } from './fsvn/fsvn-custom-data-hook'
import { useMfsComplianceCertCustomData } from './mfs/mfs-compliance-cert-custom-data-hook'

export const getCustomData = (
  category: CategoriesResult,
  effectiveDate?: string
): [boolean, any] => {
  switch (category.category) {
    case 'Borrowing Base Certificate': {
      switch (category.subcategory) {
        case 'fsvn-alteriq':
          return useFSVNCustomData(category.facility)

        default:
          return [false, {}]
      }
    }

    case 'Compliance Certificate': {
      switch (category.subcategory) {
        case 'autochek-lendable': {
          return useLendableComplianceCertData(effectiveDate ?? '')
        }

        case 'fs-hsbc': {
          return useFsHsbcComplianceCertData(effectiveDate ?? '')
        }

        case 'mfs-promissory':
        case 'mfs-norsad':
        case 'mfs-lendable':
        case 'mfs': {
          return useMfsComplianceCertCustomData(
            category.facility,
            effectiveDate
          )
        }

        default:
          return [false, {}]
      }
    }

    case 'Financial Covenant Certificate': {
      switch (category.subcategory) {
        case 'fsvn-alteriq':
          return useFSVNCustomData(category.facility)

        default:
          return [false, {}]
      }
    }
    case 'Portfolio Covenant Certificate': {
      switch (category.subcategory) {
        case 'fsvn-alteriq':
          return useFSVNCustomData(category.facility)

        default:
          return [false, {}]
      }
    }
    case 'Unaudited Financial Statements': {
      switch (category.subcategory) {
        default:
          return [false, {}]
      }
    }

    default:
      return [false, {}]
  }
}
