import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  addressContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  addressRow: {
    fontSize: 8,
    fontFamily: 'Times-Roman',
    color: '#000000',
  },

  companyName: {
    fontSize: 8,
    fontFamily: 'Times-Bold',
    color: '#000000',
    marginBottom: 10,
  },

  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 36,
    marginBottom: 24,
    backgroundColor: '#ffffff',
  },

  image: {
    width: 100,
    paddingBottom: 12,
    marginBottom: 16,
    borderBottom: '4px solid #feef00',
  },

  imageContainer: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
