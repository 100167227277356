import React, { useState } from 'react'

import Table from '@components/table'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react'

import { BORROWING_BASE_DETAILS_MOCK, BORROWING_BASE_MOCK } from './mock'

const BorrowingBaseL4 = () => {
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([])

  const handleOpen = (index: number): void => {
    const indexes = expandedIndexes.includes(index)
      ? expandedIndexes.filter(x => x !== index)
      : [...expandedIndexes, index]
    setExpandedIndexes(indexes)
  }

  return (
    <div className="flex flex-col p-4">
      <Table
        columns={[
          { title: 'Advance Rate', field: 'advance_rate' },
          { title: 'Multiplied by:', field: 'multiplied_by' },
          { title: 'Divided by FX Rate', field: 'divided_by_fx_rate' },
          { title: 'Included', field: 'included' },
        ]}
        data={BORROWING_BASE_MOCK}
      />
      {BORROWING_BASE_DETAILS_MOCK.map((item, i) => {
        const isOpen = expandedIndexes.includes(i)
        const Icon = isOpen ? ChevronDownIcon : ChevronRightIcon
        return (
          <Accordion
            key={i}
            open={isOpen}
            icon={<span></span>}
            className="border-t border-neutral-border"
          >
            <AccordionHeader
              onClick={() => handleOpen(i)}
              className="text-lg border-0 justify-start"
            >
              <Icon className="w-6 h-6 mr-2" />
              <span className="text-left">{item.title}</span>
            </AccordionHeader>
            <AccordionBody>
              <Table columns={item.columns} data={item.data} />
            </AccordionBody>
          </Accordion>
        )
      })}
    </div>
  )
}

export default BorrowingBaseL4
