import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import PrepaymentL5 from './prepayment'

const PRRRL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const tabs = [
    {
      label: 'Prepayment',
      desc: {
        description:
          'The percentage of the disbursed principal that is prepaid.',
        formula:
          'At each date snapshot: Total Prepayment / Disbursed Principal',
      },
      component: <PrepaymentL5 exportable={exportable} />,
    },
  ]

  return (
    <L4TabsLayout
      tabs={tabs}
      additionalIndicator={
        showHistoricalRateIndicator ? (
          <ExchangeRateBadge isHistorical={false} />
        ) : undefined
      }
    />
  )
}

export default PRRRL4
