import React, { useState } from 'react'
import Dropzone from 'react-dropzone'

import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ArrowUpOnSquareStackIcon } from '@heroicons/react/24/solid'

import {
  DEFAULT_MAX_FILE_SIZE,
  DEFAULT_MAX_FILE_SIZE_TEXT,
  DEFAULT_MAX_NUMBER_OF_FILES,
} from './constants'
import { FilesUploaderProps } from './interface'

const FilesUploadDropZone = ({
  isLoading,
  uploader,
  handleCancel,
  children,
  fileTypes,
  fileTypeText,
  maxFiles,
  maxSize,
  titleMaxFilesSize,
  containerClass,
  isHintHidden,
}: FilesUploaderProps) => {
  const [clearErrors, setClearErrors] = useState<boolean>(false)
  const fileSize = maxSize ?? DEFAULT_MAX_FILE_SIZE
  return (
    <div className="relative w-full">
      <div className="flex flex-col gap-6">
        <Dropzone
          maxFiles={maxFiles ?? DEFAULT_MAX_NUMBER_OF_FILES}
          maxSize={fileSize}
          accept={
            fileTypes ?? {
              'application/xlsx': ['.xlsx'],
            }
          }
          multiple={!!maxFiles && maxFiles > 1}
          onDrop={acceptedFiles => uploader(acceptedFiles)}
        >
          {({ getRootProps, getInputProps, fileRejections, acceptedFiles }) => {
            const isFileTooLarge =
              fileRejections.length > 0 &&
              fileRejections[0].file.size > fileSize
            const isOverMaxNumberOfFiles =
              fileRejections.length > 0 && fileRejections.length > 10
            const isWrongFileExtension =
              fileRejections.length > 0 &&
              fileRejections[0].file.name.split('.').pop() !== 'xlsx'
            return (
              <section
                className={
                  containerClass ??
                  'relative top-[35%] border-dashed border-2 border-neutral-border-1 rounded-lg cursor-pointer  bg-[#f5f5f5] h-48'
                }
              >
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="flex text-xs items-center justify-center gap-4 absolute w-full h-full ">
                    {acceptedFiles != null && acceptedFiles.length > 0 ? (
                      isLoading ? (
                        <div className="p-3">
                          <ArrowPathIcon className="animate-spin w-6 h-6 text-primary-main" />
                        </div>
                      ) : (
                        <>
                          {!isFileTooLarge && !isOverMaxNumberOfFiles && (
                            <>
                              <span className="text-sm">
                                Files selected <></>
                                {acceptedFiles.length}
                              </span>
                              <XMarkIcon
                                onClick={handleCancel}
                                className="w-5 h-5 cursor-pointer hover:opacity-50 -mt-4 -ml-1 "
                              />
                            </>
                          )}
                        </>
                      )
                    ) : (
                      <>
                        {!isFileTooLarge &&
                          !isOverMaxNumberOfFiles &&
                          !isWrongFileExtension && (
                            <>
                              <ArrowUpOnSquareStackIcon className="w-6 h-6" />
                              <span className="flex-inline">
                                <span className="font-bold text-[#54b3e0] text-sm">
                                  {!maxFiles || maxFiles > 1
                                    ? 'Choose Files'
                                    : 'Choose File'}
                                </span>
                                <></>{' '}
                                {!maxFiles || maxFiles > 1
                                  ? 'or drop files here'
                                  : 'or drop file here'}
                              </span>
                            </>
                          )}
                      </>
                    )}

                    {((!clearErrors && isFileTooLarge) ||
                      isOverMaxNumberOfFiles ||
                      isWrongFileExtension) && (
                      <div className="relative flex flex-col-reverse items-end">
                        <div className="text-red">
                          {isFileTooLarge &&
                            `'File is too large:'${fileRejections[0].file.name}`}

                          {isOverMaxNumberOfFiles &&
                            `Maximum files Allowed ${
                              maxFiles ?? `1`
                            } - Files Selected: ${fileRejections.length}`}
                          {isWrongFileExtension &&
                            `Wrong file extension. Only ${
                              fileTypeText ? fileTypeText : 'XLSX'
                            } files allowed.`}
                        </div>

                        <div className="">
                          <XMarkIcon
                            onClick={() => {
                              handleCancel && handleCancel()
                              setClearErrors(true)
                            }}
                            className="w-5 h-5 cursor-pointer hover:opacity-50"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            )
          }}
        </Dropzone>

        {!isHintHidden && (
          <div className="my-4 text-xs text-left">
            <span className="font-bold">{maxFiles ?? 1} file(s) maximum.</span>{' '}
            Only {''}
            <span className="font-bold">
              {fileTypeText ? fileTypeText : 'XLSX'}
            </span>{' '}
            files allowed. Max size file{' '}
            <span className="font-bold">
              {titleMaxFilesSize ?? DEFAULT_MAX_FILE_SIZE_TEXT}
            </span>
            .
          </div>
        )}
        {children ? children : null}
      </div>
    </div>
  )
}

export default FilesUploadDropZone
