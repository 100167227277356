import React, { useContext, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import Button from '@components/atoms/button'
import FilterFinancialDisplay from '@components/filters/filter-financial-display'
import FormInput from '@components/form/form-input'
import AuthContext from '@contexts/auth'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { FinancialsFilters, StatementResponse } from '@interfaces/financial'
import { SignatureRequest } from '@interfaces/manage-signature'
import { TemplateDialogProps } from '@interfaces/manage-signature'
import { Dialog, DialogBody } from '@material-tailwind/react'
import { pdf } from '@react-pdf/renderer'
import { PDFViewer } from '@react-pdf/renderer'
import { StatementService } from '@services/api-analytics/financials-statement'
import { FacilityDetailsService } from '@services/api-manage/facility-details'

import { SignerInput } from '../components'
import { getCustomData } from '../custom-data'

import { renderTemplate } from './templates'

const UnauditedFinancialStatements = ({
  form,
  user,
  createEmbeddedSignRequest,
  uploadDocument,
  handler,
  dialogOpen,
  category,
  docData,
}: TemplateDialogProps) => {
  const { company, activeFilters, optionFilters } = useContext(AuthContext)
  const [agent, setAgent] = useState<string>('')
  const [borrower, setBorrower] = useState<string>('')
  const [guarantors, setGuarantors] = useState<string>('')
  const [priorToDate, setPriorToDate] = useState<Moment | null>(
    docData?.effective_date ? moment(docData.effective_date) : null
  )
  const {
    activeDebtDeal,
    financialEntity,
    financialEntityCurrency,
    currency,
    rate,
  } = activeFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const signers = category?.signers ? JSON.parse(category.signers) : undefined

  const [isLoadingCustomData, customData] = getCustomData(category)

  const currencyOptions = {
    default: financialEntityCurrency,
    code: currency,
    ratePrimary:
      financialEntityCurrency == company?.currencies_available?.[0]?.to_currency
        ? { code: currency, rate: rate }
        : optionFilters.rates?.find(
            (r: { code: string; rate: number }) =>
              r.code == financialEntityCurrency
          ) ?? { code: financialEntityCurrency, rate: 1 },
    rateSecondary:
      financialEntityCurrency == company?.currencies_available?.[0]?.to_currency
        ? optionFilters.rates?.find(
            (r: { code: string; rate: number }) =>
              r.code == financialEntityCurrency
          )
        : optionFilters.rates?.find(
            (r: { code: string; rate: number }) => r.code == currency
          ) ?? { code: financialEntityCurrency, rate: 1 },
  }

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = form

  const { mutate: getPartyDetails, isLoading: isLoadingPartyDetails } =
    useMutation(
      (params: { facility: string; slug_name: string }) => {
        return FacilityDetailsService.getDealPartyDetailsRequest(params)
      },
      {
        onSuccess: data => {
          const guarantorsString = data
            .filter(entry => entry.type === 'Guarantor')
            .map(entry => entry.legal_name)
            .join(' and ')
          setGuarantors(guarantorsString)
          const borrowerValue = data.filter(
            entry => entry.type === 'Borrower'
          )[0].legal_name
          setBorrower(borrowerValue)
          const agentValue = data.filter(entry => entry.type === 'Agent')[0]
            .legal_name
          setAgent(agentValue)
        },
      }
    )

  const filters: FinancialsFilters = {
    date_from: priorToDate
      ? moment(priorToDate).subtract(1, 'months').format('YYYY-MM-DD')
      : moment().subtract(1, 'months').format('YYYY-MM-DD'),
    date_to: priorToDate
      ? moment(priorToDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
    entity_id: financialEntity,
    slug_name: company?.slug_name ?? '',
  }

  const emptyResponse = {
    cols: [],
    data: [],
    download_url: '',
  } as unknown as StatementResponse

  // Get Balance Sheet Data
  const {
    data: balanceSheetTableData = emptyResponse,
    isFetching: isLoadingBalanceSheet,
  } = useQuery(
    ['balanceSheet', filters],
    () => StatementService.getBalanceSheet(filters),
    {
      enabled: !!financialEntity,
    }
  )

  // Get P&L Data
  const { data: pnlTableData = emptyResponse, isFetching: isLoadingPnL } =
    useQuery(['pnl', filters], () => StatementService.getPnL(filters), {
      enabled: !!financialEntity,
    })

  const onSubmit = (cd: any) => {
    if (!!signers?.signers && !signers.signers.length) {
      uploadDoc(cd)
    } else {
      createSignableDoc(cd)
    }
  }

  const uploadDoc = async (cd: any) => {
    const formData = new FormData()
    if (activeFacility?.slug_name || company?.slug_name) {
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name
      )
    }
    formData.append('effective_date', moment(priorToDate).format('DD-MM-YYYY'))
    formData.append('category', 'Unaudited Financial Statements')
    formData.append(
      'title',
      `Unaudited Financial Statement Compliance Certificate - ${
        priorToDate
          ? moment(priorToDate).format('DD-MM-YYYY')
          : moment().format('DD-MM-YYYY')
      }`
    )
    formData.append('facility', debtDealOptions?.[activeDebtDeal]?.facility)
    user?.email && formData.append('author_email', `${user.email}`)
    user?.name && formData.append('author_name', `${user.name}`)
    signers?.saveCalculatedData &&
      formData.append('calculated_data', JSON.stringify(customData))
    formData.append('form_values', JSON.stringify(cd))
    formData.append(
      'files',
      await pdf(
        renderTemplate(category, {
          agent,
          borrower,
          guarantors,
          agreementDate: activeFacility?.agreement_date
            ? moment.utc(activeFacility.agreement_date).format('DD-MM-YYYY')
            : '',
          balanceSheetTableData,
          pnlTableData,
          currency,
          currencyOptions,
          customData,
          formData: cd,
        })
      ).toBlob()
    )

    uploadDocument(formData)
    handler()
    toast.loading('Request in progress', { autoClose: false })
    reset()
  }

  const createSignableDoc = async (cd: any) => {
    const formData = new FormData()
    user?.email && formData.append('requester_email', `${user.email}`)
    user?.name && formData.append('requester_name', `${user.name}`)
    if (activeFacility?.slug_name || company?.slug_name) {
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name
      )
    }
    activeFacility?.facility &&
      formData.append('facility', activeFacility.facility)
    docData.id && formData.append('id', docData.id)
    formData.append('category', 'Unaudited Financial Statements')
    formData.append(
      'title',
      `Unaudited Financial Statement Compliance Certificate - ${
        priorToDate
          ? moment(priorToDate).format('DD-MM-YYYY')
          : moment().format('DD-MM-YYYY')
      }`
    )
    formData.append('effective_date', moment(priorToDate).format('DD-MM-YYYY'))
    formData.append(
      'files',
      await pdf(
        renderTemplate(category, {
          agent,
          borrower,
          guarantors,
          agreementDate: activeFacility?.agreement_date
            ? moment.utc(activeFacility.agreement_date).format('DD-MM-YYYY')
            : '',
          balanceSheetTableData,
          pnlTableData,
          currency,
          currencyOptions,
          customData,
          formData: cd,
        })
      ).toBlob()
    )
    formData.append(
      'subject',
      `Unaudited Financial Statement Compliance Certificate`
    )
    formData.append(
      'message',
      `${user?.name} has requested your signature via Cascade Debt`
    )
    formData.append(
      'signers',
      JSON.stringify(
        cd.signers.map(
          (s: { name: string; email: string; title?: string }, i: number) => ({
            name: s.name,
            order: i,
            emailAddress: s.email,
          })
        )
      )
    )
    signers?.saveCalculatedData &&
      formData.append('calculated_data', JSON.stringify(customData))
    formData.append('form_values', JSON.stringify(cd))
    formData.append('tags', 'true')
    createEmbeddedSignRequest(formData as SignatureRequest)
    handler()
    toast.loading('Request in progress', { autoClose: false })
    reset()
  }

  useEffect(() => {
    dialogOpen &&
      !!activeFacility.facility &&
      company?.slug_name &&
      getPartyDetails({
        facility: activeFacility?.facility,
        slug_name: activeFacility?.slug_name ?? company.slug_name,
      })
  }, [dialogOpen])

  const isLoading =
    isLoadingPartyDetails ||
    isLoadingBalanceSheet ||
    isLoadingPnL ||
    isLoadingCustomData

  return (
    <Dialog
      open={dialogOpen}
      size={'xl'}
      dismiss={{ enabled: false }}
      handler={handler}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody className="flex flex-row h-[80vh] overflow-hidden relative">
          <XMarkIcon
            className="absolute right-3 w-7 h-7 cursor-pointer"
            onClick={handler}
          />
          {isLoading ? (
            <div className="flex flex-col justify-center items-center w-full h-full">
              <ArrowPathIcon className="w-10 h-10 mx-20 text-primary-main animate-spin" />
            </div>
          ) : !pnlTableData?.cols?.length ||
            !balanceSheetTableData?.cols?.length ? (
            <div className="flex flex-col justify-center items-center w-full h-full">
              <div className="text-2xl font-bold text-center">
                No{' '}
                {!balanceSheetTableData?.cols?.length && 'balance sheet data'}
                {!balanceSheetTableData?.cols?.length &&
                  !pnlTableData?.cols?.length &&
                  ' or '}
                {!pnlTableData?.cols?.length && 'profit and loss data'}{' '}
                available
              </div>
            </div>
          ) : (
            <PDFViewer showToolbar={false} className="h-full w-full">
              {renderTemplate(category, {
                agent,
                borrower,
                guarantors,
                agreementDate: activeFacility?.agreement_date
                  ? moment
                      .utc(activeFacility.agreement_date)
                      .format('DD-MM-YYYY')
                  : '',
                balanceSheetTableData,
                pnlTableData,
                currency,
                currencyOptions,
                customData,
                formData: getValues(),
              })}
            </PDFViewer>
          )}
          <div className="m-5 w-1/3 overflow-auto">
            <div className="flex flex-col content-between">
              <div className="font-bold text-lg pb-5">
                Fill in below fields to generate document
              </div>
              <FilterFinancialDisplay />
              <FormInput
                type="date"
                label={{ start: 'For the month prior to' }}
                value={priorToDate}
                onSelected={val => setPriorToDate(val)}
                error={errors?.date?.message as string}
              />
              <FormInput
                type="text"
                label={{ start: 'Target' }}
                value={getValues('target') || ''}
                {...register('target', {
                  required: 'Target is required',
                  disabled: isSubmitting,
                  onChange: (e: any) => {
                    setValue('target', e.target.value, {
                      shouldValidate: true,
                    })
                  },
                })}
                error={errors?.date?.message as string}
              />
              <SignerInput user={user} form={form} signers={signers} />
              {!signers?.hideDisclosure && (
                <FormInput
                  type="textarea"
                  label={{ start: 'Material Contingencies Report' }}
                  value={getValues('disclosure')}
                  {...register('disclosure', {
                    disabled: isSubmitting,
                    onChange: (e: any) => {
                      setValue('disclosure', e.target.value, {
                        shouldValidate: true,
                      })
                    },
                  })}
                  error={errors?.disclosure?.message as string}
                />
              )}
              <div className="flex flex-row w-full justify-end my-3">
                <Button
                  disabled={!isValid}
                  type="submit"
                  color="primary"
                  className="w-full"
                >
                  {isLoading ? (
                    <ArrowPathIcon className="w-4 h-4 mx-20 text-primary-main animate-spin" />
                  ) : (
                    `Prepare Signature Request`
                  )}
                </Button>
              </div>
            </div>
          </div>
        </DialogBody>
      </form>
    </Dialog>
  )
}

export default UnauditedFinancialStatements
