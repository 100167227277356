import {
  FinancialKeyIndicatorsDataResponse,
  FinancialsFilters,
} from '@interfaces/financial'
import { OverviewDataResponse } from '@interfaces/risk'

import apiClient from './client'

export class FinancialKeyIndicatorService {
  static async getNetIncome(params: FinancialsFilters) {
    if (params?.entity_id) {
      const { data } = await apiClient.post<FinancialKeyIndicatorsDataResponse>(
        '/financial-indicators/net-income',
        params
      )

      return data
    }
  }

  static async getCash(params: FinancialsFilters) {
    if (params?.entity_id) {
      const { data } = await apiClient.post<FinancialKeyIndicatorsDataResponse>(
        '/financial-indicators/cash',
        params
      )

      return data
    }
  }
  static async getNetWorth(params: FinancialsFilters) {
    if (params?.entity_id) {
      const { data } = await apiClient.post<FinancialKeyIndicatorsDataResponse>(
        '/financial-indicators/net-worth',
        params
      )

      return data
    }
  }

  static async getRunway(params: FinancialsFilters) {
    if (params?.entity_id) {
      const { data } = await apiClient.post<FinancialKeyIndicatorsDataResponse>(
        '/financial-indicators/runway',
        params
      )

      return data
    }
  }

  static async getDebtEquityRatio(params: FinancialsFilters) {
    if (params?.entity_id) {
      const { data } = await apiClient.post<FinancialKeyIndicatorsDataResponse>(
        '/financial-indicators/debt-to-equity',
        params
      )

      return data
    }
  }

  static async getOverview(params: any) {
    if (params?.entity_id) {
      const { data } = await apiClient.post<OverviewDataResponse>(
        '/financial-indicators/overview',
        params
      )

      return data
    }
  }
}
