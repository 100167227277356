import { SignatureRequest } from '@interfaces/manage-signature'

import manageApiClient from './client'

class SignatureService {
  static async createSignatureRequest(signatureRequestInfo: SignatureRequest) {
    const formData = new FormData()
    formData.append('signers', JSON.stringify(signatureRequestInfo.signers))
    formData.append(
      'ccEmailAddresses',
      JSON.stringify(signatureRequestInfo.cc_email_addresses)
    )
    signatureRequestInfo.title &&
      formData.append('title', signatureRequestInfo.title)
    signatureRequestInfo.subject &&
      formData.append('subject', signatureRequestInfo.subject)
    signatureRequestInfo.message &&
      formData.append('message', signatureRequestInfo.message)
    signatureRequestInfo.files &&
      formData.append('files', signatureRequestInfo.files[0])

    return await manageApiClient.post(`signature/email-request`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    })
  }

  static async createEmbeddedSignatureURL(
    signatureRequestInfo: SignatureRequest
  ) {
    return await manageApiClient.post(
      `signature/embedded-request`,
      signatureRequestInfo,
      {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
    )
  }

  static async createEmbeddedSignature(signatureRequestInfo: SignatureRequest) {
    return await manageApiClient.post(
      `signature/embedded-request-plain`,
      signatureRequestInfo,
      {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
    )
  }

  static async getEmbeddedSignatureURL(signature_id: string) {
    return await manageApiClient.get(
      `signature/embedded-request-plain/${signature_id}`
    )
  }

  static async getClaimUrl(id: string, slug_name?: string) {
    return await manageApiClient.post(
      `signature/claim-url`,
      { slug_name, signature_request_id: id },
      {}
    )
  }

  static async getDownloadUrl(id: string, slug_name?: string) {
    return await manageApiClient.post(
      `signature/download-url`,
      { slug_name, signature_request_id: id },
      {}
    )
  }
  static async getdataUri(id: string, slug_name?: string) {
    return await manageApiClient.post(
      `signature/data-uri`,
      { slug_name, signature_request_id: id },
      {}
    )
  }

  static async resendSignatureRequest(params: {
    id: string
    slug_name: string
  }) {
    return await manageApiClient.post(`signature/resend-request`, params, {})
  }
}

export default SignatureService
