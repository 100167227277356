import React from 'react'
import moment from 'moment'

interface StatusLabelProps {
  statusText: string
  effectiveDate: string
  isComplete: boolean // Needed to know if signing is actually complete when there is more than one signer
}

const StatusLabel = ({
  statusText,
  effectiveDate,
  isComplete,
}: StatusLabelProps) => {
  const renderStatus = () => {
    switch (true) {
      case ['overdue'].includes(statusText): {
        return (
          <span className="py-0.5 px-4 text-danger-main border border-danger-main rounded-full bg-danger-surface text-xs">
            OVERDUE
          </span>
        )
      }

      case ['signature_request_downloadable'].includes(statusText) &&
        !isComplete:
      case [
        'signature_request_sent',
        'awaiting_signature',
        'signature_request_viewed',
        'created',
      ].includes(statusText): {
        return (
          <span className="py-0.5 px-4 text-warning-main border border-warning-main rounded-full bg-warning-surface text-xs">
            Awaiting signature
          </span>
        )
      }

      case ['due', 'upcoming'].includes(statusText) &&
        moment(effectiveDate) > moment(): {
        return (
          <span className="py-0.5 px-4 text-indigo border border-indigo rounded-full text-xs">
            Upcoming
          </span>
        )
      }

      case ['due'].includes(statusText): {
        return (
          <span className="py-0.5 px-4 text-secondary-main border border-secondary-main rounded-full text-xs">
            Due
          </span>
        )
      }

      default: {
        return (
          <span className="py-0.5 px-4 text-primary-main border border-primary-main rounded-full bg-primary-surface-1 text-xs">
            Completed
          </span>
        )
      }
    }
  }

  return <div className="flex justify-center">{renderStatus()}</div>
}

export default StatusLabel
