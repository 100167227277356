import React from 'react'

import { CategoriesResult, TemplateProps } from '@interfaces/manage-reporting'

import { LendableComplianceTemplate } from './autochek/lendable-compliance-template'
import ComplianceCertTemplate from './compliance-template'
import FssecHsbcComplianceCertTemplate from './fssec'
import {
  MfsLendableComplianceCertTemplate,
  MfsMicroComplianceCertTemplate,
  MfsNorsadComplianceCertTemplate,
  MfsPromissoryComplianceCertTemplate,
} from './mfs'

export const renderTemplate = (
  category: CategoriesResult,
  templateProps: TemplateProps
) => {
  switch (category.subcategory) {
    case 'autochek-lendable': {
      return <LendableComplianceTemplate {...templateProps} />
    }

    case 'fs-hsbc': {
      return <FssecHsbcComplianceCertTemplate {...templateProps} />
    }

    case 'mfs': {
      return <MfsMicroComplianceCertTemplate {...templateProps} />
    }

    case 'mfs-lendable': {
      return <MfsLendableComplianceCertTemplate {...templateProps} />
    }

    case 'mfs-promissory': {
      return <MfsPromissoryComplianceCertTemplate {...templateProps} />
    }

    case 'mfs-norsad': {
      return <MfsNorsadComplianceCertTemplate {...templateProps} />
    }

    case 'default':
    default: {
      return <ComplianceCertTemplate {...templateProps} />
    }
  }
}
