import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { LayoutFilter } from '@interfaces/filter'
import { Option, Select } from '@material-tailwind/react'
import { CollateralService } from '@services/api-data-validation/data-quarantine-collateral'

const FilterCollateralData = () => {
  const { company, activeFilters, setActiveFilters } = useContext(AuthContext)

  const { collateral } = activeFilters
  const { id, status } = collateral ?? {}

  const params = {
    schema_name: company?.schema_name,
    slug_name: company?.slug_name,
  }

  const { data } = useQuery(
    ['collateralFilter', params],
    () => CollateralService.getFilter(params),
    getStaleMins()
  )

  const onChange = (field: string, value: any) => {
    setActiveFilters((prev: LayoutFilter) => ({
      ...prev,
      collateral: {
        ...prev.collateral,
        [field]: value,
      },
    }))
  }

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="grid grid-cols-2 justify-between items-center gap-4">
        <Typography
          variant="b2"
          className="font-semibold text-neutral-body-2 flex-1"
        >
          ID
        </Typography>
        <input
          className="border border-neutral-border-2 rounded py-2 px-4  focus:border-primary-main outline-0"
          value={id}
          onChange={e => onChange('id', e.target.value)}
        />
      </div>
      {Array.isArray(data) &&
        data
          ?.filter(x => x.key === 'error_type')
          .map(d => {
            return (
              <div
                key={d.key}
                className="grid grid-cols-2 justify-between items-center gap-4"
              >
                <Typography
                  variant="b2"
                  className="font-semibold text-neutral-body-2 flex-1 capitalize"
                >
                  {d.key.split('_').join(' ')}
                </Typography>
                <Select
                  className="[&~ul]:max-h-[150px]"
                  onChange={val => {
                    onChange(d.key, val)
                  }}
                  selected={() => {
                    return <span>{collateral?.[d.key] ?? 'Select'}</span>
                  }}
                >
                  {d.filters.map((f: string) => (
                    <Option key={f} value={f}>
                      {f}
                    </Option>
                  ))}
                </Select>
              </div>
            )
          })}
      <div className="flex justify-between items-center gap-4">
        <Typography
          variant="b2"
          className="font-semibold text-neutral-body-2 flex-1"
        >
          Status
        </Typography>
        <div className="flex">
          {[
            { title: 'Quarantined', value: true },
            { title: 'Flagged', value: false },
            { title: 'All', value: undefined },
          ].map((b, i) => (
            <Button
              key={i}
              className={`${i > 0 ? 'rounded-l-none' : ''} ${
                i < 2 ? 'rounded-r-none' : ''
              }`}
              color={status === b.value ? 'primary' : 'default'}
              onClick={() => onChange('status', b.value)}
            >
              {b.title}
            </Button>
          ))}
        </div>
      </div>
    </div>
  )
}
export default FilterCollateralData
