import React from 'react'

import { CovenantListResponse, CovenantType } from '@interfaces/covenant'

import CovenantL4SummaryOverview from './summary/overview'
import DefaultCovenantChart from './variant/default'
import VintageCovenantChart from './variant/vintage'
interface CovenantL4Props {
  covenants: CovenantListResponse[]
  type: CovenantType
  hideChart?: boolean
  link?: string
}
const CovenantL4 = ({
  covenants,
  type,
  hideChart = false,
  link,
}: CovenantL4Props) => {
  const conv = covenants?.[0]

  if (hideChart) {
    return (
      <CovenantL4SummaryOverview
        covenants={covenants}
        type={type}
        link={link}
      />
    )
  }

  const _chartRenderer = () => {
    switch (conv?.special_covenant) {
      case 'vintage':
        return <VintageCovenantChart covenants={covenants} type={type} />
      default:
        return <DefaultCovenantChart covenants={covenants} type={type} />
    }
  }

  return <div className="flex flex-col gap-6">{_chartRenderer()}</div>
}

export default CovenantL4
