import React from 'react'

import Table from '@components/table'
import { CovenantListResponse } from '@interfaces/covenant'

import { useVintageCovenantDetail } from '../../covenant/helper'

interface CovenantL4Props {
  type: 'portfolio' | 'financial'
  covenant: CovenantListResponse
}

const CovenantVintageL4 = ({ type, covenant }: CovenantL4Props) => {
  const { isFetching, table_data, table_columns, overview_data } =
    useVintageCovenantDetail([covenant], type)

  const triggers = overview_data.map((o: any) => o.mob)
  const table_column: any[] = [
    {
      ...table_columns[0],
      title: covenant[`${type}_covenant`],
    },
    ...table_columns
      .filter(t => triggers.includes(t.title))
      .map(t => ({ ...t, title: `MoB ${t.title}` })),
  ]

  return (
    <Table
      loading={!!isFetching}
      containerClass="bg-neutral-border-2 max-h-[calc(100vh-100px)] overflow-auto [&>table]:w-auto [&>table>thead]:sticky [&>table>thead]:top-0 [&>table>thead>tr>th]:capitalize [&>table>tfoot>tr>td:nth-child(1)]:text-right [&>table>tfoot>tr>td>*]:font-bold"
      columns={table_column}
      data={table_data}
    />
  )
}

export default CovenantVintageL4
