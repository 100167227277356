import React, { createContext, FC, useState } from 'react'

import { LANGUAGE_STORAGE_KEY, Languages } from '@constants/app'

interface ContextProps {
  currentLanguage: Languages
  changeCurrentLanguage: (language: Languages) => void
}

const localStorageLang = localStorage.getItem(LANGUAGE_STORAGE_KEY) as
  | Languages
  | undefined

const LanguageProvider: FC = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState<Languages>(
    localStorageLang || Languages.ENGLISH
  )

  const changeCurrentLanguage = (language: Languages) => {
    localStorage.setItem(LANGUAGE_STORAGE_KEY, language)
    setCurrentLanguage(language)
  }

  return (
    <LanguageContext.Provider
      value={{ currentLanguage, changeCurrentLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export const LanguageContext = createContext({} as ContextProps)
export default LanguageProvider
