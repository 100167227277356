import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import CollectionByStatusL5 from './aggregated-roll-rates'

const AggregatedRollRatesL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const tabs = [
    {
      label: 'Aggregated Roll Rates',
      desc: {
        description:
          'The behaviour of loans that are in one delinquency bucket as they “roll” into other delinquency buckets 30 days later.',
        formula:
          'For each month-end, track which delinquency bucket the outstanding balance ended up in 30 days later. ',
      },
      component: <CollectionByStatusL5 exportable={exportable} />,
    },
  ]

  return (
    <L4TabsLayout
      tabs={tabs}
      additionalIndicator={
        showHistoricalRateIndicator ? <ExchangeRateBadge /> : undefined
      }
    />
  )
}

export default AggregatedRollRatesL4
