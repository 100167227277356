import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import CohortRollRatesL5 from './cohort-roll-rates'

const CohortRollRatesL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const tabs = [
    {
      label: '',
      desc: {
        description:
          'The proportion of outstanding balance or number of loans in each delinquency bucket, for a monthly cohort, tracked based on months on book.  Provides a view of how each cohort behaved for every month it was on book.',
        formula: (
          <div className="flex flex-col">
            <p className="mb-2">
              Same as Delinquency by Cohort but captured for each month on book.
            </p>
            <p className="mb-2">
              All: combines all loans disbursed to date in one cohort.
            </p>
            <p className="mb-2">
              All MoB 6: combines all loans disbursed to date with at least 6
              months on book into one cohort.
            </p>
            <p className="mb-2">
              All MoB 12: combines all loans disbursed to date with at least 12
              months on book into one cohort.
            </p>
          </div>
        ),
      },
      component: <CohortRollRatesL5 exportable={exportable} />,
    },
  ]

  return (
    <L4TabsLayout
      tabs={tabs}
      headerClassName="lg:!mr-0"
      additionalIndicator={
        showHistoricalRateIndicator ? <ExchangeRateBadge /> : undefined
      }
    />
  )
}

export default CohortRollRatesL4
