import React from 'react'

import { CategoriesResult } from '@interfaces/manage-reporting'
import { UnauditedFinancialStatementsTemplateProps } from '@interfaces/manage-reporting'

import UnauditedFinancialStatementsTemplate from './unaudited-financial-statements-template'

export const renderTemplate = (
  category: CategoriesResult,
  templateProps: UnauditedFinancialStatementsTemplateProps
) => {
  switch (category.subcategory) {
    case 'default': {
      return <UnauditedFinancialStatementsTemplate {...templateProps} />
    }

    default: {
      return <div />
    }
  }
}
