import React, { useContext, useEffect } from 'react'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  Filter,
  FilterItem,
  LayoutFilter,
} from '@interfaces/analytics-risk-filter-type-key'
import {
  Option,
  Popover,
  PopoverContent,
  PopoverHandler,
  Select,
} from '@material-tailwind/react'
import { Checkbox } from '@material-tailwind/react'
import FilterService from '@services/api-analytics/risk-filter'

const FilterTypeKeyV2 = () => {
  const {
    company,
    activeFilters,
    setActiveFilters,
    optionFilters,
    setOptionFilters,
  } = useContext(AuthContext)

  const { categoryTypes = [], activeType } = activeFilters
  const {
    categoryTypeOptions,
    categoryTypeCompanySlugName,
    categoryTypeLoading,
  } = optionFilters

  const oriActiveVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []
  const hasNaN = oriActiveVal.find((x: any) =>
    isNaN(
      Math.min(...(x.type ?? '').split(/(?:[-+\s]+)/).filter((x: any) => x))
    )
  )

  const activeVal = oriActiveVal.sort((a: any, b: any) => {
    return hasNaN
      ? a.id > b.id
        ? 1
        : -1
      : Math.min(...(a.type ?? '').split(/(?:[-+\s]+)/).filter((x: any) => x)) -
          Math.min(...(b.type ?? '').split(/(?:[-+\s]+)/).filter((x: any) => x))
  })

  const allCategoryTypes = (
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === 'All')
      ?.type_val ?? []
  ).map((av: FilterItem) => av.id)

  useEffect(() => {
    if (company && company.slug_name !== categoryTypeCompanySlugName) {
      setOptionFilters((prev: LayoutFilter) => ({
        ...prev,
        categoryTypeLoading: true,
      }))
      FilterService.getFilters(company?.schema_name, company?.slug_name)
        .then(response => {
          const filters = (response.data ?? []).filter(
            x => x.type_val.length <= 20
          )
          const allValues = filters?.reduce(
            (prev: FilterItem[], cur) => [...prev, ...cur.type_val],
            []
          )

          //adds "all" as an option
          setOptionFilters((prev: LayoutFilter) => ({
            ...prev,
            categoryTypeCompanySlugName: company.slug_name,
            categoryTypeOptions: [
              { type_key: 'All', type_val: allValues },
              ...filters,
            ],
            categoryTypeLoading: false,
          }))

          // selects "all" filters by default if no active filters found (i.e first load)
          categoryTypes?.length === 0 &&
            setActiveFilters((prev: LayoutFilter) => ({
              ...prev,
              categoryTypes: allValues.map(av => av.id).sort(),
              activeType: 'All',
            }))
        })
        .catch(() => {
          setOptionFilters((prev: LayoutFilter) => ({
            ...prev,
            categoryTypeLoading: false,
          }))
        })
    }
  }, [company])

  useEffect(() => {
    const nextCategoryTypes = activeVal.map((av: FilterItem) => av.id)
    /*
     * set categorytypes when:
     * prev activeType equal 'All' OR
     * next activeType equal 'All' OR
     * prev activetype NOT equal 'All' AND there is NO subset between categoryType (prev) and nextCategoryType (next)
     */
    if (
      categoryTypes.sort().join('.') === allCategoryTypes.sort().join('.') ||
      nextCategoryTypes.sort().join('.') ===
        allCategoryTypes.sort().join('.') ||
      (categoryTypes.sort().join('.') !== allCategoryTypes.sort().join('.') &&
        !nextCategoryTypes.some((x: number) => categoryTypes.includes(x)))
    ) {
      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        categoryTypes: nextCategoryTypes.sort(),
      }))
    }
  }, [activeType])

  return (
    <div className="flex flex-col gap-1">
      <Typography className="text-neutral-body-2 text-sm">Filter by</Typography>
      <Popover placement="bottom">
        <PopoverHandler>
          <button className="border border-neutral-border-2 !bg-neutral-white shadow-lg rounded-lg min-w-[175px] max-w-full min-h-[40px] !outline-none flex items-center justify-between px-4">
            {categoryTypeLoading && (
              <Typography className="text-sm">loading</Typography>
            )}
            {!categoryTypeLoading && (categoryTypeOptions?.length ?? 0) > 0 && (
              <>
                <div className="flex gap-2">
                  <Typography className="text-sm !text-neutral-black font-medium">
                    {activeType}
                  </Typography>
                  {activeType !== 'All' && (
                    <Typography className="text-sm !text-neutral-white bg-primary-main px-2 rounded-full font-medium">
                      {categoryTypes.length}
                    </Typography>
                  )}
                </div>
                <ChevronDownIcon className="h-3 w-3" />
              </>
            )}
            {!categoryTypeLoading &&
              (categoryTypeOptions?.length ?? 0) === 0 && (
                <Typography className="text-sm">No available filter</Typography>
              )}
          </button>
        </PopoverHandler>
        <PopoverContent className="z-[9999] border-t border-neutral-border-2 rounded-lg flex flex-col min-w-[175px] shadow-lg max-w-full">
          {!categoryTypeLoading && (categoryTypeOptions?.length ?? 0) > 0 && (
            <Select
              className="[&~ul]:max-h-[150px]"
              value={activeType}
              onChange={val => {
                setActiveFilters((prev: LayoutFilter) => ({
                  ...prev,
                  activeType: val,
                }))
              }}
            >
              {categoryTypeOptions?.map((cto: Filter) => (
                <Option key={cto.type_key} value={cto.type_key}>
                  {cto.type_key === 'All'
                    ? 'Select'
                    : cto.type_key.replaceAll('_', ' ')}
                </Option>
              ))}
            </Select>
          )}
          {activeType !== 'All' && (
            <div
              className={`mt-4 border border-neutral-border-2 rounded-lg p-2 flex flex-col max-h-[100px] overflow-y-auto`}
            >
              {activeVal.map((v: FilterItem) => (
                <Checkbox
                  id={v.id.toString()}
                  key={v.id}
                  label={v.type}
                  value={v.id}
                  checked={categoryTypes?.includes(v.id)}
                  onChange={e => {
                    const check = e.target.checked
                    let ct = categoryTypes
                    if (check && !categoryTypes.includes(v.id)) {
                      ct = [...ct, v.id]
                    } else if (!check && categoryTypes.includes(v.id)) {
                      ct = ct.filter((x: number) => x !== v.id)
                    }

                    setActiveFilters((prev: LayoutFilter) => ({
                      ...prev,
                      categoryTypes: ct,
                    }))
                  }}
                  crossOrigin={undefined}
                />
              ))}
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  )
}
export default FilterTypeKeyV2
