import React, { useContext, useEffect } from 'react'
import { useState } from 'react'

import Typography from '@components/atoms/typography'
import FilterCurrency from '@components/filters/filter-currency'
import FilterDebtDeal from '@components/filters/filter-debt-deal'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { CovenantType } from '@interfaces/covenant'

import ExportDialog from './export-dialog'
import { useCovenantList } from './helper'

const MonitorCovenantL3 = ({ type }: { type: CovenantType }) => {
  const { company, activeFilters, setAppliedFilters } = useContext(AuthContext)

  const { activeDebtDeal } = activeFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (!filterReady && activeDebtDeal !== undefined) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  /**
   * observe reset button disable state criteria
   */
  const disableReset = activeDebtDeal === 0

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  const { isFetching, tabs, facility } = useCovenantList(type)
  const { get_access } = useUserAccessFeature()
  const FEATURE = `${facility}_manage_monitor_${type}_covenant`
  const can_export = get_access(FEATURE, 'export')

  return (
    <L3TabsLayout
      title="Portfolio Covenants"
      filters={{
        left: <FilterCurrency hideSelect />,
        middle: <FilterDebtDeal />,
      }}
      disableReset={disableReset}
      tabs={tabs}
      empty={
        isFetching ? (
          <div className="flex justify-center">
            <ArrowPathIcon className="animate-spin text-primary-main w-8" />
          </div>
        ) : (
          <Typography className="text-center">
            No {type} covenant found for {company?.legal_name}
          </Typography>
        )
      }
      rightFooterContent={can_export ? <ExportDialog type={type} /> : <></>}
      switchable
    />
  )
}
export default MonitorCovenantL3
