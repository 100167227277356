import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'

import Button from '@components/atoms/button'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Radio,
} from '@material-tailwind/react'
import BankService from '@services/api-admin/data-source-banks'

import { extractSchemaNr } from '../utils'

import BankList from './banks'
import ManualAccountDialog from './manual-dialog'
import { usePlaid } from './usePlaid'

interface BankConnectorProps {
  open: boolean
  handleOpen: () => void
  handleConnect: () => void
  refetch: () => void
}

const BankConnector = ({ open, handleOpen, refetch }: BankConnectorProps) => {
  const { company, setLoadingData } = useContext(AuthContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { initializePlaid, isLoading: isLoadingPlaid } = usePlaid()
  // const [openBelvoWidget, setOpenBelvoWidget] = useState<boolean>(false)
  const [apiSupp, setApiSupp] = useState<string>('')
  const [provider, setProvider] = useState<string>('')
  const [bankId, setBankId] = useState<string>('')
  const [formData, setFormData] = useState<any>({})
  const [bankError, setBankError] = useState<boolean>(true)
  // const [multipleAccountsForm, setMultipleAccountsForm] =
  //   useState<boolean>(false)
  const [manualAccountsDialog, setManualAccountsDialog] =
    useState<boolean>(false)

  // const handleMultipleAccountsOpen = () =>
  //   setMultipleAccountsForm(!multipleAccountsForm)

  const handleManualAccountsOpen = () =>
    setManualAccountsDialog(!manualAccountsDialog)

  // const closeWidget = () => {
  //   setOpenBelvoWidget(false)
  // }

  const onSubmit = async (data: any) => {
    bankId == '' && setBankError(true)

    setFormData(data)
    if (provider === 'Plaid') {
      initializePlaid({
        slug_name: company?.slug_name ?? '',
        formData: {
          entity_ownership: data.entityOwnership,
          account_use: data.use,
        },
      })
    } else if (provider === 'Manual') {
      handleOpen()
      handleManualAccountsOpen()
    } else {
      handleOpen()
    }
  }

  useEffect(() => {
    if (!isLoadingPlaid && provider === 'Plaid') {
      handleOpen()
      refetch()
    }
  }, [isLoadingPlaid])

  const manualAccountSubmit = async (data: any, statement: File) => {
    handleManualAccountsOpen()
    if (company?.slug_name) {
      setLoadingData([
        { state: 'loading', text: 'Configuring Database' },
        { state: 'waiting', text: 'Saving Account Info' },
        { state: 'waiting', text: 'Uploading Statement' },
      ])

      //creates tables & schemas if they do not exist
      await BankService.addBankTable(company?.slug_name)
        .then(async () => {
          await BankService.addBankSchema(company?.slug_name)
            .then(async res => {
              const accountInfo = {
                account_id: uuidv4(),
                bank_id: data.bankName,
                account_type: data.accType,

                account_name: data.accName,
                account_no: data.accNo,
                account_nickname: formData.nickname,
                entity_ownership: formData.entityOwnership,
                account_use: formData.use,
                status: 'Active',
                account_balance: data.closingBalance,
                data_source: 'Manual',
                data_source_supplementary: apiSupp,
                slug_name: company?.slug_name,
                schema_no: extractSchemaNr(res.data.schema),
              }
              setLoadingData([
                { state: 'done', text: 'Configuring Database' },
                { state: 'loading', text: 'Saving Account Info' },
                { state: 'waiting', text: 'Uploading Statement' },
              ])
              await BankService.addBankAccount(accountInfo)
                .then(async () => {
                  const balanceInfo = {
                    entry_id: uuidv4(),
                    value_date: data.statementDate,
                    account_balance: data.closingBalance,
                    account_id: { account_id: accountInfo.account_id },
                    timestamp: moment().format('YYYY-MM-DD HH:mm:ss.SSS ZZ'),
                    slug_name: company?.slug_name,
                    schema_no: extractSchemaNr(res.data.schema),
                  }
                  await BankService.addBalance(balanceInfo).then(async () => {
                    setLoadingData([
                      { state: 'done', text: 'Configuring Database' },
                      { state: 'done', text: 'Saving Account Info' },
                      { state: 'loading', text: 'Uploading Statement' },
                    ])

                    await BankService.uploadStatement(
                      statement,
                      company.slug_name,
                      balanceInfo.entry_id
                    )
                      .then(() => {
                        setLoadingData([
                          { state: 'done', text: 'Configuring Database' },
                          { state: 'done', text: 'Saving Account Info' },
                          { state: 'done', text: 'Uploading Statement' },
                        ])
                        setTimeout(() => {
                          setLoadingData([])
                        }, 5000)
                      })
                      .catch(err => {
                        console.error(err)
                        setLoadingData([
                          { state: 'done', text: 'Configuring Database' },
                          { state: 'done', text: 'Saving Account Info' },
                          { state: 'failed', text: 'Uploading Statement' },
                        ])
                      })
                  })
                })
                .catch(err => {
                  console.error(err)
                  setLoadingData([
                    { state: 'done', text: 'Configuring Database' },
                    { state: 'failed', text: 'Saving Account Info' },
                    { state: 'waiting', text: 'Uploading Statement' },
                  ])
                  setTimeout(() => {
                    setLoadingData([])
                  }, 5000)

                  return
                })
            })
            .catch(err => {
              console.error(err)
              setLoadingData([
                { state: 'done', text: 'Configuring Database' },
                { state: 'failed', text: 'Saving Account Info' },
                { state: 'waiting', text: 'Uploading Statement' },
              ])
              setTimeout(() => {
                setLoadingData([])
              }, 5000)

              return
            })
        })
        .catch(err => {
          console.error(err)
          setLoadingData([
            { state: 'failed', text: 'Configuring Database' },
            { state: 'waiting', text: 'Saving Account Info' },
            { state: 'waiting', text: 'Uploading Statement' },
          ])
          setTimeout(() => {
            setLoadingData([])
          }, 5000)

          return
        })
    } else {
      setLoadingData([
        { state: 'failed', text: 'Configuring Database' },
        { state: 'waiting', text: 'Saving Account Info' },
        { state: 'waiting', text: 'Uploading Statement' },
      ])
      setTimeout(() => {
        setLoadingData([])
      }, 5000)
    }
  }

  useEffect(() => {
    setBankError(false)
  }, [bankId])

  const isLoading = isLoadingPlaid

  return (
    <>
      <Dialog open={open} handler={handleOpen} className="overflow-auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader>
            <div className="flex justify-between w-full">
              <span>Add Bank Account</span>
              <XMarkIcon
                onClick={handleOpen}
                className="w-8 h-8 cursor-pointer hover:opacity-50"
              />
            </div>
          </DialogHeader>
          <DialogBody divider>
            <div className="flex flex-col gap-6 w-full mt-5">
              <BankList
                setBankId={setBankId}
                bankId={bankId}
                setApiSupp={setApiSupp}
                setProvider={setProvider}
              />
              {provider && (
                <>
                  {provider != 'Plaid' && (
                    <div>
                      <Input
                        {...register('nickname', {
                          pattern: {
                            value: /^[A-Za-z\d\s]+$/i,
                            message:
                              'Invalid characters - Alphanumeric characters only!',
                          },
                          disabled: isLoading,
                          required: {
                            value: true,
                            message: 'This is required',
                          },
                        })}
                        variant="static"
                        label="Nickname"
                        placeholder="Enter a Nickname for the Account"
                        success={true}
                      />
                      {errors.nickname && (
                        <p className="text-red text-xs">
                          {errors.nickname.message}
                        </p>
                      )}
                    </div>
                  )}
                  <div>
                    <Input
                      {...register('entityOwnership', {
                        pattern: {
                          value: /^[A-Za-z]+$/i,
                          message:
                            'Invalid characters - Alphabetic characters only!',
                        },
                        disabled: isLoading,
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                      })}
                      variant="static"
                      label="Entity Ownership"
                      placeholder="Enter the entity ownership"
                    />
                    {errors.entityOwnership && (
                      <p className="text-red text-xs">
                        {errors.entityOwnership.message}
                      </p>
                    )}
                  </div>
                  <div className="grid grid-cols-2 flex">
                    <span className="col-span-2 text-sm">Use:</span>

                    <Radio
                      {...register('use', {
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                        disabled: isLoading,
                      })}
                      type="radio"
                      value="Operations"
                      label="Operations"
                    />
                    <Radio
                      {...register('use', {
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                        disabled: isLoading,
                      })}
                      type="radio"
                      value="Collections"
                      label="Collections"
                    />
                    <Radio
                      {...register('use', {
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                        disabled: isLoading,
                      })}
                      type="radio"
                      value="Reserve"
                      label="Reserve"
                    />
                    <Radio
                      {...register('use', {
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                        disabled: isLoading,
                      })}
                      type="radio"
                      value="Other"
                      label="Other"
                    />
                    {errors.use && (
                      <p className="text-red text-xs">{errors.use.message}</p>
                    )}
                  </div>
                </>
              )}
            </div>
          </DialogBody>
          <DialogFooter>
            <div className="flex flex-row gap-6">
              <Button
                type="submit"
                className="rounded-lg"
                disabled={
                  Object.keys(errors).length != 0 || bankError || isLoading
                }
                color={'primary'}
              >
                {isLoading ? (
                  <ArrowPathIcon className="w-4 h-4 mr-2 text-primary-main animate-spin" />
                ) : (
                  'Next'
                )}
              </Button>
            </div>
          </DialogFooter>
        </form>
      </Dialog>
      {manualAccountsDialog && (
        <ManualAccountDialog
          open={manualAccountsDialog}
          handleOpen={handleManualAccountsOpen}
          handleSubmitManualInfo={manualAccountSubmit}
        />
      )}
    </>
  )
}
export default BankConnector
