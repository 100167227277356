import React from 'react'

import { Typography, TypographyProps } from '@material-tailwind/react'

interface Props extends Omit<TypographyProps, 'variant'> {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'st1'
    | 'st2'
    | 'b1'
    | 'b2'
}

export default function CustomTypography(props: Props) {
  return <Typography {...(props as any)} />
}
