import React, { useEffect, useRef } from 'react'

interface ListPropsDropdown {
  optionListKey?: string
  options: any[]
  onOptionSelected: (option?: any, obj?: any) => void
  containerClassName?: string
  renderOption?: (option: any) => React.ReactNode
  selectedIndex: number | null
}

const ListOptions = ({
  optionListKey,
  options,
  onOptionSelected,
  containerClassName,
  renderOption = (option: any) =>
    optionListKey ? option[optionListKey] : option,
  selectedIndex,
}: ListPropsDropdown) => {
  const selectedRef = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      })
    }
  }, [selectedIndex])

  return (
    <div
      className={`${
        containerClassName ??
        `absolute w-full z-[1] border rounded-md border-[#E0E0E0]/[.55] bg-[#FFF]  mt-1 shadow-lg max-h-36 overflow-y-auto`
      }`}
    >
      <ul>
        {options.map((option, i) => {
          const optionList = optionListKey ? option[optionListKey] : option

          return (
            <li
              key={`${optionList}-${i}`}
              ref={i === selectedIndex ? selectedRef : null}
              className={`py-2.5 px-3 cursor-pointer border-[#E0E0E0]/[.55] border-b-[0.5px] hover:bg-gray-100 hover:opacity-50  text-sm ${
                i === selectedIndex ? 'bg-gray-100' : ''
              }`}
              onClick={() => onOptionSelected(optionList, option)}
              tabIndex={0}
              aria-selected={i === selectedIndex}
            >
              {renderOption(optionList) ?? optionList}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ListOptions
