import React, { useEffect, useState } from 'react'

import Chart from '@components/chart'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import { CovenantListResponse, CovenantType } from '@interfaces/covenant'

import { getLabel, getLabelFormat, useVintageCovenantDetail } from '../helper'

interface VintageCovenantChartProps {
  covenants: CovenantListResponse[]
  type: CovenantType
}

const VintageCovenantChart = ({
  covenants,
  type,
}: VintageCovenantChartProps) => {
  const conv = covenants?.[0]
  const {
    isFetching,
    table_columns,
    table_data,
    table_header_data,
    chart_series,
    chart_data,
  } = useVintageCovenantDetail(covenants, type)

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [conv])

  return (
    <div className="gap-6 [&>div]:w-full">
      <Chart
        loading={!!isFetching || isProcessing}
        id={covenants.map(x => x?.[`${type}_covenant`]).join('|')}
        yLabel={getLabel(conv, type)}
        yFormat={getLabelFormat(conv, type)}
        xLabel="Month on Books (MoB)"
        xAxisType="CategoryAxis"
        data={chart_data}
        series={chart_series}
        exportable={false}
      />
      <Table
        containerClass="nohover overflow-auto [&>table>thead>tr>td]:font-semibold"
        loading={!!isFetching}
        data={table_data}
        headerData={table_header_data}
        columns={table_columns as any}
      />
    </div>
  )
}

export default VintageCovenantChart
