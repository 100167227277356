import React from 'react'
import moment from 'moment'

import { UnauditedFinancialStatementsTemplateProps } from '@interfaces/manage-reporting'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { Footer, tableStyles } from '../../components'
import { styles } from '../../template_style'

const UnauditedFinancialStatementsTemplate = ({
  agent,
  borrower,
  guarantors,
  agreementDate,
  balanceSheetTableData,
  pnlTableData,
  currency,
  currencyOptions,
  formData,
}: UnauditedFinancialStatementsTemplateProps) => {
  const displayValue = (num: number) => {
    if (currencyOptions.ratePrimary) {
      const primaryAmount =
        currencyOptions.default == 'USD'
          ? num
          : num / currencyOptions.ratePrimary.rate
      const value = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyOptions.code,
        currencyDisplay: 'code',
        currencySign: 'accounting',
      }).format(
        //only applies secondary conversion if applicable and the entities default currency is not in USD
        !currencyOptions.rateSecondary && currencyOptions.default == 'USD'
          ? primaryAmount * currencyOptions.ratePrimary.rate
          : currencyOptions.rateSecondary && currencyOptions.default != 'USD'
          ? primaryAmount * currencyOptions.rateSecondary.rate
          : primaryAmount
      )
      // removes currency code however still keeping accounting formats such as brackets, zoning, decimals etc
      const regex = /\([A-Z]{3}\s/
      return value.endsWith(')')
        ? value.replace(regex, '(')
        : value.substring(3)
    }
  }

  const renderRow = (rowData: any, indexLevel: number) => {
    return !!rowData.info?.length ? (
      <>
        {rowData.info.map((info: any) => renderRow(info, indexLevel + 1))}
        {indexLevel === 1 && (
          <View style={tableStyles.tableRow}>
            <View style={tableStyles.tableCol}>
              <Text style={tableStyles.tableCellBold}>
                {'TOTAL ' + rowData.item.toUpperCase()}
              </Text>
            </View>
            {rowData.values.map((value: any, valueIndex: number) => (
              <View
                style={tableStyles.tableCol}
                key={`${value}_body_${valueIndex}`}
              >
                <Text style={tableStyles.tableCell}>{displayValue(value)}</Text>
              </View>
            ))}
          </View>
        )}
      </>
    ) : (
      <View style={tableStyles.tableRow}>
        <View style={tableStyles.tableCol}>
          <Text
            style={
              rowData.item.includes('Total')
                ? tableStyles.tableCellBold
                : tableStyles.tableCell
            }
          >
            {rowData.item}
          </Text>
        </View>
        {rowData.values.map((value: any, valueIndex: number) => (
          <View
            style={tableStyles.tableCol}
            key={`${value}_body_${valueIndex}`}
          >
            <Text style={tableStyles.tableCell}>{displayValue(value)}</Text>
          </View>
        ))}
      </View>
    )
  }

  const renderTable = (data: any) => (
    <View style={tableStyles.table}>
      <View style={tableStyles.tableRow}>
        <View style={tableStyles.tableHeaderCol}>
          <Text style={tableStyles.tableCell}>Currency: {currency}</Text>
        </View>
        {data?.cols?.map((header: any, index: number) => (
          <View
            style={tableStyles.tableHeaderCol}
            key={`${header}-title-${index}`}
          >
            <Text style={tableStyles.tableCell}>{header}</Text>
          </View>
        ))}
      </View>
      <View style={tableStyles.tableBody}>
        {data?.data?.map((item: any) => renderRow(item, 1))}
      </View>
    </View>
  )

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>
          Unaudited Financial Statement Compliance Certificate
        </Text>
        <Text style={styles.text}>{moment().format('DD/MM/YYYY')}</Text>
        <Text style={styles.text}>{agent || '[Agent]'}</Text>
        <Text style={styles.text}>Reference is made to:</Text>
        <Text style={styles.text}>
          Loan and Security Agreement, dated:{' '}
          {agreementDate || `[Date of Agreement]`} (as amended, restated,
          supplemented or otherwise modified from time to time), the “Loan
          Agreement”
        </Text>
        <Text style={styles.text}>Among :</Text>
        <Text style={styles.text}>
          the Borrower: {borrower || '[Borrower name]'}
        </Text>
        <Text style={styles.text}>
          the Guarantor: {guarantors || '[Intentionally left blank]'}
        </Text>
        <Text style={styles.text}>
          the Lenders: the financial institutions or entities from time to time
          defined therein
        </Text>
        <Text style={styles.text}>the Agent: {agent || '[Agent]'}</Text>
        <Text style={styles.text}>
          Capitalized terms used but not defined herein have the meanings
          specified in the Loan Agreement.
        </Text>
        <Text style={styles.text}>
          <Text style={{ ...styles.text, fontFamily: 'Times-Bold' }}>
            Target
          </Text>{' '}
          means {formData?.target ?? '[Target name]'}
        </Text>
        <Text style={{ ...styles.text, textIndent: 32 }}>
          The undersigned is an officer of the Borrower, knowledgeable of all of
          the Obligors’ financial matters, is authorized to provide this
          certification regarding, and on behalf of, the Borrower, and hereby
          certifies in such capacity, that:
        </Text>
        <Text style={styles.textIndented}>
          Delivered herewith are year-to-date unaudited financial statements of
          the Target as of the end of [date], including a balance sheet and
          income statement, accompanied by a report detailing any material
          contingencies (including the commencement of any material litigation
          by or against the Target or any other occurrence that could reasonably
          be expected to have a Material Adverse Effect (collectively the
          Financial Statements). Such Financial Statements have been prepared in
          accordance with [GAAP/applicable country law/etc] except for the
          absence of footnotes and subject to normal year-end adjustments and
          are consistent from one period to the next except as explained below.
        </Text>
        <Text style={styles.text}>Sincerely,</Text>
        <Text style={styles.text}>{formData?.target || '[Target]'}</Text>
        <Text style={styles.text}>
          Authorized Officer Signature:
          <Text style={styles.signTags}>[sig|req|signer1|signature]</Text>
        </Text>
        <Text style={styles.text}>
          Authorized Officer Name:{'      '}
          {formData?.signers?.[0]?.name || '[Authorized Officer Name]'}
        </Text>
        <Text style={styles.text}>
          Authorized Officer Title:{'        '}
          {formData?.signers?.[0]?.title || '[Authorized Officer Title]'}
        </Text>
        <Text style={styles.text}>
          Date Signed:{'                           '}
          <Text style={styles.signTags}>[date|req|signer1|signature_date]</Text>
        </Text>
        <Text style={styles.disclosure}> </Text>
        <Text break={true} style={styles.disclosure}>
          Balance Sheet
        </Text>
        {renderTable(balanceSheetTableData)}
        <Text break={true} style={styles.disclosure}>
          Profit and Loss
        </Text>
        {renderTable(pnlTableData)}
        <Text break={true} style={styles.disclosure}>
          Material Contingencies Report
        </Text>
        <Text style={styles.text}>
          {formData?.disclosure || '[Intentionally left blank]'}
        </Text>
        <Footer />
      </Page>
    </Document>
  )
}

export default UnauditedFinancialStatementsTemplate
