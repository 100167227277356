import React from 'react'
import moment from 'moment'

import { TemplateProps } from '@interfaces/manage-reporting'
import { Document, Page, View } from '@react-pdf/renderer'

import { Table, Text } from '../../../../components'
import { MFSFooter, MFSHeader, MfsTitlePage } from '../../../../components/mfs'

import { generateTableData } from './helpers'

const textOtherStyles = {
  marginVertical: 10,
}

export const MfsPromissoryComplianceCertTemplate = ({
  customData,
  formData,
}: TemplateProps) => {
  const tableData = generateTableData(formData, customData)

  return (
    <Document>
      <MfsTitlePage
        title={'Compliance Certificate'}
        effectiveDate={formData['effective-date'] ?? ''}
        customData={customData}
        formData={formData}
        contentStrings={{
          forAttention: '',
          viaEmail: ['reporting.geneva@symbiotics.com'],
          facility: 'Symbiotics - Promissory Note',
        }}
      />
      <Page style={{ paddingBottom: 68 }}>
        <MFSHeader fixed={true} address={customData.headerAddress} />
        <View
          style={{ paddingTop: 16, paddingBottom: 65, paddingHorizontal: 58 }}
        >
          <Text bold otherStyles={textOtherStyles}>
            Compliance Certificate
          </Text>
          <Text bold otherStyles={textOtherStyles}>
            Financial Covenants
          </Text>
          <View
            style={{
              marginVertical: 10,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Text
              otherStyles={{
                width: 'auto',
                paddingRight: 72,
                paddingLeft: 8,
                paddingTop: 6,
                paddingBottom: 2,
                border: '1px solid black',
                borderLeft: 'none',
                backgroundColor: '#b5e3e8',
              }}
            >
              {formData['effective-date']
                ? moment(formData['effective-date']).format('MMMM YYYY')
                : '[Effective date]'}
            </Text>
          </View>
          {tableData.map((table: any, index: number) => (
            <Table
              key={`table-${index}`}
              data={table}
              size="normal"
              index={`Covenant ${index + 1}:`}
            />
          ))}
        </View>
        <MFSFooter />
      </Page>
    </Document>
  )
}
