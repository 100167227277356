export const LENDERS_MOCK = [
  {
    legal_name: 'PCAM Creadit XVII, LLC',
    nickname: 'ParkCity',
    class: 'Class A',
    commitment: `USD 7,500,000`,
    cascade_name: 'Lender 1',
  },
  {
    legal_name: 'PCAM Creadit XVII, LLC',
    nickname: 'ParkCity',
    class: 'Class B',
    commitment: `USD 1,500,000`,
    cascade_name: 'Lender 2',
  },
]

export const BORROWERS_MOCK = [
  {
    roles: 'Borrower',
    legal_name: 'GEIA, S.A.P.I DE C.V',
    nickname: 'Baubap',
    country: 'Mexico',
  },
  {
    roles: 'Originator',
    legal_name: 'GEIA, S.A.P.I DE C.V',
    nickname: 'Baubap',
    country: 'Mexico',
  },
  {
    roles: 'Guarantor 1',
    legal_name: 'BBP HOLDING',
    nickname: 'Baubap Cayman',
    country: 'Cayman',
  },
  {
    roles: 'Guarantor 2',
    legal_name: 'BAUBAP LLC',
    nickname: 'Baubap USA',
    country: 'USA',
  },
]

export const AGENTS_MOCK = [
  {
    legal_name: 'PCAM Credit XVIII, LLC',
    nickname: 'ParkCity',
    title: 'Agent',
  },
  {
    legal_name: 'PCAM Credit XVIII, LLC',
    nickname: 'ParkCity',
    title: 'Security Agent',
  },
]

export const SERVICE_PROVIDERS_MOCK = [
  {
    name: 'Service Provider 1',
    roles: 'Cash Agent',
    cascade_access: 'Yes',
  },
  {
    name: 'Service Provider 2',
    roles: 'Trust Company',
    cascade_access: 'No',
  },
  {
    name: 'Service Provider 3',
    roles: 'Loan Verification Agent',
    cascade_access: 'Yes',
  },
]
