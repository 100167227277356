import moment from 'moment'

import {
  BorrowingBaseResponse,
  CashResponse,
  EligiblePoolBalanceResponse,
  ExcessConcentrationResponse,
  InterestReserveResponse,
} from '@interfaces/borrowing-base'
import { AllTableData, TableData } from '@interfaces/manage-reporting'

const renderDecimal = (value?: any) => {
  const isNegative = value < 0
  const formattedValue = Intl.NumberFormat(undefined, {
    style: 'decimal',
    maximumFractionDigits: 2,
  }).format(value)
  return isNegative ? `(${formattedValue.replace('-', '')})` : formattedValue
}

const SUMMARY_SORT = [
  'fx_rate',
  'cash',
  'eligible_pool',
  'repossessed_pool_balance',
  'excess_concentration',
  'interest_reserve',
  'borrowing_base',
  'advance_rate',
  'discounted_borrowing_base',
  'lender_principal',
  'surplus',
  'collateralization_percent',
]

let discountedBorrowingBase = 0
let totalLenderPrincipal = 0
export const generateSummaryTableData = (
  summaryData: BorrowingBaseResponse[],
  activeFacility: any,
  title: string
): AllTableData => {
  const data = SUMMARY_SORT.reduce((prev: any, cur) => {
    const filteredData = (summaryData ?? []).filter(x =>
      x.defined_term.startsWith(cur)
    )
    let addition: any[] = []
    switch (cur) {
      case 'fx_rate':
        addition = [
          [
            {
              content: `Date`,
              position: 'left',
              width: '50%',
            },
            {
              content: '',
              position: 'right',
              width: '25%',
            },
            {
              content: moment
                .utc(filteredData?.[0]?.calculation_date)
                .format('YYYY-MM-DD'),
              position: 'right',
              width: '25%',
            },
          ],
          [
            {
              content: `USD:${activeFacility.currency}`,
              position: 'left',
              width: '50%',
            },
            {
              content: filteredData?.[0]?.value,
              position: 'right',
              width: '25%',
            },
            {
              content: '',
              position: 'right',
              width: '25%',
            },
          ],
        ]
        break
      case 'borrowing_base':
      case 'discounted_borrowing_base':
      case 'surplus':
        if (cur === 'discounted_borrowing_base') {
          discountedBorrowingBase = filteredData?.[0]?.value
        }
        addition = filteredData.map(x => [
          {
            content:
              cur === 'surplus'
                ? x.value >= 0
                  ? 'Surplus'
                  : 'Deficiency'
                : x.defined_term.split('_').join(' '),
            position: 'left',
            bold: true,
            width: '50%',
          },
          {
            content: '',
            position: 'right',
            width: '25%',
          },
          {
            content: renderDecimal(x.value),
            position: 'right',
            bold: true,
            width: '25%',
          },
        ])
        break
      case 'advance_rate':
        addition = [
          ...filteredData.map(x => [
            {
              content: x.defined_term.split('_').join(' '),
              position: 'left',
              width: '50%',
              capitalize: true,
            },
            {
              content: renderDecimal(x.value * 100),
              position: 'right',
              width: '25%',
            },
            {
              content: '',
              position: 'right',
              width: '25%',
            },
          ]),
        ]
        break
      case 'lender_principal':
        let total = 0
        const items = filteredData.map(x => {
          total += x.value
          return [
            {
              content: x.defined_term.split('_').join(' '),
              position: 'left',
              width: '50%',
              capitalize: true,
            },
            {
              content: `(${renderDecimal(x.value)})`,
              position: 'right',
              width: '25%',
            },
            {
              content: '',
              position: 'right',
              width: '25%',
            },
          ]
        })
        addition = [
          ...items,
          [
            {
              content: 'Total Lender Principal',
              position: 'left',
              bold: true,
              width: '50%',
            },
            {
              content: '',
              position: 'right',
              width: '25%',
            },
            {
              content: `(${renderDecimal(total)})`,
              position: 'right',
              bold: true,
              width: '25%',
            },
          ],
        ]
        totalLenderPrincipal = total
        break
      case 'collateralization_percent':
        addition = [
          [
            {
              content: 'Collateralization Percent',
              position: 'left',
              bold: true,
              width: '50%',
            },
            {
              content: '',
              position: 'right',
              width: '25%',
            },
            {
              content: `${
                totalLenderPrincipal && discountedBorrowingBase
                  ? `${Number(
                      (discountedBorrowingBase / totalLenderPrincipal) * 100
                    ).toFixed(2)}%`
                  : '-'
              }`,
              position: 'right',
              bold: true,
              width: '25%',
            },
          ],
        ]
        break
      default:
        addition = filteredData.map(x => [
          {
            content: x.defined_term.split('_').join(' '),
            position: 'left',
            width: '50%',
            capitalize: true,
          },
          {
            content: `${x.action === 'subtract' ? '(' : ''}${renderDecimal(
              x.value
            )}${x.action === 'subtract' ? ')' : ''}`,
            position: 'right',
            width: '25%',
          },
          {
            content: '',
            position: 'right',
            width: '25%',
          },
        ])
        break
    }

    return [...prev, ...addition]
  }, [])

  return {
    title,
    key: 'summary',
    tableWidth: '75%',
    data,
  }
}

export const generateCashTableData = (
  data: CashResponse[],
  title: string
): AllTableData => {
  return {
    title,
    key: 'cash',
    tableWidth: '100%',
    titleRow: [
      { content: 'Bank Name', width: '15%' },
      { content: 'Account Name', width: '25%' },
      { content: 'Account Number', width: '14.5%' },
      { content: 'Account Type', width: '12.5%' },
      { content: 'Statement Date', width: '12.5%' },
      { content: 'Closing Balance', width: '12.5%' },
      { content: 'Currency', width: '8%' },
    ],
    data: data.map(row => [
      {
        content: row.bank_name,
        position: 'center',
        width: '15%',
      },
      {
        content: row.account_name,
        position: 'center' as TableData['position'],
        width: '25%',
      },
      {
        content: row.account_number,
        position: 'center' as TableData['position'],
        width: '14.5%',
      },
      {
        content: row.account_type,
        position: 'center' as TableData['position'],
        width: '12.5%',
      },
      {
        content: row.balance_as_of,
        position: 'center' as TableData['position'],
        width: '12.5%',
      },
      {
        content: renderDecimal(row.balance_value),
        position: 'center' as TableData['position'],
        width: '12.5%',
      },
      {
        content: row.currency,
        position: 'center' as TableData['position'],
        width: '8%',
      },
    ]),
  }
}

export const generateEligiblePoolTableData = (
  poolData: EligiblePoolBalanceResponse[],
  title: string
): AllTableData => {
  return {
    title,
    key: 'eligible pool balance',
    tableWidth: '100%',
    titleRow: [
      { content: 'Days Past Due', width: '25%' },
      { content: 'Outstanding Principal (USD)', width: '25%' },
      { content: 'Outstanding Principal Balance multiplied by', width: '25%' },
      {
        content: 'Discounted Outstanding Principal Balance (USD)',
        width: '25%',
      },
    ],
    data: poolData.map(bucket => [
      {
        content: bucket.dpd_bucket,
        position: 'center',
        width: '25%',
      },
      {
        content: renderDecimal(bucket.outstanding_principal_balance),
        position: 'center',
        width: '25%',
      },
      {
        content: `${parseFloat(bucket.discount) * 100}%`,
        position: 'center',
        width: '25%',
      },
      {
        content: renderDecimal(bucket.discounted_outstanding_principal_balance),
        position: 'center',
        width: '25%',
      },
    ]),
  }
}

export const generateInterestReserveTableData = (
  data: InterestReserveResponse[],
  title: string
): AllTableData => {
  let total = 0
  const tableData = data.map(row => {
    total += row.interest_payment
    return [
      {
        content: moment.utc(row.interest_period).format('MMM-YY'),
        position: 'left' as TableData['position'],
        width: '50%',
      },
      {
        content: renderDecimal(row.interest_payment),
        position: 'center' as TableData['position'],
        width: '50%,',
      },
    ]
  })
  tableData.push([
    {
      content: 'Total',
      position: 'left' as TableData['position'],
      width: '50%',
    },
    {
      content: renderDecimal(total),
      position: 'center' as TableData['position'],
      width: '50%',
    },
  ])
  return {
    title,
    key: 'borrowing base interest reserve',
    tableWidth: '45%',
    titleRow: [
      { content: 'Interest Period', width: '50%' },
      { content: 'Interest Payment (USD)', width: '50%' },
    ],
    data: tableData,
  }
}

export const generateExcessConcentrationTableData = (
  data: ExcessConcentrationResponse[],
  title: string
): AllTableData => ({
  title,
  key: 'excess concentration',
  tableWidth: '100%',
  titleRow: [
    { content: 'Calculation Date', width: '50%' },
    { content: 'Amount (USD)', width: '50%' },
  ],
  data: data.map(row => [
    {
      content: moment(row.calculation_date).format('YYYY-MM-DD'),
      position: 'center' as TableData['position'],
      width: '50%',
    },
    {
      content: renderDecimal(row.amount),
      position: 'right' as TableData['position'],
      width: '50%',
    },
  ]),
})
