import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import BaseLayout from '@components/layouts/base-layout'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowPathIcon, PlusIcon } from '@heroicons/react/24/outline'
import BankAccount from '@interfaces/bank-account'
import { Card, CardBody } from '@material-tailwind/react'
import BanksService from '@services/api-admin/data-source-banks'

import { usePlaid } from './bank-connector/usePlaid'
import BankConnector from './bank-connector'
import BankList from './bank-list'
const BankOverviewL3 = () => {
  const { get_access } = useUserAccessFeature()
  const feature = `administration_sources_banking`
  const can_create = get_access(feature, 'create')
  const can_edit = get_access(feature, 'edit')

  const { company } = useContext(AuthContext)
  const { data, refetch, isLoading } = useQuery(
    ['banks', company?.slug_name],
    () => BanksService.getAllActiveAccounts(company?.slug_name),
    getStaleMins()
  )

  const activeBanks = data?.data.data
  const [open, setOpen] = useState(false)
  const { initializePlaid, isLoading: isLoadingPlaid } = usePlaid()
  const inactivePlaidAccount = activeBanks?.find(
    (acct: BankAccount) =>
      acct.data_source === 'Plaid' && acct.status === 'Inactive'
  )

  const handleOpen = () => setOpen(!open)
  const handleConnect = () => setOpen(!open)

  useEffect(() => {
    if (!isLoadingPlaid && isLoadingPlaid !== undefined) {
      refetch()
    }
  }, [isLoadingPlaid])

  return (
    <BaseLayout title="Bank Accounts">
      <Card>
        <CardBody>
          <div className="flex justify-between mb-5">
            <Typography variant="h6" className="mb-2">
              Active Bank Accounts
            </Typography>
            <div className="flex gap-4">
              {can_create && (
                <Button
                  onClick={handleOpen}
                  className="rounded-md w-fit"
                  color={'primary'}
                >
                  <PlusIcon className="w-5 h-5 mr-1" />
                  Add Account
                </Button>
              )}
              {inactivePlaidAccount && (
                <Button
                  onClick={() =>
                    initializePlaid({
                      slug_name: company?.slug_name ?? '',
                      access_token: JSON.parse(
                        inactivePlaidAccount?.data_source_supplementary
                      )?.access_token,
                    })
                  }
                  className="rounded-md w-fit"
                  color="primary"
                >
                  <ArrowPathIcon className="w-5 h-5 mr-1" />
                  Reconnect Account
                </Button>
              )}
            </div>
          </div>
          {isLoading ? (
            <span className="flex justify-center w-full">
              <ArrowPathIcon className="animate-spin w-6 h-6 text-primary-main " />
            </span>
          ) : (
            <BankList banksArray={activeBanks} can_edit={can_edit} />
          )}
        </CardBody>
      </Card>
      <BankConnector
        open={open}
        handleOpen={handleOpen}
        handleConnect={handleConnect}
        refetch={refetch}
      />
    </BaseLayout>
  )
}
export default BankOverviewL3
