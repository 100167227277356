import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from '@components/atoms/button'
import FilesUploadDropZone from '@components/file-upload-modal/drop-zone'
import SearchBar from '@components/searchbar'
import { SelectedTags, Selector } from '@components/selectors'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { ArrowPathIcon, PlusIcon } from '@heroicons/react/24/solid'

import { UpdateCompanyInfo } from '../../../../../../interfaces/accounting'
import { CURRENCIES_CODES } from '../../constants'
import { MAX_FILE_SIZE } from '../../constants'
import { COUNTRIES_LIST } from '../../constants/countries-list'
import { AccountTypeNames } from '../../interfaces/modal.interfaces'

import { State } from './state-manager'

interface ModalFormProps {
  submitForm: (data: any) => void
  // isLoading: boolean
  handleTags: () => void
  handleOnChange: (e: any, key: AccountTypeNames) => void
  accountData?: UpdateCompanyInfo
  setCurrency: (value: string) => void
  removeTag: (idx: number) => void
  fileUpload: (acceptedFile: Array<any>) => void
  children?: React.ReactNode
  isEdit: boolean
  handleOpenConfirmation: () => void
  handleCancel: () => void
  handleGeography: (e: any) => void
  state: State
}

const AccountingForm: React.FC<ModalFormProps> = ({
  submitForm,
  accountData,
  setCurrency,
  handleTags,
  removeTag,
  fileUpload,
  isEdit,
  handleOpenConfirmation,
  handleOnChange,
  handleCancel,
  handleGeography,
  state,
}) => {
  const { get_access } = useUserAccessFeature()
  const feature = `administration_sources_accounting`
  const can_delete = get_access(feature, 'delete')
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const checkKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(data => {
          setIsLoading(true)
          submitForm(data)
        })}
        onKeyDown={e => checkKeyDown(e)}
      >
        <>
          <div className="flex w-full gap-6 justify-between shrink-0 pt-3.5">
            <div className="block w-1/2 mb-2">
              <label className="font-normal text-left block text-black text-sm pb-2">
                Entity Legal Name
              </label>
              <input
                className="w-full text-sm h-9 border rounded-md border-[#E0E0E0] placeholder:text-xs px-3 py-2 focus:outline-[#0E9EDA]/[.55]
                          invalid:outline-[#FF3B30]/[.55] block
                          "
                {...register('entityName', {
                  required: {
                    value: true,
                    message: 'This is required',
                  },
                })}
                value={state?.populated?.entityName ?? ''}
                onChange={e => {
                  handleOnChange(e, AccountTypeNames.name)
                }}
                placeholder="Enter Company Legal Name"
                color="blue"
              />
              {errors?.entityName && (
                <p className="text-red text-xs mt-1">
                  {errors?.entityName?.message}
                </p>
              )}
            </div>
            <div className="block w-1/2 mb-2">
              <label className="font-normal text-left block text-black text-sm pb-2">
                Entity Nickname
              </label>
              <input
                className="w-full text-sm h-9 border rounded-md border-[#E0E0E0] placeholder:text-xs px-3 py-2 focus:outline-[#0E9EDA]/[.55]
                          invalid:outline-[#FF3B30]/[.55]
                          "
                {...register('entitySlugName', {
                  required: {
                    value: true,
                    message: 'This is required',
                  },
                })}
                value={state?.populated?.entitySlugName ?? ''}
                onChange={e => {
                  handleOnChange(e, AccountTypeNames.slugName)
                }}
                placeholder="Enter Company Nickname"
                color="blue"
              />
              {errors?.slugName && (
                <p className="text-red text-xs mt-1">
                  {errors?.slugName?.message}
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-between w-full item-center mt-4 mb-4">
            <div className="w-1/4">
              <label className="font-normal text-left block text-black text-sm pb-2">
                Currency
              </label>
              <Selector
                selectedOption={accountData?.currency ?? state?.currency}
                options={CURRENCIES_CODES}
                onSelectedOption={setCurrency}
              />
            </div>

            <div className="flex gap-3 item-center justify-end items-end w-full">
              <SearchBar
                onSearch={handleGeography}
                options={COUNTRIES_LIST}
                optionListKey="name"
                onSelectedOption={handleTags}
                queryInput={state?.populated.geography ?? ''}
                label="Geography"
                className="w-3/5"
              />
              <Button
                type="button"
                className="h-9 self-end transition-none focus:outline-none :hover none active:outline-none w-2/12 click:outline-none font-black px-0"
                onClick={() => handleTags()}
              >
                <span className="flex flex-row font-medium items-center">
                  <PlusIcon className="mr-1 w-5 h-5 " />
                  Add
                </span>
              </Button>
            </div>
          </div>
        </>

        <div
          className={`relative flex flex-grow-0 shrink max-w-lg px-2 justify-end  ${
            Array.isArray(state.tags) &&
            state.tags.length > 1 &&
            'overflow-x-scroll'
          } py-2`}
        >
          {state?.tags && (
            <SelectedTags
              selectedTags={accountData?.geography?.split(',') ?? state?.tags}
              onTagRemoved={removeTag}
            />
          )}
        </div>
        <div className="flex flex-col gap-4 w-full mt-4">
          <span className="text-sm font-medium  opacity-50 text-left ">
            Supported file formats - xlsx{'.'}
          </span>

          <FilesUploadDropZone
            isLoading={state.isLoading}
            uploader={fileUpload}
            handleCancel={handleCancel}
            maxFiles={1}
            maxSize={MAX_FILE_SIZE}
            titleMaxFilesSize="5MB"
          />
        </div>

        <div className="flex justify-evenly gap-4">
          {isEdit ? (
            <>
              {can_delete && (
                <Button
                  className="rounded-lg flex-1"
                  type="button"
                  onClick={handleOpenConfirmation}
                >
                  Delete
                </Button>
              )}

              <Button
                type="submit"
                className="rounded-lg flex-1"
                color={'primary'}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <ArrowPathIcon className="animate-spin w-6 h-6 text-primary-main mr-1" />
                    Updating...
                  </>
                ) : (
                  'Save'
                )}
              </Button>
            </>
          ) : (
            <>
              <Button
                type="submit"
                className="rounded-lg flex-1"
                color={'primary'}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <ArrowPathIcon className="animate-spin w-6 h-6 text-primary-main mr-1" />
                    Processing...
                  </>
                ) : (
                  'Add Company'
                )}
              </Button>
            </>
          )}
        </div>
      </form>
    </>
  )
}

export default AccountingForm
