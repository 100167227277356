import React from 'react'
import HelloSign from 'hellosign-embedded'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import { DROPBOX_SIGN_CLIENT_ID } from '@constants/config'
import { CategoriesResult } from '@interfaces/manage-reporting'
import {
  SignatureRequest,
  TemplateDialogProps,
} from '@interfaces/manage-signature'
import DocumentService from '@services/api-manage/docs'
import SignatureService from '@services/api-manage/signature'

interface SigningTemplateAction {
  docData: any
  handler: () => void
  dialogOpen: boolean
  renderDialog: (props: TemplateDialogProps) => JSX.Element
  category: CategoriesResult
  can_sign: boolean
}

const SigningTemplateAction = ({
  docData,
  handler,
  dialogOpen,
  renderDialog,
  category,
  can_sign,
}: SigningTemplateAction) => {
  const client = new HelloSign()
  const { user } = useAuth0()

  const handleSuccess = (data: any) => {
    toast.dismiss()
    if (data.data?.signature_request_id || data.message === 'Upload Success') {
      toast.success('Signature Request Created', { autoClose: 5000 })
      handler()
      return
    }
    if (data.data.claim_url) {
      if (can_sign) {
        client.open(data.data.claim_url, {
          clientId: DROPBOX_SIGN_CLIENT_ID,
          skipDomainVerification: true,
        })
        client.on('cancel', handler)

        client.on('error', handler)

        client.on('sign', handler)
      } else {
        handler()
      }
    } else {
      handler()
      toast.error('Unexpected Error Occurred', { autoClose: false })
    }
  }

  const handleError = () => {
    toast.error('An Error occurred during signature request creation', {
      autoClose: false,
    })
  }

  const { mutate: createEmbeddedSignRequest } = useMutation(
    (params: SignatureRequest) => {
      return SignatureService.createEmbeddedSignatureURL(params)
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  )

  const { mutate: uploadDocument } = useMutation(
    (formData: any) => {
      return DocumentService.uploadDocument(formData)
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  )

  const form = useForm({
    shouldUnregister: false,
  })

  return (
    <div>
      {dialogOpen &&
        renderDialog({
          form,
          user,
          createEmbeddedSignRequest,
          uploadDocument,
          handler,
          dialogOpen,
          docData: docData || {},
          category,
        })}
    </div>
  )
}

export default SigningTemplateAction
