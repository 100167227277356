import React from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, DialogBody, DialogHeader } from '@material-tailwind/react'

interface PropsOverlayContainer {
  children: React.ReactNode
  open: boolean
  handler: () => void
  className?: string
  title: string
}
const OverlayContainer = ({
  children,
  open,
  handler,
  className,
  title,
}: PropsOverlayContainer) => {
  return (
    <Dialog
      open={open}
      handler={() => handler()}
      className={
        className ?? 'overflow-auto z-40  max-w-none min-w-0 w-[35rem]'
      }
    >
      <DialogHeader>
        <div className={`flex justify-between w-full`}>
          <span className="text-lg font-bold">{title}</span>
          <XMarkIcon
            onClick={() => handler()}
            className="w-8 h-8 cursor-pointer opacity-50 hover:opacity-20"
          />
        </div>
      </DialogHeader>
      <DialogBody className="flex flex-col">{children}</DialogBody>
    </Dialog>
  )
}
export default OverlayContainer
