/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'

import { ENVIRONMENT } from '@constants/config'

import Sentry from './sentry/sentry.config'
import App from './app'
import reportWebVitals from './reportWebVitals'

import './index.css'

try {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID as string,
  }

  ENVIRONMENT == 'prod' && TagManager.initialize(tagManagerArgs)
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  )
} catch (error) {
  //Need to nullify the sentry DSN on prod temporarily.
  if (process.env.REACT_APP_DSN_SENTRY !== 'null') {
    Sentry.captureException(error)
  } else {
    console.error(error)
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
