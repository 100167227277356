import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from 'react-query'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import FormInput from '@components/form/form-input'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Alert } from '@material-tailwind/react'
import { AuditService } from '@services/api-manage/audit'

interface ReviewActionsProps {
  isLoadingItemData: boolean
  auditStatus: string
  auditPermissions: string
  setFileAdded: any
  isFileAdded: boolean
  getValues: any
  data: any
  register: any
  setValue: any
  refetchData: any
  currentIdx: number
  reset: any
  getNextArtifact: any
  itemId: string
  auditId: string
  setIsUploading: any
  total: number
  uploads: any
  auditType: string
}
export const ReviewActions = ({
  isLoadingItemData,
  auditStatus,
  auditPermissions,
  isFileAdded,
  getValues,
  setFileAdded,
  data,
  register,
  setValue,
  refetchData,
  currentIdx,
  reset,
  getNextArtifact,
  itemId,
  auditId,
  setIsUploading,
  total,
  uploads,
  auditType,
}: ReviewActionsProps) => {
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)
  const { activeDebtDeal } = appliedFilters
  const { debtDealOptions } = optionFilters

  const activeFacility = debtDealOptions?.[activeDebtDeal]
  const { user } = useAuth0()
  const [showError, setShowError] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [requestInfo, setRequestInfo] = useState<boolean>(false)
  const [isContinueNext, setIsContinueNext] = useState<boolean>(true)
  const filters = {
    slug_name: company?.slug_name,
    slug: company?.slug_name,
    id: itemId,
    user_id: user?.sub,
    type: auditType.toLowerCase(),
    facility: activeFacility?.facility,
  }

  //requestWithComment
  const { mutate: requestWithComment, isLoading: isUpdating } = useMutation(
    (comments: string) => {
      return AuditService.requestMoreInfo({
        ...filters,
        action: 'further',
        comment: comments,
      })
    },
    {
      onSuccess: () => handleSuccess(),
      onError: () => handleError(),
    }
  )

  //upload
  const { mutate: upload, isLoading: isUploading } = useMutation(
    () => {
      setIsUploading(true)

      const formData = new FormData()
      company?.slug_name && formData.append('slug_name', company.slug_name)
      company?.slug_name && formData.append('slug', company.slug_name)

      formData.append('audit_id', auditId)
      formData.append('id', itemId)
      formData.append('type', auditType.toLowerCase())
      formData.append('facility', activeFacility?.facility)

      user?.sub && formData.append('user_id', user.sub)
      Object.keys(uploads).map((ru: string) => {
        formData.append('files', uploads[ru][0], ru)
      })
      return AuditService.uploadForItem(formData, auditType.toLowerCase())
    },
    {
      onSuccess: () => handleSuccess(),
      onError: () => handleError(),
    }
  )

  //approve item
  const { mutate: approveItem, isLoading: isApproving } = useMutation(
    () => {
      return AuditService.reviewItem({ ...filters, action: 'approve' })
    },
    {
      onSuccess: () => handleSuccess(),
      onError: () => handleError(),
    }
  )

  //reject item
  const { mutate: rejectItem, isLoading: isRejecting } = useMutation(
    () => {
      return AuditService.reviewItem({ ...filters, action: 'reject' })
    },
    {
      onSuccess: () => handleSuccess(),
      onError: () => handleError(),
    }
  )

  const handleSuccess = () => {
    setRequestInfo(false)
    setIsUploading(false)
    setFileAdded(false)
    setIsSuccess(true)
    refetchData()
    setTimeout(() => {
      reset()
      if (isContinueNext && currentIdx + 1 < total) {
        getNextArtifact()
        setIsSuccess(false)
      }
    }, 1500)
  }

  const handleError = () => {
    setIsUploading(false)
    setShowError(true)
    setTimeout(() => {
      setShowError(false)
    }, 5000)
  }

  const isLoading =
    isLoadingItemData || isRejecting || isApproving || isUploading || isUpdating

  //reset Alert states
  useEffect(() => {
    setIsSuccess(false)
    setShowError(false)
  }, [currentIdx])

  return (
    <div className="flex flex-col w-full justify-center">
      <div className="w-full flex justify-center">
        {isLoading ? (
          <Button disabled>
            <ArrowPathIcon className="animate-spin w-7 mx-5" />
          </Button>
        ) : showError ? (
          <Alert
            className="flex bg-danger-main mb-6 text-center"
            onTap={() => setShowError(false)}
          >
            An error occured during operation
            <XMarkIcon className="w-5" />
          </Alert>
        ) : isSuccess ? (
          <Alert
            className="flex bg-green mb-6 text-center font-semibold justify-center relative"
            onTap={() => setIsSuccess(false)}
          >
            Succesfully updated!
            <XMarkIcon className="absolute right-10 top-5 w-5" />
          </Alert>
        ) : (
          <div className="flex flex-col w-full justify-center">
            {!(currentIdx + 1 >= total) && auditStatus != 'FINALISED' && (
              <div className="flex flex-row w-full justify-center">
                <FormInput
                  type="checkbox"
                  value={isContinueNext}
                  {...register('toNext', {
                    disabled: isLoading,
                    onChange: (e: any) => {
                      setIsContinueNext(e.target.checked)
                    },
                  })}
                />
                <div className="mt-1.5 text-sm">Continue to next item</div>
              </div>
            )}
            {auditPermissions.includes('upload') && (
              <div className="flex my-3.5 w-full justify-center">
                <Button
                  color="primary"
                  disabled={!isFileAdded || isLoading}
                  onClick={() => upload(getValues('file'))}
                >
                  {'Submit'}
                </Button>
              </div>
            )}
            {!requestInfo && auditPermissions.includes('approve/reject') && (
              <div className="flex my-3.5 justify-between w-full">
                <Button
                  disabled={
                    isLoading ||
                    !data.attachments?.length ||
                    data.data.audit_status == 'REJECTED'
                  }
                  color="secondary"
                  className="ml-3"
                  onClick={() => rejectItem()}
                >
                  {'Reject'}
                </Button>
                <Button
                  disabled={isLoading}
                  color="default"
                  className="mx-2"
                  onClick={() => setRequestInfo(!requestInfo)}
                >
                  {'Request More Information'}
                </Button>

                <Button
                  color="primary"
                  onClick={() => approveItem()}
                  disabled={
                    isLoading ||
                    !data.attachments?.length ||
                    data.data.audit_status == 'APPROVED'
                  }
                >
                  {'Approve'}
                </Button>
              </div>
            )}
            {!!requestInfo && auditPermissions.includes('approve/reject') && (
              <>
                <div className="flex w-full">
                  <Button
                    disabled={isLoading}
                    color="secondary"
                    className="mb-2"
                    onClick={() => setRequestInfo(!requestInfo)}
                  >
                    {'Cancel'}
                  </Button>
                  <div className="w-full px-10">
                    <FormInput
                      type="textarea"
                      value={getValues('comment')}
                      {...register('comment', {
                        disabled: isLoading,
                        onChange: (e: any) => {
                          setValue('comment', e.target.value)
                        },
                      })}
                    />
                  </div>

                  <Button
                    disabled={isLoading}
                    color="primary"
                    className="mb-2"
                    onClick={() => requestWithComment(getValues('comment'))}
                  >
                    {'Request'}
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
