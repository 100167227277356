import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'

import Button from '@components/atoms/button'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/solid'

import { UpdateStatementsRecordsParams } from '../../../../../../../interfaces/accounting'
import { SheetType } from '../../../interfaces/validation.interface'
import { Status } from '../../../interfaces/validation.interface'
import { useUpdateStatementsRecords } from '../../../service.runners.hooks'

import Description from './description-foot'
import Pagination from './pagination-controls'
import useValidationTable from './validation-table.hook'
interface ValidationTableProps<T> {
  entityId: string
  sheetType: SheetType
  slug_name: string
  data: T
  onClose: () => void
  handleStatus: (s: Status) => void
  handleUpdatedRecords: (s: Record<string, any>[]) => void
}

const ValidationTable = ({
  entityId,
  sheetType,
  slug_name,
  data,
  onClose,
  handleStatus,
  handleUpdatedRecords,
}: ValidationTableProps<Record<string, any>>) => {
  const { updateStatementsRecords } = useUpdateStatementsRecords()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const footNote = useRef<HTMLDivElement>(null)
  const {
    headers,
    currentData,
    incomingData,
    currentIndex,
    updateClicked,
    handleUpdateClick,
    hasUpdatedRecords,
    setCurrentIndex,
    updatedRecords,
  } = useValidationTable(data, sheetType)

  useEffect(() => {
    if (footNote.current) {
      footNote.current.scrollTop = footNote.current.scrollHeight
    }
  }, [footNote.current])

  const validate = () => {
    if (updatedRecords.length === 0) {
      setIsLoading(true)
      return setTimeout(() => {
        setIsLoading(false)
        handleStatus(Status.PASSED)
        onClose()
        toast.warn('No records were updated', { autoClose: 5000 })
      }, 1000)
    }
    const params: UpdateStatementsRecordsParams = {
      entityId,
      slug_name,
      sheetType,
      statementData: updatedRecords,
    }
    setIsLoading(true)
    setTimeout(() => {
      updateStatementsRecords(params)
      setIsLoading(false)
      handleUpdatedRecords(updatedRecords)
      handleStatus(Status.PASSED) // Update status
      onClose()
    }, 1000)
  }

  return (
    <div className="fixed z-[999] top-0 left-0 h-[calc(100dvh)] w-full overflow-hidden bg-gray-600 bg-opacity-75 flex items-center justify-center">
      <div
        className="p-4 w-full md:w-[75%] max-w-5xl h-[80%] max-h-5xl mx-4 md:mx-0 bg-white rounded-lg shadow-lg overflow-auto"
        ref={footNote}
      >
        <div className="flex justify-between items-center sticky top-0 bg-white rounded-t-lg shadow-md">
          <div className="flex items-center">
            <h2 className="text-2xl font-bold py-4 px-4 ">{sheetType}</h2>
            {updatedRecords.length > 0 && (
              <span className=" text-black-100 font-medium bg-gray-100 text-sm p-2 ml-1">
                Records updated: {updatedRecords.length}
              </span>
            )}
          </div>
          <XMarkIcon
            onClick={onClose}
            className="h-8 w-8 flex-shrink-0 mr-2 opacity-75 cursor-pointer"
          />
        </div>
        <div className="overflow-auto shadow-md max-h-[70%] mt-4 p-4">
          {incomingData.length > 0 && currentData.length > 0 && (
            <table className="w-full border-collapse border border-gray-300">
              <thead className="text-sm text-left py-2 bg-gray-100 text-black-500 font-medium">
                <tr>
                  <th className="px-2 py-2 border border-gray-300 ">Names</th>
                  <th className="px-2 py-2 border border-gray-300">
                    Data on Record{' '}
                    {updatedRecords.length > 0 && (
                      <span className="text-white font-normal bg-green text-xs p-1 ml-1 h-[14px]">
                        Updated
                      </span>
                    )}
                  </th>
                  <th className="px-2 py-2 border border-gray-300">
                    Incoming Data
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(headers).map(
                  (headerKey: string, index: number) => {
                    const current =
                      headerKey === 'Statement close date'
                        ? moment(
                            currentData[currentIndex][headers[headerKey]]
                          ).format('YYYY-MM-DD')
                        : currentData[currentIndex][headers[headerKey]]
                    const incoming =
                      incomingData[currentIndex][headers[headerKey]]
                    const isFlagged = current !== incoming

                    return (
                      <tr
                        key={index}
                        className={`border-b border-gray-300 ${
                          isFlagged ? 'bg-yellow' : ''
                        } `}
                      >
                        <td className="px-2 text-sm font-medium text-black-400 border border-gray-300 ">
                          {headerKey}
                        </td>
                        <td className="p-2 text-sm font-normal border border-gray-300">
                          {current}
                        </td>
                        <td className="p-2 text-sm  font-normal border border-gray-300 ">
                          {incoming}
                        </td>
                      </tr>
                    )
                  }
                )}
              </tbody>
            </table>
          )}
          {incomingData.length === 0 && (
            <div className="text-center text-gray-500">
              All data has been validated
            </div>
          )}
        </div>
        <div className="flex items-center justify-between px-4 py-2 bg-gray-100 rounded-b-lg shadow-md mt-4 ">
          <Pagination
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            currentData={currentData}
          />
          {hasUpdatedRecords() && (
            <div className="flex items-center ">
              <Button
                className="text-sm flex items-center justify-center"
                onClick={validate}
                size="sm"
                color="primary"
              >
                {isLoading ? (
                  <ArrowPathIcon
                    fill="white"
                    className="animate-spin w-6 h-6 text-white"
                  />
                ) : (
                  'Complete'
                )}
              </Button>
            </div>
          )}
          {!hasUpdatedRecords() && (
            <>
              <div className="flex gap-2 justify-center">
                <Button
                  onClick={handleUpdateClick}
                  disabled={updateClicked}
                  size="sm"
                  className="text-sm text-white"
                  color="primary"
                >
                  Update all
                </Button>
                <Button
                  onClick={onClose}
                  size="sm"
                  className=" border disabled:bg-gray-500 disabled:cursor-not-allowed"
                >
                  Re-upload
                </Button>
              </div>
            </>
          )}
        </div>

        <Description />
      </div>
    </div>
  )
}

export default ValidationTable
