import React, { useLayoutEffect } from 'react'

import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive'

interface PieChartProps {
  /**
   * chart data
   */
  data: any
  id: string
  categoryField: string
  valueField: string
  label1Text?: string
  label2Text?: string
  label3Text?: string
}
const PieChart = ({
  id,
  data,
  categoryField,
  valueField,
  label1Text,
  label2Text,
  label3Text,
}: PieChartProps) => {
  useLayoutEffect(() => {
    const root = am5.Root.new(`pie-chart${id}`)

    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Responsive.new(root),
    ])
    // root.container._settings.paddingTop = 24
    root.container._settings.paddingBottom = 20
    // root.container._settings.paddingLeft = -400

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(95),
        innerRadius: am5.percent(70),
      })
    )

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: valueField,
        categoryField: categoryField,
        legendLabelText: '{category}',
        legendValueText: '{value}',
      })
    )

    series.data.setAll(data)

    series.appear(1000, 100)
    //legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        paddingLeft: 10,
        centerX: am5.percent(-200),
        x: am5.percent(50),
        layout: root.verticalLayout,
        height: am5.percent(100),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: 'vertical',
        }),
      })
    )
    //sets legend to be vertical aligned and grid
    legend.itemContainers.template.setAll({
      layout: am5.GridLayout.new(root, {
        maxColumns: 2,
      }),
    })
    //changes markers to circles
    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
      width: 10,
      height: 10,
      centerY: -30,
      centerX: 10,
    })
    legend.labels.template.setAll({
      fontSize: 14,
      fontWeight: '300',
    })

    legend.valueLabels.template.setAll({
      fontSize: 15,
      fontWeight: '600',
    })

    legend.data.setAll(series.dataItems)
    series.labels.template.set('visible', false)
    series.ticks.template.set('visible', false)
    // Add label
    const label1 = root.tooltipContainer.children.push(
      am5.Label.new(root, {
        x: am5.percent(50),
        y: am5.percent(40),
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        fill: am5.color(0x757575),
        fontSize: 14,
        fontWeight: '500',
      })
    )
    const label2 = root.tooltipContainer.children.push(
      am5.Label.new(root, {
        x: am5.percent(50),
        y: am5.percent(50),
        centerX: am5.percent(50),
        centerY: am5.percent(60),
        fill: am5.color(0x000000),
        fontSize: 50,
        fontWeight: '600',
      })
    )
    const label3 = root.tooltipContainer.children.push(
      am5.Label.new(root, {
        x: am5.percent(50),
        y: am5.percent(60),
        centerX: am5.percent(50),
        centerY: am5.percent(90),
        fill: am5.color(0x757575),
        fontSize: 12,
        fontWeight: '500',
      })
    )
    label1 && label1.set('text', label1Text)
    label2 && label2.set('text', label2Text)
    label3 && label3.set('text', label3Text)

    root?._logo?.dispose()
    return () => {
      root?.dispose()
    }
  }, [])

  return (
    <div
      id={`pie-chart${id}`}
      className={`w-full min-h-[450px] relative`}
    ></div>
  )
}

export default PieChart
