import { StyleSheet } from '@react-pdf/renderer'

import { styles } from '../template-style'

export const autochekStyles = StyleSheet.create({
  body: {
    ...styles.body,
    width: '100%',
    paddingTop: 8,
    marginRight: 35,
    marginLeft: 80,
  },

  signTags: {
    ...styles.signTags,
  },

  text: {
    fontFamily: 'Times-Roman',
    margin: 8,
    marginLeft: 0,
    fontSize: 8,
  },

  textAddressIndent: {
    fontFamily: 'Times-Roman',
    margin: 8,
    fontSize: 8,
    marginLeft: 48,
  },

  textBig: {
    fontFamily: 'Times-Roman',
    marginVertical: 10,
    fontSize: 12,
  },

  textBigBold: {
    fontFamily: 'Times-Bold',
    fontSize: 12,
  },

  textBigBoldUnderline: {
    fontFamily: 'Times-Bold',
    fontSize: 12,
    textDecoration: 'underline',
  },

  textBold: {
    fontFamily: 'Times-Bold',
    margin: 8,
    marginLeft: 0,
    fontSize: 8,
  },

  textContainer: {
    display: 'flex',
    width: '90%',
    maxWidth: '90%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },

  textContainerWithMargin: {
    display: 'flex',
    width: '90%',
    maxWidth: '90%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginVertical: 8,
  },

  textLettered: {
    fontFamily: 'Times-Roman',
    fontSize: 8,
    paddingLeft: 24,
    marginRight: 22,
  },

  textLetteredBig: {
    fontFamily: 'Times-Roman',
    fontSize: 12,
    marginLeft: 24,
    marginRight: 22,
  },

  textNoMargin: {
    fontFamily: 'Times-Roman',
    fontSize: 8,
  },

  textNoMarginBig: {
    fontFamily: 'Times-Roman',
    fontSize: 12,
  },

  textNumberedNumber: {
    fontFamily: 'Times-Roman',
    fontSize: 8,
    paddingRight: 20,
  },

  title: {
    textAlign: 'center',
    fontSize: 8,
    fontFamily: 'Times-Bold',
    marginBottom: 10,
  },

  titleWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
