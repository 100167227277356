import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import StatisticL5 from '@components/layouts/l5-stat'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/risk'
import { DelinquencyService } from '@services/api-analytics/risk-delinquency'

const DelinqByCohortL5 = ({ exportable }: { exportable: boolean }) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const { dateStart, dateEnd, categoryTypes = [], activeType } = appliedFilters

  const filters: RisksFilters = {
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name,
    filters: categoryTypes,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['delinqByCohort', filters],
    () => DelinquencyService.getDelinqByCohort(filters),
    getStaleMins()
  )

  const { data, ...growthRatios } = fetchedData ?? {}

  const chartData = (data || []).map(x => {
    const typecasetedX = x as unknown as { [key: string]: number | string }
    const result: { x: number; [key: string]: number } = {
      x: moment(typecasetedX.cohort).valueOf(),
    }
    Object.keys(x).forEach((key: string) => {
      result[key] = parseFloat((typecasetedX[key] ?? 0).toString())
    })

    return result
  })
  const series = [
    { field: 'dbc_paid', label: 'Paid Off' },
    { field: 'dbc0', label: '0 DPD' },
    { field: 'dbc1', label: '1-14 DPD' },
    { field: 'dbc15', label: '15-29 DPD' },
    { field: 'dbc30', label: '30-59 DPD' },
    { field: 'dbc60', label: '60-89 DPD' },
    { field: 'dbc90', label: '90-119 DPD' },
    { field: 'dbc120', label: '120-179 DPD' },
    { field: 'dbc180', label: '180+ DPD' },
  ]

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  return (
    <L5ChartstatLayout
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`delinqByCohort_by_${activeType}`}
          yLabel="Percentage"
          yFormat="#.0a%"
          data={chartData}
          series={series.map(s => ({
            ...s,
            tooltipValueFormat: '#.0a%',
            type: 'ColumnSeries',
            isStack: true,
          }))}
          exportable={exportable}
          error={error as { message: string }}
        />
      }
      stat={
        <StatisticL5
          description="percentage at 90DPD"
          classSetting={{ up: 'text-danger-hover', down: 'text-success-hover' }}
          {...growthRatios}
        />
      }
    />
  )
}

export default DelinqByCohortL5
