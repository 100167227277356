import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { Facility } from '@interfaces/facility'
import { DashboardService } from '@services/api-manage/monitor-dashboard'

import Overview from '../overview/overview'

import MonitorDashboardTabL4 from './tab'

export const useDashboard = () => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)

  const { activeDebtDeal = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]
  const facility = activeFacility?.facility
  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility,
  }
  const isMasterFacility = activeFacility?.is_master_facility

  const { data, isFetching } = useQuery(
    ['covenant-list', filters],
    () => DashboardService.getComponent(filters),
    {
      ...getStaleMins(),
      enabled:
        !!debtDealOptions?.[activeDebtDeal]?.facility &&
        !!company?.slug_name &&
        !isMasterFacility,
    }
  )

  const tabs = isMasterFacility
    ? (debtDealOptions ?? [])
        .filter((f: Facility) => f.slug_name)
        .map((f: Facility) => ({
          label: f.facility,
          component: <Overview facility={f} />,
        }))
    : (data ?? [])
        .reduce((p: any[], c) => {
          if (p.find(x => x.label === c.tab)) {
            return p
          }
          return [
            ...p,
            {
              label: c.tab,
              component: (
                <MonitorDashboardTabL4
                  items={(data ?? []).filter(x => x.tab === c.tab)}
                />
              ),
            },
          ]
        }, [])
        .sort((a, b) => (a.label < b.label ? -1 : 1))

  return { data, isFetching, tabs, facility }
}
