import React, { useContext } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { CloudArrowDownIcon } from '@heroicons/react/24/outline'
import BankAccount from '@interfaces/bank-account'
import BanksService from '@services/api-admin/data-source-banks'

interface BankAccordionBodyProps {
  bank: BankAccount
}

const BankAccordionBody = ({ bank }: BankAccordionBodyProps) => {
  const { company } = useContext(AuthContext)
  const schemaNr = bank.schema_no
  const { data } = useQuery(
    [bank.schema_no, company?.slug_name],
    () => BanksService.getStatementBalances(company?.slug_name, schemaNr),
    getStaleMins()
  )
  const statements = data?.data.data

  const downloadStatement = async (id: string) => {
    if (company?.slug_name) {
      const url = await BanksService.downloadStatement(company.slug_name, id)
      //triggers download with the url
      window.open(url.data, '_blank')
    }
  }
  return (
    <div className="flex flex-col w-full mx-20 max-h-52 overflow-y-auto">
      <div className="grid grid-cols-5 gap-4 w-full pl-10 p-2 font-semibold text-neutral-subtitle-3 border-b-2 border-neutral-border-2 ">
        <span>Statement</span>
        <span>Account Balance</span>
        <span>Date Uploaded</span>
        <span>Source</span>
        <span>{''}</span>
      </div>
      {statements?.map((statement: any) => (
        <div
          className="grid grid-cols-5 gap-4 w-full pl-10 p-5 border-t border-neutral-border-2"
          key={statement.id}
        >
          <span>
            {moment(statement.value_date, 'YYYY-MM-DD HH:mm:ss.SSS ZZ').format(
              'MMMM-YYYY'
            )}{' '}
            {bank.bank_id}
          </span>
          <span>{statement.balance}</span>
          <span>
            {moment(
              statement.collected_at,
              'YYYY-MM-DD HH:mm:ss.SSS ZZ'
            ).format('DD-MMM-YYYY')}
          </span>
          <span>Manual</span>
          <span>
            <CloudArrowDownIcon
              className="stroke-blue w-6 hover:opacity-50 cursor-pointer"
              onClick={() => downloadStatement(statement.id)}
            />
          </span>
        </div>
      ))}
    </div>
  )
}
export default BankAccordionBody
