/**
 * https://github.com/creativetimofficial/material-tailwind/blob/main/packages/material-tailwind-react/src/theme/components/select/index.ts
 */
export default {
  styles: {
    base: {
      container: {
        minWidth: 'min-w-[90px]',
      },
      label: {
        display: 'hidden',
      },
      menu: {
        p: 'p-0',
      },
      option: {
        initial: {
          px: 'px-4',
          py: 'py-2',
          background: 'hover:bg-secondary-surface focus:bg-secondary-surface',
          color: 'hover:text-primary-main focus:text-primary-main',
        },
        active: {
          bg: 'bg-primary-surface-2 bg-opacity-80',
          color: 'text-blue-gray-900',
        },
      },
    },
    variants: {
      outlined: {
        sizes: {
          md: {
            container: {
              child:
                '[&>button]:border-t-neutral-border-2 [&>button]:border-neutral-border-2',
            },
            select: {
              py: 'py-2',
              px: 'px-4',
              flex: 'flex items-center justify-between',
              children: '[&>*]:relative',
              div: '[&>div]:w-4 [&>div]:h-4 [&>div]:transform-none [&>div]:top-[0px] [&>div]:right-[0px]',
              span: '[&>span]:top-0 [&>span]:left-0 [&>span]:transform-none [&>span]:pt-[0px] [&>span]:top-[0px] [&>span]:left-[0px]',
              borderColor: 'border-primary-main',
            },
          },
        },
        states: {
          open: {
            select: {
              border: 'border',
            },
          },
        },
      },
    },
  },
}
