import React from 'react'

import { Text, View } from '@react-pdf/renderer'

import { styles } from './style'

interface Props {
  title?: string
  titleStyle?: Record<string, string | number>
  title2?: string
  title2Style?: Record<string, string | number>
  subtitle?: string
  subtitleStyle?: Record<string, string | number>
  value1?: string
  value2?: string
  valuesStyle?: Record<string, string | number>
}

const Row = ({
  title = '',
  titleStyle = {},
  title2 = '',
  title2Style = {},
  subtitle = '',
  subtitleStyle = {},
  value1 = '',
  value2 = '',
  valuesStyle = {},
}: Props) => {
  return (
    <View style={styles.tableRow}>
      {title2 ? (
        <View style={styles.tableCellLeft}>
          <View style={styles.highlighted}>
            <Text style={titleStyle}>
              {title}
              <Text style={title2Style}>{title2 ? ` ${title2}` : ''}</Text>
            </Text>
          </View>
        </View>
      ) : (
        <View style={styles.tableCellLeft}>
          <Text style={titleStyle}>
            {title}
            <Text style={title2Style}>{title2 ? ` ${title2}` : ''}</Text>
          </Text>
          {subtitle && <Text style={subtitleStyle}>{subtitle}</Text>}
        </View>
      )}
      <View style={styles.tableCellRight}>
        <Text style={value1 ? valuesStyle : { ...styles.text, width: '50%' }}>
          {value1 ?? ' '}
        </Text>
        {value2 && <Text style={valuesStyle}>{value2}</Text>}
      </View>
    </View>
  )
}

export default Row
