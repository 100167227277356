import React, { useContext, useEffect } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import MultiLevelTable from '@components/multi-level-table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { FinancialsFilters } from '@interfaces/financial'
import { Alert, Typography } from '@material-tailwind/react'
import { StatementService } from '@services/api-analytics/financials-statement'

interface ProfitAndLossL5Props {
  hasDataHandler: (hasData: boolean) => void
}
const ProfitAndLossL5 = ({ hasDataHandler }: ProfitAndLossL5Props) => {
  const { appliedFilters, company, optionFilters } = useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    financialEntity,
    currency,
    financialEntityCurrency,
    rate,
    historicalRate,
  } = appliedFilters

  const filters: FinancialsFilters = {
    date_from: moment(dateStart).format('YYYY-MM-DD'),
    date_to: moment(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    entity_id: financialEntity,
  }

  const { error, data, isFetching } = useQuery(
    ['pnl', filters],
    () => StatementService.getPnL(filters),
    getStaleMins()
  )

  const currencyOptions = {
    default: financialEntityCurrency,
    code: currency,
    historicalRates: historicalRate,
    ratePrimary:
      financialEntityCurrency == company?.currencies_available?.[0]?.to_currency
        ? { code: currency, rate: rate }
        : optionFilters.rates?.find(
            (r: { code: string; rate: number }) =>
              r.code == financialEntityCurrency
          ) ?? { code: financialEntityCurrency, rate: 1 },
    rateSecondary:
      financialEntityCurrency == company?.currencies_available?.[0]?.to_currency
        ? optionFilters.rates?.find(
            (r: { code: string; rate: number }) =>
              r.code == financialEntityCurrency
          )
        : optionFilters.rates?.find(
            (r: { code: string; rate: number }) => r.code == currency
          ) ?? { code: financialEntityCurrency, rate: 1 },
  }

  useEffect(() => {
    hasDataHandler(!!(data as any)?.data)
  }, [data])

  return (
    <div>
      {!isFetching ? (
        error ? (
          <div
            className={`flex flex-col w-full min-h-[400px] justify-center items-center`}
          >
            <Alert className="w-1/2 text-danger-main border border-danger-main text-center">
              Unexpected Error has occured.
            </Alert>
          </div>
        ) : (
          <>
            {!(data as any)?.data && (
              <div
                className={`flex flex-col w-full min-h-[400px] justify-center items-center`}
              >
                <Alert className="w-1/2 text-neutral-subtitle-3 border border-primary-border text-center">
                  No available data to display
                </Alert>
              </div>
            )}
            {(data as any)?.data && (
              <MultiLevelTable dataSet={data} currencyUtil={currencyOptions} />
            )}
            {data && (
              <div className="m-5">
                <Typography className="text-sm font-medium text-left">
                  Notes
                </Typography>
                <div className="flex mt-2">
                  <Typography className="text-xs font-light text-left">
                    1 :
                  </Typography>
                  <Typography className="text-xs font-light text-left pl-1">
                    For lending companies, revenue includes all interest and fee
                    income earned from the asset portfolio.
                  </Typography>
                </div>
                <div className="flex mt-2">
                  <Typography className="text-xs font-light text-left">
                    2 :
                  </Typography>
                  <Typography className="text-xs font-light text-left pl-1">
                    For lending companies, CoGS includes interest expense from
                    debt investoris for capital that is used to lend, and
                    write-offs/provisions
                  </Typography>
                </div>
                <div className="flex mt-2">
                  <Typography className="text-xs font-light text-left">
                    3 :
                  </Typography>
                  <Typography className="text-xs font-light text-left pl-1">
                    Non-operating income includes currency impact, investment
                    income/loss, or ther items not related to the core business.
                  </Typography>
                </div>
              </div>
            )}
          </>
        )
      ) : (
        <div className="w-full h-[300px] flex justify-center items-center">
          <ArrowPathIcon className="animate-spin text-primary-main w-8" />
        </div>
      )}
    </div>
  )
}

export default ProfitAndLossL5
