import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import Pagination from '@components/pagination'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { BorrowingBaseService } from '@services/api-manage/borrowing-base'

const renderDecimal = (value?: any) =>
  Intl.NumberFormat(undefined, {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value)

const CollateralL4 = () => {
  const { company, optionFilters, appliedFilters, activeFilters } =
    useContext(AuthContext)

  const { activeDebtDeal = 0, calculationDate } = activeFilters
  const {
    activeDebtDeal: appliedDebtDeal,
    calculationDate: appliedCalculationDate,
    isLastCalculationDateOfMonth: isLastDateOfMonth,
  } = appliedFilters
  const { debtDealOptions = [], appliedCalculationDateOption = [] } =
    optionFilters
  const activeFacility = debtDealOptions?.[appliedDebtDeal]

  const isFullApplied =
    activeDebtDeal === appliedDebtDeal &&
    calculationDate === appliedCalculationDate
  const isMostRecentDate =
    appliedCalculationDate ===
    appliedCalculationDateOption?.[0]?.calculation_date

  const poolFilters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility ?? '',
    date: isMostRecentDate ? undefined : appliedCalculationDate,
  }

  const { data: poolData } = useQuery(
    ['pools', poolFilters],
    () => BorrowingBaseService.getCollateralPoolList(poolFilters),
    {
      ...getStaleMins(),
      enabled: (isLastDateOfMonth || isMostRecentDate) && isFullApplied,
    }
  )

  const tabs = ['All', ...(poolData ?? []).map(x => x.pool)]

  const [activePool, setActivePool] = useState<string>('All')
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([])
  const PER_PAGE = 10
  const LIMITS = Array(5)
    .fill('')
    .map((_, i) => PER_PAGE * (i + 1))
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(LIMITS[0])

  const filters = {
    ...poolFilters,
    pool: activePool === 'All' ? undefined : activePool,
    page: page + 1,
    per_page: perPage,
  }

  const { data, isFetched } = useQuery(
    ['collaterals', filters],
    () => BorrowingBaseService.getCollateralList(filters),
    {
      ...getStaleMins(),
      enabled:
        (poolData ?? []).length > 0 &&
        (isLastDateOfMonth || isMostRecentDate) &&
        isFullApplied,
    }
  )

  let separatorIndex: number
  const firstData: { [key: string]: any } = (data?.data ?? [])[0] ?? {}
  const [columns, expandedColumns] = Object.keys(firstData).reduce(
    (_p: any[], _c, _i) => {
      let [_columns, _expandedColumns] = _p
      if (_c === 'separator') {
        separatorIndex = _i
      }
      const isNumber = typeof firstData[_c] === 'number'

      const new_column = {
        title: _c,
        field: _c,
        align: isNumber ? 'right' : 'center',
        head: { align: 'center' },
        render: (r: any) => (isNumber ? renderDecimal(r[_c]) : r[_c]),
      }

      _columns = !separatorIndex ? [..._columns, new_column] : _columns

      _expandedColumns =
        !!separatorIndex && _c !== 'separator'
          ? [..._expandedColumns, new_column]
          : _expandedColumns

      return [_columns, _expandedColumns]
    },
    [[], []]
  )

  const expandedRowRender = (record: any) => {
    return (
      <div className="grid grid-cols-3">
        {expandedColumns.map((item: any, i: number) => (
          <div
            key={i}
            className={`flex justify-between px-4 py-2 border-neutral-border-2 ${
              i % 3 === 2 ? '' : 'border-r'
            } ${i < expandedColumns.length - 3 ? 'border-b' : ''}`}
          >
            <span className="font-medium">{item.title}</span>
            <span>{item.render(record)}</span>
          </div>
        ))}
      </div>
    )
  }
  const onExpand = (index: number) => {
    const indexes = expandedIndexes.includes(index)
      ? expandedIndexes.filter(i => i !== index)
      : [...expandedIndexes, index]

    setExpandedIndexes(indexes)
  }

  useEffect(() => {
    setPage(0)
  }, [activePool])

  return (
    <div className="flex flex-col p-4">
      {isLastDateOfMonth || isMostRecentDate ? (
        <>
          <div className="flex justify-center">
            <div className="rounded p-0.5 bg-neutral-surface-2 flex items-center mb-6">
              {tabs.map((f, i) => (
                <button
                  key={i}
                  onClick={() => setActivePool(f)}
                  className={`rounded text-sm font-medium min-w-[150px] px-4 py-0.5 capitalize ${
                    activePool === f
                      ? 'bg-secondary-main text-neutral-white'
                      : 'text-neutral-body-2'
                  }`}
                >
                  {f.split('_').join(' ')}
                </button>
              ))}
            </div>
          </div>
          <Table
            loading={!isFetched}
            columns={columns}
            data={data?.data}
            expandable={{
              expandedIndexes,
              expandedRowRender,
              onExpand,
            }}
            containerClass="secondary"
          />
          <Pagination
            id="user-table-pagination"
            total={data?.total ?? 0}
            page={page}
            onChangePage={p => setPage(p)}
            limit={PER_PAGE}
            limits={LIMITS}
            onChangeLimit={l => setPerPage(l)}
          />
        </>
      ) : (
        <Typography className="text-center">
          Collateral is captured on the final day of each month or maintained on
          a daily basis. Historic days that are not the end of the month are
          currently not supported for collateral lookup. For specific help on a
          query contact
          <a
            href="mailto:admin@cascadedebt.com?subject=Collateral suuport"
            className="cursor-pointer text-primary-main mx-1"
          >
            admin@cascadedebt.com
          </a>
          for support.
        </Typography>
      )}
    </div>
  )
}

export default CollateralL4
