import React, { useContext, useState } from 'react'
import moment from 'moment'
import { useInfiniteQuery, useMutation } from 'react-query'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  AdvanceRequestLogResponse,
  AdvanceRequestResponse,
} from '@interfaces/manage-monitor-action'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import SignatureHistoryDialog from '@pages/manage/monitor/actions/document-centre/dialogs/audit-dialog'
import { ActionService } from '@services/api-manage/monitor-action'

interface DialogHistoryAdvanceRequestProps {
  open?: boolean
  handler: () => void
  data?: AdvanceRequestResponse
}

const DialogHistoryAdvanceRequest = ({
  open = false,
  handler,
  data,
}: DialogHistoryAdvanceRequestProps) => {
  const [signedImageOpen, setSignedImageOpen] = useState<boolean>(false)
  const PER_PAGE = 15

  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeDebtDeal = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const filter = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    page: 1,
    per_page: PER_PAGE,
  }

  const {
    fetchNextPage,
    status,
    isFetchingNextPage,
    hasNextPage,
    data: historyData,
  } = useInfiniteQuery({
    queryKey: ['advance_request_history', filter, data?.id],
    queryFn: ({ pageParam = 0 }) => {
      return ActionService.getHistoryAdvanceRequest(data?.id ?? 0, {
        ...filter,
        page: pageParam + 1,
      })
    },
    getNextPageParam: (lastPage, pages: any[]) => {
      const hasNextPage =
        pages?.reduce(
          (prev: any[], cur: any) => [...prev, ...(cur.data ?? [])],
          []
        ).length < lastPage?.total
      return hasNextPage
    },
    enabled: open,
  })

  const list = (historyData?.pages ?? []).reduce(
    (prev: any[], cur: any) => [...prev, ...(cur.data ?? [])],
    []
  )

  const { mutate: downloadAdvanceRequest } = useMutation(
    (params: { slug_name: string; file: string }) => {
      return ActionService.downloadAdvanceRequest(params)
    },
    {
      onSuccess: res => {
        window.open(String(res), '_blank')
      },
    }
  )

  return (
    <>
      <Dialog
        open={open}
        handler={() => undefined}
        className="px-4 py-2"
        size="lg"
      >
        <DialogHeader className="text-xl flex justify-between">
          Advance Request History
          <XMarkIcon
            onClick={() => handler()}
            className="w-6 h-6 cursor-pointer"
          />
        </DialogHeader>
        <DialogBody className="text-sm text-black flex flex-col max-h-[80vh] overflow-y-auto overflow-x-hidden">
          {status === 'loading' ? (
            <div className="flex justify-center items-center">
              <ArrowPathIcon className="animate-spin text-primary-main w-4" />
            </div>
          ) : (
            <>
              {list.map((l: AdvanceRequestLogResponse, i) => {
                const log = JSON.parse(l.to)
                return (
                  <div
                    key={l.id}
                    className={`
                  relative pl-8
                  before:content-[''] before:absolute before:top-[4px] before:left-0 before:w-[10px] before:h-[10px] before:bg-primary-main before:rounded-full before:outline-double before:outline-primary-main
                  ${
                    i < list.length - 1 || hasNextPage
                      ? `after:content-[''] after:absolute after:top-[16px] after:left-[5px] after:w-[1px] after:h-[calc(100%-16px)] after:bg-primary-main`
                      : ``
                  }
                `}
                  >
                    <div className="flex flex-col">
                      <Typography className="text-sm font-medium">
                        {moment.utc(l.at).format('LLLL')}
                      </Typography>
                      <Typography className="text-sm">{`${l.by_name} ${l.action} request`}</Typography>
                      {l.action !== 'nullified' && (
                        <>
                          <Table
                            containerClass="mt-4 [&_*]:!text-sm"
                            columns={[
                              {
                                title: 'Capital Requested',
                                field: 'amount',
                                align: 'right',
                                head: { align: 'center' },
                                render: (r: any) =>
                                  Intl.NumberFormat(undefined, {
                                    maximumFractionDigits: 2,
                                  }).format(r.amount),
                              },
                              {
                                title: 'Document',
                                field: 'file',
                                align: 'center',
                                render: (r: any) => (
                                  <Button
                                    onClick={() => {
                                      if (r.file.includes('.pdf')) {
                                        downloadAdvanceRequest({
                                          slug_name:
                                            activeFacility?.slug_name ??
                                            company?.slug_name ??
                                            '',
                                          file: r.file,
                                        })
                                      } else {
                                        setSignedImageOpen(true)
                                      }
                                    }}
                                  >
                                    View
                                  </Button>
                                ),
                              },
                            ]}
                            data={[log]}
                          />
                          {log.lenders?.length > 0 && (
                            <Table
                              containerClass="mt-4 [&_*]:!text-sm"
                              columns={[
                                {
                                  title: 'Lender',
                                  field: 'lender',
                                  align: 'center',
                                  width: 120,
                                  render: (r: any) =>
                                    r.lender?.replaceAll('_', ' '),
                                },
                                {
                                  title: 'Accrual Date',
                                  field: 'funded_date',
                                  align: 'center',
                                  width: 120,
                                  render: (r: any) =>
                                    r.funded_date
                                      ? moment
                                          .utc(r.funded_date)
                                          .format('YYYY-MM-DD')
                                      : '',
                                },
                                {
                                  title: 'Additional Note',
                                  field: 'comment',
                                  align: 'center',
                                  width: 240,
                                  render: (r: any) => r.comment || '-',
                                },
                                {
                                  title: 'Proof of Transfer',
                                  field: 'proof_of_transfer_file',
                                  render: (r: any) => {
                                    if (r.proof_of_transfer_file) {
                                      return (
                                        <Button
                                          onClick={() =>
                                            downloadAdvanceRequest({
                                              slug_name:
                                                activeFacility?.slug_name ??
                                                company?.slug_name ??
                                                '',
                                              file: r.proof_of_transfer_file,
                                            })
                                          }
                                        >
                                          View
                                        </Button>
                                      )
                                    }
                                  },
                                },
                              ]}
                              data={log.lenders}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </DialogBody>
        <DialogFooter className="flex justify-center">
          {hasNextPage && (
            <Button
              onClick={() => fetchNextPage()}
              color="primary"
              disabled={isFetchingNextPage}
            >
              {isFetchingNextPage && (
                <ArrowPathIcon className="w-4 h-4 mr-2 text-primary-main animate-spin" />
              )}
              Load More
            </Button>
          )}
        </DialogFooter>
      </Dialog>
      <SignatureHistoryDialog
        docData={data?.signature_request}
        open={signedImageOpen}
        handler={() => setSignedImageOpen(false)}
      />
    </>
  )
}

export default DialogHistoryAdvanceRequest
