import React from 'react'

import { AdvanceRequestResponse } from '@interfaces/manage-monitor-action'

const statuses = {
  pending: 'Pending',
  signed: 'Signed / Not Funded',
  funded: 'Funded',
  nullified: 'Nullified',
}

const StatusLabel = ({ request }: { request: AdvanceRequestResponse }) => {
  const status = request.status
    ? statuses[request.status as keyof typeof statuses]
    : null
  return (
    <div className="flex justify-center">
      <span
        className={`py-0.5 px-4 rounded-full capitalize text-xs ${
          request.status === 'funded'
            ? 'text-success-main border border-success-main bg-success-surface'
            : request.status === 'pending'
            ? 'text-warning-main border border-warning-main bg-warning-surface'
            : request.status === 'signed'
            ? 'text-primary-main border border-primary-main  bg-primary-surface-2'
            : request.status === 'nullified'
            ? 'text-neutral-border-3 border border-neutral-border-2  bg-neutral-border-1'
            : ''
        }`}
      >
        {status}
      </span>
    </div>
  )
}

export default StatusLabel
