interface tableColumn {
  content: string
  position?: 'left' | 'right'
  style?: Record<string, any>
  bold?: boolean
}

export const mfsTableRow = (
  column1: tableColumn,
  column2: tableColumn,
  column3: tableColumn
) => [
  { width: '10%', content: column1.content, position: 'left' as const },
  {
    width: '55%',
    content: column2.content,
    position: 'left' as const,
  },
  {
    width: '35%',
    content: column3.content,
    position: (column3.position ?? 'right') as tableColumn['position'],
    style: column3.style,
    bold: column3.bold ?? false,
  },
]
