import React from 'react'

import { AdvanceRequestTemplateProps } from '@interfaces/manage-signature'

import AutochekDeloreanNigeriaTemplate from './autochek/delorean-nigeria-advance-request-template'
import AutochekLendableTemplate from './autochek/lendable-ci-advance-request-template'
import FlexpathAdvanceRequestTemplate from './flexpath/flexpath-advance-request-template'
import DefaultAdvanceRequestTemplate from './advance-request-template'

const renderTemplate = (
  subcategory: string,
  templateProps: AdvanceRequestTemplateProps
) => {
  switch (subcategory) {
    case 'default': {
      return <DefaultAdvanceRequestTemplate {...templateProps} />
    }
    case 'autochek-lendable': {
      return <AutochekLendableTemplate {...templateProps} />
    }
    case 'autochek-delorean-nigeria': {
      return <AutochekDeloreanNigeriaTemplate {...templateProps} />
    }
    case 'flexpath-facility1': {
      return <FlexpathAdvanceRequestTemplate {...templateProps} />
    }
    default: {
      return <div />
    }
  }
}

export default renderTemplate
