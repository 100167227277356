export default {
  defaultProps: {
    color: 'primary',
  },
  valid: {
    colors: ['primary', 'secondary'],
  },
  styles: {
    base: {
      container: {
        padding: 'p-1.5',
      },
      label: {
        color: 'text-neutral-black',
        padding: 'mx-2',
      },
    },
    colors: {
      primary: {
        background: 'checked:bg-primary-main',
        border: 'checked:border-primary-main',
        before: 'checked:before:bg-primary-main',
      },
      secondary: {
        background: 'checked:bg-secondary-main',
        border: 'checked:border-secondary-main',
        before: 'checked:before:bg-secondary-main',
      },
    },
  },
}
