import { AccountTypeNames } from '../../interfaces/modal.interfaces'

export type PopulatedState = {
  [key in AccountTypeNames | 'geography' | 'tags']: string
}
export type State = {
  isLoading: boolean
  isDelete: boolean
  uploadedStatement: File[]
  populated: PopulatedState
  tags: string[]
  currency: string
}
export enum ACTIONS_TYPES {
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_DELETE = 'SET_IS_DELETE',
  SET_UPLOADED_STATEMENT = 'SET_UPLOADED_STATEMENT',
  SET_POPULATED = 'SET_POPULATED',
  SET_TAGS = 'SET_TAGS',
  SET_CURRENCY = 'SET_CURRENCY',
}

type Action =
  | { type: ACTIONS_TYPES.SET_IS_LOADING; payload: boolean }
  | { type: ACTIONS_TYPES.SET_IS_DELETE; payload: boolean }
  | { type: ACTIONS_TYPES.SET_UPLOADED_STATEMENT; payload: File[] }
  | { type: ACTIONS_TYPES.SET_POPULATED; payload: PopulatedState }
  | { type: ACTIONS_TYPES.SET_TAGS; payload: string[] }
  | { type: ACTIONS_TYPES.SET_CURRENCY; payload: string }

export const initialState: State = {
  isLoading: false,
  isDelete: false,
  uploadedStatement: [],
  populated: {} as PopulatedState,
  tags: [],
  currency: 'USD',
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ACTIONS_TYPES.SET_IS_LOADING:
      return { ...state, isLoading: action.payload }
    case ACTIONS_TYPES.SET_IS_DELETE:
      return { ...state, isDelete: action.payload }
    case ACTIONS_TYPES.SET_UPLOADED_STATEMENT:
      return { ...state, uploadedStatement: action.payload }
    case ACTIONS_TYPES.SET_POPULATED:
      return { ...state, populated: action.payload }
    case ACTIONS_TYPES.SET_TAGS:
      const uniqueTags = Array.from(new Set(action.payload))
      return { ...state, tags: uniqueTags }
    case ACTIONS_TYPES.SET_CURRENCY:
      return { ...state, currency: action.payload }
    default:
      return state
  }
}
