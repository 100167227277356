import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import CustomButton from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import Pagination from '@components/pagination'
import Table from '@components/table'
import { Role } from '@constants/role'
import AuthContext from '@contexts/auth'
import { getProviderLogo } from '@helpers/auth-provider'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { getStaleMins } from '@helpers/stale-timer'
import {
  EllipsisHorizontalIcon,
  EnvelopeIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { UserResponse } from '@interfaces/admin-settings-user'
import {
  Avatar,
  Card,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from '@material-tailwind/react'
import { UserService } from '@services/api-admin/settings-user'

import DialogDeleteUser from './dialog-delete'
import DialogFormUser from './dialog-form'
import DialogResendInvitation from './dialog-resend-invitation'

interface UserTableProps {
  isExternal: boolean
}

const UserTable = ({ isExternal }: UserTableProps) => {
  const { get_access } = useUserAccessFeature()
  const feature = `administration_setting_users`
  const can_create = get_access(feature, 'create')
  const can_edit = get_access(feature, 'edit')
  const can_delete = get_access(feature, 'delete')

  const { company } = useContext(AuthContext)
  const now = moment()
  const PER_PAGE = 5
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)

  useEffect(() => {
    setPage(0)
  }, [company?.slug_name])

  const params = {
    slug_name: company?.slug_name ?? '',
    is_external: isExternal,
    per_page: perPage,
    page,
  }

  const { data, isFetching, refetch } = useQuery(
    ['userList', params],
    () => UserService.getUsers(params),
    getStaleMins()
  )

  enum ACTIONS {
    Create = 1,
    Update,
    Delete,
    ResendInvitation,
  }
  const [action, setAction] = useState<ACTIONS | undefined>(undefined)
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)
  const selectedData = data?.data.find(d => d.user_id === selectedId)

  const _handler = (needUpdate?: boolean) => {
    if (needUpdate) {
      if (!selectedData) {
        setPage(0)
      }
      setTimeout(() => {
        refetch()
      }, 500)
    }
    setAction(undefined)
    setSelectedId(undefined)
  }

  const columns = [
    {
      title: 'No.',
      field: 'index',
      align: 'center',
      render: (_: any, i: number) => page * perPage + (i + 1),
    },
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Role',
      align: 'center',
      render: (record: UserResponse) => {
        return (
          <span className="capitalize">
            {record.user_metadata?.companies?.[company?.slug_name ?? '']
              ?.control || Role.staff}
          </span>
        )
      },
    },
    {
      title: 'Social',
      align: 'center',
      render: (record: UserResponse) => {
        return record.identities?.map((x, i) =>
          x.provider === 'auth0' ? (
            ''
          ) : (
            <Avatar
              key={x.provider + i}
              src={getProviderLogo(x.provider)}
              size="xs"
              className="mr-2"
            />
          )
        )
      },
    },
    {
      title: 'Status',
      align: 'center',
      render: (record: UserResponse) => {
        const isActive = now.diff(moment(record.last_login), 'month') < 1
        return (
          <span
            className={`border rounded-full px-2 py-1 text-xs ${
              isActive
                ? 'border-success-main text-success-main bg-primary-success-hover'
                : 'border-primary-main text-primary-main bg-primary-surface-2'
            }`}
          >
            {isActive ? 'Active' : 'Inactive'}
          </span>
        )
      },
    },
    {
      title: 'Last Login',
      align: 'center',
      render: (record: UserResponse) => {
        return record.last_login ? moment(record.last_login).fromNow() : 'Never'
      },
    },
    {
      title: '',
      width: 50,
      render: (record: UserResponse) => {
        const show_burger = !record.last_login || can_edit || can_delete
        return show_burger ? (
          <Menu placement="left-end">
            <MenuHandler>
              <button className="p-2 text-sm cursor-pointer rounded hover:bg-primary-surface-2 hover:text-primary-main">
                <EllipsisHorizontalIcon className="w-4 h-4" />
              </button>
            </MenuHandler>
            <MenuList>
              {!record.last_login && (
                <MenuItem
                  className="flex"
                  onClick={() => {
                    setAction(ACTIONS.ResendInvitation)
                    setSelectedId(record.user_id)
                  }}
                >
                  <EnvelopeIcon className="mr-2 w-4 h-4" />
                  Resend Invitation Email
                </MenuItem>
              )}
              {can_edit && (
                <MenuItem
                  className="flex"
                  onClick={() => {
                    setAction(ACTIONS.Update)
                    setSelectedId(record.user_id)
                  }}
                >
                  <PencilIcon className="mr-2 w-4 h-4" />
                  Edit User
                </MenuItem>
              )}
              {can_delete && (
                <MenuItem
                  className="text-danger-main flex"
                  onClick={() => {
                    setAction(ACTIONS.Delete)
                    setSelectedId(record.user_id)
                  }}
                >
                  <TrashIcon className="mr-2 w-4 h-4" />
                  Delete User
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        ) : (
          <></>
        )
      },
    },
  ]

  return (
    <>
      <DialogFormUser
        isExternal={isExternal}
        data={selectedData}
        open={!!action && [ACTIONS.Create, ACTIONS.Update].includes(action)}
        handler={_handler}
      />
      <DialogDeleteUser
        data={selectedData}
        open={action === ACTIONS.Delete && !!selectedData}
        handler={_handler}
      />

      <DialogResendInvitation
        data={selectedData}
        open={action === ACTIONS.ResendInvitation && !!selectedData}
        handler={_handler}
      />
      <Card className="rounded-lg my-6 p-6">
        <div className="flex justify-between items-center mb-6">
          <Typography
            variant="b1"
            className="font-semibold text-neutral-body-1"
          >
            {`${isExternal ? 'External' : 'Internal'} Users`}
          </Typography>
          {can_create && (
            <CustomButton
              color="primary"
              onClick={() => {
                setAction(ACTIONS.Create)
                setSelectedId(undefined)
              }}
            >
              <PlusIcon className="mr-4 w-4 h-4" />
              Invite New user
            </CustomButton>
          )}
        </div>
        <Table
          columns={columns as any}
          data={data?.data}
          loading={isFetching}
        />
        <Pagination
          id="user-table-pagination"
          total={data?.total ?? 0}
          page={page}
          onChangePage={p => setPage(p)}
          limit={perPage}
          limits={Array(2)
            .fill('')
            .map((_, i) => PER_PAGE * (i + 1))}
          onChangeLimit={l => {
            setPage(0)
            setPerPage(l)
          }}
        />
      </Card>
    </>
  )
}

export default UserTable
