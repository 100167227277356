import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  coverPage: {
    alignItems: 'center',
    textAlign: 'center',
  },
  coverTitle: {
    fontSize: 30,
    fontStyle: 'bold',
    color: '#0E9EDA',
    textAlign: 'center',
    paddingVertical: '160px',
  },
  sectionCover: {
    fontSize: 14,
    fontStyle: 'bold',
    textAlign: 'center',
    paddingVertical: '170px',
  },
  sectionTitle: {
    fontSize: 14,
    fontStyle: 'bold',
    color: '#0E9EDA',
    textAlign: 'left',
    paddingVertical: '10px',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 14,
    color: '#0E9EDA',
    margin: 12,
  },
  text: {
    margin: 3,
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
})
