import React from 'react'

const NoteBoxSkeleton = () => {
  return (
    <div className="flex flex-row w-full items-center animate-pulse">
      <div className="w-12 h-12 mx-2 mt-3 bg-gray-300 rounded-full"></div>
      <div className="flex flex-col mt-3 w-full">
        <div className="w-32 h-4 mb-2 bg-gray-300 rounded"></div>
        <div className="w-24 h-3 bg-gray-300 rounded"></div>
      </div>
    </div>
  )
}

export default NoteBoxSkeleton
