import React, { Fragment, useContext, useReducer } from 'react'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import BaseLayout from '@components/layouts/base-layout'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { PlusIcon } from '@heroicons/react/24/outline'
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid/'
import { Card, CardBody } from '@material-tailwind/react'

import AuthContext from '../../../../contexts/auth'

import { ACTIONS_TYPES } from './shared/actions'
import { apiReducer, StateType } from './shared/api-states.reducer'
import CustomContext from './shared/local-context'
import TableContainer from './table/container'
import {
  ACTIONS,
  CASCADE_FOLDER_NAME_FINANCIAL_TEMPLATE,
  CASCADE_TEMPLATE_BUCKET_NAME,
} from './constants'
import { downloadFile } from './service.runners.hooks'
const INITIAL_STATE: StateType = {
  loading: false,
  error: '',
  reload: false,
  message: '',
  isSuccess: false,
  schemaName: '',
}

const AccountingOverviewL3 = () => {
  const { get_access } = useUserAccessFeature()
  const feature = `administration_sources_accounting`
  const can_create = get_access(feature, 'create')

  const [state, dispatch] = useReducer(apiReducer, INITIAL_STATE)
  const { company } = useContext(AuthContext)
  const downloadStatement = async () => {
    const url = await downloadFile({
      bucketName: CASCADE_TEMPLATE_BUCKET_NAME,
      folderName: CASCADE_FOLDER_NAME_FINANCIAL_TEMPLATE,
      slug_name: company?.slug_name,
    })
    //triggers download with the url
    window.open(url, '_blank')
  }

  const DownloadButton = () => {
    return (
      <Fragment>
        <Button
          onClick={() => downloadStatement()}
          className="flex items-center gap-2  rounded-md outline-1"
        >
          <span>
            <ArrowDownTrayIcon className="w-4 h-4" />
          </span>
          Template
        </Button>
      </Fragment>
    )
  }

  return (
    <BaseLayout title="Accounting">
      <Card>
        <CardBody>
          <div className="flex justify-between mb-5">
            <div>
              <Typography variant="h6" className="mb-2">
                Active Entities
              </Typography>
            </div>
            <div className="flex items-center gap-2">
              <DownloadButton />
              {can_create && (
                <Button
                  onClick={() =>
                    dispatch({
                      type: ACTIONS_TYPES.OPEN_MODAL,
                      payload: {
                        modalPayload: {
                          actions: ACTIONS.NEW_COMPANY,
                          isOpenModal: true,
                          title: 'Add Entity',
                        },
                      },
                    })
                  }
                  className="rounded-md"
                  color="primary"
                >
                  <span className="bold text-lg mr-1">
                    <PlusIcon className="mr-2 w-4 h-4" />
                  </span>
                  Add Entity
                </Button>
              )}
            </div>
          </div>
          <CustomContext.Provider
            value={{
              state,
              dispatch,
            }}
          >
            <TableContainer />
          </CustomContext.Provider>
        </CardBody>
      </Card>
    </BaseLayout>
  )
}

export default AccountingOverviewL3
