import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/risk'
import { DelinquencyService } from '@services/api-analytics/risk-delinquency'

const BorrowerConcentrationL5 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    categoryTypes = [],
    activeType,
    currency = 'USD',
  } = appliedFilters
  const { rates = [], display_rates = [] } = optionFilters

  const filters: RisksFilters = {
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name,
    filters: categoryTypes,
  }

  const { error, data, isFetching } = useQuery(
    ['BorrowerConcentration', filters],
    () => DelinquencyService.getBorrowerConcentration(filters),
    getStaleMins()
  )

  const chartData = (data?.data || []).map(x => {
    const typecasetedX = x as unknown as { [key: string]: number | string }
    const result: { x: number; [key: string]: number } = {
      x: moment(typecasetedX.cohort).valueOf(),
      ...(rates ?? []).reduce((p = {}, r: any) => {
        return {
          ...p,
          [`fx_${r.code}`]: Intl.NumberFormat(undefined, {
            style: 'decimal',
            notation: 'compact',
            maximumFractionDigits: 2,
          }).format(r.display_rates?.[typecasetedX.cohort] ?? 1),
        }
      }, {}),
    }
    Object.keys(x).forEach((key: string) => {
      result[key] = parseFloat((typecasetedX[key] ?? 0).toString())
    })

    return result
  })
  const series = [
    { label: 'Top 10 Borrowers', field: 'bc10' },
    { label: 'Top 11-20 Borrowers', field: 'bc20' },
    { label: 'Top 21-50 Borrowers', field: 'bc50' },
    { label: 'Top 51-99 Borrowers', field: 'bc100' },
    { label: 'Top 100+ Borrowers', field: 'bc_rem' },
  ]

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  return (
    <L5ChartstatLayout
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`BorrowerConcentration_by_${activeType}`}
          yLabel="Percentage"
          yFormat="#.0a%"
          data={chartData}
          series={series.map(s => ({
            ...s,
            tooltipValueFormat: '#.0a%',
            type: 'ColumnSeries',
            isStack: true,
          }))}
          tooltipSubtitle={
            showHistoricalRateIndicator
              ? (display_rates ?? []).map((r: any) => {
                  return {
                    field: `fx_${r.code}`,
                    title: `USD:${r.code}`,
                  }
                })
              : undefined
          }
          exportable={exportable}
          error={error as { message: string }}
        />
      }
    />
  )
}

export default BorrowerConcentrationL5
