import React, { useState } from 'react'
import moment from 'moment'

import { ArrowPathIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

import SheetsAccordionBody from './table-level-three'

interface AccountListProps {
  data: Array<any>
  isExpanded: boolean
}

const AccountAccordionBody = ({ data }: AccountListProps) => {
  const [open, setOpen] = useState<number | null>()
  const handleOpen = (id: number) => {
    setOpen(open === id ? null : id)
  }

  return (
    <>
      <div className="grid grid-cols-5 text-sm font-medium text-neutral-subtitle-3 center border-b-2  py-3 pl-6 text-left">
        <div className="">Statement</div>
        <div className="">Last Update</div>
      </div>
      {data.map((statement: any, idx: number) => {
        return (
          <div key={idx}>
            <div className="border-b  py-4 grid grid-cols-5 w-full ">
              <div className="text-xs md:text-sm font-light pl-6">
                <ChevronRightIcon
                  className={`${
                    open === idx ? 'rotate-90 stroke-red' : ''
                  } absolute h-4 w-4 transition-transform cursor-pointer`}
                  onClick={() => handleOpen(idx)}
                />
                <span className="pl-8 text-black/[0.8]">
                  {statement.sheetType}
                </span>
              </div>
              <div className="text-xs md:text-sm font-light text-left w-full pl-6 text-black/[0.8]">
                <span>
                  {moment(statement.lastUpdated).format('YYYY-MM-DD')}{' '}
                </span>
              </div>
            </div>
            {!data && (
              <div className="p-3 align-middle absolute top-1/2 left-1/2">
                <ArrowPathIcon className="animate-spin w-6 h-6 text-primary-main" />
              </div>
            )}
            <div
              className={`overflow-hidden
                            transform origin-top
                            transition-all ease-out 
                            ${open === idx ? 'scale-y-100' : 'scale-y-0'}
                            `}
            >
              {open === idx && <SheetsAccordionBody data={statement.data} />}
            </div>
          </div>
        )
      })}
    </>
  )
}
export default AccountAccordionBody
