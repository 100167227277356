export default {
  defaultProps: {
    color: 'primary',
    ripple: true,
  },
  valid: {
    colors: ['primary', 'secondary'],
  },
  styles: {
    base: {
      root: {
        default:
          '[&>label>div>svg]:h-2.5 [&>label>div>svg]:w-2.5 [&>label>div>svg]:fill-primary-main',
      },
      label: {
        color: 'text-neutral-subtitle-2',
        fontSize: 'text-sm',
      },
      container: {
        padding: 'p-2',
      },
    },
    colors: {
      primary: {
        border: 'checked:border-primary-main',
        before: 'checked:before:bg-primary-main',
      },
      secondary: {
        border: 'checked:border-secondary-main',
        before: 'checked:before:bg-secondary-main',
      },
    },
  },
}
