import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useMutation } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import BaseLayout from '@components/layouts/base-layout'
import { MAX_LOANTABLE_SIZE } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { downloadS3File } from '@helpers/file-downloader'
import {
  ArrowDownTrayIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import {
  Card,
  CardBody,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import LoantapeService from '@services/api-analytics/data-source-loantape'
import BigDataLoantapeService from '@services/api-secondary/data-source-loantape'

const LoantapeOverviewL3 = () => {
  const { company } = useContext(AuthContext)
  const { get_access, user } = useUserAccessFeature()
  const feature = `analytics_data-exports_loan-tape`
  const can_export = get_access(feature, 'export')

  const [searchParams] = useSearchParams()

  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false)
  const [isAbbr, setIsAbbr] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [startDateExport, setStartDateExport] = useState<string>('')
  const [endDateExport, setEndDateExport] = useState<string>('')
  const [dateErr, setDateErr] = useState<string>('')
  const [isOverSize, setIsOverSize] = useState<boolean>(false)

  const OpenExportDialog = (isAbbr: boolean) => {
    setIsAbbr(isAbbr)
    setExportDialogOpen(!exportDialogOpen)
  }

  const beginDownloadLoantape = async () => {
    OpenExportDialog(isAbbr)
    setIsProcessing(true)
    try {
      /**@brief
       * this request may timeout due to AWS api-gateway limitations.
       * However lambda will continue to process and notify the user once the export is ready.
       */
      if (company) {
        !isOverSize
          ? LoantapeService.downloadLoantape(
              isAbbr,
              company.date_start,
              company.date_end,
              user?.email,
              company.slug_name
            )
          : BigDataLoantapeService.downloadLoantape(
              isAbbr,
              company.date_start,
              company.date_end,
              user?.email,
              company.slug_name
            )
        toast.success(
          <p className="text-sm">
            {' '}
            Request received. Please allow up-to 15 mins for an email to arrive
            at {user?.email}.
          </p>,
          {
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
          }
        )
      }
    } catch (error) {
      toast.error(
        <p className="text-sm">
          We encountered an unexpected issue. Please try again!
        </p>,
        {
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
        }
      )
    }
    setIsProcessing(false)
  }

  const checkExportAvailability = async () => {
    const size = await LoantapeService.getLoantableSize(company?.slug_name)
    setIsOverSize(size.data[0].pg_total_relation_size > MAX_LOANTABLE_SIZE)
  }

  const { mutate: downloadFile } = useMutation(async (params: any) => {
    const { slugName, hierarchy1 } = params
    const urlRes = await LoantapeService.getLoantapePresignedUrl(
      slugName,
      hierarchy1
    )
    const presignedUrl = urlRes.data
    return downloadS3File(
      presignedUrl,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      `${company?.slug_name}${
        presignedUrl.match('abbreviated_loan_info') ? '_abbreviated_' : '_'
      }loan_tape_${moment().format('DD_MM_YYYY')}`
    )
  })

  useEffect(() => {
    const decodedFilename = searchParams.get('fileName')
    if (company?.slug_name && decodedFilename) {
      const getUrlParams = {
        slugName: company.slug_name,
        hierarchy1: decodedFilename,
      }
      downloadFile(getUrlParams)
    }
  }, [])

  useEffect(() => {
    if (company) {
      checkExportAvailability()
      setEndDateExport(company.date_end_cohort)
      setStartDateExport(
        moment(company.date_end_cohort)
          .subtract(90, 'days')
          .format('YYYY-MM-DD')
      )
    }
  }, [company])

  useEffect(() => {
    moment(endDateExport).diff(moment(startDateExport), 'days') > 91
      ? setDateErr('Date range cannot exceed a quarter')
      : setDateErr('')
  }, [startDateExport, endDateExport])

  return (
    <BaseLayout title="Loantape">
      <Card>
        {!searchParams.get('fileName') ? (
          <CardBody>
            {company?.slug_name ? (
              <>
                <div className="div">
                  <Typography variant="h6">Loan Tape</Typography>
                </div>
                <div className="flex justify-between ">
                  <div className="flex flex-col my-6">
                    <span className="text-sm text-neutral-body-2">
                      Complete loan tape export including expected payments,
                      real payments and collateral information.
                    </span>
                  </div>
                  <div>
                    {can_export && (
                      <Button
                        disabled={isProcessing}
                        className="rounded-md flex-1"
                        color={'default'}
                        onClick={() => OpenExportDialog(false)}
                      >
                        <ArrowDownTrayIcon className="w-6 h-6 mx-2" />
                        {`Export`}
                      </Button>
                    )}
                  </div>
                </div>
                <div className="div">
                  <Typography variant="h6">Abbreviated Loan Tape</Typography>
                </div>
                <div className="flex justify-between ">
                  <div className="flex flex-col my-6">
                    <span className="text-sm  text-neutral-body-2">
                      Simple loan tape with a snapshot of data; does not
                      including expected payments or real payments.
                    </span>
                  </div>
                  <div>
                    {can_export && (
                      <Button
                        disabled={isProcessing}
                        className="rounded-md flex-1"
                        color={'default'}
                        onClick={() => OpenExportDialog(true)}
                      >
                        <ArrowDownTrayIcon className="w-6 h-6 mx-2" />
                        {`Export`}
                      </Button>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="flex justify-between">
                <div className="flex flex-row gap-4">
                  <ExclamationCircleIcon className="w-7 h-7 stroke-orange mt-1" />
                  <Typography variant="h6"> No Loantape Found </Typography>
                </div>
                <div>
                  <Button
                    className="rounded-md flex-1 w-full"
                    color={'primary'}
                  >
                    {`+ Connect Loantape`}
                  </Button>
                </div>
              </div>
            )}
          </CardBody>
        ) : (
          <CardBody>
            {' '}
            <div className="flex">
              <InformationCircleIcon className="w-8 text-primary-main ml-10 mr-5 -mt-1 cursor-help" />
              <Typography variant="b1">
                Your download should begin soon. If not, please refresh this
                page.
              </Typography>
            </div>
          </CardBody>
        )}
      </Card>
      <Dialog
        open={exportDialogOpen}
        handler={OpenExportDialog}
        className="overflow-auto"
      >
        <DialogHeader>
          <div className="flex justify-between w-full">
            <span>Are you Sure?</span>
            <XMarkIcon
              onClick={() => OpenExportDialog(isAbbr)}
              className="w-8 h-8 cursor-pointer hover:opacity-50"
            />
          </div>
        </DialogHeader>
        <DialogBody divider>
          <div className="flex flex-col">
            <p className="text-sm py-5">
              {`We will prepare your export and notify you via email at ${user?.email} when the download is ready. This may take upto 15 mins.`}
            </p>
            <p className="text-sm pb-5">
              {`Select 'Yes' to submit the export request`}
            </p>
          </div>
        </DialogBody>
        <DialogFooter>
          <div className="flex justify-between">
            <Button
              disabled={dateErr != ''}
              className="rounded-lg flex-1 mx-2"
              color={'primary'}
              onClick={beginDownloadLoantape}
            >
              Yes
            </Button>
            <Button
              className="rounded-lg flex-1 mx-2"
              color={'default'}
              onClick={() => OpenExportDialog(isAbbr)}
            >
              No
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </BaseLayout>
  )
}
export default LoantapeOverviewL3
