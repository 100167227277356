export const ACTIONS_TYPES = {
  FETCH_SUCCESS: 'API_STATES_FETCH_SUCCESS',
  FETCH_ERROR: 'API_STATES_FETCH_ERROR',
  FETCH_LOADING: 'API_STATES_FETCH_CANCEL',
  UPDATE_UI: 'UPDATE_UI',
  OPEN_MODAL: 'OPEN_MODAL',
  OPEN_ALERT: 'OPEN_ALERT',
  OPEN_DROPZONE: 'OPEN_DROPZONE',
  RESET_RELOAD_STATE: 'RESET_RELOAD_STATE',
  SET_SCHEMA_NAME: 'SET_SCHEMA_NAME',
  OPEN_VALIDATION_MODAL: 'OPEN_VALIDATION_MODAL',
}
