export const CREDIT_FACILITY_ECONOMICS_MOCK = [
  {
    economics: 'Interest',
    tranche: 'All',
    paid_to: 'Lender 1',
    paid_by: 'Borrower',
    rate: '15%',
  },
  {
    economics: 'Interest',
    tranche: 'All',
    paid_to: 'Lender 2',
    paid_by: 'Borrower',
    rate: '21%',
  },
  {
    economics: 'Default Interest',
    tranche: 'All',
    paid_to: 'Lenders',
    paid_by: 'Borrower',
    rate: '6%',
  },
  {
    economics: 'Profit Share',
    tranche: 'All',
    paid_to: 'Lender 2',
    paid_by: 'Borrower',
    rate: '8%',
    notes: 'Paid as residual of last item on the waterfall',
  },
]

export const FACILITY_PAYMENT_MOCK = [
  {
    category: 'Payment Date',
    term: 'End of month',
  },
  {
    category: 'Draw Period',
    term: '1 year',
  },
  {
    category: 'Facility Period',
    term: '4 years',
  },
]

export const FACILITY_TRANCHE_MOCK = [
  {
    tranche: 'Tranche 1',
  },
  {
    tranche: 'Tranche 2',
  },
  {
    tranche: 'Tranche 3',
  },
  {
    tranche: 'Tranche 4',
  },
]

export const PREPAYMENT_DETAIL_MOCK = [
  {
    prepayment: 'Prepayment allowed',
    year_1: 'No',
    year_2: 'yes',
    year_3: 'Yes',
    year_4: 'Yes',
  },
  {
    prepayment: 'Prepayment Fee',
    year_1: '3%',
    year_2: '3%',
    year_3: '3%',
    year_4: '3%',
  },
]
