import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart, { SeriesData } from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/risk'
import { CharacteristicService } from '@services/api-analytics/risk-characteristic'

const SEPARATOR = '_'

const DistributionL5 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes,
    activeType,
    currency = 'USD',
  } = appliedFilters
  const { rates = [], display_rates = [] } = optionFilters

  const filters: RisksFilters = {
    date_from: moment.utc(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEndCohort).format('YYYY-MM-DD'),
    filters: categoryTypes,
    is_distribution: true,
    slug_name: company?.slug_name,
  }

  const { error, data, isFetching } = useQuery(
    ['termsDistribution', filters],
    () => CharacteristicService.getTerms(filters),
    getStaleMins()
  )

  const chartData: SeriesData[] = ((data?.bar_data?.data || []) as any[]).map(
    x => {
      const { cohort, ...rest } = x
      const result: { [key: string]: number } = {
        x: moment(cohort).valueOf(),
        ...(rates ?? []).reduce((p = {}, r: any) => {
          return {
            ...p,
            [`fx_${r.code}`]: Intl.NumberFormat(undefined, {
              style: 'decimal',
              notation: 'compact',
              maximumFractionDigits: 2,
            }).format(r.display_rates?.[cohort] ?? 1),
          }
        }, {}),
      }
      Object.keys(rest).forEach(k => {
        result[k] = parseFloat((rest[k] ?? 0).toString())
      })
      return result as SeriesData
    }
  )

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  return (
    <L5ChartstatLayout
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`ristCharacteristicTermsDistribution_by_${activeType}`}
          yLabel="Percentage"
          yFormat="#.0a%"
          data={chartData}
          series={(data?.labels ?? [])
            .sort((a, b) => {
              const aFromRange = a.bin_label.split(SEPARATOR)?.[0]
              const bFromRange = b.bin_label.split(SEPARATOR)?.[0]
              return Number(aFromRange) < Number(bFromRange) ? -1 : 1
            })
            .map(l => {
              const splittedBinLabel = l.bin_label.split(SEPARATOR)
              const isMonthly = Number(splittedBinLabel[0]) >= 30

              return {
                label: `${l.bin_label
                  .split(SEPARATOR)
                  .map(x =>
                    x
                      ? isMonthly
                        ? (Number(x) / 30).toFixed(1)
                        : Number(x).toFixed(1)
                      : ''
                  )
                  .join(' - ')} ${isMonthly ? 'months' : 'days'}`,
                tooltipValueFormat: `#.0a%`,
                type: 'ColumnSeries',
                isStack: true,
                field: `val_${l.bucket}`,
              }
            })}
          tooltipSubtitle={
            showHistoricalRateIndicator
              ? (display_rates ?? []).map((r: any) => {
                  return {
                    field: `fx_${r.code}`,
                    title: `USD:${r.code}`,
                  }
                })
              : undefined
          }
          exportable={exportable}
          error={error as { message: string }}
        />
      }
    />
  )
}

export default DistributionL5
