import React, { useContext, useEffect } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { SignalIcon, SignalSlashIcon } from '@heroicons/react/24/outline'
import { LayoutFilter } from '@interfaces/filter'
import { Option, Select, Tooltip } from '@material-tailwind/react'
import ExchangeService from '@services/api-analytics/currency-exchange'

export interface FilterCurrencyProps {
  hideSelect?: boolean
}

const FilterCurrency = ({ hideSelect }: FilterCurrencyProps) => {
  const {
    company,
    setActiveFilters,
    activeFilters,
    optionFilters,
    setOptionFilters,
  } = useContext(AuthContext)

  const { rates } = optionFilters
  const { currency, financialEntityCurrency } = activeFilters

  const availableCurrencies = (
    company?.currencies_available?.reduce((prev: string[], cur) => {
      const newCur = []
      if (cur?.to_currency && prev.findIndex(x => x === cur?.to_currency) < 0) {
        newCur.push(cur?.to_currency)
      }
      if (
        cur?.from_currency &&
        prev.findIndex(x => x === cur?.from_currency) < 0
      ) {
        newCur.push(cur?.from_currency)
      }

      return [...prev, ...newCur]
    }, []) ?? []
  ).filter(function (item, pos, self) {
    return item && self.indexOf(item) === pos
  })

  const hasUSD = availableCurrencies.find(x => x === 'USD')
  const currencies = hasUSD
    ? availableCurrencies
    : [...availableCurrencies, 'USD']
  const defaultCurrency =
    financialEntityCurrency ?? company?.currencies_available?.[0]?.to_currency

  //date_sync is greater than 1 week ago is not connected
  const isConnected =
    moment().diff(moment(company?.date_sync, 'YYYY-MM-DD'), 'days') <= 7

  const filters = {
    slug_name: company?.slug_name ?? '',
    currencies: availableCurrencies,
    default_currency: defaultCurrency,
  }

  const { data } = useQuery(
    ['historical-exchange', filters],
    () => ExchangeService.getHistoricalRate(filters),
    {
      ...getStaleMins(),
      enabled: !!company?.slug_name && availableCurrencies.length > 0,
    }
  )

  useEffect(() => {
    if (company) {
      setActiveFilters((prev: LayoutFilter) => {
        return { ...prev, currency: prev.currency ?? currencies[0] }
      })
    }
  }, [company])

  useEffect(() => {
    if (data !== rates) {
      const availableRates = (data ?? []).map((d: any) => {
        const keys = Object.keys(d?.rates ?? {})
        return {
          ...d,
          rate: (d.rates ?? {})[keys?.[keys?.length - 1]],
        }
      })

      setOptionFilters((prev: LayoutFilter) => {
        return availableRates.length > 0
          ? {
              ...prev,
              rates: availableRates,
              display_rates: availableRates.filter(
                (r: any) => r.code !== 'USD'
              ),
            }
          : prev
      })
    }
  }, [data])

  useEffect(() => {
    const rate =
      currency === currencies[0]
        ? { rate: 1 }
        : (rates ?? []).find(
            (x: {
              code: string
              rate: number
              rates: { [month: string]: number }
            }) => x.code === currency
          )

    setActiveFilters((prev: LayoutFilter) => {
      return {
        ...prev,
        rate: 1 / (rate?.rate ?? 1),
        historicalRate: rate?.rates,
      }
    })
  }, [currency])

  return (
    <div className="flex lg:pr-4">
      <div className="flex flex-col flex-1 mr-4">
        <Typography
          variant="b2"
          className="font-semibold text-neutral-body-2 mb-4 md:mb-0 lg:mb-4"
        >
          Last Updated
        </Typography>
        <Typography className="text-neutral-black  text-2xl font-bold">
          {(moment.utc(company?.date_end).isBefore(moment.utc())
            ? moment.utc(company?.date_end)
            : moment.utc()
          ).format('MMM DD YYYY')}
        </Typography>
        {company?.date_sync && (
          <Tooltip
            placement="top"
            content={`Last synchronised at ${moment(company?.date_sync).format(
              'MMM DD YYYY'
            )}`}
          >
            <div className="flex items-center mt-6">
              {isConnected ? (
                <SignalIcon className="text-green w-6 h-6 mr-2 animate-pulse" />
              ) : (
                <SignalSlashIcon className="text-red w-6 h-6 mr-2" />
              )}
              <Typography
                className={`${
                  isConnected ? 'text-green' : 'text-red'
                } text-2xl text-base`}
              >
                {`Database ${isConnected ? 'Connected' : 'Disconnected'}`}
              </Typography>
            </div>
          </Tooltip>
        )}
      </div>
      {!hideSelect && (
        <div className="flex flex-col flex-1 ml-4">
          <div className="flex items-center">
            <Typography
              variant="b2"
              className="font-semibold text-neutral-body-2 mb-4 md:mb-0 lg:mb-4 mr-4"
            >
              Currency
            </Typography>
            <Select
              className="[&~ul]:max-h-[100px]"
              disabled={currencies.length <= 1 || (rates ?? []).length === 0}
              onChange={val => {
                setActiveFilters({ ...activeFilters, currency: val })
              }}
              selected={() => {
                return <span>{currency}</span>
              }}
            >
              {currencies.map((c: string) => (
                <Option key={c} value={c}>
                  {c}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      )}
    </div>
  )
}
export default FilterCurrency
