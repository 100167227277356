export const getMetaData = (data: any, key: string) => {
  if (!data?.statements || !data.statements.length) {
    return null
  }

  const mappedData = data.statements.map(
    (item: any, i: number) => item.data?.[i]
  )

  if (!mappedData.length) {
    return null
  }

  const findTargetValue = mappedData.filter(
    (item: any) => item?.entity_id === data?.entityId
  )

  if (!findTargetValue.length || !findTargetValue[0][key]) {
    return null
  }

  return findTargetValue[0][key].trim()
}

export const timer = (ms: number, callback: () => void) =>
  setTimeout(callback, ms)

export const checkKeyWordMismatch = (message: string): boolean => {
  const pattern = /Mismatch/
  const hasMismatch = pattern.test(message)
  return hasMismatch
}

export const throttle = <T extends (...args: any[]) => any>(
  func: T,
  limit: number,
  maxCalls: number
): T => {
  let inThrottle = false
  let callsCount = 0
  return function (this: any, ...args: any[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this
    if (!inThrottle && callsCount < maxCalls) {
      func.apply(context, args)
      inThrottle = true
      callsCount++
      setTimeout(() => {
        inThrottle = false
        callsCount = 0
      }, limit)
    }
  } as T
}
