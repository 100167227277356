import manageApiClient from './client'

export class OverviewService {
  static async getMasterFacilityOverviewData(params: {
    slug_name: string
    facility: string
  }) {
    const { data } = await manageApiClient.post<any[]>(
      `/borrowing-base/master-facility-overview`,
      params
    )
    return data
  }
}
