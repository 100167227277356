import React from 'react'

import Table from '@components/table'

import {
  CREDIT_FACILITY_ECONOMICS_MOCK,
  FACILITY_PAYMENT_MOCK,
  FACILITY_TRANCHE_MOCK,
  PREPAYMENT_DETAIL_MOCK,
} from './mock'

const EconomicsL4 = () => {
  return (
    <div className="flex flex-col p-4">
      <Table
        title="Credit Facility Economic Detail"
        columns={[
          { title: 'Economics', field: 'economics' },
          { title: 'Tranche', field: 'tranche' },
          { title: 'Paid To', field: 'paid_to' },
          { title: 'Paid By', field: 'paid_by' },
          { title: 'Rate', field: 'rate' },
          { title: 'WHT Rate', field: 'wht_rate' },
          { title: 'Witholding Tax Paid By', field: 'tax_paid_by' },
          { title: 'Notes', field: 'notes' },
        ]}
        data={CREDIT_FACILITY_ECONOMICS_MOCK}
      />
      <div className="flex gap-6">
        <Table
          title="Facility Payment / Period Detail"
          columns={[
            { title: 'Category', field: 'category' },
            { title: 'term', field: 'term' },
          ]}
          data={FACILITY_PAYMENT_MOCK}
        />
        <Table
          title="Facility Tranche Detail"
          columns={[
            { title: 'Tranche', field: 'tranche' },
            { title: 'Range', field: 'range' },
          ]}
          data={FACILITY_TRANCHE_MOCK}
        />
      </div>
      <Table
        title="Prepayment Detail"
        columns={[
          { title: 'Prepayment', field: 'prepayment' },
          { title: 'Year 1', field: 'year_1' },
          { title: 'Year 2', field: 'year_2' },
          { title: 'Year 3', field: 'year_3' },
          { title: 'Year 4', field: 'year_4' },
        ]}
        data={PREPAYMENT_DETAIL_MOCK}
      />
    </div>
  )
}

export default EconomicsL4
