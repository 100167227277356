import React from 'react'
import moment from 'moment'

import { AdvanceRequestTemplateProps } from '@interfaces/manage-signature'
import { Document, Page, View } from '@react-pdf/renderer'

import { Footer, Text } from '../../../document-centre/templates/components'
import { styles } from '../template-style'

const textStyle = { ...styles.text, marginVertical: 1 }

const FlexpathAdvanceRequestTemplate = ({
  requestDate,
  advanceDate,
  loanAgreementDate,
  formData,
  bankInfo,
}: AdvanceRequestTemplateProps) => {
  const formatCurrency = (amount: number) => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount)
  }

  return (
    <Document>
      <Page style={styles.body}>
        <Text
          otherStyles={{
            ...styles.title,
            fontFamily: 'Times-Bold',
            borderBottom: 'none',
          }}
        >
          NOTICE OF BORROWING
        </Text>
        <Text otherStyles={styles.text}>
          {requestDate
            ? moment(requestDate, 'DD-MM-YYYY').format('MMMM DD, YYYY')
            : '[Date of Request]'}
        </Text>
        <Text otherStyles={textStyle}>Variant Alternative Income Fund</Text>
        <Text otherStyles={textStyle}>10260 SW Greenburg Road, Suite 268</Text>
        <Text otherStyles={textStyle}>Portland, Oregon 97223 Attention:</Text>
        <Text otherStyles={{ ...textStyle, marginBottom: 26 }}>J.B. Hayes</Text>
        <Text otherStyles={styles.text}>Ladies and Gentlemen:</Text>
        <Text
          otherStyles={{
            ...styles.text,
            textIndent: 32,
            marginBottom: 26,
          }}
        >
          Reference is made to the Loan and Security Agreement, dated as of{' '}
          {loanAgreementDate
            ? moment(loanAgreementDate, 'DD-MM-YYYY').format('MMMM DD, YYYY')
            : '[Date of Agreement]'}{' '}
          (as it may be amended, restated, supplemented, or otherwise modified
          from time to time, the “Loan Agreement”), among the undersigned, as
          Borrower, the lenders party thereto from time to time (each a “Lender”
          and collectively, the “Lenders”), and Variant Alternative Income Fund,
          as administrative, payment and collateral agent on behalf of the
          Lenders. Terms defined in the Loan Agreement are used herein with the
          same meanings. This notice constitutes a Notice of Borrowing, and the
          Borrower hereby requests an Advance under the Loan Agreement
          (“Borrowing”), and in that connection the Borrower specifies the
          following information with respect to the Borrowing requested hereby:
        </Text>
        <View
          style={{
            border: '1px solid black',
            marginBottom: 24,
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingHorizontal: 10,
              borderBottom: '1px solid black',
            }}
          >
            <Text otherStyles={textStyle}>
              (A) Aggregate principal amount of requested Borrowing:
            </Text>
            <Text otherStyles={textStyle}>
              {formData?.amount ? formatCurrency(formData.amount) : '[Amount]'}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingHorizontal: 10,
              borderBottom: '1px solid black',
            }}
          >
            <Text otherStyles={textStyle}>
              (B) Date of requested Borrowing (which must be a Business Day):
            </Text>
            <Text otherStyles={textStyle}>
              {advanceDate
                ? moment(advanceDate, 'DD-MM-YYYY').format('MMMM DD, YYYY')
                : '[Advance date]'}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingHorizontal: 10,
              borderBottom: '1px solid black',
            }}
          >
            <Text otherStyles={textStyle}>
              (C) Eligible Receivables to be pledged in connection with such
              Borrowing:
            </Text>
            <Text otherStyles={textStyle}>{''}</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingHorizontal: 10,
            }}
          >
            <View>
              <Text otherStyles={textStyle}>
                (D) Wire information for account to which the Advance shall be
                made:
              </Text>
              <View
                style={{
                  marginVertical: 26,
                  display: 'flex',
                  flexDirection: 'row',
                  paddingLeft: 64,
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text otherStyles={textStyle}>Account Holder:</Text>
                  <Text otherStyles={textStyle}>Financial Institution:</Text>
                  <Text otherStyles={textStyle}>ABA Number:</Text>
                  <Text otherStyles={textStyle}>Account Number:</Text>
                  <Text otherStyles={textStyle}>
                    Address of Financial Institution:
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text otherStyles={textStyle}>
                    {bankInfo.recipientName || '[]'}
                  </Text>
                  <Text otherStyles={textStyle}>
                    {bankInfo.recipientBankName || '[]'}
                  </Text>
                  <Text otherStyles={textStyle}>
                    {bankInfo.wireRoutingNumber || '[]'}
                  </Text>
                  <Text otherStyles={textStyle}>
                    {bankInfo.recipientBankAccountNumber || '[]'}
                  </Text>
                  <Text otherStyles={textStyle}>
                    {bankInfo.recipientBankAddress || '[]'}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View wrap={false}>
          <Text otherStyles={textStyle}>Very truly yours,</Text>
          <Text otherStyles={textStyle}>FLEXPATH WH III LLC, a</Text>
          <Text otherStyles={{ ...textStyle, marginBottom: 10 }}>
            Delaware Limited Liability Company, as Borrower
          </Text>
          <Text otherStyles={styles.text}>
            {`By: `}
            <Text
              underline
              otherStyles={{
                ...styles.signTags,
                textDecorationColor: '#000000',
              }}
            >
              [sig|req|signer1|signature]
            </Text>
          </Text>
          <Text otherStyles={styles.text}>
            Name: {formData?.signers?.[0]?.name || `[Authorized Officer Name]`}
          </Text>
          <Text otherStyles={styles.text}>
            Title:{' '}
            {formData?.signers?.[0]?.title || `[Authorized Officer Title]`}
          </Text>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}

export default FlexpathAdvanceRequestTemplate
