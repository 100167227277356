import React, { useContext, useEffect, useState } from 'react'

import FilterCurrency from '@components/filters/filter-currency'
import FilterDebtDeal from '@components/filters/filter-debt-deal'
import BaseLayout from '@components/layouts/base-layout'
import AuthContext from '@contexts/auth'

import PaymentAudit from './payment'

const UnderwritingAuditL3 = () => {
  const { company, activeFilters, setAppliedFilters } = useContext(AuthContext)

  const { activeDebtDeal } = activeFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (!filterReady && activeDebtDeal !== undefined) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters, activeDebtDeal])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset = activeDebtDeal === 0
  return (
    <BaseLayout
      title="Payment Audit"
      filters={{
        left: <FilterCurrency hideSelect />,
        middle: <FilterDebtDeal />,
      }}
      disableReset={disableReset}
    >
      <PaymentAudit />
    </BaseLayout>
  )
}

export default UnderwritingAuditL3
