import React, { useContext } from 'react'

import AuthContext from '@contexts/auth'
import { Tooltip } from '@material-tailwind/react'

interface ExchangeRateBadgeProps {
  isHistorical?: boolean
}
const ExchangeRateBadge = ({ isHistorical = true }: ExchangeRateBadgeProps) => {
  const { optionFilters } = useContext(AuthContext)

  const { rates = [] } = optionFilters

  return isHistorical ? (
    <div className="text-xs rounded-full px-2 py-1 border border-primary-main text-primary-main flex items-center text-center">
      Converted with historical exchange rates
    </div>
  ) : (
    <Tooltip
      content={
        <>
          {rates
            .filter((x: any) => x.code !== 'USD')
            .map((r: any) => (
              <div key={r.code}>{`USD:${r.code} = ${Intl.NumberFormat(
                undefined,
                {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                }
              ).format(r.rate ?? 1)}`}</div>
            ))}
        </>
      }
      placement="right"
    >
      <div className="text-xs rounded-full px-2 py-1 border border-primary-main text-primary-main flex items-center text-center">
        Converted with spot exchange rate
      </div>
    </Tooltip>
  )
}

export default ExchangeRateBadge
