import React, { useContext, useEffect } from 'react'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { Filter, FilterItem, LayoutFilter } from '@interfaces/filter'
import { Option, Select } from '@material-tailwind/react'
import { Checkbox } from '@material-tailwind/react'
import FilterService from '@services/api-analytics/risk-traction-filter'

const FilterTypeKey = () => {
  const {
    company,
    activeFilters,
    setActiveFilters,
    optionFilters,
    setOptionFilters,
  } = useContext(AuthContext)

  const { categoryTypes = [], activeType } = activeFilters
  const { categoryTypeOptions, categoryTypeCompanySlugName } = optionFilters

  const oriActiveVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []
  const hasNaN = oriActiveVal.find((x: any) =>
    isNaN(
      Math.min(...(x.type ?? '').split(/(?:[-+\s]+)/).filter((x: any) => x))
    )
  )

  const activeVal = oriActiveVal.sort((a: any, b: any) => {
    return hasNaN
      ? a.id > b.id
        ? 1
        : -1
      : Math.min(...(a.type ?? '').split(/(?:[-+\s]+)/).filter((x: any) => x)) -
          Math.min(...(b.type ?? '').split(/(?:[-+\s]+)/).filter((x: any) => x))
  })

  const allCategoryTypes = (
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === 'All')
      ?.type_val ?? []
  ).map((av: FilterItem) => av.id)

  useEffect(() => {
    if (company && company.slug_name !== categoryTypeCompanySlugName) {
      FilterService.getFilters(company?.slug_name).then(response => {
        const filters = (response.data ?? []).filter(
          x => x.type_val.length <= 20
        )
        const allValues = filters?.reduce(
          (prev: FilterItem[], cur) => [...prev, ...cur.type_val],
          []
        )

        //adds "all" as an option
        setOptionFilters((prev: LayoutFilter) => ({
          ...prev,
          categoryTypeCompanySlugName: company.slug_name,
          categoryTypeOptions: [
            { type_key: 'All', type_val: allValues },
            ...filters,
          ],
        }))

        // selects "all" filters by default if no active filters found (i.e first load)
        categoryTypes?.length === 0 &&
          setActiveFilters((prev: LayoutFilter) => ({
            ...prev,
            categoryTypes: allValues.map(av => av.id),
            activeType: 'All',
          }))
      })
    }
  }, [company])

  useEffect(() => {
    const nextCategoryTypes = [...activeVal.map((av: FilterItem) => av.id)]
    /*
     * set categorytypes when:
     * prev activeType equal 'All' OR
     * next activeType equal 'All' OR
     * prev activetype NOT equal 'All' AND there is NO subset between categoryType (prev) and nextCategoryType (next)
     */
    const ct = [...categoryTypes]
    const act = [...allCategoryTypes]
    if (
      ct.sort().join('.') === act.sort().join('.') ||
      nextCategoryTypes.sort().join('.') === act.sort().join('.') ||
      (ct.sort().join('.') !== act.sort().join('.') &&
        !nextCategoryTypes.some((x: number) => ct.includes(x)))
    ) {
      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        categoryTypes: nextCategoryTypes,
      }))
    }
  }, [activeType])

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between">
        <Typography
          variant="b2"
          className="font-semibold text-neutral-body-2 flex-1"
        >
          Display By
        </Typography>
        <div className="flex-1">
          {(categoryTypeOptions?.length ?? 0) > 0 && (
            <Select
              className="[&~ul]:max-h-[150px]"
              value={activeType}
              onChange={val => {
                setActiveFilters((prev: LayoutFilter) => ({
                  ...prev,
                  activeType: val,
                }))
              }}
            >
              {categoryTypeOptions?.map((cto: Filter) => (
                <Option key={cto.type_key} value={cto.type_key}>
                  {cto.type_key === 'All'
                    ? 'Select'
                    : cto.type_key.replaceAll('_', ' ')}
                </Option>
              ))}
            </Select>
          )}
        </div>
      </div>
      <div
        className={`mt-4 border border-neutral-border-2 rounded-lg p-4 flex flex-col max-h-[100px] overflow-y-auto ${
          activeType === 'All' ? 'invisible' : 'visible'
        }`}
      >
        {activeVal.map((v: FilterItem) => (
          <Checkbox
            key={v.id}
            label={v.type}
            value={v.id}
            checked={categoryTypes?.includes(v.id)}
            onChange={e => {
              const check = e.target.checked
              let ct = categoryTypes
              if (check && !categoryTypes.includes(v.id)) {
                ct = [...ct, v.id]
              } else if (!check && categoryTypes.includes(v.id)) {
                ct = ct.filter((x: number) => x !== v.id)
              }

              setActiveFilters((prev: LayoutFilter) => ({
                ...prev,
                categoryTypes: ct,
              }))
            }}
          />
        ))}
      </div>
    </div>
  )
}
export default FilterTypeKey
