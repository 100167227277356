import React, { useContext } from 'react'
import { useMutation } from 'react-query'

import Button from '@components/atoms/button'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { UserResponse } from '@interfaces/admin-settings-user'
import {
  Alert,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { UserService } from '@services/api-admin/settings-user'

interface DialogDeleteUserProps {
  open?: boolean
  handler: (needUpdate?: boolean) => void
  data?: UserResponse
}

const DialogDeleteUser = ({
  open = false,
  handler,
  data,
}: DialogDeleteUserProps) => {
  const { company } = useContext(AuthContext)
  const _successHandler = () => {
    handler?.(true)
  }

  const {
    mutate: deleteUser,
    isLoading,
    error,
  } = useMutation(
    () => {
      return UserService.deleteUser({
        user_id: data?.user_id as string,
        email: data?.email as string,
        slug_name: company?.slug_name as string,
        is_external: data?.user_metadata?.isExternal,
      })
    },
    {
      onSuccess: _successHandler,
    }
  )

  return (
    <Dialog
      open={open}
      handler={() => handler()}
      className="px-4 py-2"
      size="xs"
    >
      <DialogHeader className="text-xl">Delete user</DialogHeader>
      <DialogBody className="text-sm text-black flex flex-col">
        {error && (
          <Alert className="bg-danger-main mb-6">
            {(error as any).message}
          </Alert>
        )}
        {`Are you sure you want to delete "${data?.name}"? This cannot be undone!`}
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => handler()} className="mr-2" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={() => deleteUser()}
          className={`bg-danger-hover border border-danger-hover  hover:bg-danger-pressed ${
            isLoading ? '!text-danger-main' : '!text-white'
          }`}
          disabled={isLoading}
        >
          {isLoading && (
            <ArrowPathIcon className="w-4 h-4 mr-2 text-danger-main animate-spin" />
          )}
          Yes, Delete it
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default DialogDeleteUser
