import React from 'react'

import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'

interface AlertPopUpProps {
  open: boolean
  handler: () => void
  message: string
  header: string
  children?: React.ReactNode
  isSuccess?: boolean
  footerClassName?: string
  dialogClassName?: string
  headerClassName?: string
  bodyClassName?: string
}

const AlertPopUp = ({
  open,
  handler,
  message,
  children,
  header,
  isSuccess,
  dialogClassName,
  headerClassName,
  bodyClassName,
  footerClassName,
}: AlertPopUpProps) => {
  return (
    <Dialog
      open={open}
      handler={handler}
      className={`overflow-auto z-50 py-4 px-4${
        dialogClassName ? dialogClassName : ''
      }`}
      size="sm"
    >
      <DialogHeader
        className={`${headerClassName ? headerClassName : 'px-0 text-xl'}`}
      >
        <div className="flex justify-between w-full items-center">
          <div className="flex w-full justify-start items-center">
            {isSuccess ? (
              <CheckCircleIcon className="stroke-green w-6" />
            ) : (
              <ExclamationTriangleIcon className="stroke-red w-6" />
            )}
            <h4 className="ml-3 text-neutral-border-4 ">{header}</h4>
          </div>
          <XMarkIcon
            className="stroke-black w-7 h-7 hover:opacity-50 cursor-pointer -mt-8"
            onClick={() => handler()}
          />
        </div>
      </DialogHeader>
      <DialogBody
        className={`${
          bodyClassName
            ? bodyClassName
            : 'border-b border-t border-gray-300 py-4 px-0 text-md'
        }`}
      >
        <span className="antialiased text-neutral-border-4 text-left font-normal leading-normal ">
          {message}
        </span>
      </DialogBody>
      <DialogFooter
        className={`${
          footerClassName ? footerClassName : 'gap-4 justify-center p-3'
        }`}
      >
        {children}
      </DialogFooter>
    </Dialog>
  )
}

export default AlertPopUp
