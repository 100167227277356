import React, { useContext, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import Button from '@components/atoms/button'
import FormInput from '@components/form/form-input'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { CollateralListUploadRequest } from '@interfaces/borrowing-base'
import { ComponentResponse } from '@interfaces/borrowing-base'
import { AllTableData } from '@interfaces/manage-reporting'
import {
  SignatureRequest,
  TemplateDialogProps,
} from '@interfaces/manage-signature'
import { Dialog, DialogBody } from '@material-tailwind/react'
import { pdf } from '@react-pdf/renderer'
import { PDFViewer } from '@react-pdf/renderer'
import { BorrowingBaseService } from '@services/api-manage/borrowing-base'
import { FacilityDetailsService } from '@services/api-manage/facility-details'

import { CustomFields, SignerInput } from '../components'
import { getCustomData } from '../custom-data'

import {
  generateCashTableData,
  generateEligiblePoolTableData,
  generateExcessConcentrationTableData,
  generateInterestReserveTableData,
  generateSummaryTableData,
} from './data-helpers'
import { renderTemplate } from './templates'

interface Props extends TemplateDialogProps {
  components: ComponentResponse[]
}

type ComponentName =
  | 'summary'
  | 'cash'
  | 'eligible pool balance'
  | 'borrowing base interest reserve'

const BorrowingBaseCertAction = ({
  form,
  user,
  createEmbeddedSignRequest,
  uploadDocument,
  handler,
  dialogOpen,
  components,
  category,
  docData,
}: Props) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { debtDealOptions = [] } = optionFilters
  const { activeDebtDeal = 0 } = appliedFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]
  const [guarantors, setGuarantors] = useState<string>('')
  const [agent, setAgent] = useState<string>('')
  const [borrower, setBorrower] = useState<string>('')
  const [dateOptions, setDateOptions] = useState<any>([])
  const [fileUrl, setFileUrl] = useState<string>('')
  const [calculationDate, setCalculationDate] = useState<string>(
    docData?.effective_date
      ? moment(docData.effective_date).format('YYYY-MM-DD')
      : ''
  )
  const tableAggregator = useRef<Record<string, any>>({})

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = form

  const { mutate: uploadFile } = useMutation(
    (params: CollateralListUploadRequest) => {
      return BorrowingBaseService.uploadCollateralReportData(params)
    }
  )

  const signers = category?.signers ? JSON.parse(category.signers) : undefined

  const [isLoadingCustomData, customData] = getCustomData(category)

  /**
   * Get all data and format
   */
  const { mutate: getPartyDetails, isLoading: isLoadingPartyDetails } =
    useMutation(
      (params: { facility: string; slug_name: string }) => {
        return FacilityDetailsService.getDealPartyDetailsRequest(params)
      },
      {
        onSuccess: (data: any) => {
          const guarantorsList = data
            .filter((entry: { type: string }) => entry.type === 'Guarantor')
            .map((entry: { legal_name: any }) => entry.legal_name)
          if (guarantorsList.length) {
            setGuarantors(guarantorsList.join(' and '))
          }
          const borrower = data.filter(
            (entry: { type: string }) => entry.type === 'Borrower'
          )?.[0]?.legal_name
          const agent = data.filter(
            (entry: { type: string }) => entry.type === 'Agent'
          )?.[0]?.legal_name
          setAgent(agent)
          setBorrower(borrower)
        },
      }
    )
  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility ?? '',
    date: calculationDate
      ? moment(calculationDate).format('YYYY-MM-DD')
      : moment(dateOptions?.[0]).format('YYYY-MM-DD'),
  }
  const { data: validDates, isFetching: isFetchingCalcDates } = useQuery(
    ['validDate', filters],
    () => BorrowingBaseService.getValidDate(filters),
    {
      ...getStaleMins(),
      enabled: !!activeFacility,
    }
  )
  useEffect(() => {
    if (validDates?.length) {
      setDateOptions(validDates.map(d => d.calculation_date))
    }
  }, [validDates])

  const API = (component: string, params: any): Promise<any> => {
    switch (component.toLowerCase()) {
      case 'summary':
        return BorrowingBaseService.getSummaryTable(params)
      case 'cash':
        return BorrowingBaseService.getCash(params)
      case 'eligible pool balance':
        return BorrowingBaseService.getEligiblePool(params)
      case 'borrowing base interest reserve':
        return BorrowingBaseService.getInterestReserve(params)
      case 'excess concentration':
        return BorrowingBaseService.getExcessConcentration(params)
      default:
        return new Promise(resolve => resolve([]))
    }
  }

  const FILTER = (component: string, subcomponent: string) => {
    switch (component.toLowerCase()) {
      case 'summary':
        return {
          ...filters,
          numberOfLenders: activeFacility?.lender ?? 0,
        }
      case 'cash':
        return { ...filters, cashNumber: subcomponent }
      case 'eligible pool balance':
        return { ...filters, eligiblePool: subcomponent }
      case 'excess concentration':
        return { ...filters, defined_term: subcomponent }
      default:
        return filters
    }
  }

  const generateTable = (
    component: ComponentName,
    subcomponent: string,
    data: any
  ): AllTableData => {
    switch (component.toLowerCase()) {
      case 'summary':
        return generateSummaryTableData(data, activeFacility, subcomponent)
      case 'cash':
        return generateCashTableData(data, subcomponent)
      case 'eligible pool balance':
        return generateEligiblePoolTableData(data, subcomponent)
      case 'borrowing base interest reserve':
        return generateInterestReserveTableData(data, subcomponent)
      case 'excess concentration':
        return generateExcessConcentrationTableData(data, subcomponent)
      default:
        return {} as AllTableData
    }
  }

  const componentQueries = components.map(c =>
    useQuery(
      [c.subcomponent, FILTER(c.component, c.subcomponent)],
      () => API(c.component, FILTER(c.component, c.subcomponent)),
      {
        ...getStaleMins(),
      }
    )
  )

  componentQueries.every(q => !q.isLoading) &&
    componentQueries.forEach((q, i) => {
      const table = generateTable(
        components[i].component as ComponentName,
        components[i].subcomponent,
        q.isError ? [] : q.data
      )
      tableAggregator.current[components[i].subcomponent] = table
    })

  const allTableData = componentQueries.every(q => !q.isLoading)
    ? components.map(c => tableAggregator.current[c.subcomponent])
    : []

  const isLoading =
    isFetchingCalcDates || isLoadingPartyDetails || isLoadingCustomData

  const onSubmit = (cd: any) => {
    if (!!signers?.signers && !signers.signers.length) {
      uploadDoc(cd)
    } else {
      createSignableDoc(cd)
    }
  }

  const uploadDoc = async (cd: any) => {
    const formData = new FormData()
    if (activeFacility?.slug_name || company?.slug_name) {
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name
      )
    }
    formData.append(
      'effective_date',
      moment(calculationDate).format('DD-MM-YYYY')
    )
    formData.append('category', 'Borrowing Base Certificate')
    formData.append(
      'title',
      `Borrowing Base Certificate - ${moment().format('DD-MMM-YYYY')}`
    )
    formData.append('facility', debtDealOptions?.[activeDebtDeal]?.facility)
    user?.email && formData.append('author_email', `${user.email}`)
    user?.name && formData.append('author_name', `${user.name}`)
    signers?.saveCalculatedData &&
      formData.append('calculated_data', JSON.stringify(customData))
    formData.append('form_values', JSON.stringify(cd))
    formData.append(
      'files',
      await pdf(
        renderTemplate(category, {
          agent,
          borrower,
          guarantors,
          agreementDate: moment
            .utc(activeFacility?.agreement_date)
            .format('DD-MM-YYYY'),
          effectiveDate: moment(calculationDate || dateOptions?.[0]).format(
            'DD-MM-YYYY'
          ),
          tableData: allTableData,
          fileUrl,
          customData,
          formData: cd,
        })
      ).toBlob()
    )

    uploadDocument(formData)
    handler()
    toast.loading('Request in progress', { autoClose: false })
    reset()
  }

  const createSignableDoc = async (cd: any) => {
    if (
      fileUrl &&
      company?.slug_name &&
      debtDealOptions?.[activeDebtDeal]?.facility
    ) {
      const fileName = fileUrl.split('fileName=').pop()
      fileName &&
        uploadFile({
          facility: debtDealOptions?.[activeDebtDeal]?.facility,
          slug_name: activeFacility?.slug_name ?? company.slug_name,
          date:
            moment(calculationDate).month() === moment(dateOptions?.[0]).month()
              ? moment(dateOptions?.[0]).format('YYYY-MM-DD')
              : moment(calculationDate).endOf('month').format('YYYY-MM-DD'),
          file_name: fileName,
        })
    }
    const formData = new FormData()
    user?.email && formData.append('requester_email', `${user.email}`)
    user?.name && formData.append('requester_name', `${user.name}`)
    docData.id && formData.append('id', docData.id)
    if (activeFacility?.slug_name || company?.slug_name) {
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name
      )
    }
    formData.append('facility', debtDealOptions?.[activeDebtDeal]?.facility)
    formData.append('category', 'Borrowing Base Certificate')
    formData.append(
      'effective_date',
      calculationDate
        ? moment(calculationDate).format('DD-MM-YYYY')
        : moment(dateOptions[0]).format('DD-MM-YYYY')
    )
    formData.append(
      'title',
      `Borrowing Base Certificate - ${moment().format('DD-MMM-YYYY')}`
    )
    formData.append(
      'files',
      await pdf(
        renderTemplate(category, {
          agent,
          borrower,
          guarantors,
          agreementDate: moment
            .utc(activeFacility?.agreement_date)
            .format('DD-MM-YYYY'),
          effectiveDate: moment(calculationDate || dateOptions?.[0]).format(
            'DD-MM-YYYY'
          ),
          tableData: allTableData,
          fileUrl,
          customData,
          formData: cd,
        })
      ).toBlob()
    )
    formData.append('subject', `Borrowing Base Certificate`)
    formData.append(
      'message',
      `${user?.name} has requested your signature via Cascade Debt`
    )
    formData.append(
      'signers',
      JSON.stringify(
        cd.signers.map(
          (s: { name: string; email: string; title?: string }, i: number) => ({
            name: s.name,
            order: i,
            emailAddress: s.email,
          })
        )
      )
    )
    signers?.saveCalculatedData &&
      formData.append('calculated_data', JSON.stringify(customData))
    formData.append('form_values', JSON.stringify(cd))
    formData.append('tags', 'true')
    createEmbeddedSignRequest(formData as SignatureRequest)
    handler()
    toast.loading('Request in progress', { autoClose: false })
    reset()
  }

  const erroredComponents: string[] = componentQueries
    .map((q, index) => (q.isError ? components[index].subcomponent : null))
    .filter(x => x !== null) as string[]

  useEffect(() => {
    if (dialogOpen) {
      const fileName = `${moment().unix()}_borrowing_base_certificate_collateral.xlsx`
      setFileUrl(
        `${window.location.origin}/manage/monitor/actions?pid=${company?.slug_name}&facility=${activeFacility?.facility}&tab=document-centre&fileName=${fileName}`
      )
    }
  }, [dialogOpen])

  useEffect(() => {
    if (dialogOpen) {
      !!activeFacility.facility &&
        company?.slug_name &&
        getPartyDetails({
          facility: activeFacility?.facility,
          slug_name: activeFacility?.slug_name ?? company?.slug_name,
        })
    }
  }, [dialogOpen, activeFacility])

  return (
    <Dialog
      open={dialogOpen}
      size={'xl'}
      dismiss={{ enabled: false }}
      handler={handler}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody className="flex flex-row h-[80vh] overflow-hidden relative">
          <XMarkIcon
            className="absolute right-3 w-7 h-7 cursor-pointer"
            onClick={handler}
          />
          {isLoading ? (
            <div className="flex flex-col justify-center items-center w-full">
              <ArrowPathIcon className="w-10 h-10 mr-2 text-primary-main animate-spin" />
            </div>
          ) : componentQueries.every(q => !q.isLoading) &&
            erroredComponents?.length ? (
            <div className="flex flex-col justify-center items-center w-full">
              No data available for the following components:
              <ul>
                {erroredComponents.map(c => (
                  <li key={c}>{c}</li>
                ))}
              </ul>
            </div>
          ) : (
            <PDFViewer showToolbar={false} className="h-full w-full">
              {renderTemplate(category, {
                agent,
                borrower,
                guarantors,
                agreementDate:
                  moment
                    .utc(activeFacility?.agreement_date)
                    .format('DD-MM-YYYY') || '',
                effectiveDate: moment(
                  calculationDate || dateOptions?.[0]
                ).format('DD-MM-YYYY'),
                tableData: allTableData,
                fileUrl,
                customData,
                formData: getValues(),
              })}
            </PDFViewer>
          )}
          <div className="m-5 w-1/3 overflow-auto">
            <div className="flex flex-col content-between">
              <div className="font-bold text-lg pb-5">
                Fill in below fields to generate document
              </div>
              <FormInput
                type="select"
                label={{ start: 'Date of Calculation' }}
                options={dateOptions.map((d: string) => ({
                  value: d,
                  title: d,
                }))}
                value={calculationDate || dateOptions?.[0]}
                onSelected={val => setCalculationDate(val)}
                error={errors?.date?.message as string}
              />
              <CustomFields category={category} form={form} />
              <SignerInput form={form} user={user} signers={signers} />
              {!signers?.hideDisclosure && (
                <FormInput
                  type="textarea"
                  label={{ start: 'Disclosure Notice' }}
                  value={getValues('disclosure')}
                  {...register('disclosure', {
                    disabled: isSubmitting,
                    onChange: (e: any) => {
                      setValue('disclosure', e.target.value, {
                        shouldValidate: true,
                      })
                    },
                  })}
                  error={errors?.disclosure?.message as string}
                />
              )}
              <div className="flex flex-row w-full justify-end my-3">
                <Button
                  disabled={
                    isLoading ||
                    !isValid ||
                    componentQueries.every(q => !q.isLoading && q.isError)
                  }
                  type="submit"
                  color="primary"
                  className="w-full"
                >
                  {isLoading ? (
                    <ArrowPathIcon className="w-4 h-4 mx-20 text-primary-main animate-spin" />
                  ) : (
                    `Prepare Signature Request`
                  )}
                </Button>
              </div>
            </div>
          </div>
        </DialogBody>
      </form>
    </Dialog>
  )
}

const ActionWrapper = (props: TemplateDialogProps) => {
  const { company, activeFilters, optionFilters } = useContext(AuthContext)
  const { activeDebtDeal } = activeFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const params = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility,
  }

  const { data: componentsResult, isFetching: isFetchingComponents } = useQuery(
    ['component', params],
    () => BorrowingBaseService.getComponent(params),
    {
      ...getStaleMins(),
      enabled: !!activeFacility?.facility,
    }
  )

  const components = (componentsResult ?? []).filter(
    d =>
      d.variable.toLowerCase() === 'borrowing base table' &&
      d.subcomponent !== 'borrowing_base'
  )

  components.unshift({
    component: 'Summary',
    subcomponent: 'summary',
    action: '',
    variable: '',
  })

  return (
    <Dialog
      open={props.dialogOpen}
      size={'xl'}
      dismiss={{ enabled: false }}
      handler={props.handler}
    >
      {isFetchingComponents ? (
        <div className="w-[100%] h-[70vh] flex items-center justify-center">
          <ArrowPathIcon className="w-10 h-10 mx-20 text-primary-main animate-spin" />
        </div>
      ) : (
        <BorrowingBaseCertAction {...props} components={components} />
      )}
    </Dialog>
  )
}

export default ActionWrapper
