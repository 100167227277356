import React, { useState } from 'react'

import Button from '@components/atoms/button'
import { ArrowPathIcon } from '@heroicons/react/24/solid'

import AlertPopUp from '../../pop-ups/alert-popup'

interface DeletePopUpProps {
  onClose: () => void
  handleDelete: () => void
  isOpen: boolean
  handleCancel: () => void
}
const ConfirmationDeleteAlert: React.FC<DeletePopUpProps> = ({
  onClose,
  isOpen,
  handleDelete,
  handleCancel,
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false)

  const handleConfirm = () => {
    setIsConfirmed(true)
    handleDelete()
  }

  return (
    <AlertPopUp
      header="Delete Entity"
      message="Are you sure you want to delete this company? This action can't be undone."
      handler={onClose}
      open={isOpen}
    >
      <Button
        type="button"
        className="rounded-lg flex-1"
        color={'primary'}
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button
        type="button"
        className="rounded-lg flex-1 bg-[#ff3b31] :hover[#ff3b31]/[.55] text-white"
        onClick={handleConfirm}
        disabled={isConfirmed}
      >
        <>
          {isConfirmed ? (
            <ArrowPathIcon className="animate-spin w-6 h-6 text-primary-main mr-1" />
          ) : (
            'Confirm'
          )}
        </>
      </Button>
    </AlertPopUp>
  )
}
export default ConfirmationDeleteAlert
