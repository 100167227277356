import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react'
import { BorrowingBaseService } from '@services/api-manage/borrowing-base'

// import CalculationTable from './calculation-table'
// import DetailChart from './detail-chart'
import DetailTable from './detail-table'
import HistoricalSurplusChart from './historical-surplus-chart'
// import LenderSurplusTable from './lender-surplus-table'
import SummaryTable from './summary-table'
// import SurplusChart from './surplus-chart'

// const Graphs = () => {
//   const graphs = [SurplusChart, HistoricalSurplusChart, DetailChart]
//   const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined)

//   const onClickExpand = (idx: number) => {
//     setActiveIndex(activeIndex === idx ? undefined : idx)
//   }

//   return (
//     <div
//       className={`grid gap-6 mb-6 ${
//         activeIndex
//           ? `grid-cols-${graphs.length - 1}`
//           : `grid-cols-${graphs.length}`
//       }`}
//     >
//       {graphs.map((graphComponent, i) => {
//         const Icon =
//           i + 1 === activeIndex ? ArrowsPointingInIcon : ArrowsPointingOutIcon
//         return (
//           <div
//             key={i}
//             className={`relative border border-neutral-border-2 rounded-lg p-4 justify-center ${
//               i + 1 === activeIndex ? 'order-1 col-span-2' : 'order-2'
//             }`}
//           >
//             <button
//               className="absolute right-2 top-2 w-6 h-6 border border-neutral-border-2 rounded p-1 hover"
//               onClick={() => onClickExpand(i + 1)}
//             >
//               <Icon />
//             </button>
//             {graphComponent({ expanded: i + 1 === activeIndex })}
//           </div>
//         )
//       })}
//     </div>
//   )
// }

const BorrowingBaseL4 = () => {
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([])
  const [focusCollapsible, setFocusCollapsible] = useState<string | undefined>()

  const handleOpen = (index: number): void => {
    const indexes = expandedIndexes.includes(index)
      ? expandedIndexes.filter(x => x !== index)
      : [...expandedIndexes, index]
    setExpandedIndexes(indexes)
  }

  const { optionFilters, appliedFilters, company } = useContext(AuthContext)

  const { activeDebtDeal = 0, calculationDate } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility ?? '',
  }

  const componentFilters = {
    ...filters,
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
  }

  const { data } = useQuery(
    ['component', componentFilters],
    () => BorrowingBaseService.getComponent(componentFilters),
    {
      ...getStaleMins(),
      enabled: !!calculationDate,
      onSuccess: () => {
        setExpandedIndexes([])
      },
    }
  )

  const components = (data ?? []).filter(
    d =>
      d.variable.toLowerCase() === 'borrowing base table' &&
      d.subcomponent !== 'borrowing_base'
  )

  const _handleOpenComponent = (c: string) => {
    const componentIndex = components?.findIndex(x => x.subcomponent === c)

    if (componentIndex >= 0) {
      const indexes = expandedIndexes.includes(componentIndex)
        ? expandedIndexes
        : [...expandedIndexes, componentIndex]
      setExpandedIndexes(indexes)
      setFocusCollapsible(c)
    }
  }

  useEffect(() => {
    if (
      focusCollapsible &&
      components[expandedIndexes?.[expandedIndexes.length - 1]].subcomponent ===
        focusCollapsible
    ) {
      setTimeout(() => {
        document.getElementsByClassName(focusCollapsible)?.[0]?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'center',
        })
        setTimeout(() => {
          setFocusCollapsible(undefined)
        }, 100)
      }, 100)
    }
  }, [focusCollapsible, expandedIndexes])

  return (
    <div className="flex flex-col p-4">
      {/* <Graphs />
      <LenderSurplusTable />
      <CalculationTable /> */}
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <SummaryTable onClickComponent={_handleOpenComponent} />
        </div>
        <div>
          <div className="border border-neutral-border-2 rounded-lg p-4 justify-center">
            <HistoricalSurplusChart expanded />
          </div>
        </div>
      </div>
      {components.map((item, i) => {
        const isOpen = expandedIndexes.includes(i)
        const Icon = isOpen ? ChevronDownIcon : ChevronRightIcon
        return (
          <Accordion
            key={i}
            open={isOpen}
            icon={<span></span>}
            className={`border-t border-neutral-border ${item.subcomponent}`}
          >
            <AccordionHeader
              onClick={() => handleOpen(i)}
              className="text-lg border-0 justify-start"
            >
              <Icon className="w-6 h-6 mr-2" />
              <span className="text-left capitalize">
                {item.subcomponent.split('_').join(' ')}
              </span>
            </AccordionHeader>
            <AccordionBody>
              <DetailTable component={item} />
            </AccordionBody>
          </Accordion>
        )
      })}
    </div>
  )
}

export default BorrowingBaseL4
