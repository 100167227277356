type ListItem = string | { [key: string]: string }
export const filterList = (
  list: ListItem[],
  filter: string,
  key?: string,
  limit?: number
): ListItem[] => {
  const filteredList = list.filter((item: any) => {
    const itemValue = key ? item[key] : item

    return (
      filter &&
      itemValue.toLowerCase().startsWith(filter.toLowerCase()) &&
      filter !== itemValue
    )
  })
  const filteredOptions = limit ? filteredList.slice(0, limit) : filteredList
  return filteredOptions
}
