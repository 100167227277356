import React, { useContext, useEffect, useState } from 'react'

import Typography from '@components/atoms/typography'
import FilterCalculationDate from '@components/filters/filter-calculation-date'
import FilterCurrency from '@components/filters/filter-currency'
import FilterDebtDeal from '@components/filters/filter-debt-deal'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { ArrowPathIcon } from '@heroicons/react/24/outline'

// import AdvanceRateL4 from './advance-rate'
import BorrowingBaseL4 from './borrowing-base'
import CollateralL4 from './collateral'
import ExportDialog from './export-dialog'

const MonitorBorrowingBaseL3 = () => {
  const {
    company,
    activeFilters,
    appliedFilters,
    setAppliedFilters,
    optionFilters,
  } = useContext(AuthContext)
  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)

  const {
    activeDebtDeal,
    calculationDate,
    initialDate,
    calculationDateIsFetching,
  } = activeFilters

  const {
    activeDebtDeal: appliedDebtDeal,
    calculationDate: appliedCalculationDate,
  } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const facility = debtDealOptions?.[activeDebtDeal]?.facility

  const { filter_access, get_access } = useUserAccessFeature()
  const FEATURE = `${facility}_manage_monitor_borrowing-base`
  const can_export_bb = get_access(`${FEATURE}_borrowing-base`, 'export')
  const can_export_collateral = get_access(`${FEATURE}_collateral`, 'export')

  useEffect(() => {
    if (
      !filterReady &&
      typeof activeDebtDeal !== appliedDebtDeal &&
      calculationDate !== appliedCalculationDate
    ) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset = activeDebtDeal === 0 && calculationDate !== initialDate

  const tabs =
    debtDealOptions.length === 0 || !calculationDate
      ? []
      : [
          {
            label: 'Borrowing Base',
            component: <BorrowingBaseL4 />,
          },
          // { label: 'Advance Rate', component: <AdvanceRateL4 /> },
          { label: 'Collateral', component: <CollateralL4 /> },
        ].filter(t =>
          filter_access(
            `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`
          )
        )

  return (
    <L3TabsLayout
      title="Borrowing Base"
      filters={{
        left: <FilterCurrency hideSelect />,
        middle: <FilterDebtDeal />,
        right: <FilterCalculationDate />,
      }}
      disableReset={disableReset}
      tabs={tabs}
      empty={
        filterReady && !calculationDateIsFetching ? (
          <Typography className="text-center">{`No borrowing base found for ${company?.legal_name}`}</Typography>
        ) : (
          <div className="flex justify-center">
            <ArrowPathIcon className="w-6 h-6 text-primary-main animate-spin m-6" />
          </div>
        )
      }
      rightFooterContent={
        appliedCalculationDate && (can_export_bb || can_export_collateral) ? (
          <ExportDialog />
        ) : undefined
      }
    />
  )
}
export default MonitorBorrowingBaseL3
