import { useEffect, useState } from 'react'
import moment from 'moment'

import { hardcodedData } from './temp-hardcoded-data'

const getMillions = (value: number) => {
  if (!value) {
    return '0.0'
  }
  const debasedValue = value / 1000000
  return (Math.round(debasedValue * 10) / 10).toFixed(1)
}

const roundNumber = (value: number, decimals?: number) => {
  return (value ?? 0).toFixed(decimals ?? 2)
}

export const useFsHsbcComplianceCertData = (effectiveDate: string) => {
  const [data, setData] = useState<any>([true, {}])

  const date = moment(effectiveDate).format('YYYY-MM-DD')

  useEffect(() => {
    const returnedData: Record<string, string | string[]> = {
      effectiveDateOptions: [
        '2024-02-15',
        '2024-01-31',
        '2024-01-15',
        '2023-12-31',
      ],
      a1: `US$ ${getMillions(hardcodedData?.[date]?.['a1']) ?? 0} million`,
      a2: `US$ ${getMillions(hardcodedData?.[date]?.['a2']) ?? 0} million`,
      bi1: `US$ ${getMillions(hardcodedData?.[date]?.['bi1'])} million`,
      bi2: `US$ ${getMillions(hardcodedData?.[date]?.['bi2'])} million`,
      bii1: `US$ ${getMillions(hardcodedData?.[date]?.['bii1'])} million`,
      bii2: `US$ ${getMillions(hardcodedData?.[date]?.['bii2'])} million`,
      c1: `US$ ${getMillions(hardcodedData?.[date]?.['c1'])} million`,
      c2: `US$ ${getMillions(hardcodedData?.[date]?.['c2'])} million`,
      d1: `US$ ${getMillions(hardcodedData?.[date]?.['d1'])} million`,
      d2: `US$ ${getMillions(hardcodedData?.[date]?.['d2'])} million`,
      e1: `US$ ${getMillions(hardcodedData?.[date]?.['e1'])} million`,
      e2: `US$ ${getMillions(hardcodedData?.[date]?.['e2'])} million`,
      f1: `US$ ${getMillions(hardcodedData?.[date]?.['f1'])} million`,
      f2: `US$ ${getMillions(hardcodedData?.[date]?.['f2'])} million`,
      g1: `US$ ${getMillions(hardcodedData?.[date]?.['g1'])} million`,
      g2: `US$ ${getMillions(hardcodedData?.[date]?.['g2'])} million`,
      h1: `US$ ${getMillions(hardcodedData?.[date]?.['h1'])} million`,
      h2: `US$ ${getMillions(hardcodedData?.[date]?.['h2'])} million`,
      i1: `US$ ${getMillions(hardcodedData?.[date]?.['i1'])} million`,
      i2: `US$ ${getMillions(hardcodedData?.[date]?.['i2'])} million`,
      j1: `(US$ ${getMillions(hardcodedData?.[date]?.['j1'])} million)`,
      j2: `(US$ ${getMillions(hardcodedData?.[date]?.['j2'])} million`,
      k1: `US$ ${getMillions(hardcodedData?.[date]?.['k1'])} million`,
      k2: `US$ ${getMillions(hardcodedData?.[date]?.['k2'])} million`,
      l1: `US$ ${getMillions(hardcodedData?.[date]?.['l1'])} million`,
      l2: `US$ ${getMillions(hardcodedData?.[date]?.['l2'])} million`,
      m1: `US$ ${getMillions(hardcodedData?.[date]?.['m1'])} million`,
      m2: `US$ ${getMillions(hardcodedData?.[date]?.['m2'])} million`,
      n1: `US$ ${getMillions(hardcodedData?.[date]?.['n1'])} million`,
      n2: `US$ ${getMillions(hardcodedData?.[date]?.['n2'])} million`,
      o1: `${roundNumber(hardcodedData?.[date]?.['o1'], 0)}%`,
      o2: `${roundNumber(hardcodedData?.[date]?.['o2'])}%`,
      o3: `${roundNumber(hardcodedData?.[date]?.['o3'])}%`,
      o4: `${roundNumber(hardcodedData?.[date]?.['o4'])}%`,
      o5: `${roundNumber(hardcodedData?.[date]?.['o5'], 0)}%`,
      o6: `${roundNumber(hardcodedData?.[date]?.['o6'])} x`,
      o7: `${roundNumber(hardcodedData?.[date]?.['o7'], 0)}%`,
      o8: `${roundNumber(hardcodedData?.[date]?.['o8'])}%`,
      p1: `US$ ${getMillions(hardcodedData?.[date]?.['p1'])} million`,
      p2: `US$ ${getMillions(hardcodedData?.[date]?.['p2'])} million`,
      p3: `US$ ${getMillions(hardcodedData?.[date]?.['p3'])} million`,
      p4: `US$ ${getMillions(hardcodedData?.[date]?.['p1'] * 0.5)} million`,
      q1a: `US$ ${getMillions(hardcodedData?.[date]?.['q1a'])} million`,
      q1b: `US$ ${getMillions(hardcodedData?.[date]?.['q1b'])} million`,
      q2a: `S$ ${getMillions(hardcodedData?.[date]?.['q2a'])} million`,
      q2b: `US$ ${getMillions(hardcodedData?.[date]?.['q2b'])} million`,
      q3: `US$ ${getMillions(
        hardcodedData?.[date]?.['q1b'] + hardcodedData?.[date]?.['q2b']
      )} million`,
      q4:
        hardcodedData?.[date]?.['q3'] > hardcodedData?.[date]?.['p3']
          ? 'Y'
          : 'N',
      q5: 'N/A',
      r1a: `US$ ${getMillions(hardcodedData?.[date]?.['r1a'])} million`,
      r1b: `US$ ${getMillions(hardcodedData?.[date]?.['r1b'])} million`,
      r2a: `S$ ${getMillions(hardcodedData?.[date]?.['r2a'])} million`,
      r2b: `US$ ${getMillions(hardcodedData?.[date]?.['r2b'])} million`,
      r3: hardcodedData?.[date]?.['o2'] > 2.25 ? 'Y' : 'N',
    }
    returnedData['p5'] =
      hardcodedData?.[date]?.['p3'] > hardcodedData?.[date]?.['p1'] * 0.5
        ? returnedData['p4']
        : returnedData['p3']
    returnedData['r4'] = ' '
    returnedData['r5a'] = returnedData['r3'] === 'Y' ? returnedData['r1a'] : ' '
    returnedData['r5b'] = returnedData['r5a']
    returnedData['r6a'] = returnedData['r3'] === 'Y' ? returnedData['r2a'] : ' '
    returnedData['r6b'] =
      returnedData['r3'] === 'Y'
        ? `US$ ${getMillions(
            hardcodedData?.[date]?.['r2a'] / hardcodedData?.[date]?.['t1']
          )} million`
        : ' '
    returnedData['r8a'] = returnedData['r3'] === 'Y' ? returnedData['q1a'] : ' '
    returnedData['r8b'] = returnedData['r8a']
    returnedData['r9a'] = returnedData['r3'] === 'Y' ? returnedData['q2a'] : ' '
    returnedData['r9b'] =
      returnedData['r9a'] !== ' ' ? returnedData['q2b'] : ' '
    returnedData['r7'] =
      returnedData['r8b'] !== ' ' && returnedData['r9b'] !== ' '
        ? `US$ ${getMillions(
            hardcodedData?.[date]?.['q1a'] + hardcodedData?.[date]?.['q2b']
          )} million`
        : ' '
    returnedData['r10a'] = ' '
    returnedData['r10b'] = ' '
    returnedData['s1'] = 'N'
    returnedData['s2'] = hardcodedData?.[date]?.['o2'] > 2.75 ? 'Y' : 'N'
    returnedData['s3'] = 'N'
    returnedData['s4'] =
      hardcodedData?.[date]?.['o1'] < 140 ||
      hardcodedData?.[date]?.['o1'] >= 120
        ? 'Y'
        : 'N'
    returnedData['s5'] = hardcodedData?.[date]?.['n1'] < 250000 ? 'Y' : 'N'
    returnedData['s6'] = hardcodedData?.[date]?.['o7'] < 100 ? 'Y' : 'N'
    returnedData['s7'] = hardcodedData?.[date]?.['o8'] > 7 ? 'Y' : 'N'
    const sTestHasY = [
      returnedData['s1'],
      returnedData['s2'],
      returnedData['s3'],
      returnedData['s4'],
      returnedData['s5'],
      returnedData['s6'],
      returnedData['s7'],
    ].includes('Y')
    returnedData['s8a'] = sTestHasY ? returnedData['r1a'] : ''
    returnedData['s8b'] = returnedData['s8a']
    returnedData['s9a'] = sTestHasY ? returnedData['r2a'] : ''
    returnedData['s9b'] = sTestHasY ? returnedData['r2b'] : ''
    returnedData['s10'] = ' '
    returnedData['t1'] = `1 USD : ${hardcodedData?.[date]?.['t1']} SGD`
    setData([false, returnedData])
  }, [effectiveDate])

  return data
}
