import React, { useContext, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'

import Button from '@components/atoms/button'
import DatePicker from '@components/atoms/date-picker'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { LayoutFilter } from '@interfaces/filter'

const FilterDateRange = ({
  isCohort = false,
  forcedRange,
}: {
  isCohort?: boolean
  forcedRange?: { fDateStart: moment.Moment; fDateEnd: moment.Moment }
}) => {
  const { activeFilters, setActiveFilters, company } = useContext(AuthContext)

  const {
    dateStart,
    dateEnd = moment.utc(),
    dateStartCohort,
    dateEndCohort = moment.utc(),
    range,
  } = activeFilters

  const [fRange, setFRange] = useState<
    { fDateStart: moment.Moment; fDateEnd: moment.Moment } | undefined
  >()

  const company_end = moment.utc(company?.date_end)
  const end = forcedRange
    ? forcedRange.fDateEnd
    : company_end.isAfter(moment.utc())
    ? moment.utc(dateEnd)
    : company_end

  const start = forcedRange
    ? forcedRange.fDateStart
    : range <= 0
    ? moment.utc(company?.date_start)
    : end.clone().subtract(range, 'years')

  const end_cohort = moment.utc(company?.date_end_cohort)

  const start_cohort = forcedRange
    ? forcedRange.fDateStart
    : range <= 0
    ? moment.utc(company?.date_start)
    : end_cohort.clone().subtract(range, 'years')

  useEffect(() => {
    if (company) {
      setActiveFilters((prev: LayoutFilter) => {
        return {
          ...prev,
          range: range ?? 0,
          dateEnd: end.format(),
          dateStart: start.format(),
          dateEndCohort: end_cohort.format(),
          dateStartCohort: start_cohort.format(),
        }
      })
    }
  }, [company])

  useEffect(() => {
    if (
      forcedRange?.fDateEnd?.format('YYYY-MM-DD') !==
        fRange?.fDateEnd?.format('YYYY-MM-DD') ||
      forcedRange?.fDateStart?.format('YYYY-MM-DD') !==
        fRange?.fDateStart?.format('YYYY-MM-DD')
    ) {
      setFRange(forcedRange)
      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        dateEnd: end.format(),
        dateStart: start.format(),
        dateEndCohort: end_cohort.format(),
        dateStartCohort: start_cohort.format(),
      }))
    }
  }, [forcedRange])

  useEffect(() => {
    if (range !== -1) {
      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        dateEnd: end.format(),
        dateStart: start.format(),
        dateEndCohort: end_cohort.format(),
        dateStartCohort: start_cohort.format(),
      }))
    }
  }, [range])

  return (
    <div className="filter__date-range flex flex-col">
      <Typography variant="b2" className="font-semibold text-neutral-body-2">
        Date Range
      </Typography>
      <div className="flex mt-4 w-full items-center justify-between">
        <DatePicker
          value={moment.utc(isCohort ? dateStartCohort : dateStart)}
          onChange={val => {
            if (val) {
              const key = isCohort ? 'dateStartCohort' : 'dateStart'
              setActiveFilters((prev: LayoutFilter) => ({
                ...prev,
                [key]: val,
                range: -1,
              }))
            }
          }}
          disabledDate={(current: Moment) => {
            return (
              current.isBefore(moment.utc(isCohort ? start_cohort : start)) ||
              current.isAfter(moment.utc(isCohort ? dateEndCohort : dateEnd))
            )
          }}
        />
        <Typography className="text-neutral-body-2 mx-4">to</Typography>
        <DatePicker
          value={moment.utc(isCohort ? dateEndCohort : dateEnd)}
          onChange={val => {
            if (val) {
              const key = isCohort ? 'dateEndCohort' : 'dateEnd'
              setActiveFilters((prev: LayoutFilter) => ({
                ...prev,
                [key]: val,
                range: -1,
              }))
            }
          }}
          disabledDate={(current: Moment) => {
            return (
              current.isAfter(moment.utc(isCohort ? end_cohort : end)) ||
              current.isBefore(
                moment.utc(isCohort ? dateStartCohort : dateStart)
              )
            )
          }}
        />
      </div>
      <div className="flex items-center mt-4">
        <Typography variant="b2" className="font-semibold text-neutral-body-2">
          Last
        </Typography>
        <div className="ml-4 flex w-full">
          {Array(3)
            .fill('')
            .map((x, i) => (
              <Button
                key={i}
                color={range === i + 1 ? 'primary' : 'default'}
                onClick={() => {
                  setActiveFilters((prev: LayoutFilter) => ({
                    ...prev,
                    range: i + 1,
                  }))
                }}
                className={`flex-1 h-[40px] ${
                  i === 0 ? 'rounded-r-none' : 'rounded-none'
                }`}
              >{`${i + 1}Y`}</Button>
            ))}
          <Button
            className="rounded-l-none flex-1"
            color={range === 0 ? 'primary' : 'default'}
            onClick={() => {
              setActiveFilters((prev: LayoutFilter) => ({
                ...prev,
                range: 0,
              }))
            }}
          >
            All
          </Button>
        </div>
      </div>
    </div>
  )
}
export default FilterDateRange
