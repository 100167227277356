import React, { Fragment, useContext, useState } from 'react'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import AuthContext from '@contexts/auth'
import {
  ChevronRightIcon,
  CloudArrowUpIcon,
  PencilIcon,
} from '@heroicons/react/24/outline'
import BankAccount from '@interfaces/bank-account'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react'
import BankService from '@services/api-admin/data-source-banks'

import EditAccountDialog from '../bank-connector/edit-dialog'
import ManualAccountDialog from '../bank-connector/manual-dialog'

import BankAccordionBody from './bank-accordion'

interface BankListProps {
  banksArray?: BankAccount[]
  can_edit: boolean
}

const Banklist = ({ banksArray, can_edit }: BankListProps) => {
  const { company, setLoadingData } = useContext(AuthContext)
  const [open, setOpen] = useState<string>('')
  const [schemaForUpload, setSchemaForUpload] = useState<string>('')
  const [accountForUpload, setAccountForUpload] = useState<string>('')
  const [accountForEdit, setAccountForEdit] = useState<BankAccount>()
  const [manualAccountsDialog, setManualAccountsDialog] =
    useState<boolean>(false)
  const [editAccountsDialog, setEditAccountsDialog] = useState<boolean>(false)

  const handleManualAccountsOpen = (schemaNo?: number, accountId?: string) => {
    setSchemaForUpload(schemaNo ? `${company?.slug_name}_${schemaNo}` : '')
    setAccountForUpload(accountId ? accountId : '')

    setManualAccountsDialog(!manualAccountsDialog)
  }

  const handleEditAccount = (bank?: BankAccount) => {
    if (bank) {
      setAccountForEdit(bank)
    }
    setEditAccountsDialog(!editAccountsDialog)
  }

  const handleOpen = (id: string) => {
    setOpen(open === id ? '' : id)
  }

  const manualAccountSubmit = async (data: any, statement: File) => {
    handleManualAccountsOpen()

    if (company?.slug_name) {
      setLoadingData([
        { state: 'loading', text: 'Uploading document' },
        { state: 'waiting', text: 'Saving statement information' },
      ])

      const balanceInfo = {
        entry_id: uuidv4(),
        value_date: data.statementDate,
        account_balance: data.closingBalance,
        account_id: { account_id: accountForUpload },
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss.SSS ZZ'),
        schema_name: schemaForUpload,
        slug_name: company?.slug_name,
      }
      await BankService.uploadStatement(
        statement,
        company.slug_name,
        balanceInfo.entry_id
      )
        .then(async () => {
          setLoadingData([
            { state: 'done', text: 'Uploading document' },
            { state: 'loading', text: 'Saving statement information' },
          ])
          await BankService.addBalance(balanceInfo)
            .then(() => {
              setLoadingData([
                { state: 'done', text: 'Uploading document' },
                { state: 'done', text: 'Saving statement information' },
              ])
              setTimeout(() => {
                setLoadingData([])
              }, 3000)
            })

            .catch(err => {
              console.error(err)
              setLoadingData([
                { state: 'done', text: 'Uploading document' },
                { state: 'failed', text: 'Saving statement information' },
              ])
              setTimeout(() => {
                setLoadingData([])
              }, 5000)

              return
            })
        })

        .catch(err => {
          console.error(err)
          setLoadingData([
            { state: 'failed', text: 'Uploading document' },
            { state: 'waiting', text: 'Saving statement information' },
          ])
          setTimeout(() => {
            setLoadingData([])
          }, 5000)

          return
        })
    }
  }
  const editAccountSubmit = async (data: any) => {
    handleEditAccount()

    if (company?.slug_name && accountForEdit?.account_id) {
      setLoadingData([{ state: 'loading', text: 'Saving account information' }])
      const editAccountInfo = {
        account_id: accountForEdit?.account_id,
        bank_id: data.bankName,
        account_name: data.accName,
        account_no: data.accNo,
        account_nickname: data.nickname,
        entity_ownership: data.entityOwnership,
        account_type: data.accType,
        account_use: data.use,
        status: 'Active',
        account_balance: accountForEdit?.account_balance,
        data_source: 'Manual',
        data_source_supplementary: accountForEdit?.data_source_supplementary,
        slug_name: company?.slug_name,
        schema_no: accountForEdit.schema_no,
      }

      await BankService.editBankAccount(editAccountInfo)
        .then(async () => {
          setLoadingData([
            { state: 'done', text: 'Saving account information' },
          ])
          setTimeout(() => {
            setLoadingData([])
          }, 3000)
        })

        .catch(err => {
          console.error(err)
          setLoadingData([
            { state: 'failed', text: 'Saving account information' },
          ])

          setTimeout(() => {
            setLoadingData([])
          }, 5000)

          return
        })
    }
  }

  return (
    <>
      {!(banksArray && banksArray.length) ? (
        <div>No active bank accounts to display.</div>
      ) : (
        <div className="border border border-neutral-border-2 rounded-md ">
          <div className="px-10 text-sm md:text-md grid grid-cols-10 gap-3 text-left font-semibold text-neutral-subtitle-3 py-5 flex items-center border-b border-neutral-border-2">
            <div>Bank</div>
            <div>Acc Number</div>
            <div>Acc Name</div>
            <div>Nickname</div>
            <div>Entity Ownership</div>
            <div>Type</div>
            <div>Use</div>
            <div>Source</div>
            <div>Status</div>
          </div>
          {banksArray.map((bank: BankAccount) => (
            <Accordion
              key={bank.account_id}
              className="relative"
              open={open === bank.account_id}
              icon={
                <ChevronRightIcon
                  onClick={() => handleOpen(bank.account_id)}
                  className={`${
                    open === bank.account_id ? 'rotate-90 stroke-red' : ''
                  } ${
                    bank.data_source != 'Manual' && 'hidden'
                  } absolute left-3 h-4 w-4 transition-transform cursor-pointer`}
                />
              }
            >
              <AccordionHeader
                className={
                  open === bank.account_id ||
                  accountForEdit?.account_id == bank.account_id ||
                  accountForUpload === bank.account_id
                    ? 'bg-secondary-surface cursor-default'
                    : ' cursor-default'
                }
              >
                <div className="ml-10 text-xs md:text-sm font-light grid grid-cols-10 gap-3 text-left w-full">
                  <div>{bank.bank_id}</div>
                  <div>{bank.account_no}</div>
                  <div>{bank.account_name}</div>
                  <div>{bank.account_nickname}</div>
                  <div>{bank.entity_ownership}</div>
                  <div>{bank.account_type}</div>
                  <div>{bank.account_use}</div>
                  <div>{bank.data_source}</div>
                  <div>{bank.status}</div>
                  {bank.data_source == 'Manual' && can_edit && (
                    <div className="flex justify-around">
                      <PencilIcon
                        className="stroke-blue hover:opacity-50 w-5 cursor-pointer"
                        onClick={() => handleEditAccount(bank)}
                      />
                      <CloudArrowUpIcon
                        className="stroke-blue hover:opacity-50 w-6 cursor-pointer"
                        onClick={() =>
                          handleManualAccountsOpen(
                            bank.schema_no,
                            bank.account_id
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              </AccordionHeader>
              {bank.data_source == 'Manual' && (
                <AccordionBody className="flex justify-center">
                  <BankAccordionBody bank={bank} />
                </AccordionBody>
              )}
            </Accordion>
          ))}
        </div>
      )}
      <ManualAccountDialog
        open={manualAccountsDialog}
        handleOpen={handleManualAccountsOpen}
        handleSubmitManualInfo={manualAccountSubmit}
        statementUploadOnly={true}
      />
      {accountForEdit && (
        <EditAccountDialog
          open={editAccountsDialog}
          handleOpen={handleEditAccount}
          handleSubmitManualInfo={editAccountSubmit}
          defaultValues={accountForEdit}
        />
      )}
    </>
  )
}
export default Banklist
