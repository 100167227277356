export const mfpa001 = {
  audit_id: '8ep3j898-adff-4f7a-9a58-aed30h542cp2',
  id: 'dbad2fd2-9221-431b-9680-3e97be8e59a7',
  category: 'Other',
  name: 'Balance Audit Report 2024-02-23',
  dropbox_api_supp: 's3 upload',
  status: 'completed',
  author_email: 'don@cascadedebt.com',
  signees: '',
  supplementary_data:
    '{"ETag":"\\"f6e8b4abf2d07a683e8e5a00ef2620d4\\"","ServerSideEncryption":"AES256","Location":"https://mfs-cascade.s3.ap-southeast-2.amazonaws.com/Manage/Document%20Centre/Balance%20Audit%20Report%202024-02-23%20-%20dbad2fd2-9221-431b-9680-3e97be8e59a7","key":"Manage/Document Centre/Balance Audit Report 2024-02-23 - dbad2fd2-9221-431b-9680-3e97be8e59a7","Key":"Manage/Document Centre/Balance Audit Report 2024-02-23 - dbad2fd2-9221-431b-9680-3e97be8e59a7","Bucket":"mfs-cascade"}',
  slug: 'mfs',
  date_created: '2024-03-19T03:28:00.404Z',
  author_name: 'Don Devon',
  effective_date: '2024-02-23T00:00:00.000Z',
  facility: 'MSME Bonds',
  form_values: null,
  calculated_data: null,
  total: '1',
}
