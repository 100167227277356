import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useInfiniteQuery } from 'react-query'
import * as XLSX from 'xlsx'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  CollateralDataResponse,
  GetCollateralListRequest,
} from '@interfaces/collateral'
import { PaginationResponse } from '@interfaces/pagination'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { CollateralService } from '@services/api-data-validation/data-quarantine-collateral'

const ExportCollateralL3 = () => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }

  const { company, appliedFilters, optionFilters } = useContext(AuthContext)

  const { activeDebtDeal, collateral } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const { id, table_name, column_name, error_type, status } = collateral ?? {}

  const LIMIT = 1000

  const filters: GetCollateralListRequest = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    id,
    table_name,
    column_name,
    error_type,
    status,
    start: 0,
    limit: LIMIT,
  }

  const { data, fetchNextPage } = useInfiniteQuery({
    queryKey: ['collateralExport', filters],
    queryFn: ({ pageParam = 0 }) => {
      return CollateralService.getList({ ...filters, start: pageParam * LIMIT })
    },
    getNextPageParam: (lastPage, pages) => {
      return pages?.length
    },
    enabled: open,
  })

  const total =
    (data?.pages?.[0] as unknown as PaginationResponse<CollateralDataResponse>)
      ?.total ?? 0
  const maxPage = Math.ceil(total / LIMIT)
  const processedPage = (data?.pages ?? []).length
  const isProcessing = processedPage < maxPage

  const processedData =
    data?.pages?.reduce((prev: any, cur: any) => [...prev, ...cur.data], []) ??
    []

  const processedDataLength = processedData?.length

  const prepareExport = () => {
    if (open && isProcessing) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    if (open) {
      prepareExport()
    }
  }, [open, processedDataLength])

  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const _download = async () => {
    setIsDownloading(true)
    const worksheet = XLSX.utils.json_to_sheet(
      processedData.map((x: any) => ({
        ID: x.id,
        'Error Type': x.error_type,
        'Error Message': x.error_message,
        Status: x.to_quarantine ? 'Quarantined' : 'Flagged',
      }))
    )
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Collateral Data')
    await XLSX.writeFile(
      workbook,
      `Data Quarantined - Collateral Data - ${moment().format(
        'YYYY-MM-DD HH:mm:ss'
      )}.xlsx`
    )
    setIsDownloading(false)
  }

  return (
    <>
      <Button
        className="mr-0.25!shadow-none border border-primary-main text-primary-main py-2 px-6 hover:bg-primary-main hover:text-neutral-white"
        onClick={handleOpen}
      >
        <Typography className="capitalize font-medium text-sm">
          Export
        </Typography>
      </Button>
      <Dialog open={open} handler={() => undefined}>
        <DialogHeader className="justify-between">
          <Typography className="capitalize font-medium text-xl">
            Export Data Quarantine
          </Typography>
          <XMarkIcon
            onClick={handleOpen}
            className="w-6 h-6 cursor-pointer hover:opacity-50"
          />
        </DialogHeader>
        <DialogBody divider className="flex flex-col p-6">
          <Typography className="text-sm text-center font-bold">
            Please DO NOT close this dialog nor browser tab while exporting
          </Typography>
          <div className="mt-6">
            <div className="flex border border-primary-main rounded h-4">
              <div
                className="bg-primary-main animate-pulse"
                style={{
                  height: '100%',
                  width: `${(processedDataLength / total) * 100}%`,
                }}
              />
            </div>
            <div className="flex justify-center mt-2">
              <Typography className="text-xs">
                {`Exporting ${Intl.NumberFormat(undefined, {
                  style: 'decimal',
                }).format(processedDataLength)} of ${Intl.NumberFormat(
                  undefined,
                  {
                    style: 'decimal',
                  }
                ).format(total)}`}
              </Typography>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={_download}
            disabled={isDownloading || isProcessing}
            className="!shadow-none border py-2 px-6 border-primary-main text-primary-main hover:bg-primary-main hover:text-neutral-white disabled:!border-neutral-border-3 disabled:bg-neutral-border-2 disabled:text-neutral-border-3"
          >
            Download
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default ExportCollateralL3
