import React, { useEffect, useState } from 'react'

import Button from '@components/atoms/button'
import {
  ArrowPathIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'

import { Status, ValidationTypes } from '../../interfaces/validation.interface'

type ListItemProps<T> = {
  entityId: string
  slug_name: string
  sheetType: string
  setShowChildren: () => void
  showChildren: boolean
  item: T
  handleStatusUpdate: (s: Status) => void
  handleUpdatedRecords: (s: Record<string, any>[]) => void
}

const ValidationListItems = ({
  setShowChildren,
  showChildren,
  item,
  handleStatusUpdate,
  handleUpdatedRecords,
}: ListItemProps<Record<string, any>>) => {
  const [renderChildren, setRenderChildren] = useState<boolean>(false)
  useEffect(() => {
    if (
      (item.status === Status.FAILED &&
        item.validation === ValidationTypes.TEMPLATE) ||
      item.validation === ValidationTypes.DATES
    ) {
      setRenderChildren(true)
    }
  }, [item.status, showChildren])

  const getStatusIcon = () => {
    switch (item.status as Status) {
      case Status.LOADING:
        return (
          <div className="h-full flex items-center">
            <ArrowPathIcon className="animate-spin w-6 h-6 text-primary-main mr-2" />
          </div>
        )
      case Status.PASSED:
        return (
          <CheckCircleIcon
            color="green"
            className="h-6 w-6 flex-shrink-0 mr-2 opacity-75"
            fill="green"
          />
        )
      case Status.FAILED:
        return (
          <XCircleIcon
            color="red"
            className="h-6 w-6 flex-shrink-0 mr-2 opacity-75"
            fill="red"
          />
        )
    }
  }
  return (
    <div className="flex items-center border-b py-3">
      <div className="flex-1">
        <p className="text-sm">{item.text}</p>
      </div>
      <div className="h-full flex-shrink-0">{getStatusIcon()}</div>
      <div className="transition duration-500 ease-in-out">
        {item.status === 'FAILED' && (
          <Button onClick={setShowChildren} className="text-xs" size="sm">
            Review
          </Button>
        )}
      </div>
      {showChildren
        ? item.render(item, handleStatusUpdate, handleUpdatedRecords)
        : renderChildren && item.render && item.render(item)}
    </div>
  )
}

export default ValidationListItems
