import {
  OverviewDataResponse,
  RiskDataResponse,
  RisksFilters,
  TractionDataResponse,
} from '@interfaces/risk'

import apiClient from './client'

export class TractionService {
  static async getOutstandingBalance(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<TractionDataResponse>
      >('/risk-traction/outstanding-principal', params)

      return data
    }
  }

  static async getTotalLoans(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<TractionDataResponse>
      >('/risk-traction/outstanding-loan-count', params)

      return data
    }
  }

  static async getMonthlyActiveClients(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<TractionDataResponse>
      >('/risk-traction/active-clients', params)

      return data
    }
  }

  static async getTotalUniqueClients(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<TractionDataResponse>
      >('/risk-traction/total-unique-clients', params)

      return data
    }
  }

  static async getVolume(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<TractionDataResponse>
      >('/risk-traction/volume-cohort', params)

      return data
    }
  }

  static async getOverview(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<OverviewDataResponse>
      >('/risk-traction/overview-traction', params)

      return data
    }
  }
}
