import React from 'react'
import moment from 'moment'

import { Page, View } from '@react-pdf/renderer'

import { styles } from '../../../template_style'
import { MFSFooter, MFSHeader } from '../../mfs'
import { Text } from '../../text'

interface Props {
  title: string
  effectiveDate: string
  customData: Record<string, any>
  formData: Record<string, any>
  contentStrings: {
    forAttention: string
    viaEmail: string[]
    facility: string
  }
}

const textOtherStyles = {
  marginVertical: 10,
}

export const MfsTitlePage = ({
  title,
  effectiveDate,
  customData,
  formData,
  contentStrings,
}: Props) => {
  return (
    <Page>
      <MFSHeader fixed={true} address={customData.headerAddress} />
      <View
        style={{ paddingTop: 16, paddingBottom: 65, paddingHorizontal: 58 }}
      >
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent:
              contentStrings.facility === 'Lendable SPC'
                ? 'flex-end'
                : 'flex-start',
          }}
        >
          <Text otherStyles={textOtherStyles}>
            {moment().format('DD MMMM YYYY')}
          </Text>
        </View>
        <Text bold otherStyles={textOtherStyles}>
          {contentStrings.facility}
        </Text>
        <Text otherStyles={textOtherStyles}>
          For Attention: {contentStrings.forAttention}
        </Text>
        <Text otherStyles={textOtherStyles}>
          Via E-mail: {contentStrings.viaEmail[0]}{' '}
          {contentStrings.viaEmail[1] && (
            <Text otherStyles={{ ...textOtherStyles, color: '#0563C1' }}>
              {contentStrings.viaEmail[1]}
            </Text>
          )}
        </Text>
        <Text
          bold
          otherStyles={{
            marginTop: 36,
            marginBottom: 10,
            fontSize: 14,
            color: '#1f3763',
          }}
        >
          {title} -{' '}
          {effectiveDate
            ? moment(effectiveDate).format('DD MMMM YYYY')
            : '[Effective date]'}
        </Text>
        <Text otherStyles={{ ...textOtherStyles, marginBottom: 30 }}>
          Please find attached the detailed compliance certificate for the
          period ended{' '}
          {effectiveDate
            ? moment(effectiveDate).format('DD MMMM YYYY')
            : '[Effective date]'}
          .
        </Text>
        <Text otherStyles={textOtherStyles}>Yours faithfully,</Text>
        <Text otherStyles={styles.signTags}>[sig|req|signer1|signature]</Text>
        <Text
          underline
          otherStyles={{
            color: 'white',
            fontSize: 6,
            ...textOtherStyles,
            textDecorationColor: 'black',
          }}
        >
          {
            'This is just some hidden filler text that has to be as long as the line under the signature'
          }
        </Text>
        <Text otherStyles={textOtherStyles}>
          {formData?.signers?.[0]?.name || `[Authorized Officer Name]`}
        </Text>
        <Text otherStyles={textOtherStyles}>
          {formData?.signers?.[0]?.title || `[Authorized Officer Title]`}
        </Text>
        <Text otherStyles={textOtherStyles}>For Onafriq Group</Text>
      </View>
      <MFSFooter />
    </Page>
  )
}
