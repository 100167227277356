import {
  CovenantDetailRequest,
  CovenantDetailResponse,
  CovenantListRequest,
  CovenantListResponse,
} from '@interfaces/covenant'

import manageApiClient from './client'

export class CovenantService {
  static async getList(params: CovenantListRequest) {
    const { data } = await manageApiClient.post<CovenantListResponse[]>(
      `/covenant/list`,
      params
    )

    return data
  }

  static async getDetail(params: CovenantDetailRequest) {
    const { data } = await manageApiClient.post<CovenantDetailResponse[]>(
      `/covenant/detail`,
      params
    )

    return data
  }

  static async getRawData(params: CovenantDetailRequest) {
    const { data } = await manageApiClient.post<any[]>(`/covenant/raw`, params)

    return data
  }

  static async getCovenantNames(params: CovenantDetailRequest) {
    const { data } = await manageApiClient.post<any[]>(
      `/covenant/list-report-covenants`,
      params
    )

    return data
  }
}
