import React, { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import Typography from '@components/atoms/typography'
import { Role } from '@constants/role'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { LayoutFilter } from '@interfaces/filter'
import { Option, Radio, Select } from '@material-tailwind/react'
import { ManageFilterService } from '@services/api-manage/filter'

const FilterDebtDeal = ({
  type = 'radio',
  isFilterAccess = true,
  applyOnChange = false,
}: {
  type?: 'select' | 'radio'
  isFilterAccess?: boolean
  applyOnChange?: boolean
}) => {
  const {
    company,
    activeFilters,
    setActiveFilters,
    setAppliedFilters,
    appliedFilters,
    optionFilters,
    setOptionFilters,
  } = useContext(AuthContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const { activeDebtDeal: appliedActiveDebtDeal = 0, debtDealCompanySlugName } =
    appliedFilters
  const { userCompanyAccess, role } = useUserAccessFeature()
  const activeAccess = userCompanyAccess?.access
  const { activeDebtDeal } = activeFilters
  const { debtDealOptions = [], allDebtDealOptions = [] } = optionFilters
  const availableDebtFacilities = Object.keys(activeAccess ?? {}).reduce(
    (p: string[], c) => {
      const splittedKey = c.split('_')
      const facility = splittedKey[0]?.toLowerCase()
      const module = splittedKey[1]?.toLowerCase()
      return module === 'manage' && !p.includes(facility) ? [...p, facility] : p
    },
    []
  )
  const filterOutFacilities = (ddo: any) => {
    return activeAccess && isFilterAccess && role === Role.custom
      ? ddo?.filter((x: any) =>
          availableDebtFacilities.includes(x.facility.toLowerCase())
        )
      : ddo
  }
  const options =
    activeAccess && isFilterAccess && role === Role.custom
      ? debtDealOptions
      : allDebtDealOptions

  const activeDebtDealObject = options?.[activeDebtDeal]

  useEffect(() => {
    if (company && company.slug_name !== debtDealCompanySlugName) {
      ManageFilterService.getFacilityList({
        slug_name: company?.slug_name ?? '',
      })
        .then(response => {
          setActiveFilters((prev: LayoutFilter) => ({
            ...prev,
            activeDebtDeal: appliedActiveDebtDeal,
          }))
          setOptionFilters((prev: LayoutFilter) => ({
            ...prev,
            debtDealCompanySlugName: company?.slug_name,
            debtDealOptions: filterOutFacilities(response),
            allDebtDealOptions: response,
          }))
        })
        .catch(() => {
          console.error('error')
          setActiveFilters((prev: LayoutFilter) => ({
            ...prev,
            activeDebtDeal: appliedActiveDebtDeal,
          }))
        })
    }
  }, [company])

  // Handle external download links (from email or documents)
  useEffect(() => {
    const facilityParam = searchParams.get('facility')
    if (company?.slug_name && facilityParam) {
      debtDealOptions.forEach((o: { facility: string }, i: number) => {
        if (o.facility === facilityParam) {
          setActiveFilters((prev: LayoutFilter) => ({
            ...prev,
            activeDebtDeal: i,
          }))
          setAppliedFilters((prev: LayoutFilter) => ({
            ...prev,
            activeDebtDeal: i,
          }))
        }
      })
    }
    searchParams.delete('facility')
    setSearchParams(searchParams)
  }, [searchParams])

  return (
    <div
      className={`flex w-full gap-6 ${type === 'select' ? 'items-center' : ''}`}
    >
      <Typography variant="b2" className="font-semibold text-neutral-body-2">
        Facilities
      </Typography>
      {type === 'radio' && (
        <form
          className={`border border-neutral-border-2 rounded-lg px-4 py-0.5 flex flex-1 flex-col max-h-[115px] overflow-y-auto`}
        >
          {options.length === 0 && <Typography>No facility found</Typography>}
          {options.map((o: { facility: string }, i: number) => (
            <Radio
              key={o.facility}
              id={o.facility}
              name={`activeDebtDeal-${i}`}
              label={o.facility}
              labelProps={{ class: 'capitalize' }}
              checked={o.facility === activeDebtDealObject?.facility}
              onChange={() => {
                setActiveFilters((prev: LayoutFilter) => ({
                  ...prev,
                  activeDebtDeal: i,
                }))
                !!applyOnChange &&
                  setAppliedFilters((prev: LayoutFilter) => ({
                    ...prev,
                    activeDebtDeal: i,
                  }))
              }}
            />
          ))}
        </form>
      )}
      {type === 'select' && (
        <Select
          className="[&~ul]:max-h-[100px]"
          disabled={options.length === 0}
          onChange={val => {
            setActiveFilters((prev: LayoutFilter) => ({
              ...prev,
              activeDebtDeal: val,
            }))
            !!applyOnChange &&
              setAppliedFilters((prev: LayoutFilter) => ({
                ...prev,
                activeDebtDeal: val,
              }))
          }}
          selected={() => {
            return <span>{activeDebtDealObject?.facility}</span>
          }}
        >
          {options.map((c: { facility: string }, i: number) => (
            <Option key={c.facility} value={i.toString()}>
              {c.facility}
            </Option>
          ))}
        </Select>
      )}
    </div>
  )
}
export default FilterDebtDeal
