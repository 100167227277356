import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import FpdL5 from './fpd'

const FirstPaymentDefaultL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const generateDesc = () => {
    return {
      description: `Loans that do not have any repayments before defaulting and being written off.`,
      formula:
        'Use same default definition as used in default rate. Outstanding balance of loans without any payment / Total Principal Disbursed',
    }
  }

  const tabs = [30].map(v => ({
    label: `${v} DPD`,
    desc: generateDesc(),
    component: <FpdL5 value={v.toString()} exportable={exportable} />,
  }))

  return (
    <L4TabsLayout
      tabs={tabs}
      additionalIndicator={
        showHistoricalRateIndicator ? (
          <ExchangeRateBadge isHistorical={false} />
        ) : undefined
      }
    />
  )
}

export default FirstPaymentDefaultL4
