import React from 'react'
import { NavLink } from 'react-router-dom'

import Typography from '@components/atoms/typography'
import Table from '@components/table'
import { CovenantListResponse, CovenantType } from '@interfaces/covenant'

import { useVintageCovenantDetail } from '../helper'

interface CovenantL4SummaryOverviewProps {
  covenants: CovenantListResponse[]
  type: CovenantType
  link?: string
}
const CovenantL4SummaryVintageOverview = ({
  covenants,
  type,
  link,
}: CovenantL4SummaryOverviewProps) => {
  const conv = covenants?.[0]

  const { overview_columns, overview_data } = useVintageCovenantDetail(
    covenants,
    type
  )

  return (
    <NavLink
      to={
        (link ?? `/manage/monitor/${type}-covenants?`) +
        `tab=${conv?.[`${type}_covenant`].toLowerCase().split(' ').join('-')}`
      }
      className="flex flex-col border border-neutral-border-2 rounded p-3 gap-6 hover:bg-primary-surface-2"
    >
      <div className="flex flex-col flex-1">
        <Typography className="font-medium text-center">
          {conv[`${type}_covenant`]}
        </Typography>
      </div>

      <div className="flex flex-1 [&>div]:!mb-0">
        <Table columns={overview_columns as any} data={overview_data} />
      </div>
    </NavLink>
  )
}

export default CovenantL4SummaryVintageOverview
