import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import ExchangeRateBadge from '@components/exchange-rate-badge'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import StatisticL5 from '@components/layouts/l5-stat'
import { AVG_MONTH_THRESHOLD_DAYS } from '@constants/app'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Filter, FilterItem } from '@interfaces/filter'
import { RisksFilters } from '@interfaces/risk'
import { Tooltip } from '@material-tailwind/react'
import { CollectionService } from '@services/api-analytics/risk-collection'
import FilterService from '@services/api-analytics/risk-traction-filter'

const NetYieldL5 = ({ exportable }: { exportable: boolean }) => {
  const [isAdjusted, setIsAdjusted] = useState<boolean>(false)

  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes = [],
    activeType,
    currency = 'USD',
  } = appliedFilters
  const {
    categoryTypeOptions = [],
    rates = [],
    display_rates = [],
  } = optionFilters
  const isAggregate = activeType === 'All'
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const avgFilters = {
    date_from: moment.utc(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEndCohort).format('YYYY-MM-DD'),
    slug_name: company?.slug_name,
    filters: categoryTypes,
  }

  const { data: avgTerm, isFetching: isfetchingAvgTerm } = useQuery(
    ['avgTerm', avgFilters],
    () => FilterService.getAvgTerm(avgFilters),
    { ...getStaleMins(), enabled: categoryTypes?.length > 0 }
  )

  const filters: RisksFilters = {
    ...avgFilters,
    is_adjusted: isAdjusted,
  }

  const {
    error: errorAggregated,
    data: fetchedDataAggregated,
    isFetching: isFetchingAggregated,
  } = useQuery(
    ['netYield', filters, true],
    () =>
      CollectionService.getNetYield({
        ...filters,
        is_aggregate: true,
        filters: categoryTypeOptions?.reduce(
          (prev: string[], cur: Filter) => [
            ...prev,
            ...cur.type_val.map((x: FilterItem) => x.id),
          ],
          []
        ),
      }),
    { ...getStaleMins(), enabled: !!avgTerm }
  )

  const {
    error: errorNonAggregated,
    data: fetchedDataNonAggregated,
    isFetching: isFetchingNonAggregated,
  } = useQuery(
    ['netYield', filters, false, categoryTypes],
    () =>
      CollectionService.getNetYield({
        ...filters,
        is_aggregate: false,
        filters: categoryTypes,
      }),
    { ...getStaleMins(), enabled: !!avgTerm && isAggregate === false }
  )

  const error = isAggregate ? errorAggregated : errorNonAggregated
  const isFetching = isAggregate
    ? isFetchingAggregated
    : isFetchingNonAggregated

  const { data, ...growthRatios } =
    (isAggregate ? fetchedDataAggregated : fetchedDataNonAggregated) ?? {}

  const cohort = (data || []).filter(
    (v, i, a) => a.findIndex(v2 => v2.cohort === v.cohort) === i
  )

  let minRange = 0

  const chartData = cohort.map(c => {
    const result: { x: number; [key: string]: any } = {
      x: moment(c.cohort).valueOf(),
      ...(rates ?? []).reduce((p = {}, r: any) => {
        return {
          ...p,
          [`fx_${r.code}`]: Intl.NumberFormat(undefined, {
            style: 'decimal',
            notation: 'compact',
            maximumFractionDigits: 2,
          }).format(r.display_rates?.[c.cohort] ?? 1),
        }
      }, {}),
    }
    const cohortData = (data || []).filter(d => d.cohort === c.cohort)
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
      if (result.type_all < minRange) {
        minRange = result.type_all
      }
    } else {
      ;[0, ...categoryTypes].forEach((ct: number) => {
        const cohortCategory =
          ct === 0
            ? fetchedDataAggregated?.data?.find(
                x => x.cohort === c.cohort && x.id === ct
              )
            : cohortData.find(cd => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
        if (result[`type_${ct}`] < minRange) {
          minRange = result[`type_${ct}`]
        }
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  const netYieldSeries = isAggregate
    ? [
        {
          label: 'Net Yield',
          tooltipValueFormat: '#.0a%',
          type: 'SmoothedXLineSeries',
          field: 'type_all',
        },
      ]
    : [0, ...categoryTypes].map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: `${typeVal ? typeVal.type : 'All'}`,
          tooltipValueFormat: '#.0a%',
          field: `type_${ct}`,
          color,
          type: 'SmoothedXLineSeries',
          isStack: true,
        }
      })

  const showAdjustedToggle =
    parseFloat((avgTerm ?? 0).toString()) <= AVG_MONTH_THRESHOLD_DAYS

  return (
    <div className="[&>div>div:nth-child(2)]:mt-10">
      <L5ChartstatLayout
        chart={
          <>
            <div className="flex mb-8 justify-between">
              <div className="flex justify-center items-center">
                <Tooltip
                  content={
                    <span>
                      Description:
                      <br />
                      The net profits a loan portfolio is generating each month.
                      <br />
                      <br />
                      Outstanding Balance:
                      <br />
                      All unpaid principal as of a specific date.
                      <br />
                      <br />
                      {showAdjustedToggle && (
                        <>
                          Adjusted Outstanding Balance:
                          <br />
                          All unpaid principal as of specific date + paid
                          principal for loans disbursed and paid in same month
                          <br />
                          <br />
                        </>
                      )}
                      Formula:
                      <br />
                      [(non-principal payments - new writeoff) in a month / end
                      of month outstanding balance] x 12
                      <br />
                      <br />
                      Write Offs = {company?.writeoff_days} days
                      <br />
                    </span>
                  }
                  placement="right"
                >
                  <InformationCircleIcon className="w-8 text-primary-main cursor-help" />
                </Tooltip>
                {showHistoricalRateIndicator && <ExchangeRateBadge />}
              </div>
              <div className="gap-4 flex flex-col 2xl:flex-row">
                {[
                  ...(showAdjustedToggle
                    ? [
                        {
                          options: [
                            {
                              title: 'Outstanding Balance',
                              active: !isAdjusted,
                              action: () => setIsAdjusted(false),
                            },
                            {
                              title: 'Adjusted Outstanding Balance',
                              active: isAdjusted,
                              action: () => setIsAdjusted(true),
                            },
                          ],
                        },
                      ]
                    : []),
                ].map((group, i) => (
                  <div key={i} className={`flex `}>
                    <div className="bg-neutral-border-1 rounded-md p-1 flex">
                      {group.options.map((g, j) => (
                        <button
                          key={j}
                          className={`block text-sm rounded-md px-8 py-0.5 font-semibold ${
                            g.active ? 'bg-secondary-main text-white' : ''
                          }`}
                          onClick={g.action}
                        >
                          {g.title}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Chart
              loading={isFetching || isProcessing || isfetchingAvgTerm}
              id={`riskCollectionNetYield_by_${activeType}`}
              yLabel="Percentage"
              ySetting={
                minRange < 0 ? { min: minRange - 5, maxPrecision: 1 } : {}
              }
              yFormat="#.0a%"
              data={chartData}
              series={netYieldSeries as any}
              tooltipSubtitle={
                showHistoricalRateIndicator
                  ? (display_rates ?? []).map((r: any) => {
                      return {
                        field: `fx_${r.code}`,
                        title: `USD:${r.code}`,
                      }
                    })
                  : undefined
              }
              exportable={exportable}
              error={error as { message: string }}
            />
          </>
        }
        stat={<StatisticL5 {...growthRatios} />}
      />
    </div>
  )
}

export default NetYieldL5
