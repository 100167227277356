import { Languages } from '@constants/app'

export default {
  [Languages.ENGLISH]: {
    login: {
      signIn: 'Sign In',
      username: 'Username',
      password: 'Password',
      error: 'Incorrect username or password',
    },
    navbar: {
      summary: 'SUMMARY',

      analytics: {
        analytics: 'ANALYTICS',
        list: {
          risk: 'RISK',
          financial: 'FINANCIAL',
          debtTable: 'DEBT TABLE',
          data: 'DATA',
        },
      },
      raise: {
        raise: 'RAISE',
        list: {
          dataRoom: 'DATA ROOM',
          structuring: 'STRUCTURING',
          scenarioModeling: 'SCENARIO MODELING',
        },
      },
      manage: {
        manage: 'MANAGE',
        list: {
          allocate: 'ALLOCATE',
          monitor: 'MONITOR',
          reporting: 'REPORTING',
        },
      },
    },
    risk: {
      filters: {
        dataLastUpdated: 'Data last updated',
        dataRange: 'Date Range',
        ranges: {
          oneYear: '1Y',
          twoYears: '2Y',
          threeYears: '3Y',
          all: 'All',
        },
        last: 'Last',
        currency: 'Currency',
        usd: 'USD',
      },
      view: {
        keyIndicators: 'Key Indicators',
        traction: 'Traction',
        risk: 'Risk',
        collection: 'Collection',
        cohort: 'Cohort',
        characteristics: 'Characteristics',
      },
      tractionView: {
        monthlyVolumeTitle: 'Monthly Volume',
        totalVolumeTitle: 'Total Volume',
        monthlyActiveClientsTitle: 'Monthly Active Clients',
        totalUniqueClientsTitle: 'Total Unique Clients',
        outstandingBalanceTitle: 'Outstanding Balance',
        totalLoansTitle: 'Total Loans',
      },

      summaryView: {
        title: 'Summary Risk',
        summaryHeaderTable: 'Summary',

        tables: {
          cumulativeTraction: {
            title: 'Cumulative Traction',
            timePeriod: 'Time Period',
            totalBorrowers: 'Total Borrowers',
            totalBorrowersPercentage: 'Total Borrowers (%)',
            totalLoans: 'Total Loans',
            totalLoansPercentage: 'Total Loans (%)',
            totalVolume: 'Total Volume',
            totalVolumePercentage: 'Total Volume (%)',
          },
          currentTraction: {
            title: 'Current Traction',
            avgMonthlyVolume3Months: 'Avg Monthly Volume (Last 3 Months)',
            avgMonthlyVolume12Months: 'Avg Monthly Volume (Last 12 Months)',
            outstandingPortfolio: 'Outstanding Portfolio',
            outstandingPortfolioPercentage: 'Outstanding Portfolio (%)',
            activeLoans: 'Active loans (%)',
            activeBorrowers: 'Active Borrowers',
          },
          portfolioCharacteristics: {
            title: 'Portfolio Characteristics',
            avgAPRall: 'Avg APR (All)',
            avgEffectiveInterestRateAll: 'Avg Effective Interest Rate (all)',
            avgTermInMonthsAll: 'Avg Term In Months (All)',
            avgSizeAll: 'Avg Size (All)',
            avgAPRLast12Months: 'Avg APR (Last 12 Months)',
            avgEffectiveInterestRate12Months:
              'Avg Effective Interest Rate (Last 12 Months)',
            avgTermInMonthsLast12Months: 'Avg Term In Months (Last 12 Months)',
            avgSizeLast12Months: 'Avg Size (Last 12 Months)',
          },
          portfolioPerformance: {
            title: 'Portfolio Performance',
            par30: 'PAR30',
            par90: 'PAR90',
          },
        },
      },
      riskView: {
        qrtOverQtr: 'Qtr over Qtr',
        monthOverMonth: 'Month over Month',
        yearOverYear: 'Year over Year',
        portfolioAtRiskTitle: 'Portfolio at Risk',
        rollingDefaultRateTitle: 'Rolling Default Rate',
        outstandingByDelinquencyTitle: 'Outstanding by Delinquency (%)',
        firstPaymentDefaultTitle: 'First Payment Default',
        borrowerConcentrationTitle: 'Borrower Concentration (%)',
        delinquencyByCohortTitle: 'Delinquency by Cohort',
        rollingDefaultRate: {
          volume: 'Volume',
        },
        outstandingByDelinquency: {
          '0DPD': '0 DPD',
          '1to14DPD': '1-14 DPD',
          '15to29DPD': '15-29 DPD',
          '30to59DPD': '30-59 DPD',
          '60to89DPD': '60-89 DPD',
          '90to119DPD': '90-119 DPD',
          '120DPD': '120+ DPD',
        },
        borrowerConcentration: {
          top10Borrowers: 'Top 10 Borrowers',
          top11to20Borrowers: 'Top 11-20 Borrowers',
          top21to50Borrowers: 'Top 21-50 Borrowers',
          top51to100Borrowers: 'Top 51-100 Borrowers',
          restBorrowers: 'Rest',
        },
        delinquencyByCohort: {
          paid: 'Paid',
        },
      },
      overview: {
        title: 'Risks Overview',
      },
    },
    administration: {
      esgReportingView: {
        collapsibleTitles: {
          environmental: 'Environmental',
          social: 'Social',
          governance: 'Governance',
        },
        buttonText: 'Publish',
        title: 'ESG Reporting',
      },
      financialCrimeView: {
        buttonText: 'Publish',
        title: 'Wolfsberg Group Financial Crime Questionnaire',
      },
      debtTableView: {
        title: 'Debt Table',
        buttonText: '+ Add Facility',
        mainText:
          'The debt table is like an equity table, which is a record of all outstanding debt a company currently has.  This is something that all debt investors will want and is a great way for a company to track the overall cost of debt on their books.  If your company uses the manage function of Cascade, the debt table will be automatically updated.  If your company does not use the manage function, the debt table will need to be updated manually so that it remains up-to-date.',
      },
    },
    financials: {
      view: {
        keyIndicators: 'Key Indicators',
        statements: 'Statements',
        debtTable: 'Debt Table',
        overview: 'Overview',
      },
      overview: {
        title: 'Financials Overview',
      },
    },
    manage: {
      overview: {
        title: 'Manage Overview',
      },
      status: {
        title: 'Status',
      },
      collateral: {
        title: 'Collateral',
      },
      deal: {
        title: 'Deal Details',
        dealView: {
          title: 'Detail View',
        },
      },
    },
    statsHeader: {
      traction: {
        totalVolume: 'Total Volume',
        totalClients: 'Total Clients',
        outstanding: 'Outstanding',
      },
    },
  },
}
