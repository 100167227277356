import React from 'react'

import L4TabsLayout from '@components/layouts/l4-tabs-layout'

import NetWorthL5 from './net-worth'

const NetWorthL4 = ({ exportable }: { exportable: boolean }) => {
  const tabs = [
    {
      label: 'Net Worth',
      desc: 'The equity of the business',
      component: <NetWorthL5 exportable={exportable} />,
    },
  ]

  return <L4TabsLayout tabs={tabs} />
}

export default NetWorthL4
