import React, { useState } from 'react'

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react'

import Activity from '../../activity'

interface AuditInfoAccordionGroupProps {
  artifact: string
  showCols: string[]
  data: any
}

export const AuditInfoAccordionGroup = ({
  artifact,
  showCols,
  data,
}: AuditInfoAccordionGroupProps) => {
  const [openAccordion, setOpenAccordion] = useState(1)

  const handleOpen = (value: React.SetStateAction<number>) => {
    setOpenAccordion(0)
    //avoids unexpect behaviour on height of the dialog
    setTimeout(() => {
      setOpenAccordion(openAccordion === value ? 0 : value)
    }, 200)
  }

  const AccordionIcon = ({ open }: { open: boolean }) => {
    const Icon = open ? ChevronUpIcon : ChevronDownIcon
    return <Icon className="w-4 h-4" />
  }

  const paymentInfo = Object.keys(data.data)
    .map((key: string) => {
      return {
        artifact_info: key,
        artifact_val: data.data[key],
      }
    })
    .filter((item: any) => {
      return showCols.includes(item.artifact_info)
    })

  return (
    <div>
      <>
        <Accordion
          icon={<AccordionIcon open={openAccordion == 1} />}
          open={openAccordion == 1}
          className={`flex flex-col ${
            openAccordion == 1 ? `[&>div]:!h-[unset]` : ``
          }`}
        >
          <AccordionHeader
            onClick={() => handleOpen(1)}
            className="text-md text-left"
          >
            {`${artifact} Information`}
          </AccordionHeader>
          <AccordionBody className="flex flex-col p-0 pt-0 pb-2 pt-2 overflow-y-auto">
            {paymentInfo.map((payment: any, idx: number) => (
              <div key={idx} className="flex grid grid-cols-2 gap-4 my-1">
                <div className="font-bold">
                  {payment.artifact_info
                    .replace('artifact', artifact)
                    .toUpperCase()
                    .replace('_', ' ')}
                </div>
                <div>{payment.artifact_val}</div>
              </div>
            ))}
          </AccordionBody>
        </Accordion>
        <Accordion
          icon={<AccordionIcon open={openAccordion == 2} />}
          open={openAccordion == 2}
          className={`flex flex-col`}
        >
          <AccordionHeader
            onClick={() => handleOpen(2)}
            className="text-md text-left"
          >
            Activity
          </AccordionHeader>
          <AccordionBody className="flex flex-col py-2 h-[160px] overflow-y-auto">
            <Activity data={data.activity} />
          </AccordionBody>
        </Accordion>
      </>
    </div>
  )
}
