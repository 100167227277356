import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import appRouting from '@components/app-routes/routes'
import AuthContext from '@contexts/auth'
import { IconButton, Tooltip } from '@material-tailwind/react'

interface SidebarProps {
  /**
   * whether L1 navigation is expanded or not
   */
  isExpanded: boolean
}

const Sidebar = ({ isExpanded }: SidebarProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth0 = useAuth0()

  const context = useContext(AuthContext)
  const { setShowSidemenu } = context

  const flaggedRouting = appRouting(auth0, context, true)

  return (
    <div
      className={`bg-neutral-white flex flex-col border-r border-neutral-border-1 w-sidebar h-full z-10`}
    >
      <div className="h-sidebar flex justify-center items-center">
        <img
          src="/svgs/cascade-logo.svg"
          alt="Cascade"
          data-testid="cascade-logo"
          className="w-[30px] h-[30px] my-6"
        />
      </div>
      <div
        className={`${
          isExpanded ? 'visible' : 'invisible'
        } lg:visible flex flex-1 flex-col items-center`}
      >
        {flaggedRouting.map(r => {
          const isActive = location.pathname.includes(r.path)
          const Icon = isActive ? r.activeIcon : r.icon
          return (
            <Tooltip key={r.path} content={r.title} placement="right">
              <IconButton
                variant="text"
                className={`w-[40px] h-[40px] mb-4 hover:bg-secondary-surface rounded transition-none ${
                  isActive ? 'bg-primary-surface-2' : ''
                }`}
                onClick={() => {
                  setShowSidemenu(true)
                  navigate(r.path)
                }}
              >
                <Icon className="w-6 text-primary-main" />
              </IconButton>
            </Tooltip>
          )
        })}
      </div>
    </div>
  )
}

export default Sidebar
