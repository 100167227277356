import React, { PropsWithChildren } from 'react'

import { Text as PdfText } from '@react-pdf/renderer'

interface Props {
  size?: 'small' | 'normal'
  bold?: boolean
  underline?: boolean
  indent?: number
  otherStyles?: Record<string, string | number>
  wrap?: boolean
  debug?: boolean
  render?: () => void
  fixed?: boolean
  hephenationCallback?: () => void
  id?: string
  bookmark?: string
}

export const Text = ({
  children,
  size,
  bold,
  underline,
  indent,
  otherStyles,
  ...restProps
}: PropsWithChildren<Props>) => {
  const styles = {
    fontSize: size === 'small' ? 10 : 12,
    fontFamily: bold ? 'Times-Bold' : 'Times-Roman',
    textDecoration: underline ? ('underline' as const) : ('none' as const),
    textIndent: indent || 0,
    ...otherStyles,
  }

  return (
    <PdfText style={styles} {...restProps}>
      {children}
    </PdfText>
  )
}
