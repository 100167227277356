export default {
  styles: {
    variants: {
      h1: {
        fontSize: 'text-7xl',
        fontWeight: 'font-semibold',
      },
      h2: {
        fontSize: 'text-6xl',
        fontWeight: 'font-semibold',
      },
      h3: {
        fontSize: 'text-5xl',
        fontWeight: 'font-semibold',
      },
      h4: {
        fontSize: 'text-4xl',
        fontWeight: 'font-semibold',
      },
      h5: {
        fontSize: 'text-3xl',
        fontWeight: 'font-semibold',
      },
      h6: {
        fontSize: 'text-2xl',
        fontWeight: 'font-semibold',
      },
      st1: {
        fontSize: 'text-xl',
        fontWeight: 'font-medium',
      },
      st2: {
        fontSize: 'text-lg',
        fontWeight: 'font-medium',
      },
      b1: {
        fontSize: 'text-base',
      },
      b2: {
        fontSize: 'text-sm',
      },
    },
  },
}
