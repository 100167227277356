import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  addressRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  countryAddress: {
    fontSize: 8,
    fontFamily: 'Times-Roman',
    color: '#2f345e',
  },

  countryName: {
    fontSize: 8,
    fontFamily: 'Times-Bold',
    color: '#2f345e',
  },

  headerContainer: {
    width: '100%',
    height: 'auto',
  },

  image: {
    width: '50%',
  },

  imageContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  mainRow: {
    paddingVertical: 20,
    display: 'flex',
    flexDirection: 'row',
  },

  servicesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 16,
    width: '50%',
    borderLeft: '1px solid #2f345e',
  },

  servicesTitle: {
    fontSize: 12,
    fontFamily: 'Times-Roman',
    color: '#2f345e',
  },

  topBar: {
    width: '100%',
    height: 16,
    backgroundColor: '#2f345e',
  },
})
