const columns = [
  {
    title: 'Defined Term',
    field: 'defined_term',
  },
  {
    title: 'Definition',
    field: 'definition',
  },
  {
    title: 'Facility Variable',
    field: 'facility_variable',
  },
]

export const FINANCIAL_COVENANT_MOCK = [
  {
    title: 'Minimum Cash Balance',
    columns,
    data: [
      {
        defined_term: 'Minimum Cash Balance Test',
        definition:
          'The Target agrees to, at all times, maintain a minimum cash balance of at least Trigger Table in the Target Account.  If funds are kept in non-required currency, the funds will be divided by the FX Rate.',
      },
      {
        defined_term: 'Target',
        definition: 'means the entity [•]',
        facility_variable: 'Borrower',
      },
      {
        defined_term: 'Target Account',
        definition: 'means the account [•] belonging to the Target. ',
        facility_variable: 'Opco Account',
      },
      {
        defined_term: 'Trigger Table',
        definition: {
          content: 'means',
          table: {
            columns: [
              { title: `Trigger`, field: 'trigger' },
              { title: 'Minimum Cash Balance', field: 'minimum_cash_balance' },
            ],
            data: [
              { trigger: 'Trigger 1', minimum_cash_balance: '250,000 USD' },
              { trigger: 'Trigger 2' },
              { trigger: 'Trigger 3' },
            ],
          },
        },
      },
    ],
  },
  {
    title: 'Liability to Asset Ratio',
    columns,
    data: [],
  },
  {
    title: 'Minimum Cash Runway',
    columns,
    data: [],
  },
  {
    title: 'Debt to Equity Ratio',
    columns,
    data: [],
  },
]
