import axios from 'axios'
import FileSaver from 'file-saver'
import moment from 'moment'
import { toast } from 'react-toastify'

export const downloadS3File = (
  s3DownloadUrl: string,
  fileType: string,
  fileName?: string
) => {
  const urlParams = new URLSearchParams(s3DownloadUrl)
  const expiry = urlParams.get('Expires')
  try {
    if (expiry && parseInt(expiry) < moment().unix()) {
      throw new Error('Request has Expired')
    }
    axios({
      method: 'get',
      url: s3DownloadUrl,
      responseType: 'blob',
    })
      .then(response => {
        const file = new Blob([response.data], { type: fileType })
        FileSaver.saveAs(file, fileName || getFileNameFromUrl(s3DownloadUrl))
      })
      .catch(error => {
        throw new Error(error.response.statusText)
      })
  } catch (error) {
    toast.error((error as Error)?.message ?? 'An error occured')
  }
}

const getFileNameFromUrl = (url: string) => {
  const matches = decodeURIComponent(url).match(/\/([^/]+)\.[^/]+$/)
  return matches ? matches[1] : 'file'
}

export const pdfDownloader = (data: any, fileName: string) => {
  const downloadLink = document.createElement('a')

  downloadLink.href = data
  downloadLink.download = fileName
  downloadLink.click()
}
