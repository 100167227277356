import React from 'react'

import {
  BorrowingBaseCertTemplateProps,
  CategoriesResult,
} from '@interfaces/manage-reporting'

import FsvnBorrowingBaseCertTemplate from './fsvn/fsvn-borrowing-base-template'
import BorrowingBaseCertTemplate from './borrowing-base-template'

export const renderTemplate = (
  category: CategoriesResult,
  templateProps: BorrowingBaseCertTemplateProps
) => {
  switch (category.subcategory) {
    case 'default': {
      return <BorrowingBaseCertTemplate {...templateProps} />
    }

    case 'fsvn-alteriq': {
      return <FsvnBorrowingBaseCertTemplate {...templateProps} />
    }

    default: {
      return <div />
    }
  }
}
