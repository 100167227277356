import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import Chart from '@components/chart'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { BorrowingBaseService } from '@services/api-manage/borrowing-base'

const HistoricalSurplusChart = ({ expanded = true }: { expanded: boolean }) => {
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)

  const { activeDebtDeal = 0, calculationDate } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const filters = {
    facility: activeFacility?.facility ?? '',
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    date: calculationDate,
  }

  const { data, isFetching } = useQuery(
    ['historical surplus', filters],
    () => BorrowingBaseService.getHistoricalSurplus(filters),
    { ...getStaleMins(), enabled: !!calculationDate }
  )

  const graphData = (data ?? []).filter(x => x.action === 'surplus_deficiency')
  const chartData = graphData.map(x => {
    return {
      x: moment(x.calculation_date).valueOf(),
      y: x.value,
    }
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, expanded])

  const loading = isFetching || isProcessing || !calculationDate

  const tableHeight =
    document?.getElementById('summary-table')?.clientHeight ?? 0
  const height = (tableHeight < 500 ? 780 : tableHeight) - 100

  return (
    <>
      <Typography className="text-xl font-medium mb-4 text-center">
        Historic Borrowing Base Surplus/Deficiency
      </Typography>
      <Chart
        loading={loading}
        id="history"
        yLabel="USD"
        ySetting={{
          fontSize: 12,
        }}
        xSetting={{
          fontSize: 12,
          dateAxis: {
            baseInterval: { timeUnit: 'day', count: 1 },
          },
        }}
        data={chartData}
        series={[
          {
            label: 'All',
            type: 'SmoothedXLineSeries',
            field: 'y',
            hasBullet: false,
            tooltipValueFormat: `#.0a USD`,
          },
        ]}
        scroll={{ x: expanded, y: expanded }}
        height={expanded ? height ?? 400 : 250}
        exportable={false}
      />
    </>
  )
}

export default HistoricalSurplusChart
