import React from 'react'

import { Typography } from '@material-tailwind/react'

const FootNote = () => (
  <div className="m-5">
    <Typography className="text-sm font-medium text-left">Notes:</Typography>
    <div className="flex mt-2">
      <Typography className="text-xs font-light text-left">¹</Typography>
      <Typography className="text-xs font-light text-left pl-1">
        Makes sure file provided complies with Cascade&rsquo;s template{'.'}
      </Typography>
    </div>
    <div className="flex mt-2">
      <Typography className="text-xs font-light text-left">²</Typography>
      <Typography className="text-xs font-light text-left pl-1">
        Makes sure all dates are valid, formatted correctly and are not past the
        current date{'.'}
      </Typography>
    </div>
    <div className="flex mt-2">
      <Typography className="text-xs font-light text-left">³</Typography>
      <Typography className="text-xs font-light text-left pl-1">
        Validating incoming statement data against our records from previous
        statements{'.'}
      </Typography>
    </div>
    <div className="flex mt-2">
      <Typography className="text-xs font-light text-left">*</Typography>
      <Typography className="text-xs font-light text-left pl-1">
        Validating Total Assets is equal to Total Liabilities and Equity. —Only
        applicable for Balance Sheet{'.'}
      </Typography>
    </div>
  </div>
)

export default FootNote
