import React, { useState } from 'react'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import Button from '@components/atoms/button'
import FormInput from '@components/form/form-input'
import { getStaleMins } from '@helpers/stale-timer'
import {
  ArrowPathIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Avatar } from '@material-tailwind/react'
import { UserService } from '@services/api-admin/settings-user'
import DocumentService from '@services/api-manage/docs'

import NoteBoxSkeleton from './notebox-skeleton'

interface NoteBoxProps {
  docId: string
  note: {
    note_id: string
    creation_timestamp: string
    notes: string
    user_info: string
    history: any
  }
  notesRefetch: () => void
  currentUser: any
}

const NoteBox = ({ note, currentUser, docId, notesRefetch }: NoteBoxProps) => {
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { data, isFetching } = useQuery(
    ['commentor_data', [note.note_id, note.user_info]],
    () => UserService.getUserById({ user_id: note.user_info }),
    { ...getStaleMins() }
  )

  const { mutate: deleteNote, isLoading: isDeleting } = useMutation(
    (id: string) => {
      return DocumentService.deleteNote(id)
    },
    {
      onSuccess: () => {
        setIsDelete(!isDelete)
        notesRefetch()
      },
      onError: () => {
        toast.error('Could not delete note', { autoClose: 5000 })
      },
    }
  )
  const { mutate: updateNote, isLoading: isUpdating } = useMutation(
    (data: any) => {
      const payload = {
        doc_id: docId,
        note_id: note.note_id,
        note: data.notes,
        user_id: note.user_info,
      }
      return DocumentService.updateNote(payload)
    },
    {
      onSuccess: () => {
        setIsEdit(!isEdit)
        notesRefetch()
        reset({ notes: '' })
      },
      onError: () => {
        toast.error('Could not post note', { autoClose: 5000 })
      },
    }
  )

  const onSubmit = (dt: any) => {
    updateNote(dt)
  }

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = useForm({
    shouldUnregister: false,
  })

  const isAuthorCurrentUser = note.user_info === currentUser.user_id

  const renderDeleteConfirmation = () => {
    return (
      <div className="flex flex-row justify-between w-full items-center rounded-lg border border-1 px-2 py-1 mt-3">
        <div className="text-sm font-medium ml-16">
          Are you sure you want to delete this note?
        </div>
        <div className="flex flex-row mr-10">
          <Button
            className="mx-5"
            onClick={() => {
              setIsDelete(false)
            }}
          >
            Cancel
          </Button>
          <Button color="secondary" onClick={() => deleteNote(note.note_id)}>
            {isDeleting ? (
              <ArrowPathIcon
                className={`${isFetching ? 'animate-spin' : ''} w-4 h-4 `}
              />
            ) : (
              `Delete`
            )}
          </Button>
        </div>
      </div>
    )
  }

  const renderNoteContent = () => {
    return (
      <div className="flex flex-row w-full items-center">
        <Avatar
          src={data?.picture}
          alt="avatar"
          variant="circular"
          size="sm"
          className="w-12 h-12 mx-2 mt-3"
        />
        <div className="flex justify-between w-full items-center">
          <div className="flex flex-col mt-3 w-full">
            <div className="flex flex-row">
              <div className="ml-2 font-semibold text-sm">{data?.name}</div>
              <div className="ml-2 mt-1 text-xs font-light">
                {moment(note.creation_timestamp).format('LLL')}
              </div>
              {note.history != '' && (
                <div className="ml-2 mt-1 text-xs font-extralight cursor-pointer">
                  Edited
                </div>
              )}
            </div>
            <div className="flex flex-col mx-2 text-sm">
              {isEdit ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-row w-full justify-between">
                    <div className="w-full">
                      <FormInput
                        type="textarea"
                        value={note.notes ?? getValues('notes')}
                        {...register('notes', {
                          required: `Your input is required`,
                          disabled: isSubmitting,
                          onChange: (e: any) => {
                            setValue('notes', e.target.value, {
                              shouldValidate: true,
                            })
                          },
                        })}
                        error={errors?.notes?.message as string}
                      />
                    </div>
                    <div className="flex flex-row mb-2">
                      <Button
                        type="submit"
                        className="mx-2"
                        disabled={isUpdating}
                        onClick={() => {
                          setIsEdit(!isEdit)
                          notesRefetch()
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        className="mx-2"
                        disabled={!isValid || isUpdating}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </form>
              ) : (
                note.notes
              )}
            </div>
          </div>
          {isAuthorCurrentUser && !isEdit && (
            <div className="flex flex-row">
              <PencilIcon
                className="w-4 opacity-50 hover:opacity-100 cursor-pointer hover:text-primary-main mr-5"
                onClick={() => setIsEdit(!isEdit)}
              />
              <TrashIcon
                className="w-4 opacity-50 hover:opacity-100 cursor-pointer hover:text-danger-main mr-5"
                onClick={() => setIsDelete(!isDelete)}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
  return (
    <>
      {isFetching ? (
        <NoteBoxSkeleton />
      ) : isDelete ? (
        renderDeleteConfirmation()
      ) : (
        !!data && note && renderNoteContent()
      )}
    </>
  )
}

export default NoteBox
