import React from 'react'

import { Footer } from '@pages/manage/monitor/actions/document-centre/templates/components'
import { Document, Page, Text } from '@react-pdf/renderer'

import { styles } from './style'

const CashAuditReportEndTemplate = () => {
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.sectionCover}>~ END OF REPORT ~</Text>

        <Footer />
      </Page>
    </Document>
  )
}

export default CashAuditReportEndTemplate
