import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import RollRatesL5 from './roll-rates'

const RollRatesL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const desc = `How loans roll from one delinquency bucket to another after a set period of time, using MOB for time snapshots and for the aggregated portfolio.`
  const tabs = [
    {
      label: '# of Loans',
      desc,
      component: <RollRatesL5 exportable={exportable} />,
    },
  ]

  return (
    <L4TabsLayout
      tabs={tabs}
      headerClassName="lg:!mr-0"
      additionalIndicator={
        showHistoricalRateIndicator ? <ExchangeRateBadge /> : undefined
      }
    />
  )
}

export default RollRatesL4
