import React from 'react'

import {
  CategoriesResult,
  FinancialCovenantTemplateProps,
} from '@interfaces/manage-reporting'

import FsvnFinancialCovenantTemplate from './fsvn/fsvn-financial-covenant-template'
import FinancialCovenantTemplate from './financial-covenant-template'

export const renderTemplate = (
  category: CategoriesResult,
  templateProps: FinancialCovenantTemplateProps
) => {
  switch (category.subcategory) {
    case 'default': {
      return <FinancialCovenantTemplate {...templateProps} />
    }

    case 'fsvn-alteriq': {
      return <FsvnFinancialCovenantTemplate {...templateProps} />
    }

    default: {
      return <div />
    }
  }
}
