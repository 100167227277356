import {
  AccountingRequest,
  CancelValidationParams,
  DeleteEntityRecord,
  DeleteFileParams,
  DownloadFileParams,
  GetQuarantineDataParams,
  SaveRecordParams,
  UpdateCompanyInfo,
  UpdateQuarantineStatusParams,
  UpdateStatementsRecordsParams,
  UploadFileParams,
} from '@interfaces/accounting'

import adminApiClient from './client'

class AccountingService {
  static async retrieveAccountingInfo(slug_name?: string) {
    const { data } = await adminApiClient.get(`accounting/${slug_name}/info`)
    return data
  }

  static async addNewCompany(companyInfo: AccountingRequest) {
    const formData = new FormData()
    formData.append('id', companyInfo.entityId)
    formData.append('entityName', companyInfo.entityName)
    formData.append('entitySlugName', companyInfo.entitySlugName)
    formData.append('source', companyInfo.source)
    formData.append('status', companyInfo.status)
    formData.append('createdAt', companyInfo.createdAt)
    formData.append('currency', companyInfo.currency)
    formData.append('geography', companyInfo.geography)
    formData.append('consolidated', `${companyInfo.consolidated}`)
    formData.append('slug_name', `${companyInfo.slug_name}`)
    companyInfo.files?.forEach((file: Blob) => {
      formData.append('files', file)
    })
    return await adminApiClient.post(`accounting/entity`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    })
  }

  static async editCompany(companyInfo: UpdateCompanyInfo) {
    const formData = new FormData()
    formData.append('id', companyInfo.entityId)
    formData.append('entityName', `${companyInfo.entityName}`)
    formData.append('entitySlugName', `${companyInfo?.entitySlugName}`)
    formData.append('source', 'manual') // this is hardcoded for now
    formData.append('status', `${companyInfo?.status}`)
    formData.append('updatedAt', `${companyInfo?.updatedAt}`)
    formData.append('geography', `${companyInfo?.geography} `)
    formData.append('uploadedDate', `${companyInfo?.uploadedDate}`)
    formData.append('currency', `${companyInfo?.currency}`)
    formData.append('consolidated', `${companyInfo?.consolidated}`)
    formData.append('bucketName', `${companyInfo?.bucketName}`)
    formData.append('slug_name', `${companyInfo?.slug_name}`)
    companyInfo.files?.forEach((file: Blob) => {
      formData.append('files', file)
    })
    const { data } = await adminApiClient.put(`accounting/entity`, formData)
    return data
  }

  static async uploadOnlyStatement(params: UploadFileParams) {
    const formData = new FormData()
    formData.append('id', `${params.id}`)
    formData.append('uploadedDate', params.uploadedDate)
    formData.append('entityName', params.entityName)
    formData.append('entitySlugName', `${params.entitySlugName}`)
    formData.append('bucketName', `${params.bucketName}`)
    formData.append('consolidated', `${params.consolidated}`)
    formData.append('slug_name', `${params.slug_name}`)
    params?.files?.forEach((file: Blob) => {
      formData.append('files', file)
    })
    const { data } = await adminApiClient.post(`accounting/upload`, formData)
    return data
  }

  static async deleteFile(params: DeleteFileParams) {
    const { data } = await adminApiClient.delete(`accounting/statement`, {
      data: params,
    })
    return data.message
  }

  static async downloadFile(params: DownloadFileParams) {
    const { data } = await adminApiClient.post(`accounting/download`, params)
    return data
  }
  static async deleteEntityRecords(params: DeleteEntityRecord) {
    const { data } = await adminApiClient.delete(`accounting/entity`, {
      data: params,
    })
    return data.message
  }
  static async validate(params: any) {
    const { data } = await adminApiClient.post(`accounting/validate`, params)
    return data[0]
  }

  static async cancelValidation(params: CancelValidationParams) {
    const { data } = await adminApiClient.delete(`accounting/validation`, {
      data: params,
    })
    return data?.message
  }
  static async getCurrentQuarantineData(params: GetQuarantineDataParams) {
    const { data } = await adminApiClient.post(`accounting/quarantine`, params)
    return data
  }
  static async updateStatementRecord(params: UpdateStatementsRecordsParams) {
    const { data } = await adminApiClient.put('accounting/statement', params)
    return data?.message
  }

  static async updateQuarantineStatus(params: UpdateQuarantineStatusParams) {
    const { data } = await adminApiClient.put(`accounting/status`, params)
    return data?.status
  }
  static async saveStatementRecords(params: SaveRecordParams) {
    const { data } = await adminApiClient.post(`accounting/statements`, params)
    return data?.message
  }
  static async uploadSourceFile(params: UploadFileParams) {
    const formData = new FormData()
    formData.append('id', `${params.id}`)
    formData.append('uploadedDate', params.uploadedDate)
    formData.append('entityName', params.entityName)
    formData.append('entitySlugName', `${params.entitySlugName}`)
    formData.append('bucketName', `${params.bucketName}`)
    formData.append('consolidated', `${params.consolidated}`)
    formData.append('slug_name', `${params.slug_name}`)
    params?.files?.forEach((file: Blob) => {
      formData.append('files', file)
    })
    const { data } = await adminApiClient.post(
      `accounting/source-files`,
      formData
    )
    return data?.message
  }
  static async emailStatementRecords(params: {
    slug_name?: string
    files?: Blob[]
    entityName?: string
  }) {
    const formData = new FormData()
    formData.append('company_slug_name', `${params.slug_name}`)
    formData.append('entityName', `${params.entityName}`)
    params?.files?.forEach((file: Blob) => {
      formData.append('files', file)
    })
    const { data } = await adminApiClient.post(
      `accounting/email-statement`,
      formData
    )
    return data
  }
}

export default AccountingService
