import React, { useContext } from 'react'

import FilterCurrency from '@components/filters/filter-currency'
import FilterDebtDeal from '@components/filters/filter-debt-deal'
import BaseLayout from '@components/layouts/base-layout'
import AuthContext from '@contexts/auth'

import MasterFacilityOverview from './master-facility-overview'
import Overview from './overview'

const ManageOverviewL3 = () => {
  const { activeFilters, appliedFilters, optionFilters } =
    useContext(AuthContext)

  const { activeDebtDeal } = activeFilters
  const { activeDebtDeal: appliedActiveDebt = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[appliedActiveDebt]
  const isMasterFacility = activeFacility?.is_master_facility

  /**
   * observe reset button disable state criteria
   */
  const disableReset = activeDebtDeal === 0

  return (
    <BaseLayout
      title="Monitor Overview"
      filters={{
        left: <FilterCurrency hideSelect />,
        middle: <FilterDebtDeal />,
      }}
      disableReset={disableReset}
    >
      {isMasterFacility ? <MasterFacilityOverview /> : <Overview />}
    </BaseLayout>
  )
}

export default ManageOverviewL3
