import React from 'react'
import moment from 'moment'

import Typography from '@components/atoms/typography'
import Chart from '@components/chart'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'

import MonitorDashboardTableItem from './table'

const MonitorDashboardLineGraphItem = ({
  item,
}: {
  item: DashboardResponse
}) => {
  const chart_data: any[] = (item.data ?? []).map(i => ({
    ...i,
    x:
      item.x_axis_type === 'CategoryAxis'
        ? i[item.x_axis_field]
        : moment.utc(i[item.x_axis_field]).valueOf(),
  }))
  const unit = item.data?.[0]?.unit
  const chart_series: any[] = [
    {
      label: item.title ?? '',
      tooltipValueFormat: `${item.y_axis_format ?? '#.00a'} ${unit ?? ''}`,
      type: 'SmoothedXLineSeries',
      field: item.y_axis_field,
    },
  ]
  const xSetting = item.x_axis_setting
    ? JSON.parse(item.x_axis_setting)
    : undefined

  return (
    <>
      {item.title && (
        <Typography className="text-center text-lg font-semibold mt-8">
          {item.title}
        </Typography>
      )}
      <Chart
        data={chart_data}
        series={chart_series}
        loading={false}
        yLabel={`${item.y_axis_label} ${unit ? `(${unit})` : ''}`}
        yFormat={item.y_axis_format ?? '#.00a'}
        xSetting={xSetting}
        id={item.title ?? ''}
        exportable={false}
      />
      <MonitorDashboardTableItem item={item} />
    </>
  )
}
export default MonitorDashboardLineGraphItem
