import React, { useContext, useEffect } from 'react'

import FormInput from '@components/form/form-input'
import AuthContext from '@contexts/auth'
import { LayoutFilter } from '@interfaces/filter'
import { Option, Select, Typography } from '@material-tailwind/react'
import DocumentService from '@services/api-manage/docs'

const FilterDocCentre = () => {
  const {
    company,
    activeFilters,
    setActiveFilters,
    optionFilters,
    setOptionFilters,
  } = useContext(AuthContext)

  const { activeDebtDeal, docCategory, docStatus, docKeyword } = activeFilters
  const {
    debtDealOptions = [],
    docCategories = [],
    docStatuses = [],
  } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]
  const statuses = [
    {
      status: [
        'signature_request_downloadable',
        'signature_request_all_signed',
        'completed',
        'upcoming',
        'signature_request_sent',
        'awaiting_signature',
        'signature_request_viewed',
        'created',
        'overdue',
        'due',
      ],
      display: 'All',
    },
    { status: 'overdue', display: 'Overdue' },
    { status: 'due', display: 'Due' },

    {
      status: [
        'signature_request_sent',
        'awaiting_signature',
        'signature_request_viewed',
        'created',
      ],
      display: 'Awaiting Signature',
    },
    { status: 'upcoming', display: 'Upcoming' },
    {
      status: [
        'signature_request_downloadable',
        'signature_request_all_signed',
        'completed',
      ],
      display: 'Completed',
    },
  ]

  useEffect(() => {
    if (activeFacility) {
      DocumentService.listCategories({
        slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
        facility: activeFacility?.facility ?? '',
        action: 'upload',
      })
        .then(response => {
          const allValues: any = { category: 'All', subcategory: [] }
          response.forEach(i => allValues.subcategory.push(i.category))
          response.unshift(allValues)
          setActiveFilters((prev: LayoutFilter) => ({
            ...prev,
            docKeyword: '',
            docCategory: response.filter(
              (cat: any) => cat.category == 'All'
            )[0],
            docStatus: statuses.filter(
              (status: any) => status.display == 'All'
            )[0],
          }))
          setOptionFilters((prev: LayoutFilter) => ({
            ...prev,
            docKeyword: '',
            docCategories: response,
            docStatuses: statuses,
          }))
        })
        .catch(() => {
          console.error('error')
        })
    }
  }, [company, activeFacility])

  const getStatusDisplayName = (val: any) => {
    const status = statuses.find((s: any) => s.display === val?.display)
    return status ? status.display : 'All'
  }

  const getCategoryDisplayName = (val: any) => {
    const category = docCategories.find(
      (s: any) => s.category === val?.category
    )
    return category ? category.category : 'All'
  }

  return (
    <div className="grid grid-cols-4 gap-4">
      <Typography
        variant="b2"
        className="font-semibold text-neutral-body-2 flex-1 pt-1"
      >
        Document
      </Typography>
      <div className="w-full col-span-3 pr-2">
        <FormInput
          value={docKeyword}
          {...{
            placeholder: 'Enter Document Name..',
            onChange: (e: any) => {
              setActiveFilters({
                ...activeFilters,
                docKeyword: e.target.value,
              })
            },
          }}
        />
      </div>
      {!!docCategories && docCategories.length > 0 && (
        <>
          <Typography
            variant="b2"
            className="font-semibold text-neutral-body-2 flex-1 pt-1"
          >
            Category
          </Typography>
          <div className="w-full col-span-3 pr-2">
            <Select
              className="w-full [&~ul]:max-h-[100px] border border-neutral-border-1"
              onChange={(val: any) => {
                setActiveFilters({
                  ...activeFilters,
                  docCategory: val,
                })
              }}
              selected={() => (
                <span>{getCategoryDisplayName(docCategory)}</span>
              )}
            >
              {docCategories.map((e: any, i: number) => (
                <Option key={i} value={e}>
                  {e.category}
                </Option>
              ))}
            </Select>
          </div>
        </>
      )}
      <Typography
        variant="b2"
        className="font-semibold text-neutral-body-2 flex-1 pt-1"
      >
        Status
      </Typography>
      <div className="w-full col-span-3 pr-2">
        <Select
          className="w-full [&~ul]:max-h-[100px] border border-neutral-border-1"
          onChange={val => {
            setActiveFilters({
              ...activeFilters,
              docStatus: val,
            })
          }}
          selected={() => <span>{getStatusDisplayName(docStatus)}</span>}
        >
          {docStatuses.map((e: any, i: number) => (
            <Option key={i} value={e}>
              {e.display}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  )
}
export default FilterDocCentre
