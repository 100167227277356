import React from 'react'

import Typography from '@components/atoms/typography'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import { Option, Select } from '@material-tailwind/react'

import { DOTS, usePagination } from './hook'

export interface PaginationProps {
  id: string
  total: number
  page: number
  onChangePage: (page: number) => void
  limit: number
  limits?: number[]
  onChangeLimit?: (limit: number) => void
}
const Pagination = ({
  id,
  total,
  page,
  onChangePage,
  limit,
  limits,
  onChangeLimit,
}: PaginationProps) => {
  const paginationRange = usePagination({
    id,
    page,
    total,
    limit,
  })

  if (!limits && (paginationRange?.length ?? 0) < 2) {
    return null
  }

  const lastPage = paginationRange?.[paginationRange.length - 1] ?? 1
  const maxPage = Number(lastPage)
  const disablePrev = page === 0
  const disableNext = page === maxPage - 1

  const buttons: {
    icon?: any
    title?: string
    action?: () => void
    disabled?: boolean
  }[] = [
    {
      icon: ChevronDoubleLeftIcon,
      action: () => onChangePage(0),
      disabled: disablePrev,
    },
    {
      icon: ChevronLeftIcon,
      action: () => onChangePage(page - 1),
      disabled: disablePrev,
    },
    ...(paginationRange ?? []).map(p => ({
      title: String(p),
      action: p !== DOTS ? () => onChangePage(Number(p) - 1) : undefined,
      disabled: p === DOTS,
    })),
    {
      icon: ChevronRightIcon,
      action: () => onChangePage(page + 1),
      disabled: disableNext,
    },
    {
      icon: ChevronDoubleRightIcon,
      action: () => onChangePage(maxPage - 1),
      disabled: disableNext,
    },
  ]

  return (
    <div className={`mt-6 flex ${limits ? 'justify-between' : 'justify-end'}`}>
      {limits && (
        <div className="flex items-center gap-4">
          <Select
            className="[&~ul]:max-h-[100px] max-w-[100px]"
            onChange={val => onChangeLimit?.(Number(val))}
            selected={() => {
              return <span>{limit}</span>
            }}
          >
            {limits.map((l: number) => (
              <Option key={l} value={l.toString()}>
                {l}
              </Option>
            ))}
          </Select>
          <Typography className="text-sm min-w-[100px]">
            rows per page
          </Typography>
        </div>
      )}
      <div className="border border-neutral-border-1 rounded flex items-center overflow-hidden p-0">
        {maxPage > 0 &&
          buttons.map((b, i) => (
            <button
              key={i}
              disabled={b.disabled}
              onClick={b.action}
              className={`px-3 py-2 text-sm disabled:bg-neutral-surface-1 cursor-pointer disabled:cursor-not-allowed hover:bg-primary-surface-2 ${
                Number(b.title) === page + 1
                  ? 'text-primary-main bg-primary-surface-2'
                  : ''
              }`}
            >
              {b.icon ? <b.icon className="h-4 w-4 m-0.5" /> : b.title}
            </button>
          ))}
      </div>
    </div>
  )
}

export default Pagination
