import {
  ARRDataResponse,
  CollectionByStatusRiskDataResponse,
  OverviewDataResponse,
  RiskDataResponse,
  RisksFilters,
  TractionDataResponse,
} from '@interfaces/risk'

import apiClient from './client'

export class CollectionService {
  static async getCollectionRate(params: RisksFilters) {
    if (params.filters.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<TractionDataResponse>
      >('/collection/collection-rate', params)

      return data
    }
  }
  static async getPrepaymentRate(params: RisksFilters) {
    if (params.filters.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<TractionDataResponse>
      >('/collection/prepayment', params)

      return data
    }
  }

  static async getNetYield(params: RisksFilters) {
    if (params.filters.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<TractionDataResponse>
      >('/collection/net-yield', params)

      return data
    }
  }

  static async getCashCollected(params: RisksFilters) {
    if (params.filters.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<TractionDataResponse>
      >('/collection/cash-collected-cohort', params)

      return data
    }
  }

  static async getCollectionByStatus(params: RisksFilters) {
    if (params.filters.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<CollectionByStatusRiskDataResponse>
      >('/collection/collection-by-status', params)

      return data
    }
  }

  static async getAggregatedRollRates(params: RisksFilters) {
    if (params.filters.length) {
      const { data } = await apiClient.post<RiskDataResponse<ARRDataResponse>>(
        '/collection/aggregate-roll-rate',
        params
      )

      return data
    }
  }

  static async getOverview(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<OverviewDataResponse>
      >('/collection/overview', params)

      return data
    }
  }
}
