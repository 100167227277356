import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQueries } from 'react-query'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import FilterCurrency from '@components/filters/filter-currency'
import FilterDateRange from '@components/filters/filter-date-range'
import FilterTypeKey from '@components/filters/filter-type-key'
import BaseLayout from '@components/layouts/base-layout'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import {
  ArrowPathIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import { Filter, FilterItem } from '@interfaces/filter'
import { RisksFilters } from '@interfaces/risk'
import { Card, CardBody } from '@material-tailwind/react'
import IndicatorService from '@services/api-analytics/risk-indicator'

const RiskIndicatorsL3 = () => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const submodules = [
    'cumulative-traction',
    'current-traction',
    'portfolio-characteristics',
    'portfolio-performance',
  ]
  const {
    appliedFilters,
    activeFilters,
    optionFilters,
    setAppliedFilters,
    showFilter,
    company,
  } = useContext(AuthContext)

  const {
    dateStart,
    dateEnd,
    categoryTypes = [],
    activeType,
    range,
    currency,
    rate: rateActive,
  } = activeFilters

  const {
    dateStartCohort: appliedDateStart,
    dateEndCohort: appliedDateEnd,
    currency: appliedCurrency,
    categoryTypes: appliedCategoryTypes = [],
    activeType: appliedActiveType,
    rate = 1,
  } = appliedFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (
      !filterReady &&
      dateStart &&
      dateEnd &&
      !!categoryTypes &&
      categoryTypes.length > 0 &&
      rateActive
    ) {
      // need to add delay since its rendered in same component
      setTimeout(() => {
        // carries filters over unless no previously applied filters
        JSON.stringify(appliedFilters) !== JSON.stringify({ activeFilters }) &&
          setAppliedFilters(activeFilters)
        setFilterReady(true)
      }, 100)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    range === 0 &&
    activeType === 'All' &&
    currency === company?.currencies_available?.[0]?.to_currency

  /**
   * assign data
   */
  const activeCategoryType = optionFilters.categoryTypeOptions?.find(
    (o: Filter) => o.type_key === appliedActiveType
  )
  const isAll = appliedActiveType === 'All'
  const numberOfCategory = isAll ? 1 : appliedCategoryTypes?.length ?? 0
  const allFilters = optionFilters.categoryTypeOptions
    ?.find((o: Filter) => o.type_key === 'All')
    ?.type_val?.map((x: FilterItem) => x.id)

  const fetchers: {
    [key: string]: any
  } = {}

  const filters: RisksFilters = {
    date_from: moment.utc(appliedDateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(appliedDateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name,
    allFilters,
    filters: [],
  }

  submodules.forEach(sm => {
    Array(numberOfCategory)
      .fill('')
      .forEach((_, i) => {
        const key = isAll ? 'All' : appliedCategoryTypes[i]
        fetchers[`${sm}_${key}`] = {
          queryKey: [sm, key, filters],
          queryFn: () =>
            IndicatorService.getIndicator(
              {
                ...filters,
                filters: isAll
                  ? appliedCategoryTypes
                  : [appliedCategoryTypes[i]],
              },
              sm
            ),
        }
      })
  })

  const indicatorQueries = useQueries(Object.values(fetchers))
  const isLoading = indicatorQueries.find(x => x.isLoading)

  const data: {
    title: string
    key: string
    currency?: string
    children?: any[]
  }[] = [
    {
      title: 'Cumulative Traction',
      key: submodules[0],
      children: [
        {
          title: 'Total Unique Customers',
          key: 'total_unique_customers',
        },
        {
          title: 'Total Loans',
          key: 'total_loans',
        },
      ],
    },
    {
      title: 'Current Traction',
      key: submodules[1],
      children: [
        {
          title: 'Avg Monthly Volume (Last 3m)',
          currency: appliedCurrency,
          key: '_3mAvgVol',
        },
        {
          title: 'Avg Monthly Volume (Last 12m)',
          currency: appliedCurrency,
          key: '_12mAvgVol',
        },
        // {
        //   title: 'Total Borrowers',
        //   key: 'total_borrowers',
        // },
        {
          title: 'Outstanding Portfolio',
          currency: appliedCurrency,
          key: 'outstanding_portfolio',
        },
        {
          title: 'Active Loans',
          key: 'active_loans',
        },
        {
          title: 'Active Borrowers',
          key: 'active_borrowers',
        },
      ],
    },
    {
      title: 'Portfolio Characteristics',
      key: submodules[2],
      children: [
        {
          title: 'Avg APR',
          key: 'avg_apr',
          percentage: true,
          decimal: 3,
        },
        {
          title: 'Avg Effective Interest rate',
          key: 'avg_eir',
          percentage: true,
        },
        {
          title: 'Avg Term',
          key: 'avg_term_in_months',
          render: (v: number) =>
            v > 30 ? `${(v / 30).toFixed(2)} months` : `${v.toFixed(2)} days`,
        },
        {
          title: 'Avg Loan Size',
          key: 'avg_loan_size',
          currency: appliedCurrency,
        },
        {
          title: 'Avg APR (Last 12m)',
          key: 'avg_apr_12m',
          percentage: true,
          decimal: 3,
        },
        {
          title: 'Avg Effect Interest Rate (Last 12m)',
          key: 'avg_eir_12m',
          percentage: true,
        },
        {
          title: 'Avg term (Last 12m)',
          key: 'avg_term_in_months_12m',
          render: (v: number) =>
            v > 30 ? `${(v / 30).toFixed(2)} months` : `${v.toFixed(2)} days`,
        },
        {
          title: 'Avg Loan Size (Last 12m)',
          key: 'avg_loan_size_12m',
          currency: appliedCurrency,
        },
      ],
    },
    {
      title: 'Portfolio Performance',
      key: submodules[3],
      children: [
        {
          title: 'PAR 30',
          key: 'par30',
          percentage: true,
        },
        {
          title: 'PAR 90',
          key: 'par90',
          percentage: true,
        },
        {
          title: 'Bad 90 @6months',
          key: 'badPar90_6m',
          percentage: true,
        },
        {
          title: 'Bad 90 @12months',
          key: 'badPar90_12m',
          percentage: true,
        },
      ],
    },
  ]

  const [activeRows, setActiveRows] = useState<number[]>(data.map((_, i) => i))
  const [hoverCell, setHoverCell] = useState<string | undefined>(undefined)

  return (
    <BaseLayout
      title="Key Indicators"
      filters={{
        left: <FilterCurrency />,
        middle: <FilterDateRange isCohort />,
        right: <FilterTypeKey />,
      }}
      disableReset={disableReset}
    >
      {isLoading ? (
        <div className="w-full h-[300px] flex justify-center items-center">
          <ArrowPathIcon className="animate-spin text-primary-main w-8" />
        </div>
      ) : (
        <Card>
          <CardBody>
            {showHistoricalRateIndicator && (
              <div className="flex mb-4">
                <ExchangeRateBadge isHistorical={false} />
              </div>
            )}
            <div
              className={`${
                showFilter
                  ? 'max-h-[calc(100vh-479px)]'
                  : 'max-h-[calc(100vh-156px)]'
              } overflow-auto rounded-lg border border-neutral-border-1`}
            >
              <table className="border-separate border-spacing-0">
                <thead className="sticky top-0 z-10">
                  <tr>
                    <th
                      className={`lg:min-w-[250px] sticky left-0 p-3 border-b border-r border-neutral-border-1 p-3  bg-neutral-surface-1 text-sm font-semibold`}
                    ></th>
                    {isAll ? (
                      <>
                        <th
                          className={`min-w-[200px] p-3 border-b border-r border-neutral-border-1 text-sm font-semibol ${
                            hoverCell?.endsWith('-val')
                              ? 'bg-primary-surface-2'
                              : 'bg-neutral-white'
                          }`}
                        >
                          All
                        </th>
                        <th
                          className={`min-w-[200px] p-3 border-b border-r border-neutral-border-1 text-sm font-semibol ${
                            hoverCell?.endsWith('-percentage')
                              ? 'bg-primary-surface-2'
                              : 'bg-neutral-white'
                          }`}
                        >
                          Percent of Total Portfolio
                        </th>
                      </>
                    ) : (
                      Array(numberOfCategory)
                        .fill('')
                        .map((_, i) => {
                          const activeTypeTitle =
                            activeCategoryType?.type_val?.find(
                              (t: FilterItem) =>
                                t.id === appliedCategoryTypes[i]
                            )
                          return (
                            <React.Fragment key={i}>
                              <th
                                className={`min-w-[150px] p-3 border-b border-r border-neutral-border-1 text-sm font-semibol ${
                                  hoverCell?.endsWith(`-${i}-val`)
                                    ? 'bg-primary-surface-2'
                                    : i % 2 === 0
                                    ? 'bg-neutral-surface-2'
                                    : 'bg-neutral-surface-1'
                                }`}
                              >
                                {activeTypeTitle?.type}
                              </th>
                              <th
                                className={`min-w-[150px] p-3 border-b border-r border-neutral-border-1 text-sm font-semibol ${
                                  hoverCell?.endsWith(`-${i}-percentage`)
                                    ? 'bg-primary-surface-2'
                                    : i % 2 === 0
                                    ? 'bg-neutral-surface-2'
                                    : 'bg-neutral-surface-1'
                                }`}
                              >
                                Percentage of Portfolio
                              </th>
                            </React.Fragment>
                          )
                        })
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data.map((d, i) => {
                    const isOpen = activeRows.includes(i)
                    const Icon = isOpen ? ChevronDownIcon : ChevronRightIcon
                    return (
                      <React.Fragment key={`row-${i}`}>
                        <tr
                          onClick={() => {
                            const nextActiveRow = isOpen
                              ? activeRows.filter(x => x !== i)
                              : [...activeRows, i]
                            setActiveRows(nextActiveRow)
                          }}
                          className="cursor-pointer"
                        >
                          <td className="sticky left-0 text-sm p-3 bg-neutral-white">
                            <div className="flex">
                              <Icon className="w-4 h-4" />
                              <span className="ml-4">{d.title}</span>
                            </div>
                          </td>
                          <td
                            className="bg-neutral-white border-r border-neutral-border-1"
                            colSpan={numberOfCategory * 2}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            colSpan={numberOfCategory * 2 + 1}
                            className="p-0"
                          >
                            <hr />
                          </td>
                        </tr>
                        {d.children?.map((c, j) => {
                          return (
                            <tr
                              key={`row-${i}-${j}`}
                              className={`[&>td]:transition-all ${
                                isOpen
                                  ? '[&>td]:leading-6'
                                  : '[&>td]:leading-[0px] [&>td]:overflow-hidden [&>td]:whitespace-nowrap [&>td]:p-0 [&>td]:border-none'
                              }`}
                            >
                              <td
                                className={`sticky left-0 text-sm p-3 pl-12 border-b border-r border-neutral-border-1 ${
                                  hoverCell?.startsWith(`${i}-${j}-`)
                                    ? 'bg-primary-surface-2'
                                    : 'bg-neutral-surface-1'
                                }`}
                              >
                                {c.title}
                              </td>
                              {Array(numberOfCategory)
                                .fill('')
                                .map((_, k) => {
                                  const fetcherIndex = Object.keys(
                                    fetchers
                                  ).indexOf(
                                    `${d.key}_${
                                      isAll ? 'All' : appliedCategoryTypes[k]
                                    }`
                                  )
                                  const dt = (
                                    indicatorQueries[fetcherIndex]?.data as {
                                      data: any[]
                                    }
                                  )?.data as any[]
                                  const metric =
                                    dt?.find(x => x.metric === c.key) ?? {}

                                  const neutralBackgroundClass = isAll
                                    ? 'bg-neutral-white'
                                    : k % 2 === 0
                                    ? 'bg-neutral-surface-2'
                                    : 'bg-neutral-surface-1'
                                  const hoveredCellIndex = hoverCell?.split('-')
                                  /**
                                   * hover when:
                                   * i === group && row === hoverRow && k < hoverColumn
                                   * i === group && row < hoverRow && k === hoverColumn
                                   * i < group && k === hoverColumn
                                   * active when:
                                   * i === group && row === hoverRow && k === hoverColumn
                                   */
                                  const isSiblingHovered =
                                    hoveredCellIndex &&
                                    ((i === parseFloat(hoveredCellIndex?.[0]) &&
                                      j === parseFloat(hoveredCellIndex?.[1]) &&
                                      k < parseFloat(hoveredCellIndex?.[2])) ||
                                      (i ===
                                        parseFloat(hoveredCellIndex?.[0]) &&
                                        j < parseFloat(hoveredCellIndex?.[1]) &&
                                        k ===
                                          parseFloat(hoveredCellIndex?.[2])) ||
                                      (i < parseFloat(hoveredCellIndex?.[0]) &&
                                        k ===
                                          parseFloat(hoveredCellIndex?.[2])))
                                  const isHovered =
                                    hoveredCellIndex &&
                                    i === parseFloat(hoveredCellIndex?.[0]) &&
                                    j === parseFloat(hoveredCellIndex?.[1]) &&
                                    k === parseFloat(hoveredCellIndex?.[2])

                                  const isSiblingPercentageHovered =
                                    isSiblingHovered &&
                                    (hoverCell?.includes('-percentage') ||
                                      (i ===
                                        parseFloat(hoveredCellIndex?.[0]) &&
                                        j ===
                                          parseFloat(hoveredCellIndex?.[1])))
                                  const isPercentageHovered =
                                    isHovered &&
                                    hoverCell?.includes('-percentage')

                                  const isSiblingValHovered =
                                    isSiblingHovered &&
                                    (hoverCell?.includes('-val') ||
                                      (i ===
                                        parseFloat(hoveredCellIndex?.[0]) &&
                                        j ===
                                          parseFloat(hoveredCellIndex?.[1])))
                                  const isValHovered =
                                    isHovered && hoverCell?.includes('-val')

                                  const value = c.currency
                                    ? metric.value?.val / rate
                                    : metric.value?.val

                                  return (
                                    <React.Fragment
                                      key={`column-${i}-${j}-${k}`}
                                    >
                                      <td
                                        className={`p-3 text-sm border-b border-r border-neutral-border-1 text-right ${
                                          isValHovered
                                            ? 'bg-primary-surface-2'
                                            : isSiblingValHovered ||
                                              isPercentageHovered
                                            ? 'bg-primary-surface-1'
                                            : neutralBackgroundClass
                                        }`}
                                        onMouseEnter={() => {
                                          setHoverCell(`${i}-${j}-${k}-val`)
                                        }}
                                        onMouseLeave={() => {
                                          setHoverCell(undefined)
                                        }}
                                      >
                                        {!['undefined', 'object'].includes(
                                          typeof metric.value?.val
                                        )
                                          ? c.render?.(value) ??
                                            `${Intl.NumberFormat(undefined, {
                                              maximumFractionDigits:
                                                c.decimal ??
                                                (c.percentage
                                                  ? 2
                                                  : value >= 1000000
                                                  ? 3
                                                  : 0),
                                              style: 'decimal',
                                              notation: c.currency
                                                ? 'compact'
                                                : 'standard',
                                            }).format(value)} ${
                                              c.currency ??
                                              (c.percentage ? '%' : '')
                                            }`
                                          : '-'}
                                      </td>
                                      <td
                                        className={`p-3 text-sm border-b border-r border-neutral-border-1 text-right ${
                                          isPercentageHovered
                                            ? 'bg-primary-surface-2'
                                            : isSiblingPercentageHovered
                                            ? 'bg-primary-surface-1'
                                            : neutralBackgroundClass
                                        }`}
                                        onMouseEnter={() => {
                                          setHoverCell(
                                            `${i}-${j}-${k}-percentage`
                                          )
                                        }}
                                        onMouseLeave={() => {
                                          setHoverCell(undefined)
                                        }}
                                      >
                                        {!['undefined', 'object'].includes(
                                          typeof metric.value?.percentage
                                        )
                                          ? Intl.NumberFormat(undefined, {
                                              maximumFractionDigits: 2,
                                              style: 'percent',
                                              notation: 'compact',
                                            }).format(
                                              metric.value?.percentage / 100
                                            )
                                          : '-'}
                                      </td>
                                    </React.Fragment>
                                  )
                                })}
                            </tr>
                          )
                        })}
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      )}
    </BaseLayout>
  )
}
export default RiskIndicatorsL3
