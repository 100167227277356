import {
  GenerateAudit,
  getAuditDataParams,
  reviewAuditItemParams,
} from '@interfaces/manage-audit'

import manageApiClient from './client'

export class AuditService {
  static async getAllAudits(params: getAuditDataParams) {
    if (params.facility) {
      const { data } = await manageApiClient.post<any>(
        `/${params.type}-audit/all`,
        params
      )
      return data
    }
  }

  static async getAuditDataById(params: getAuditDataParams) {
    if (params.id != '' && params.facility) {
      const { data } = await manageApiClient.post<any>(
        `/${params.type}-audit/summary-by-id`,
        params
      )
      return data
    }
  }
  static async getAuditItemInfo(params: getAuditDataParams) {
    if (params.facility) {
      const { data } = await manageApiClient.post<any>(
        `/${params.type}-audit/item-by-id`,
        params
      )
      return data
    }
  }

  static async uploadForItem(formData: FormData, type: string) {
    if (formData.has('facility')) {
      const { data } = await manageApiClient.post<any[]>(
        `/${type}-audit/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data;',
          },
        }
      )
      return data
    }
  }
  static async reviewItem(params: reviewAuditItemParams) {
    if (params.facility) {
      const { data } = await manageApiClient.post<any>(
        `/${params.type}-audit/review-item`,
        params
      )
      return data
    }
  }
  static async requestMoreInfo(params: reviewAuditItemParams) {
    if (params.facility) {
      const { data } = await manageApiClient.post<any>(
        `/${params.type}-audit/request-more-info`,
        params
      )
      return data
    }
  }

  static async finaliseAudit(params: getAuditDataParams) {
    if (params.facility) {
      const { data } = await manageApiClient.post<any>(
        `/${params.type}-audit/finalise-audit`,
        params
      )
      return data
    }
  }

  static async getAttachmentByKey(params: any) {
    if (params.slug_name && params.key && params.facility) {
      const { data } = await manageApiClient.post(
        `/payment-audit/attachment`,
        params,
        {
          responseType: 'arraybuffer',
        }
      )
      return data
    }
  }

  static async getReportData(params: getAuditDataParams) {
    if (params.facility) {
      const { data } = await manageApiClient.post<any>(
        `/${params.type}-audit/report-data`,
        params
      )
      return data
    }
  }
  static async generateAudit(params: GenerateAudit) {
    if (params.facility) {
      const { data } = await manageApiClient.post<any>(
        `/${params.audit_type}-audit/generate`,
        params
      )
      return data
    }
  }
}
