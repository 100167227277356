import React from 'react'

import { Button, ButtonProps } from '@material-tailwind/react'

interface Props extends Omit<ButtonProps, 'color'> {
  color?: 'default' | 'primary' | 'secondary'
}

export default function CustomButton(props: Props) {
  return <Button {...(props as any)} />
}
