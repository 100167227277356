import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  highlighted: {
    backgroundColor: '#ffffcc',
    width: '82%',
  },

  pageTitle: {
    width: '100%',
    fontSize: 6,
    fontFamily: 'Helvetica-Bold',
    backgroundColor: '#c00000',
    color: '#ffffff',
    marginBottom: 10,
    padding: 1,
    paddingLeft: 2,
  },

  sectionSubTitle: {
    width: '45%',
    fontSize: 6,
    fontFamily: 'Helvetica-Bold',
    backgroundColor: '#ffc000',
    color: '#000000',
    padding: 1,
    paddingLeft: 2,
  },

  sectionTitle: {
    width: '100%',
    fontSize: 6,
    fontFamily: 'Helvetica-Bold',
    color: '#c00000',
    borderBottom: '1px solid #c00000',
    paddingLeft: 2,
  },

  signatureBox: {
    width: '55%',
    height: 70,
    borderBottom: '1px solid #000000',
  },

  tableCellLeft: {
    width: '55%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  tableCellRight: {
    width: '45%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },

  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  text: {
    fontSize: 6,
    fontFamily: 'Helvetica',
    color: '#000000',
  },

  textBold: {
    fontSize: 6,
    fontFamily: 'Helvetica-Bold',
    color: '#000000',
  },

  textIndent: {
    fontSize: 6,
    fontFamily: 'Helvetica',
    color: '#000000',
    textIndent: 10,
  },

  valueText: {
    width: '50%',
    fontSize: 6,
    fontFamily: 'Helvetica',
    border: '1px solid #000000',
    backgroundColor: '#ffffcc',
    color: '#0000cc',
    padding: 1,
    textAlign: 'center',
  },

  valueTitle: {
    width: '100%',
    fontSize: 6,
    fontFamily: 'Helvetica-Bold',
    borderBottom: '1px solid #333333',
    color: '#000000',
    textAlign: 'center',
  },
})
