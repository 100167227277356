import React from 'react'
import { useForm } from 'react-hook-form'

import Button from '@components/atoms/button'
import { XMarkIcon } from '@heroicons/react/24/outline'
import BankAccount from '@interfaces/bank-account'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Radio,
} from '@material-tailwind/react'

interface EditAccountDialogProps {
  open: boolean
  handleOpen: () => void
  handleSubmitManualInfo: (data: any) => void
  defaultValues: BankAccount
}

const EditAccountDialog = ({
  open,
  handleOpen,
  handleSubmitManualInfo,
  defaultValues,
}: EditAccountDialogProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nickname: defaultValues.account_nickname,
      entityOwnership: defaultValues.entity_ownership,
      use: defaultValues.account_use,
      bankName: defaultValues.bank_id,
      accNo: defaultValues.account_no,
      accName: defaultValues.account_name,
      accType: defaultValues.account_type,
    },
  })

  const onSubmit = (data: any) => {
    handleSubmitManualInfo(data)
  }

  return (
    <Dialog open={open} handler={handleOpen} className="overflow-auto z-40">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>
          <div className="flex justify-between w-full">
            <span>Edit Account Information</span>
            <XMarkIcon
              onClick={handleOpen}
              className="w-8 h-8 cursor-pointer hover:opacity-50"
            />
          </div>
        </DialogHeader>
        <DialogBody divider>
          <div className="flex flex-col gap-6 w-full mt-5">
            <>
              <div>
                <Input
                  {...register('nickname', {
                    pattern: {
                      value: /^[A-Za-z\d\s]+$/i,
                      message:
                        'Invalid characters - Alphanumeric characters only!',
                    },
                    required: {
                      value: true,
                      message: 'This is required',
                    },
                  })}
                  variant="static"
                  label="Nickname"
                  placeholder="Enter a Nickname for the Account"
                  success={true}
                />
                {errors.nickname && (
                  <p className="text-red text-xs">{errors.nickname.message}</p>
                )}
              </div>
              <div>
                <Input
                  {...register('entityOwnership', {
                    pattern: {
                      value: /^[A-Za-z\d\s\.,]+$/i,
                      message:
                        'Invalid characters - Only alphanumeric, commas, or periods!',
                    },
                    required: {
                      value: true,
                      message: 'This is required',
                    },
                  })}
                  variant="static"
                  label="Entity Ownership"
                  placeholder="Enter the entity ownership"
                />
                {errors.entityOwnership && (
                  <p className="text-red text-xs">
                    {errors.entityOwnership.message}
                  </p>
                )}
              </div>
              <div className="grid grid-cols-2 flex">
                <span className="col-span-2 text-sm">Use:</span>

                <Radio
                  {...register('use', {
                    required: {
                      value: true,
                      message: 'This is required',
                    },
                  })}
                  type="radio"
                  value="Operations"
                  label="Operations"
                />
                <Radio
                  {...register('use', {
                    required: {
                      value: true,
                      message: 'This is required',
                    },
                  })}
                  type="radio"
                  value="Collections"
                  label="Collections"
                />
                <Radio
                  {...register('use', {
                    required: {
                      value: true,
                      message: 'This is required',
                    },
                  })}
                  type="radio"
                  value="Reserve"
                  label="Reserve"
                />
                <Radio
                  {...register('use', {
                    required: {
                      value: true,
                      message: 'This is required',
                    },
                  })}
                  type="radio"
                  value="Other"
                  label="Other"
                />
                {errors.use && (
                  <p className="text-red text-xs">{errors.use.message}</p>
                )}
              </div>
              <div>
                <Input
                  {...register('bankName', {
                    pattern: {
                      value: /^[A-Za-z\s]+$/,
                      message: 'Invalid characters',
                    },
                    required: {
                      value: true,
                      message: 'This is required',
                    },
                  })}
                  variant="static"
                  label="Bank Name"
                  placeholder="Enter the name of your bank"
                  success={true}
                />
                {errors.bankName && (
                  <p className="text-red text-xs">{errors.bankName.message}</p>
                )}
              </div>
              <div>
                <Input
                  {...register('accNo', {
                    pattern: {
                      value: /^[0-9\s]+$/,
                      message: 'Invalid characters',
                    },
                    required: {
                      value: true,
                      message: 'This is required',
                    },
                  })}
                  variant="static"
                  label="Account Number"
                  placeholder="Enter Account Number"
                  success={true}
                />
                {errors.accNo && (
                  <p className="text-red text-xs">{errors.accNo.message}</p>
                )}
              </div>
              <div>
                <Input
                  {...register('accName', {
                    pattern: {
                      value: /^[A-Za-z0-9\s]+$/,
                      message: 'Invalid characters',
                    },
                    required: {
                      value: true,
                      message: 'This is required',
                    },
                  })}
                  variant="static"
                  label="Account Name"
                  placeholder="Enter the Name of the Account"
                />
                {errors.accName && (
                  <p className="text-red text-xs">{errors.accName.message}</p>
                )}
              </div>
              <div>
                <Input
                  {...register('accType', {
                    pattern: {
                      value: /^[A-Za-z\s]+$/,
                      message: 'Invalid characters',
                    },
                    required: {
                      value: true,
                      message: 'This is required',
                    },
                  })}
                  variant="static"
                  label="Account Type"
                  placeholder="Enter the Type of the Account (i.e Checking)"
                />
                {errors.accType && (
                  <p className="text-red text-xs">{errors.accType.message}</p>
                )}
              </div>
            </>
          </div>
        </DialogBody>
        <DialogFooter>
          <div>
            <Button
              type="submit"
              className="rounded-lg flex-1"
              disabled={Object.keys(errors).length != 0}
              color={'primary'}
            >
              Save
            </Button>
          </div>
        </DialogFooter>
      </form>
    </Dialog>
  )
}
export default EditAccountDialog
