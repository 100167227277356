import { useEffect, useState } from 'react'

import { filterList } from './helper'

const useFilteredOptions = (
  options: any[],
  filterValue: string,
  optionListKey?: string,
  sizeList?: number
): [any[], number | null] => {
  const [filteredOptions, setFilteredOptions] = useState<any[]>([])
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  useEffect(() => {
    const filtered = filterList(options, filterValue, optionListKey, sizeList)
    setFilteredOptions(filtered)
    setSelectedIndex(filtered?.length ? 0 : null)

    return () => {
      setFilteredOptions([])
      setSelectedIndex(null)
    }
  }, [options, filterValue, optionListKey, sizeList])

  return [filteredOptions, selectedIndex]
}

export default useFilteredOptions
