import { Filter } from '@interfaces/analytics-risk-filter-type-key'

import apiClient from './client'

class FilterService {
  static async getFilters(schema_name: string, slug_name: string) {
    return apiClient.post<Filter[]>(`risk-traction-filters/retrieve`, {
      schema_name,
      slug_name,
    })
  }

  static async getAvgTerm(params: {
    slug_name?: string
    filters: any[]
    date_from: string
    date_to: string
  }) {
    const { data } = await apiClient.post<number>(
      `risk-traction-filters/avg-term`,
      params
    )

    return data
  }
}

export default FilterService
