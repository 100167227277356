import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/risk'
import { CollectionService } from '@services/api-analytics/risk-collection'

const AggregatedRollRatesL5 = ({ exportable }: { exportable: boolean }) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes = [],
    activeType,
  } = appliedFilters

  const filters: RisksFilters = {
    date_from: moment.utc(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEndCohort).format('YYYY-MM-DD'),
    slug_name: company?.slug_name,
    filters: categoryTypes,
    is_aggregate: true,
  }

  const { error, data, isFetching } = useQuery(
    ['aggregatedRollRates', filters],
    () => CollectionService.getAggregatedRollRates(filters),
    getStaleMins()
  )

  const dt = (data?.data || [])?.[0] ?? {}
  const chartData = [
    '0 DPD',
    '1-14 DPD',
    '15-29 DPD',
    '30-59 DPD',
    '60-89 DPD',
    '90-119 DPD',
    '120+ DPD',
  ].map((x, i) => ({
    x,
    improve: parseFloat((dt[`improve_${i + 1}`] ?? 0).toString()),
    no_change: parseFloat((dt[`no_change_${i + 1}`] ?? 0).toString()),
    worsen: parseFloat((dt[`worsen_${i + 1}`] ?? 0).toString()),
    paid_off: parseFloat((dt[`paid_off_${i + 1}`] ?? 0).toString()),
  }))

  const series = [
    { field: 'paid_off', label: 'Paid Off' },
    { field: 'improve', label: 'Improve' },
    { field: 'no_change', label: 'No Change' },
    { field: 'worsen', label: 'Worsen' },
  ]

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  return (
    <L5ChartstatLayout
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`riskCollectionARR_by_${activeType}`}
          yLabel="Percentage"
          yFormat="#.0a%"
          xLabel="Starting DPD Bucket"
          xAxisType="CategoryAxis"
          data={chartData}
          series={series.map(s => ({
            ...s,
            tooltipValueFormat: '#.0a%',
            type: 'ColumnSeries',
            isStack: true,
          }))}
          exportable={exportable}
          error={error as { message: string }}
        />
      }
    />
  )
}

export default AggregatedRollRatesL5
