import React from 'react'
import moment from 'moment'

import Table from '@components/table'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'

const MonitorDashboardTableItem = ({ item }: { item: DashboardResponse }) => {
  const unit = item.data?.[0]?.unit
  const unprepared_column = (item.data ?? []).reduce((p, c) => {
    const x =
      item.x_axis_type === 'CategoryAxis'
        ? c[item.x_axis_field]
        : moment.utc(c[item.x_axis_field]).valueOf()

    const existingIndex = p.findIndex((_x: any) => _x.field === x)
    if (existingIndex >= 0) {
      return p
    } else {
      return [
        ...p,
        {
          title: c[item.x_axis_field],
          field: x,
          align: 'right',
          head: { align: 'center' },
          render: (r: any) => {
            return r[x]
              ? Intl.NumberFormat(undefined, {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  notation: 'compact',
                }).format(r[x]) + (unit ?? '')
              : '-'
          },
        },
      ]
    }
  }, [])

  const table_columns = [
    {
      title: item.title,
      field: 'series',
      className: 'min-w-[200px]',
    },
    ...unprepared_column,
  ]

  const table_footer_data: any[] = [{ series: 'Total' }]

  const table_data = (item.data ?? []).reduce((p, c) => {
    const x =
      item.x_axis_type === 'CategoryAxis'
        ? c[item.x_axis_field]
        : moment.utc(c[item.x_axis_field]).valueOf()
    const existingIndex = p.findIndex(
      (x: any) => x.series === c[item.series_field ?? '']
    )

    table_footer_data[0][x] =
      Number(table_footer_data[0][x] ?? 0) + Number(c[item.y_axis_field])

    if (existingIndex >= 0) {
      p[existingIndex][x] = c[item.y_axis_field]
      return p
    } else {
      return [
        ...p,
        {
          series: c[item.series_field ?? ''],
          [x]: c[item.y_axis_field],
        },
      ]
    }
  }, [])

  return item.show_table ? (
    <Table
      data={table_data}
      footerData={item.show_table_total ? table_footer_data : undefined}
      columns={table_columns}
    />
  ) : (
    <></>
  )
}
export default MonitorDashboardTableItem
