import {
  CategoriesResult,
  ListCategoriesFilters,
  ReportingDocFilters,
} from '@interfaces/manage-reporting'
import { SignatureRequestFilters } from '@interfaces/manage-signature'

import manageApiClient from './client'

class DocumentService {
  static async getSignedDocsInfo(filters: SignatureRequestFilters) {
    return await manageApiClient.post(`doc-centre/list-filtered`, filters, {})
  }

  static async getOrderedReporting(filters: ReportingDocFilters) {
    const { data } = await manageApiClient.post<any[]>(
      `doc-centre/order-by-column`,
      filters,
      {}
    )
    return data
  }

  static async voidDoc(id: string, slug_name?: string) {
    return await manageApiClient.post(
      `doc-centre/void-doc`,
      { slug_name, sig_request_id: id },
      {}
    )
  }
  static async uploadDocument(formData: FormData) {
    const { data } = await manageApiClient.post<any[]>(
      `/doc-centre/upload-file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
    )
    return data
  }

  static async getPresignedUrl(
    docData: { Bucket: any; Key: any },
    slug?: string
  ) {
    return manageApiClient.post(`doc-centre/presigned-url`, {
      slug_name: slug,
      bucket: docData.Bucket,
      key: docData.Key,
    })
  }

  static async postNoteOnDoc(payload: {
    note: string
    userId: string
    docId: string
  }) {
    return manageApiClient.post(`notes`, {
      note: payload.note,
      user_id: payload.userId,
      doc_id: payload.docId,
    })
  }

  static async getNotesOnDoc(id: string) {
    return manageApiClient.get(`notes/${id}`)
  }

  static async deleteNote(id: string) {
    return manageApiClient.delete(`notes/${id}`)
  }

  static async updateNote(payload: any) {
    return manageApiClient.post(`notes/update`, {
      ...payload,
    })
  }

  static async listCategories({
    slug_name,
    facility,
    action,
  }: ListCategoriesFilters) {
    const { data } = await manageApiClient.post<CategoriesResult[]>(
      `doc-centre/list-categories`,
      {
        slug_name,
        facility,
        action,
      }
    )
    return data
  }
}

export default DocumentService
