import React, { useState } from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'
import { CovenantListResponse, CovenantType } from '@interfaces/covenant'
import {
  Dialog,
  DialogBody,
  DialogHeader,
  // Option,
  // Select,
} from '@material-tailwind/react'

// import DefinitionAndCalculation from './definition-and-calculation'
import RawData from './raw-data'
// import LoanPool from './loan-pool'

interface PopupAuditProps {
  open?: boolean
  handler: () => void
  covenant: CovenantListResponse
  type: CovenantType
}
const PopupAudit = ({
  open = false,
  handler,
  covenant,
  type,
}: PopupAuditProps) => {
  // const convenants = ['Cohort', 'Convenant 2', 'Convenant 3']
  // const [activeConvenant, setActiveConvenant] = useState<number>(0)

  const contents = [
    // {
    //   title: 'Definition and Calculation',
    //   component: <DefinitionAndCalculation />,
    // },
    {
      title: 'Raw Data',
      component: <RawData covenant={covenant} type={type} />,
    },
    // { title: 'Loan Pool', component: <LoanPool /> },
  ]
  const [activeContent, setActiveContent] = useState<number>(0)

  return (
    <Dialog open={open} handler={() => handler()} size="lg">
      <DialogHeader className="border-b border-neutral-border-2 flex justify-end items-start">
        {/* <Select
          className="max-w-[150px]"
          onChange={(val = 0) => {
            setActiveConvenant(Number(val))
          }}
          selected={() => {
            return <span>{convenants[activeConvenant]}</span>
          }}
        >
          {convenants.map((c, i) => (
            <Option key={c} value={i.toString()}>
              {c}
            </Option>
          ))}
        </Select> */}
        <button onClick={() => handler()}>
          <XMarkIcon className="w-6 h-6" />
        </button>
      </DialogHeader>
      <DialogBody className="flex flex-col text-sm text-black max-h-[80vh] overflow-y-auto">
        {contents.length > 1 && (
          <div className="flex justify-center mb-6">
            <div className="rounded p-1 bg-neutral-surface-2">
              {contents.map((c, i) => (
                <button
                  key={i}
                  onClick={() => setActiveContent(i)}
                  className={`rounded text-sm font-medium min-w-[200px] py-0.5 ${
                    activeContent === i
                      ? 'bg-secondary-main text-white'
                      : 'text-neutal-subtitle-1'
                  }`}
                >
                  {c.title}
                </button>
              ))}
            </div>
          </div>
        )}
        <div>{contents[activeContent].component}</div>
      </DialogBody>
    </Dialog>
  )
}

export default PopupAudit
