export default {
  styles: {
    base: {
      bg: 'bg-neutral-body-2',
      color: 'text-white',
      padding: 'px-4 py-2',
      maxWidth: 'max-w-[350px]',
    },
  },
}
