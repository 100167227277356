import {
  WaterfallDetailRequest,
  WaterfallDetailResponse,
  WaterfallListResponse,
  WaterfallRequest,
  WaterfallSignRequest,
} from '@interfaces/manage-monitor-waterfall'

import manageApiClient from './client'

export class WaterfallService {
  static async getList(params: WaterfallRequest) {
    const { data } = await manageApiClient.post<WaterfallListResponse[]>(
      `/waterfall/list`,
      params
    )
    return data
  }

  static async getDetail(params: WaterfallDetailRequest) {
    const { data } = await manageApiClient.post<WaterfallDetailResponse[]>(
      `/waterfall/detail`,
      params
    )
    return data
  }

  static async getHistory(params: WaterfallDetailRequest) {
    const { data } = await manageApiClient.post<WaterfallDetailResponse[]>(
      `/waterfall/history`,
      params
    )
    return data
  }

  static async submit(formData: FormData) {
    const { data } = await manageApiClient.post(`/waterfall/submit`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    })
    return data
  }

  static async sign(params: WaterfallSignRequest) {
    const { data } = await manageApiClient.post(`/waterfall/sign`, params)
    return data
  }

  static async download(params: { slug_name: string; file: string }) {
    const { data } = await manageApiClient.post(`/waterfall/download`, params)
    return data
  }

  static async uploadProofOfTransaction(formData: FormData) {
    const { data } = await manageApiClient.post(
      `/waterfall/proof-of-transaction`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
    )
    return data
  }
}
