import React from 'react'

import L4TabsLayout from '@components/layouts/l4-tabs-layout'

import RunwayL5 from './runway'

const RunwayL4 = ({ exportable }: { exportable: boolean }) => {
  const tabs = [{ label: '', component: <RunwayL5 exportable={exportable} /> }]

  return <L4TabsLayout tabs={tabs} />
}

export default RunwayL4
