import React, { ReactNode, useEffect } from 'react'

interface StepperProps {
  activeStep: number
  children: ReactNode[]
  setIsLastStep: (value: any) => any
  setIsFirstStep: (value: any) => any
  setActiveStep: (val: number) => any
}

const Stepper: React.FC<StepperProps> = ({
  activeStep,
  children,
  setIsLastStep,
  setIsFirstStep,
}) => {
  useEffect(() => {
    children && setIsLastStep(activeStep == children.length - 1)
    children && setIsFirstStep(activeStep == 0)
  }, [activeStep])

  const len = (activeStep * 100) / (children.length - 1)

  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-col justify-center relative">
        <div className="mb-5 h-1 bg-primary-surface-1 rounded-lg mx-12">
          <div
            className={`transition-all ease-in-out delay-150 h-1 bg-gradient-to-r from-primary-main to-secondary-main rounded-lg ${
              len != 0 ? `w-[${len}%]` : 'w-0'
            }`}
          ></div>
        </div>
        <div className="w-full absolute -top-2.5 flex justify-between">
          {children.map((child: any, idx: number) => (
            <div key={idx}>
              <div
                className={`flex flex-col items-center ${
                  activeStep > idx ? 'cursor-pointer' : 'cursor-not-allowed'
                }`}
                onClick={() => {
                  idx < activeStep && child.props.onClick(idx)
                }}
              >
                <div
                  className={`transition-all ease-in-out delay-150 p-3 ${
                    activeStep > idx
                      ? 'bg-primary-main'
                      : activeStep == idx
                      ? 'bg-secondary-main'
                      : 'bg-primary-surface-1 '
                  } rounded-full`}
                />
                <div className="text-xs font-semibold pt-1">
                  {child.props.title}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="transition-all ease-in-out delay-250 mt-10">
        {children[activeStep]}
      </div>
    </div>
  )
}

export default Stepper
