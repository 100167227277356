import React from 'react'

import Button from '@components/atoms/button'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'

interface ConfirmDialogProps {
  open: boolean
  handler: () => void
  confirmHandler: () => void
}

export const ConfirmDialog = ({
  open,
  handler,
  confirmHandler,
}: ConfirmDialogProps) => {
  return (
    <Dialog open={open} handler={handler} size="xl">
      <DialogHeader>
        Are you sure?{' '}
        <div onClick={() => handler()} className="absolute right-10">
          <XMarkIcon className="w-7 cursor-pointer" onClick={handler} />
        </div>
      </DialogHeader>
      <DialogBody className="flex flex-col">
        <p className="text-sm m-2">
          Once finalised, no further changes can be made to this audit & this
          action cannot be undone.
        </p>
        <p className="text-sm m-2">Are you sure you want to proceed?</p>
      </DialogBody>
      <DialogFooter className="pr-10">
        <Button color="secondary" className="mx-2" onClick={handler}>
          Cancel
        </Button>
        <Button color="primary" className="mx-2 px-10" onClick={confirmHandler}>
          Yes
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default ConfirmDialog
