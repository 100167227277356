import React, { useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import HelloSign from 'hellosign-embedded'
import moment, { Moment } from 'moment'
import Dropzone from 'react-dropzone'
import { useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import FormInput from '@components/form/form-input'
import { DROPBOX_SIGN_CLIENT_ID } from '@constants/config'
import AuthContext from '@contexts/auth'
import { getCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { DocumentCheckIcon } from '@heroicons/react/24/solid'
import { AdvanceRequestResponse } from '@interfaces/manage-monitor-action'
import {
  Alert,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { BorrowingBaseService } from '@services/api-manage/borrowing-base'
import { ActionService } from '@services/api-manage/monitor-action'
import SignatureService from '@services/api-manage/signature'

interface DialogFormAdvanceRequestProps {
  open?: boolean
  handler: (needupdate?: boolean) => void
  data?: AdvanceRequestResponse
  docCentreId?: string
  can_sign: boolean
}

const DialogFormAdvanceRequest = ({
  open = false,
  handler,
  data,
  docCentreId,
  can_sign,
}: DialogFormAdvanceRequestProps) => {
  const client = new HelloSign()
  const { user } = useAuth0()
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)
  const { currencies } = getCurrencies()
  const [userIsSignee, setUserIsSignee] = useState<boolean>(false)
  const [signeeJson, setSigneeJson] = useState<any>()
  const [docSigned, setDocSigned] = useState<boolean>(false)

  const { activeDebtDeal = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = useForm({
    shouldUnregister: false,
  })
  const { fields, replace, remove } = useFieldArray({
    control,
    name: 'lenders',
  })
  const file = getValues('file')
  const amount = getValues('amount') ?? 0

  useEffect(() => {
    if (open) {
      if (
        !!data?.signature_request?.id &&
        data.signature_request.dropbox_api_supp !== 's3 upload'
      ) {
        const parsedSignees = data.signature_request.signees
          ? JSON.parse(data.signature_request.signees)
          : null
        setSigneeJson(parsedSignees)
        setUserIsSignee(
          parsedSignees.some((s: any) => s.signer_email_address === user?.email)
        )
        setDocSigned(
          parsedSignees.status_code == 'signed' || data.status !== 'pending'
        )
      }
      setTimeout(() => {
        setValue('date', data?.date ? moment.utc(data?.date) : '', {
          shouldValidate: true,
        })
        setValue(
          'currency',
          data?.currency
            ? data.currency
            : activeFacility.agreement_currency ?? 'USD',
          {
            shouldValidate: true,
          }
        )
        setValue('amount', data?.amount, { shouldValidate: false })
        setValue('file', data?.file, { shouldValidate: true })
        const lenders = Array(Number(activeFacility?.lender ?? 0))
          .fill('')
          .map((x, i) => ({
            id: data?.lenders?.[i]?.id,
            comment: data?.lenders?.[i]?.comment,
            proof_of_transfer_file: data?.lenders?.[i]?.proof_of_transfer_file,
            funded_date: data?.lenders?.[i]?.funded_date
              ? moment(data?.lenders?.[i]?.funded_date)
              : '',
            lender_ratio: data?.lenders?.[i]?.lender_ratio,
            payout_amount: data?.lenders?.[i]?.payout_amount ?? 0,
          }))
        replace(lenders)
      })
    } else {
      reset()
      remove()
    }
  }, [data, open])

  /**
   * This section gets the advance rate
   * currently hard coded to 0.95 until the db gets caught up
   */
  const calcFilters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility ?? '',
    numberOfLenders: activeFacility?.lender ?? 0,
  }
  const { data: calcData } = useQuery(
    ['calculation', calcFilters],
    () => BorrowingBaseService.getCalculation(calcFilters),
    getStaleMins()
  )
  const advanceRate =
    (calcData?.tableResponse ?? [])?.find(
      x => x.defined_term === 'advance_rate'
    )?.value ?? 1

  const _successHandler = () => {
    handler?.(true)
  }

  /**
   * Doc signing functionality
   */
  const handleSuccess = (data: any) => {
    toast.dismiss()

    if (data.data.signature_request_id) {
      toast.success('Signature Request Created', { autoClose: 5000 })
      return
    }
    if (data.data.claim_url) {
      if (can_sign) {
        client.open(data.data.claim_url, {
          clientId: DROPBOX_SIGN_CLIENT_ID,
          skipDomainVerification: true,
        })
        client.on('sign', () => {
          setDocSigned(true)
          onSigned()
        })
      }
    } else {
      toast.error('Unexpected Error Occurred', { autoClose: false })
    }
  }
  const { mutate: retrieveEmbeddedClaimUrl } = useMutation(
    (sigRequestId: string) => {
      return SignatureService.getClaimUrl(sigRequestId, company?.slug_name)
    },
    {
      onSuccess: handleSuccess,
    }
  )

  const {
    mutate: createAdvanceRequest,
    isLoading: isLoadingCreate,
    error: errorCreate,
  } = useMutation(
    (formData: FormData) => {
      return ActionService.createAdvanceRequest(formData)
    },
    {
      onSuccess: _successHandler,
    }
  )

  const {
    mutate: updateAdvanceRequest,
    isLoading: isLoadingUpdate,
    error: errorUpdate,
  } = useMutation(
    ({ formData, id }: { formData: FormData; id: number }) => {
      return ActionService.updateAdvanceRequest(formData, id)
    },
    {
      onSuccess: _successHandler,
    }
  )

  const isFunded = (lenders: any[]) => {
    return lenders.every((lender: any) => !!lender.funded_date)
  }

  const onSigned = () => {
    if (data?.id) {
      const formData = new FormData()
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name ?? ''
      )
      formData.append('facility', activeFacility?.facility ?? '')
      formData.append('status', 'signed')
      formData.append('requester_name', user?.name ?? '')
      data.date &&
        formData.append('date', moment(data?.date).format('YYYY-MM-DD'))
      formData.append('currency', data.currency)
      formData.append('amount', Number(data.amount).toString())
      data.file && formData.append('files', data.file)
      data.lenders?.forEach((l: any, i: number) => {
        const ratio =
          activeFacility?.[`lender_${i + 1}_advance_request_ratio`] /
          advanceRate
        const payoutAmount = l.payout_amount || Number(amount) * ratio
        formData.append(`lenders[${i}][payout_amount]`, payoutAmount)
        formData.append(`lenders[${i}][lender_ratio]`, ratio.toString())
        l.id && formData.append(`lenders[${i}][id]`, l.id)
        l.comment && formData.append(`lenders[${i}][comment]`, l.comment)
        l.funded_date &&
          formData.append(
            `lenders[${i}][funded_date]`,
            moment(l.funded_date).format('YYYY-MM-DD')
          )
        l.proof_of_transfer_file &&
          formData.append(
            `lenders[${i}][proof_of_transfer_file]`,
            l.proof_of_transfer_file
          )
      })
      updateAdvanceRequest({ formData, id: data.id })
    }
  }

  const onSubmit = (dt: any) => {
    const formData = new FormData()
    docCentreId && formData.append('d_centre_id', docCentreId)
    formData.append(
      'slug_name',
      activeFacility?.slug_name ?? company?.slug_name ?? ''
    )
    formData.append('facility', activeFacility?.facility ?? '')
    dt.date && formData.append('date', moment(dt.date).format('YYYY-MM-DD'))
    formData.append('currency', dt.currency)
    formData.append('amount', Number(dt.amount).toString())
    formData.append('files', dt.file)
    formData.append('form_values', JSON.stringify(dt))
    dt.lenders?.forEach((l: any, i: number) => {
      const ratio =
        activeFacility?.[`lender_${i + 1}_advance_request_ratio`] / advanceRate
      const payoutAmount = l.payout_amount || Number(amount) * ratio
      formData.append(`lenders[${i}][payout_amount]`, payoutAmount)
      formData.append(`lenders[${i}][lender_ratio]`, ratio.toString())
      l.id && formData.append(`lenders[${i}][id]`, l.id)
      l.comment && formData.append(`lenders[${i}][comment]`, l.comment)
      l.funded_date &&
        formData.append(
          `lenders[${i}][funded_date]`,
          moment(l.funded_date).format('YYYY-MM-DD')
        )
      l.proof_of_transfer_file &&
        formData.append(
          `lenders[${i}][proof_of_transfer_file]`,
          l.proof_of_transfer_file
        )
    })

    if (data?.id) {
      formData.append(
        'status',
        isFunded(dt.lenders)
          ? 'funded'
          : docSigned || data.status === 'signed'
          ? 'signed'
          : 'pending'
      )
      updateAdvanceRequest({ formData, id: data.id })
    } else {
      formData.append('status', isFunded(dt.lenders) ? 'funded' : 'signed')
      data?.signature_request?.id &&
        formData.append('doc_id', data.signature_request.id)

      createAdvanceRequest(formData)
    }
  }

  const datePickerStart = debtDealOptions?.[activeDebtDeal ?? 0]?.agreement_date
    ? moment(debtDealOptions[activeDebtDeal ?? 0]?.agreement_date)
    : moment(company?.date_start)
  const datePickerEnd = moment().add(1, 'month')

  const isProcessing = isSubmitting || isLoadingCreate || isLoadingUpdate
  const error = errorCreate || errorUpdate
  const isSigning = data?.status === 'pending' && !docSigned && userIsSignee

  return (
    <Dialog open={open} handler={() => undefined} size="md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader className="text-xl flex justify-between">
          Capital Advance Request
          <XMarkIcon
            onClick={() => handler()}
            className="w-6 h-6 cursor-pointer"
          />
        </DialogHeader>
        <DialogBody
          divider
          className="flex flex-col overflow-y-auto max-h-[70vh]"
        >
          {error && (
            <Alert className="bg-danger-main mb-6">
              {((error as AxiosError).response?.data as any)?.error ??
                (error as AxiosError).message}
            </Alert>
          )}
          {data?.id && signeeJson && !docSigned && !userIsSignee && (
            <Alert className="bg-warning-main mb-6">
              Please have {signeeJson?.[0]?.signer_email_address} login to sign
              this document
            </Alert>
          )}
          <div className="mb-4">
            <div className="text-neutral-border-3 p-3 border border-neutral-border-1 flex justify-center items-center rounded-t-lg bg-neutral-surface-1">
              <Typography className="font-semibold text-sm">Details</Typography>
            </div>
            <div className="p-4 flex flex-col border border-t-0 border-neutral-border-1 rounded-b-lg">
              <div className="gap-8 grid grid-cols-3 items-center">
                <Typography className="font-medium text-sm mb-2">
                  Request Date
                </Typography>
                <div className="col-span-2">
                  <FormInput
                    type="date"
                    value={getValues('date') || ''}
                    disabled={data?.id ? true : false}
                    onSelected={val => {
                      setValue('date', val, {
                        shouldValidate: true,
                      })
                    }}
                    disabledDate={(current: Moment) => {
                      return (
                        current.isBefore(moment.utc(datePickerStart)) ||
                        current.isAfter(moment.utc(datePickerEnd))
                      )
                    }}
                    error={errors?.date?.message as string}
                  />
                </div>
              </div>
              <div className="gap-8 grid grid-cols-3 items-center">
                <Typography className="font-medium text-sm mb-2">
                  Currency
                </Typography>
                <div className="col-span-2">
                  <FormInput
                    type="select"
                    disabled={!!data?.id}
                    value={getValues('currency') || currencies[0]}
                    onSelected={val => {
                      setValue('currency', val, {
                        shouldValidate: true,
                      })
                    }}
                    options={currencies.map(c => ({ value: c, title: c }))}
                    error={errors?.currency?.message as string}
                  />
                </div>
              </div>
              <div className="gap-8 grid grid-cols-3 items-center">
                <Typography className="font-medium text-sm mb-2">
                  Capital Requested
                </Typography>
                <div className="col-span-2">
                  <FormInput
                    type="number"
                    disabled={!!data?.id}
                    value={getValues('amount') || 0}
                    {...register('amount', {
                      required: `Amount is required`,
                      onChange: e => {
                        setValue('amount', e.target.value, {
                          shouldValidate: true,
                        })
                      },
                      min: {
                        value: 1,
                        message: 'Amount can not be 0',
                      },
                    })}
                    {...{ step: '0.01' }}
                    error={errors?.amount?.message as string}
                  />
                </div>
              </div>
              {!data?.file && data?.status !== 'nullified' && (
                <div className="gap-8 grid grid-cols-3 items-center mb-4">
                  <Typography className="font-medium text-sm mb-2">
                    Advance Request Document
                  </Typography>
                  <div className="col-span-2">
                    <Dropzone
                      accept={{
                        'application/pdf': ['.pdf'],
                      }}
                      onDrop={acceptedFiles => {
                        setValue('file', acceptedFiles?.[0], {
                          shouldValidate: true,
                        })
                        setDocSigned(true)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="focus-within:border-primary-main cursor-pointer border border-neutral-border-2 rounded-lg p-4 border-dashed bg-neutral-white justify-center text-center">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />

                            {file ? (
                              <div className="flex justify-center items-center gap-4">
                                <DocumentCheckIcon className="w-6 h-6 text-primary-main" />
                                <p className="truncate text-sm">
                                  {file?.path ?? file}
                                </p>
                              </div>
                            ) : (
                              <p className="text-sm">
                                Drag & drop file here, or click to select file
                              </p>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
              )}
            </div>
          </div>
          {fields.map((f, i) => {
            const fieldFile = getValues(`lenders.${i}.proof_of_transfer_file`)
            return (
              <div key={i} className="mb-4">
                <div className="text-neutral-border-3 p-3 border border-neutral-border-1 flex justify-center items-center rounded-t-lg bg-neutral-surface-1">
                  <Typography className="font-semibold text-sm">
                    {`Lender ${i + 1} Request Detail`}
                  </Typography>
                </div>
                <div className="p-4 flex flex-col border border-t-0 border-neutral-border-1 rounded-b-lg">
                  {advanceRate > 0 && (
                    <div className="gap-8 grid grid-cols-3 items-center mb-2">
                      <Typography className="text-sm">Ratio</Typography>
                      <div className="col-span-2">
                        <Typography className="text-sm">
                          {Intl.NumberFormat(undefined, {
                            style: 'percent',
                            maximumFractionDigits: 2,
                          }).format(
                            getValues(`lenders.${i}.lender_ratio`)
                              ? getValues(`lenders.${i}.lender_ratio`)
                              : activeFacility?.[
                                  `lender_${i + 1}_advance_request_ratio`
                                ] / advanceRate ?? 0
                          )}
                        </Typography>
                      </div>
                    </div>
                  )}
                  <div className="gap-8 grid grid-cols-3 items-center mb-2">
                    <Typography className="text-sm">Amount</Typography>
                    <div className="col-span-2">
                      {advanceRate > 0 ? (
                        <Typography className="text-sm">
                          {`${Intl.NumberFormat(undefined, {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                          }).format(
                            getValues(`lenders.${i}.payout_amount`)
                              ? getValues(`lenders.${i}.payout_amount`)
                              : amount *
                                  (activeFacility?.[
                                    `lender_${i + 1}_advance_request_ratio`
                                  ] / advanceRate ?? 0)
                          )} ${getValues('currency')}`}
                        </Typography>
                      ) : (
                        <FormInput
                          type="number"
                          value={getValues(`lenders.${i}.payout_amount`) || ''}
                          disabled={isProcessing}
                          {...register(`lenders.${i}.payout_amount`, {
                            onChange: e => {
                              setValue(
                                `lenders.${i}.payout_amount`,
                                e.target.value,
                                {
                                  shouldValidate: true,
                                }
                              )
                            },
                          })}
                          error={
                            errors?.[`lenders.${i}.comment`]?.message as string
                          }
                        />
                      )}
                    </div>
                  </div>
                  {!isSigning && data?.status !== 'nullified' && (
                    <>
                      {data?.status !== 'pending' && (
                        <div className="gap-8 grid grid-cols-3 items-center">
                          <Typography className="text-sm mb-2">
                            Accrual Date
                          </Typography>
                          <div className="col-span-2">
                            <FormInput
                              type="date"
                              disabled={isProcessing}
                              value={
                                getValues(`lenders.${i}.funded_date`)
                                  ? getValues(`lenders.${i}.funded_date`)
                                  : ''
                              }
                              onSelected={val => {
                                setValue(`lenders.${i}.funded_date`, val, {
                                  shouldValidate: true,
                                })
                              }}
                              error={errors?.funded_date?.message as string}
                            />
                          </div>
                        </div>
                      )}
                      <div className="gap-8 grid grid-cols-3 items-center">
                        <Typography className="text-sm mb-2">
                          Additional Note
                        </Typography>
                        <div className="col-span-2">
                          <FormInput
                            type="textarea"
                            value={getValues(`lenders.${i}.comment`) || ''}
                            disabled={isProcessing}
                            {...register(`lenders.${i}.comment`, {
                              onChange: e => {
                                setValue(
                                  `lenders.${i}.comment`,
                                  e.target.value,
                                  {
                                    shouldValidate: true,
                                  }
                                )
                              },
                            })}
                            error={
                              errors?.[`lenders.${i}.comment`]
                                ?.message as string
                            }
                          />
                        </div>
                      </div>
                      <div className="gap-8 grid grid-cols-3 items-center">
                        <Typography className="text-sm">
                          Proof of Transfer
                        </Typography>
                        <div className="col-span-2">
                          <Dropzone
                            accept={{
                              'application/pdf': ['.pdf'],
                            }}
                            onDrop={acceptedFiles => {
                              setValue(
                                `lenders.${i}.proof_of_transfer_file`,
                                acceptedFiles?.[0],
                                {
                                  shouldValidate: true,
                                }
                              )
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section className="focus-within:border-primary-main cursor-pointer border border-neutral-border-2 rounded-lg p-4 border-dashed bg-neutral-white justify-center text-center">
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />

                                  {fieldFile ? (
                                    <div className="flex justify-center items-center gap-4">
                                      <DocumentCheckIcon className="w-6 h-6 text-primary-main" />
                                      <p className="truncate text-sm">
                                        {fieldFile?.path ?? fieldFile}
                                      </p>
                                    </div>
                                  ) : (
                                    <p className="text-sm">
                                      Drag & drop file here, or click to select
                                      file
                                    </p>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )
          })}
        </DialogBody>
        <DialogFooter className="justify-center">
          {isSigning ? (
            <Button
              onClick={() => {
                retrieveEmbeddedClaimUrl(
                  signeeJson.find((s: any) => s.signer_email_address)
                    .signature_id
                )
                handler()
                toast.loading('Request in progress', { autoClose: false })
              }}
              disabled={isProcessing}
              color="primary"
            >
              Sign
            </Button>
          ) : (
            <Button
              type="submit"
              color="primary"
              disabled={!isValid || isProcessing}
            >
              {isProcessing && (
                <ArrowPathIcon className="w-4 h-4 mr-2 text-primary-main animate-spin" />
              )}
              {data?.id ? 'Update Request' : 'Request'}
            </Button>
          )}
        </DialogFooter>
      </form>
    </Dialog>
  )
}

export default DialogFormAdvanceRequest
