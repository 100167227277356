/**
 * https://github.com/creativetimofficial/material-tailwind/blob/main/packages/material-tailwind-react/src/theme/components/button/index.ts
 */
export default {
  defaultProps: {
    color: 'default',
  },
  valid: {
    colors: ['default', 'primary', 'secondary'],
  },
  styles: {
    base: {
      initial: {
        flex: 'flex justify-center items-center',
        fontWeight: 'font-medium',
        textTransform: 'normal-case',
        disabled:
          'disabled:bg-neutral-border-1 disabled:border-neutral-border-2 disabled:border disabled:text-neutral-body-1',
      },
    },
    sizes: {
      sm: {
        fontSize: 'text-sm',
        py: 'py-1',
        px: 'px-2',
        borderRadius: 'rounded-md',
      },
      md: {
        fontSize: 'text-sm',
        py: 'py-2',
        px: 'px-4',
        borderRadius: 'rounded-md',
      },
      lg: {
        fontSize: 'text-sm',
        py: 'py-4',
        px: 'px-8',
        borderRadius: 'rounded-md',
      },
    },
    variants: {
      filled: {
        default: {
          background: 'bg-neutral-white border border-neutral-border-2',
          color: 'text-neutral-body-2',
          hover: 'hover:bg-primary-surface-2 hover:text-primary-main',
          active: 'active:bg-neutral-body-2',
          focus: 'focus:outline focus:outline-2 focus:outline-border-1',
        },
        primary: {
          background: 'bg-primary-main',
          color: 'text-neutral-white',
          hover: 'hover:bg-primary-hover',
          active: 'active:bg-primary-pressed',
          focus: 'focus:outline focus:outline-2 focus:outline-primary-border',
        },
        secondary: {
          background: 'bg-secondary-main',
          color: 'text-neutral-white',
          hover: 'hover:bg-secondary-hover',
          active: 'active:bg-secondary-pressed',
          focus: 'focus:outline focus:outline-2 focus:outline-secondary-border',
        },
      },
      outlined: {
        default: {
          border: 'border border-neutral-border-2',
          color: 'text-neutral-black',
          hover: 'hover:bg-neutral-border-2 hover:text-neutral-white',
          active: 'active:bg-neutral-body-2',
          focus: 'focus:outline focus:outline-2 focus:outline-border-2',
        },
        primary: {
          border: 'border border-primary-main',
          color: 'text-primary-main',
          hover: 'hover:bg-primary-hover hover:text-neutral-white',
          active: 'active:bg-primary-pressed',
          focus: 'focus:outline focus:outline-2 focus:outline-primary-border',
        },
        secondary: {
          border: 'border border-secondary-main',
          color: 'text-secondary-main',
          hover: 'hover:bg-secondary-hover hover:text-neutral-white',
          active: 'active:bg-secondary-pressed',
          focus: 'focus:outline focus:outline-2 focus:outline-secondary-border',
        },
      },
      gradient: {
        default: {
          background: 'bg-gradient-to-r from-primary-main to-secondary-main',
          color: 'text-neutral-white',
          hover: 'hover:bg-primary-hover',
          active: 'active:bg-primary-pressed',
          focus: 'focus:outline focus:outline-2 focus:outline-primary-border',
        },
        primary: {
          background: 'bg-gradient-to-r from-primary-main to-primary-hover',
          color: 'text-neutral-white',
          hover: 'hover:bg-primary-hover',
          active: 'active:bg-primary-pressed',
          focus: 'focus:outline focus:outline-2 focus:outline-primary-border',
        },
        secondary: {
          background: 'bg-gradient-to-r from-secondary-main to-secondary-hover',
          color: 'text-neutral-white',
          hover: 'hover:bg-secondary-hover',
          active: 'active:bg-secondary-pressed',
          focus: 'focus:outline focus:outline-2 focus:outline-secondary-border',
        },
      },
      text: {
        default: {
          color: 'text-neutral-body-2',
          hover: 'hover:text-primary-main',
          active: 'active:bg-primary-surface',
          focus: 'focus:outline focus:outline-2 focus:outline-primary-border',
        },
        primary: {
          color: 'text-primary-main',
          hover: 'hover:bg-primary-hover',
          active: 'active:bg-primary-pressed',
          focus: 'focus:outline focus:outline-2 focus:outline-primary-border',
        },
        secondary: {
          color: 'text-secondary-main',
          hover: 'hover:bg-secondary-hover',
          active: 'active:bg-secondary-pressed',
          focus: 'focus:outline focus:outline-2 focus:outline-secondary-border',
        },
      },
    },
  },
}
