import React, { useContext } from 'react'
import { Fragment } from 'react'

import AuthContext from '@contexts/auth'
import { Transition } from '@headlessui/react'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  TicketIcon,
} from '@heroicons/react/24/outline'
import { LoadingItem } from '@interfaces/loading'

const Loading = () => {
  const { loadingData } = useContext(AuthContext)

  return (
    <Transition
      as={Fragment}
      show={!!loadingData?.length}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="z-20 fixed flex inset-0 bg-opacity-70 overflow-y-auto bg-black h-[calc(100dvh)] w-screen flex justify-center backdrop-blur-sm">
        <div className="w-auto max-w-xl flex-row  my-auto rounded-lg p-2 mx-3">
          <div className="z-0">
            {!!loadingData.length &&
              loadingData.map((item: LoadingItem) => (
                <div key={item.text} className="flex flex-row items-center">
                  <Transition
                    as={Fragment}
                    show={item.state == 'loading'}
                    enter="transition-opacity duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                  >
                    <div className="flex items-center justify-center m-2">
                      <div className="w-7 h-7 border-l-2  border-t-2 border-purple rounded-full animate-spin"></div>
                    </div>
                  </Transition>

                  <Transition
                    as={Fragment}
                    show={item.state == 'done'}
                    enter="transition-opacity duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                  >
                    <div className="flex justify-center items-center justify-center m-2">
                      <CheckCircleIcon className="w-7 h-7 stroke-green" />
                    </div>
                  </Transition>
                  <Transition
                    as={Fragment}
                    show={item.state == 'waiting'}
                    enter="transition-opacity duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                  >
                    <div className="flex justify-center items-center justify-center ml-3 m-2 mr-3">
                      <TicketIcon className="w-5 5-7 stroke-white" />
                    </div>
                  </Transition>
                  <Transition
                    as={Fragment}
                    show={item.state == 'failed'}
                    enter="transition-opacity duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                  >
                    <div className="flex justify-center items-center justify-center m-2">
                      <ExclamationCircleIcon className="w-7 h-7 stroke-orange" />
                    </div>
                  </Transition>
                  <span className="text-sm md:text-md text-gray-100 drop-shadow-xl text-left ml-5">
                    {item.text} {item.state == 'failed' ? 'failed' : ''}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default Loading
