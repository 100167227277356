import React, { useContext, useEffect } from 'react'
import { useState } from 'react'

import FilterCurrency from '@components/filters/filter-currency'
import FilterDebtDeal from '@components/filters/filter-debt-deal'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import AuthContext from '@contexts/auth'
import { Facility } from '@interfaces/facility'

import PaymentSchedule from './payment-schedule'

const CashflowsL3 = () => {
  const {
    company,
    activeFilters,
    optionFilters,
    appliedFilters,
    setAppliedFilters,
  } = useContext(AuthContext)

  const { activeDebtDeal, currency, rate } = activeFilters
  const { activeDebtDeal: appliedActiveDebt = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[appliedActiveDebt]

  const isMasterFacility = activeFacility?.is_master_facility

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (!filterReady && activeDebtDeal !== undefined && rate) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    activeDebtDeal === 0 &&
    currency === company?.currencies_available?.[0]?.to_currency

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  const tabs = (facility?: Facility) => [
    {
      label: 'Payment Schedule',
      component: <PaymentSchedule facility={facility} />,
    },
  ]

  const masterFacilityTabs = isMasterFacility
    ? debtDealOptions
        .filter((f: Facility) => f.slug_name)
        .map((f: Facility) => ({
          label: f.facility,
          component: (
            <L3TabsLayout
              title="Cashflows"
              disableReset={disableReset}
              tabs={tabs(f)}
              disableBreadcrumbs
              layer={2}
            />
          ),
        }))
    : []
  masterFacilityTabs.unshift({
    label: 'All',
    component: (
      <L3TabsLayout
        title="Cashflows"
        disableReset={disableReset}
        tabs={tabs(activeFacility)}
        disableBreadcrumbs
        layer={2}
      />
    ),
  })

  return (
    <L3TabsLayout
      title="Cashflows"
      filters={{
        left: <FilterCurrency hideSelect />,
        middle: <FilterDebtDeal />,
      }}
      disableReset={disableReset}
      tabs={isMasterFacility ? masterFacilityTabs : tabs()}
    />
  )
}
export default CashflowsL3
