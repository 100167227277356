import React, { useContext, useEffect, useState } from 'react'

import FilterCurrency from '@components/filters/filter-currency'
import FilterDebtDeal from '@components/filters/filter-debt-deal'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { Typography } from '@material-tailwind/react'

import ExportDialog from './export-dialog'
import { useDashboard } from './helper'

const MonitorDashboardL3 = () => {
  const {
    company,
    activeFilters,
    appliedFilters,
    setAppliedFilters,
    optionFilters,
  } = useContext(AuthContext)

  const { activeDebtDeal } = activeFilters
  const { activeDebtDeal: appliedActiveDebt = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[appliedActiveDebt]
  const isMasterFacility = activeFacility?.is_master_facility

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (!filterReady && activeDebtDeal !== undefined) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  /**
   * observe reset button disable state criteria
   */
  const disableReset = activeDebtDeal === 0

  const { isFetching, tabs, data, facility } = useDashboard()
  const { get_access } = useUserAccessFeature()
  const FEATURE = `${facility}_manage_monitor_dashboard`
  const can_export = get_access(FEATURE, 'export')

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  return (
    <L3TabsLayout
      title="Portfolio Covenants"
      filters={{
        left: <FilterCurrency hideSelect />,
        middle: <FilterDebtDeal />,
      }}
      disableReset={disableReset}
      tabs={tabs}
      empty={
        isFetching ? (
          <div className="flex justify-center">
            <ArrowPathIcon className="animate-spin text-primary-main w-8" />
          </div>
        ) : (
          <Typography className="text-center">
            This feature is not available for {company?.legal_name}
          </Typography>
        )
      }
      rightFooterContent={
        !isMasterFacility && can_export ? (
          <ExportDialog data={data ?? []} />
        ) : (
          <></>
        )
      }
      switchable
    />
  )
}

export default MonitorDashboardL3
