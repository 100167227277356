import React from 'react'

import { PortfolioCovenantCertTemplateProps } from '@interfaces/manage-reporting'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { Footer, tableStyles } from '../../../components'
import { styles } from '../../../template_style'

const FsvnPortfolioCovenantCertTemplate = ({
  tableData,
  agreementDate,
  effectiveDate,
  covenantNames,
  customData,
  formData,
}: PortfolioCovenantCertTemplateProps) => {
  const renderColumnData = (record: any, recordIndex: number, columns: any) => {
    return (
      <View style={tableStyles.tableRow}>
        {columns.map(({ field }: any, index: number) => {
          return (
            <View style={tableStyles.tableCol} key={index}>
              <Text style={tableStyles.tableCell}>{record[field]}</Text>
            </View>
          )
        })}
      </View>
    )
  }

  return (
    <Document>
      <Page style={styles.body}>
        <View style={{ ...styles.flexRowContainer, flexDirection: 'column' }}>
          <Text style={{ ...styles.textBold, marginBottom: 0 }}>
            SCHEDULE 5
          </Text>
          <Text style={styles.textBold}>FORM OF COMPLIANCE CERTIFICATE</Text>
        </View>
        <Text style={styles.text}>
          To:{'              '}
          <Text style={styles.textBold}>Durational Holdings</Text>
        </Text>
        <Text style={styles.text}>
          From:{'          '}
          <Text style={styles.textBold}>
            KOMODO ASSET I VCC for and on behalf of KY LAN ASSET FINANCE 2
          </Text>
        </Text>
        <Text style={styles.text}>
          Dated:{'         '}
          <Text style={styles.textBold}>{effectiveDate}</Text>
        </Text>
        <Text style={styles.textBold}>
          KOMODO ASSET I VCC for and on behalf of KY LAN ASSET FINANCE 2 -
          US$20,000,000 senior note issuance agreement dated {agreementDate}{' '}
          (the &quot;Agreement&quot;)
        </Text>
        <View style={styles.flexRowContainer}>
          <Text style={styles.textNumbered}>1.{'        '}</Text>
          <Text style={styles.text}>
            We refer to the Agreement. This is a Compliance Certificate. Terms
            used in the Agreement shall have the same meaning in this Compliance
            Certificate.
          </Text>
        </View>
        <View style={styles.flexRowContainer}>
          <Text style={styles.textNumbered}>2.{'        '}</Text>
          <View>
            <Text style={styles.text}>
              We confirm that, as of the date of this Compliance Certificate:
            </Text>
            <Text style={styles.text}>
              (a){'    '}the aggregate principal amount outstanding of the Notes
              is{' '}
              {Intl.NumberFormat(undefined, {
                style: 'decimal',
                maximumFractionDigits: 2,
              }).format(customData?.outstanding_principal ?? 0)}
              ;
            </Text>
            <Text style={styles.text}>
              (b){'    '}we confirm that no Default is continuing and
            </Text>
            <Text style={styles.text}>
              (c){'    '}the Borrowing Base calculation is as reported below and
              accordingly we are not in breach of the Borrowing Base Limit.
            </Text>
          </View>
        </View>
        <View style={{ ...styles.flexRowContainer, marginBottom: 20 }}>
          <Text style={styles.textNumbered}>3.{'        '}</Text>
          <Text style={styles.text}>
            We confirm that, on the date of this Compliance Certificate, the
            Repeating Representations are true and correct in all material
            respects.
          </Text>
        </View>
        {covenantNames.map((covenantName: string, n: number) => {
          return tableData.map((row: any) => {
            return (
              Object.keys(row).includes(covenantName) && (
                <View wrap={false} style={tableStyles.tableView} key={n}>
                  <Text style={styles.text}>
                    {n + 1}.{covenantName}
                  </Text>
                  {
                    <View style={tableStyles.table}>
                      <View style={tableStyles.tableRow}>
                        {row?.[covenantName]?.columns?.map(
                          (col: any, index: React.Key | null | undefined) => {
                            return (
                              <View
                                style={tableStyles.tableHeaderCol}
                                key={index}
                              >
                                <Text style={tableStyles.tableCell}>
                                  {col.title}
                                </Text>
                              </View>
                            )
                          }
                        )}
                      </View>

                      {row?.[covenantName]?.data?.map((d: any, i: number) => {
                        return (
                          <View style={tableStyles.tableBody} key={i}>
                            {renderColumnData(d, i, row[covenantName].columns)}
                          </View>
                        )
                      })}
                    </View>
                  }
                </View>
              )
            )
          })
        })}
        <View
          wrap={false}
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: 20,
          }}
        >
          <View
            style={{
              width: '33%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Text style={styles.text}>Signed:</Text>
          </View>
          <View
            style={{
              width: '33%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>Director</Text>
            <Text style={styles.signTags}>[sig|req|signer1|signature]</Text>
            <Text style={styles.text}>
              {formData?.signers?.[0]?.name ?? '[signer name]'}
            </Text>
            <Text style={styles.text}>of</Text>
            <Text style={styles.textBold}>KOMODO ASSET I VCC</Text>
          </View>
          <View
            style={{
              width: '33%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>Director</Text>
            <Text style={styles.signTags}>[sig|req|signer2|signature]</Text>
            <Text style={styles.text}>
              {formData?.signers?.[1]?.name ?? '[signer name]'}
            </Text>
            <Text style={styles.text}>of</Text>
            <Text style={styles.textBold}>KOMODO ASSET I VCC</Text>
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}

export default FsvnPortfolioCovenantCertTemplate
