import { MfsComplianceDataResponse } from '@interfaces/manage-monitor-template-custom-data'

import manageApiClient from './client'

export class CustomDataService {
  static async getMfsComplianceData(params: {
    slug_name: string
    facility: string
    effectiveDate: string
  }) {
    const { data } = await manageApiClient.post<MfsComplianceDataResponse>(
      `/client-data/mfs-compliance-data`,
      params
    )
    return data
  }
}
