import React, { FC, Fragment, useContext } from 'react'
import { flatten } from 'flat'
import { IntlProvider } from 'react-intl'
import languagesData from 'src/i18n'

import LangState, { LanguageContext } from '@contexts/language'

const LanguagesProvider: FC = ({ children }) => {
  return (
    <LangState>
      <ProviderWrapper> {children}</ProviderWrapper>
    </LangState>
  )
}

const ProviderWrapper: FC = ({ children }) => {
  const { currentLanguage } = useContext(LanguageContext)

  return (
    <IntlProvider
      textComponent={Fragment}
      locale={currentLanguage}
      messages={flatten(languagesData[currentLanguage])}
    >
      {children}
    </IntlProvider>
  )
}

ProviderWrapper.displayName = 'I18nProvider'

export default LanguagesProvider
