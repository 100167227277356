import {
  AdvanceRequestListPayload,
  AdvanceRequestLogListPayload,
  AdvanceRequestResponse,
} from '@interfaces/manage-monitor-action'

import manageApiClient from './client'

export class ActionService {
  static async getAdvanceRequest(params: AdvanceRequestListPayload) {
    const { data } = await manageApiClient.post<{
      data: AdvanceRequestResponse[]
      total: number
    }>(`/advance-request/list`, params)
    return data
  }

  static async getAdvanceRequestById(params: AdvanceRequestListPayload) {
    const { data } = await manageApiClient.post<{
      data: AdvanceRequestResponse[]
      total: number
    }>(`/advance-request/filter-by-id`, params)
    return data
  }

  static async createAdvanceRequest(formData: FormData) {
    const { data } = await manageApiClient.post<any[]>(
      `/advance-request`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
    )
    return data
  }

  static async updateAdvanceRequest(formData: FormData, id: number) {
    const { data } = await manageApiClient.post<any[]>(
      `/advance-request/${id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
    )
    return data
  }

  static async archiveAdvanceRequest(
    id: number,
    params: { slug_name: string }
  ) {
    const { data } = await manageApiClient.post<any[]>(
      `/advance-request/${id}/archive`,
      params
    )
    return data
  }

  static async downloadAdvanceRequest(params: {
    slug_name: string
    file: string
  }) {
    const { data } = await manageApiClient.post<any[]>(
      `/advance-request/download`,
      params
    )
    return data
  }

  static async getHistoryAdvanceRequest(
    id: number,
    params: AdvanceRequestLogListPayload
  ) {
    const { data } = await manageApiClient.post<any[]>(
      `/advance-request/${id}/history`,
      params
    )
    return data
  }
}
