import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import VolumeL5 from './volume'

const VolumeL4 = () => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { filter_access, get_access } = useUserAccessFeature()
  const FEATURE = `analytics_risk_traction_volume`
  const tabs = [
    {
      label: 'Monthly',
      desc: {
        description:
          'The total principal of loans disbursed in a monthly cohort',
        formula:
          'Aggregate sum of all Disbursed Principal for loans in a cohort.',
      },
      isTotal: false,
    },
    {
      label: 'Total',
      desc: {
        description:
          'The total principal of loans disbursed since the beginning of operations',
        formula:
          'Aggregate sum of all Disbursed Principal for loans since the beginning.',
      },
      isTotal: true,
    },
  ]
    .filter(t => filter_access(`${FEATURE}_${t.label.toLowerCase()}`))
    .map(t => {
      const exportable = get_access(
        `${FEATURE}_${t.label.toLowerCase()}`,
        'export'
      )
      return {
        ...t,
        component: <VolumeL5 isTotal={t.isTotal} exportable={exportable} />,
      }
    })

  return (
    <L4TabsLayout
      tabs={tabs}
      additionalIndicator={
        showHistoricalRateIndicator ? <ExchangeRateBadge /> : undefined
      }
      headerClassName={`test-class [&>nav>ul]:!flex`}
    />
  )
}

export default VolumeL4
