import React, { FC } from 'react'

import { InputProps } from '../interfaces'

const SearchInput: FC<InputProps> = ({
  onSearch,
  inputValue,
  inputRef,
  onKeyDown,
  placeholder,
  classNameInput,
  label,
}) => {
  return (
    <>
      <label className="font-normal text-left block text-black text-sm pb-2">
        {label ?? 'Search'}
      </label>
      <input
        className={
          classNameInput
            ? classNameInput
            : `w-full h-9 border  text-sm rounded-md border-[#E0E0E0] placeholder:text-xs px-3 py-2 focus:outline-[#0E9EDA]/[.55]
                          invalid:outline-[#FF3B30]/[.55] block text-sm`
        }
        ref={inputRef}
        onChange={e => onSearch && onSearch(e.target.value)}
        onKeyDown={onKeyDown}
        placeholder={placeholder ?? 'Search'}
        value={inputValue}
        pattern=".*\S.*"
        tabIndex={0}
      />
    </>
  )
}

export default SearchInput
