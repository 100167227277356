import React from 'react'

import { Text, View } from '@react-pdf/renderer'

import { styles } from './style'

export const MFSFooter = ({ indent }: { indent?: number }) => {
  return (
    <View
      fixed={true}
      style={{
        ...styles.footerContainer,
        paddingHorizontal: indent ?? 58,
      }}
    >
      <Text style={styles.footerText}>www.onafriq.com</Text>
    </View>
  )
}
