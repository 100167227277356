import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import ExchangeRateBadge from '@components/exchange-rate-badge'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import StatisticL5 from '@components/layouts/l5-stat'
import { AVG_MONTH_THRESHOLD_DAYS } from '@constants/app'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@material-tailwind/react'
import { DelinquencyService } from '@services/api-analytics/risk-delinquency'
import FilterService from '@services/api-analytics/risk-traction-filter'

const OutstandingDelinqL5 = ({ exportable }: { exportable: boolean }) => {
  const [isAdjusted, setIsAdjusted] = useState<boolean>(false)

  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    categoryTypes = [],
    currency = 'USD',
  } = appliedFilters
  const { rates = [], display_rates = [] } = optionFilters

  const avgFilters = {
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name,
    filters: categoryTypes,
  }

  const { data: avgTerm } = useQuery(
    ['avgTerm', avgFilters],
    () => FilterService.getAvgTerm(avgFilters),
    { ...getStaleMins(), enabled: categoryTypes?.length > 0 }
  )

  const filters = {
    ...avgFilters,
    is_adjusted: isAdjusted,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['outstandingDelinq', filters],
    () => DelinquencyService.getOutstandingDelinq(filters),
    { ...getStaleMins(), enabled: !!avgTerm }
  )

  const { data, ...growthRatios } = fetchedData ?? {}

  const chartData = (data || []).map(x => {
    const typecasetedX = x as unknown as { [key: string]: number | string }
    const result: { x: number; [key: string]: number } = {
      x: moment(typecasetedX.cohort).valueOf(),
      ...(rates ?? []).reduce((p = {}, r: any) => {
        return {
          ...p,
          [`fx_${r.code}`]: Intl.NumberFormat(undefined, {
            style: 'decimal',
            notation: 'compact',
            maximumFractionDigits: 2,
          }).format(r.display_rates?.[typecasetedX.cohort] ?? 1),
        }
      }, {}),
    }
    Object.keys(x).forEach((key: string) => {
      result[key] = parseFloat((typecasetedX[key] ?? 0).toString())
    })

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  const showAdjustedToggle =
    parseFloat((avgTerm ?? 0).toString()) <= AVG_MONTH_THRESHOLD_DAYS

  return (
    <div className="[&>div>div:nth-child(2)]:mt-10">
      <L5ChartstatLayout
        chart={
          <>
            <div className="flex mb-8 justify-between">
              <div className="flex justify-center items-center">
                <Tooltip
                  content={
                    <span>
                      Description:
                      <br />
                      The proportion of the outstanding balance by value in each
                      delinquency bucket, for the whole loan portfolio for a
                      given date.
                      <br />
                      <br />
                      Outstanding Balance:
                      <br />
                      All unpaid principal as of a specific date.
                      <br />
                      <br />
                      {showAdjustedToggle && (
                        <>
                          Adjusted Outstanding Balance:
                          <br />
                          All unpaid principal as of specific date + paid
                          principal for loans disbursed and paid in same month
                          <br />
                          <br />
                        </>
                      )}
                      Formula:
                      <br />
                      For each delinquency bucket: outstanding balance in that
                      bucket / total outstanding balance
                      <br />
                      <br />
                      Write Offs = {company?.writeoff_days} days
                      <br />
                    </span>
                  }
                  placement="right"
                >
                  <InformationCircleIcon className="w-8 text-primary-main cursor-help" />
                </Tooltip>
                {showHistoricalRateIndicator && <ExchangeRateBadge />}
              </div>
              <div className="gap-4 flex flex-col 2xl:flex-row">
                {[
                  ...(showAdjustedToggle
                    ? [
                        {
                          options: [
                            {
                              title: 'Outstanding Balance',
                              active: !isAdjusted,
                              action: () => setIsAdjusted(false),
                            },
                            {
                              title: 'Adjusted Outstanding Balance',
                              active: isAdjusted,
                              action: () => setIsAdjusted(true),
                            },
                          ],
                        },
                      ]
                    : []),
                ].map((group, i) => (
                  <div key={i} className={`flex `}>
                    <div className="bg-neutral-border-1 rounded-md p-1 flex">
                      {group.options.map((g, j) => (
                        <button
                          key={j}
                          className={`block text-sm rounded-md px-8 py-0.5 font-semibold ${
                            g.active ? 'bg-secondary-main text-white' : ''
                          }`}
                          onClick={g.action}
                        >
                          {g.title}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Chart
              loading={isFetching || isProcessing}
              id="outstandingDelinq"
              yLabel="Percentage"
              yFormat="#.0a%"
              data={chartData}
              series={[
                { label: '0 DPD', field: 'obd0' },
                { label: '1-14 DPD', field: 'obd1' },
                { label: '15-29 DPD', field: 'obd15' },
                { label: '30-59 DPD', field: 'obd30' },
                { label: '60-89 DPD', field: 'obd60' },
                { label: '90-119 DPD', field: 'obd90' },
                { label: '120+ DPD', field: 'obd120' },
              ].map(x => ({
                ...x,
                tooltipValueFormat: '#.0a%',
                type: 'ColumnSeries',
                isStack: true,
                field: `${isAdjusted ? 'adjusted_' : ''}${x.field}`,
              }))}
              tooltipSubtitle={
                showHistoricalRateIndicator
                  ? (display_rates ?? []).map((r: any) => {
                      return {
                        field: `fx_${r.code}`,
                        title: `USD:${r.code}`,
                      }
                    })
                  : undefined
              }
              exportable={exportable}
              error={error as { message: string }}
            />
          </>
        }
        stat={
          <StatisticL5
            description="percentage at 90DPD"
            classSetting={{
              up: 'text-danger-hover',
              down: 'text-success-hover',
            }}
            {...growthRatios}
          />
        }
      />
    </div>
  )
}

export default OutstandingDelinqL5
