import React from 'react'

import L4TabsLayout from '@components/layouts/l4-tabs-layout'

import NetIncomeL5 from './net-income'

const NetIncomeL4 = ({ exportable }: { exportable: boolean }) => {
  const tabs = [
    {
      label: 'Net Income',
      desc: "An entity's income minus cost of goods sold, expenses, depreciation and amortization, interest, and taxes for an accounting period",
      component: <NetIncomeL5 exportable={exportable} />,
    },
  ]

  return <L4TabsLayout tabs={tabs} />
}

export default NetIncomeL4
