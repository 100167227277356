/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import useHotjar from 'react-use-hotjar'

import { Auth0Provider } from '@auth0/auth0-react'
import AppRoutes from '@components/app-routes'
import {
  AUTH_AUDIENCE,
  AUTH_CLIENT_ID,
  AUTH_DOMAIN,
  HJID,
  HJSV,
} from '@constants/config'
import AuthContext from '@contexts/auth'
import LanguagesProvider from '@contexts/language/provider'
import Company from '@interfaces/company'
import { LayoutFilter } from '@interfaces/filter'
import { LoadingData } from '@interfaces/loading'
import { UserMetadata } from '@interfaces/user-metadata'
import { ThemeProvider } from '@material-tailwind/react'

import theme from './theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => {
  const { initHotjar } = useHotjar()

  useEffect(() => {
    initHotjar(HJID, HJSV, false, console.info)
  }, [initHotjar])

  const [userMetadata, setUserMetadata] = useState<UserMetadata>()
  const [companyDetails, setCompanyDetails] = useState<Company>()
  const [optionFilters, setOptionFilters] = useState<LayoutFilter>({})
  const [activeFilters, setActiveFilters] = useState<LayoutFilter>({})
  const [appliedFilters, setAppliedFilters] = useState<LayoutFilter>({})
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [showSidemenu, setShowSidemenu] = useState<boolean>(true)
  const [loadingData, setLoadingData] = useState<LoadingData>({})

  return (
    <Auth0Provider
      domain={AUTH_DOMAIN!}
      clientId={AUTH_CLIENT_ID!}
      redirectUri={`${window.location.origin}/auth-callback`}
      audience={AUTH_AUDIENCE}
    >
      <QueryClientProvider client={queryClient}>
        <LanguagesProvider>
          <ThemeProvider value={theme}>
            <AuthContext.Provider
              value={{
                userMetadata: userMetadata,
                setUserMetadata,
                company: companyDetails,
                setCompanyDetails,
                optionFilters,
                setOptionFilters,
                activeFilters,
                setActiveFilters,
                appliedFilters,
                setAppliedFilters,
                showFilter,
                setShowFilter,
                showSidemenu,
                setShowSidemenu,
                loadingData,
                setLoadingData,
              }}
            >
              <AppRoutes />
            </AuthContext.Provider>
          </ThemeProvider>
        </LanguagesProvider>
      </QueryClientProvider>
    </Auth0Provider>
  )
}

export default App
