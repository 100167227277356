import React, { createContext, Dispatch } from 'react'

import { Action, StateType } from './api-states.reducer'

interface ContextProps {
  state: StateType
  dispatch: Dispatch<Action>
}

const CustomContext = createContext({} as ContextProps)

export function useCustomContext() {
  return React.useContext(CustomContext)
}

export default CustomContext
