export enum Status {
  LOADING = 'LOADING',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
}
export enum ValidationTypes {
  HISTORICAL = 'HISTORICAL',
  DATES = 'DATES',
  TEMPLATE = 'TEMPLATE',
  BALANCE_SHEET = 'BALANCE_SHEET',
}
export enum SheetType {
  BALANCE_SHEET = 'Balance Sheet',
  PROFIT_AND_LOSS = 'Profit and Loss',
}
export type ApiParams = {
  slug_name?: string
  entityId?: string
  validationType: ValidationTypes
  sheetType: SheetType
}

export type ValidatorPopUpProps = {
  title: string
  slug_name?: string
  entityId?: string
  entitySlugName: string
}
