import React from 'react'
import moment from 'moment'

import { Activity } from '@interfaces/timeline'

interface TimelineProps {
  activity: Activity[]
}

const Timeline = ({ activity }: TimelineProps) => {
  return (
    <ol className="relative ml-5">
      {activity.map((event: Activity, x: number) => {
        return (
          <li
            key={x}
            className={`${
              activity.length - 1 == x
                ? ''
                : 'border-l-2 border-color-primary-main'
            } border-primary`}
          >
            <div className="absolute -left-4 -mt-6 rounded-full p-2 bg-primary-main text-white">
              {event.icon}
            </div>
            <div className="ml-5 ">
              <div className="flex-start flex items-center">
                <div className="-ml-[9px] -mt-2 mr-3 flex h-4 w-4 items-center justify-center rounded-full bg-primary"></div>
                <p className="-mt-6 text-md font-semibold">{event.title}</p>
              </div>
              <div className="mb-6 ml-5 pb-6">
                <p className="text-sm">{event.desc}</p>
                <p className="text-xs">
                  on {moment.unix(event.time).format('DD-MMM-YYYY')}
                </p>
              </div>
            </div>
          </li>
        )
      })}
    </ol>
  )
}
export default Timeline
