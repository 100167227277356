import React from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, DialogBody, DialogHeader } from '@material-tailwind/react'

import { ACTIONS_TYPES } from '../../pages/administration/sources/accounting/shared/actions'
import { useCustomContext } from '../../pages/administration/sources/accounting/shared/local-context'

import { PropsOverlayContainer } from './interface'

const OverlayContainerDropZone = ({
  children,
  open,
  header,
}: PropsOverlayContainer) => {
  const { dispatch } = useCustomContext()
  return (
    <Dialog
      open={open}
      handler={() => {
        dispatch({
          type: ACTIONS_TYPES.OPEN_DROPZONE,
          payload: { openDropZone: false },
        })
      }}
      className={'overflow-auto z-40  max-w-none min-w-0 w-[35rem]'}
    >
      <DialogHeader>
        <div className={`flex justify-between w-full`}>
          <span className="text-lg font-bold">{header}</span>
          <XMarkIcon
            onClick={() =>
              dispatch({
                type: ACTIONS_TYPES.OPEN_DROPZONE,
                payload: { openDropZone: false },
              })
            }
            className="w-8 h-8 cursor-pointer opacity-50 hover:opacity-20"
          />
        </div>
      </DialogHeader>
      <DialogBody>{children}</DialogBody>
    </Dialog>
  )
}
export default OverlayContainerDropZone
