import React from 'react'

import L4TabsLayout from '@components/layouts/l4-tabs-layout'

import ParL5 from './par'

const PortfolioAtRiskL4 = ({ exportable }: { exportable: boolean }) => {
  const tabs = [
    {
      label: '',
      component: <ParL5 exportable={exportable} />,
    },
  ]

  return <L4TabsLayout tabs={tabs} />
}

export default PortfolioAtRiskL4
