import { useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import { getStaleMins } from '@helpers/stale-timer'
import { CovenantType } from '@interfaces/covenant'
import { CovenantService } from '@services/api-manage/covenant'
import { CustomDataService } from '@services/api-manage/monitor-template-custom-data'

const getDateOptions = (covData: any[]) => {
  const initialDateList = covData?.[0]?.map((e: any) => e.calculation_date)
  return initialDateList
    ?.reduce((acc: any[], date: string) => {
      let found = true
      for (let i = 2; i < covData.length; i++) {
        if (
          covData[i].findIndex((e: any) => e.calculation_date === date) === -1
        ) {
          found = false
          break
        }
      }
      if (found) {
        return [...acc, date]
      } else {
        return acc
      }
    }, [])
    ?.reverse()
}

const covenantNames = {
  'MSME Bonds': [
    'Financial Leverage',
    'Float Equity Contributions',
    'Single Currency Exposure',
    'Tangible Net Worth',
  ],
  Lendable: [
    'Debt To Equity',
    'Float Equity Contribution',
    'Single Currency Exposure',
    'Tangible Net Worth',
  ],
  Norsad: [
    'Current Ratio',
    'Debt To Equity',
    'Float Equity Contribution',
    'Non Hard Currency Exposure',
  ],
  'Symbiotics - Promissory Note': [
    'Double Leverage Ratio',
    'Financial Leverage Ratio Group',
    'Financial Leverage Ratio Guarantor',
    'Foreign Currency Ratio',
    'Tangible Net Worth',
    'Tangible Net Worth Group',
  ],
}

export const useMfsComplianceCertCustomData = (
  facility: string,
  effectiveDate?: string
): [boolean, any] => {
  const [result, setResult] = useState<[boolean, any]>([true, {}])

  const filters = {
    slug_name: 'mfs',
    facility,
    effectiveDate: moment(effectiveDate).format('YYYY-MM-DD'),
  }

  const covenantFilters = {
    slug_name: 'mfs',
    facility,
    date: moment(effectiveDate).format('YYYY-MM-DD'),
    covenant_type: 'financial' as CovenantType,
  }

  const covenantQueries = covenantNames[
    facility as keyof typeof covenantNames
  ].map(covenant_name =>
    useQuery(
      [covenant_name, covenantFilters],
      () => CovenantService.getRawData({ ...covenantFilters, covenant_name }),
      getStaleMins()
    )
  )

  const covenantData = covenantQueries.map(query =>
    !query.isLoading ? query.data : []
  )

  const effectiveDateOptions = covenantQueries.every(query => !query.isLoading)
    ? getDateOptions(covenantData)
    : []

  const { data: customData, isFetching: isFetchingCustomData } = useQuery(
    ['paymentScheduleStatistic', filters],
    () => CustomDataService.getMfsComplianceData(filters),
    {
      ...getStaleMins(),
    }
  )

  const isFetching =
    isFetchingCustomData || covenantQueries.some(query => query.isLoading)

  useEffect(() => {
    !isFetching &&
      setResult([false, { ...customData, covenantData, effectiveDateOptions }])
  }, [isFetching])

  return result
}
