import React, { useLayoutEffect } from 'react'

import * as am5 from '@amcharts/amcharts5'
import * as am5radar from '@amcharts/amcharts5/radar'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import Typography from '@components/atoms/typography'
import { greenToRed } from '@helpers/green-to-red'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid'

interface RadarChartProps {
  value: string
  color?: string
  subtitle?: string
  percent: number
  id?: string
}

const RadarChart = ({
  value,
  color: color_props,
  subtitle,
  percent,
  id,
}: RadarChartProps) => {
  const color =
    color_props ?? greenToRed(100 - percent - (percent >= 50 ? 20 : 0), true)

  useLayoutEffect(() => {
    const root = am5.Root.new(id ?? 'radar-chart')
    root.setThemes([am5themes_Animated.new(root)])
    root.container._settings.paddingTop = -24
    root.container._settings.paddingBottom = -24

    const chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360,
      })
    )

    const axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -40,
    })

    axisRenderer.labels.template.setAll({
      visible: false,
    })

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: axisRenderer,
      })
    )

    chart.bulletsContainer.set('mask', undefined)

    const axisRange0 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 0,
        endValue: percent,
      })
    )

    axisRange0.get('axisFill')?.setAll({
      visible: true,
      fill: am5.color(color.toString()),
    })

    axisRange0.get('label')?.setAll({
      forceHidden: true,
    })

    const axisRange1 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: percent,
        endValue: 100,
      })
    )

    axisRange1.get('axisFill')?.setAll({
      visible: true,
      fill: am5.color('#E4E7EC'),
    })

    axisRange1.get('label')?.setAll({
      forceHidden: true,
    })

    const axisRange2 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 49.75,
        endValue: 50.25,
      })
    )

    axisRange2.get('axisFill')?.setAll({
      visible: true,
      fill: am5.color('#FF0000'),
    })

    axisRange2.get('label')?.setAll({
      forceHidden: true,
    })

    root?._logo?.dispose()
    return () => {
      root?.dispose()
    }
  }, [value, percent])

  const Icon = percent >= 50 ? CheckCircleIcon : ExclamationTriangleIcon

  return (
    <>
      <div className="relative">
        <div
          id={id ?? 'radar-chart'}
          className={`mt-6 w-full min-h-[150px]`}
        ></div>
        <div className="flex flex-col items-center justify-center absolute bottom-0 w-full">
          {subtitle && (
            <Typography className="text-sm text-neutral-black">
              {subtitle}
            </Typography>
          )}
          <div className="flex">
            <Icon
              className="w-5 h-5 mr-2"
              style={{ color: color.toString() }}
            />
            <Typography className="text-base font-medium text-neutral-black">
              {value}
            </Typography>
          </div>
        </div>
      </div>
    </>
  )
}

export default RadarChart
