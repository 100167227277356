import React from 'react'

import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'

import MonitorDashboardGroupedGraphItem from './items/grouped-graph'
import MonitorDashboardLineGraphItem from './items/line-graph'
import MonitorDashboardMultilineGraphItem from './items/multiline-graph'
import MonitorDashboardStackedGraphItem from './items/stacked-graph'
import CovenantL4 from './covenant'

const MonitorDashboardTabL4 = ({ items }: { items: DashboardResponse[] }) => {
  const _renderItem = (i: DashboardResponse) => {
    switch (i.display) {
      case 'line_graph':
        return <MonitorDashboardLineGraphItem item={i} />
      case 'multiline_graph':
        return <MonitorDashboardMultilineGraphItem item={i} />
      case 'stacked_graph':
        return <MonitorDashboardStackedGraphItem item={i} />
      case 'grouped_graph':
        return <MonitorDashboardGroupedGraphItem item={i} />
      case 'covenant':
        return <CovenantL4 type={i.display_type as any} />
      default:
        return <></>
    }
  }
  return (
    <div className="flex flex-col gap-8">{items.map(i => _renderItem(i))}</div>
  )
}
export default MonitorDashboardTabL4
