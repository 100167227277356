import apiClient from './client'

class ExchangeService {
  static async getRate({
    from_currency,
    to_currency,
    date,
    slug_name,
  }: {
    from_currency: string
    to_currency: string
    date: string
    slug_name?: string
  }) {
    return apiClient.post<{ rate_to: number; rate_from: number }>(
      `currency-exchange/rate`,
      {
        from_currency,
        to_currency,
        date,
        slug_name,
      }
    )
  }

  static getLatestISOCode = (code: string) => {
    /**
     * @description
     * Some currency codes has historical changes.
     * We need to make sure, code we're using to match with our fixer db pool is the latest one
     * @todo
     * Put this history or API/lib that possible to replace this function
     * https://en.wikipedia.org/wiki/ISO_4217#Historical_currency_codes
     */
    switch (code) {
      case 'GHC':
        return 'GHS'
      default:
        return code
    }
  }

  static async getHistoricalRate({
    slug_name,
    currencies,
    default_currency,
  }: {
    slug_name: string
    currencies: string[]
    default_currency?: string
  }) {
    const { data } = await apiClient.post(`exchange-rate/historical`, {
      slug_name,
      currencies,
    })

    if (default_currency === 'USD') {
      return data
    } else {
      const USD = data.find((x: any) => x.code === 'USD')
      const { rates, display_rates } = USD
      const resp = data.map((x: any) =>
        x.code === default_currency ? { ...x, rates, display_rates } : x
      )

      return resp
    }
  }
}

export default ExchangeService
