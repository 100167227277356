import { useEffect, useState } from 'react'
import moment from 'moment'

import { remappedHeaders } from '../../../constants'
import { SheetType } from '../../../interfaces/validation.interface'

const useValidationTable = (
  data: Record<string, any>,
  sheetType: SheetType
) => {
  const headers = remappedHeaders[sheetType]

  const [currentData, setCurrentData] = useState<Record<string, any>[]>([])
  const [incomingData, setIncomingData] = useState<Record<string, any>[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [updateClicked, setUpdateClicked] = useState<boolean>(false)
  const [updatedRecords, setUpdatedRecords] = useState<Record<string, any>[]>(
    []
  )

  useEffect(() => {
    setCurrentData(data?.data?.current)
    setIncomingData(data?.data?.incoming)
    setCurrentIndex(0)
    setUpdateClicked(false)
  }, [data])
  const getMatchingRecords = (): Record<string, any>[] => {
    const matchingRecords: Record<string, any>[] = []

    currentData.forEach((currentRecord: Record<string, any>) => {
      const matchingRecord = incomingData.find(
        (incomingRecord: Record<string, any>) => {
          const incomingDate = moment(incomingRecord.data_date)
            .endOf('month')
            .format('YYYY-MM-DD')
          const currentDate = moment(currentRecord?.data_date)
            .endOf('month')
            .format('YYYY-MM-DD')
          return incomingDate === currentDate
        }
      )
      if (matchingRecord) {
        matchingRecords.push(matchingRecord)
      }
    })

    return matchingRecords
  }

  const handleUpdateClick = () => {
    const matchingRecords = getMatchingRecords()
    setUpdatedRecords(matchingRecords)
    setCurrentData(matchingRecords)
  }

  const hasUpdatedRecords = (): boolean => {
    return updatedRecords.length > 0
  }

  return {
    headers,
    currentData,
    incomingData,
    currentIndex,
    updateClicked,
    handleUpdateClick,
    hasUpdatedRecords,
    setCurrentIndex,
    updatedRecords,
  }
}
export default useValidationTable
