import React from 'react'
import { Moment } from 'moment'
import Picker from 'rc-picker/'
import generateConfig from 'rc-picker/lib/generate/moment'
import { PickerMode } from 'rc-picker/lib/interface'
import enUS from 'rc-picker/lib/locale/en_US'

import {
  CalendarIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline'

interface DatePickerProps {
  value: Moment
  onChange: (date: Moment | null) => void
  picker?: PickerMode
  disabledDate?: (current: Moment) => boolean
  disabled?: boolean
  allowClear?: boolean
}

const DatePicker = ({
  value,
  onChange,
  picker,
  disabledDate,
  disabled = false,
  allowClear = false,
}: DatePickerProps) => {
  return (
    <Picker<Moment>
      generateConfig={generateConfig}
      locale={enUS}
      picker={picker}
      value={value}
      onChange={onChange}
      suffixIcon={<CalendarIcon className="w-4 h-4" />}
      superPrevIcon={<ChevronDoubleLeftIcon className="w-3 h-3" />}
      superNextIcon={<ChevronDoubleRightIcon className="w-3 h-3" />}
      className="cd-date-picker"
      dropdownClassName="cd-date-picker__dropdown"
      disabledDate={disabledDate}
      disabled={disabled}
      allowClear={allowClear}
    />
  )
}

export default DatePicker
