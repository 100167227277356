import React from 'react'

import { CovenantListResponse, CovenantType } from '@interfaces/covenant'

import CovenantL4SummaryDefaultOverview from './overview-default'
import CovenantL4SummaryVintageOverview from './overview-vintage'

interface CovenantL4SummaryOverviewProps {
  covenants: CovenantListResponse[]
  type: CovenantType
  link?: string
}
const CovenantL4SummaryOverview = ({
  covenants,
  type,
  link,
}: CovenantL4SummaryOverviewProps) => {
  const conv = covenants?.[0]
  const _overviewRenderer = () => {
    switch (conv?.special_covenant) {
      case 'vintage':
        return (
          <CovenantL4SummaryVintageOverview
            covenants={covenants}
            type={type}
            link={link}
          />
        )
      default:
        return (
          <CovenantL4SummaryDefaultOverview
            covenants={covenants}
            type={type}
            link={link}
          />
        )
    }
  }

  return _overviewRenderer()
}

export default CovenantL4SummaryOverview
