import React from 'react'
import moment from 'moment'

import FormInput from '@components/form/form-input'
import { CategoriesResult } from '@interfaces/manage-reporting'

interface Props {
  category: CategoriesResult
  form: any
  certificateData?: Record<string, any>
  customData?: Record<string, any>
}

export const CustomFields = ({
  category,
  form,
  certificateData,
  customData,
}: Props) => {
  const {
    getValues,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = form

  const customFields = category?.custom_fields
    ? JSON.parse(category.custom_fields)
    : undefined

  return (
    !!customFields?.length &&
    customFields.map((field: any, index: number) => {
      const fieldName = field.label.toLowerCase().replaceAll(' ', '-')
      switch (field.type) {
        case 'date':
          return (
            <FormInput
              type="date"
              label={{ start: field.label }}
              value={
                getValues(fieldName) ||
                setValue(fieldName, moment(), {
                  shouldValidate: true,
                })
              }
              onSelected={val =>
                setValue(fieldName, val, {
                  shouldValidate: true,
                })
              }
              {...register(fieldName, {
                required: field.required ? `${field.label} is required` : false,
                disabled: isSubmitting,
              })}
              error={errors?.[fieldName]?.message as string}
            />
          )
        case 'select':
          const dateOptions =
            field.source === 'customData' && customData?.[field.values]
              ? customData[field.values].map((d: string) => ({
                  value: d,
                  title: d,
                }))
              : certificateData?.[field.values]
              ? certificateData[field.values]?.map((d: string) => ({
                  value: d,
                  title: d,
                }))
              : []
          return (
            <FormInput
              type="select"
              label={{ start: field.label }}
              options={dateOptions}
              value={getValues(fieldName) || undefined}
              onSelected={val =>
                setValue(fieldName, val, { shouldValidate: true })
              }
              {...register(fieldName, {
                required: field.required ? `${field.label} is required` : false,
                disabled: isSubmitting,
              })}
              error={errors?.[fieldName]?.message as string}
            />
          )
        default:
          return (
            <FormInput
              key={`${field.label}-${index}`}
              type={field.type}
              label={{ start: field.label }}
              value={getValues(fieldName) || ''}
              {...register(fieldName, {
                required: field.required ? `${field.label} is required` : false,
                disabled: isSubmitting,
                onChange: (e: any) => {
                  setValue(
                    fieldName,
                    field.type === 'checkbox'
                      ? e.target.checked
                      : e.target.value,
                    {
                      shouldValidate: true,
                    }
                  )
                },
              })}
              error={errors?.[fieldName]?.message as string}
            />
          )
      }
    })
  )
}
