import { mfsTableRow } from '../../../../components/mfs'
import {
  formatMoney,
  formatPercent,
  parseValue,
} from '../mfs-compliance-cert-helpers'

export const generateTableData = (vars: any, data: any) => {
  const covenantData = data?.covenantData?.map((e: any) =>
    e.find((e: any) => e.calculation_date === vars['effective-date'])
  )

  const calcs: Record<string, any> = {}
  calcs['table-1-a'] = parseValue(covenantData?.[3]?.total_assets, 'number')
  calcs['table-1-b'] = parseValue(
    covenantData?.[3]?.intangible_assets,
    'number'
  )
  calcs['table-1-c'] = parseValue(
    covenantData?.[3]?.total_liabilities,
    'number'
  )
  calcs['table-1-d'] = parseValue(
    covenantData?.[3]?.tangible_net_worth,
    'number'
  )
  calcs['table-1-e'] = calcs['table-1-d'] >= 3000000

  calcs['table-2-a'] = parseValue(
    covenantData?.[0]?.total_liabilities,
    'number'
  )
  calcs['table-2-b'] = parseValue(covenantData?.[0]?.paidin_capital, 'number')
  calcs['table-2-c'] = parseValue(
    covenantData?.[0]?.retained_earnings,
    'number'
  )
  calcs['table-2-d'] = parseValue(covenantData?.[0]?.debt_to_equity, 'percent')
  calcs['table-2-e'] = calcs['table-2-d'] <= 200

  calcs['table-3-a'] = parseValue(covenantData?.[1]?.total_float_usd, 'number')
  calcs['table-3-b'] = parseValue(covenantData?.[1]?.total_float_eur, 'number')
  calcs['table-3-c'] = parseValue(covenantData?.[1]?.total_float_gbp, 'number')
  calcs['table-3-d'] = parseValue(covenantData?.[1]?.total_float_xof, 'number')
  calcs['table-3-e'] = parseValue(covenantData?.[1]?.total_float_xaf, 'number')
  calcs['table-3-f'] = parseValue(covenantData?.[1]?.total_float_ghs, 'number') // may need to discount
  calcs['table-3-g'] = parseValue(covenantData?.[1]?.total_float_ugx, 'number') // may need to discount
  calcs['table-3-h'] = parseValue(covenantData?.[1]?.total_float_kes, 'number') // may need to discount
  calcs['table-3-i'] = covenantData?.[1]?.fx_rate_eur ?? 1
  calcs['table-3-j'] = covenantData?.[1]?.fx_rate_gbp ?? 1
  calcs['table-3-k'] = covenantData?.[1]?.fx_rate_xof ?? 1
  calcs['table-3-l'] = covenantData?.[1]?.fx_rate_xaf ?? 1
  calcs['table-3-m'] = covenantData?.[1]?.fx_rate_ghs ?? 1
  calcs['table-3-n'] = covenantData?.[1]?.fx_rate_ugx ?? 1
  calcs['table-3-o'] = covenantData?.[1]?.fx_rate_kes ?? 1
  calcs['table-3-p'] = covenantData?.[1]?.total_eur ?? 0
  calcs['table-3-q'] = covenantData?.[1]?.total_gbp ?? 0
  calcs['table-3-r'] = covenantData?.[1]?.total_xof ?? 0
  calcs['table-3-s'] = covenantData?.[1]?.total_xaf ?? 0
  calcs['table-3-t'] = covenantData?.[1]?.total_ghs ?? 0
  calcs['table-3-u'] = covenantData?.[1]?.total_ugx ?? 0
  calcs['table-3-v'] = covenantData?.[1]?.total_kes ?? 0
  calcs['table-3-w'] = covenantData?.[1]?.total_hard_currency_usd ?? 0
  calcs['table-3-x'] = covenantData?.[1]?.total_non_hard_currency_usd ?? 0
  calcs['table-3-y'] = covenantData?.[1]?.float_prefunded_by_counterparties ?? 0
  calcs['table-3-z'] = covenantData?.[1]?.total_float ?? 0
  calcs['table-3-aa'] =
    covenantData?.[1]?.principal_outstanding_of_symbiotics_and_lendable_loans ??
    0
  calcs['table-3-ab'] = (1 - calcs['table-3-aa'] / calcs['table-3-z']) * 100
  calcs['table-3-ac'] = calcs['table-3-ab'] >= 15

  calcs['table-4-a'] = parseValue(covenantData?.[2]?.total_ghs, 'number')
  calcs['table-4-b'] = parseValue(covenantData?.[2]?.total_ugx, 'number')
  calcs['table-4-c'] = parseValue(covenantData?.[2]?.total_kes, 'number')
  calcs['table-4-d'] = parseValue(covenantData?.[2]?.total_float, 'number')
  calcs['table-4-e'] = parseValue(covenantData?.[2]?.ghs_percentage, 'percent')
  calcs['table-4-f'] = parseValue(covenantData?.[2]?.ugx_percentage, 'percent')
  calcs['table-4-g'] = parseValue(covenantData?.[2]?.kes_percentage, 'percent')
  calcs['table-4-h'] = parseValue(
    covenantData?.[2]?.single_currency_exposure,
    'percent'
  )
  calcs['table-4-i'] = calcs['table-4-h'] < 33

  return [
    {
      title: 'Tangible Net Worth',
      tableWidth: '100%',
      wrap: false,
      data: [
        mfsTableRow(
          { content: 'a' },
          {
            content:
              "Total consolidated value of all assets recognised on the Borrower Group's balance sheet",
          },
          { content: `USD ${formatMoney(calcs['table-1-a'])}` }
        ),
        mfsTableRow(
          { content: 'b' },
          {
            content:
              "Total consolidated value of intangible assets recognised on the Borrower Group's balance sheet",
          },
          { content: `USD ${formatMoney(calcs['table-1-b'])}` }
        ),
        mfsTableRow(
          { content: 'c' },
          {
            content:
              'Total consolidated value of all liabilities of the Borrower Group (for the avoidance of doubt, anything which falls within the definition of Equity shall not be considered a liability)',
          },
          { content: `USD ${formatMoney(calcs['table-1-c'])}` }
        ),
        mfsTableRow(
          { content: 'd' },
          {
            content: 'Difference of a - b – c',
          },
          { content: `USD ${formatMoney(calcs['table-1-d'])}` }
        ),
        mfsTableRow(
          { content: 'e' },
          {
            content: 'Target result of d (Greater than or equal to )',
          },
          {
            content: `USD ${formatMoney(3000000)}`,
            bold: true,
            style: {
              container: {
                backgroundColor: calcs['table-1-e'] ? '#00b050' : 'red',
              },
            },
          }
        ),
      ],
    },
    {
      title: 'Financial Leverage',
      tableWidth: '100%',
      wrap: false,
      data: [
        mfsTableRow(
          { content: 'a' },
          {
            content:
              'Total consolidated Financial Indebtedness of the Borrower Group that ranks senior or pari passu to the Facility',
          },
          { content: `USD\n${formatMoney(calcs['table-2-a'])}` }
        ),
        mfsTableRow(
          { content: 'b' },
          {
            content:
              'Total consolidated paid up share capital of the Borrower Group',
          },
          { content: `USD\n${formatMoney(calcs['table-2-b'])}` }
        ),
        mfsTableRow(
          { content: 'c' },
          {
            content:
              'Total consolidated retained earnings of the Borrower Group',
          },
          { content: `USD\n${formatMoney(calcs['table-2-c'])}` }
        ),
        mfsTableRow(
          { content: 'd' },
          { content: 'Ratio of a / (b + c)' },
          { content: `${formatPercent(calcs['table-2-d'])}` }
        ),
        mfsTableRow(
          { content: 'e' },
          {
            content: 'Target result of d (Less than or equal to)',
          },
          {
            content: formatPercent(200),
            bold: true,
            style: {
              container: {
                backgroundColor: calcs['table-2-e'] ? '#00b050' : 'red',
              },
            },
          }
        ),
      ],
    },
    {
      title: 'Float Equity Contribution',
      tableWidth: '100%',
      wrap: true,
      data: [
        mfsTableRow(
          { content: 'a' },
          { content: 'Total value of Float held in USD' },
          { content: formatMoney(calcs['table-3-a']) }
        ),
        mfsTableRow(
          { content: 'b' },
          { content: 'Total value of Float held in EUR' },
          { content: formatMoney(calcs['table-3-b']) }
        ),
        mfsTableRow(
          { content: 'c' },
          { content: 'Total value of Float held in GBP' },
          { content: formatMoney(calcs['table-3-c']) }
        ),
        mfsTableRow(
          { content: 'd' },
          { content: 'Total value of Float held in XOF' },
          { content: formatMoney(calcs['table-3-d']) }
        ),
        mfsTableRow(
          { content: 'e' },
          { content: 'Total value of Float held in XAF' },
          { content: formatMoney(calcs['table-3-e']) }
        ),
        mfsTableRow(
          { content: 'f' },
          { content: 'Total value of Float held in GHS (discounted by 10%)' },
          { content: formatMoney(calcs['table-3-f']) }
        ),
        mfsTableRow(
          { content: 'g' },
          { content: 'Total value of Float held in UGX (discounted by 10%)' },
          { content: formatMoney(calcs['table-3-g']) }
        ),
        mfsTableRow(
          { content: 'h' },
          { content: 'Total value of Float held in KES (discounted by 10%)' },
          { content: formatMoney(calcs['table-3-h']) }
        ),
        mfsTableRow(
          { content: 'i' },
          { content: 'FX spot conversion rate of EUR to USD' },
          {
            content: calcs['table-3-i'],
            style: { color: '#5b9bd5' },
          }
        ),
        mfsTableRow(
          { content: 'j' },
          { content: 'FX spot conversion rate of GBP to USD' },
          {
            content: calcs['table-3-j'],
            style: { color: '#5b9bd5' },
          }
        ),
        mfsTableRow(
          { content: 'k' },
          { content: 'FX spot conversion rate of XOF to USD' },
          {
            content: calcs['table-3-k'],
            style: { color: '#5b9bd5' },
          }
        ),
        mfsTableRow(
          { content: 'l' },
          { content: 'FX spot conversion rate of XAF to USD' },
          {
            content: calcs['table-3-l'],
            style: { color: '#5b9bd5' },
          }
        ),
        mfsTableRow(
          { content: 'm' },
          { content: 'FX spot conversion rate of GHS to USD' },
          {
            content: calcs['table-3-m'],
            style: { color: '#5b9bd5' },
          }
        ),
        mfsTableRow(
          { content: 'n' },
          { content: 'FX spot conversion rate of UGX to USD' },
          {
            content: calcs['table-3-n'],
            style: { color: '#5b9bd5' },
          }
        ),
        mfsTableRow(
          { content: 'o' },
          { content: 'FX spot conversion rate of KES to USD' },
          {
            content: calcs['table-3-o'],
            style: { color: '#5b9bd5' },
          }
        ),
        mfsTableRow(
          { content: 'p' },
          { content: 'USD equivalent value of EUR Float: Product of b * i' },
          { content: formatMoney(calcs['table-3-p']) }
        ),
        mfsTableRow(
          { content: 'q' },
          { content: 'USD equivalent value of GBP Float: Product of c * j' },
          { content: formatMoney(calcs['table-3-q']) }
        ),
        mfsTableRow(
          { content: 'r' },
          { content: 'USD equivalent value of XOF Float: Product of d * k' },
          { content: formatMoney(calcs['table-3-r']) }
        ),
        mfsTableRow(
          { content: 's' },
          { content: 'USD equivalent value of XAF Float: Product of e * l' },
          { content: formatMoney(calcs['table-3-s']) }
        ),
        mfsTableRow(
          { content: 't' },
          { content: 'USD equivalent value of GHS Float: Product of f * m' },
          { content: formatMoney(calcs['table-3-t']) }
        ),
        mfsTableRow(
          { content: 'u' },
          { content: 'USD equivalent value of UGX Float: Product of g * n' },
          { content: formatMoney(calcs['table-3-u']) }
        ),
        mfsTableRow(
          { content: 'v' },
          { content: 'USD equivalent value of KES Float: Product of h * o' },
          { content: formatMoney(calcs['table-3-v']) }
        ),
        mfsTableRow(
          { content: 'w' },
          {
            content:
              'Total USD value of Hard Currency Float: a + p + q + r + s',
          },
          { content: formatMoney(calcs['table-3-w']) }
        ),
        mfsTableRow(
          { content: 'x' },
          {
            content:
              '(Total USD value of Eligible Currency Float which is not Hard Currency: t + u + v)',
          },
          { content: formatMoney(calcs['table-3-x']) }
        ),
        mfsTableRow(
          { content: 'y' },
          {
            content:
              'USD Value of Float prefunded by counterparties (USD equivalent)',
          },
          { content: calcs['table-3-y'] }
        ),
        mfsTableRow(
          { content: 'z' },
          {
            content: 'Total Float Value: w+x-y',
          },
          { content: formatMoney(calcs['table-3-z']) }
        ),
        mfsTableRow(
          { content: 'aa' },
          {
            content:
              'The aggregate principal outstanding of the Facility and any other Financial Indebtedness of the Borrower Group that ranks pari passu to the Facility (including any further Advances requested in the Drawdown Notice (where applicable))',
          },
          { content: formatMoney(calcs['table-3-aa']) }
        ),
        mfsTableRow(
          { content: 'ab' },
          {
            content: 'Float Equity Contribution: 1 - (aa / z)\n[Target >= 15%',
          },
          { content: formatPercent(calcs['table-3-ab']) }
        ),
        mfsTableRow(
          { content: 'ac' },
          { content: 'Target result of ab (Greater than or equal to)' },
          {
            content: formatPercent(15),
            bold: true,
            style: {
              container: {
                backgroundColor: calcs['table-3-ac'] ? '#00b050' : 'red',
              },
            },
          }
        ),
      ],
    },
    {
      title: 'Single Currency Exposure',
      tableWidth: '100%',
      wrap: false,
      data: [
        mfsTableRow(
          { content: 'a' },
          { content: 'USD equivalent value of GHS Float: 3t' },
          { content: formatMoney(calcs['table-4-a']) }
        ),
        mfsTableRow(
          { content: 'b' },
          { content: 'USD equivalent value of UGX Float: 3u' },
          { content: formatMoney(calcs['table-4-b']) }
        ),
        mfsTableRow(
          { content: 'c' },
          { content: 'USD equivalent value of KES Float: 3v' },
          { content: formatMoney(calcs['table-4-c']) }
        ),
        mfsTableRow(
          { content: 'd' },
          {
            content: 'Total USD value of Eligible Currency Float: 3z',
          },
          { content: formatMoney(calcs['table-4-d']) }
        ),
        mfsTableRow(
          { content: 'e' },
          {
            content: 'GHS percentage float exposure: Ratio of a / d',
          },
          { content: formatPercent(calcs['table-4-e']) }
        ),
        mfsTableRow(
          { content: 'f' },
          { content: 'UGX percentage float exposure: Ratio of b / d' },
          { content: formatPercent(calcs['table-4-f']) }
        ),
        mfsTableRow(
          { content: 'g' },
          { content: 'KES percentage float exposure: Ratio of c / d' },
          { content: formatPercent(calcs['table-4-g']) }
        ),
        mfsTableRow(
          { content: 'h' },
          { content: 'Exposure (e+f+g)' },
          { content: formatPercent(calcs['table-4-h']) }
        ),
        mfsTableRow(
          { content: 'i' },
          {
            content: 'Target e,f,g exposure should be less than 33%',
          },
          {
            content: formatPercent(33),
            bold: true,
            style: {
              container: {
                backgroundColor: calcs['table-4-i'] ? '#00b050' : 'red',
              },
            },
          }
        ),
      ],
    },
  ]
}
