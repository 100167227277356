import { ACTIONS } from '../constants'

import { ACTIONS_TYPES } from './actions'
export type Action = {
  type: string
  payload?: StateType
}

type ModalPayload = {
  title?: string
  actions?: ACTIONS
  isOpenModal?: boolean
}
type Validation = {
  entityId?: string
  entitySlugName?: string
}

export type StateType = {
  loading?: boolean
  message?: string
  reload?: boolean
  error?: string
  isSuccess?: boolean
  isOpenAlert?: boolean
  openDropZone?: boolean
  openValidationModal?: boolean
  modalPayload?: ModalPayload
  schemaName?: string
  data?: Record<string, any>
  validation?: Validation
}

const initialState: StateType = {
  loading: false,
  message: '',
  reload: false,
  isSuccess: true,
  isOpenAlert: false,
  openDropZone: false,
  openValidationModal: false,
  modalPayload: {
    isOpenModal: false,
  },
}

export const apiReducer = (
  state: StateType = initialState,
  action: Action
): StateType => {
  const { type, payload } = action

  switch (type) {
    case ACTIONS_TYPES.FETCH_LOADING:
      return {
        ...state,
        loading: true,
        message: payload?.message || '',
      }
    case ACTIONS_TYPES.FETCH_SUCCESS:
      return {
        ...state,
        reload: true,
        message: payload?.message || '',
        openDropZone: false,
        openValidationModal: false,
        modalPayload: {
          isOpenModal: false,
        },
      }

    case ACTIONS_TYPES.FETCH_ERROR:
      return {
        ...state,
        isSuccess: payload?.isSuccess || false,
        reload: payload?.reload || false,
        message: payload?.message || '',
        modalPayload: {
          isOpenModal: false,
        },
      }
    case ACTIONS_TYPES.UPDATE_UI:
      return {
        ...state,
        reload: payload?.reload || false,
        message: payload?.message || '',
      }
    case ACTIONS_TYPES.OPEN_MODAL:
      return {
        ...state,
        modalPayload: {
          title: payload?.modalPayload?.title,
          actions: payload?.modalPayload?.actions,
          isOpenModal: !!payload?.modalPayload?.isOpenModal,
        },
      }
    case ACTIONS_TYPES.OPEN_ALERT:
      return {
        ...state,
        isOpenAlert: payload?.isOpenAlert || false,
        message: payload?.message || '',
        isSuccess: payload?.isSuccess || false,
        openDropZone: false,
        modalPayload: {
          isOpenModal: false,
        },
      }
    case ACTIONS_TYPES.OPEN_DROPZONE:
      return {
        ...state,
        openDropZone: payload?.openDropZone || false,
      }
    case ACTIONS_TYPES.RESET_RELOAD_STATE:
      return {
        ...state,
        reload: payload?.reload || false,
      }
    case ACTIONS_TYPES.OPEN_VALIDATION_MODAL:
      return {
        ...state,
        openValidationModal: payload?.openValidationModal || false,
        data: payload?.data,
        validation: payload?.validation,
        reload: payload?.reload,
        openDropZone: false,
        modalPayload: {
          isOpenModal: false,
        },
      }

    default:
      return state
  }
}
