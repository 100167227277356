import React, { useContext, useEffect } from 'react'
import moment, { Moment } from 'moment'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { LayoutFilter } from '@interfaces/filter'
import { Option, Select } from '@material-tailwind/react'
import { BorrowingBaseService } from '@services/api-manage/borrowing-base'

const FilterCalculationDate = () => {
  const {
    company,
    optionFilters,
    setOptionFilters,
    activeFilters,
    setActiveFilters,
    appliedFilters,
  } = useContext(AuthContext)

  const { activeDebtDeal = 0, calculationDate } = activeFilters
  const { calculationDate: appliedCalculationDate } = appliedFilters
  const { debtDealOptions = [], calculationDateOption = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const params = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility ?? '',
  }

  const { data, isFetching, isError } = useQuery(
    ['validDate', params],
    () => BorrowingBaseService.getValidDate(params),
    {
      ...getStaleMins(),
      enabled: !!activeFacility,
    }
  )

  const onChange = (value: any) => {
    const _v = moment.utc(value)
    const lastDateOfMonth = data?.reduce((p: Moment | undefined, c: any) => {
      const current = moment.utc(c.calculation_date)
      return current.format('YYYY-MM') === _v.format('YYYY-MM') &&
        (!p || current.isAfter(p))
        ? current
        : p
    }, undefined)

    const isLastCalculationDateOfMonth = _v.isSame(lastDateOfMonth)

    setActiveFilters((prev: LayoutFilter) => ({
      ...prev,
      calculationDate: value,
      isLastCalculationDateOfMonth,
    }))
  }

  useEffect(() => {
    if ((data ?? []).length > 0) {
      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        calculationDate: data?.[0]?.calculation_date,
        initialDate: data?.[0]?.calculation_date,
      }))
      setOptionFilters((prev: LayoutFilter) => ({
        ...prev,
        calculationDateOption: data,
      }))
    }
  }, [data])

  useEffect(() => {
    setActiveFilters((prev: LayoutFilter) => ({
      ...prev,
      calculationDateIsFetching: isFetching,
    }))
  }, [isFetching])

  useEffect(() => {
    if (isError) {
      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        calculationDate: null,
        initialDate: null,
      }))
      setOptionFilters((prev: LayoutFilter) => ({
        ...prev,
        calculationDateOption: null,
      }))
    }
  }, [isError])

  useEffect(() => {
    setOptionFilters((prev: LayoutFilter) => ({
      ...prev,
      appliedCalculationDateOption: calculationDateOption,
    }))
  }, [appliedCalculationDate])

  return (
    <div className="filter__calcuation-date flex flex-col w-full gap-4">
      <div className="grid grid-cols-2 justify-between items-center gap-4">
        <Typography
          variant="b2"
          className="font-semibold text-neutral-body-2 flex-1"
        >
          Calculation Date
        </Typography>
        {!isFetching ? (
          <Select
            className="[&~ul]:max-h-[100px]"
            onChange={onChange}
            selected={() => {
              return <span>{calculationDate ?? '-'}</span>
            }}
          >
            {(data ?? []).map(
              ({ calculation_date }: { calculation_date: string }, i) => (
                <Option key={i} value={calculation_date}>
                  {calculation_date}
                </Option>
              )
            )}
          </Select>
        ) : (
          <Typography className="text-sm leading-[40px]">
            Loading Dates
          </Typography>
        )}
      </div>
    </div>
  )
}
export default FilterCalculationDate
