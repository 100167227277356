export const generateTableData = (vars: any, data: any) => {
  const formatMoney = (value: number) => {
    return typeof value === 'number' && !isNaN(value)
      ? `US$${Intl.NumberFormat(undefined, {
          style: 'decimal',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(value ?? 0)}`
      : 'US$[*]'
  }

  const formatPercent = (value: number) => {
    return typeof value === 'number' && !isNaN(value)
      ? `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          maximumFractionDigits: 2,
          notation: 'compact',
        }).format(value ?? 0)}%`
      : '[*]%'
  }

  return [
    {
      title: 'LTV',
      tableWidth: '100%',
      footnote:
        '*Any Advance counted as part of (b) must be held in an account secured to the Finance Parties under the Transaction Security and the Facility Agent must have view access rights to the account(s)',
      data: [
        [
          { width: '10%', content: 'a', position: 'left' as const },
          {
            width: '55%',
            content: 'The aggregate principal outstanding on the Facility',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(data.aggregatePrincipalOutstanding),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'b', position: 'left' as const },
          {
            width: '55%',
            content:
              'The USD-Equivalent value of any Advance which has not yet been applied to the Purpose for a period of 60 days*',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(data.advanceRequestSum),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'c', position: 'left' as const },
          {
            width: '55%',
            content:
              'The USD-Equivalent of the principal value of all receivables secured under the Secured Portfolio that are not more than ninety (90) days past their original due date',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(data.totalReceivables),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'd', position: 'left' as const },
          {
            width: '55%',
            content:
              "The USD-Equivalent of 80% of the market value of all repossessed vehicles in the Borrower's possession, to which it holds legal title and that are secured under the Secured Portfolio",
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(data.repossessedPoolBalance),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'e', position: 'left' as const },
          {
            width: '55%',
            content: 'FX spot conversion rate of USD to [●]',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: `USD:UGX: ${data.UGXRate || '[*]'}\nUSD:XOF: ${
              data.XOFRate || '[*]'
            }`,
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'f', position: 'left' as const },
          {
            width: '55%',
            content: '(a - b) / (c + d)',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatPercent(
              ((data.aggregatePrincipalOutstanding - data.advanceRequestSum) /
                (data.totalReceivables + data.repossessedPoolBalance)) *
                100
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'g', position: 'left' as const },
          {
            width: '55%',
            content: 'Target result of f',
            bold: true,
            position: 'left' as const,
          },
          {
            width: '35%',
            content: '<= 80%',
            bold: true,
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'h', position: 'left' as const },
          {
            width: '55%',
            content:
              'Target result of (f) if Step up 1 achieved (see definition)',
            bold: true,
            position: 'left' as const,
          },
          {
            width: '35%',
            content: '<= 85%',
            bold: true,
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'i', position: 'left' as const },
          {
            width: '55%',
            content:
              'Target result of (f) if Step up 2 achieved (see definition)',
            bold: true,
            position: 'left' as const,
          },
          {
            width: '35%',
            content: '<= 90%',
            bold: true,
            position: 'left' as const,
          },
        ],
      ],
    },
    {
      title: 'Debt To Equity',
      tableWidth: '100%',
      data: [
        [
          { width: '10%', content: 'a', position: 'left' as const },
          {
            width: '55%',
            content:
              'Total Financial Indebtedness of the Group on a consolidated basis (including any guarantees)',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(
              parseFloat(vars['total-financial-indebtedness'])
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'b', position: 'left' as const },
          {
            width: '55%',
            content:
              'Total paid up share capital of the Group on a consolidated basis',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(
              parseFloat(vars['total-paid-up-share-capital'])
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'c', position: 'left' as const },
          {
            width: '55%',
            content:
              'Total amounts received under the SAFE Notes of the Group on a consolidated basis and any other similar “equity-like” instruments, as approved by the Facility Agent in its sole discretion',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(
              parseFloat(vars['amounts-received-under-the-safe-notes'])
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'd', position: 'left' as const },
          {
            width: '55%',
            content:
              'Total retained earnings of the Group on a consolidated basis',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(parseFloat(vars['total-retained-earnings'])),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'e', position: 'left' as const },
          {
            width: '55%',
            content:
              'Total value of intangible assets and goodwill of the Group on a consolidated basis',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(
              parseFloat(vars['intangible-assets-and-goodwill'])
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'f', position: 'left' as const },
          {
            width: '55%',
            content: 'a / (b + c + d - e)',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatPercent(
              (parseFloat(vars['total-financial-indebtedness']) /
                (parseFloat(vars['total-paid-up-share-capital']) +
                  parseFloat(vars['amounts-received-under-the-safe-notes']) +
                  parseFloat(vars['total-retained-earnings']) -
                  parseFloat(vars['intangible-assets-and-goodwill']))) *
                100
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'g', position: 'left' as const },
          {
            width: '55%',
            content: 'Target result of f',
            bold: true,
            position: 'left' as const,
          },
          {
            width: '35%',
            content: '<= 300%',
            bold: true,
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'h', position: 'left' as const },
          {
            width: '55%',
            content:
              'Target result of f if the Group on a consolidated basis maintains a profit after tax for two consecutive quarters',
            bold: true,
            position: 'left' as const,
          },
          {
            width: '35%',
            content: '<= 400%',
            bold: true,
            position: 'left' as const,
          },
        ],
      ],
    },
    {
      title: 'Minimum Consolidated Cash',
      tableWidth: '100%',
      data: [
        [
          { width: '10%', content: 'a', position: 'left' as const },
          {
            width: '55%',
            content:
              'The sum of the projected consolidated operating expenses of the Group for the next four months',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(
              parseFloat(vars['projected-consolidated-operating-expenses'])
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'b', position: 'left' as const },
          {
            width: '55%',
            content:
              'Total consolidated cash balance of the Group less any cash applied under 1b for LTV covenant',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(parseFloat(vars['consolidated-cash-balance'])),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'c', position: 'left' as const },
          {
            width: '55%',
            content: 'Difference of b - a',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(
              parseFloat(vars['consolidated-cash-balance']) -
                parseFloat(vars['projected-consolidated-operating-expenses'])
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'd', position: 'left' as const },
          {
            width: '55%',
            content: 'Target result of c',
            bold: true,
            position: 'left' as const,
          },
          { width: '35%', content: `>= US$0`, position: 'left' as const },
        ],
      ],
    },
    {
      title: 'PAR 60 Ratio',
      tableWidth: '100%',
      data: [
        [
          { width: '10%', content: 'a', position: 'left' as const },
          {
            width: '55%',
            content:
              'Weighted average of the monthly cohort level PAR 60 ratios, weighted by the Gross Book Value outstanding for each cohort at the testing date (excluding the loans listed in Schedule 11), for all cohorts originated in the last 30 months prior to the testing date and at least 2 months prior to the testing date, calculated on the Secured Portfolio',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatPercent(data?.par60),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'b', position: 'left' as const },
          {
            width: '55%',
            content: 'Target result',
            bold: true,
            position: 'left' as const,
          },
          {
            width: '35%',
            content: `<=38%`,
            bold: true,
            position: 'left' as const,
          },
        ],
      ],
    },
    {
      title: 'PAR 90 Ratio',
      tableWidth: '100%',
      data: [
        [
          { width: '10%', content: 'a', position: 'left' as const },
          {
            width: '55%',
            content:
              'Weighted average of the monthly cohort level PAR 90 ratios, weighted by the gross book value outstanding for each cohort at the testing date (excluding the loans listed in Schedule 11), for all cohorts originated in the last 30 months prior to the testing date and at least 3 months prior to the testing date, calculated on the Secured Portfolio',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatPercent(data?.par90),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'b', position: 'left' as const },
          {
            width: '55%',
            content: 'Target result',
            bold: true,
            position: 'left' as const,
          },
          {
            width: '35%',
            content: `<=35%`,
            bold: true,
            position: 'left' as const,
          },
        ],
      ],
    },
    {
      title: 'Write off Ratio',
      tableWidth: '100%',
      data: [
        [
          { width: '10%', content: 'a', position: 'left' as const },
          {
            width: '55%',
            content:
              'Weighted average of the Monthly Cohort Write Off Ratio, weighted by the outstanding Gross Book Value for that monthly cohort as at the testing date, applied to all amounts originated in the last 30 months prior to the testing date and at least 6 months prior to the testing date (excluding the loans listed in Schedule 11), calculated on the Secured Portfolio',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatPercent(data?.writeoff),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'b', position: 'left' as const },
          {
            width: '55%',
            content: 'Target result',
            bold: true,
            position: 'left' as const,
          },
          {
            width: '35%',
            content: `<=25%`,
            bold: true,
            position: 'left' as const,
          },
        ],
      ],
    },
    {
      title: 'Post Recovery Losses ',
      tableWidth: '100%',
      data: [
        [
          { width: '10%', content: 'a', position: 'left' as const },
          {
            width: '55%',
            content: 'Write-Off Ratio from 6.a.',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatPercent(data?.writeoff),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'b', position: 'left' as const },
          {
            width: '55%',
            content:
              'Gross Book Value outstanding for all loans that have reached their Default Date, less the market value for repossessed vehicles and sale proceeds from sold vehicles associated with all such loans',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(
              data.outstandingGBV -
                (data.expectedLosses ? data.expectedLosses : 0)
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'c', position: 'left' as const },
          {
            width: '55%',
            content:
              'Gross Book Value outstanding of loans in (b) as of the Default Date',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(data.outstandingGBV),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'd', position: 'left' as const },
          {
            width: '55%',
            content: 'a x (b / c)',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatPercent(
              data.writeoff *
                ((data.outstandingGBV -
                  (data.expectedLosses ? data.expectedLosses : 0)) /
                  data.outstandingGBV)
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'e', position: 'left' as const },
          {
            width: '55%',
            content: 'Target result',
            bold: true,
            position: 'left' as const,
          },
          {
            width: '35%',
            content: `<=15%`,
            bold: true,
            position: 'left' as const,
          },
        ],
      ],
    },
    {
      title: 'Concentration Limits1',
      tableWidth: '100%',
      data: [
        [
          { width: '10%', content: 'a', position: 'left' as const },
          {
            width: '55%',
            content:
              'No more than 65% of the Secured Portfolio shall be exposed to any one (1) country.',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: `UG: ${formatPercent(
              data.concentrationRateUG
            )}\nCI: ${formatPercent(data.concentrationRateCI)}`,
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'b', position: 'left' as const },
          {
            width: '55%',
            content:
              'No more than 5% of the Secured Portfolio shall be exposed to any one (1) end customer',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatPercent(data.concentrationRate),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'c', position: 'left' as const },
          {
            width: '55%',
            content: 'Target result of a and b',
            bold: true,
            position: 'left' as const,
          },
          {
            width: '35%',
            content: `${
              data.concentrationRateUG > 65 ||
              data.concentrationRateCI > 65 ||
              data.concentrationRate > 5
                ? 'Breach'
                : 'No Breach'
            }`,
            bold: true,
            position: 'left' as const,
          },
        ],
      ],
    },
    {
      title: 'Net Open FX Position to Equity',
      tableWidth: '100%',
      data: [
        [
          { width: '10%', content: 'a', position: 'left' as const },
          {
            width: '55%',
            content:
              "Group's total unhedged Foreign Currency denominated liabilities on a consolidated basis",
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(
              parseFloat(
                vars['unhedged-foreign-currency-denominated-liabilities']
              )
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'b', position: 'left' as const },
          {
            width: '55%',
            content:
              "Group's total Foreign Currency denominated tangible assets on a consolidated basis",
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(
              parseFloat(vars['foreign-currency-denominated-tangible-assets'])
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'c', position: 'left' as const },
          {
            width: '55%',
            content:
              'Total paid up share capital of the Group on a consolidated basis',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(parseFloat(vars['paid-up-share-capital'])),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'd', position: 'left' as const },
          {
            width: '55%',
            content:
              'Total amounts received under the SAFE Notes of the Group on a consolidated basis and any other similar equity-like instruments, as approved by the Facility Agent in its sole discretion',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(
              parseFloat(vars['amounts-received-under-the-safe-notes'])
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'e', position: 'left' as const },
          {
            width: '55%',
            content:
              'Total retained earnings of the Group on a consolidated basis',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(parseFloat(vars['total-retained-earnings'])),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'f', position: 'left' as const },
          {
            width: '55%',
            content:
              'Total value of intangible assets and goodwill of the Group on a consolidated basis',
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatMoney(
              parseFloat(vars['intangible-assets-and-goodwill'])
            ),
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'g', position: 'left' as const },
          {
            width: '55%',
            content: '(a - b) / (c+d+e-f)',
            bold: true,
            position: 'left' as const,
          },
          {
            width: '35%',
            content: formatPercent(
              ((parseFloat(
                vars['unhedged-foreign-currency-denominated-liabilities']
              ) -
                parseFloat(
                  vars['foreign-currency-denominated-tangible-assets']
                )) /
                (parseFloat(vars['paid-up-share-capital']) +
                  parseFloat(vars['amounts-received-under-the-safe-notes']) +
                  parseFloat(vars['total-retained-earnings']) -
                  parseFloat(vars['intangible-assets-and-goodwill']))) *
                100
            ),
            bold: true,
            position: 'left' as const,
          },
        ],
        [
          { width: '10%', content: 'h', position: 'left' as const },
          {
            width: '55%',
            content: 'Target result',
            bold: true,
            position: 'left' as const,
          },
          {
            width: '35%',
            content: '<=20%',
            bold: true,
            position: 'left' as const,
          },
        ],
      ],
    },
  ]
}
