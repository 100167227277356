import { FacilityDetailResponse } from '@interfaces/manage-monitor-facility-detail'

import manageApiClient from './client'

export class FacilityDetailService {
  static async getList(params: { facility: string; slug_name: string }) {
    const { data } = await manageApiClient.post<FacilityDetailResponse[]>(
      `/deal-party-details/filter-by-facility`,
      params
    )
    return data
  }
}
