import React from 'react'

import { Image, Text, View } from '@react-pdf/renderer'

import { styles } from './style'

const countryAddresses = [
  { country: 'South Africa', address: 'Midrands, Johannesburg,' },
  { country: 'Nigeria', address: '1 Sylvia Crescent, Anthony Village, Lagos' },
  { country: 'Kenya', address: '209, West Park Towers, Mpesi Lane, Nairobi' },
  {
    country: 'Ghana',
    address: 'Unit 2, Oasis, Park Residence, Opey Street, Accra',
  },
  {
    country: 'Uganda',
    address: '5th Floor - Block D. UAP Nakawa Business Park, Kampala',
  },
  {
    country: 'Côte d’Ivoire',
    address: 'Immeuble plein ciel, Boulevard VGE, Marcory, Abidjan',
  },
  {
    country: 'Senegal',
    address: 'Résidence Darou Minam Rue de Diourbel x Rue B, Dakar.',
  },
  { country: 'Telephone', address: '+254 797 156 088' },
]

export const AutochekHeader = ({ fixed = false }: { fixed?: boolean }) => {
  return (
    <View fixed={fixed} style={styles.headerContainer}>
      <View style={styles.topBar} />
      <View style={styles.mainRow}>
        <View style={styles.imageContainer}>
          <Image style={styles.image} src="/company-logos/autochekLogo.jpg" />
        </View>
        <View wrap={false} style={styles.servicesContainer}>
          <Text style={styles.servicesTitle}>AutoChek Financial Services</Text>
          {countryAddresses.map((entry, index) => (
            <View
              wrap={true}
              key={`${entry.country}-${index}`}
              style={styles.addressRow}
            >
              <Text style={styles.countryName}>
                {entry.country}
                {` - `}
              </Text>
              <Text style={styles.countryAddress}>{entry.address}</Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  )
}
