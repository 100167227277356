export const REPORTING_MOCK = [
  {
    document: 'Compliance Certificate',
    description:
      'A certificate that signifies that a set of criteria has been maintened pursuant to the loan agreement',
    target: 'Borrower',
    frequency: 'Monthly',
    grace_period: '45 days',
  },
  {
    document: 'Compliance Certificate',
    description:
      'A certificate that signifies that a set of criteria has been maintened pursuant to the loan agreement',
    target: 'Guarantor 1',
    frequency: 'Monthly',
    grace_period: '45 days',
  },
  {
    document: 'Compliance Certificate',
    description:
      'A certificate that signifies that a set of criteria has been maintened pursuant to the loan agreement',
    target: 'Guarantor 2',
    frequency: 'Monthly',
    grace_period: '45 days',
  },
  {
    document: 'Compliance Certificate',
    description:
      'A certificate that signifies that a set of criteria has been maintened pursuant to the loan agreement',
    target: 'Consolidated Financials',
    frequency: 'Monthly',
    grace_period: '45 days',
  },

  {
    document: 'Unaudited Finanical Statements',
    description:
      'Unaudited financial statements for a specific entity or on a consolidated basis',
    target: 'Borrower',
    frequency: 'Monthly',
    grace_period: '45 days',
  },
  {
    document: 'Unaudited Finanical Statements',
    description:
      'Unaudited financial statements for a specific entity or on a consolidated basis',
    target: 'Guarantor 1',
    frequency: 'Monthly',
    grace_period: '45 days',
  },
  {
    document: 'Unaudited Finanical Statements',
    description:
      'Unaudited financial statements for a specific entity or on a consolidated basis',
    target: 'Guarantor 2',
    frequency: 'Monthly',
    grace_period: '45 days',
  },
  {
    document: 'Unaudited Finanical Statements',
    description:
      'Unaudited financial statements for a specific entity or on a consolidated basis',
    target: 'Consolidated Financials',
    frequency: 'Monthly',
    grace_period: '45 days',
  },

  {
    document: 'Audited Finanical Statements',
    description:
      'Audited financial statements for a specific entity or on a consolidated basis',
    target: 'Borrower',
    frequency: 'Annual',
    grace_period: '180 days',
  },
  {
    document: 'Audited Finanical Statements',
    description:
      'Audited financial statements for a specific entity or on a consolidated basis',
    target: 'Guarantor 1',
    frequency: 'Annual',
    grace_period: '180 days',
  },
  {
    document: 'Audited Finanical Statements',
    description:
      'Audited financial statements for a specific entity or on a consolidated basis',
    target: 'Guarantor 2',
    frequency: 'Annual',
    grace_period: '180 days',
  },
  {
    document: 'Audited Finanical Statements',
    description:
      'Audited financial statements for a specific entity or on a consolidated basis',
    target: 'Consolidated Financials',
    frequency: 'Annual',
    grace_period: '180 days',
  },

  {
    document: 'Borrowing Base Certificate',
    description: 'Certificate of borrowing base calculation',
    target: 'Borrower',
    frequency: 'Monthly',
  },
  {
    document: 'Portfolio Covenant Certificate',
    description:
      'Reasonably detailed calculations of the Borrower’s compliance with the Performance Covenants',
    target: 'Borrower',
    frequency: 'Monthly',
  },
  {
    document: 'Financial Covenant Certificate',
    description:
      'Reasonably detailed calculations of the Borrower’s compliance with the Financial Covenants',
    target: 'Borrower',
    frequency: 'Monthly',
  },
  {
    document: 'Cash Balance Certificate',
    description: 'Ending balance of specific bank accounts',
    target: 'Borrower',
    frequency: 'Monthly',
    grace_period: '20 days',
  },
  {
    document: 'Certificate of Witholding',
    description:
      'Proof of any tax withholding paid with respect to the Loan Agreement',
    target: 'Borrower',
    inputs: 'N/A',
    frequency: 'Ad hoc',
    grace_period: '15 days',
  },

  {
    document: 'Collections Report',
    description:
      'Report outlining all cash collected by in respect of pledged receivables',
  },
  {
    document: 'Waterfall Report',
    description:
      'Reporting documenting the outflow of cash in accordance with the waterfall of the loan agreement',
  },
  {
    document: 'Financial Plan',
    description: 'Forecasted financial statements',
    frequency: 'Annual',
    grace_period: '',
  },
  {
    document: 'Required Filings',
    description:
      'copies of (i) any proxy statements, financial statements or reports that the Borrower has made available to holders of debt or the Borrower’s equity securities, and (ii) any regular, periodic and special reports or registration statements that the Borrower files, in respect of the Borrower, with (A) any relevant regulatory authority under Applicable Law or (B) any national securities exchange.',
    frequency: 'Ad hoc',
  },
  {
    document: 'Board of Director Reports',
    description:
      'Detailed audit reports management letters or recommendations submitted to the board of directors',
    frequency: 'Ad hoc',
  },
  {
    document: 'Certain AML Events',
    description:
      'Immediate notice if the Borrower has knowledge that any Obligor is listed on the OFAC Lists or (i) is convicted on, (ii) pleads nolo contendere to, (iii) is indicted on, or (iv) is arraigned and held over on charges involving money laundering or predicate crimes to money laundering.',
    frequency: 'Ad hoc',
  },
]
