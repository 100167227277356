export const hardcodedData: Record<string, Record<string, any>> = {
  '2023-12-31': {
    a1: 143310642.098175,
    a2: 124710380.936725,
    bi1: 60996652.1968132,
    bi2: 62064654.0118561,
    bii1: 0,
    bii2: 0,
    c1: 8107727.91777804,
    c2: 6111633.53818357,
    d1: 145991262.333271,
    d2: 144426958.741951,
    e1: 1412298.76457148,
    e2: 877185.747784777,
    f1: 74249493.0795879,
    f2: 54648507.7931838,
    g1: 23689962.3320408,
    g2: 22834248.6891143,
    h1: 165001797.466814,
    h2: 166843800.526857,
    i1: 130965743.993249,
    i2: 131012522.279339,
    j1: -1313892.58932322,
    j2: -1403765.62437505,
    k1: 56822311.3158068,
    k2: 33897239.6883269,
    l1: 18839723.7684112,
    l2: 20301934.2785019,
    m1: 6838463.33005154,
    m2: 8008702.98522829,
    n1: 1092163.92033766,
    n2: 121701.630549399,
    o1: 234.948373290662,
    o2: 0.771287281697635,
    o3: 44.9992025659728,
    o4: 18.0886708307952,
    o5: 5.55357066457142,
    o6: 18.0303645248836,
    o7: 275.496450871064,
    o8: 2.03338582186579,
    p1: 58265197.79,
    p2: null,
    p3: 30000000,
    q1a: 7303354.07,
    q1b: 7303354.07,
    q2a: 27770000,
    q2b: 21045451.9618802,
    r1a: 360785.691058,
    r1b: 360785.691058,
    r2a: 1127947.975,
    r2b: 854813.64506167,
    t1: 1.319525,
  },
  '2024-01-15': {
    a1: 129926999.52,
    a2: 143310642.098175,
    bi1: 56970587,
    bi2: 60996652.1968132,
    bii1: 0,
    bii2: 0,
    c1: 8107727.91777804,
    c2: 6111633.53818357,
    d1: 145991262.333271,
    d2: 144426958.741951,
    e1: 1412298.76457148,
    e2: 877185.747784777,
    f1: 74249493.0795879,
    f2: 54648507.7931838,
    g1: 23689962.3320408,
    g2: 22834248.6891143,
    h1: 165001797.466814,
    h2: 166843800.526857,
    i1: 130965743.993249,
    i2: 131012522.279339,
    j1: -1313892.58932322,
    j2: -1403765.62437505,
    k1: 56822311.3158068,
    k2: 33897239.6883269,
    l1: 18839723.7684112,
    l2: 20301934.2785019,
    m1: 6838463.33005154,
    m2: 8008702.98522829,
    n1: 1092163.92033766,
    n2: 1092163.92033766,
    o1: null,
    o2: 0.771287281697635,
    o3: 44.9992025659728,
    o4: 18.0886708307952,
    o5: 5.55357066457142,
    o6: 18.0303645248836,
    o7: 275.496450871064,
    o8: 2.03338582186579,
    p1: 50202645.06,
    p2: 58265197.79,
    p3: 30000000,
    q1a: 5503354.07,
    q1b: 5503354.07,
    q2a: 30370000,
    q2b: 22765178.3472196,
    r1a: 269939.5171335,
    r1b: 269939.5171335,
    r2a: 1226629.115,
    r2b: 919474.170855025,
    t1: 1.334055,
  },
  '2024-01-31': {
    a1: 139676365.385833,
    a2: 129926999.52,
    bi1: 58564683.2325011,
    bi2: 56970587,
    bii1: 0,
    bii2: 0,
    c1: 7650801.96146559,
    c2: 8107727.91777804,
    d1: 143574348.16322,
    d2: 145991262.333271,
    e1: 2259829.49077681,
    e2: 1412298.76457148,
    f1: 70114374.7338354,
    f2: 74249493.0795879,
    g1: 22917222.7730071,
    g2: 23689962.3320408,
    h1: 158473830.176313,
    h2: 165001797.466814,
    i1: 127849685.881719,
    i2: 130965743.993249,
    j1: -1342324.53360015,
    j2: -1313892.58932322,
    k1: 46218659.3094381,
    k2: 56822311.3158068,
    l1: 18448129.6906225,
    l2: 18839723.7684112,
    m1: 8034287.9080532,
    m2: 6838463.33005154,
    n1: 1092163.92033766,
    n2: 1092163.92033766,
    o1: 238.499309953269,
    o2: 1.07488658005034,
    o3: 44.2435035840482,
    o4: 17.9251302926228,
    o5: 5.32880842528215,
    o6: 17.0727884348076,
    o7: 229.617483238695,
    o8: 2.45294325206386,
    p1: 55785648.3,
    p2: 50202645.06,
    p3: 30000000,
    q1a: 5503354.07,
    q1b: 5503354.07,
    q2a: 26170000,
    q2b: 19522420.5712751,
    r1a: 270214.684837,
    r1b: 270214.684837,
    r2a: 1044758.74,
    r2b: 779374.074046445,
    t1: 1.34051,
  },
  '2024-02-15': {
    a1: 126423459.53,
    a2: 139676365.385833,
    bi1: 55068998.7889635,
    bi2: 58564683.2325011,
    bii1: 0,
    bii2: 0,
    c1: 7650801.96146559,
    c2: 8107727.91777804,
    d1: 143574348.16322,
    d2: 145991262.333271,
    e1: 2259829.49077681,
    e2: 1412298.76457148,
    f1: 70114374.7338354,
    f2: 74249493.0795879,
    g1: 22917222.7730071,
    g2: 23689962.3320408,
    h1: 158473830.176313,
    h2: 165001797.466814,
    i1: 127849685.881719,
    i2: 130965743.993249,
    j1: -1342324.53360015,
    j2: -1313892.58932322,
    k1: 46218659.3094381,
    k2: 56822311.3158068,
    l1: 18448129.6906225,
    l2: 18839723.7684112,
    m1: 8034287.9080532,
    m2: 6838463.33005154,
    n1: 1092163.92033766,
    n2: 1092163.92033766,
    o1: null,
    o2: 1.07488658005034,
    o3: 44.2435035840482,
    o4: 17.9251302926228,
    o5: 5.32880842528215,
    o6: 17.0727884348076,
    o7: 229.617483238695,
    o8: 2.45294325206386,
    p1: 51082629.94,
    p2: 55785648.3,
    p3: 30000000,
    q1a: 5503354.07,
    q1b: 5503354.07,
    q2a: 26170000,
    q2b: 19447922.7433684,
    r1a: 269939.5171335,
    r1b: 269939.5171335,
    r2a: 1072289.58,
    r2b: 796859.186486778,
    t1: 1.345645,
  },
}
