import React, { useCallback, useEffect, useState } from 'react'

import ListOptions from './sub-components/list-dropdown'
import SearchInput from './sub-components/search-input'
import useFilteredOptions from './filtered-options-hooks'
import { InputProps, ListPropsDropdown, SearchBarProps } from './interfaces'

const SearchBar: React.FC<
  SearchBarProps & Partial<ListPropsDropdown & InputProps>
> = ({
  onSearch,
  className,
  placeholder,
  optionListKey,
  options,
  onSelectedOption,
  queryInput,
  label,
  classNameInput,
  inputRef,
  renderOptionElements,
  sizeList,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [query, setQuery] = useState<string>('')
  const [filteredOptions] = useFilteredOptions(
    options,
    query,
    optionListKey,
    sizeList
  )
  useEffect(() => {
    setQuery(queryInput || '')
  }, [queryInput])

  useEffect(() => {
    setSelectedIndex(filteredOptions?.length ? 0 : null)
  }, [filteredOptions])

  const updateSelectedOption = useCallback(
    (selectedOption: string, objOption?: any) => {
      onSelectedOption(selectedOption, objOption)
      setQuery(selectedOption)
      if (onSearch) {
        onSearch(selectedOption)
      }
    },
    [onSelectedOption, onSearch]
  )

  const handleSelectedOption = useCallback(
    (selectedOption: string, obj?: any) => {
      updateSelectedOption(selectedOption, obj)
    },
    [updateSelectedOption]
  )

  const handleSearch = useCallback(
    (value: string) => {
      if (onSearch) {
        onSearch(value)
      } else {
        setQuery(value)
      }
    },
    [queryInput, onSearch]
  )
  const handleArrowDown = () => {
    setSelectedIndex(prevIndex =>
      prevIndex === null || prevIndex === filteredOptions.length - 1
        ? 0
        : prevIndex + 1
    )
  }

  const handleArrowUp = () => {
    setSelectedIndex(prevIndex =>
      prevIndex === null || prevIndex === 0
        ? filteredOptions.length - 1
        : prevIndex - 1
    )
  }

  const handleEnter = () => {
    if (selectedIndex === null) {
      return
    }
    const selectedOption = optionListKey
      ? filteredOptions[selectedIndex][optionListKey]
      : filteredOptions[selectedIndex]
    updateSelectedOption(selectedOption, filteredOptions[selectedIndex])
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault()
        handleArrowDown()
        break
      case 'ArrowUp':
        event.preventDefault()
        handleArrowUp()
        break
      case 'Enter':
        if (selectedIndex !== null) {
          handleEnter()
        }
        break
      case 'Escape':
        setQuery('')
        break
      default:
        break
    }
  }

  return (
    <div className={`relative ${className}`}>
      <SearchInput
        onSearch={handleSearch}
        onKeyDown={handleKeyDown}
        inputValue={query}
        placeholder={placeholder}
        label={label}
        classNameInput={classNameInput}
        inputRef={inputRef}
      />
      {filteredOptions && filteredOptions.length > 0 && (
        <ListOptions
          optionListKey={optionListKey}
          options={filteredOptions}
          onOptionSelected={handleSelectedOption}
          selectedIndex={selectedIndex}
          renderOption={renderOptionElements}
        />
      )}
    </div>
  )
}

export default SearchBar
