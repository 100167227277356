import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import StatisticL5 from '@components/layouts/l5-stat'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { Filter, FilterItem } from '@interfaces/filter'
import { RisksFilters } from '@interfaces/risk'
import { CollectionService } from '@services/api-analytics/risk-collection'

const CollectionRateL5 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes = [],
    activeType,
    currency = 'USD',
  } = appliedFilters
  const {
    categoryTypeOptions = [],
    rates = [],
    display_rates = [],
  } = optionFilters
  const isAggregate = activeType === 'All'
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const filters: RisksFilters = {
    date_from: moment.utc(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEndCohort).format('YYYY-MM-DD'),
    slug_name: company?.slug_name,
    filters: [],
  }

  const {
    error: errorAggregated,
    data: fetchedDataAggregated,
    isFetching: isFetchingAggregated,
  } = useQuery(
    ['collectionRate', filters, true],
    () =>
      CollectionService.getCollectionRate({
        ...filters,
        is_aggregate: true,
        filters: categoryTypeOptions?.reduce(
          (prev: string[], cur: Filter) => [
            ...prev,
            ...cur.type_val.map((x: FilterItem) => x.id),
          ],
          []
        ),
      }),
    getStaleMins()
  )

  const {
    error: errorNonAggregated,
    data: fetchedDataNonAggregated,
    isFetching: isFetchingNonAggregated,
  } = useQuery(
    ['collectionRate', filters, false, categoryTypes],
    () =>
      CollectionService.getCollectionRate({
        ...filters,
        is_aggregate: false,
        filters: categoryTypes,
      }),
    {
      ...getStaleMins(),
      enabled: isAggregate === false,
    }
  )

  const error = isAggregate ? errorAggregated : errorNonAggregated
  const isFetching = isAggregate
    ? isFetchingAggregated
    : isFetchingNonAggregated

  const { data, ...growthRatios } =
    (isAggregate ? fetchedDataAggregated : fetchedDataNonAggregated) ?? {}

  const cohort = (data || []).filter(
    (v, i, a) => a.findIndex(v2 => v2.cohort === v.cohort) === i
  )

  let maxVal = 0

  const chartData = cohort.map(c => {
    const result: { x: number; [key: string]: any } = {
      x: moment(c.cohort).valueOf(),
      ...(rates ?? []).reduce((p = {}, r: any) => {
        return {
          ...p,
          [`fx_${r.code}`]: Intl.NumberFormat(undefined, {
            style: 'decimal',
            notation: 'compact',
            maximumFractionDigits: 2,
          }).format(r.display_rates?.[c.cohort] ?? 1),
        }
      }, {}),
    }
    const cohortData = (data || []).filter(d => d.cohort === c.cohort)
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
      if (result.type_all > maxVal) {
        maxVal = result.type_all
      }
    } else {
      ;[0, ...categoryTypes].forEach((ct: number) => {
        const cohortCategory =
          ct === 0
            ? fetchedDataAggregated?.data?.find(
                x => x.cohort === c.cohort && x.id === ct
              )
            : cohortData.find(cd => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
        if (result[`type_${ct}`] > maxVal) {
          maxVal = result[`type_${ct}`]
        }
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  const collecRateSeries = isAggregate
    ? [
        {
          label: `All`,
          tooltipValueFormat: '#.0a%',
          type: 'SmoothedXLineSeries',
          field: 'type_all',
        },
      ]
    : [0, ...categoryTypes].map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: `${typeVal ? typeVal.type : 'All'}`,
          tooltipValueFormat: '#.0a%',
          field: `type_${ct}`,
          color,
          type: 'SmoothedXLineSeries',
          hasBullet: true,
        }
      })

  return (
    <L5ChartstatLayout
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`riskCollectionRate_by_${activeType}`}
          yLabel="Percentage"
          ySetting={maxVal < 5 ? { max: 5, maxPrecision: 1 } : {}}
          yFormat="#.0a%"
          data={chartData}
          series={collecRateSeries as any}
          tooltipSubtitle={
            showHistoricalRateIndicator
              ? (display_rates ?? []).map((r: any) => {
                  return {
                    field: `fx_${r.code}`,
                    title: `USD:${r.code}`,
                  }
                })
              : undefined
          }
          exportable={exportable}
          error={error as { message: string }}
        />
      }
      stat={<StatisticL5 {...growthRatios} />}
    />
  )
}

export default CollectionRateL5
