export const ACCOUNTS_MOCK = [
  {
    bank_institution: 'Bank 1',
    nickname: 'Trust USD Funding Account',
    entity_ownership: 'Trust',
    type: 'Collection Account',
    borrowing_base: 'Yes',
    reporting: 'Yes',
    portfolio_covenant: 'No',
    financial_covenant: 'Yes',
    integration: 'Belvo',
  },
  {
    bank_institution: 'Bank 2',
    nickname: 'Trust MXN Funding Account',
    entity_ownership: 'Trust',
    type: 'Collection Account',
    borrowing_base: 'Yes',
    reporting: 'Yes',
    portfolio_covenant: 'No',
    financial_covenant: 'Yes',
    integration: 'Belvo',
  },
  {
    bank_institution: 'Bank 3',
    nickname: 'Master Trust Account',
    entity_ownership: 'Trust',
    type: 'Collection Account',
    borrowing_base: 'Yes',
    reporting: 'Yes',
    portfolio_covenant: 'No',
    financial_covenant: 'No',
    integration: 'Belvo',
  },
  {
    bank_institution: 'Bank 4',
    nickname: 'STP Disbursement Account',
    type: 'Collection Account',
    borrowing_base: 'No',
    reporting: 'No',
    portfolio_covenant: 'No',
    financial_covenant: 'No',
  },
  {
    bank_institution: 'Bank 5',
    nickname: 'STP Collection Account',
    type: 'Collection Account',
    borrowing_base: 'No',
    reporting: 'No',
    portfolio_covenant: 'No',
    financial_covenant: 'No',
  },
  {
    bank_institution: 'Bank 6',
    nickname: 'Opco Account',
    entity_ownership: '8%',
    type: 'Opco',
    borrowing_base: 'No',
    reporting: 'Yes',
    portfolio_covenant: 'No',
    financial_covenant: 'Yes',
    integration: 'Belvo',
  },
]
