import React, { useEffect, useState } from 'react'

import DatePicker from '@components/atoms/date-picker'
import { Checkbox, Option, Select } from '@material-tailwind/react'

interface FormInputProps {
  label?: {
    start?: string
    end?: string
  }
  type?:
    | 'email'
    | 'text'
    | 'textarea'
    | 'select'
    | 'date'
    | 'number'
    | 'checkbox'
  error?: string
  /**
   * Mandatory
   * Need to take it out from RHF register function to trigger rerender component
   */
  value: any
  /**
   * Mandatory for select type
   */
  options?: { value: any; title: string }[]
  /**
   * Mandatory for select type
   */
  onSelected?: (value: any) => void
  /**
   * Disables input but leaves current value in place
   */
  disabled?: boolean
  /**
   * Controls selectable date range in date picker
   */
  disabledDate?: (current: any) => boolean

  containerClass?: string
}

const FormInput = ({
  label,
  type,
  options,
  value,
  onSelected,
  error,
  disabled,
  disabledDate,
  containerClass,
  ...rest
}: FormInputProps) => {
  const [_value, setValue] = useState<any>()
  useEffect(() => {
    setValue(value)
  }, [value])

  return (
    <div
      className={`flex mb-2 relative ${
        type === 'checkbox' ? 'flex-row' : 'flex-col'
      }`}
    >
      {label?.start && (
        <label className="text-neutral-black text-sm pb-2">{label.start}</label>
      )}
      {type === 'textarea' && (
        <textarea
          className={`py-2 px-3 rounded-md text-neutral-body-2 border ${
            error ? '!border-danger-main' : 'border-neutral-border-1'
          } text-sm focus:border-primary-main outline-0 disabled:bg-neutral-surface-2${
            disabled ? ' cursor-not-allowed' : ''
          } ${containerClass}`}
          {...rest}
          value={_value}
          onChange={e => {
            setValue(e.target.value)
            ;(rest as any).onChange(e)
          }}
          disabled={disabled}
        />
      )}
      {type === 'date' && (
        <div
          className={`w-full flex flex-col [&>.rc-picker>.rc-picker-input]:border-neutral-border-1 [&>.rc-picker>.rc-picker-input>input]:text-sm [&>.rc-picker>.rc-picker-input:focus-within]:outline-none [&>.rc-picker>.rc-picker-input:focus-within]:border-primary-main${
            disabled ? ' cursor-not-allowed' : ''
          } ${containerClass}`}
        >
          <DatePicker
            disabled={disabled}
            value={_value}
            onChange={val => {
              setValue(val)
              onSelected?.(val)
            }}
            disabledDate={disabledDate}
            allowClear
          />
        </div>
      )}
      {type === 'select' && options && (
        <div className={`[&>div>button]:border-neutral-border-1`}>
          <Select
            className={`${
              disabled ? ' cursor-not-allowed' : ''
            } ${containerClass}`}
            disabled={disabled || (rest as any).disabled}
            error={!!error}
            selected={() => {
              const selectedValue = options?.find(o => o.value === value)
              return <span>{selectedValue?.title}</span>
            }}
            onChange={onSelected}
          >
            {options.map(o => (
              <Option key={o.value} value={o.value}>
                {o.title}
              </Option>
            ))}
          </Select>
        </div>
      )}

      {type === 'number' && (
        <input
          className={`py-2 px-3 rounded-md text-neutral-body-2 border ${
            error ? '!border-danger-main' : 'border-neutral-border-1'
          } text-sm focus:border-primary-main outline-0 disabled:bg-neutral-surface-2 disabled:border-neutral-border-1${
            disabled ? ' cursor-not-allowed' : ''
          } ${containerClass}`}
          type={type}
          {...rest}
          value={_value}
          onChange={e => {
            setValue(e.target.value)
            ;(rest as any).onChange(e)
          }}
          onWheel={e => {
            const target = e.currentTarget
            target.blur()
            e.stopPropagation()
            setTimeout(() => {
              target.focus()
            }, 0)
          }}
          disabled={disabled}
        />
      )}

      {!['select', 'date', 'textarea', 'checkbox', 'number'].includes(
        type ?? ''
      ) && (
        <input
          className={`py-2 px-3 rounded-md text-neutral-body-2 border ${
            error ? '!border-danger-main' : 'border-neutral-border-1'
          } text-sm focus:border-primary-main outline-0 disabled:bg-neutral-surface-2 disabled:border-neutral-border-1${
            disabled ? ' cursor-not-allowed' : ''
          } ${containerClass}`}
          type={type}
          {...rest}
          value={_value}
          onChange={e => {
            setValue(e.target.value)
            ;(rest as any).onChange(e)
          }}
          disabled={disabled}
        />
      )}
      {type === 'checkbox' && (
        <Checkbox
          className={`font-s${
            disabled ? ' cursor-not-allowed' : ''
          } ${containerClass}`}
          {...rest}
          checked={_value}
          value={_value}
          onChange={e => {
            setValue(e.target.checked)
            ;(rest as any).onChange(e)
          }}
        />
      )}
      {label?.end && (
        <label
          className={`text-neutral-black ${
            type != 'checkbox' ? 'text-xs' : 'text-sm my-2'
          } m-1${disabled ? ' cursor-not-allowed' : ''}`}
        >
          {label.end}
        </label>
      )}
      {error && <label className="text-danger-main text-xs m-1">{error}</label>}
    </div>
  )
}

export default FormInput
