import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import CashCollectedL5 from './cash-collected'

const CashCollectedL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const tabs = [
    {
      label: 'Cash Collected by Cohort',
      desc: {
        description:
          'The cash in vs cash out for each disbursed cohort, showing whether the cohort was profitable or not.',
        formula:
          'For each cohort, total_payments (payments) / (disbursed_principal - fees_origin)',
      },
      component: <CashCollectedL5 exportable={exportable} />,
    },
  ]

  return (
    <L4TabsLayout
      tabs={tabs}
      additionalIndicator={
        showHistoricalRateIndicator ? (
          <ExchangeRateBadge isHistorical={false} />
        ) : undefined
      }
    />
  )
}

export default CashCollectedL4
