import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import StatisticL5 from '@components/layouts/l5-stat'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { Filter, FilterItem } from '@interfaces/filter'
import { RisksFilters } from '@interfaces/risk'
import { DelinquencyService } from '@services/api-analytics/risk-delinquency'

const FpdL5 = ({
  value,
  exportable,
}: {
  value: string
  exportable: boolean
}) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { dateStart, dateEnd, categoryTypes = [], activeType } = appliedFilters
  const { categoryTypeOptions } = optionFilters

  const isAggregate = activeType === 'All'
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const filters: RisksFilters = {
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name,
    filters: categoryTypes,
    value: `fpd${value}`,
    is_aggregate: isAggregate,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    [`dpd${value}fpd`, filters],
    () => DelinquencyService.getFpdByCohort(filters),
    getStaleMins()
  )

  const { data, ...growthRatios } = fetchedData ?? {}

  const cohort = (data || []).filter(
    (v, i, a) => a.findIndex(v2 => v2.cohort === v.cohort) === i
  )

  const chartData = cohort.map(c => {
    const result: { x: number; [key: string]: any } = {
      x: moment(c.cohort).valueOf(),
    }
    const cohortData = (data || []).filter(d => d.cohort === c.cohort)
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
    } else {
      categoryTypes.forEach((ct: number) => {
        const cohortCategory = cohortData.find(cd => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  const series = isAggregate
    ? [
        {
          label: 'All',
          tooltipValueFormat: '#.0a%',
          field: 'type_all',
          type: 'ColumnSeries',
        },
      ]
    : (categoryTypes || []).map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: typeVal?.type,
          tooltipValueFormat: '#.0a%',
          field: `type_${ct}`,
          isStack: true,
          isTotal: false,
          color,
          type: 'ColumnSeries',
        }
      })

  return (
    <L5ChartstatLayout
      chart={
        <Chart
          loading={isFetching || isProcessing}
          data={chartData}
          id={`dpd${value}Fpd_by_${activeType}`}
          yLabel="Percentage"
          yFormat="#.0a%"
          ySetting={{ min: 0 }}
          series={series}
          exportable={exportable}
          error={error as { message: string }}
        />
      }
      stat={
        <StatisticL5
          description={`percentage at ${value}DPD`}
          classSetting={{ up: 'text-danger-hover', down: 'text-success-hover' }}
          {...growthRatios}
        />
      }
    />
  )
}

export default FpdL5
