import React, { useEffect, useState } from 'react'

interface Option {
  actionIcon: any
  label: string
  value: any
  disabledHint: string
  displayhint: boolean
}
export interface OptionSearchProps {
  defaultValue?: string
  availableOptions: Option[]
  searchParam: string
  showOptions: boolean
  handleChange: (value: string) => void
}

const OptionSearch = ({
  defaultValue,
  availableOptions,
  searchParam,
  handleChange,
  showOptions,
}: OptionSearchProps) => {
  const [query, setQuery] = useState('')
  const [options, setOptions] = useState<Option[]>([])
  const [optionsShown, setOptionsShown] = useState<boolean>(showOptions)
  const [displayVal, setDisplayVal] = useState<string>('')

  const filteredOptions = options.filter(o =>
    o.label?.toLowerCase().includes(query?.toLowerCase())
  )

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const handleSelect = (option: Option) => {
    setOptionsShown(false)
    setDisplayVal('')
    setQuery(option.label)
    handleChange(option.value)
  }

  useEffect(() => {
    defaultValue && setQuery(defaultValue)
    availableOptions && setOptions(availableOptions)
  }, [defaultValue, availableOptions])

  return (
    <div
      className="bg-white overflow-y-auto z-[9999]"
      onBlur={() => {
        setTimeout(() => {
          setOptionsShown(false)
        }, 300)
      }}
    >
      <input
        className="sticky top-0 shadow-lg focus:outline-none focus:shadow-outline-blue border border-gray-300 rounded-lg py-2 pr-4 pl-2 block w-full text-sm"
        type="text"
        placeholder={`Search by ${searchParam}...`}
        value={displayVal}
        onInput={handleInput}
        onChange={e => {
          setDisplayVal(e.target.value)
          setOptionsShown(true)
        }}
      />
      {optionsShown && filteredOptions.length > 0 && (
        <div className="w-full h-[120px] overflow-y-auto z-[9999]">
          <div className="rounded-lg">
            <ul className="list-reset">
              {filteredOptions.map(option => (
                <li
                  key={option.value}
                  className={`${
                    option.disabledHint
                      ? `cursor-not-allowed bg-gray-300`
                      : `cursor-pointer hover:bg-primary-surface-2`
                  } p-4 `}
                  onClick={() => handleSelect(option)}
                >
                  <div className="flex justify-between">
                    <span>
                      {' '}
                      {option.label}{' '}
                      {option.displayhint && <sup>{option.disabledHint}</sup>}
                    </span>
                    {option.actionIcon && option.actionIcon}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default OptionSearch
