import React, { useContext } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { BorrowingBaseService } from '@services/api-manage/borrowing-base'

const renderDecimal = (value?: any) =>
  Intl.NumberFormat(undefined, {
    style: 'decimal',
    maximumFractionDigits: 2,
  }).format(value)

export const SUMMARY_SORT = [
  'fx_rate',
  'cash',
  'eligible_pool',
  'excess_concentration',
  'repossessed_pool_balance',
  'interest_reserve',
  'borrowing_base',
  'advance_rate',
  'discounted_borrowing_base',
  'lender_principal',
  'surplus',
  'collateralization_percent',
]

const SummaryTable = ({
  onClickComponent,
}: {
  onClickComponent: (c: string) => void
}) => {
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)

  const { activeDebtDeal = 0, calculationDate } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility ?? '',
    numberOfLenders: activeFacility?.lender ?? 0,
    date: calculationDate,
  }

  const { data, isFetching } = useQuery(
    ['summary', filters],
    () => BorrowingBaseService.getSummaryTable(filters),
    { ...getStaleMins(), enabled: !!calculationDate }
  )

  const columns = [
    {
      title: 'USD',
      field: 'defined_term',
      render: (record: any) => (
        <span className="capitalize">
          {record.defined_term.split('_').join(' ')}
        </span>
      ),
      className: '!p-0',
      head: {
        colSpan: 3,
      },
    },
    {
      title: '',
      field: 'value',
      align: 'right',
      className: '!p-0',
      width: 150,
      head: {
        colSpan: 0,
      },
    },
    {
      title: '',
      field: 'total',
      align: 'right',
      className: '!p-0',
      width: 150,
      head: {
        colSpan: 0,
      },
    },
  ] as any

  let discountedBorrowingBase = 0
  let totalLenderPrincipal = 0
  const tableData = SUMMARY_SORT.reduce((prev: any, cur) => {
    const filteredData = (data ?? []).filter(x =>
      cur === 'borrowing_base'
        ? x.defined_term === cur
        : x.defined_term.includes(cur)
    )
    let addition: any[] = []
    let borderClass = ''
    switch (cur) {
      case 'fx_rate':
        borderClass = `border-b-2 border-b-neutral-border-2`
        addition = [
          {
            defined_term: {
              content: `<div class="p-3">Date</div>`,
            },
            value: `<div class="p-3">${moment
              .utc(filteredData?.[0]?.calculation_date)
              .format('YYYY-MM-DD')}</div>`,
          },
          {
            defined_term: {
              content: `<div class="${borderClass} p-3">USD:${activeFacility.currency}</div>`,
            },
            value: {
              content: `<div class="${borderClass} p-3">${filteredData?.[0]?.value}<div>`,
            },
            total: {
              content: `<div class="${borderClass} p-3 h-full">&nbsp;<div>`,
            },
          },
        ]
        break
      case 'borrowing_base':
      case 'discounted_borrowing_base':
      case 'surplus':
        if (cur === 'discounted_borrowing_base') {
          discountedBorrowingBase = filteredData?.[0]?.value
        }
        borderClass = `border-b-2 border-b-neutral-border-2 border-t-2 border-t-neutral-border-2`
        addition = filteredData.map(x => ({
          ...x,
          defined_term: {
            content: `<div class="${borderClass} font-semibold p-3">
                ${
                  cur === 'surplus'
                    ? x.value >= 0
                      ? 'Surplus'
                      : 'Deficiency'
                    : x.defined_term.split('_').join(' ')
                }
              </div>`,
          },
          value: {
            content: `<div class="${borderClass} p-3 h-full">&nbsp;<div>`,
          },
          total: {
            content: `<div class="${borderClass} p-3 h-full ${
              cur === 'surplus'
                ? x.value >= 0
                  ? 'bg-success-surface'
                  : 'bg-danger-surface'
                : ''
            }">${x.value < 0 ? '(' : ''}${renderDecimal(Math.abs(x.value))}${
              x.value < 0 ? ')' : ''
            }<div>`,
          },
        }))
        break
      case 'advance_rate':
        borderClass = `border-b-2 border-b-neutral-border-2 border-t-2 border-t-neutral-border-2`
        addition = [
          ...filteredData.map(x => ({
            ...x,
            defined_term: {
              content: `<div class="p-3">${x.defined_term
                .split('_')
                .join(' ')}</div>`,
            },
            value: {
              content: `<div class="p-3">${renderDecimal(x.value * 100)}%<div>`,
            },
          })),
        ]
        break
      case 'lender_principal':
        borderClass = `border-b-2 border-b-black border-t-4`
        let total = 0
        const items = filteredData.map(x => {
          total += x.value
          return {
            ...x,
            defined_term: {
              content: `<div class="p-3">${x.defined_term
                .split('_')
                .join(' ')}</div>`,
            },
            value: {
              content: `<div class="p-3">(${renderDecimal(x.value)})<div>`,
            },
          }
        })
        addition = [
          ...items,
          {
            defined_term: {
              content: `<div class="${borderClass} p-3 font-semibold">Total Lender Principal</div>`,
            },
            value: {
              content: `<div class="${borderClass} p-3 h-full">&nbsp;<div>`,
            },
            total: {
              content: `<div class="${borderClass} p-3 h-full">(${renderDecimal(
                total
              )})<div>`,
            },
          },
        ]
        totalLenderPrincipal = total
        break
      case 'collateralization_percent':
        addition = [
          {
            defined_term: {
              content: `<div class="p-3 font-semibold">Collateralization Percent</div>`,
            },
            total: {
              content: `<div class="p-3">${
                totalLenderPrincipal && discountedBorrowingBase
                  ? `${renderDecimal(
                      Number(
                        (discountedBorrowingBase / totalLenderPrincipal) * 100
                      )
                    )}%`
                  : '-'
              }</div>`,
            },
          },
        ]
        break
      default:
        addition = filteredData.map(x => ({
          ...x,
          component: x.defined_term,
          defined_term: {
            content: `<div class="p-3">${x.defined_term
              .split('_')
              .join(' ')}</div>`,
          },
          value: {
            content: `<div class="p-3">${
              x.action === 'subtract' ? '(' : ''
            }${renderDecimal(x.value)}${
              x.action === 'subtract' ? ')' : ''
            }</div>`,
          },
        }))
        break
    }

    return [...prev, ...addition]
  }, [])

  return (
    <div className="flex flex-col" id="summary-table">
      <Table
        loading={isFetching || !calculationDate}
        containerClass="[&>table>thead>tr>th]:!p-3 [&>table>tbody>tr>td:first-child]:capitalize [&>table>tbody>tr>td:last-child]:font-semibold"
        columns={columns}
        data={tableData}
        onRowClick={data => data.component && onClickComponent(data.component)}
      />
      <Table
        loading={isFetching || !calculationDate}
        containerClass="[&>table>thead]:hidden [&>table>tbody>tr>td:first-child]:capitalize [&>table>tbody>tr>td]:!p-3"
        columns={columns.slice(0, 2)}
        data={(data ?? [])
          .filter((x: any) => ['days_deficient'].includes(x.defined_term))
          .map(x => ({ ...x, value: x.value < 0 ? 0 : x.value }))}
      />
    </div>
  )
}

export default SummaryTable
