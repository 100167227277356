import {
  CashflowRequest,
  PaymentScheduleRequest,
  PaymentScheduleResponse,
} from '@interfaces/manage-monitor-cashflow'

import manageApiClient from './client'

export class CashflowService {
  static async getPaymentScheduleDisbursementDates(params: CashflowRequest) {
    const { data } = await manageApiClient.post<string[]>(
      `/payment-schedule/disbursement-list`,
      params
    )
    return data
  }

  static async getPaymentScheduleFee(params: CashflowRequest) {
    const { data } = await manageApiClient.post<string[]>(
      `/payment-schedule/table-headers`,
      params
    )
    return data
  }

  static async getPaymentScheduleList(params: PaymentScheduleRequest) {
    const { data } = await manageApiClient.post<{
      data: PaymentScheduleResponse[]
      total: number
    }>(`/payment-schedule/list`, params)
    return data
  }

  static async getPaymentScheduleStatistic(params: CashflowRequest) {
    const { data } = await manageApiClient.post<any>(
      `/payment-schedule/stats`,
      params
    )
    return data
  }
}
