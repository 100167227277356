/**
 * Language tags that we support in the app.
 * Values correspond to filenames in `/src/constants/languagesData`.
 */
export enum Languages {
  SPANISH = 'es-AR',
  ENGLISH = 'en-GB',
}
/** Key where we store the selected language in local storage. */
export const LANGUAGE_STORAGE_KEY = 'current_language'

export const ACCESS_TOKEN_STORAGE_KEY = 'access_token'
export const FAV_COMPANY = 'fav_company'

export const AVG_MONTH_THRESHOLD_DAYS = 90
