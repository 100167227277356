import { Facility } from '@interfaces/facility'

import manageApiClient from './client'

export class ManageFilterService {
  static async getFacilityList(params: { slug_name: string }) {
    const { data } = await manageApiClient.post<Facility[]>(
      `/monitor-filters/manage-filter`,
      params
    )

    return data
  }
}
