import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import StatisticL5 from '@components/layouts/l5-stat'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { Filter, FilterItem } from '@interfaces/filter'
import { RisksFilters } from '@interfaces/risk'
import { TractionService } from '@services/api-analytics/risk-traction'

const VolumeL5 = ({
  isTotal,
  exportable,
}: {
  isTotal: boolean
  exportable: boolean
}) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes,
    currency = 'USD',
    activeType,
    historicalRate = {},
  } = appliedFilters
  const {
    categoryTypeOptions = [],
    rates = [],
    display_rates = [],
  } = optionFilters

  const isAggregate = activeType === 'All'
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const filters: RisksFilters = {
    date_from: moment.utc(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEndCohort).format('YYYY-MM-DD'),
    slug_name: company?.slug_name,
    filters: categoryTypes,
    is_cumulative: isTotal,
    is_aggregate: isAggregate,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    ['volume', isTotal, filters],
    () => TractionService.getVolume(filters),
    getStaleMins()
  )

  const { data, ...growthRatios } = fetchedData ?? {}

  const cohort = (data || []).filter(
    (v, i, a) => a.findIndex(v2 => v2.cohort === v.cohort) === i
  )

  const chartData = cohort.map(c => {
    const result: { x: number; [key: string]: any } = {
      x: moment(c.cohort).valueOf(),
      ...(rates ?? []).reduce((p = {}, r: any) => {
        return {
          ...p,
          [`fx_${r.code}`]: Intl.NumberFormat(undefined, {
            style: 'decimal',
            notation: 'compact',
            maximumFractionDigits: 2,
          }).format(r.display_rates?.[c.cohort] ?? 1),
        }
      }, {}),
    }
    const fx = historicalRate?.[c.cohort] ?? 1
    const cohortData = (data || []).filter(d => d.cohort === c.cohort)
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString()) * fx
    } else {
      categoryTypes.forEach((ct: number) => {
        const cohortCategory = cohortData.find(cd => cd.id === ct)
        result[`type_${ct}`] =
          parseFloat((cohortCategory?.val ?? 0).toString()) * fx
      })
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [currency, data])

  const seriesConfig = {
    tooltipValueFormat: `${currency} #.0a`,
    type: 'ColumnSeries',
  }
  const series = isAggregate
    ? [
        {
          label: 'All',
          field: 'type_all',
          tooltipLabel: `Volume`,
          ...seriesConfig,
        },
      ]
    : (categoryTypes || []).map((ct: number) => {
        const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        return {
          label: typeVal?.type,
          field: `type_${ct}`,
          isStack: true,
          isTotal: false,
          color,
          ...seriesConfig,
        }
      })

  return (
    <L5ChartstatLayout
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`riskTractionVolume${
            isTotal ? '_total' : '_'
          }by_${activeType}_${exportable}`}
          yLabel={currency}
          data={chartData}
          series={series}
          tooltipSubtitle={
            showHistoricalRateIndicator
              ? (display_rates ?? []).map((r: any) => {
                  return {
                    field: `fx_${r.code}`,
                    title: `USD:${r.code}`,
                  }
                })
              : undefined
          }
          error={error as { message: string }}
          exportable={exportable}
        />
      }
      stat={<StatisticL5 {...growthRatios} />}
    />
  )
}

export default VolumeL5
