import React, { useContext, useEffect, useMemo, useReducer } from 'react'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import AuthContext from '@contexts/auth'
import {
  AccountingRequest,
  DeleteEntityRecord,
  UpdateCompanyInfo,
} from '@interfaces/accounting'

import { ACTIONS } from '../../constants'
import { throttle } from '../../helper'
import { AccountTypeNames } from '../../interfaces/modal.interfaces'
import {
  useAddNewCompany,
  useDeleteEntityRecords,
  useEditCompany,
} from '../../service.runners.hooks'
import { ACTIONS_TYPES } from '../../shared/actions'
import { useCustomContext } from '../../shared/local-context'
import OverlayContainer from '../overlay-container'

import ConfirmationDeleteAlert from './confirmation-delete'
import AccountingForm from './form'
import {
  ACTIONS_TYPES as ACTION_TYPES_LOCAL,
  initialState,
  PopulatedState,
  reducer,
} from './state-manager'

interface ContainerProps {
  accountData: Record<string, any>
}

const FormContainer: React.FC<ContainerProps> = ({ accountData }) => {
  const { company } = useContext(AuthContext)
  const { state, dispatch } = useCustomContext()
  const [formState, dispatchForm] = useReducer(reducer, initialState)
  const { addNewCompany } = useAddNewCompany()
  const { deleteEntityRecords } = useDeleteEntityRecords()
  const { editCompany } = useEditCompany()

  const selectedGeography = useMemo(
    () => accountData?.geography ?? [],
    [accountData?.geography]
  )

  const isEditAction = useMemo(
    () => state.modalPayload?.actions === ACTIONS.EDIT,
    [state.modalPayload?.actions]
  )

  const isMatchingGeography = useMemo(
    () =>
      formState.tags.every(tag =>
        selectedGeography.includes(tag.trimEnd().replace(/(^,)|(,$)/g, ''))
      ),
    [selectedGeography, formState.tags]
  )

  const geography = useMemo(() => {
    if (isEditAction && isMatchingGeography) {
      return selectedGeography
    }
    return formState?.tags
  }, [isEditAction, isMatchingGeography, selectedGeography, formState.tags])

  const editEntityDetails = (data: any) => {
    const params: UpdateCompanyInfo = {
      ...data,
      entityId: accountData?.entityId,
      bucketName: accountData?.bucketName,
      slug_name: company?.slug_name,
      consolidated: true,
      files: formState?.uploadedStatement || undefined,
      geography: geography,
      currency: formState?.currency ?? accountData?.currency,
      status: 'active',
    }
    return editCompany(params)
  }

  const addNewEntity = (data: any) => {
    const params: AccountingRequest = {
      ...data,
      slug_name: company?.slug_name,
      entityId: uuidv4(),
      source: 'manual', //defaulted to manual for now.
      status: 'active',
      uploadedDate:
        formState.uploadedStatement?.length > 0
          ? moment().format('YYYY-MM-DD')
          : '',
      createdAt: moment().format('YYYY-MM-DD'),
      consolidated: true,
      geography:
        formState.populated?.geography || [...formState?.tags].toString(),
      currency: formState?.currency ?? accountData?.currency,
      files: formState?.uploadedStatement || undefined,
      bucketName: data.entityName,
    }
    return addNewCompany(params)
  }

  const handledDeleteEntity = () => {
    if (accountData) {
      dispatchForm({ type: ACTION_TYPES_LOCAL.SET_IS_LOADING, payload: true })
      const params: DeleteEntityRecord = {
        entityId: accountData?.entityId,
        slug_name: company?.slug_name,
        bucketName: accountData.bucketName, //Entity Name might get updated. So getting bucket name on record.
        entitySlugName: accountData.entitySlugName,
      }

      deleteEntityRecords(params)
      return dispatchForm({
        type: ACTION_TYPES_LOCAL.SET_IS_LOADING,
        payload: false,
      })
    }
  }

  const removeTag = (index: number) => {
    dispatchForm({
      type: ACTION_TYPES_LOCAL.SET_TAGS,
      payload: formState.tags.filter((_, i) => i !== index),
    })
  }

  const handleTags = (value?: string) => {
    const input = formState.populated?.geography
    if (input !== '' && !value) {
      dispatchForm({
        type: ACTION_TYPES_LOCAL.SET_TAGS,
        payload: [...formState.tags, input.trimEnd().replace(/(^,)|(,$)/g, '')],
      })
      dispatchForm({
        type: ACTION_TYPES_LOCAL.SET_POPULATED,
        payload: {
          ...formState.populated,
          geography: formState.tags.toString(),
        },
      })
    }
    if (value) {
      dispatchForm({
        type: ACTION_TYPES_LOCAL.SET_TAGS,
        payload: [...formState.tags, value.trimEnd().replace(/(^,)|(,$)/g, '')],
      })
      dispatchForm({
        type: ACTION_TYPES_LOCAL.SET_POPULATED,
        payload: {
          ...formState.populated,
          geography: formState.tags.toString(),
        },
      })
    }
    dispatchForm({
      type: ACTION_TYPES_LOCAL.SET_POPULATED,
      payload: {
        ...formState.populated,
        geography: '',
      },
    })
  }

  const handleGeoGraphy = (value: string) => {
    dispatchForm({
      type: ACTION_TYPES_LOCAL.SET_POPULATED,
      payload: { ...formState.populated, geography: value },
    })
  }
  const handleOnChange = (e: any, key: AccountTypeNames) => {
    const { value } = e.target
    dispatchForm({
      type: ACTION_TYPES_LOCAL.SET_POPULATED,
      payload: { ...formState.populated, [key]: value },
    })
  }

  const handleOpenConfirmation = () => {
    dispatchForm({
      type: ACTION_TYPES_LOCAL.SET_IS_DELETE,
      payload: true,
    })
  }

  const fileUpload = (acceptedFile: Array<any>) => {
    dispatchForm({
      type: ACTION_TYPES_LOCAL.SET_UPLOADED_STATEMENT,
      payload: acceptedFile,
    })
  }

  const handleCancelUpload = () => {
    dispatchForm({
      type: ACTION_TYPES_LOCAL.SET_UPLOADED_STATEMENT,
      payload: [],
    })
  }

  const handleCurrency = (value: string) => {
    dispatchForm({ type: ACTION_TYPES_LOCAL.SET_CURRENCY, payload: value })
  }

  useEffect(() => {
    const populatedPayload = {
      entityName: accountData?.entityName,
      entitySlugName: accountData?.entitySlugName,
    }
    if (isEditAction && accountData) {
      dispatchForm({
        type: ACTION_TYPES_LOCAL.SET_POPULATED,
        payload: populatedPayload as PopulatedState,
      })
    }
  }, [accountData, isEditAction])

  const handleFormSubmit = throttle(
    (data: any) => {
      dispatchForm({ type: ACTION_TYPES_LOCAL.SET_IS_LOADING, payload: true })
      return new Promise(resolve => {
        if (data && isEditAction) {
          editEntityDetails(data)
          resolve(true)
        } else {
          addNewEntity(data)
          resolve(true)
        }
      }).then(success => {
        dispatchForm({
          type: ACTION_TYPES_LOCAL.SET_IS_LOADING,
          payload: false,
        })
        return success
      })
    },
    1000,
    2
  )

  return (
    <div className="relative">
      <ConfirmationDeleteAlert
        onClose={() =>
          dispatch({
            type: ACTIONS_TYPES.OPEN_MODAL,
            payload: {
              modalPayload: {
                isOpenModal: false,
              },
            },
          })
        }
        isOpen={formState.isDelete}
        handleDelete={handledDeleteEntity}
        handleCancel={() =>
          dispatchForm({
            type: ACTION_TYPES_LOCAL.SET_IS_DELETE,
            payload: false,
          })
        }
      />
      {!formState.isDelete && (
        <OverlayContainer
          open={state?.modalPayload?.isOpenModal as boolean}
          handler={() => {
            dispatch({
              type: ACTIONS_TYPES.OPEN_MODAL,
              payload: {
                modalPayload: {
                  isOpenModal: false,
                  title: '',
                  actions: undefined,
                },
              },
            })
          }}
          title={state.modalPayload?.title || 'Edit Entity'}
        >
          <AccountingForm
            submitForm={handleFormSubmit}
            handleTags={handleTags}
            setCurrency={handleCurrency}
            removeTag={removeTag}
            handleOnChange={handleOnChange}
            fileUpload={fileUpload}
            handleOpenConfirmation={handleOpenConfirmation}
            isEdit={isEditAction}
            handleCancel={handleCancelUpload}
            handleGeography={handleGeoGraphy}
            state={formState}
            accountData={
              accountData ? (accountData as UpdateCompanyInfo) : undefined
            }
          />
        </OverlayContainer>
      )}
    </div>
  )
}

export default FormContainer
