import React from 'react'

import { Option, Select } from '@material-tailwind/react'

import { SelectorProps } from './interfaces'

const Selector = ({
  options,
  selectedOption,
  optionKey,
  onSelectedOption,
  className,
}: SelectorProps) => {
  return (
    <Select className={`${className ?? 'shadow-md'}`} value={selectedOption}>
      {options.map((_value: any, index: number) => (
        <Option
          onClick={() =>
            onSelectedOption(optionKey ? _value[optionKey] : _value)
          }
          key={index}
          value={_value}
        >
          {optionKey ? _value[optionKey] : _value}
        </Option>
      ))}
    </Select>
  )
}

export default Selector
