import React from 'react'

import Typography from '@components/atoms/typography'

/**
 *  Technically its NOT a filter
 *  but placed in filter card
 */
const FilterDebtStat = () => {
  return (
    <div className="flex w-full h-full items-center">
      {[
        { value: `4`, title: 'Active Debt Facilities' },
        { value: `$x`, title: 'Outstanding Debt' },
        { value: `10.78%`, title: 'Cost of Capital' },
      ].map((s, i) => {
        return (
          <div
            key={i}
            className="flex flex-col flex-1 justify-center items-center px-4"
          >
            <Typography className="text-3xl font-semibold text-neutral-subtitle-2">
              {s.value}
            </Typography>
            <Typography className="text-sm font-medium text-neutral-body-2 text-center">
              {s.title}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}
export default FilterDebtStat
