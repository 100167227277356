import React, { FC, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import {
  Card,
  CardBody,
  Option,
  Select,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from '@material-tailwind/react'

import BaseLayout, { BaseLayoutProps } from './base-layout'

interface L3BaseLayoutProps extends BaseLayoutProps {
  /**
   * L4 tabs for this L3
   */
  tabs: {
    /**
     * Tab label
     */
    label: string
    /**
     * Tab body component
     */
    component: JSX.Element
    /**
     * Classname that will applied to
     */
    headerClassName?: string
  }[]
  /**
   * fallback when tabs is empty array
   */
  empty?: React.ReactNode
  /**
   * wheter switchable to dropdown or not
   */
  switchable?: boolean
  /**
   * remove breadcrumbs row
   */
  disableBreadcrumbs?: boolean
  /**
   * When there are nested tabs set the layer to 2 to avoid them fighting over
   * the "tab" query param value
   */
  layer?: number
}

/**
 * Base layout for L3 that use tab as main layout of its L4s
 * @param {L3BaseLayoutProps} props Component properties
 * @returns JSX
 */
const L3TabsLayout: FC<L3BaseLayoutProps> = ({
  tabs,
  filters,
  title,
  disableReset = true,
  empty,
  switchable = false,
  rightFooterContent,
  disableBreadcrumbs,
  layer,
}) => {
  const { company } = useContext(AuthContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get(layer ? `tab${layer}` : 'tab')
  const tabIndex = tabs.findIndex(
    t => t.label.toLowerCase().replaceAll(' ', '-') === tab
  )
  const [activeTab, setActiveTab] = useState<number>(
    tabIndex < 0 ? 0 : tabIndex
  )

  const showDropdown = tabs.length > 4 && switchable

  useEffect(() => {
    const nextActiveTab =
      tabIndex < 0 || tabIndex > tabs.length - 1 ? 0 : tabIndex
    setActiveTab(nextActiveTab)
    // force click
    setTimeout(() => {
      const tabElem = document.querySelector(
        `[data-value="${tabs[nextActiveTab]?.label}"]`
      ) as HTMLElement

      if (tabElem) {
        tabElem.click()
      }
    }, 100)
  }, [JSON.stringify(tabs.map(x => x.label)), tab])

  const handleClick = (idx: number, label: string) => {
    setActiveTab(idx)
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set(
      layer ? `tab${layer}` : 'tab',
      label.toLowerCase().replaceAll(' ', '-')
    )
    company && newSearchParams.set('pid', company?.slug_name)

    setSearchParams(newSearchParams.toString())
  }

  return (
    <BaseLayout
      title={title}
      filters={filters}
      disableReset={disableReset}
      rightFooterContent={rightFooterContent}
      disableBreadcrumbs={disableBreadcrumbs}
    >
      <Card>
        <CardBody className="p-0 flex flex-col">
          {tabs.length === 0 && empty && <div className="p-6">{empty}</div>}
          {tabs.length === 0 ? null : showDropdown ? (
            <>
              <div className="flex items-center p-6 [&>div]:!w-auto">
                <Typography className="font-semibold flex-1 text-center text-lg">
                  {tabs[activeTab]?.label}
                </Typography>
                <Select
                  className="[&~ul]:max-h-[200px]"
                  onChange={val => {
                    setActiveTab(Number(val))
                  }}
                  selected={() => tabs[activeTab].label}
                >
                  {tabs.map(({ label }, idx) => (
                    <Option key={idx} value={idx.toString()}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="p-6">{tabs[activeTab]?.component}</div>
            </>
          ) : (
            <Tabs value={tabs[activeTab]?.label} className="rounded-lg">
              <TabsHeader className="z-0 rounded-none bg-neutral-white bg-opacity-100 p-0 border-b-neutral-border-1 border-b-2 overflow-x-auto">
                {tabs.map(({ label, headerClassName = '' }, idx) => (
                  <Tab
                    key={label}
                    value={label}
                    onClick={() => {
                      handleClick(idx, label)
                    }}
                    className={`${headerClassName} ${
                      label === tabs[activeTab]?.label
                        ? 'font-semibold text-primary-pressed'
                        : ''
                    } relative min-h-[70px] hover:bg-primary-surface-2 hover:text-primary-main [&>div:nth-child(1)]:px-6 [&>div:nth-child(2)]:rounded-none [&>div:nth-child(2)]:border-b-primary-hover [&>div:nth-child(2)]:border-b-2 [&>div:nth-child(2)]:!transform-none [&>div:nth-child(2)]:!opacity-100`}
                  >
                    {label}
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody>
                {tabs.map(({ label, component }) => (
                  <TabPanel key={label} value={label}>
                    {component}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
          )}
        </CardBody>
      </Card>
    </BaseLayout>
  )
}

export default L3TabsLayout
