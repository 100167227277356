import { useCallback, useEffect, useRef, useState } from 'react'
import { noop } from 'lodash'
import { usePlaidLink } from 'react-plaid-link'
import { useMutation } from 'react-query'

import BankService from '@services/api-admin/data-source-banks'

export const usePlaid = () => {
  const [linkToken, setLinkToken] = useState<string | null>(null)
  const [config, setConfig] = useState<Parameters<typeof usePlaidLink>[0]>({
    token: '',
    onSuccess: () => noop(),
  })
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined)
  const [passedFormData, setPassedFormData] = useState<{
    entity_ownership: string
    account_use: string
  }>({ entity_ownership: '', account_use: '' })
  const { open, ready } = usePlaidLink(config)
  const slugRef = useRef<string>('')
  const accessTokenRef = useRef<string | undefined>()

  useEffect(() => {
    if (linkToken) {
      setConfig({
        token: linkToken,
        onSuccess,
        onExit,
      })
    }
  }, [linkToken])

  useEffect(() => {
    if (config.token && ready) {
      open()
    }
  }, [config, ready])

  const { mutate: getLinkToken } = useMutation(
    (params: { access_token?: string; slug_name: string }) =>
      BankService.getPlaidLinkToken(params),
    {
      onSuccess: data => {
        setLinkToken(data?.link_token)
      },
    }
  )

  const { mutate: setPlaidAccessToken } = useMutation(
    (payload: { public_token: string; metadata: Record<string, any> }) => {
      return BankService.setPlaidAccessToken({
        slug_name: slugRef.current,
        ...payload,
        ...passedFormData,
      })
    },
    {
      onSuccess: () => setIsLoading(false),
      onError: () => setIsLoading(false),
    }
  )

  const { mutate: updatePlaidAccessToken } = useMutation(
    () => {
      return BankService.updatePlaidAccessToken({
        slug_name: slugRef.current,
        access_token: accessTokenRef.current || '',
      })
    },
    {
      onSuccess: () => setIsLoading(false),
      onError: () => setIsLoading(false),
    }
  )

  const onSuccess = (public_token: string, metadata: any) => {
    if (accessTokenRef.current) {
      updatePlaidAccessToken()
    } else {
      setPlaidAccessToken({ public_token, metadata })
    }
  }

  const onExit = useCallback(() => setIsLoading(false), [])

  const initializePlaid = ({
    slug_name,
    formData,
    access_token,
  }: {
    slug_name: string
    formData?: {
      entity_ownership: string
      account_use: string
    }
    access_token?: string
  }) => {
    setIsLoading(true)
    slugRef.current = slug_name
    accessTokenRef.current = access_token
    formData && setPassedFormData(formData)
    getLinkToken({ access_token, slug_name })
  }

  return { initializePlaid, isLoading }
}
