import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useInfiniteQuery, useMutation, useQuery } from 'react-query'
import * as XLSX from 'xlsx'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { BorrowingBaseService } from '@services/api-manage/borrowing-base'

import { SUMMARY_SORT } from './borrowing-base/summary-table'

const ExportDialogComponent = ({ components }: { components: any[] }) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }

  const { optionFilters, appliedFilters, company } = useContext(AuthContext)

  const { activeDebtDeal = 0, calculationDate: selectedDate } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]
  const facility = activeFacility?.facility ?? ''

  const { get_access } = useUserAccessFeature()
  const FEATURE = `${facility}_manage_monitor_borrowing-base`
  const can_export_bb = get_access(`${FEATURE}_borrowing-base`, 'export')
  const can_export_collateral = get_access(`${FEATURE}_collateral`, 'export')

  const LIMIT = 1000
  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility,
  }

  const API = (component: string, params: any): Promise<any> => {
    switch (component.toLowerCase()) {
      case 'summary':
        return BorrowingBaseService.getSummaryTable(params)
      case 'historical surplus':
        return BorrowingBaseService.getHistoricalSurplus(params)
      case 'cash':
        return BorrowingBaseService.getCash(params)
      case 'eligible pool balance':
        return BorrowingBaseService.getEligiblePool(params)
      case 'borrowing base interest reserve':
        return BorrowingBaseService.getInterestReserve(params)
      case 'collaterals':
        return BorrowingBaseService.getCollateralList(params)
      default:
        return new Promise(resolve => resolve([]))
    }
  }

  const FILTER = (component: string, subcomponent: string) => {
    switch (component.toLowerCase()) {
      case 'summary':
        return {
          ...filters,
          date: selectedDate,
          numberOfLenders: activeFacility?.lender ?? 0,
        }
      case 'historical surplus':
      case 'borrowing base interest reserve':
      case 'collaterals':
        return { ...filters, date: selectedDate }
      case 'cash':
        return { ...filters, date: selectedDate, cashNumber: subcomponent }
      case 'eligible pool balance':
        return { ...filters, date: selectedDate, eligiblePool: subcomponent }
      default:
        return filters
    }
  }

  const COLUMNS = (component: string, data?: any[]) => {
    switch (component.toLowerCase()) {
      case 'summary':
        return [
          {
            title: 'Defined Term',
            render: (r: any) => r.defined_term.split('_').join(' '),
          },
          {
            title: 'Value',
            render: (r: any) => Number(r.value).toFixed(2),
          },
        ]
      case 'historical surplus':
        return [
          {
            title: 'Calculation Date',
            render: (r: any) =>
              moment.utc(r.calculation_date).format('YYYY-MM-DD'),
          },
          {
            title: 'Surplus / Deficient',
            render: (r: any) => Number(r.value).toFixed(2),
          },
        ]
      case 'cash':
        return [
          { title: 'Bank Name', render: (r: any) => r.bank_name },
          { title: 'Account Name', render: (r: any) => r.account_name },
          { title: 'Account Number', render: (r: any) => r.account_number },
          { title: 'Account Type', render: (r: any) => r.account_type },
          { title: 'Statement Date', render: (r: any) => r.balance_as_of },
          {
            title: 'Closing Balance',
            render: (r: any) => Number(r.balance_value).toFixed(2),
          },
          { title: 'Currency', render: (r: any) => r.currency },
        ]
      case 'eligible pool balance':
        return [
          { title: 'Days Past Due ', render: (r: any) => r.dpd_bucket },
          {
            title: `Outstanding Principal (USD)`,
            render: (r: any) =>
              Number(r.outstanding_principal_balance).toFixed(2),
          },
          {
            title: 'Outstanding Principal Balance multiplied by: ',
            render: (r: any) => (r.discount ? `${r.discount * 100}%` : '0%'),
          },
          {
            title: `Discounted Outstanding Principal Balance (USD)`,
            render: (r: any) =>
              Number(r.discounted_outstanding_principal_balance).toFixed(2),
          },
        ]
      case 'borrowing base interest reserve':
        return [
          {
            title: 'Interest Period ',
            render: (r: any) =>
              r.interest_period
                ? moment.utc(r.interest_period).format('MMM-YY')
                : 'Total',
          },
          {
            title: `Interest Payment (USD)`,
            render: (r: any) => Number(r.interest_payment).toFixed(2),
          },
        ]
      case 'collaterals':
        return data?.[0]
          ? Object.keys(data?.[0])
              .filter(x => x !== 'separator')
              .map(x => ({
                title: x,
                render: (r: any) => r[x],
              }))
          : [
              {
                title: 'Assigned Date',
                render: (r: any) =>
                  moment.utc(r[`Assigned Date`]).format('YYYY-MM-DD'),
              },
              { title: 'Loan ID', render: (r: any) => r[`Loan ID`] },
              {
                title: 'Outstanding Balance',
                render: (r: any) => Number(r[`Outstanding Balance`]).toFixed(2),
              },
              {
                title: 'Facility Value',
                render: (r: any) => Number(r[`Facility Value`]).toFixed(2),
              },
              {
                title: 'Status',
                render: (r: any) => r[`Status`],
              },
              {
                title: 'Origination Date',
                render: (r: any) => r[`Originiation Date`],
              },
              { title: 'Product', render: (r: any) => r[`Product`] },
              {
                title: 'Next Payment Date',
                render: (r: any) => r[`Next Payment Date`],
              },
              {
                title: 'Interest Rate (%)',
                render: (r: any) => Number(r[`Interest Rate (%)`]).toFixed(2),
              },
              {
                title: 'Loan Number',
                render: (r: any) => r[`Loan Number`],
              },
              {
                title: 'Next Payment Amount',
                render: (r: any) => r[`Next Payment Amount`],
              },
              { title: 'Term', render: (r: any) => r[`Term`] },
              { title: 'DPD', render: (r: any) => r[`DPD`] },
              { title: 'Geography', render: (r: any) => r[`Geography`] },
              {
                title: 'Disbursement Value',
                render: (r: any) => Number(r[`Disbursement Value`]).toFixed(2),
              },
              { title: 'Max DPD', render: (r: any) => r[`Max DPD`] },
              {
                title: 'Internal Risk Score',
                render: (r: any) => r[`Internal Risk Score`],
              },
            ]
      default:
        return []
    }
  }

  const ADDITIONAL_DATA = (component: string, data: any[]) => {
    if (data.length === 0) {
      return []
    }

    switch (component.toLowerCase()) {
      case 'cash':
        return [
          data?.reduce((prev, cur) => {
            return {
              bank_name: 'Total',
              balance_value: (prev?.balance_value ?? 0) + cur.balance_value,
            }
          }, {}),
        ]
      case 'eligible pool balance':
        const totalEligibleData = data?.reduce((prev, cur) => {
          return {
            dpd_bucket: 'Total',
            outstanding_principal_balance:
              (prev?.outstanding_principal_balance ?? 0) +
              cur.outstanding_principal_balance,
            discounted_outstanding_principal_balance:
              (prev?.discounted_outstanding_principal_balance ?? 0) +
              cur.discounted_outstanding_principal_balance,
          }
        }, {})

        totalEligibleData.discount =
          totalEligibleData.outstanding_principal_balance
            ? (
                totalEligibleData.discounted_outstanding_principal_balance /
                totalEligibleData.outstanding_principal_balance
              ).toFixed(2)
            : 0

        return [totalEligibleData]
      case 'borrowing base interest reserve':
        return [
          data?.reduce((prev, cur) => {
            return {
              interest_payment:
                (prev?.interest_payment ?? 0) + cur.interest_payment,
            }
          }, {}),
        ]
      default:
        return []
    }
  }

  const detailQuery = (component: string, params: any) => {
    return useMutation({
      mutationKey: [component, params],
      mutationFn: () => {
        return API(component, params)
      },
    })
  }

  const paginatedQuery = (component: string, params: any) => {
    return useInfiniteQuery({
      queryKey: [component, params],
      queryFn: ({ pageParam = 0 }) => {
        return API(component, {
          ...params,
          page: pageParam + 1,
          per_page: LIMIT,
        })
      },
      getNextPageParam: (lastPage, pages) => {
        return pages?.length
      },
      enabled: !!selectedDate && open,
    })
  }

  const rawParts = [
    ...(can_export_bb
      ? [
          { component: 'Summary', subcomponent: 'summary' },
          {
            component: 'Historical Surplus',
            subcomponent: 'historical surplus',
          },
          ...(components ?? []),
        ]
      : []),
    ...(can_export_collateral
      ? [{ component: 'Collaterals', subcomponent: 'collaterals' }]
      : []),
  ]

  const parts = rawParts.map(c => {
    const params = FILTER(c.component, c.subcomponent)
    return {
      component: c.component,
      subcomponent: c.subcomponent,
      paginated: c.component === 'Collaterals',
      ...(c.component === 'Collaterals'
        ? paginatedQuery(c.component, params)
        : detailQuery(c.component, params)),
    }
  })
  const currentPart: any = parts.find((p: any) => {
    let total = 0,
      maxPage = 0,
      processedPage = 0

    if (p.paginated) {
      total = p.paginated ? p.data?.pages?.[0]?.total : 1
      maxPage = Math.ceil(total / LIMIT)
      processedPage = (p.data?.pages ?? []).length
    }
    const fetching = p.paginated ? processedPage < maxPage : p.isIdle
    return fetching
  })

  const prepareExport = () => {
    if (open && currentPart) {
      if (currentPart?.fetchNextPage) {
        currentPart?.fetchNextPage()
      }
      if (currentPart?.mutate) {
        currentPart?.mutate()
      }
    }
  }

  useEffect(() => {
    if (open) {
      prepareExport()
    }
  }, [open, currentPart])

  const isDownloadReady = !currentPart
  const _download = async () => {
    setIsDownloading(true)

    const workbook = XLSX.utils.book_new()

    parts.forEach(p => {
      let data = p.paginated
        ? (p as any).data?.pages?.reduce((prev: any, cur: any) => {
            return [...prev, ...cur.data]
          }, [])
        : (p as any).data

      const columns = COLUMNS(p.component, data)
      if (p.component.toLowerCase() === 'summary') {
        data = data.sort((a: any, b: any) => {
          const indexA = SUMMARY_SORT.findIndex(x => a.defined_term.includes(x))
          const indexB = SUMMARY_SORT.findIndex(x => b.defined_term.includes(x))
          return indexA - indexB
        })
      }

      const content = [
        ...(data ?? []),
        ...ADDITIONAL_DATA(p.component, data ?? []),
      ]?.map(d => {
        return columns.reduce((prev, cur) => {
          return {
            ...prev,
            [cur.title]: cur.render(d),
          }
        }, {})
      })

      if ((content ?? []).length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(content ?? [])
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          p.subcomponent.split('_').join(' ')
        )
      }
    })

    await XLSX.writeFile(
      workbook,
      `${company?.legal_name} Borrowing Base ${selectedDate}.xlsx`
    )

    setIsDownloading(false)
  }

  return selectedDate ? (
    <>
      <Button
        className="mr-0.25!shadow-none border border-primary-main text-primary-main py-2 px-6 hover:bg-primary-main hover:text-neutral-white"
        onClick={handleOpen}
      >
        <Typography className="capitalize font-medium text-sm">
          Export
        </Typography>
      </Button>
      <Dialog open={open} handler={() => undefined}>
        <DialogHeader className="justify-between">
          <Typography className="capitalize font-medium text-xl">
            Export Borrowing base
          </Typography>
          <XMarkIcon
            onClick={handleOpen}
            className="w-6 h-6 cursor-pointer hover:opacity-50"
          />
        </DialogHeader>
        <DialogBody divider className="flex flex-col p-6">
          <Typography className="text-sm text-center font-bold">
            Please DO NOT close this dialog or the browser tab until exporting
            is complete
          </Typography>
          <div className="mt-8 flex flex-col gap-4 max-h-[50vh] overflow-y-auto">
            {parts.map((p: any) => {
              const current = p.paginated
                ? p.data?.pages?.reduce(
                    (prev: any, cur: any) => prev + cur.data.length,
                    0
                  )
                : 0
              const total = p.paginated ? p.data?.pages?.[0]?.total : 1
              const fetching = p.paginated ? current < total : p.isLoading
              return (
                <div
                  key={p.subcomponent}
                  className="rounded-lg bg-neutral-border-1 px-4 py-2 flex gap-4 justify-between items-center"
                >
                  <Typography className="capitalize text-sm flex-1">
                    {p.subcomponent.split('_').join(' ')}
                    {p.paginated && total > 0 && (
                      <span className="text-sm flex-1 ml-2 normal-case">
                        {`(${current} of ${total})`}
                      </span>
                    )}
                  </Typography>

                  {fetching && (
                    <div className="rounded h-4 flex-1 overflow-hidden border border-primary-main">
                      <div
                        className="bg-primary-main animate-pulse"
                        style={{
                          height: '100%',
                          width: p.paginated
                            ? `${(current / total) * 100}%`
                            : '100%',
                        }}
                      />
                    </div>
                  )}
                  {!fetching && p.isSuccess && (
                    <CheckCircleIcon className="text-primary-main w-6 h-6" />
                  )}
                  {!fetching && p.isError && (
                    <ExclamationTriangleIcon className="text-secondary-main w-6 h-6" />
                  )}
                </div>
              )
            })}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={_download}
            disabled={!isDownloadReady || isDownloading}
            className="!shadow-none border py-2 px-6 border-primary-main text-primary-main hover:bg-primary-main hover:text-neutral-white disabled:border-neutral-border-3 disabled:bg-neutral-border-2 disabled:text-neutral-border-3"
          >
            {isDownloading && (
              <ArrowPathIcon className="w-4 h-4 mr-4 text-primary-main animate-spin" />
            )}
            Download
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  ) : (
    <></>
  )
}

const ExportDialog = () => {
  const { optionFilters, appliedFilters, company } = useContext(AuthContext)

  const { activeDebtDeal = 0, calculationDate: selectedDate } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility ?? '',
  }

  const componentQuery = useQuery(
    ['component', filters],
    () => BorrowingBaseService.getComponent(filters),
    { ...getStaleMins() }
  )

  const components = (componentQuery.data ?? []).filter(
    d =>
      d.variable.toLowerCase() === 'borrowing base table' &&
      d.subcomponent !== 'borrowing_base'
  )

  return selectedDate && components?.length > 0 ? (
    <ExportDialogComponent components={components} />
  ) : (
    <></>
  )
}

export default ExportDialog
