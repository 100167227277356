import {
  BorrowingBaseRequest,
  BorrowingBaseResponse,
  CalculationResponse,
  CashResponse,
  CollateralListRequest,
  CollateralListResponse,
  CollateralListUploadRequest,
  CollateralPoolListResponse,
  ComponentResponse,
  DetailResponse,
  EligiblePoolBalanceResponse,
  ExcessConcentrationResponse,
  HistoricalSurplusResponse,
  InterestReserveResponse,
  SurplusResponse,
} from '@interfaces/borrowing-base'

import manageApiClient from './client'

export class BorrowingBaseService {
  static async getValidDate(params: { slug_name: string; facility: string }) {
    const { data } = await manageApiClient.post<{ calculation_date: string }[]>(
      `/borrowing-base/valid-date`,
      params
    )
    return data
  }

  static async getComponent(params: { slug_name: string; facility: string }) {
    const { data } = await manageApiClient.post<ComponentResponse[]>(
      `/borrowing-base/borrowing-base-components`,
      params
    )
    return data
  }

  static async getSurplus(params: BorrowingBaseRequest) {
    const { data } = await manageApiClient.post<SurplusResponse>(
      `/borrowing-base/surplus-deficiency-graph`,
      params
    )
    return data
  }

  static async getHistoricalSurplus(params: BorrowingBaseRequest) {
    const { data } = await manageApiClient.post<HistoricalSurplusResponse[]>(
      `/borrowing-base/historical-surplus`,
      params
    )
    return data
  }

  static async getDetail(params: BorrowingBaseRequest) {
    const { data } = await manageApiClient.post<DetailResponse>(
      `/borrowing-base/borrowing-base-details`,
      params
    )
    return data
  }

  static async getLenderSurplus(params: BorrowingBaseRequest) {
    const { data } = await manageApiClient.post<BorrowingBaseResponse[]>(
      `/borrowing-base/lender-bb-surplus-table`,
      params
    )
    return data
  }

  static async getCalculation(params: BorrowingBaseRequest) {
    const { data } = await manageApiClient.post<CalculationResponse>(
      `/borrowing-base/bb-calculation-table`,
      params
    )
    return data
  }

  static async getEligiblePool(params: BorrowingBaseRequest) {
    const { data } = await manageApiClient.post<EligiblePoolBalanceResponse[]>(
      `/borrowing-base/pools-balances-table`,
      params
    )
    return data
  }

  static async getInterestReserve(params: BorrowingBaseRequest) {
    const { data } = await manageApiClient.post<InterestReserveResponse[]>(
      `/borrowing-base/interest-reserve-table`,
      params
    )
    return data
  }

  static async getCash(params: BorrowingBaseRequest) {
    const { data } = await manageApiClient.post<CashResponse[]>(
      `/borrowing-base/cash-table`,
      params
    )
    return data
  }

  static async getExcessConcentration(params: BorrowingBaseRequest) {
    const { data } = await manageApiClient.post<ExcessConcentrationResponse[]>(
      `/borrowing-base/excess-concentration-table`,
      params
    )
    return data
  }

  static async getCollateralPoolList(params: BorrowingBaseRequest) {
    const { data } = await manageApiClient.post<CollateralPoolListResponse[]>(
      `/borrowing-base/collateral-pool`,
      params
    )
    return data
  }

  static async getCollateralList(params: CollateralListRequest) {
    const { data } = await manageApiClient.post<CollateralListResponse>(
      `/borrowing-base/collateral`,
      params
    )
    return data
  }

  static async uploadCollateralReportData(params: CollateralListUploadRequest) {
    const { data } = await manageApiClient.post(
      `/borrowing-base/collateral-report-upload-file`,
      params
    )
    return data
  }

  static async getSummaryTable(params: BorrowingBaseRequest) {
    const { data } = await manageApiClient.post<BorrowingBaseResponse[]>(
      `/borrowing-base/summary-table`,
      params
    )
    return data
  }

  static async getAutochekCustomData(params: BorrowingBaseRequest) {
    const { data } = await manageApiClient.post<BorrowingBaseResponse[]>(
      `/borrowing-base/autochek-compliance-cert-data`,
      params
    )
    return data
  }
}
