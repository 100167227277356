import React, { useContext, useEffect, useState } from 'react'
import HelloSign from 'hellosign-embedded'
import moment from 'moment'
import { useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import FormInput from '@components/form/form-input'
import { DROPBOX_SIGN_CLIENT_ID } from '@constants/config'
import AuthContext from '@contexts/auth'
import { getCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { CategoriesResult } from '@interfaces/manage-reporting'
import { BankInfo, SignatureRequest } from '@interfaces/manage-signature'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { pdf, PDFViewer } from '@react-pdf/renderer'
import { BorrowingBaseService } from '@services/api-manage/borrowing-base'
import { FacilityDetailsService } from '@services/api-manage/facility-details'
import { ActionService } from '@services/api-manage/monitor-action'
import { CashflowService } from '@services/api-manage/monitor-cashflow'
import SignatureService from '@services/api-manage/signature'

import { SignerInput } from '../document-centre/templates/components'

import renderTemplate from './templates'

interface DialogFormAdvanceRequestProps {
  docData?: any
  open?: boolean
  handler: (needupdate?: boolean) => void
  docCentreId: string
  category: CategoriesResult
  can_sign: boolean
}

const DialogFormAdvanceRequest = ({
  open = false,
  handler,
  docData,
  docCentreId,
  category,
  can_sign,
}: DialogFormAdvanceRequestProps) => {
  const client = new HelloSign()
  const { user } = useAuth0()
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)
  const { currencies } = getCurrencies()
  const [submittedFormData, setSubmittedFormData] = useState<any>()
  const [guarantors, setGuarantors] = useState<string[]>([])
  const [borrower, setBorrower] = useState<string>('')
  const [agent, setAgent] = useState<string>('')
  const [bankingInfo, setBankingInfo] = useState<BankInfo>({} as BankInfo)

  const { activeDebtDeal = 0 } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const form = useForm({
    shouldUnregister: false,
  })
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = form
  const { fields, remove } = useFieldArray({
    control,
    name: 'lenders',
  })
  const amount = getValues('amount') ?? 0
  const currency = activeFacility?.agreement_currency ?? 'USD'

  useEffect(() => {
    if (open && !!activeFacility?.facility) {
      setTimeout(() => {
        setValue('currency', activeFacility.agreement_currency ?? 'USD', {
          shouldValidate: true,
        })
        if (!!signers.length) {
          setValue('signer', 0, {
            shouldValidate: true,
          })
          setValue('name', signers?.[0].name, {
            shouldValidate: true,
          })
          setValue('email', signers?.[0].email, {
            shouldValidate: true,
          })
          setValue('title', 'CEO', {
            shouldValidate: true,
          })
        }
      })
    } else {
      reset()
      remove()
    }
  }, [open, activeFacility])

  const _successHandler = () => {
    handler?.(true)
  }

  const { mutate: getPartyDetails, isLoading: isLoadingPartyDetails } =
    useMutation(
      (params: { facility: string; slug_name: string }) => {
        return FacilityDetailsService.getDealPartyDetailsRequest(params)
      },
      {
        onSuccess: data => {
          const guarantorsList = data
            .filter(entry => entry.type === 'Guarantor')
            .map(entry => entry.legal_name)
          setGuarantors(guarantorsList)
          const foundBorrower = data.filter(
            entry => entry.type === 'Borrower'
          )?.[0]?.legal_name
          setBorrower(foundBorrower)
          const foundAgent = data.filter(entry => entry.type === 'Agent')?.[0]
            ?.legal_name
          setAgent(foundAgent)
          const banking = JSON.parse(
            data.find(entry => entry.type === 'Banking')?.supplementary ?? '{}'
          )
          setBankingInfo(banking)
        },
      }
    )

  const { mutate: createAdvanceRequest, isLoading: isLoadingCreate } =
    useMutation(
      (formData: FormData) => {
        return ActionService.createAdvanceRequest(formData)
      },
      {
        onSuccess: _successHandler,
        onError: () => {
          toast.error('There was an issue saving the document', {
            autoClose: false,
          })
        },
      }
    )

  const onSubmit = async (dt: any) => {
    const formData = new FormData()
    formData.append(
      'slug_name',
      activeFacility?.slug_name ?? company?.slug_name ?? ''
    )
    setSubmittedFormData(dt)
    user?.email && formData.append('requester_email', `${user.email}`)
    user?.name && formData.append('requester_name', `${user.name}`)
    formData.append('send_emails', 'false')
    docData?.id && formData.append('id', docData.id)
    docCentreId && formData.append('id', docCentreId)
    formData.append('category', 'Advance Request')
    formData.append('facility', debtDealOptions?.[activeDebtDeal]?.facility)
    formData.append(
      'effective_date',
      moment(dt.advance_date).format('DD-MM-YYYY')
    )
    formData.append(
      'title',
      `Advance Request - ${moment(dt.advance_date).format('DD-MMM-YYYY')}`
    )
    formData.append(
      'files',
      await pdf(
        renderTemplate(category?.subcategory ?? '', {
          slugName: company?.slug_name,
          facility: debtDealOptions?.[activeDebtDeal]?.facility,
          agent: agent,
          borrower: borrower,
          guarantors: guarantors,
          requestDate: moment().format('DD-MM-YYYY'),
          loanAgreementDate: moment
            .utc(activeFacility?.agreement_date)
            .format('DD-MM-YYYY'),
          advanceDate: dt.advance_date
            ? moment(dt.advance_date).format('DD-MM-YYYY')
            : '',
          formData: dt,
          percentOfAvailable: calculatePercentOfAvailable(),
          bankInfo: bankingInfo,
          lenderRatios: generateLenderRatios(),
          originationFee: originationFee,
        })
      ).toBlob()
    )
    formData.append('subject', `Capital advance request form`)
    formData.append(
      'message',
      `${user?.name} has requested your signature via Cascade Debt`
    )
    formData.append(
      'signers',
      JSON.stringify(
        dt.signers
          ? dt.signers.map((s: any, i: number) => ({
              name: s.name,
              order: i,
              emailAddress: s.email,
            }))
          : []
      )
    )
    formData.append('form_values', JSON.stringify(dt))
    formData.append('tags', 'true')
    createEmbeddedSignRequest(formData as SignatureRequest)
    toast.loading('Request in progress', { autoClose: false })
    if (can_sign) {
      handler()
      reset()
    }
  }

  const saveAdvanceRequest = (
    fileUrl: string,
    status: string,
    docId: string
  ) => {
    const formData = new FormData()
    formData.append(
      'slug_name',
      activeFacility?.slug_name ?? company?.slug_name ?? ''
    )
    formData.append('facility', activeFacility?.facility ?? '')
    formData.append('doc_id', docId ?? '')
    formData.append('date', moment().format('YYYY-MM-DD'))
    formData.append('currency', submittedFormData.currency)
    formData.append('amount', Number(submittedFormData.amount).toString())
    formData.append('fileUrl', fileUrl)
    formData.append('status', status)
    formData.append('requester_name', user?.name ?? '')
    formData.append(
      'signer_email',
      JSON.stringify(
        submittedFormData.signers
          ? submittedFormData.signers.map((s: any) => s.email)
          : []
      )
    )
    formData.append('origination_fee', Number(originationFee).toString())
    if (submittedFormData.lenders?.length) {
      submittedFormData.lenders?.forEach((l: any, i: number) => {
        const payout_amount =
          submittedFormData.amount *
          (activeFacility?.[`lender_${i + 1}_advance_request_ratio`] /
            advanceRate)
        formData.append(
          `lenders[${i}][payout_amount]`,
          payout_amount.toString()
        )
        formData.append(
          `lenders[${i}][lender_ratio]`,
          (
            activeFacility?.[`lender_${i + 1}_advance_request_ratio`] /
            advanceRate
          ).toString()
        )
        l.id && formData.append(`lenders[${i}][id]`, l.id)
        l.comment && formData.append(`lenders[${i}][comment]`, l.comment)
        l.proof_of_transfer_file &&
          formData.append(
            `lenders[${i}][proof_of_transfer_file]`,
            l.proof_of_transfer_file
          )
      })
    } else {
      const ratios = generateLenderRatios()
      ratios.forEach((ratio, index) => {
        const payout_amount = submittedFormData.amount * ratio
        formData.append(
          `lenders[${index}][payout_amount]`,
          payout_amount.toString()
        )
        formData.append(`lenders[${index}][lender_ratio]`, ratio.toString())
      })
    }
    createAdvanceRequest(formData)
    setSubmittedFormData(null)
    handler?.(true)
    reset()
  }

  const generateLenderRatios = () => {
    const ratios: number[] = []
    if (!!activeFacility?.facility && activeFacility?.lender > 0) {
      let counter = 1
      while (counter <= activeFacility.lender) {
        ratios.push(
          advanceRate
            ? activeFacility[`lender_${counter}_advance_request_ratio`] /
                advanceRate
            : 0
        )
        counter++
      }
      return ratios
    } else {
      return []
    }
  }

  /**
   * This section gets the data required to calculate the percentage of the amount
   * requested vs the amount currently available to request on the loan
   */
  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility ?? '',
  }
  const { data: statisticData } = useQuery(
    ['paymentScheduleStatistic', filters],
    () => CashflowService.getPaymentScheduleStatistic(filters),
    {
      ...getStaleMins(),
      enabled: !!activeFacility?.facility && !!company?.slug_name,
    }
  )
  const committedAmount = Array(activeFacility?.lender ?? 0)
    .fill('')
    .reduce((prev: number, cur: number, idx: number) => {
      return (
        prev + (activeFacility?.[`lender_${idx + 1}_committed_amount`] ?? 0)
      )
    }, 0)
  const calculatePercentOfAvailable = () => {
    const percentValue =
      (getValues('amount') ?? 0) /
      ((committedAmount ?? 0) - (statisticData?.outstanding_principal ?? 0))
    return Intl.NumberFormat(undefined, {
      style: 'percent',
      maximumFractionDigits: 2,
    }).format(percentValue)
  }

  const signers = category?.signers ? JSON.parse(category.signers) : {}

  /**
   * This section gets the advance rate
   */
  const calcFilters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility ?? '',
    numberOfLenders: activeFacility?.lender ?? 0,
  }
  const { data: calcData } = useQuery(
    ['calculation', calcFilters],
    () => BorrowingBaseService.getCalculation(calcFilters),
    getStaleMins()
  )
  const advanceRate =
    (calcData?.tableResponse ?? [])?.find(
      x => x.defined_term === 'advance_rate'
    )?.value ?? 1

  const originationFee =
    getValues('amount') / advanceRate - getValues('amount') ?? undefined

  /**
   * Signing functionality
   */
  const handleError = () => {
    toast.dismiss()
    toast.error('An Error occurred during signature request creation', {
      autoClose: false,
    })
  }
  const handleSuccess = (data: any) => {
    toast.dismiss()
    if (data.data.signature_request_id && data.data.doc_id) {
      toast.success('Signature Request Created', { autoClose: 5000 })
      saveAdvanceRequest(data.data.final_copy_uri, 'pending', data.data.doc_id)
      return
    }
    if (data.data.claim_url && data.data.doc_id) {
      if (can_sign) {
        client.open(data.data.claim_url, {
          clientId: DROPBOX_SIGN_CLIENT_ID,
          skipDomainVerification: true,
        })
        client.on('sign', () =>
          saveAdvanceRequest(
            data.data.final_copy_uri,
            'signed',
            data.data.doc_id
          )
        )
        client.on('cancel', () =>
          saveAdvanceRequest(
            data.data.final_copy_uri,
            'pending',
            data.data.doc_id
          )
        )
        client.on('error', () =>
          saveAdvanceRequest(
            data.data.final_copy_uri,
            'pending',
            data.data.doc_id
          )
        )
      } else {
        saveAdvanceRequest(
          data.data.final_copy_uri,
          'pending',
          data.data.doc_id
        )
      }
    } else {
      toast.error('Unexpected Error Occurred', { autoClose: false })
    }
  }
  const { mutate: createEmbeddedSignRequest, isLoading: isLoadingDropBoxSign } =
    useMutation(
      (params: SignatureRequest) => {
        return SignatureService.createEmbeddedSignatureURL(params)
      },
      {
        onSuccess: handleSuccess,
        onError: handleError,
      }
    )

  useEffect(() => {
    open &&
      !!activeFacility.facility &&
      company?.slug_name &&
      getPartyDetails({
        facility: activeFacility?.facility,
        slug_name: activeFacility?.slug_name ?? company?.slug_name,
      })
  }, [open, activeFacility])

  const isProcessing =
    isSubmitting ||
    isLoadingDropBoxSign ||
    isLoadingCreate ||
    isLoadingPartyDetails

  return (
    <Dialog open={open} handler={() => undefined} size="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader className="text-xl flex justify-between">
          <div />
          Capital Advance Request
          <XMarkIcon
            onClick={() => handler()}
            className="w-6 h-6 cursor-pointer"
          />
        </DialogHeader>
        <DialogBody
          divider
          className="flex flex-row overflow-y-auto h-[80vh] gap-4"
        >
          <PDFViewer showToolbar={false} className="h-full w-full">
            {renderTemplate(category?.subcategory ?? '', {
              slugName: company?.slug_name,
              facility: debtDealOptions?.[activeDebtDeal]?.facility,
              agent: agent,
              borrower: borrower,
              guarantors: guarantors,
              requestDate: moment().format('DD-MM-YYYY'),
              loanAgreementDate: moment
                .utc(activeFacility?.agreement_date)
                .format('DD-MM-YYYY'),
              advanceDate: getValues('advance_date')
                ? moment(getValues('advance_date')).format('DD-MM-YYYY')
                : '',
              formData: getValues(),
              percentOfAvailable: calculatePercentOfAvailable(),
              bankInfo: bankingInfo,
              lenderRatios: generateLenderRatios(),
              originationFee: originationFee,
            })}
          </PDFViewer>
          <div className="flex flex-col overflow-y-auto">
            <div className="mb-4">
              <div className="text-neutral-border-3 p-3 border border-neutral-border-1 flex justify-center items-center rounded-t-lg bg-neutral-surface-1">
                <Typography className="font-semibold text-sm">
                  Details
                </Typography>
              </div>
              <div className="p-4 flex flex-col border border-t-0 border-neutral-border-1 rounded-b-lg">
                <div className="gap-8 grid grid-cols-3 items-center">
                  <Typography className="font-medium text-sm mb-2">
                    Advance Date
                  </Typography>
                  <div className="col-span-2">
                    <FormInput
                      type="date"
                      value={getValues(`advance_date`) || ''}
                      onSelected={val => {
                        setValue(`advance_date`, val, {
                          shouldValidate: true,
                        })
                      }}
                      error={errors?.advance_date?.message as string}
                    />
                  </div>
                </div>
                <div className="gap-8 grid grid-cols-3 items-center">
                  <Typography className="font-medium text-sm mb-2">
                    Currency
                  </Typography>
                  <div className="col-span-2">
                    <FormInput
                      type="select"
                      value={getValues('currency') || currency}
                      onSelected={val => {
                        setValue('currency', val, {
                          shouldValidate: true,
                        })
                      }}
                      options={(currencies ?? ['USD']).map(c => ({
                        value: c,
                        title: c,
                      }))}
                      disabled={true}
                      error={errors?.currency?.message as string}
                    />
                  </div>
                </div>
                <div className="gap-8 grid grid-cols-3 items-center">
                  <Typography className="font-medium text-sm mb-2">
                    Capital Requested
                  </Typography>
                  <div className="col-span-2">
                    <FormInput
                      type="number"
                      value={getValues('amount') || 0}
                      disabled={isProcessing}
                      {...register('amount', {
                        required: `Amount is required`,
                        onChange: e => {
                          setValue('amount', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        min: {
                          value: 1,
                          message: 'Amount can not be 0',
                        },
                      })}
                      {...{ step: '0.01' }}
                      error={errors?.amount?.message as string}
                    />
                  </div>
                </div>
                <div>
                  <SignerInput form={form} user={user} signers={signers} />
                  {fields.map((f, i) => {
                    return (
                      <div key={i} className="mb-4">
                        <div className="text-neutral-border-3 p-3 border border-neutral-border-1 flex justify-center items-center rounded-t-lg bg-neutral-surface-1">
                          <Typography className="font-semibold text-sm">
                            {`Lender ${i + 1} Request Detail`}
                          </Typography>
                        </div>
                        <div className="p-4 flex flex-col border border-t-0 border-neutral-border-1 rounded-b-lg">
                          <div className="gap-8 grid grid-cols-3 items-center mb-2">
                            <Typography className="text-sm">Ratio</Typography>
                            <div className="col-span-2">
                              <Typography className="text-sm">
                                {Intl.NumberFormat(undefined, {
                                  style: 'percent',
                                  maximumFractionDigits: 2,
                                }).format(
                                  activeFacility?.[
                                    `lender_${i + 1}_advance_request_ratio`
                                  ] ?? 0
                                )}
                              </Typography>
                            </div>
                          </div>
                          <div className="gap-8 grid grid-cols-3 items-center mb-2">
                            <Typography className="text-sm">Amount</Typography>
                            <div className="col-span-2">
                              <Typography className="text-sm">
                                {`${Intl.NumberFormat(undefined, {
                                  style: 'decimal',
                                  maximumFractionDigits: 2,
                                }).format(
                                  amount *
                                    (activeFacility?.[
                                      `lender_${i + 1}_advance_request_ratio`
                                    ] ?? 0)
                                )} ${currency}`}
                              </Typography>
                            </div>
                          </div>
                          <div className="gap-8 grid grid-cols-3 items-center">
                            <Typography className="text-sm mb-2">
                              Additional Note
                            </Typography>
                            <div className="col-span-2">
                              <FormInput
                                type="textarea"
                                value={getValues(`lenders.${i}.comment`) || ''}
                                {...register(`lenders.${i}.comment`, {
                                  onChange: e => {
                                    setValue(
                                      `lenders.${i}.comment`,
                                      e.target.value,
                                      {
                                        shouldValidate: true,
                                      }
                                    )
                                  },
                                })}
                                error={
                                  errors?.[`lenders.${i}.comment`]
                                    ?.message as string
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            {category?.subcategory === 'default' && (
              <div className="mb-4">
                <div className="text-neutral-border-3 p-3 border border-neutral-border-1 flex justify-center items-center rounded-t-lg bg-neutral-surface-1">
                  <Typography className="font-semibold text-sm">
                    Disclosure Notice
                  </Typography>
                </div>
                <div className="p-4 flex flex-col border border-t-0 border-neutral-border-1 rounded-b-lg">
                  <div className="gap-8 grid grid-cols-3 items-center">
                    <div className="col-span-3">
                      <FormInput
                        type="textarea"
                        value={getValues('disclosure')}
                        disabled={isProcessing}
                        {...register(`disclosure`, {
                          onChange: e => {
                            setValue(`disclosure`, e.target.value, {
                              shouldValidate: true,
                            })
                          },
                        })}
                        error={errors?.[`disclosure`]?.message as string}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </DialogBody>
        <DialogFooter className="justify-center">
          <Button
            type="submit"
            color="primary"
            disabled={!isValid || isProcessing}
          >
            {isProcessing && (
              <ArrowPathIcon className="w-4 h-4 mr-2 text-primary-main animate-spin" />
            )}
            Request
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  )
}

export default DialogFormAdvanceRequest
