import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },

  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  disclosure: {
    width: '100%',
    paddingBottom: 4,
    marginBottom: 10,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    borderBottom: '2px solid black',
  },

  flexRowContainer: {
    width: '88%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },

  image: {
    textAlign: 'center',
    width: '20',
    marginVertical: 5,
    marginHorizontal: 100,
  },

  signTags: {
    margin: 10,
    fontSize: 12,
    fontFamily: 'Times-Roman',
    color: 'white',
  },

  text: {
    margin: 10,
    fontSize: 12,
    fontFamily: 'Times-Roman',
  },

  textBold: {
    margin: 10,
    fontSize: 12,
    fontFamily: 'Times-Bold',
  },

  textIndented: {
    margin: 15,
    marginTop: 5,
    marginLeft: 42,
    fontSize: 12,
    fontFamily: 'Times-Roman',
  },

  textNumbered: {
    margin: 15,
    marginTop: 5,
    fontSize: 12,
    fontFamily: 'Times-Roman',
  },

  textSmall: {
    margin: 10,
    fontSize: 8,
    fontFamily: 'Times-Roman',
  },

  title: {
    width: '100%',
    marginBottom: 10,
    paddingBottom: 2,
    fontSize: 15,
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Times-Roman',
    borderBottom: '2px solid black',
  },
})
