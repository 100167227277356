import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { AvailableFeatures } from '@components/app-routes/routes'
import OptionSearch from '@components/atoms/search'
import { FAV_COMPANY } from '@constants/app'
import { Role } from '@constants/role'
import AuthContext from '@contexts/auth'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  StarIcon as StarOutlined,
} from '@heroicons/react/24/outline'
import {
  ArrowRightOnRectangleIcon,
  StarIcon as StarSolid,
} from '@heroicons/react/24/solid'
import {
  Avatar,
  Popover,
  PopoverContent,
  PopoverHandler,
  Tooltip,
} from '@material-tailwind/react'

import SidebarProps from './typing'

const SidebarFooter = ({
  isExpanded,
  companies,
  onSelectCompany,
}: SidebarProps) => {
  const navigate = useNavigate()
  const { user, logout } = useAuth0()
  const { userMetadata, company } = useContext(AuthContext)
  const [favCompany, setFavCompany] = useState<string>()

  const [openSwitcher, setOpenSwitcher] = useState<boolean>(false)

  const ArrowIcon = openSwitcher ? ChevronUpIcon : ChevronDownIcon
  const StarIcon = favCompany == company?.slug_name ? StarSolid : StarOutlined

  const searchOptions = companies.map(c => {
    return {
      label: c.legal_name,
      value: c.slug_name,
      displayhint: true,
      disabledHint:
        !c.currencies_available || c.currencies_available.length === 0
          ? '(No Currency)'
          : '',
      actionIcon: favCompany == c.slug_name && (
        <StarSolid className="w-5 hover:bg-primary-surface-2 hover:cursor-pointer text-peach" />
      ),
    }
  })

  const setFavourite = (slugName: string) => {
    if (favCompany == slugName) {
      localStorage.removeItem(FAV_COMPANY)
      setFavCompany('')
    } else {
      localStorage.setItem(FAV_COMPANY, slugName)
      setFavCompany(slugName)
    }
  }
  useEffect(() => {
    const fav = localStorage.getItem(FAV_COMPANY)
    fav && setFavCompany(fav)
  }, [])

  return (
    <div className={`bg-neutral-white flex  z-10`}>
      <Tooltip content={`${user?.name} - ${user?.email}`} placement="top">
        <button
          className={`border border-neutral-border-1 ${
            isExpanded ? 'p-2' : 'w-sidebar py-2'
          }`}
        >
          <Avatar
            onClick={() => {
              if (AvailableFeatures().includes('administrations_profile')) {
                navigate('/administrations/profile')
              }
            }}
            src={user?.picture}
            alt="avatar"
            variant="circular"
            size="sm"
          />
        </button>
      </Tooltip>
      {isExpanded && (
        <>
          <Popover placement="top-start" open={openSwitcher}>
            <PopoverHandler>
              <div className="border-t border-neutral-border-1 flex flex-1 p-2 items-center">
                <div className="flex w-full items-center">
                  <Tooltip
                    content={`Always view ${company?.legal_name} when logging in`}
                    placement="top"
                  >
                    <StarIcon
                      className="w-5 hover:bg-primary-surface-2 hover:cursor-pointer text-peach"
                      onClick={() =>
                        company?.slug_name && setFavourite(company.slug_name)
                      }
                    />
                  </Tooltip>
                  <button
                    className="flex items-center justify-between w-full px-2"
                    onClick={() =>
                      companies.length > 1 && setOpenSwitcher(!openSwitcher)
                    }
                  >
                    <div className="flex flex-col">
                      <span className="text-neutral-body-1 text-sm capitalize text-left truncate text-ellipsis max-w-[100px]">
                        {company?.legal_name || '-'}
                      </span>
                      <span className="text-neutral-body-1 text-xs text-left">
                        {userMetadata?.isSuperadmin
                          ? Role.superadmin
                          : userMetadata?.companies?.[company?.slug_name ?? '']
                              ?.control || 'user'}
                      </span>
                    </div>
                    {companies.length > 1 && (
                      <div>
                        <ArrowIcon className="w-4 text-neutral-body-2" />
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </PopoverHandler>
            <PopoverContent
              className="border-t border-neutral-border-1 z-[999] rounded-none -ml-[54px] w-side shadow-none translate-y-1.5 flex flex-col h-[200px]"
              onBlur={() => setOpenSwitcher(!openSwitcher)}
            >
              <OptionSearch
                availableOptions={searchOptions}
                searchParam={'Company name'}
                handleChange={(value: string) => {
                  const selectedCom = companies.find(
                    company => company.slug_name === value
                  )
                  selectedCom && onSelectCompany(selectedCom)
                  setOpenSwitcher(false)
                }}
                showOptions={true}
              />
            </PopoverContent>
          </Popover>
          <Tooltip content="Logout" placement="top">
            <button
              className="w-[54px] h-[54px] border border-neutral-border-1 rounded-none justify-center flex items-center"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              <ArrowRightOnRectangleIcon className="w-4 text-neutral-body-2" />
            </button>
          </Tooltip>
        </>
      )}
    </div>
  )
}

export default SidebarFooter
