import React from 'react'
import moment from 'moment'

import { TemplateProps } from '@interfaces/manage-reporting'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { Footer } from '../../../components'
import { styles as globalStyles } from '../../../template_style'

import Row from './row'
import { styles } from './style'

const FssecHsbcComplianceCertTemplate = ({
  effectiveDate,
  customData,
  formData,
}: TemplateProps) => {
  const formattedEffectiveDate = moment(effectiveDate, 'DD-MM-YYYY').format(
    'DD-MMM-YY'
  )
  const fortnightDate =
    moment(effectiveDate, 'DD-MM-YYYY').date() !== 15
      ? moment(effectiveDate, 'DD-MM-YYYY').format('15-MMM-YY')
      : moment(effectiveDate, 'DD-MM-YYYY')
          .subtract(1, 'months')
          .endOf('month')
          .format('DD-MMM-YY')

  const monthlyDate =
    moment(effectiveDate, 'DD-MM-YYYY').date() !== 15
      ? moment(effectiveDate, 'DD-MM-YYYY')
          .subtract(1, 'months')
          .endOf('month')
          .format('DD-MMM-YY')
      : moment(effectiveDate, 'DD-MM-YYYY')
          .subtract(1, 'months')
          .format('15-MMM-YY')

  const quarterlyDate = (
    moment(effectiveDate, 'DD-MM-YYYY').month() <= 2
      ? moment(`${moment(effectiveDate, 'DD-MM-YYYY').year() - 1}-12-31`)
      : moment(effectiveDate, 'DD-MM-YYYY').month() <= 5
      ? moment(`${moment(effectiveDate, 'DD-MM-YYYY').year()}-03-31`)
      : moment(effectiveDate, 'DD-MM-YYYY').month() <= 8
      ? moment(`${moment(effectiveDate, 'DD-MM-YYYY').year()}-06-30`)
      : moment(`${moment(effectiveDate, 'DD-MM-YYYY').year()}-09-30`)
  ).format('DD-MMM-YY')

  return (
    <Document>
      <Page style={globalStyles.body}>
        <Text style={styles.pageTitle}>
          Schedule 5: Form of Fortnightly Borrowing Base Report
        </Text>
        <Row
          title="Reporting Period:"
          titleStyle={styles.textBold}
          value1="Fortnight"
          valuesStyle={styles.valueText}
        />
        <Row
          title="Previous Date:"
          titleStyle={styles.textBold}
          value1={moment(fortnightDate, 'DD-MMM-YY').format(
            'dddd, MMMM DD, YYYY'
          )}
          valuesStyle={styles.valueText}
        />
        <Row
          title="Current Date:"
          titleStyle={{ ...styles.textBold, marginBottom: 10 }}
          value1={moment(effectiveDate, 'DD-MM-YYYY').format(
            'dddd, MMMM DD, YYYY'
          )}
          valuesStyle={styles.valueText}
        />

        <View wrap={false}>
          <Text style={styles.sectionTitle}>Portfolio Reporting</Text>
          <Text style={styles.sectionSubTitle}>Fortnightly:</Text>
          <Row
            title="(a) Portfolio Size of all Loan Assets (net of writeoff)"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['a1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={fortnightDate}
            titleStyle={styles.textIndent}
            value1={customData['a2']}
            valuesStyle={styles.valueText}
          />

          <Row title="(b) Relevant Liabilities" titleStyle={styles.textBold} />
          <Row
            title="(i) Secured Liablities"
            titleStyle={{ ...styles.text, textDecoration: 'underline' }}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['bi1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={fortnightDate}
            titleStyle={styles.textIndent}
            value1={customData['bi2']}
            valuesStyle={styles.valueText}
          />

          <Row
            title="(ii) Other Secured liabilities and obligations"
            titleStyle={{ ...styles.text, textDecoration: 'underline' }}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['bii1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={fortnightDate}
            titleStyle={styles.textIndent}
            value1={customData['bii2']}
            valuesStyle={styles.valueText}
          />

          <Text style={{ ...styles.sectionSubTitle, marginTop: 8 }}>
            Monthly:
          </Text>
          <Row
            title="(c) Write off"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['c1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={monthlyDate}
            titleStyle={styles.textIndent}
            value1={customData['c2']}
            valuesStyle={styles.valueText}
          />

          <Row
            title="(d) Average 12 months loan receivables"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['d1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={monthlyDate}
            titleStyle={styles.textIndent}
            value1={customData['d2']}
            valuesStyle={styles.valueText}
          />

          <Row
            title="(e) Underperforming Loan Assets (DPD 91-120 days)"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['e1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={monthlyDate}
            titleStyle={styles.textIndent}
            value1={customData['e2']}
            valuesStyle={styles.valueText}
          />

          <Row
            title="(f) TNW (Parent)"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['f1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={monthlyDate}
            titleStyle={styles.textIndent}
            value1={customData['f2']}
            valuesStyle={styles.valueText}
          />

          <Row
            title="(g) TNW (Borrower)"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['g1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={monthlyDate}
            titleStyle={styles.textIndent}
            value1={customData['g2']}
            valuesStyle={styles.valueText}
          />

          <Row
            title="(h) Total Risk Weighted Assets (Parent)"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['h1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={monthlyDate}
            titleStyle={styles.textIndent}
            value1={customData['h2']}
            valuesStyle={styles.valueText}
          />

          <Row
            title="(i) Total Risk Weighted Assets (Borrower)"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['i1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={monthlyDate}
            titleStyle={styles.textIndent}
            value1={customData['i2']}
            valuesStyle={styles.valueText}
          />

          <Row
            title="(j) Average Monthly Cash Burn (on TTM basis - Parent)"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['j1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={monthlyDate}
            titleStyle={styles.textIndent}
            value1={customData['j2']}
            valuesStyle={styles.valueText}
          />

          <Row
            title="(k) Cash on hand (Parent)"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['k1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={monthlyDate}
            titleStyle={styles.textIndent}
            value1={customData['k2']}
            valuesStyle={styles.valueText}
          />

          <Row
            title="(l) Loan Loss Provisions (Borrower)"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['l1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={monthlyDate}
            titleStyle={styles.textIndent}
            value1={customData['l2']}
            valuesStyle={styles.valueText}
          />

          <Row
            title="(m) Underperforming Loans (DPD 90+ days)"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['m1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={monthlyDate}
            titleStyle={styles.textIndent}
            value1={customData['m2']}
            valuesStyle={styles.valueText}
          />

          <Text style={{ ...styles.sectionSubTitle, marginTop: 8 }}>
            Quarterly:
          </Text>
          <Row
            title="(n) Gross Margin (Parent)"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['n1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={quarterlyDate}
            titleStyle={styles.textIndent}
            value1={customData['n2']}
            valuesStyle={styles.valueText}
          />
        </View>

        <View wrap={false}>
          <Text style={{ ...styles.sectionTitle, marginTop: 10 }}>
            Portfolio Metrics / Financial Covenants
          </Text>
          <Row
            title="filler"
            titleStyle={{ ...styles.text, color: '#ffffff' }}
            subtitle="FA Requirement"
            subtitleStyle={{
              ...styles.valueTitle,
              width: '20%',
              textAlign: 'center',
            }}
          />
          <Row
            title="Fortnightly"
            titleStyle={{ ...styles.text, textDecoration: 'underline' }}
          />
          <Row
            title="Collateral Coverage Ratio"
            titleStyle={styles.textIndent}
            subtitle=">= 120%"
            subtitleStyle={{
              ...styles.text,
              width: '22%',
              textAlign: 'center',
            }}
            value1={customData['o1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title="Monthly"
            titleStyle={{ ...styles.text, textDecoration: 'underline' }}
          />
          <Row
            title="Delinquency Rate (91-120 DPD) % - 3 months' rolling average"
            titleStyle={styles.textBold}
            subtitle="=< 2.5%"
            subtitleStyle={{
              ...styles.text,
              width: '22%',
              textAlign: 'center',
            }}
            value1={customData['o2']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title="Capital Adequacy Ratio (Parent)"
            titleStyle={styles.textBold}
            subtitle=">= 15%"
            subtitleStyle={{
              ...styles.text,
              width: '22%',
              textAlign: 'center',
            }}
            value1={customData['o3']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title="Capital Adequacy Ratio (Borrower)"
            titleStyle={styles.textBold}
            subtitle=">= 15%"
            subtitleStyle={{
              ...styles.text,
              width: '22%',
              textAlign: 'center',
            }}
            value1={customData['o4']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title="Write-off Ratio"
            titleStyle={styles.textBold}
            subtitle="=< 6%"
            subtitleStyle={{
              ...styles.text,
              width: '22%',
              textAlign: 'center',
            }}
            value1={customData['o5']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title="Cash Reserve for Min. Cash Runway"
            titleStyle={styles.textBold}
            subtitle="=> 6x min."
            subtitleStyle={{
              ...styles.text,
              width: '22%',
              textAlign: 'center',
            }}
            value1={customData['o6']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title="Provision to NPL (90+ DPD) coverage ratio"
            titleStyle={styles.textBold}
            subtitle=">= 100%"
            subtitleStyle={{
              ...styles.text,
              width: '22%',
              textAlign: 'center',
            }}
            value1={customData['o7']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title="Delinquency Rate (90+ DPD) % - 3 months' rolling average"
            titleStyle={styles.textBold}
            subtitle="< 7%"
            subtitleStyle={{
              ...styles.text,
              width: '22%',
              textAlign: 'center',
            }}
            value1={customData['o8']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
        </View>

        <View wrap={false}>
          <Text style={{ ...styles.sectionTitle, marginVertical: 10 }}>
            Borrowing Base Calculation - Fortnightly
          </Text>
          <Row
            title="Eligible Loan Asset Size"
            titleStyle={styles.textBold}
            value1="US$ (or US$ equivalent)"
            valuesStyle={{
              ...styles.textBold,
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Row
            title={formattedEffectiveDate}
            titleStyle={styles.textIndent}
            value1={customData['p1']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title={fortnightDate}
            titleStyle={{ ...styles.textIndent, marginBottom: 10 }}
            value1={customData['p2']}
            valuesStyle={styles.valueText}
          />

          <Row
            title="Facility Limit (A)"
            titleStyle={styles.text}
            value1={customData['p3']}
            valuesStyle={styles.valueText}
          />
          <Row
            title="Borrowing Base [Eligible Receivables] * Advance Rate of 50% (B)"
            titleStyle={styles.text}
            value1={customData['p4']}
            valuesStyle={styles.valueText}
          />
          <Row
            title="Available Facility Limit [Lower of A and B] (C)"
            titleStyle={styles.text}
            value1={customData['p5']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
        </View>

        <View wrap={false}>
          <Text style={{ ...styles.sectionTitle, marginVertical: 10 }}>
            Facility Balance Computation - Fortnightly
          </Text>
          <Row
            value1="Original Currency (If Applicable)"
            value2="US$"
            valuesStyle={styles.valueTitle}
          />
          <Row
            title="Total Outstanding Loans"
            titleStyle={{ ...styles.text, textDecoration: 'underline' }}
          />
          <Row
            title="(A) Loan Denominated in USD"
            titleStyle={styles.text}
            value1={customData['q1a']}
            value2={customData['q1b']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title="(B) Loan Denominated in SGD"
            titleStyle={styles.text}
            value1={customData['q2a']}
            value2={customData['q2b']}
            valuesStyle={styles.valueText}
          />
          <Row
            title="Total Outstanding Amount in USD (A+B)"
            titleStyle={{ ...styles.textBold, marginBottom: 10 }}
            value1=""
            value2={customData['q3']}
            valuesStyle={{
              ...styles.valueText,
              marginBottom: 10,
            }}
          />

          <Row
            title="Breach of Facility Limit?"
            titleStyle={styles.textBold}
            value1={customData['q4']}
            valuesStyle={{
              ...styles.valueText,
              fontFamily: 'Helvetica-Bold',
              backgroundColor: '#ffffff',
              color: '#000000',
            }}
          />
          <Row
            title="Excess Amount above Facility Limit required to be prepaid under Clause 8.2 (Breach of Facility Limit)"
            titleStyle={styles.text}
            value1={customData['q5']}
            valuesStyle={{
              ...styles.valueText,
              backgroundColor: '#ffffff',
              color: '#000000',
            }}
          />
        </View>

        <View wrap={false}>
          <Text style={{ ...styles.sectionTitle, marginVertical: 10 }}>
            DSRA Minimum Required Amount Computation - Fortnightly
          </Text>
          <Row
            title="(a) 6 months interest reserve on outstanding Loans [=(i)+(ii)]"
            titleStyle={styles.textBold}
            value1="Original Currency"
            value2="US$"
            valuesStyle={{ ...styles.valueTitle, borderBottom: 'none' }}
          />
          <Row
            title="(i) Loan denominated in USD (using the applicable Margin + RFR for USD Loan)"
            titleStyle={styles.textIndent}
            value1={customData['r1a']}
            value2={customData['r1b']}
            valuesStyle={{ ...styles.valueText, fontFamily: 'Helvetica-Bold' }}
          />
          <Row
            title="(ii) Loan denominated in SGD (using the applicable Margin + RFR for SGD Loan)"
            titleStyle={{ ...styles.textIndent, marginBottom: 10 }}
            value1={customData['r2a']}
            value2={customData['r2b']}
            valuesStyle={{ ...styles.valueText, marginBottom: 10 }}
          />

          <Row
            title="(b) DSRA Top Up Event"
            titleStyle={{ ...styles.textBold, backgroundColor: '#ffffcc' }}
            title2="(i.e. Delinquency Rate (91-120 DPD > 2.25%)"
            title2Style={styles.text}
            value1={customData['r3']}
            valuesStyle={{
              ...styles.valueText,
              color: '#000000',
              fontFamily: 'Helvetica-Bold',
            }}
          />
          <Row
            title="[Complete section (b) if a DSRA Top Up Event occurs]"
            titleStyle={{ ...styles.text, color: '#c00000' }}
          />
          <Row
            title="(i) 6 months interest reserve (=A+B below)"
            titleStyle={{ ...styles.textIndent, textDecoration: 'underline' }}
            value1={customData['r4']}
            valuesStyle={styles.valueText}
          />
          <Row
            title="(A) Loan denominated in USD (using the applicable Margin + RFR for USD Loan)"
            titleStyle={styles.textIndent}
            value1={customData['r5a']}
            value2={customData['r5b']}
            valuesStyle={styles.valueText}
          />
          <Row
            title="(B) Loan denominated in SGD (using the applicable Margin + RFR for SGD Loan)"
            titleStyle={styles.textIndent}
            value1={customData['r6a']}
            value2={customData['r6b']}
            valuesStyle={styles.valueText}
          />
          <Row
            title="(ii) 15% of principal for all outstanding loans (=A+B below)"
            titleStyle={{ ...styles.textIndent, textDecoration: 'underline' }}
            value1={customData['r7']}
            valuesStyle={styles.valueText}
          />
          <Row
            title="(A) Loan denominated in USD"
            titleStyle={styles.textIndent}
            value1={customData['r8a']}
            value2={customData['r8b']}
            valuesStyle={styles.valueText}
          />
          <Row
            title="(B) Loan denominated in SGD"
            titleStyle={styles.textIndent}
            value1={customData['r9a']}
            value2={customData['r9b']}
            valuesStyle={styles.valueText}
          />
          <Row
            title="(c) DSRA credit balance as of Current Report Date"
            titleStyle={styles.textBold}
          />
          <Row
            title="Required amount to be deposited in the DSRA (=a+b-c)"
            titleStyle={styles.text}
            value1={customData['r10a']}
            value2={customData['r10b']}
            valuesStyle={styles.valueText}
          />
        </View>

        <View wrap={false}>
          <Text style={{ ...styles.sectionTitle, marginVertical: 10 }}>
            Early Amortisation Event
          </Text>
          <Row
            title="- Criteria (a) Failure to fund DSRA"
            titleStyle={styles.textBold}
            value1={customData['s1']}
            valuesStyle={{
              ...styles.valueText,
              color: '#000000',
              fontFamily: 'Helvetica-Bold',
            }}
          />
          <Row title="OR" titleStyle={styles.textIndent} />
          <Row
            title="- Criteria (b) Delinquency Rate (91-120 DPD > 2.75%)"
            titleStyle={{ ...styles.textBold, ...styles.highlighted }}
            value1={customData['s2']}
            valuesStyle={{
              ...styles.valueText,
              color: '#000000',
              fontFamily: 'Helvetica-Bold',
            }}
          />
          <Row title="OR" titleStyle={styles.textIndent} />
          <Row
            title="- Criteria (c) SSD Deed of Novation Event"
            titleStyle={{ ...styles.textBold }}
            value1={customData['s3']}
            valuesStyle={{
              ...styles.valueText,
              color: '#000000',
              fontFamily: 'Helvetica-Bold',
            }}
          />
          <Row title="OR" titleStyle={styles.textIndent} />
          <Row
            title="- Criteria (d) 120% <= Collateral Coverage Ratio < 140%"
            titleStyle={{ ...styles.textBold }}
            value1={customData['s4']}
            valuesStyle={{
              ...styles.valueText,
              color: '#000000',
              fontFamily: 'Helvetica-Bold',
            }}
          />
          <Row
            title="OR"
            titleStyle={{ ...styles.textIndent, ...styles.highlighted }}
          />
          <Row
            title="- Criteria (e) Gross Margin - Parent < USD0.25m"
            titleStyle={{ ...styles.textBold, ...styles.highlighted }}
            value1={customData['s5']}
            valuesStyle={{
              ...styles.valueText,
              color: '#000000',
              fontFamily: 'Helvetica-Bold',
            }}
          />
          <Row
            title="OR"
            titleStyle={{ ...styles.textIndent, ...styles.highlighted }}
          />
          <Row
            title="- Criteria (f) Provision to NPL coverage ratio < 100%"
            titleStyle={{ ...styles.textBold, ...styles.highlighted }}
            value1={customData['s6']}
            valuesStyle={{
              ...styles.valueText,
              color: '#000000',
              fontFamily: 'Helvetica-Bold',
            }}
          />
          <Row
            title="OR"
            titleStyle={{ ...styles.textIndent, ...styles.highlighted }}
          />
          <Row
            title="- Criteria (g) Delinquency Rate (90+ DPD) > 7%"
            titleStyle={{ ...styles.textBold, ...styles.highlighted }}
            value1={customData['s7']}
            valuesStyle={{
              ...styles.valueText,
              color: '#000000',
              fontFamily: 'Helvetica-Bold',
            }}
          />

          <Text style={{ ...styles.text, color: '#c00000', marginTop: 10 }}>
            [Complete the following if YES to either one of more of the criteria
            above]
          </Text>
          <Row title="(a) DSRA credit balance" titleStyle={styles.textIndent} />
          <Row
            title="(b) 6 months interest reserve (=A+B below)"
            titleStyle={styles.textIndent}
          />
          <Row
            title="(A) Loan denominated in USD (using the applicable Margin + RFR for USD Loan)"
            titleStyle={styles.textIndent}
            value1={customData['s8a']}
            value2={customData['s8b']}
            valuesStyle={styles.valueText}
          />
          <Row
            title="(B) Loan denominated in SGD (using the applicable Margin + RFR for SGD Loan)"
            titleStyle={styles.textIndent}
            value1={customData['s9a']}
            value2={customData['s9b']}
            valuesStyle={styles.valueText}
          />
          <Row
            title='If an Early Amortisation Event occurs (or if the Revolving Period ends after 12 months from the initial Utilisation Date), amount required to be prepaid under Clause 8.3 "Expiry of the Revolving Period" = (a) - (b)'
            titleStyle={{ ...styles.text, fontSize: 6, marginTop: 10 }}
            value1={customData['s10']}
            valuesStyle={styles.valueText}
          />
        </View>

        <View wrap={false}>
          <Text style={{ ...styles.sectionTitle, marginTop: 10 }}>
            Miscellaneous - Fortnightly
          </Text>
          <Row
            title="Conversion Rate used in the Fortnightly Borrower Report"
            titleStyle={styles.textBold}
            value1={customData['t1']}
            valuesStyle={styles.valueText}
          />
        </View>
        <View wrap={false}>
          <Text style={{ ...styles.text, marginVertical: 10 }}>
            For and on behalf of FS Capital Pte Ltd
          </Text>
          <Text style={styles.text}>To: HSBC as Facility Agent</Text>
          <Text style={styles.text}>From: FS Capital Pte Ltd</Text>
          <Row
            title={`Dated ${moment().format('DD MMM YYYY')}`}
            titleStyle={{
              ...styles.valueText,
              textAlign: 'left',
              width: '82%',
            }}
          />

          <View style={{ width: '100%', marginVertical: 10 }}>
            <Text style={styles.text}>
              FS Capital Pte. Ltd. - up to US$30,000,000 revolving loan facility
              agreement dated 28 August 2023 (the &quot;Facility
              Agreement&quot;)
            </Text>
            <Text style={{ ...styles.text, marginBottom: 10 }}>
              1. We refer to the Facility Agreement. This is a Fortnightly
              Borrower Report submitted in accordance with clause 20.2
              (&quot;Fortnightly Borrower Report&quot;) of the Facility
              Agreement. Terms defined in the Facility Agreement shall have the
              same meaning in this Fortnightly Borrower Report.
            </Text>
            <Text style={styles.text}>
              2. We confirm that no Default is continuing.
            </Text>
            <Text style={styles.text}>
              3. We confirm and certify the computations and reporting as set in
              the Schedule hereto.
            </Text>
            <Text
              style={{
                ...styles.text,
                backgroundColor: '#ffffcc',
                width: '45%',
              }}
            >
              4. As at {formattedEffectiveDate}, FS Capital Sdn Bhd has become a
              Material Subsidiary.
            </Text>
          </View>

          <View style={styles.signatureBox}>
            <Text style={globalStyles.signTags}>
              [sig|req|signer1|signature]
            </Text>
          </View>
          <Text style={styles.text}>
            Signed: {formData.signers?.[0]?.title || ''}
          </Text>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}

export default FssecHsbcComplianceCertTemplate
