import React, { useContext, useEffect } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { Facility } from '@interfaces/facility'
import { OverviewService } from '@services/api-manage/monitor-overview'

const MasterFacilityOverview = () => {
  const { activeFilters, appliedFilters, setAppliedFilters, optionFilters } =
    useContext(AuthContext)
  const { activeDebtDeal: appliedActiveDebtDeal } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility =
    appliedActiveDebtDeal !== undefined
      ? debtDealOptions?.[appliedActiveDebtDeal]
      : undefined
  const { activeDebtDeal } = activeFilters
  const isMasterFacility = activeFacility?.is_master_facility

  /**
   * Apply filter if no debtdeal applied
   */
  useEffect(() => {
    if (appliedActiveDebtDeal === undefined && activeDebtDeal !== undefined) {
      setAppliedFilters(activeFilters)
    }
  }, [activeFilters])

  const fetchResult = debtDealOptions
    .filter((f: Facility) => f.slug_name)
    .map((f: Facility) =>
      useQuery(
        [
          'master-facility-overview',
          { slug_name: f.slug_name, facility: f.facility },
        ],
        () =>
          OverviewService.getMasterFacilityOverviewData({
            slug_name: f.slug_name ?? '',
            facility: f.facility,
          }),
        {
          ...getStaleMins(),
          enabled: !!isMasterFacility,
        }
      )
    )

  const data = fetchResult.every((query: any) => !query.isLoading)
    ? fetchResult.map((query: any) => query.data ?? [])
    : []

  const isFetching = fetchResult.some((query: any) => query.isLoading)

  return (
    <Table
      loading={isFetching}
      columns={[
        { title: 'Facility', field: 'facility', align: 'center' },
        {
          title: 'Borrowing Base as of',
          field: 'borrowingBaseAsOf',
          align: 'center',
          render: i => moment.utc(i.borrowingBaseAsOf).format('DD-MM-YYYY'),
        },
        {
          title: 'Borrowing Base [Surplus / Deficiency]',
          field: 'borrowingBaseSurplus',
          align: 'right',
          render: i => {
            const isNegative = i.borrowingBaseSurplus < 0
            const formattedValue = Intl.NumberFormat(undefined, {
              style: 'decimal',
              maximumFractionDigits: 2,
            }).format(i.borrowingBaseSurplus)
            return isNegative
              ? `(${formattedValue.replace('-', '')})`
              : formattedValue
          },
        },
        {
          title: 'Portfolio Covenant Breaches',
          field: 'portfolioCovenantBreaches',
          align: 'right',
        },
        {
          title: 'Financial Covenant Breaches',
          field: 'financialCovenantBreaches',
          align: 'right',
        },
        {
          title: 'Overdue Reporting',
          field: 'overdueReports',
          align: 'right',
        },
        // {
        //   title: 'Disbursed Investment',
        //   field: 'disbursedInvestment',
        //   render: i =>
        //     `${Intl.NumberFormat(undefined, {
        //       style: 'decimal',
        //       maximumFractionDigits: 2,
        //       notation: 'compact',
        //     }).format(i.disbursedInvestment * 100 ?? 0)}%`,
        // },
        // {
        //   title: 'Available to Disburse',
        //   field: 'availableToDisburse',
        //   render: i =>
        //     `${Intl.NumberFormat(undefined, {
        //       style: 'decimal',
        //       maximumFractionDigits: 2,
        //       minimumFractionDigits: 2,
        //     }).format(i.availableToDisburse ?? 0)}`,
        // },
      ]}
      data={data}
    />
  )
}

export default MasterFacilityOverview
