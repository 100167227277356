import React, { Fragment, useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Button from '@components/atoms/button'
import FilesUploadDropZone from '@components/file-upload-modal/drop-zone'
import OverlayContainerDropZone from '@components/file-upload-modal/dropzone-container'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { AccountingInfo, UploadFileParams } from '@interfaces/accounting'
import AccountingService from '@services/api-admin/data-source-accounting'

import FormContainer from '../components/form/container-form'
import {
  ACTIONS,
  CASCADE_FOLDER_NAME_FINANCIAL_TEMPLATE,
  CASCADE_TEMPLATE_BUCKET_NAME,
  TIMER,
} from '../constants'
import { timer } from '../helper'
import AlertPopUp from '../pop-ups/alert-popup'
import ValidatorDialog from '../pop-ups/validator-popup'
import {
  downloadFile,
  useUploadSourceFile,
  useUploadStatement,
} from '../service.runners.hooks'
import { ACTIONS_TYPES } from '../shared/actions'
import { useCustomContext } from '../shared/local-context'

import TableStatements from './table'

const TableContainer: React.FC = () => {
  const { state, dispatch } = useCustomContext()
  const [expandRow, setExpandRow] = useState<string | null>()
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [isSourceFile, setIsSourceFile] = useState<boolean>(false)
  const [showFileAlert, setShowFileAlert] = useState<boolean>(false)
  const [uploadDetails, setUploadDetails] = useState<UploadFileParams>(
    {} as any
  )

  const [updateEntityInfo, setUpdateEntityInfo] = useState<
    AccountingInfo | undefined
  >()
  const { company } = useContext(AuthContext)
  const { uploadOnlyStatement, isLoading } = useUploadStatement()
  const { uploadSourceFile } = useUploadSourceFile()
  const handleOpen = (id: string) => {
    setExpandRow(expandRow === id ? null : id)
  }
  const { data, isFetching, refetch } = useQuery(['accounting'], async () => {
    return await AccountingService.retrieveAccountingInfo(company?.slug_name)
  })

  const openFileUpload = (companyInfo: AccountingInfo, bucketName: string) => {
    const params: UploadFileParams = {
      id: companyInfo.entityId,
      entityName: companyInfo.entityName,
      entitySlugName: companyInfo.entitySlugName,
      uploadedDate: moment().format('YYYY-MM-DD'),
      bucketName: bucketName,
      consolidated: true,
      slug_name: company?.slug_name,
    }
    setUploadDetails(params)
    setShowFileAlert(true)
  }

  const openModalEdit = (companyInfo: AccountingInfo) => {
    setUpdateEntityInfo(companyInfo)

    dispatch({
      type: ACTIONS_TYPES.OPEN_MODAL,
      payload: {
        modalPayload: {
          isOpenModal: true,
          title: 'Edit Entity',
          actions: ACTIONS.EDIT,
        },
      },
    })
  }
  const fileUpload = (acceptedFile: Array<any>) => {
    setUploadDetails({ ...uploadDetails, files: acceptedFile })
  }

  const handleFileUpload = () => {
    return isSourceFile
      ? uploadSourceFile(uploadDetails)
      : uploadOnlyStatement(uploadDetails)
  }

  useEffect(
    () => {
      if (state.reload) {
        refetch()

        timer(TIMER, () => {
          dispatch({
            type: ACTIONS_TYPES.RESET_RELOAD_STATE,
            payload: {
              isOpenAlert: false,
              isSuccess: false,
              reload: false,
              openDropZone: false,
              modalPayload: {
                isOpenModal: false,
                title: '',
                actions: undefined,
              },
            },
          })
          setUploadDetails({} as any)
          setExpandRow(null)
          setUpdateEntityInfo(undefined)
        })
      }
    },
    //clean up states after close modal or open alert
    [state.reload]
  )
  useEffect(() => {
    refetch()
  }, [company?.slug_name])

  useEffect(() => {
    if (!data && data?.length <= 0 && !isFetching) {
      setShowAlert(true)
    }
  }, [data, company?.slug_name])
  const downloadTemplate = async () => {
    const url = await downloadFile({
      bucketName: CASCADE_TEMPLATE_BUCKET_NAME,
      folderName: CASCADE_FOLDER_NAME_FINANCIAL_TEMPLATE,
    })
    //triggers download with the url
    window.open(url, '_blank')
  }

  return (
    <Fragment>
      {state?.openValidationModal && (
        <ValidatorDialog
          title="Hold tight, we're validating your file."
          slug_name={company?.slug_name}
          entityId={state?.validation?.entityId}
          entitySlugName={state?.validation?.entitySlugName as string}
        />
      )}
      {state.modalPayload?.isOpenModal && (
        <FormContainer accountData={updateEntityInfo as AccountingInfo} />
      )}

      <AlertPopUp
        open={showFileAlert}
        handler={() => setShowFileAlert(false)}
        header="Upload statement"
        message="Please select statement type"
        isSuccess={true}
      >
        <Button
          className="rounded-lg flex-1 bg-primary-hover text-white"
          onClick={() => {
            setIsSourceFile(true)
            return dispatch({
              type: ACTIONS_TYPES.OPEN_DROPZONE,
              payload: { openDropZone: true },
            })
          }}
        >
          Original statement
        </Button>
        <Button
          className="rounded-lg flex-1  border "
          onClick={() => {
            setIsSourceFile(false)
            return dispatch({
              type: ACTIONS_TYPES.OPEN_DROPZONE,
              payload: { openDropZone: true },
            })
          }}
        >
          Cascade&apos;s template
        </Button>
      </AlertPopUp>

      {!data && data?.length < 1 && (
        <>
          <AlertPopUp
            open={showAlert}
            handler={() => setShowAlert(false)}
            header={(<>Uploading financial statements?</>) as unknown as string}
            isSuccess={true}
            message={
              (
                <>
                  Please make sure to use Cascade&#39;s official template which
                  can be downloaded by clicking the button below.
                </>
              ) as unknown as string
            }
            footerClassName="flex justify-end p-3"
          >
            <Button
              onClick={async () => {
                await downloadTemplate()
              }}
              className="rounded-md"
              color="primary"
            >
              Download Template
            </Button>
          </AlertPopUp>
        </>
      )}
      {state.openDropZone && (
        <OverlayContainerDropZone
          open={state.openDropZone}
          header={`Upload ${
            isSourceFile ? 'original file statement' : "Cascade's statement"
          }`}
        >
          <FilesUploadDropZone uploader={fileUpload} isLoading={isLoading}>
            <Button
              onClick={handleFileUpload}
              className="rounded-lg flex-1"
              disabled={
                !uploadDetails.files ||
                uploadDetails.files.length <= 0 ||
                isLoading
              }
              color={'primary'}
            >
              {isLoading ? (
                <>
                  <ArrowPathIcon className="animate-spin w-6 h-6 text-primary-main mr-1" />
                  Processing...
                </>
              ) : (
                'Upload & Save'
              )}
            </Button>
          </FilesUploadDropZone>
        </OverlayContainerDropZone>
      )}

      {!isFetching && !data ? (
        <div>No active entities</div>
      ) : (
        <div
          className={`${
            !data || isFetching
              ? 'border-none'
              : 'border border-neutral-border-2 rounded-md'
          }`}
        >
          {isFetching && (
            <div className="p-3 align-middle absolute top-1/2 left-1/2">
              <ArrowPathIcon className="animate-spin w-6 h-6 text-primary-main" />
            </div>
          )}
          {!isFetching && data.length > 0 && (
            <TableStatements
              data={data}
              company={company as Record<string, any>}
              handleOpen={handleOpen}
              openFileUpload={openFileUpload}
              openModalEdit={openModalEdit}
              expandRow={expandRow as string}
            />
          )}
        </div>
      )}
    </Fragment>
  )
}

export default TableContainer
