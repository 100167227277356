import {
  formatDecimal,
  formatPercent,
  parseValue,
} from '../mfs-compliance-cert-helpers'

export const generateTableData = (vars: any, data: any) => {
  const covenantData = data?.covenantData?.map((e: any) =>
    e.find((e: any) => e.calculation_date === vars['effective-date'])
  )

  const calcs: Record<string, any> = {}
  calcs['i'] = parseValue(covenantData?.[1]?.debt_to_equity, 'percent') / 100
  calcs['ii'] = parseValue(covenantData?.[0]?.current_ratio, 'percent') / 100
  calcs['iii'] = parseValue(
    covenantData?.[2]?.float_equity_contribution,
    'percent'
  )
  calcs['iv'] = parseValue(
    covenantData?.[3]?.non_hard_currency_exposure,
    'percent'
  )

  return {
    tableWidth: '80%',
    wrap: false,
    style: {
      headerRow: { borderLeftWidth: 0 },
      headerRowText: { fontFamily: 'Times-Bold', margin: 0 },
      table: { borderTopWidth: 0 },
      tableHeaderCol: {
        borderWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 1,
      },
      tableView: { paddingBottom: 4 },
    },
    titleRow: [
      {
        width: '7%',
        content: '',
        position: 'left' as const,
      },
      {
        width: '38%',
        content: 'Financial Covenant',
        position: 'center' as const,
      },
      {
        width: '5%',
        content: '',
        position: 'left' as const,
      },
      {
        width: '22%',
        content: 'Required',
        position: 'center' as const,
      },
      {
        width: '28%',
        content: 'Actual',
        position: 'center' as const,
      },
    ],
    data: [
      [
        {
          width: '7%',
          content: 'i)',
          position: 'left' as const,
        },
        {
          width: '38%',
          content: 'Debt to Equity Ratio',
          position: 'left' as const,
        },
        {
          width: '5%',
          content: ':',
          position: 'left' as const,
        },
        {
          width: '22%',
          content: 'Maximum 2.5x',
          position: 'left' as const,
        },
        {
          width: '28%',
          content: `${formatDecimal(calcs['i'])} times`,
          position: 'left' as const,
        },
      ],
      [
        {
          width: '7%',
          content: 'ii)',
          position: 'left' as const,
        },
        {
          width: '38%',
          content: 'Current Ratio',
          position: 'left' as const,
        },
        {
          width: '5%',
          content: ':',
          position: 'left' as const,
        },
        {
          width: '22%',
          content: 'Maximum 1.0x',
          position: 'left' as const,
        },
        {
          width: '28%',
          content: `${formatDecimal(calcs['ii'])} times`,
          position: 'left' as const,
        },
      ],
      [
        {
          width: '7%',
          content: 'iii)',
          position: 'left' as const,
        },
        {
          width: '38%',
          content: 'Float Equity Contribution',
          position: 'left' as const,
        },
        {
          width: '5%',
          content: ':',
          position: 'left' as const,
        },
        {
          width: '22%',
          content: 'Maximum 85%',
          position: 'left' as const,
        },
        {
          width: '28%',
          content: `${formatPercent(calcs['iii'])}`,
          position: 'left' as const,
        },
      ],
      [
        {
          width: '7%',
          content: 'iv)',
          position: 'left' as const,
        },
        {
          width: '38%',
          content: 'Non Hard Currency Exposure',
          position: 'left' as const,
        },
        {
          width: '5%',
          content: ':',
          position: 'left' as const,
        },
        {
          width: '22%',
          content: 'Maximum 33%',
          position: 'left' as const,
        },
        {
          width: '28%',
          content: `${formatPercent(calcs['iv'])}`,
          position: 'left' as const,
        },
      ],
    ],
  }
}
