import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { CovenantListResponse, CovenantType } from '@interfaces/covenant'
import { CovenantService } from '@services/api-manage/covenant'

interface RawDataProps {
  covenant: CovenantListResponse
  type: CovenantType
}
const RawData = ({ covenant, type }: RawDataProps) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)

  const { activeDebtDeal } = appliedFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility: activeFacility?.facility,
    covenant_name: covenant?.[`${type}_covenant`],
    covenant_type: type,
  }

  const { data, isFetching } = useQuery(
    ['covenant-raw', filters],
    () => CovenantService.getRawData(filters),
    {
      ...getStaleMins(),
      enabled:
        !!debtDealOptions?.[activeDebtDeal]?.facility && !!company?.slug_name,
    }
  )

  const firstData = (data ?? [])?.[0] ?? {}
  const columns = Object.keys(firstData)
    .filter(x => firstData[x] !== null)
    .map(x => ({
      field: x,
      title: x.split('_').join(' '),
      align: 'right',
      head: { align: 'center' },
    }))

  const tableData = data
    ?.sort((a: any, b: any) => (a.cohort < b.cohort ? -1 : 1))
    .filter((x: any) => {
      // only show raw data when last column (taken as chart data) is not zero
      return x[columns[columns.length - 1].field] !== 0
    })

  return (
    <Table
      containerClass="max-h-[60vh] overflow-auto [&>table>thead]:sticky [&>table>thead]:top-0 [&>table>thead>tr>th]:uppercase"
      loading={isFetching}
      title={`${covenant?.[`${type}_covenant`]} Raw`}
      columns={columns as any}
      data={tableData}
    />
  )
}

export default RawData
