import { Languages } from '@constants/app'

export default {
  [Languages.SPANISH]: {
    navbar: {
      summary: 'RESUMEN',
      analytics: 'ANALYSIS',
      raise: 'ELEVAR',
      manage: 'ADMINISTRAR',
    },
  },
}
