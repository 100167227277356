import {
  OverviewDataResponse,
  RiskDataResponse,
  RisksFilters,
} from '@interfaces/risk'

import apiClient from './client'

class IndicatorService {
  static async getIndicator(params: RisksFilters, url: string) {
    return apiClient.post<any>(`indicators/${url}`, params)
  }

  static async getOverview(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<OverviewDataResponse>
      >('/risk-traction/overview-indicators', params)

      return data
    }
  }
}

export default IndicatorService
