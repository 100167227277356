import React, { useContext, useState } from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import AuthContext from '@contexts/auth'
import { Typography } from '@material-tailwind/react'

import BalanceSheetL5 from './balance-sheet'

const BalanceSheetL4 = () => {
  const { appliedFilters } = useContext(AuthContext)
  const { historicalRate, currency } = appliedFilters
  const [hasData, setHasData] = useState<boolean>(false)
  const desc = 'Balance Sheets'
  const tabs = [
    {
      label: 'Balance Sheet',
      desc,
      component: <BalanceSheetL5 hasDataHandler={setHasData} />,
    },
  ]

  return (
    <L4TabsLayout
      tabs={tabs}
      additionalIndicator={
        <div className="flex flex-row">
          {hasData && (
            <>
              <div className="flex flex-row mx-4">
                <Typography className="text-md font-medium text-left ">
                  Currency :
                </Typography>
                <Typography className="text-md font-bold text-left pl-2">
                  {currency}
                </Typography>{' '}
              </div>
              {historicalRate && (
                <ExchangeRateBadge isHistorical={!!historicalRate} />
              )}
            </>
          )}
        </div>
      }
    />
  )
}

export default BalanceSheetL4
