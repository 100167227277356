import React from 'react'

import { Image, Text, View } from '@react-pdf/renderer'

import { styles } from './style'

interface Props {
  fixed?: boolean
  address: string[]
  indent?: number
}

export const MFSHeader = ({ fixed = false, address, indent = 58 }: Props) => {
  return (
    <View
      fixed={fixed}
      style={{ ...styles.headerContainer, paddingLeft: indent }}
    >
      <View style={styles.imageContainer}>
        <Image style={styles.image} src="/company-logos/onafriq-logo.png" />
      </View>
      <View wrap={false} style={styles.addressContainer}>
        {address.map((line: string, idx: number) => (
          <Text
            key={line}
            style={!!idx ? styles.addressRow : styles.companyName}
          >
            {line}
          </Text>
        ))}
      </View>
    </View>
  )
}
