import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  title: {
    width: '100%',
    marginBottom: 10,
    paddingBottom: 4,
    fontSize: 15,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    borderBottom: '1px solid black',
  },

  text: {
    margin: 6,
    fontSize: 12,
    fontFamily: 'Times-Roman',
  },

  tableColumn: {
    borderWidth: 1,
    borderColor: 'black',
    fontSize: 12,
    fontFamily: 'Times-Roman',
    padding: 6,
  },

  tableColumnVariant: {
    backgroundColor: '#EDEDED',
  },

  disclosure: {
    width: '100%',
    marginBottom: 10,
    marginTop: 100,
    paddingBottom: 4,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    borderBottom: '1px solid black',
  },
})
