import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '@components/atoms/button'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'

const NoMatchPage = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-primary-surface-1 w-screen h-[calc(100dvh)] flex flex-col justify-center p-8">
      <img
        src="/svgs/cascade-logo-long.svg"
        alt="Cascade"
        data-testid="cascade-logo"
        className="mt-6 mb-8 px-4 h-[40px]"
      />
      <div className="flex flex-1 flex-col items-center">
        <span className="text-[256px] font-bold text-transparent bg-clip-text bg-gradient-to-r from-primary-main to-secondary-main opacity-30">
          404
        </span>
        <span className="font-semibold text-4xl text-neutral-subtitle-2">
          Page Not Found
        </span>
        <p className="text-neutral-body-2 text-base">
          The page you requested was not found or has been removed.
        </p>
        <div className="mt-12 flex">
          <Button onClick={() => navigate(-1)}>
            <ArrowLeftIcon className="w-[12px] h-[12px] mr-3" /> Go Back
          </Button>
          <Button
            className="ml-4"
            color="primary"
            onClick={() => navigate('/')}
          >
            Dashboard Home
          </Button>
        </div>
      </div>
    </div>
  )
}
export default NoMatchPage
