import { mfsTableRow } from '../../../../components/mfs'
import {
  formatMoney,
  formatPercent,
  parseValue,
} from '../mfs-compliance-cert-helpers'

export const generateTableData = (vars: any, data: any) => {
  const covenantData = data?.covenantData?.map((e: any) =>
    e.find((e: any) => e.calculation_date === vars['effective-date'])
  )

  const calcs: Record<string, any> = {}
  calcs['table-1-a'] = parseValue(covenantData?.[4]?.total_assets, 'number')
  calcs['table-1-b'] = parseValue(
    covenantData?.[4]?.intangible_assets,
    'number'
  )
  calcs['table-1-c'] = parseValue(
    covenantData?.[4]?.total_liabilities,
    'number'
  )
  calcs['table-1-d'] = parseValue(
    covenantData?.[4]?.tangible_net_worth,
    'number'
  )
  calcs['table-1-e'] = calcs['table-1-d'] >= 3000000

  calcs['table-2-a'] = parseValue(
    covenantData?.[0]?.aggregate_shares_held_by_guarantor,
    'number'
  )
  calcs['table-2-b'] = parseValue(
    covenantData?.[0]?.total_equity_of_guarantor,
    'number'
  )
  calcs['table-2-c'] = parseValue(
    covenantData?.[0]?.subordinated_debt_of_guarantor,
    'number'
  )
  calcs['table-2-d'] = parseValue(
    covenantData?.[0]?.double_leverage_ratio,
    'percent'
  )
  calcs['table-2-e'] = calcs['table-2-d'] <= 100

  calcs['table-3-a'] = parseValue(
    covenantData?.[3]?.foreign_currency_assets_guarantor,
    'number'
  )
  calcs['table-3-b'] = parseValue(
    covenantData?.[3]?.foreign_currency_liabilities_guarantor,
    'number'
  )
  calcs['table-3-c'] = parseValue(
    covenantData?.[3]?.total_equity_guarantor,
    'number'
  )
  calcs['table-3-d'] = parseValue(
    covenantData?.[3]?.subordinated_debt_of_guarantor,
    'number'
  )
  calcs['table-3-e'] = parseValue(
    covenantData?.[3]?.foreign_currency_ratio,
    'percent'
  )
  calcs['table-3-f'] = calcs['table-3-e'] > 0 && calcs['table-3-e'] <= 100

  calcs['table-4-a'] = parseValue(
    covenantData?.[2]?.total_indebtedness_of_guarantor,
    'number'
  )
  calcs['table-4-b'] = parseValue(
    covenantData?.[2]?.total_equity_of_guarantor,
    'number'
  )
  calcs['table-4-c'] = parseValue(
    covenantData?.[2]?.subordinated_debt_of_guarantor,
    'number'
  )
  calcs['table-4-d'] =
    parseValue(covenantData?.[2]?.financial_leverage_ratio, 'percent') / 100
  calcs['table-4-e'] = calcs['table-4-d'] <= 1

  calcs['table-5-a'] = parseValue(
    covenantData?.[1]?.total_indebtedness_of_guarantor,
    'number'
  )
  calcs['table-5-b'] = parseValue(
    covenantData?.[1]?.total_equity_of_guarantor,
    'number'
  )
  calcs['table-5-c'] = parseValue(
    covenantData?.[1]?.subordinated_debt_of_guarantor,
    'number'
  )
  calcs['table-5-d'] =
    parseValue(covenantData?.[1]?.financial_leverage_ratio, 'percent') / 100
  calcs['table-5-e'] = calcs['table-5-d'] <= 1

  calcs['table-6-a'] = parseValue(covenantData?.[5]?.total_assets, 'number')
  calcs['table-6-b'] = parseValue(
    covenantData?.[5]?.intangible_assets,
    'number'
  )
  calcs['table-6-c'] = parseValue(
    covenantData?.[5]?.total_liabilities,
    'number'
  )
  calcs['table-6-d'] = parseValue(
    covenantData?.[5]?.tangible_net_worth,
    'number'
  )
  calcs['table-6-e'] = calcs['table-6-d'] >= 20000000

  return [
    {
      title: 'Tangible Net Worth of Issuer',
      tableWidth: '100%',
      wrap: false,
      data: [
        mfsTableRow(
          { content: 'a' },
          { content: 'Total assets of the Issuer' },
          { content: `USD ${formatMoney(calcs['table-1-a'])}` }
        ),
        mfsTableRow(
          { content: 'b' },
          { content: 'Intangible assets of the Issuer' },
          { content: `USD ${formatMoney(calcs['table-1-b'])}` }
        ),
        mfsTableRow(
          { content: 'c' },
          { content: 'Total indebtedness of the Issuer' },
          { content: `USD ${formatMoney(calcs['table-1-c'])}` }
        ),
        mfsTableRow(
          { content: 'd' },
          { content: 'a - b - c' },
          { content: `USD ${formatMoney(calcs['table-1-d'])}` }
        ),
        mfsTableRow(
          { content: 'e' },
          { content: 'Target result of d (Greater than or equal to)' },
          {
            content: `USD ${formatMoney(3000000)}`,
            style: {
              container: {
                backgroundColor: calcs['table-1-e'] ? '#00b050' : 'red',
              },
            },
          }
        ),
      ],
    },
    {
      title: 'Double Leverage Ratio of Guarantor',
      tableWidth: '100%',
      wrap: false,
      data: [
        mfsTableRow(
          { content: 'a' },
          {
            content:
              'Aggregate of any shares or other equity instruments held by Guarantor',
          },
          { content: `USD ${formatMoney(calcs['table-2-a'])}` }
        ),
        mfsTableRow(
          { content: 'b' },
          { content: 'Total equity of the Guarantor' },
          { content: `USD ${formatMoney(calcs['table-2-b'])}` }
        ),
        mfsTableRow(
          { content: 'c' },
          { content: 'Subordinated debt of the Guarantor (max 50% of equity)' },
          { content: `USD ${formatMoney(calcs['table-2-c'])}` }
        ),
        mfsTableRow(
          { content: 'd' },
          { content: 'Ratio of a / (b + c)' },
          { content: formatPercent(calcs['table-2-d']) }
        ),
        mfsTableRow(
          { content: 'e' },
          { content: 'Target result of d (Less than or equal to)' },
          {
            content: formatPercent(100),
            style: {
              container: {
                backgroundColor: calcs['table-2-e'] ? '#00b050' : 'red',
              },
            },
          }
        ),
      ],
    },
    {
      title: 'Foreign Currency Ratio of Guarantor',
      tableWidth: '100%',
      wrap: false,
      data: [
        mfsTableRow(
          { content: 'a' },
          { content: 'Foreign currency assets of the Guarantor' },
          { content: `USD ${formatMoney(calcs['table-3-a'])}` }
        ),
        mfsTableRow(
          { content: 'b' },
          { content: 'Foreign currency liabilities of the Guarantor' },
          { content: `USD ${formatMoney(calcs['table-3-b'])}` }
        ),
        mfsTableRow(
          { content: 'c' },
          { content: 'Total equity of the Guarantor' },
          { content: `USD ${formatMoney(calcs['table-3-c'])}` }
        ),
        mfsTableRow(
          { content: 'd' },
          { content: 'Subordinated debt of the Guarantor (max 50% of equity)' },
          { content: `USD ${formatMoney(calcs['table-3-d'])}` }
        ),
        mfsTableRow(
          { content: 'e' },
          { content: 'Ratio of (a - b) / (c + d)' },
          { content: formatMoney(calcs['table-3-e']) }
        ),
        mfsTableRow(
          { content: 'f' },
          {
            content:
              'Target result of e (Greater than 0 and less than or equal to)',
          },
          {
            content: '1.00',
            style: {
              container: {
                backgroundColor: calcs['table-3-f'] ? '#00b050' : 'red',
              },
            },
          }
        ),
      ],
    },
    {
      title: 'Financial Leverage Ratio of Guarantor',
      tableWidth: '100%',
      wrap: false,
      data: [
        mfsTableRow(
          { content: 'a' },
          { content: 'Total indebtedness of the Guarantor' },
          { content: `USD ${formatMoney(calcs['table-4-a'])}` }
        ),
        mfsTableRow(
          { content: 'b' },
          { content: 'Total equity of the Guarantor' },
          { content: `USD ${formatMoney(calcs['table-4-b'])}` }
        ),
        mfsTableRow(
          { content: 'c' },
          { content: 'Subordinated debt of the Guarantor (max 50% of equity)' },
          { content: `USD ${formatMoney(calcs['table-4-c'])}` }
        ),
        mfsTableRow(
          { content: 'd' },
          { content: 'Ratio of (a / (b - c)' },
          { content: formatMoney(calcs['table-4-d']) }
        ),
        mfsTableRow(
          { content: 'e' },
          {
            content: 'Target result of e (less than or equal to)',
          },
          {
            content: '1.00',
            style: {
              container: {
                backgroundColor: calcs['table-4-e'] ? '#00b050' : 'red',
              },
            },
          }
        ),
      ],
    },
    {
      title: 'Financial Leverage Ratio of Group',
      tableWidth: '100%',
      wrap: false,
      data: [
        mfsTableRow(
          { content: 'a' },
          { content: 'Total indebtedness of the Group' },
          { content: `USD ${formatMoney(calcs['table-5-a'])}` }
        ),
        mfsTableRow(
          { content: 'b' },
          { content: 'Total equity of the Group' },
          { content: `USD ${formatMoney(calcs['table-5-b'])}` }
        ),
        mfsTableRow(
          { content: 'c' },
          { content: 'Subordinated debt of the Group (max 50% of equity)' },
          { content: `USD ${formatMoney(calcs['table-5-c'])}` }
        ),
        mfsTableRow(
          { content: 'd' },
          { content: 'Ratio of (a / (b + c)' },
          { content: formatMoney(calcs['table-5-d']) }
        ),
        mfsTableRow(
          { content: 'e' },
          {
            content: 'Target result of e (less than or equal to)',
          },
          {
            content: '2.00',
            style: {
              container: {
                backgroundColor: calcs['table-5-e'] ? '#00b050' : 'red',
              },
            },
          }
        ),
      ],
    },
    {
      title: 'Tangible Net Worth of Group',
      tableWidth: '100%',
      wrap: false,
      data: [
        mfsTableRow(
          { content: 'a' },
          { content: 'Total assets of the Group' },
          { content: `USD ${formatMoney(calcs['table-6-a'])}` }
        ),
        mfsTableRow(
          { content: 'b' },
          { content: 'Intangible assets of the Group' },
          { content: `USD ${formatMoney(calcs['table-6-b'])}` }
        ),
        mfsTableRow(
          { content: 'c' },
          { content: 'Total indebtedness of the Group' },
          { content: `USD ${formatMoney(calcs['table-6-c'])}` }
        ),
        mfsTableRow(
          { content: 'd' },
          { content: 'a - b - c' },
          { content: `USD ${formatMoney(calcs['table-6-d'])}` }
        ),
        mfsTableRow(
          { content: 'e' },
          {
            content: 'Target result of d (Greater than or equal to)',
          },
          {
            content: `USD ${formatMoney(20000000)}`,
            style: {
              container: {
                backgroundColor: calcs['table-6-e'] ? '#00b050' : 'red',
              },
            },
          }
        ),
      ],
    },
  ]
}
