import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Button from '@components/atoms/button'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/solid'

import { SaveRecordParams } from '../../../../../interfaces/accounting'
import FootNote from '../components/validation/foot-note'
import {
  DatesValidation,
  HistoricalValidation,
  TemplateValidation,
} from '../components/validation/validation-components'
import ValidationListItems from '../components/validation/validation-item-list'
import BalanceSheetValidationTable from '../components/validation/validation-tables/balance-sheet-table'
import {
  CASCADE_FOLDER_NAME_FINANCIAL_TEMPLATE,
  CASCADE_TEMPLATE_BUCKET_NAME,
} from '../constants'
import {
  ApiParams,
  SheetType,
  Status,
  ValidationTypes,
  ValidatorPopUpProps,
} from '../interfaces/validation.interface'
import {
  downloadFile,
  useCancelValidation,
  useSaveStatement,
  useUpdateQuarantineStatus,
  useValidate,
} from '../service.runners.hooks'
import { ACTIONS_TYPES } from '../shared/actions'
import { useCustomContext } from '../shared/local-context'

const downloadTemplate = async (slugName: string) => {
  const url = await downloadFile({
    bucketName: CASCADE_TEMPLATE_BUCKET_NAME,
    folderName: CASCADE_FOLDER_NAME_FINANCIAL_TEMPLATE,
    slug_name: slugName,
  })
  //triggers download with the url
  window.open(url, '_blank')
}

const ValidatorPopUp = ({
  slug_name,
  entityId,
  entitySlugName,
}: ValidatorPopUpProps) => {
  const { validate } = useValidate()
  const { cancelValidation } = useCancelValidation()
  const { updateQuarantineStatus } = useUpdateQuarantineStatus()
  const { saveStatementRecords } = useSaveStatement()
  const { dispatch } = useCustomContext()
  const [sheetType, setSheetType] = useState<SheetType>(SheetType.BALANCE_SHEET)
  const [isProcessed, setIsProcessed] = useState<boolean>(false)
  const [isNext, setIsNext] = useState<boolean>(false)
  const [currentSheetIndex, setCurrentSheetIndex] = useState<number>(0)
  const [clickedIndex, setClickedIndex] = useState<number>(-1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [updatedRecords, setUpdatedRecords] = useState<Record<string, any>[]>(
    []
  )
  const cancelApiParams = {
    entityId,
    slug_name,
  }

  const [list, setList] = useState([
    {
      text: 'Validating template¹',
      validation: ValidationTypes.TEMPLATE,
      status: Status.LOADING,
      data: [],
      quarantineId: '',
      render: (data: Record<string, any>) => (
        <TemplateValidation
          downloadTemplate={() => downloadTemplate(slug_name as string)}
          cancelValidation={() => cancelValidation(cancelApiParams)}
          data={data}
        />
      ),
    },
    {
      text: 'Validating dates²',
      validation: ValidationTypes.DATES,
      status: Status.LOADING,
      data: [],
      quarantineId: '',
      render: (data: Record<string, any>) => (
        <DatesValidation
          downloadTemplate={() => downloadTemplate(slug_name as string)}
          cancelValidation={() => cancelValidation(cancelApiParams)}
          data={data}
        />
      ),
    },
    {
      text: 'Validating Balance Sheet*',
      validation: ValidationTypes.BALANCE_SHEET,
      status: Status.LOADING,
      data: [],
      quarantineId: '',
      render: (
        data: Record<string, any>,
        handleStatus: (s: Status) => void
      ) => (
        <BalanceSheetValidationTable
          data={data?.data}
          onClose={() => setClickedIndex(-1)}
          handleStatus={handleStatus}
        />
      ),
    },
    {
      text: 'Validating historical changes³',
      validation: ValidationTypes.HISTORICAL,
      status: Status.LOADING,
      data: [],
      quarantineId: '',
      render: (
        data: any,
        handleStatus: (s: Status) => void,
        handleUpdatedRecords: (r: Record<string, any>[]) => void
      ) => (
        <HistoricalValidation
          entityId={entityId as string}
          slug_name={slug_name as string}
          handleStatus={handleStatus}
          setClickedIndex={() => setClickedIndex(-1)}
          data={data?.data}
          sheetType={SheetType.BALANCE_SHEET}
          handleUpdateRecords={handleUpdatedRecords}
        />
      ),
    },
  ])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentItem = list[currentSheetIndex]
        const params: ApiParams = {
          slug_name,
          entityId,
          validationType: currentItem?.validation,
          sheetType: sheetType,
        }
        const response = await validate(params as any)

        if (response) {
          const { quarantine_id, validation_data, status, validation_type } =
            response
          if (validation_type === currentItem.validation) {
            setList(prevList => {
              const newList = [...prevList]
              newList[currentSheetIndex].status = status
              newList[currentSheetIndex].data = validation_data as any
              newList[currentSheetIndex].quarantineId = quarantine_id
              return newList
            })

            // If there are more items in the list, increment the currentSheetIndex
            if (currentSheetIndex < list.length - 1) {
              setTimeout(
                () => setCurrentSheetIndex(currentSheetIndex + 1),
                2000
              )
            }
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [currentSheetIndex, sheetType])

  useEffect(() => {
    setCurrentSheetIndex(0)
  }, [sheetType])

  const handleNext = () => {
    setSheetType(SheetType.PROFIT_AND_LOSS)
    setIsProcessed(false)
    setList([
      {
        text: 'Validating template¹',
        validation: ValidationTypes.TEMPLATE,
        status: Status.LOADING,
        data: [],
        quarantineId: '',
        render: (data: Record<string, any>) => (
          <TemplateValidation
            downloadTemplate={() => downloadTemplate(slug_name as string)}
            cancelValidation={() => cancelValidation(cancelApiParams)}
            data={data}
          />
        ),
      },
      {
        text: 'Validating dates²',
        validation: ValidationTypes.DATES,
        status: Status.LOADING,
        data: [],
        quarantineId: '',
        render: (data: Record<string, any>) => (
          <DatesValidation
            downloadTemplate={() => downloadTemplate(slug_name as string)}
            cancelValidation={() => cancelValidation(cancelApiParams)}
            data={data}
          />
        ),
      },
      {
        text: 'Validating historical changes³',
        validation: ValidationTypes.HISTORICAL,
        status: Status.LOADING,
        data: [],
        quarantineId: '',
        render: (
          data: Record<string, any>,
          handleStatus: (s: Status) => void,
          handleUpdatedRecords: (r: Record<string, any>[]) => void
        ) => (
          <HistoricalValidation
            entityId={entityId as string}
            slug_name={slug_name as string}
            handleStatus={handleStatus}
            setClickedIndex={() => setClickedIndex(-1)}
            data={data?.data}
            sheetType={SheetType.PROFIT_AND_LOSS}
            handleUpdateRecords={handleUpdatedRecords}
          />
        ),
      },
    ])
  }

  const handleComplete = async () => {
    try {
      const updateStatusParam = {
        quarantineId: list[0].quarantineId,
        entityId: entityId as string,
        slug_name: slug_name as string,
        sheetType: sheetType,
      }
      const saveParams: SaveRecordParams = {
        ...updateStatusParam,
        statementData:
          Array.isArray(updatedRecords) && updatedRecords.length > 0 //Taking into account possible empty arrays or null
            ? updatedRecords
            : [],
        entitySlugName: entitySlugName as string,
      }
      const res = await updateQuarantineStatus(updateStatusParam)

      if (res === 'SANITIZED') {
        if (sheetType === SheetType.PROFIT_AND_LOSS) {
          setIsProcessed(false)
          const res = await saveStatementRecords(saveParams)
          if (res) {
            return dispatch({
              type: ACTIONS_TYPES.FETCH_SUCCESS,
              payload: {
                openValidationModal: false,
                reload: true,
                modalPayload: {
                  isOpenModal: false,
                },
              },
            })
          }
        } else if (sheetType === SheetType.BALANCE_SHEET) {
          setIsLoading(true)
          setIsProcessed(true)
          setIsNext(true)
          const res = await saveStatementRecords(saveParams)
          if (res) {
            setIsLoading(false)
          }
        }
      }
    } catch (error) {
      console.error(`${error}`)
    }
  }

  const allPassed = useMemo(
    () => list.every((item: any) => item.status === Status.PASSED),
    [list]
  )

  const handleStatusUpdate = (index: number, status: Status) => {
    setList(prevList => {
      const newItem = { ...prevList[index], status }
      return prevList.map((item, i) => (i === index ? newItem : item))
    })
  }
  const handleClick = useCallback((index: number) => {
    setClickedIndex(index)
  }, [])
  const handleUpdatedRecord = (records: Record<string, any>[]) => {
    return setUpdatedRecords(records)
  }

  return (
    <div className="fixed z-50 top-0 left-0 w-full h-full bg-gray-600 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white rounded-lg overflow-hidden max-w-lg w-full p-4">
        <div className="flex justify-between py-4 px-2 border-b border-black border-opacity-50">
          <h3 className="text-center  text-blue-gray-900 antialiased font-sans font-semibold text-md">
            {`Hold tight, we're validating your file\n
             \n-${sheetType}`}
          </h3>
          <XMarkIcon
            onClick={() => cancelValidation(cancelApiParams)}
            className="h-7 w-7 flex-shrink-0   cursor-pointer hover:text-blue -mt-4"
          />
        </div>
        <div className="p-4">
          <>
            <div className="mt-4">
              {list.map((item: any, index: number) => (
                <ValidationListItems
                  key={item.text}
                  entityId={entityId as string}
                  slug_name={slug_name as string}
                  sheetType={sheetType}
                  item={item}
                  setShowChildren={() => handleClick(index)}
                  handleStatusUpdate={(status: Status) =>
                    handleStatusUpdate(index, status)
                  }
                  showChildren={index === clickedIndex}
                  handleUpdatedRecords={handleUpdatedRecord}
                />
              ))}
            </div>
          </>
          <div className="flex justify-between items-center  mt-4">
            <div className=" mt-4">
              <Button
                className="flex items-center disabled:cursor-not-allowed disable:text-gray-300"
                disabled={!allPassed || isProcessed}
                size="sm"
                onClick={handleComplete}
                color="primary"
              >
                {isLoading ? (
                  <>
                    <ArrowPathIcon className="animate-spin w-6 h-6 text-primary-main mr-1" />
                    Processing...
                  </>
                ) : (
                  `Complete ${sheetType}`
                )}
              </Button>
            </div>
            <div className="flex justify-end gap-4 mt-4 flex-grow-0">
              {sheetType === 'Balance Sheet' && (
                <Button
                  size="sm"
                  className={`${
                    isNext ? 'bg-primary-hover text-white' : 'border'
                  } text-sm font-medium disabled:cursor-not-allowed flex-grow-0`}
                  onClick={handleNext}
                  disabled={!allPassed || !isNext || isLoading}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        </div>
        <FootNote />
      </div>
    </div>
  )
}

export default ValidatorPopUp
