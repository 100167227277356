import React from 'react'

import ExchangeRateBadge from '@components/exchange-rate-badge'
import L4TabsLayout from '@components/layouts/l4-tabs-layout'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { useDisbursementCurrencies } from '@helpers/currency-hook'

import AverageL5 from './average'
import DistributionL5 from './distribution'

const ValuesL4 = () => {
  const { filter_access, get_access } = useUserAccessFeature()
  const FEATURE = `analytics_risk_characteristics_value`
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const tabs = [
    {
      label: 'Distribution',
      component: DistributionL5,
    },
    {
      label: 'Average',
      component: AverageL5,
    },
  ]
    .filter(t =>
      filter_access(`${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`)
    )
    .map(t => {
      const exportable = get_access(
        `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`,
        'export'
      )
      return {
        ...t,
        component: <t.component exportable={exportable} />,
      }
    })

  return (
    <L4TabsLayout
      tabs={tabs}
      additionalIndicator={
        showHistoricalRateIndicator ? <ExchangeRateBadge /> : undefined
      }
    />
  )
}

export default ValuesL4
