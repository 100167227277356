import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from 'react-query'

import Timeline from '@components/timeline/timeline'
import AuthContext from '@contexts/auth'
import {
  BellAlertIcon,
  ExclamationCircleIcon,
  EyeIcon,
  PaperAirplaneIcon,
  PencilIcon,
} from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { Dialog, DialogBody } from '@material-tailwind/react'
import SignatureService from '@services/api-manage/signature'

interface SignatureHistoryDialogProps {
  docData?: any
  open: boolean
  handler: () => void
}

const SignatureHistoryDialog = ({
  docData,
  open,
  handler,
}: SignatureHistoryDialogProps) => {
  const [dataUri, setDataUri] = useState<any>()
  const { company } = useContext(AuthContext)

  const handleSuccess = (data: any) => {
    setDataUri(data.data.data_uri)
  }

  const {
    mutate: retrieveDownloadUrl,
    isLoading: isLoadingUri,
    error: uriError,
  } = useMutation(
    (sigRequestId: string) => {
      return SignatureService.getdataUri(sigRequestId, company?.slug_name)
    },
    {
      onSuccess: handleSuccess,
    }
  )

  const buildTimeline = (signatureRequest: any) => {
    const timeline: any[] = []
    const signees: any[] = JSON.parse(signatureRequest.signees)

    timeline.push({
      title: 'Created',
      time: JSON.parse(signatureRequest.dropbox_api_supp).created_at,
      desc: `by ${signatureRequest.author_name}`,
      icon: <PaperAirplaneIcon className="w-5 h-5 text-primary" />,
    })
    signees.forEach((signature: any) => {
      if (signature.last_viewed_at) {
        timeline.push({
          title: 'Last viewed',
          time: signature.last_viewed_at,
          desc: `by ${signature.signer_name}`,
          icon: <EyeIcon className="w-5 h-5 text-primary" />,
        })
      }

      if (signature.last_reminded_at) {
        timeline.push({
          title: 'Last reminded',
          time: signature.last_reminded_at,
          desc: `by ${signature.signer_name}`,
          icon: <BellAlertIcon className="w-5 h-5 text-primary" />,
        })
      }

      if (signature.signed_at) {
        timeline.push({
          title: 'Signed',
          time: signature.signed_at,
          desc: `by ${signature.signer_name}`,
          icon: <PencilIcon className="w-5 h-5 text-primary" />,
        })
      }
    })

    // Sort the timeline array based on the time of each event in ascending order
    timeline.sort(
      (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
    )
    return timeline
  }

  useEffect(() => {
    open && docData?.id && retrieveDownloadUrl(docData.id)
  }, [open])

  const activity =
    docData?.id && docData.dropbox_api_supp !== 's3 upload'
      ? buildTimeline(docData)
      : []

  return (
    <Dialog open={open} size={'xl'} handler={handler}>
      <DialogBody divider className="flex flex-row h-[80vh] overflow-auto">
        <div className="w-3/4 h-full">
          {isLoadingUri && (
            <div className="w-full h-full flex justify-center items-center">
              <ArrowPathIcon className="w-10 h-10 mx-20 text-primary-main animate-spin" />
              Loading document...
            </div>
          )}
          {uriError && (
            <div className="w-full h-full flex justify-center items-center">
              <ExclamationCircleIcon className="w-10 h-10 mx-20 text-red" />
              An Error occurred while retrieving the document. Please try again
              later or contact support if the problem persists.
            </div>
          )}
          {dataUri && <embed src={dataUri} className="w-full h-full" />}
        </div>
        <div className="w-1/4 pl-10 pt-20">
          <Timeline activity={activity} />
        </div>
      </DialogBody>
    </Dialog>
  )
}

export default SignatureHistoryDialog
