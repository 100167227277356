import React, { useEffect, useState } from 'react'

import Button from '@components/atoms/button'

import { SheetType, Status } from '../../interfaces/validation.interface'
import AlertPopUp from '../../pop-ups/alert-popup'

import ValidationTable from './validation-tables/validation-table'

interface AlertValidationProps<T> {
  downloadTemplate: () => void
  cancelValidation: () => void
  data: T
}
export const TemplateValidation: React.FC<
  AlertValidationProps<Record<string, any>>
> = ({ downloadTemplate, cancelValidation, data }) => {
  const [status, setStatus] = useState<boolean>(false)
  useEffect(() => {
    if (data?.status === Status.FAILED) {
      setStatus(true)
    }
  }, [data])

  return (
    <AlertPopUp
      header="Invalid Template"
      message={
        (
          <>
            We cannot process your file, Your file does not match our template.
            Please ensure you are using Cascade&#39;s official template. It can
            be downloaded
            <a
              className="cursor-pointer underline text-blue"
              onClick={downloadTemplate}
            >
              {' '}
              here
            </a>
            .
          </>
        ) as unknown as string
      }
      open={status}
      handler={cancelValidation}
    >
      <Button color="primary" onClick={cancelValidation}>
        Understand
      </Button>
    </AlertPopUp>
  )
}

export const DatesValidation: React.FC<
  AlertValidationProps<Record<string, any>>
> = ({ downloadTemplate, cancelValidation, data }) => {
  const [status, setStatus] = useState<boolean>(false)
  useEffect(() => {
    if (data?.status === Status.FAILED) {
      setStatus(true)
    }
  }, [data])
  return (
    <AlertPopUp
      header="Invalid Dates"
      message={
        (
          <>
            We cannot process your file. Statement contains invalid dates or
            dates beyond current month. Please ensure you are using
            Cascade&rsquo;s official template. It can be downloaded
            <a
              className="cursor-pointer underline text-blue"
              onClick={downloadTemplate}
            >
              {' '}
              here
            </a>
            . Our template will fill your dates for you, ensuring they are valid
            and formatted correctly.
          </>
        ) as unknown as string
      }
      open={status}
      handler={cancelValidation}
    >
      <Button onClick={cancelValidation}>Understand</Button>
    </AlertPopUp>
  )
}
interface HistoricalValidationProps<T> {
  entityId: string
  slug_name: string
  handleStatus: (s: Status) => void
  setClickedIndex: (v: number) => void
  data: T
  sheetType: SheetType
  handleUpdateRecords: (r: Record<string, any>[]) => void
}
export const HistoricalValidation: React.FC<
  HistoricalValidationProps<Record<string, any>>
> = ({
  entityId,
  slug_name,
  handleStatus,
  data,
  setClickedIndex,
  sheetType,
  handleUpdateRecords,
}) => {
  return (
    <ValidationTable
      entityId={entityId as string}
      onClose={() => setClickedIndex(-1)}
      sheetType={sheetType}
      data={data}
      slug_name={slug_name as string}
      handleStatus={handleStatus}
      handleUpdatedRecords={handleUpdateRecords}
    />
  )
}
