import React from 'react'

import Table from '@components/table'

import { ACCOUNTS_MOCK } from './mock'

const AccountsL4 = () => {
  return (
    <div className="flex flex-col p-4">
      <Table
        columns={[
          { title: 'Bank Institution', field: 'bank_institution' },
          { title: 'Acct. Number', field: 'account_number' },
          { title: 'Nick Name', field: 'nickname' },
          { title: 'Entity Ownership', field: 'entity_ownership' },
          { title: 'Type', field: 'type' },
          { title: 'Borrowing Base', field: 'borrowing_base' },
          { title: 'Reporting', field: 'reporting' },
          { title: 'Portfolio Covenant', field: 'portfolio_covenant' },
          { title: 'Financial Covenant', field: 'financial_covenant' },
          { title: 'Integration', field: 'integration' },
        ]}
        data={ACCOUNTS_MOCK}
      />
    </div>
  )
}

export default AccountsL4
