import React from 'react'
import moment from 'moment'

import { TemplateProps } from '@interfaces/manage-reporting'
import { Document, Page, View } from '@react-pdf/renderer'

import { Table, Text } from '../../../../components'
import { MFSFooter, MFSHeader } from '../../../../components/mfs'
import { styles } from '../../../../template_style'

import { generateTableData } from './helpers'

const textOtherStyles = {
  marginVertical: 8,
}

export const MfsNorsadComplianceCertTemplate = ({
  customData,
  formData,
  agreementDate,
}: TemplateProps) => {
  const tableData = generateTableData(formData, customData)

  return (
    <Document>
      <Page style={{ paddingBottom: 68 }}>
        <MFSHeader
          fixed={true}
          address={customData.headerAddress}
          indent={30}
        />
        <View
          style={{ ...styles.body, paddingHorizontal: 30, paddingTop: -12 }}
        >
          <Text size="small" bold otherStyles={{ marginTop: 15 }}>
            Norsad Finance Limited
          </Text>
          {customData?.bodyAddress?.map((line: string) => (
            <Text key={line} size="small">
              {line}
            </Text>
          ))}
          <Text size="small" otherStyles={textOtherStyles}>
            {moment().format('DD MMMM YYYY')}
          </Text>
          <Text size="small" bold otherStyles={textOtherStyles}>
            FACILITY AGREEMENT FOR USD 10 MILLION BETWEEN NORSAD FINANCE AND
            ONAFRIQ LIMITED DATED{' '}
            {moment(agreementDate, 'DD-MM-YYYY')
              .format('DD MMMM YYYY')
              .toUpperCase()}{' '}
            <Text size="small">
              (the <Text bold>Agreement</Text>)
            </Text>
          </Text>
          <Text size="small" otherStyles={textOtherStyles}>
            1. We refer to Clause 18 of the Agreement.
          </Text>
          <Text size="small" otherStyles={textOtherStyles}>
            2. This is a Compliance Certificate.
          </Text>
          <Text size="small" otherStyles={textOtherStyles}>
            3. Terms used in this Compliance Certificate have the same meaning
            as in the Agreement.{' '}
          </Text>
          <Text size="small" otherStyles={textOtherStyles}>
            4. We hereby confirm the following in respect of the Measurement
            Period ended on{' '}
            {formData['effective-date']
              ? moment(formData['effective-date']).format('DD MMMM YYYY')
              : '[Effective date]'}
            .
          </Text>
          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Table data={tableData} size="small" />
          </View>
          <Text size="small" otherStyles={textOtherStyles}>
            5. We annex hereto our Financial Covenants computations in respect
            of compliance with the Financial Covenants.
          </Text>
          <Text size="small" otherStyles={textOtherStyles}>
            6. We confirm that no Default is continuing.
          </Text>
          <Text size="small" otherStyles={textOtherStyles}>
            Signed by
          </Text>
          <Text size="small" otherStyles={styles.signTags}>
            [sig|req|signer1|signature]
          </Text>
          <Text
            size="small"
            underline
            otherStyles={{
              color: 'white',
              fontSize: 6,
              textDecorationColor: 'black',
            }}
          >
            {'hidden filler text as long as the line under the signature'}
          </Text>
          <Text size="small" otherStyles={textOtherStyles}>
            {formData?.signers?.[0]?.title || `[Authorized Officer Title]`}
          </Text>
        </View>
        <MFSFooter indent={30} />
      </Page>
    </Document>
  )
}
