import {
  OverviewDataResponse,
  RiskDataResponse,
  RisksFilters,
  StackDataResponseByBorrowers,
  StackDataResponseByOpd,
  TractionDataResponse,
} from '@interfaces/risk'

import apiClient from './client'

export class DelinquencyService {
  static async getFpdByCohort(params: RisksFilters) {
    if (params.filters.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<TractionDataResponse>
      >('/delinquency/first-payment-default-rate', params)

      return data
    }
  }

  static async getPar(params: RisksFilters) {
    if (params.filters.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<TractionDataResponse>
      >('/delinquency/portfolio-at-risk', params)

      return data
    }
  }

  static async getRdr(params: RisksFilters) {
    if (params.filters.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<TractionDataResponse>
      >('/delinquency/rolling-default-rate', params)

      return data
    }
  }

  static async getOutstandingDelinq(params: RisksFilters) {
    if (params.filters.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<StackDataResponseByOpd>
      >('/delinquency/outstanding-by-delinquency', params)

      return data
    }
  }
  static async getBorrowerConcentration(params: RisksFilters) {
    if (params.filters.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<StackDataResponseByBorrowers>
      >('/delinquency/borrower-concentration', params)

      return data
    }
  }
  static async getDelinqByCohort(params: RisksFilters) {
    if (params.filters.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<StackDataResponseByOpd>
      >('/delinquency/cohort-by-delinquency', params)

      return data
    }
  }
  static async getOverview(params: RisksFilters) {
    if (params.filters?.length) {
      const { data } = await apiClient.post<
        RiskDataResponse<OverviewDataResponse>
      >('/delinquency/overview', params)

      return data
    }
  }
}
