import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/risk'
import { Option, Select } from '@material-tailwind/react'
import CohortService from '@services/api-analytics/risk-cohort'

const CohortRollRatesL5 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    categoryTypes,
    currency = 'USD',
    activeType,
    historicalRate = {},
  } = appliedFilters
  const { display_rates = [] } = optionFilters

  const [isNumberOfLoan, setIsNumberOfLoan] = useState<boolean>(true)
  const [cohort, setCohort] = useState<string>('All')

  const filters: RisksFilters = {
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name,
    filters: categoryTypes,
  }

  const { error, data, isFetching } = useQuery(
    ['riskCohortRollRatesCohort', filters],
    () => CohortService.getCohortRollRates(filters),
    getStaleMins()
  )

  const cohorts = (data?.data ?? []).reduce(
    (prev: string[], cur) =>
      prev.includes(cur.disbursed_cohort)
        ? prev
        : cur.disbursed_cohort.toLowerCase().includes('all')
        ? [cur.disbursed_cohort, ...prev]
        : [...prev, cur.disbursed_cohort],
    []
  )

  const dpds = (data?.data ?? [])
    .reduce(
      (prev: string[], cur) =>
        prev.includes(cur.bucket_label) ? prev : [...prev, cur.bucket_label],
      []
    )
    .sort(
      (a, b) =>
        Number(a.split(/(?:[-+\s]+)/)[0]) - Number(b.split(/(?:[-+\s]+)/)[0])
    )

  const currentData = (data?.data ?? []).filter(
    d => d.disbursed_cohort === cohort
  )
  const currentMoBs = currentData?.reduce(
    (prev: number[], cur) =>
      prev.includes(cur.mob) ? prev : [...prev, cur.mob],
    []
  )

  const chartData = currentMoBs.map(m => {
    const res: { x: number; [key: string]: number | string } = { x: m }
    dpds.forEach((d, j) => {
      const current = currentData.find(x => x.bucket_label === d && x.mob === m)
      res[d] = !current
        ? 0
        : isNumberOfLoan
        ? (current?.outstanding_loans / current?.total_loans) * 100
        : (current?.outstanding_balance / current?.total_disbursed) * 100

      if (j === 0) {
        res['Paid off'] = !current
          ? 0
          : isNumberOfLoan
          ? (current?.paid_off_loans / current?.total_loans) * 100
          : (current?.paid_off_amount / current?.total_disbursed) * 100
      }
    })
    return res
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [cohort, data, isNumberOfLoan])
  /** end */

  /**
   * zoom
   */
  const xStart = 0
  const xSpan = Math.floor(data?.avgTermInMonth ?? 0) + 5
  const xEnd =
    chartData?.length === 0
      ? 0
      : xSpan >= chartData?.length
      ? chartData?.[chartData?.length - 1]?.x
      : chartData?.[xSpan - (xSpan > 0 ? 1 : 0)].x /
        chartData?.[chartData?.length - 1]?.x

  const maxCohort = moment.max(
    cohorts.filter(c => moment(c).isValid()).map(c => moment(c))
  )
  const conversionCohort = moment(cohort).isValid() ? moment(cohort) : maxCohort
  const fx = historicalRate?.[conversionCohort.format('YYYY-MM-DD')] ?? 1

  return (
    <L5ChartstatLayout
      chart={
        <>
          <div className="flex justify-end -mt-12">
            {[
              ...(data
                ? [
                    {
                      type: 'dropdown',
                      title: 'Cohort',
                      value: cohort,
                      options: cohorts.map(c => ({
                        value: c,
                        title: c.toLowerCase().includes('all')
                          ? c.split('_').join(' ')
                          : moment(c).format('MMM-YY'),
                      })),
                      action: (val: string) => setCohort(val),
                    },
                  ]
                : []),
              {
                type: 'toggle',
                title: 'Measurement',
                options: [
                  {
                    title: '# of Loans',
                    active: isNumberOfLoan,
                    action: () => setIsNumberOfLoan(true),
                  },
                  {
                    title: 'Volume',
                    active: !isNumberOfLoan,
                    action: () => setIsNumberOfLoan(false),
                  },
                ],
              },
            ].map((group, i) => {
              switch (group.type) {
                case 'dropdown':
                  return (
                    <div key={i} className="flex flex-col ml-4 min-w-[150px]">
                      <span className="mb-2 text-sm font-semibold">
                        {group.title}
                      </span>
                      <Select
                        className="[&~ul]:max-h-[100px]"
                        value={group.value}
                        onChange={(val: any) => group.action?.(val)}
                      >
                        {group.options.map((o: any) => {
                          return (
                            <Option key={o.value} value={o.value}>
                              {o.title}
                            </Option>
                          )
                        })}
                      </Select>
                    </div>
                  )
                default:
                  return (
                    <div key={i} className="flex flex-col ml-4">
                      <span className="mb-2 text-sm font-semibold">
                        {group.title}
                      </span>
                      <div className="bg-neutral-border-1 rounded-md p-1 flex">
                        {group.options.map((o, j) => {
                          const option = o as {
                            active: boolean
                            action: () => void
                            title: string
                          }
                          return (
                            <button
                              key={j}
                              className={`flex-1 text-sm rounded-md px-3 py-1.5 min-w-[100px] ${
                                option.active
                                  ? 'bg-secondary-main text-white'
                                  : ''
                              }`}
                              onClick={option.action}
                            >
                              {option.title}
                            </button>
                          )
                        })}
                      </div>
                    </div>
                  )
              }
            })}
          </div>
          <Chart
            loading={isFetching || isProcessing}
            id={`riskCohortRollRatesCohort_by_${activeType}`}
            yLabel={isNumberOfLoan ? 'Number of Loans' : 'Volume'}
            yFormat="#.00a%"
            xLabel="Months on Books"
            xAxisType="CategoryAxis"
            data={chartData}
            series={['Paid off', ...dpds].map(d => {
              return {
                label: d,
                tooltipValueFormat: '#.00a%',
                type: 'ColumnSeries',
                field: d,
                isStack: true,
              }
            })}
            exportable={exportable}
            error={error as { message: string }}
            scroll={{ y: true, x: true, xStart, xEnd }}
          />
          {showHistoricalRateIndicator && !isNumberOfLoan && (
            <div className="flex gap-4 mt-4">
              {display_rates.map((r: any) => (
                <div
                  key={r.code}
                  className="rounded-full p-2 border border-neutral-border-1 text-xs text-center bg-neutral-surface-1"
                >
                  <span className="font-medium">{`USD:${r.code}`}</span>
                  {` = 
                  ${Intl.NumberFormat(undefined, {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                  }).format(
                    r.display_rates?.[conversionCohort.format('YYYY-MM-DD')] ??
                      1
                  )}`}
                </div>
              ))}
            </div>
          )}
          <div
            className={`mt-4 overflow-auto rounded-lg border border-neutral-border-1 max-h-[calc(100vh-100px)] cascade-table`}
          >
            <table className="border-separate border-spacing-0">
              <thead className="sticky top-0 z-10">
                <tr>
                  <th className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[50px] sticky left-0 bg-neutral-white">
                    MoB
                  </th>
                  <th className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[120px] sticky left-[50px] bg-neutral-white">
                    {`${isNumberOfLoan ? 'Number of Loans' : 'Volume'}`}
                  </th>
                  <th className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[120px] sticky left-[170px] bg-neutral-white">
                    {`Outstanding ${
                      isNumberOfLoan ? 'Number of Loans' : 'Volume'
                    }`}
                  </th>
                  <th className="p-3 border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1 lg:min-w-[120px]  bg-neutral-white">
                    Paid off
                  </th>
                  {dpds.map((x, i) => (
                    <th
                      key={i}
                      className="p-3 min-w-[120px] border-r border-b border-neutral-border-1 text-sm bg-neutral-surface-1 text-neutral-body-1"
                    >
                      {x}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentMoBs.map(m => {
                  return (
                    <tr key={m}>
                      <td className="p-3 border-r border-b border-neutral-border-1 sticky left-0 bg-neutral-white text-sm text-center">
                        {m}
                      </td>
                      {dpds.map((_d, i) => {
                        const columnData = currentData.find(
                          x => x.mob === m && x.bucket_label === _d
                        )
                        const val = !columnData
                          ? 0
                          : isNumberOfLoan
                          ? columnData?.outstanding_loans /
                            columnData?.total_loans
                          : columnData?.outstanding_balance /
                            columnData?.total_disbursed
                        const firstBucketData = currentData.find(
                          x => x.mob === m
                        )
                        return (
                          <>
                            {i === 0 && (
                              <>
                                <td className="p-3 border-r border-b border-neutral-border-1 text-sm text-right sticky left-[50px] bg-neutral-white">
                                  {`${Intl.NumberFormat(undefined, {
                                    style: 'decimal',
                                    maximumFractionDigits: 1,
                                    currency,
                                    notation: 'compact',
                                  }).format(
                                    parseFloat(
                                      !firstBucketData
                                        ? '0'
                                        : (isNumberOfLoan
                                            ? firstBucketData?.total_loans
                                            : firstBucketData?.total_disbursed *
                                              fx
                                          ).toString()
                                    )
                                  )} ${isNumberOfLoan ? '' : currency}`}
                                </td>
                                <td className="p-3 border-r border-b border-neutral-border-1 text-sm text-right sticky left-[170px] bg-neutral-white">
                                  {`${Intl.NumberFormat(undefined, {
                                    style: 'decimal',
                                    maximumFractionDigits: 1,
                                    currency,
                                    notation: 'compact',
                                  }).format(
                                    parseFloat(
                                      !firstBucketData
                                        ? '0'
                                        : (isNumberOfLoan
                                            ? firstBucketData?.total_loans -
                                              firstBucketData.paid_off_loans
                                            : (firstBucketData?.total_disbursed -
                                                firstBucketData.paid_off_amount) *
                                              fx
                                          ).toString()
                                    )
                                  )} ${isNumberOfLoan ? '' : currency}`}
                                </td>
                                <td className="p-3 border-r border-b border-neutral-border-1 text-sm text-right bg-neutral-white">
                                  {Intl.NumberFormat(undefined, {
                                    style: 'percent',
                                    maximumFractionDigits: 2,
                                  }).format(
                                    parseFloat(
                                      (!firstBucketData
                                        ? 0
                                        : isNumberOfLoan
                                        ? firstBucketData?.paid_off_loans /
                                          firstBucketData?.total_loans
                                        : firstBucketData?.paid_off_amount /
                                          firstBucketData?.total_disbursed
                                      ).toString()
                                    )
                                  )}
                                </td>
                              </>
                            )}
                            <td
                              key={i}
                              className="p-3 border-r border-b border-neutral-border-1 text-sm text-right"
                            >
                              {Intl.NumberFormat(undefined, {
                                style: 'percent',
                                maximumFractionDigits: 2,
                              }).format(parseFloat((val ?? 0).toString()))}
                            </td>
                          </>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      }
    />
  )
}

export default CohortRollRatesL5
