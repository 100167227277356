import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  ArrowDownTrayIcon,
  CloudArrowUpIcon,
  EllipsisHorizontalIcon,
  EnvelopeOpenIcon,
  MinusCircleIcon,
  PaperAirplaneIcon,
  PencilIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline'
import { CategoriesResult } from '@interfaces/manage-reporting'
import { Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'

interface Document {
  id: string
  status: string
  category: string
  signees: string[]
  dropbox_api_supp: string
  supplementary_data: string
  name: string
}

interface DocumentMenuProps {
  doc: Document
  setGenerateDocType: (doc: Document, category: CategoriesResult) => void
  sign: (doc: Document) => void
  handleCompleted: (doc: Document, action: string) => void
  downloadFile: (doc: Document) => void
  handleUpload: (doc: Document) => void
  handleNotes: (doc: Document) => void
  handleResendSignRequest: (doc: Document) => void
  createCategories: CategoriesResult[]
  can_create: boolean
  can_sign: boolean
}

const DocumentMenu: React.FC<DocumentMenuProps> = ({
  doc,
  setGenerateDocType,
  sign,
  handleCompleted,
  downloadFile,
  handleUpload,
  handleNotes,
  handleResendSignRequest,
  createCategories,
  can_create,
  can_sign,
}) => {
  const { user } = useAuth0()
  const category = createCategories?.find(cat => cat.category === doc.category)

  const isSignatureDue = (doc: any) => {
    const parsedSignees = JSON.parse(doc)
    const currentUserIndex = parsedSignees.findIndex(
      (signee: { signer_email_address: string | undefined }) =>
        signee.signer_email_address === user?.email
    )

    return (
      currentUserIndex > -1 &&
      parsedSignees[currentUserIndex].status_code === 'awaiting_signature' &&
      (currentUserIndex === 0 ||
        parsedSignees[currentUserIndex - 1].status_code !==
          'awaiting_signature' ||
        !parsedSignees[currentUserIndex - 1].order)
    )
  }

  const menuHandler = (action: string) => {
    const actionMap: any = {
      generate: () => setGenerateDocType(doc, category as CategoriesResult),
      sign: () => sign(doc),
      view: () => handleCompleted(doc, action),
      download: () => downloadFile(doc),
      void: () => handleCompleted(doc, action),
      upload: () => handleUpload(doc),
      notes: () => handleNotes(doc),
      resend: () => handleResendSignRequest(doc),
    }

    const selectedAction = actionMap[action]
    if (selectedAction) {
      selectedAction()
    }
  }

  return (
    <Menu placement="left-end">
      <MenuHandler>
        <button className="flex justify-center w-full p-2 text-sm cursor-pointer rounded-lg hover:bg-primary-main hover:text-white transition ease-in-out delay-50 duration-300 curor-default ">
          <EllipsisHorizontalIcon className="w-4 h-4 cursor-pointer" />
        </button>
      </MenuHandler>
      <MenuList className="z-10">
        <MenuItem
          className="!text-primary-main flex hover:bg-primary-surface-2"
          onClick={() => menuHandler('notes')}
        >
          <PencilIcon className="mr-2 w-4 h-4" />
          Notes
        </MenuItem>
        {['overdue', 'due'].includes(doc.status) && can_create && (
          <>
            {!!category && (
              <MenuItem
                className="!text-primary-main flex hover:bg-primary-surface-2"
                onClick={() => menuHandler('generate')}
              >
                <PencilSquareIcon className="mr-2 w-4 h-4" />
                Generate
              </MenuItem>
            )}
            <MenuItem
              className="!text-primary-main flex hover:bg-primary-surface-2"
              onClick={() => menuHandler('upload')}
            >
              <CloudArrowUpIcon className="mr-2 w-4 h-4" />
              Upload
            </MenuItem>
          </>
        )}
        {([
          'signature_request_sent',
          'awaiting_signature',
          'signature_request_viewed',
          'created',
        ].includes(doc.status) ||
          (doc.status === 'signature_request_downloadable' &&
            !JSON.parse(doc.dropbox_api_supp).is_complete)) && (
          <>
            {isSignatureDue(doc.signees) && can_sign ? (
              <MenuItem
                className="!text-primary-main flex hover:bg-primary-surface-2"
                onClick={() => menuHandler('sign')}
              >
                <PencilIcon className="mr-2 w-4 h-4" />
                Sign
              </MenuItem>
            ) : (
              <MenuItem
                className="!text-primary-main flex hover:bg-primary-surface-2"
                onClick={() => menuHandler('resend')}
              >
                <PaperAirplaneIcon className="mr-2 w-4 h-4" />
                Resend
              </MenuItem>
            )}
            <MenuItem
              className="!text-primary-main flex hover:bg-primary-surface-2"
              onClick={() => menuHandler('view')}
            >
              <EnvelopeOpenIcon className="mr-2 w-4 h-4" />
              View
            </MenuItem>
          </>
        )}

        {([
          'completed',
          'signature_request_signed',
          'signature_request_all_signed',
        ].includes(doc.status) ||
          (doc.status === 'signature_request_downloadable' &&
            JSON.parse(doc.dropbox_api_supp).is_complete)) && (
          <>
            <MenuItem
              className="!text-primary-main flex hover:bg-primary-surface-2"
              onClick={() =>
                doc.dropbox_api_supp != 's3 upload'
                  ? menuHandler('view')
                  : menuHandler('download')
              }
            >
              <EnvelopeOpenIcon className="mr-2 w-4 h-4" />

              {doc.dropbox_api_supp != 's3 upload' ? 'View' : 'Open'}
            </MenuItem>
            {doc.dropbox_api_supp != 's3 upload' && (
              <MenuItem
                className="!text-primary-main flex hover:bg-primary-surface-2"
                onClick={() => menuHandler('download')}
              >
                <ArrowDownTrayIcon className="mr-2 w-4 h-4" />
                Download
              </MenuItem>
            )}
            {can_create && (
              <MenuItem
                className="!text-danger-main flex hover:bg-primary-surface-2"
                onClick={() => menuHandler('void')}
              >
                <MinusCircleIcon className="mr-2 w-4 h-4" />
                Void
              </MenuItem>
            )}
          </>
        )}
      </MenuList>
    </Menu>
  )
}

export default DocumentMenu
