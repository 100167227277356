import React from 'react'

import { Auth0ContextInterface, User } from '@auth0/auth0-react'
import { Role } from '@constants/role'
import { AuthContextValues } from '@contexts/auth'
import {
  ChartBarIcon,
  FolderIcon,
  PencilSquareIcon,
  Squares2X2Icon as ViewGridIcon,
} from '@heroicons/react/24/outline'
import {
  ChartBarIcon as ChartBarSolid,
  FolderIcon as FolderIconSolid,
  PencilSquareIcon as PencilSquareSolid,
  Squares2X2Icon as ViewGridSolid,
} from '@heroicons/react/24/solid'
// import ProfilePage from '@pages/administration/profile'
import UsersPage from '@pages/administration/setting/users'
import AccountingOverviewL3 from '@pages/administration/sources/accounting'
import BankOverviewL3 from '@pages/administration/sources/banks'
import LoantapeOverviewL3 from '@pages/analytics/data-exports/loantape'
import KeyIndicatorsL3 from '@pages/analytics/financials/key-indicators'
import FinancialOverviewL3 from '@pages/analytics/financials/overview'
import StatementsPage from '@pages/analytics/financials/statements'
import CharacteristicPage from '@pages/analytics/risk/characteristic'
import CohortPage from '@pages/analytics/risk/cohort'
import CollectionPage from '@pages/analytics/risk/collection'
import DelinquencyPage from '@pages/analytics/risk/delinquency'
import RiskIndicatorsL3 from '@pages/analytics/risk/indicators'
import RiskOverviewL3 from '@pages/analytics/risk/overview'
import TractionPage from '@pages/analytics/risk/traction'
import CollateralL3 from '@pages/data-validation/data-quarantine/collateral'
import PaymentAuditL3 from '@pages/manage/audit/payment'
import UnderwritingAuditL3 from '@pages/manage/audit/underwriting'
import ActionsL3 from '@pages/manage/monitor/actions'
import MonitorBorrowingBaseL3 from '@pages/manage/monitor/borrowing-base'
import CashflowsL3 from '@pages/manage/monitor/cashflows'
import MonitorCovenantsL3 from '@pages/manage/monitor/covenant'
import MonitorDashboardL3 from '@pages/manage/monitor/dashboard'
// import MonitorEligibilityL3 from '@pages/manage/monitor/eligibility'
import MonitorFacilityDetailsL3 from '@pages/manage/monitor/facility-details'
import MonitorOverviewL3 from '@pages/manage/monitor/overview'

import features from './features'

export interface RoutesProps {
  /**
   * used as routing path
   */
  path: string
  /**
   * used as navigation title
   */
  title: string
  /**
   * used as navigation icon
   */
  icon?: any
  /**
   * used as navigation icon while `path is active
   */
  activeIcon?: any
  /**
   * whether this route hidden or not from side menu
   */
  menuHidden?: boolean
  /**
   * component that will be rendered. Only for latest child
   */
  component?: any
  /**
   * nested routing
   */
  routes?: RoutesProps[]
  /**
   * function to validate whether current user is authorized for menu
   */
  isAuthorized?: (
    role: Role,
    slug?: string,
    context?: AuthContextValues
  ) => boolean
  /**
   * level order
   */
  order?: number
  /**
   * route access
   */
  access?: string[]
  /**
   * route access specific for external user. if not available fall back to access
   */
  access_external?: string[]
}

const manageModuleFeatureCheck = (
  feature: string,
  context?: AuthContextValues
) => {
  const { appliedFilters, optionFilters } = context ?? {}

  const { activeDebtDeal = 0 } = appliedFilters ?? {}
  const { debtDealOptions = [] } = optionFilters ?? {}
  const activeFacility = debtDealOptions?.[activeDebtDeal]
  const default_features =
    'overview, dashboard, borrowing-base, portfolio-covenants, financial-covenants, cashflows, actions, payment-audit, underwriting-audit'

  return (activeFacility?.features ?? default_features).includes(feature)
}

const Routes: RoutesProps[] = [
  {
    path: 'analytics',
    title: 'Analytics',
    icon: ChartBarIcon,
    activeIcon: ChartBarSolid,
    order: 2,
    routes: [
      {
        path: 'risk',
        title: 'Risk',
        routes: [
          {
            path: 'overview',
            title: 'Overview',
            menuHidden: false,
            access: ['access'],
            component: <RiskOverviewL3 />,
          },
          {
            path: 'indicators',
            title: 'Key Indicators',
            access: ['access'],
            component: <RiskIndicatorsL3 />,
          },
          {
            path: 'traction',
            title: 'Traction',
            component: <TractionPage />,
            routes: [
              {
                path: 'volume',
                title: 'Volume',
                routes: [
                  {
                    path: 'monthly',
                    title: 'Monthly',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'total',
                    title: 'Total',
                    access: ['access', 'export'],
                  },
                ],
              },
              {
                path: 'clients',
                title: 'Clients',
                routes: [
                  {
                    path: 'monthly-active-clients',
                    title: 'Monthly Active Clients',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'total-unique-clients',
                    title: 'Total Unique Clients',
                    access: ['access', 'export'],
                  },
                ],
              },
              {
                path: 'balance',
                title: 'Balance',
                routes: [
                  {
                    path: 'balance-volume',
                    title: 'Balance Volume',
                    access: ['access', 'export'],
                  },
                  {
                    path: '#-of-loans',
                    title: '# of Loans',
                    access: ['access', 'export'],
                  },
                ],
              },
            ],
          },
          {
            path: 'delinquency',
            title: 'Delinquency',
            component: <DelinquencyPage />,
            routes: [
              {
                path: 'portfolio-at-risk',
                title: 'Portfolio at Risk',
                access: ['access', 'export'],
              },
              {
                path: 'rolling-default-rate',
                title: 'Rolling Default Rate',
                access: ['access', 'export'],
              },
              {
                path: 'outstanding-by-delinquency',
                title: 'Outstanding by Delinquency',
                access: ['access', 'export'],
              },
              {
                path: 'first-payment-default(fpd)-by-cohort',
                title: 'First Payment Default(FPD) by Cohort',
                access: ['access', 'export'],
              },
              {
                path: 'borrower-concentration',
                title: 'Borrower Concentration',
                access: ['access', 'export'],
              },
              {
                path: 'delinquency-by-cohort',
                title: 'Delinquency by Cohort',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'collection',
            title: 'Collection',
            component: <CollectionPage />,
            routes: [
              {
                path: 'collection-rate',
                title: 'Collection Rate',
                access: ['access', 'export'],
              },
              {
                path: 'prepayment-rate-by-cohort',
                title: 'Prepayment Rate by Cohort',
                access: ['access', 'export'],
              },
              {
                path: 'collection-by-status',
                title: 'Collection by Status',
                access: ['access', 'export'],
              },
              {
                path: 'net-yield',
                title: 'Net Yield',
                access: ['access', 'export'],
              },
              {
                path: 'aggregated-roll-rates',
                title: 'Aggregated Roll Rates',
                access: ['access', 'export'],
              },
              {
                path: 'cash-collected-by-cohort',
                title: 'Cash Collected by Cohort',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'cohort',
            title: 'Cohort',
            component: <CohortPage />,
            routes: [
              {
                path: 'vintage-analysis',
                title: 'Vintage Analysis',
                access: ['access', 'export'],
              },
              {
                path: 'roll-rates',
                title: 'Roll Rates',
                access: ['access', 'export'],
              },
              {
                path: 'roll-rates-by-cohort',
                title: 'Roll Rates by Cohort',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'characteristics',
            title: 'Characteristics',
            component: <CharacteristicPage />,
            routes: [
              {
                path: 'interest-rate',
                title: 'Interest Rate',
                routes: [
                  {
                    path: 'distribution',
                    title: 'Distribution',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'average',
                    title: 'Average',
                    access: ['access', 'export'],
                  },
                ],
              },
              {
                path: 'term',
                title: 'Term',
                routes: [
                  {
                    path: 'distribution',
                    title: 'Distribution',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'average',
                    title: 'Average',
                    access: ['access', 'export'],
                  },
                ],
              },
              {
                path: 'value',
                title: 'Value',
                routes: [
                  {
                    path: 'distribution',
                    title: 'Distribution',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'average',
                    title: 'Average',
                    access: ['access', 'export'],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'financials',
        title: 'Financials',
        routes: [
          {
            path: 'overview',
            title: 'Overview',
            menuHidden: false,
            component: <FinancialOverviewL3 />,
            access: ['access'],
          },
          {
            path: 'key-indicators',
            title: 'Key Indicators',
            component: <KeyIndicatorsL3 />,
            routes: [
              {
                path: 'net-income',
                title: 'Net Income',
                access: ['access', 'export'],
              },
              {
                path: 'cash',
                title: 'Cash',
                routes: [
                  {
                    path: 'cash',
                    title: 'Cash',
                    access: ['access', 'export'],
                  },
                  {
                    path: 'bank-account-distribution',
                    title: 'Bank Account Distribution',
                    access: ['access'],
                  },
                ],
              },
              {
                path: 'net-worth',
                title: 'Net Worth',
                access: ['access', 'export'],
              },
              {
                path: 'runway',
                title: 'Runway',
                access: ['access', 'export'],
              },
              {
                path: 'debt:equity',
                title: 'Debt:Equity',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'statements',
            title: 'Statements',
            component: <StatementsPage />,
            routes: [
              {
                path: 'balance-sheet',
                title: 'Balance Sheet',
                access: ['access', 'export'],
              },
              {
                path: 'profit-&-loss',
                title: 'Profit Loss',
                access: ['access', 'export'],
              },
            ],
          },
        ],
      },
      {
        path: 'data-exports',
        title: 'Data Exports',
        routes: [
          {
            path: 'loan-tape',
            title: 'Loan Tape',
            access: ['access', 'export'],
            component: <LoantapeOverviewL3 />,
          },
        ],
      },
    ],
  },
  {
    path: 'manage',
    title: 'Manage',
    icon: PencilSquareIcon,
    activeIcon: PencilSquareSolid,
    order: 3,
    routes: [
      {
        path: 'monitor',
        title: 'Monitor',
        routes: [
          {
            path: 'overview',
            title: 'Overview',
            menuHidden: false,
            component: <MonitorOverviewL3 />,
            access: ['access'],
          },
          {
            path: 'dashboard',
            title: 'Dashboard',
            component: <MonitorDashboardL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('dashboard', context),
            access: ['access', 'export'],
          },
          // {
          //   path: 'eligibility',
          //   title: 'Eligibility',
          //   component: <MonitorEligibilityL3 />,
          //   isAuthorized: (_r, _s, context) =>
          //     manageModuleFeatureCheck('eligibility', context),
          //   access: ['access'],
          // },
          {
            path: 'facility-details',
            title: 'Facility Details',
            component: <MonitorFacilityDetailsL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('facility-details', context),
          },
          {
            path: 'borrowing-base',
            title: 'Borrowing Base',
            component: <MonitorBorrowingBaseL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('borrowing-base', context),
            routes: [
              {
                path: 'borrowing-base',
                title: 'Borrowing Base',
                access: ['access', 'export'],
              },
              {
                path: 'collateral',
                title: 'Collateral',
                access: ['access', 'export'],
              },
            ],
          },
          {
            path: 'portfolio-covenants',
            title: 'Portfolio Covenants',
            component: <MonitorCovenantsL3 type="portfolio" />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('portfolio-covenants', context),
            access: ['access', 'export'],
          },
          {
            path: 'financial-covenants',
            title: 'Financial Covenants',
            component: <MonitorCovenantsL3 type="financial" />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('financial-covenants', context),
            access: ['access', 'export'],
          },
          {
            path: 'cashflows',
            title: 'Cashflows',
            component: <CashflowsL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('cashflows', context),
            routes: [
              {
                path: 'payment-schedule',
                title: 'Payment Schedule',
                access: ['access'],
              },
            ],
          },
          {
            path: 'actions',
            title: 'Actions',
            component: <ActionsL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('actions', context),
            routes: [
              {
                path: 'document-centre',
                title: 'Document Centre',
                access: ['access', 'create', 'sign'],
              },
              {
                path: 'waterfall',
                title: 'Waterfall',
                access: ['access', 'create', 'sign'],
              },
              {
                path: 'advance-request',
                title: 'Advance Request',
                access: ['access', 'create', 'sign'],
              },
            ],
          },
        ],
      },
      {
        path: 'audit',
        title: 'Audit',
        isAuthorized: (_r, _s, context) =>
          manageModuleFeatureCheck('-audit', context),
        routes: [
          {
            path: 'payment-audit',
            title: 'Payment Audit',
            component: <PaymentAuditL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('payment-audit', context),
            access: ['access', 'upload', 'approve/reject', 'export'],
          },
          {
            path: 'underwriting-audit',
            title: 'Underwriting Audit',
            component: <UnderwritingAuditL3 />,
            isAuthorized: (_r, _s, context) =>
              manageModuleFeatureCheck('underwriting-audit', context),
            access: ['access', 'upload', 'approve/reject', 'export'],
          },
        ],
      },
    ],
  },
  {
    path: 'data-validation',
    title: 'Data Validation',
    icon: FolderIcon,
    activeIcon: FolderIconSolid,
    order: 4,
    routes: [
      {
        path: 'data-quarantine',
        title: 'Data Quarantine',
        routes: [
          {
            path: 'collateral-data',
            title: 'Collateral Data',
            access: ['access', 'export'],
            component: <CollateralL3 />,
          },
        ],
      },
    ],
  },
  {
    path: 'administration',
    title: 'Administration',
    icon: ViewGridIcon,
    activeIcon: ViewGridSolid,
    order: 1,
    routes: [
      // {
      //   path: 'profile',
      //   title: 'Profile',
      //   component: <ProfilePage />,
      // },
      {
        path: 'sources',
        title: 'Data Sources',
        isAuthorized: (role: Role) =>
          [Role.superadmin, Role.admin, Role.custom].includes(role),
        routes: [
          {
            path: 'banking',
            title: 'Banking',
            component: <BankOverviewL3 />,
            access: ['access', 'create', 'edit'],
          },
          {
            path: 'accounting',
            title: 'Accounting',
            component: <AccountingOverviewL3 />,
            access: ['access', 'create', 'edit', 'delete'],
          },
        ],
      },
      {
        path: 'setting',
        title: 'Settings',
        routes: [
          {
            path: 'users',
            title: 'Users',
            component: <UsersPage />,
            access: ['access', 'create', 'edit', 'delete'],
            isAuthorized: (role: Role) =>
              [Role.superadmin, Role.admin, Role.custom].includes(role),
          },
        ],
      },
    ],
  },
]

/**
 *
 * @param   {string[]}      auth0Features   Auth0 user's features
 * @returns {string[]}                      Available features
 */
export const AvailableFeatures = (auth0Features?: string[]) => {
  const availableFeatures: string[] =
    auth0Features && auth0Features?.length > 0 ? auth0Features : features

  return availableFeatures
}

const check_accessibility = (
  context: AuthContextValues,
  path: string,
  isAuthorized?: (
    role: Role,
    slug?: string,
    context?: AuthContextValues
  ) => boolean,
  include_facility = true
) => {
  const { userMetadata, company, activeFilters, optionFilters } = context
  const { activeDebtDeal = 0 } = activeFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const is_superadmin = userMetadata?.isSuperadmin
  const user_company_access =
    userMetadata?.companies?.[company?.slug_name ?? '']
  const default_role = Role.staff
  const active_role = is_superadmin
    ? Role.superadmin
    : user_company_access?.control ?? default_role
  const active_access = user_company_access?.access ?? {}
  const is_manage = path.includes('manage')

  const full_path = `${
    is_manage && include_facility && activeFacility?.facility
      ? `${activeFacility?.facility}_`
      : ''
  }${path}`

  const is_authorized = isAuthorized
    ? isAuthorized(active_role, company?.slug_name, context)
    : true
  const is_available_feature = !!AvailableFeatures(
    Object.keys(active_access)
  ).find(x => x.includes(path))
  const is_available_access =
    active_role === Role.custom
      ? Object.keys(active_access).filter(
          aa => aa.includes(full_path) && active_access[aa].includes('access')
        ).length > 0
      : true

  const is_available =
    is_authorized && is_available_feature && is_available_access

  return is_available
}

/**
 *
 * @param   {string[]}      auth0Features   Auth0 user's features
 * @param   {string[]}      auth0Roles      Auth0 user's roles
 * @returns {RoutesProps[]}                 Feature Flagged App Routing
 */
const FlaggedRoutes = (
  auth: Auth0ContextInterface<User>,
  context: AuthContextValues,
  sort = false,
  include_facility = true
) => {
  const { company } = context

  /**
   * we're currently assuming every company has access to administration, analytic and data-validation module by default
   */
  const modules: { [key: string]: boolean } = {
    administration: true,
    analytics: company?.has_analytics ?? true,
    'data-validation': true,
    manage: company?.has_manage ?? false,
    raise: company?.has_raise ?? false,
  }

  const flaggedRouting = Routes.filter(l1 => {
    const path = l1.path
    const is_available = check_accessibility(
      context,
      path,
      l1.isAuthorized,
      include_facility
    )
    return is_available && modules?.[path]
  })
    .map(l1 => {
      const l2 = l1.routes
        ?.filter(l2 => {
          const path = `${l1.path}_${l2.path}`
          const is_available = check_accessibility(
            context,
            path,
            l2.isAuthorized,
            include_facility
          )
          return is_available
        })
        .map(l2 => {
          const l3 = l2.routes?.filter(l3 => {
            const path = `${l1.path}_${l2.path}_${l3.path}`
            const is_available = check_accessibility(
              context,
              path,
              l3.isAuthorized,
              include_facility
            )
            return is_available
          })
          return {
            ...l2,
            routes: l3,
          }
        })
        .filter(f => f.routes && f.routes.length > 0)
      return {
        ...l1,
        routes: l2,
      }
    })
    .filter(f => f.routes && f.routes.length > 0)

  return sort
    ? flaggedRouting.sort((a, b) =>
        (a?.order ?? 0) < (b?.order ?? 0) ? -1 : 1
      )
    : flaggedRouting
}

export default FlaggedRoutes
