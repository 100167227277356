import React, { useContext, useEffect, useState } from 'react'

import FilterCurrency from '@components/filters/filter-currency'
import FilterDateRange from '@components/filters/filter-date-range'
import FilterFinancialDisplay from '@components/filters/filter-financial-display'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'

import CashL4 from './cash'
import DebtEquityL4 from './debt-equity'
import NetIncomeL4 from './net-income'
import NetWorthL4 from './net-worth'
import RunwayL4 from './runway'

const KeyIndicatorsL3 = () => {
  const { filter_access, get_access } = useUserAccessFeature()
  const FEATURE = `analytics_financials_key-indicators`
  const { activeFilters, company, appliedFilters, setAppliedFilters } =
    useContext(AuthContext)

  const { activeType, range, currency, dateStart, dateEnd, financialEntity } =
    activeFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (!filterReady && dateStart && dateEnd && financialEntity) {
      //carries filters over unless no previously applied filters
      JSON.stringify(appliedFilters) !== JSON.stringify({ activeFilters }) &&
        setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    range === 0 &&
    activeType === 'All' &&
    currency === company?.currencies_available?.[0]?.to_currency

  const tabs = [
    { label: 'Net Income', component: NetIncomeL4 },
    { label: 'Cash', component: CashL4 },
    { label: 'Net Worth', component: NetWorthL4 },
    { label: 'Runway', component: RunwayL4 },
    { label: 'Debt:Equity', component: DebtEquityL4 },
  ]
    .filter(t =>
      filter_access(`${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`)
    )
    .map(t => {
      const exportable = get_access(
        `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`,
        'export'
      )
      return { ...t, component: <t.component exportable={exportable} /> }
    })

  return (
    <L3TabsLayout
      title="Key Indicators"
      filters={{
        left: <FilterCurrency />,
        middle: <FilterDateRange isCohort={true} />,
        right: <FilterFinancialDisplay />,
      }}
      disableReset={disableReset}
      tabs={tabs}
    />
  )
}
export default KeyIndicatorsL3
