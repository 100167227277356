import React, { useContext } from 'react'
import moment from 'moment'

import { AvailableFeatures } from '@components/app-routes/routes'
import FilterCurrency from '@components/filters/filter-currency'
import FilterDateRange from '@components/filters/filter-date-range'
import FilterTypeKey from '@components/filters/filter-type-key'
import OverviewL2 from '@components/layouts/l2-overview-layout'
import AuthContext from '@contexts/auth'
import { CharacteristicService } from '@services/api-analytics/risk-characteristic'
import CohortService from '@services/api-analytics/risk-cohort'
import { CollectionService } from '@services/api-analytics/risk-collection'
import { DelinquencyService } from '@services/api-analytics/risk-delinquency'
import IndicatorService from '@services/api-analytics/risk-indicator'
import { TractionService } from '@services/api-analytics/risk-traction'

const RiskOverviewL3 = () => {
  const { userMetadata, company } = useContext(AuthContext)
  const userCompanyAccess = userMetadata?.companies?.[company?.slug_name ?? '']
  const activeAccess = Object.keys(userCompanyAccess?.access ?? {})
  const features = AvailableFeatures(activeAccess)

  const data = [
    {
      feature: 'analytics_risk_indicators',
      cardTitle: 'Summary',
      cardLink: '/analytics/risk/indicators',
      fetcher: IndicatorService.getOverview,
      fetchOption: {
        date_to: moment.utc(company?.date_end_cohort).format('YYYY-MM-DD'),
        slug_name: company?.slug_name,
      },
      icon: 'bar',
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
        }).format(val ?? 0)}`,
    },
    {
      feature: 'analytics_risk_traction',
      cardTitle: 'Traction',
      cardLink: '/analytics/risk/traction',
      fetcher: TractionService.getOverview,
      icon: 'bar',
      fetchOption: {
        date_to: moment.utc(company?.date_end_cohort).format('YYYY-MM-DD'),
        is_cumulative: true,
        slug_name: company?.slug_name,
      },
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
        }).format(val ?? 0)} ${
          company?.currencies_available?.[0]?.to_currency
        }`,
    },
    {
      feature: 'analytics_risk_delinquency',
      cardTitle: 'Delinquency',
      cardLink: '/analytics/risk/delinquency',
      fetcher: DelinquencyService.getOverview,
      icon: 'line',
      fetchOption: {
        value: (company?.writeoff_days ?? 0) <= 90 ? 'par30' : 'par90',
        slug_name: company?.slug_name,
      },
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          maximumFractionDigits: 1,
          style: 'percent',
          notation: 'compact',
        }).format((val ?? 0) / 100)}`,
      secondaryFormatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'decimal',
          notation: 'compact',
        }).format((val ?? 0) * 100)}bp`,
      indicatorClass: {
        up: 'bg-red',
        down: 'bg-green',
      },
    },

    {
      feature: 'analytics_risk_delinquency',
      cardTitle: 'Delinquency',
      cardLink: '/analytics/risk/delinquency',
      fetcher: CohortService.getOverview,
      fetchOption: {
        date_from: moment.utc(company?.date_start).format('YYYY-MM-DD'),
        slug_name: company?.slug_name,
      },
      icon: 'line',
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'percent',
          notation: 'compact',
          maximumFractionDigits: 2,
        }).format((val ?? 0) / 100)}`,
      indicatorClass: {
        up: 'bg-red',
        down: 'bg-green',
      },
    },
    {
      feature: 'analytics_risk_characteristics',
      cardTitle: 'Characteristics',
      cardLink: '/analytics/risk/characteristics',
      fetcher: CharacteristicService.getOverview,
      fetchOption: {
        date_to: moment.utc(company?.date_end_cohort).format('YYYY-MM-DD'),
        slug_name: company?.slug_name,
      },
      icon: 'line',
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'percent',
          notation: 'compact',
          maximumFractionDigits: 2,
        }).format((val ?? 0) / 100)}`,
    },
    {
      feature: 'analytics_risk_collection',
      cardTitle: 'Collections',
      cardLink: '/analytics/risk/collection?tab=excess-spread-by-cohort',
      fetcher: CollectionService.getOverview,
      fetchOption: {
        date_to: moment.utc(company?.date_end_cohort).format('YYYY-MM-DD'),
        slug_name: company?.slug_name,
      },
      icon: 'line',
      formatter: (val: number) =>
        `${Intl.NumberFormat(undefined, {
          style: 'percent',
          notation: 'compact',
        }).format((val ?? 0) / 100)}`,
    },
  ].filter(d => features.includes(d.feature))

  return (
    <OverviewL2
      title="Risk Overview"
      filters={{
        left: <FilterCurrency />,
        middle: <FilterDateRange />,
        right: <FilterTypeKey />,
        className: 'invisible',
      }}
      data={data}
    />
  )
}
export default RiskOverviewL3
