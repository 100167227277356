import Company from '@interfaces/company'

import apiClient from './client'

class CompanyService {
  static async getCompanies(slug_name?: string | string[]) {
    const { data } = await apiClient.post<Company[]>(`client/get-companies`, {
      slug_name,
    })
    return data
  }
}

export default CompanyService
