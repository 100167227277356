import React, { useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { useAuth0 } from '@auth0/auth0-react'
import appRouting from '@components/app-routes/routes'
import Button from '@components/atoms/button'
import FormInput from '@components/form/form-input'
import { Controls, Role } from '@constants/role'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { IsValidEmail } from '@helpers/validator'
import {
  ArrowPathIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import {
  CreateUserRequest,
  UpdateUserRequest,
  UserResponse,
} from '@interfaces/admin-settings-user'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Alert,
  Checkbox,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { UserService } from '@services/api-admin/settings-user'

import { get_routes_counter } from './helper'
import TableRouting from './table-routing'

interface DialogFormUserProps {
  isExternal?: boolean
  open?: boolean
  handler: (needupdate?: boolean) => void
  data?: UserResponse
}

const DialogFormUser = ({
  isExternal = false,
  open = false,
  handler,
  data,
}: DialogFormUserProps) => {
  const { userCompanyAccess, user } = useUserAccessFeature()
  const { email } = user ?? {}
  const active_is_external = userCompanyAccess?.is_external ?? false
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([])

  const handleOpen = (index: number): void => {
    const indexes = expandedIndexes.includes(index)
      ? expandedIndexes.filter(x => x !== index)
      : [...expandedIndexes, index]
    setExpandedIndexes(indexes)
  }

  const auth0 = useAuth0()

  const context = useContext(AuthContext)
  const { company, activeFilters, optionFilters } = context
  const { activeDebtDeal = 0 } = activeFilters
  const { debtDealOptions = [] } = optionFilters
  const activeFacility = debtDealOptions?.[activeDebtDeal]

  const origin_flagged_routing = appRouting(auth0, context, true, false)

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = useForm({
    shouldUnregister: false,
  })

  const default_control = Role.staff
  const control = getValues('control') ?? default_control

  const default_access = {}
  const access = getValues('access') ?? default_access

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setValue('email', data?.email, { shouldValidate: false })
        setValue('name', data?.name, { shouldValidate: false })
        setValue(
          'control',
          data?.user_metadata?.companies?.[company?.slug_name ?? '']?.control ??
            default_control,
          {
            shouldValidate: true,
          }
        )
        const access_data =
          data?.user_metadata?.companies?.[company?.slug_name ?? '']?.access ??
          default_access
        setValue('access', access_data)
      })
    } else {
      reset()
    }
  }, [data, open])

  /** Workaround for material-tailwind accordions dynamic height issue */
  useEffect(() => {
    const manageIdx = origin_flagged_routing.findIndex(r => r.path == 'manage')
    if (manageIdx > -1 && expandedIndexes.includes(manageIdx)) {
      handleOpen(manageIdx)

      setTimeout(() => {
        setExpandedIndexes([...expandedIndexes, manageIdx])
      }, 100)
    }
  }, [activeFacility])

  const _successHandler = () => {
    handler?.(true)
  }

  const {
    mutate: createUser,
    isLoading: isLoadingCreate,
    error: errorCreate,
  } = useMutation(
    (params: CreateUserRequest) => {
      return UserService.createUser(params)
    },
    {
      onSuccess: _successHandler,
    }
  )

  const {
    mutate: updateUser,
    isLoading: isLoadingUpdate,
    error: errorUpdate,
  } = useMutation(
    (params: UpdateUserRequest) => {
      return UserService.updateUser(params)
    },
    {
      onSuccess: _successHandler,
    }
  )

  const onSubmit = (dt: any) => {
    if (data?.user_id) {
      const paths: string[] = origin_flagged_routing.map(ofr => ofr.path)
      const facilityTags: string[] = Object.keys(debtDealOptions).map(
        ddo => debtDealOptions[ddo].facility
      )
      const filteredAccess = Object.keys(access)
        .filter(k => {
          if (
            facilityTags.filter(ft => k.startsWith(ft)).length > 0 ||
            paths.filter(p => k.startsWith(p)).length > 0
          ) {
            return true
          } else {
            return false
          }
        })
        .reduce((p, c) => {
          return { ...p, [c]: access[c] }
        }, {})
      updateUser({
        email: dt?.email,
        name: dt?.name,
        control,
        access: filteredAccess,
        is_external: isExternal,
        user_id: data?.user_id,
        slug_name: company?.slug_name,
      } as UpdateUserRequest)
    } else {
      createUser({
        email: dt?.email,
        name: dt?.name,
        control,
        access,
        slug_name: company?.slug_name,
        is_external: isExternal,
      } as CreateUserRequest)
    }
  }

  const isProcessing = isSubmitting || isLoadingCreate || isLoadingUpdate
  const error = errorCreate || errorUpdate

  useEffect(() => {
    setExpandedIndexes([])
  }, [control])

  return (
    <Dialog open={open} handler={() => handler()} size="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader className="text-xl">{`${
          data?.user_id ? 'Edit' : 'Invite New'
        } ${isExternal ? 'External' : 'Internal'} User`}</DialogHeader>
        <DialogBody
          divider
          className="flex flex-col max-h-[70vh] overflow-y-auto"
        >
          {error && (
            <Alert className="bg-danger-main mb-6">
              {((error as AxiosError).response?.data as any)?.error ??
                (error as AxiosError).message}
            </Alert>
          )}
          <FormInput
            type="email"
            label={{ start: 'Email' }}
            value={getValues('email') || ''}
            {...register('email', {
              required: `Email is required`,
              disabled: isProcessing,
              onChange: e => {
                setValue('email', e.target.value, { shouldValidate: true })
              },
              validate: {
                validEmail: v => {
                  if (!v) {
                    return undefined
                  } else if (v) {
                    if (IsValidEmail(v)) {
                      if (active_is_external) {
                        const domain = email?.split('@')?.[1]
                        const input_domain = v?.split('@')?.[1]
                        return domain === input_domain
                          ? true
                          : `This user can only invite another user from ${domain}`
                      } else {
                        return true
                      }
                    } else {
                      return 'Email format is not correct'
                    }
                  }
                },
              },
            })}
            error={errors?.email?.message as string}
          />
          <FormInput
            label={{ start: 'Name' }}
            value={getValues('name') || ''}
            {...register('name', {
              required: `Name is required`,
              disabled: isProcessing,
              onChange: e => {
                setValue('name', e.target.value, { shouldValidate: true })
              },
            })}
            error={errors?.name?.message as string}
          />
          <FormInput
            label={{
              start: 'Role',
              end: Controls.find(x => x.value === control)?.description,
            }}
            type="select"
            options={Controls.filter(c =>
              isExternal ? c.value !== Role.admin : true
            ).map(r => ({ title: r.value, value: r.value }))}
            {...register('control', {
              required: `Role is required`,
              disabled: isProcessing,
            })}
            value={control}
            onSelected={val => {
              setValue('control', val, { shouldValidate: true })
            }}
            error={errors?.control?.message as string}
          />
          {control === Role.custom &&
            origin_flagged_routing.map((r: any, i) => {
              const isOpen = expandedIndexes.includes(i)
              const Icon = isOpen ? ChevronDownIcon : ChevronRightIcon
              const { total, checked, data } = get_routes_counter(
                r,
                0,
                r.path,
                isExternal,
                access
              )
              const is_manage = r.path.includes('manage')

              return (
                <Accordion
                  key={i}
                  open={isOpen}
                  icon={<span></span>}
                  className={`border-t border-neutral-border`}
                >
                  <div className="flex">
                    <AccordionHeader
                      onClick={e => {
                        if (!e.currentTarget.classList.contains('parent-cb')) {
                          handleOpen(i)
                        }
                      }}
                      className="text-lg border-0 justify-between [&>*:last-child]:hidden"
                    >
                      <div className="flex ">
                        <Icon className="w-4 h-4 mr-2" />
                        <span className="text-left capitalize text-sm">
                          {r.title}
                        </span>
                      </div>
                    </AccordionHeader>
                    <div className="parent-cb min-w-[175px] py-4">
                      <Checkbox
                        id={r.path}
                        label={`${checked} of ${total} access`}
                        labelProps={{ className: 'capitalize text-sm' }}
                        checked={checked > 0}
                        onChange={e => {
                          const checked = e.target.checked
                          let next_access = {}
                          if (checked) {
                            const additional_access = data.reduce((p, c) => {
                              const access = isExternal
                                ? c.access_external ?? c.access
                                : c.access

                              if (access) {
                                const access_key = `${
                                  is_manage
                                    ? `${activeFacility?.facility}_`
                                    : ``
                                }${c.path}`
                                return { ...p, [access_key]: access.join(',') }
                              }
                              return p
                            }, {})
                            next_access = { ...access, ...additional_access }
                          } else {
                            const access_key = `${
                              is_manage ? `${activeFacility?.facility}_` : ``
                            }${r.path}`

                            next_access = Object.keys(access)
                              .filter(k => !k.startsWith(access_key))
                              .reduce((p, c) => {
                                return { ...p, [c]: access[c] }
                              }, {})
                          }
                          setValue('access', next_access, {
                            shouldValidate: true,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <AccordionBody>
                    <TableRouting
                      routing={r}
                      data={access}
                      onChange={dt => {
                        setValue('access', dt, { shouldValidate: true })
                      }}
                      isExternal={isExternal}
                    />
                  </AccordionBody>
                </Accordion>
              )
            })}
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={() => handler()}
            className="mr-2"
            disabled={isProcessing}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={!isValid || isProcessing}
          >
            {isProcessing && (
              <ArrowPathIcon className="w-4 h-4 mr-2 text-primary-main animate-spin" />
            )}
            {data?.user_id ? 'Update' : 'Send an invite'}
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  )
}

export default DialogFormUser
