import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '@components/atoms/button'
import AuthContext from '@contexts/auth'
import { CategoriesResult } from '@interfaces/manage-reporting'
import { TemplateDialogProps } from '@interfaces/manage-signature'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import SigningTemplateAction from '@pages/manage/monitor/actions/document-centre/reporting/signing-template-action'
import BorrowingBaseCertAction from '@pages/manage/monitor/actions/document-centre/templates/borrowing-base-cert'
import ComplianceCertAction from '@pages/manage/monitor/actions/document-centre/templates/compliance-cert'
import FinancialCovenantCertAction from '@pages/manage/monitor/actions/document-centre/templates/financial-covenant-cert'
import PortfolioCovenantCertProvider from '@pages/manage/monitor/actions/document-centre/templates/portfolio-covenant-cert/portfolio-covenant-action'
import UnauditedFinStatementsAction from '@pages/manage/monitor/actions/document-centre/templates/unaudited-financial-statements-cert'

interface GenerateDocProviderProps {
  docData: any
  category: CategoriesResult
  handler: () => void
  refetchHandler: () => void
  can_sign: boolean
}

interface Redirects {
  'Advance Request': string
  'Capital Advance': string
  'Waterfall Report': string
  'Profit Share': string
}

export const redirectCategories = [
  'Advance Request',
  'Capital Advance',
  'Waterfall Report',
]

const GenerateDocProvider = ({
  docData,
  category,
  handler,
  refetchHandler,
  can_sign,
}: GenerateDocProviderProps) => {
  const navigate = useNavigate()
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(true)
  const { company, setLoadingData } = useContext(AuthContext)

  const redirects: Redirects = {
    'Advance Request': `/manage/monitor/actions?tab=advance-request&dCAction=create&dcId=${docData.id}&pid=${company?.slug_name}`,
    'Capital Advance': `/manage/monitor/actions?tab=advance-request&dCAction=create&dcId=${docData.id}&pid=${company?.slug_name}`,
    'Waterfall Report': `/manage/monitor/actions?tab=waterfall&dCAction=create&pid=${company?.slug_name}`,
    'Profit Share': `/manage/monitor/actions?tab=waterfall&tab2=profit-share&dCAction=create&pid=${company?.slug_name}`,
  }

  const handleDialogOpen = () => {
    handler()
    setDialogIsOpen(!dialogIsOpen)
  }

  const redirectUser = (path: string) => {
    setLoadingData([
      {
        state: 'loading',
        text: `Redirecting...`,
      },
    ])

    setTimeout(() => {
      setLoadingData({})
      navigate(path)
    }, 1000)
  }

  useEffect(() => {
    if (redirects[category.category as keyof Redirects]) {
      redirectUser(redirects[category.category as keyof Redirects])
    }
  }, [category, navigate])

  switch (category.category) {
    case 'Compliance Certificate':
      return (
        <SigningTemplateAction
          docData={docData}
          dialogOpen={dialogIsOpen}
          handler={() => {
            handleDialogOpen()
            refetchHandler()
          }}
          renderDialog={(props: TemplateDialogProps) => (
            <ComplianceCertAction {...props} />
          )}
          category={category}
          can_sign={can_sign}
        />
      )

    case 'Portfolio Covenant Certificate':
      return (
        <SigningTemplateAction
          docData={docData}
          dialogOpen={dialogIsOpen}
          handler={() => {
            handleDialogOpen()
            refetchHandler()
          }}
          renderDialog={(props: TemplateDialogProps) => (
            <PortfolioCovenantCertProvider {...props} />
          )}
          category={category}
          can_sign={can_sign}
        />
      )

    case 'Financial Covenant Certificate':
      return (
        <SigningTemplateAction
          docData={docData}
          handler={() => {
            handler()
            refetchHandler()
          }}
          dialogOpen={dialogIsOpen}
          renderDialog={(props: TemplateDialogProps) => (
            <FinancialCovenantCertAction {...props} />
          )}
          category={category}
          can_sign={can_sign}
        />
      )

    case 'Borrowing Base Certificate':
      return (
        <SigningTemplateAction
          docData={docData}
          handler={() => {
            handler()
            refetchHandler()
          }}
          dialogOpen={dialogIsOpen}
          renderDialog={(props: TemplateDialogProps) => (
            <BorrowingBaseCertAction {...props} />
          )}
          category={category}
          can_sign={can_sign}
        />
      )

    case 'Unaudited Financial Statements':
      return (
        <SigningTemplateAction
          docData={docData}
          handler={() => {
            handler()
            refetchHandler()
          }}
          dialogOpen={true}
          renderDialog={(props: TemplateDialogProps) => (
            <UnauditedFinStatementsAction {...props} />
          )}
          category={category}
          can_sign={can_sign}
        />
      )

    default:
      return !redirectCategories.includes(category.category) ? (
        <Dialog
          open={dialogIsOpen}
          handler={handleDialogOpen}
          className="overflow-auto z-40"
        >
          <DialogHeader>
            <div className="flex justify-between w-full">
              <span>Feature Available Soon!</span>
            </div>
          </DialogHeader>
          <DialogBody divider>
            <div className="flex flex-col gap-6 w-full mt-5">
              <p>This feature will be available soon</p>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              className="rounded-lg flex-1 max-w-[100px]"
              color="primary"
              onClick={handleDialogOpen}
            >
              Ok
            </Button>
          </DialogFooter>
        </Dialog>
      ) : (
        <></>
      )
  }
}

export default GenerateDocProvider
