import React from 'react'

import Button from '@components/atoms/button'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'

interface UnavailDialogProps {
  open: boolean
  handleOpen: () => void
  headerText: string
  description: string
}

const BasicDialog = ({
  open,
  handleOpen,
  headerText,
  description,
}: UnavailDialogProps) => {
  return (
    <Dialog open={open} handler={handleOpen} className="overflow-auto z-40">
      <DialogHeader>
        <div className="flex justify-between w-full">
          <span>{headerText}</span>
          <XMarkIcon
            onClick={handleOpen}
            className="w-8 h-8 cursor-pointer hover:opacity-50"
          />
        </div>
      </DialogHeader>
      <DialogBody divider>
        <div className="flex flex-col gap-6 w-full mt-5">{description}</div>
      </DialogBody>
      <DialogFooter>
        <Button
          className="rounded-lg flex-1"
          color={'primary'}
          onClick={handleOpen}
        >
          OK
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
export default BasicDialog
