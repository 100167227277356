export enum ACTIONS {
  UPLOAD,
  NEW_COMPANY,
  EDIT,
  SUCCESS,
  ERROR,
}

export const ERROR_UPLOAD =
  'Error uploading files, Please try again and refresh the page'
export const UPLOAD_SUCCESS = 'Files uploaded successfully'

export const FAILED_CREATE_ENTITY = 'Failed to create entity'
export const SUCCESS_CREATE_ENTITY = 'Entity created successfully'

export const FAILED_UPDATE_ENTITY =
  'Failed to update entity, please refresh the page and try again'
export const SUCCESS_UPDATE_ENTITY = 'Company updated successfully'

export const FAILED_DELETE_ENTITY =
  'Failed to delete entity, please refresh the page and try again'
export const SUCCESS_DELETE_ENTITY = 'Company deleted successfully'
export const FAILED_DELETE_FILE =
  'Failed to delete file, please refresh the page and try again'
export const SUCCESS_DELETE_FILE = 'File deleted successfully'
export const SUCCESS_OPERATION = 'Request has been successful'
export const FAILED_OPERATION =
  'Request has failed, please try again or refresh the page'

export const VALID_NAMES = [
  'balance Sheet' || 'statement' || 'profit' || 'statements',
]

export const TOASTY_POSITION = 'TOP_RIGHT'
export const CLOSE_TOASTY = 10000
export const TIMER = 1000
export const MAX_FILE_SIZE = 5000000
export const CASCADE_TEMPLATE_BUCKET_NAME = process.env
  .REACT_APP_CASCADE_TEMPLATE_BUCKET_NAME as string
const FILE_NAME = 'cascade-financial-template.xlsx'
export const CASCADE_FOLDER_NAME_FINANCIAL_TEMPLATE = `${
  process.env.REACT_APP_CASCADE_FOLDER_NAME_FINANCIAL_TEMPLATE as string
}${FILE_NAME}`

export const CURRENCIES_CODES = [
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTC',
  'BTN',
  'BWP',
  'BYN',
  'BYR',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CUC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GGP',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'IMP',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JEP',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LTL',
  'LVL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'STD',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'UYU',
  'UZS',
  'VEF',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XAG',
  'XAU',
  'XCD',
  'XDR',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMK',
  'ZMW',
  'ZWL',
]
interface RemappedHeaders {
  [key: string]: string
}
export const remappedHeaders: Record<string, RemappedHeaders> = {
  'Balance Sheet': {
    'Statement close date': 'data_date',
    'Cash and cash equivalents': 'cash',
    'Accounts receivable - net': 'accounts_receivable',
    Inventory: 'inventory',
    'Prepaid Expenses': 'prepaid_expenses',
    'Other Current Assets': 'other_current_assets',
    'Total Current Assets': 'total_current_assets',
    'Asset Portfolio1': 'asset_portfolio',
    'Fixed assets (Property, equpiment, etc.)': 'fixed_assets',
    'Intangible assets': 'intangible_assets',
    'Other Long-term assets': 'other_longterm_assets',
    'Total Long-term assets': 'total_longterm_assets',
    'TOTAL ASSETS': 'total_assets',
    'Accounts payable': 'accounts_payable',
    'Deferred revenues': 'defferred_revenue',
    'Current portion of long-term debt2': 'current_longterm_assets',
    'Accrued expenses': 'accrued_expenses',
    'Other current liabilities': 'other_current_liabilities',
    'Total Current Liabilities': 'total_current_liabilities',
    'Long-term debt, less current3': 'longterm_debt',
    'Other long-term liabilities': 'other_longterm_liabilities',
    'Total Long-term Liabilities': 'total_longterm_liabilities',
    'TOTAL LIABILITIES': 'total_liabilities',
    'Common and preferred equity': 'equity',
    'Additional paid-in capital': 'paidin_capital',
    'Retained Earnings': 'retained_earnings',
    'TOTAL EQUITY': 'total_equity',
    'TOTAL LIABILITIES AND EQUITY': 'total_liabilities_and_equity',
  },
  'Profit and Loss': {
    'Statement close date': 'data_date',
    Revenue1: 'revenue',
    'Cost of Goods Sold2': 'cost_of_goods_sold',
    'Sales and Marketing Expenses': 'accounts_receivable',
    'Research and Development Expenses': 'rnd_expenses',
    'General and Administrative Expenses': 'general_admin_expenses',
    'Other Operating Expenses': 'other_operating_expenses',
    EBITDA: 'ebitda',
    'Operating Income': 'operating_income',
    'Depreciation and Amortization': 'deprec_amort',
    'Non-operating income3': 'none_operating_income',
    'Non-operating expenses4': 'none_operating_expense',
    EBT: 'total_longterm_assets',
    'Income tax expense': 'income_tax_expense',
    'NET INCOME': 'net_income',
  },
}
