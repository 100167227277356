import React from 'react'
import moment from 'moment'
import { NavLink } from 'react-router-dom'

import Typography from '@components/atoms/typography'
import Table from '@components/table'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { CovenantListResponse, CovenantType } from '@interfaces/covenant'

import { getUnit, useCovenantDetail } from '../helper'

interface CovenantL4SummaryOverviewProps {
  covenants: CovenantListResponse[]
  type: CovenantType
  link?: string
}
const CovenantL4SummaryDefaultOverview = ({
  covenants,
  type,
  link,
}: CovenantL4SummaryOverviewProps) => {
  const conv = covenants?.[0]
  const isInverted = Number(conv?.invert_colors) === 1

  const { chartData, breachCounter } = useCovenantDetail(covenants, type)

  const hasTrigger =
    !!chartData?.[0]?.[
      `${covenants?.[0]?.[`${type}_covenant`]
        .toLowerCase()
        .split(' ')
        .join('_')}_trigger_1`
    ]

  const TRIAL_PERIOD_DAYS = parseFloat((conv?.test_day ?? 60).toString())
  const chartLastDate = moment.utc().subtract(TRIAL_PERIOD_DAYS, 'days')
  const eligibleData = chartData?.filter(x =>
    moment.utc(x.cohort).isSameOrBefore(moment.utc(chartLastDate))
  )
  const lastData =
    eligibleData[eligibleData.length - 1] ??
    chartData?.[chartData.length - 1] ??
    {}

  return (
    <NavLink
      to={
        (link ?? `/manage/monitor/${type}-covenants?`) +
        `tab=${conv?.[`${type}_covenant`].toLowerCase().split(' ').join('-')}`
      }
      className="flex flex-col border border-neutral-border-2 rounded p-3 gap-6 hover:bg-primary-surface-2"
    >
      <div className="flex flex-col flex-1">
        <Typography className="font-medium text-center">
          {conv[`${type}_covenant`]}
        </Typography>
        <Typography className="text-sm text-center">
          {`${
            parseFloat(conv?.cohort_based.toString()) === 1
              ? 'Current Cohort'
              : 'Current Rate'
          } as ${moment.utc(lastData?.x).format('MMM-YY')}`}
        </Typography>
      </div>
      <div className="flex flex-1 gap-4">
        {covenants.map((c, i) => {
          return (
            <div
              className={`flex flex-col flex-1 gap-2 ${
                covenants.length > 1 ? 'justify-between' : ''
              }`}
              key={i}
            >
              {covenants.length > 1 ? (
                <Typography className="text-xs text-center">
                  {c?.[`${type}_covenant`]}
                </Typography>
              ) : (
                ''
              )}
              <Typography className="text-2xl font-semibold text-center">
                {lastData?.[lastData?.[`${type}_covenant`]]
                  ? Intl.NumberFormat(undefined, {
                      notation: 'compact',
                      maximumFractionDigits: 2,
                    }).format(
                      lastData?.[
                        c?.[`${type}_covenant`]
                          ?.toLowerCase()
                          ?.split(' ')
                          .join('_')
                      ] ?? 0
                    )
                  : 0}
                {getUnit(conv, type)}
              </Typography>
            </div>
          )
        })}
      </div>
      {hasTrigger && (
        <div className="flex flex-1 [&>div]:!mb-0">
          <Table
            columns={[
              { title: 'Trigger', field: 'trigger', align: 'center' },
              { title: 'Value', field: 'value', align: 'center' },
              { title: 'Breach', field: 'breach', align: 'center' },
              {
                title: '',
                field: 'current',
                align: 'center',
                render: (r: any) => {
                  const Icon = r.current ? CheckCircleIcon : XCircleIcon
                  return (
                    <Icon
                      className={`${
                        r.current ? 'text-success-main' : 'text-danger-main'
                      } w-6 h-6`}
                    />
                  )
                },
              },
            ]}
            data={Array(Number(conv.triggers))
              .fill('')
              .map((x, i) => {
                const trigger = i + 1
                const value =
                  lastData?.[
                    `${conv?.[`${type}_covenant`]
                      ?.toLowerCase()
                      .split(' ')
                      .join('_')}_trigger_${trigger}`
                  ]
                return {
                  trigger,
                  value: `${Intl.NumberFormat(undefined, {
                    notation: 'compact',
                    maximumFractionDigits: 2,
                  }).format(value)}${getUnit(conv, type)}`,
                  breach: Intl.NumberFormat(undefined, {
                    style: 'decimal',
                  }).format(
                    breachCounter[Number(trigger)]?.filter(
                      x => x <= lastData?.x
                    )?.length ?? 0
                  ),
                  current:
                    (isInverted &&
                      covenants.some(
                        c =>
                          lastData?.[
                            c?.[`${type}_covenant`]
                              ?.toLowerCase()
                              ?.split(' ')
                              .join('_')
                          ] >= value
                      )) ||
                    (!isInverted &&
                      covenants.some(
                        c =>
                          lastData?.[
                            c?.[`${type}_covenant`]
                              ?.toLowerCase()
                              ?.split(' ')
                              .join('_')
                          ] <= value
                      )),
                }
              })}
          />
        </div>
      )}
    </NavLink>
  )
}

export default CovenantL4SummaryDefaultOverview
