import React, { useContext } from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import AppRouting from '@components/app-routes/routes'
import AuthLayout from '@components/layouts/auth-layout'
import { IS_MAINTENANCE } from '@constants/config'
import AuthContext from '@contexts/auth'
import { Facility } from '@interfaces/facility'
import AuthCallback from '@pages/auth-callback'
import AuthHandler from '@pages/auth-handler'
import MaintenancePage from '@pages/maintenance'
import NoMatchPage from '@pages/no-match'

const UnknownPath = () => {
  const auth0 = useAuth0()
  const context = useContext(AuthContext)

  const [searchParams] = useSearchParams()
  const facilityParam = searchParams.get('facility')
  const { pathname, search } = useLocation()

  // Handle master facility links to sub-facilities
  if (facilityParam) {
    const {
      appliedFilters,
      optionFilters,
      setActiveFilters,
      setAppliedFilters,
    } = context
    const { activeDebtDeal } = appliedFilters
    const { debtDealOptions = [] } = optionFilters
    const facilityIndex = debtDealOptions.findIndex(
      (d: Facility) => d.facility === facilityParam
    )
    if (facilityIndex > -1 && facilityIndex !== activeDebtDeal) {
      setActiveFilters((prev: any) => ({
        ...prev,
        activeDebtDeal: facilityIndex,
      }))
      setAppliedFilters((prev: any) => ({
        ...prev,
        activeDebtDeal: facilityIndex,
      }))
      return <Navigate to={`${pathname}${search}`} />
    }
  }

  const l1_pathname = pathname?.split('/')?.[1]

  const flaggedRouting = AppRouting(auth0, context)
  const is_l1_available = flaggedRouting.map(x => x.path).includes(l1_pathname)

  return (
    <Navigate replace to={is_l1_available ? `/${l1_pathname}${search}` : '/'} />
  )
}

/**
 * @todo
 * Make it recursive
 */
const AppRoutes = () => {
  const auth0 = useAuth0()
  const context = useContext(AuthContext)

  const flaggedRouting = AppRouting(auth0, context)

  /**
   * Javascript Ugh!
   * https://www.freecodecamp.org/news/explaining-the-best-javascript-meme-i-have-ever-seen/
   */
  if (IS_MAINTENANCE?.toLowerCase() === 'true') {
    return <MaintenancePage />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth-callback" element={<AuthCallback />} />
        <Route path="/auth-handler" element={<AuthHandler />} />
        <Route path="/" element={<AuthLayout />}>
          {flaggedRouting.map((levelOne, idxOne) => (
            <React.Fragment key={levelOne.path}>
              {idxOne === 0 && (
                <Route
                  index
                  element={<Navigate to={levelOne.path} replace />}
                />
              )}
              <Route path={levelOne.path}>
                {levelOne.routes?.map((levelTwo, idxTwo) => (
                  <React.Fragment key={levelTwo.path}>
                    {idxTwo === 0 && (
                      <Route
                        index
                        element={<Navigate to={levelTwo.path} replace />}
                      />
                    )}
                    <Route
                      path={levelTwo.path}
                      element={
                        (!levelTwo.routes || levelTwo.component) &&
                        levelTwo.component
                      }
                    >
                      {levelTwo.routes?.map((levelThree, idxThree) => (
                        <React.Fragment key={levelThree.path}>
                          {idxThree === 0 && (
                            <Route
                              index
                              element={
                                <Navigate to={levelThree.path} replace />
                              }
                            />
                          )}
                          <Route
                            path={levelThree.path}
                            element={levelThree.component}
                          ></Route>
                        </React.Fragment>
                      ))}
                    </Route>
                  </React.Fragment>
                ))}
              </Route>
            </React.Fragment>
          ))}
          <Route path="/404" element={<NoMatchPage />} />
          <Route path="*" element={<UnknownPath />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
