/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import Typography from '@components/atoms/typography'
import {
  ArrowPathIcon,
  ArrowsUpDownIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'

import { ExpandableProps, Part, SortOrder, TableProps } from './type'

const CustomTable = ({
  containerClass,
  title,
  columns,
  data = [],
  headerData,
  footerData,
  loading = false,
  expandable,
  sortField,
  sortOrder,
  onSort,
  onRowClick,
  rowClass,
}: TableProps) => {
  const {
    expandedIndexes = [],
    onExpand,
    expandedRowRender,
  } = expandable ?? ({} as ExpandableProps)

  const _renderColumnData = (record: any, recordIndex: number, part: Part) => {
    return columns.map(
      ({ head, title, field, render, props, ...restColumn }, j) => {
        const allProps = {
          ...restColumn,
          ...props?.(record, recordIndex, part),
        }
        return (
          <td key={j} {...allProps}>
            {record[field]?.content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: record[field]?.content,
                }}
              />
            )}

            {record[field]?.table && (
              <div className="mt-4">
                <CustomTable
                  columns={record[field].table.columns}
                  data={record[field].table.data}
                />
              </div>
            )}

            {!record[field]?.content &&
              !record[field]?.table &&
              render &&
              render(record, recordIndex, part)}

            {!record[field]?.content && !record[field]?.table && !render && (
              <div
                dangerouslySetInnerHTML={{
                  __html: record[field],
                }}
              />
            )}
          </td>
        )
      }
    )
  }

  const getIcon = (field: string, sortOrder?: SortOrder) => {
    if (sortField === field) {
      return sortOrder === 'asc' ? BarsArrowUpIcon : BarsArrowDownIcon
    }
    return ArrowsUpDownIcon
  }

  return (
    <div className="flex flex-col mb-6 flex-1">
      {title && (
        <Typography className="text-xl text-neutral-black font-medium mb-4">
          {title}
        </Typography>
      )}
      <div className={`cascade-table ${containerClass}`}>
        <table>
          <thead>
            <tr>
              {!!expandable && <th></th>}
              {columns.map(
                (
                  {
                    head,
                    title,
                    field,
                    headRender,
                    sortable = false,
                    ...restColumn
                  },
                  i
                ) => {
                  const props = { ...restColumn, ...head }
                  const Icon = getIcon(field, sortOrder)
                  return props.colSpan === 0 ? null : (
                    <th
                      key={i}
                      {...props}
                      onClick={() => {
                        const order =
                          sortField === field && sortOrder === 'asc'
                            ? 'desc'
                            : sortField === field && sortOrder === 'desc'
                            ? ''
                            : 'asc'
                        sortable && onSort?.(field, order)
                      }}
                    >
                      {sortable && (
                        <Icon
                          className={`w-4 h-4 mr-4 cursor-pointer ${
                            sortField === field ? 'text-primary-main' : ''
                          }`}
                        />
                      )}
                      <div>{headRender?.() ?? title}</div>
                    </th>
                  )
                }
              )}
            </tr>
            {headerData?.map((d, i) => {
              return (
                <React.Fragment key={i}>
                  <tr>
                    {!!expandable && <th></th>}
                    {_renderColumnData(d, i, 'head')}
                  </tr>
                </React.Fragment>
              )
            })}
          </thead>
          <tbody>
            {loading && (
              <td
                colSpan={columns.length + (expandable ? 1 : 0)}
                align="center"
              >
                <ArrowPathIcon className="w-6 h-6 text-primary-main animate-spin m-6" />
              </td>
            )}
            {!loading &&
              data?.length > 0 &&
              data?.map((d, i) => {
                const isOpen = expandedIndexes.includes(i)
                const Icon = isOpen ? ChevronDownIcon : ChevronRightIcon
                return (
                  <React.Fragment key={i}>
                    <tr
                      onClick={() => {
                        !expandable && !!onRowClick && onRowClick?.(d, i)
                      }}
                      className={`${rowClass?.(d, i)} row-head`}
                    >
                      {!!expandable && (
                        <td
                          width="50px"
                          align="center"
                          onClick={() => {
                            !!expandable && onExpand?.(i)
                          }}
                        >
                          <Icon
                            className={`w-4 h-4 ${
                              isOpen ? 'icon-opened' : 'icon-closed'
                            }`}
                          />
                        </td>
                      )}
                      {_renderColumnData(d, i, 'body')}
                    </tr>
                    {!!expandable && isOpen && (
                      <tr className={`${rowClass?.(d, i)} row-expandable`}>
                        <td colSpan={columns.length + 1}>
                          {expandedRowRender?.(d, i)}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                )
              })}
            {!loading && data.length === 0 && (
              <tr>
                <td
                  colSpan={columns.length + (expandable ? 1 : 0)}
                  className="text-center"
                >
                  {`No ${title || ''} data`}
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            {footerData?.map((d, i) => {
              return (
                <React.Fragment key={i}>
                  <tr>
                    {!!expandable && <th></th>}
                    {_renderColumnData(d, i, 'foot')}
                  </tr>
                </React.Fragment>
              )
            })}
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default CustomTable
