import { FinancialsFilters, StatementResponse } from '@interfaces/financial'
import { OverviewDataResponse } from '@interfaces/risk'

import apiClient from './client'

export class StatementService {
  static async getBalanceSheet(params: FinancialsFilters) {
    if (params.entity_id) {
      const { data } = await apiClient.post<StatementResponse>(
        '/financial/v1/balance-sheet',
        params
      )

      return data
    }
  }

  static async getPnL(params: FinancialsFilters) {
    if (params.entity_id) {
      const { data } = await apiClient.post<StatementResponse>(
        '/financial/v1/pnl-sheet',
        params
      )

      return data
    }
  }

  static async getOverview(params: any) {
    if (params?.entity_id) {
      const { data } = await apiClient.post<OverviewDataResponse>(
        '/financial/total-assets-overview',
        params
      )

      return data
    }
  }
}
